<script setup>
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { RegistrationState, COMPANY_SIZES } from '@/pages/register/register.js';
import useAnalytics from '@/state/composables/useAnalytics';
import useAbTestRedirect from '@/state/composables/useAbTestRedirect';

const { postAnalyticsEvent } = useAnalytics();
const { getAbTestRedirectConfig } = useAbTestRedirect();
const $router = useRouter();
const $route = useRoute();

const state = ref(new RegistrationState().load());

onBeforeMount(() => {
  postAnalyticsEvent({
    intent: 'view_registration_redirect',
    action: 'view',
  });

  const abTestGroupId = $route.query.ws_group_id;
  if (abTestGroupId) {
    const redirectConfig = getAbTestRedirectConfig(abTestGroupId);
    // Capture the abTestConfigId to mark which AB test flow a particular visitor_id is on
    const metadata = {
      redirectConfigId: redirectConfig.id,
      abTestGroupId: abTestGroupId,
      pathName: redirectConfig.name,
      ...redirectConfig.queryParams,
    };
    postAnalyticsEvent({
      intent: 'ab_test_redirect',
      action: 'navigate',
      meta: metadata,
    });
    $router.push({
      name: redirectConfig.name,
      query: { redirect_config_id: redirectConfig.id, ws_group_id: abTestGroupId, ...redirectConfig.queryParams },
    });
    return;
  }

  // handle state
  const companySize = $route.query.company_size;
  const parsedCompanySize = companySize ? companySize.replace(/\D/g, '') : null;
  state.value.state.companySize = parsedCompanySize ? parseInt(parsedCompanySize) : null;
  // other necessary info for onboarding will go here e.g email, name, etc
  state.value.save();

  // handle redirects
  if (!parsedCompanySize || parsedCompanySize < COMPANY_SIZES.S) {
    postAnalyticsEvent({
      intent: 'view_registration_self_serve',
      action: 'navigate',
    });
    $router.push({ name: 'register-user' });
  } else {
    postAnalyticsEvent({
      intent: 'navigate_registration_book_demo',
      action: 'navigate',
    });
    $router.push({ name: 'register-book-demo' });
  }
});
</script>
