<template>
  <div>
    <SmeAlert level="info" class="mb-3">
      Clicking continue will open a Stripe checkout page, where you will be able to supply payment details using a
      Credit/ Debit card or Direct Debit.
      <span class="text-danger font-weight-bold">You will not be charged until you go live with Wagestream</span>
    </SmeAlert>
    <AppRadioCards
      :value="planName"
      @input="emit('input:planName', $event)"
      :options="plans"
      class="register-subscription__cards"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import AppRadioCards from '@/components/AppRadioCards.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';

const emit = defineEmits(['input:planName']);
const props = defineProps(['planName', 'plans']);

const planName = computed(() => props.planName);
</script>

<style scoped lang="scss">
.register-subscription__cards {
  margin-bottom: 0;
}
.register-subscription__payment {
  margin-top: 1.5rem;
}
.register-subscription__payment-header {
  margin-bottom: 1.5rem;
}
.register-subscription__payment-code {
  margin-top: 1.5rem;
  border: none;
  font-size: 14px;
}
</style>
