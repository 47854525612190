<template>
  <div :class="['image-header', { small: !!props.small }]">
    <div class="cover-image-container">
      <img v-if="props.coverUrl" :src="props.coverUrl" class="cover-image" />
      <img v-if="!props.canEdit && !props.coverUrl" :src="themeImage('camera.svg')" class="camera-icon" />
      <label for="cover-upload" class="btn-primary btn add-cover" v-if="props.canEdit">
        <img :src="themeImage('camera-outline.svg')" class="button-camera" />
        {{ props.coverUrl ? 'Replace cover image' : 'Add cover image' }}
        <input type="file" id="cover-upload" hidden accept="image/*" @change="selectedCover" />
      </label>
    </div>
    <div :class="['logo-container', props.centerLogo ? 'center' : '']" v-if="!props.small">
      <img v-if="props.logoUrl" :src="props.logoUrl" class="logo" />
      <div class="logo" v-if="!props.logoUrl">
        <img :src="themeImage('camera.svg')" class="camera-icon" />
      </div>
      <label for="logo-upload" class="btn-primary btn add-logo" v-if="props.canEdit">
        <img :src="themeImage('camera-outline.svg')" class="button-camera" />
        {{ props.logoUrl ? 'Replace logo' : 'Add logo' }}
        <input type="file" id="logo-upload" hidden accept="image/*" @change="selectedLogo" />
      </label>
    </div>
  </div>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import { themeImage } from '@/Theme';
import { ref } from 'vue';

const props = defineProps({
  small: Boolean,
  logoUrl: String,
  coverUrl: String,
  canEdit: Boolean,
  centerLogo: Boolean,
});

const emit = defineEmits(['on-upload']);

const file = ref(null);

const selectedLogo = e => {
  file.value = e.target.files[0];
  uploadFile('logo');
};

const selectedCover = e => {
  file.value = e.target.files[0];
  uploadFile('cover');
};

const uploadFile = type => {
  const formData = new FormData();
  formData.append('file', file.value);

  uploadNewImage(formData, type);
};

const uploadNewImage = async (data, type) => {
  const result = await ApiClient.uploadImage(data);
  emit('on-upload', result.http_path, type);
};
</script>

<style scoped>
.image-header {
  position: relative;
  padding-bottom: 1.5rem;
}

.image-header.small {
  position: relative;
  padding-bottom: 0.5rem;
}

.image-header.small .cover-image-container {
  height: 90px;
}

.image-header.small .camera-icon {
  margin: 2rem;
}

.cover-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--palette-color-base-background);
  height: 140px;
  text-align: center;
  border-radius: 10px;
}

.cover-image {
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-icon {
  object-fit: contain;
}

.add-cover {
  position: absolute;
}

.logo-container {
  position: absolute;
  left: 1rem;
  top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  left: 39%;
  top: 3.5rem;
}

.add-logo {
  margin-top: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}

.button-camera {
  margin-right: 6px;
}

.logo {
  object-fit: contain;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid var(--palette-color-default-lighten-90);
  background-color: var(--palette-color-base-white);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
