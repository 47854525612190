<template>
  <app-page
    title="Last Day Pay"
    subtitle="Status of Employees that have received a Last Day Pay"
    icon="file-invoice-dollar"
  >
    <template #custom-content>
      <b-col>
        <b-card>
          <div class="">
            <page-list-header>
              <template #left>
                <filter-dropdown v-model="filters" :filters="filterItems">
                  <inline-search v-model="searchText" @search="onSearch" />
                </filter-dropdown>
              </template>
              <template #right>
                <b-form-group label-cols="auto" label="Per page" class="mb-0">
                  <b-form-select v-model="perPage" :options="LAST_DAY_PAY_PER_PAGE_OPTIONS" />
                </b-form-group>
                <export-button
                  :export-data="filteredPayments"
                  :export-file-rows="exportFileRows"
                  :export-headers="exportHeaders"
                  file-name="last-day-pay"
                />
              </template>
            </page-list-header>
            <sme-card class="mb-3">
              <app-loading :loading="loading" />

              <b-table
                v-if="!loading"
                :items="payments"
                :fields="fieldDefinitions"
                filter="''"
                :filter-function="filterFunction"
                :current-page="currentPage"
                :per-page="perPage"
                class="mb-0"
                table-class="table-minimal"
                :tfoot-class="!payments?.length ? 'd-none' : ''"
                primary-key="employee_id"
                no-footer-sorting
                responsive
                show-empty
                @filtered="update"
                :tbody-tr-class="rowClass"
                @row-hovered="onRowHovered"
                @row-unhovered="onRowUnhovered"
              >
                <template v-slot:cell(full_name)="data">
                  <router-link :to="{ name: 'employee', params: { employeeId: data?.item?.employee_id } }">
                    {{ data?.item?.full_name }}
                  </router-link>
                </template>
              </b-table>
              <b-tooltip v-if="rowTooltip" show :target="rowTooltip.target" triggers="manual">
                An error occured making this payment, but our support team will be aware. Please check back later.
                <br /><br />
                If this issue persists, please contact support.
              </b-tooltip>
            </sme-card>

            <b-pagination
              v-model="currentPage"
              :total-rows="filteredPayments.length"
              :per-page="perPage"
              class="justify-content-end"
            />
          </div>
        </b-card>
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import {
  LAST_DAY_PAY_API_FILTER_KEYS,
  LAST_DAY_PAY_FIELD_DEFINITIONS,
  LAST_DAY_PAY_FIELD_KEYS,
  LAST_DAY_PAY_FILTER_KEYS,
  LAST_DAY_PAY_PER_PAGE_OPTIONS,
  LAST_DAY_PAY_SEARCH_FIELDS,
} from '@/pages/lastDayPayReport/constants';
import debounceAsync from '@/utils/debounceAsync';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';

const STARTS_ON_DEFAULT_VALUE = moment().startOf('month').format('YYYY-MM-DD');
const ENDS_ON_DEFAULT_VALUE = moment().format('YYYY-MM-DD');

const payments = ref([]);
const filteredPayments = ref([]);
const loading = ref(true);
const initialized = ref(false);
const filters = ref({
  [LAST_DAY_PAY_FILTER_KEYS.STARTS_ON]: STARTS_ON_DEFAULT_VALUE,
  [LAST_DAY_PAY_FILTER_KEYS.ENDS_ON]: ENDS_ON_DEFAULT_VALUE,
});
const searchCriteria = ref('');
const searchText = ref('');
const currentPage = ref(1);
const perPage = ref(LAST_DAY_PAY_PER_PAGE_OPTIONS[0]);

const fieldDefinitions = computed(() => {
  return LAST_DAY_PAY_FIELD_DEFINITIONS.filter(field => Object.values(LAST_DAY_PAY_FIELD_KEYS).includes(field.key));
});

const exportHeaders = computed(() => fieldDefinitions.value.map(field => field.label));

const filterItems = computed(() => [
  {
    key: LAST_DAY_PAY_FILTER_KEYS.STARTS_ON,
    label: 'From',
    type: CONTROL_TYPES.DATE,
    defaultValue: STARTS_ON_DEFAULT_VALUE,
    controlProps: {
      max: filters.value[LAST_DAY_PAY_FILTER_KEYS.ENDS_ON] ? filters.value[LAST_DAY_PAY_FILTER_KEYS.ENDS_ON] : null,
    },
  },
  {
    key: LAST_DAY_PAY_FILTER_KEYS.ENDS_ON,
    label: 'To',
    type: CONTROL_TYPES.DATE,
    defaultValue: ENDS_ON_DEFAULT_VALUE,
    controlProps: {
      min: filters.value[LAST_DAY_PAY_FILTER_KEYS.STARTS_ON] ? filters.value[LAST_DAY_PAY_FILTER_KEYS.STARTS_ON] : null,
    },
  },
]);

const appliedApiFilters = computed(() =>
  omitBy(pick(filters.value, LAST_DAY_PAY_API_FILTER_KEYS), value => isNil(value)),
);

const filterConditions = computed(() => {
  const conditions = [];
  if (searchCriteria.value) {
    conditions.push(payment => {
      return Object.values(pick(payment, LAST_DAY_PAY_SEARCH_FIELDS)).some(field => {
        return field.toLowerCase().includes(searchCriteria.value.toLowerCase());
      });
    });
  }
  return conditions;
});

watch(payments, payments => update(payments.filter(filterFunction)));

const getPayments = debounceAsync(async () => {
  const params = appliedApiFilters.value;
  const rawPayments = (await ApiClient.getLastDayPayReport(params))?.data || [];
  payments.value = rawPayments.sort((a, b) => new Date(b.final_pay_initiated_at) - new Date(a.final_pay_initiated_at));
});

const update = payments => {
  filteredPayments.value = payments;
  currentPage.value = 1;
};

const filterFunction = item =>
  filterConditions.value.length ? filterConditions.value.every(condition => condition(item)) : true;

const onSearch = () => (searchCriteria.value = searchText.value);

const exportFileRows = item =>
  fieldDefinitions.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );

onBeforeMount(async () => {
  watch(
    appliedApiFilters,
    async (newFilters, prevFilters) => {
      loading.value = true;

      if (!initialized.value) {
        await getPayments();
      } else {
        if (!isEqual(newFilters, prevFilters)) {
          await getPayments();
        }
      }

      loading.value = false;
      initialized.value = true;
    },
    {
      deep: true,
      immediate: true,
    },
  );
});

const rowClass = item => {
  return {
    'table-danger': item && !item?.completed_at,
  };
};
const rowTooltip = ref(null);
const onRowHovered = (item, _, event) => {
  if (!item.completed_at) {
    rowTooltip.value = { target: event.target, item };
  }
};
const onRowUnhovered = () => {
  rowTooltip.value = null;
};
</script>
