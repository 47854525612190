import { toCurrency } from '@/utils';
import { toFormattedDateTime } from '@/utils/date';

export const EMPLOYEE_PAYMENTS_FIELD_DEFINITIONS = [
  {
    key: 'employee_id',
    label: 'Employee code',
    sortable: true,
  },
  {
    key: 'full_name',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Amount Sent',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'salary_processed_on',
    label: 'Salary Paid On',
    sortable: true,
    formatter: value => toFormattedDateTime(value),
  },
  {
    key: 'sent_at',
    label: 'Sent At',
    sortable: true,
    formatter: value => (value ? toFormattedDateTime(value) : null),
  },
  {
    key: 'to_account',
    label: 'To Account/Card',
    sortable: true,
  },
];

// export const STREAM_FIELD_KEYS = {
//   EMPLOYEE_CODE: 'employee_code',
//   FULL_NAME: 'full_name',
//   NET_AMOUNT: 'net_amount',
//   CREATED_AT: 'created_at',
// };

export const EMPLOYEE_PAYMENTS_PER_PAGE_OPTIONS = [25, 50, 100];
export const LAST_DAY_PAY_UPPER_BOUND = 10000;
