<template>
  <div class="role">
    <div class="heading">Education</div>
    <div v-if="props.education?.length" class="education-section">
      <div v-for="educationEntry in props.education" :key="educationEntry.education_id" class="education">
        <div class="heading-container">
          <span class="verified-title">{{ educationEntry?.school_name }} </span>
          <span class="time">{{ getEducationDateRange(educationEntry) }} </span>
        </div>
        <div class="company-tile">
          <div class="details">
            <span class="heading">{{ educationEntry.qualification_name }}</span>
          </div>
        </div>
        <div class="subheading">
          {{ educationEntry.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  education: {
    type: Array,
    required: true,
  },
});

const getEducationDateRange = singleEducation => {
  if (singleEducation.start_year === singleEducation.end_year) {
    return `${singleEducation.start_year}`;
  }
  return `${singleEducation.start_year} - ${singleEducation.end_year}`;
};
</script>

<style scoped lang="scss">
.heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.subheading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  margin-bottom: 0.25rem;
}

.heading-container {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: var(--palette-color-default-lighten-20);
  gap: 0.25rem;
}

.education {
  background-color: var(--palette-color-base-white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 8px;
}

.education-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.role {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.company-tile {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
}

.verified-title {
  display: flex;
  gap: 0.25rem;
  align-items: flex-start;
}

.details {
  display: flex;
  flex-direction: column;
}
</style>
