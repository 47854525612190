<template>
  <component
    @on-click="emit('onChangeTab', props.tab)"
    :is="props.count === 0 ? 'span' : 'router-link'"
    :to="{ name: 'talent-pool-shift-status', params: { locationId, tab: props.tab } }"
  >
    <li :class="{ active: props.active }">
      {{ props.label }}<span class="counter">{{ props.count }}</span>
    </li>
  </component>
</template>

<script setup>
import { useRoute } from 'vue-router/composables';

const route = useRoute();
const locationId = route.params.locationId;

const props = defineProps(['count', 'label', 'active', 'tab']);

const emit = defineEmits(['onChangeTab']);
</script>

<style scoped lang="scss">
li {
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--palette-color-default);
  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-color-base-white);
  border-radius: 999px;
  gap: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
  text-transform: capitalize;
}

li.active {
  font-weight: 600;
  color: var(--palette-color-base-white);
  background-color: var(--palette-color-brand-primary);

  .counter {
    color: var(--palette-color-brand-primary);
    background: var(--palette-color-base-white);
  }
}

li:not(.active):hover {
  border-color: var(--palette-color-default-lighten-80);
}

.counter {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  background: var(--palette-color-base-background);
  padding: 0.125rem 0.375rem;
  border-radius: 999px;
}
</style>
