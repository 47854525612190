<template>
  <div class="container">
    <app-loading />
  </div>
</template>

<script>
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading.vue';

export default {
  name: 'WhatsAppRedirect',
  components: { AppLoading },
  mounted() {
    tracker.trackEngagement('whatsapp_onboarding');
    this.getMobileOperatingSystem();
  },
  methods: {
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        window.location.replace(`https://play.google.com/store/apps/details?id=com.getpaydays.paydays`);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.replace(`https://apps.apple.com/gb/app/wagestream/id1363511325`);
      } else {
        window.location.replace(`https://wagestream.com/en/app-download/`);
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
