import { render, staticRenderFns } from "./BookDemo.vue?vue&type=template&id=783b0a93&scoped=true&"
import script from "./BookDemo.vue?vue&type=script&setup=true&lang=js&"
export * from "./BookDemo.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./BookDemo.vue?vue&type=style&index=0&id=783b0a93&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783b0a93",
  null
  
)

export default component.exports