<template>
  <sme-card class="account">
    <app-loading v-if="loading" />

    <template v-else>
      <div class="account__balance-caption">Current Balance</div>
      <div class="account__balance-value" v-if="!isNaN(funding.balance)">{{ toCurrency(funding.balance * 100) }}</div>
      <div class="account__balance-value" v-else>n/a</div>

      <hr class="d-none d-lg-block" />

      <p v-if="!hasPayrollAccountSetup" class="d-none d-lg-block">
        <strong class="account__highlight">Get started</strong><br />
        <span>Your dedicated Wagestream account will be created on the first pay run.</span>
      </p>
      <template v-else>
        <p class="d-none d-lg-block">
          <strong class="account__highlight">How to pay</strong><br />
          <span>Transfer to this account using the reference provided.</span>
        </p>
        <p class="d-none d-lg-block">
          <span>Account Name</span><br />
          <span class="account__highlight">{{ fundingAccountName }}</span>
        </p>
        <p class="d-none d-lg-block">
          <span>Sort Code</span><br />
          <span class="account__highlight">{{ fundingSortCode }}</span>
        </p>
        <p class="d-none d-lg-block">
          <span>Account Number</span><br />
          <span class="account__highlight">{{ fundingAccountNumber }}</span>
        </p>
      </template>
    </template>
  </sme-card>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';

export default {
  name: 'PayWithWagestreamAccount',
  components: {
    AppLoading,
    SmeCard,
  },
  data() {
    return {
      toCurrency,
      error: undefined,
      loading: true,
      funding: null,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      this.funding = await ApiClient.companyPayrollBalance(State.state.company.company_id);
      this.funding = this.funding?.modulr >= 0 ? { balance: this.funding?.modulr / 100.0 } : { balance: NaN };
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    hasPayrollAccountSetup() {
      return State.state.company.properties?.modulr?.payroll_sort_code;
    },
    fundingAccountName() {
      return State.state.company.properties.modulr.details.name;
    },
    fundingSortCode() {
      return State.state.company.properties?.modulr?.payroll_sort_code || 'XX-XX-XX';
    },
    fundingAccountNumber() {
      return State.state.company.properties?.modulr?.payroll_account_number || 'XXXXXXXX';
    },
  },
};
</script>

<style scoped lang="scss">
.account__highlight {
  color: var(--palette-color-brand-primary);
}

.account hr {
  margin-right: -32px;
  margin-left: -32px;
}

.account__balance-value {
  text-align: right;
  font-weight: bold;
  font-size: 40px;
  color: var(--palette-color-brand-primary);
  @media (max-width: 768px) {
    text-align: left;
  }
}

.account__balance-caption {
  margin-top: 12px;
  margin-bottom: -8px;
  text-align: right;
  font-size: 20px;
  @media (max-width: 768px) {
    text-align: left;
  }
}
</style>
