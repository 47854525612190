<template>
  <b-form-group
    v-bind="formGroupProps"
    :description="description"
    :label="label"
    :label-class="labelClass"
    :label-for="name"
    class="app-input"
    :class="[maskValid && 'app-input--mask-valid', plaintext && 'app-input--plaintext', row && 'app-input--row']"
  >
    <template v-if="$slots.label" #label><slot name="label"></slot></template>

    <ValidationProvider
      v-if="!plaintext"
      v-slot="v"
      :immediate="validateImmediate"
      :name="name"
      :rules="rules"
      :vid="vid"
      ref="$validationProvider"
    >
      <b-input-group :prepend="prepend" :append="append">
        <component
          :is="controlComponent"
          v-bind="getControlProps(v)"
          v-model="controlValue"
          v-on="$listeners"
          :class="controlClass"
        >
          <template v-if="type === CONTROL_TYPES.SELECT" #first>
            <option :value="undefined" :disabled="!undefinedAllow">{{ undefinedLabel }}</option>
          </template>
          <template v-if="defaultSlotPosition === DEFAULT_SLOT_POSITION.COMPONENT">
            <slot name="default"></slot>
          </template>
        </component>

        <template v-if="defaultSlotPosition === DEFAULT_SLOT_POSITION.AFTER_COMPONENT">
          <slot name="default"></slot>
        </template>

        <template v-if="type === CONTROL_TYPES.PASSWORD" #append>
          <b-btn
            variant="default"
            class="app-input__append-btn"
            title="Toggle password visibility"
            @click="togglePasswordType"
          >
            <font-awesome-icon :icon="['far', 'eye']" />
          </b-btn>
        </template>

        <template v-for="(_, slotName) in controlSlots">
          <slot :name="slotName" :slot="slotName"></slot>
        </template>
      </b-input-group>
      <b-form-invalid-feedback v-for="error in v.errors" :key="error" class="app-input__feedback">
        {{ error }}
      </b-form-invalid-feedback>
    </ValidationProvider>
    <div v-else class="app-input__plaintext">{{ plaintextFormatter?.(value) || value }}</div>
  </b-form-group>
</template>

<script>
export const CONTROL_TYPES = {
  CHECKBOX: 'checkbox',
  CHECKBOX_SINGLE: 'checkbox-single',
  DATE: 'date',
  TIME: 'time',
  EMAIL: 'email',
  INPUT: 'input',
  NUMBER: 'number',
  PASSWORD: 'password',
  RADIO: 'radio',
  SELECT: 'select',
  TEL: 'tel',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

const PERMITTED_CONTROL_TYPES = Object.values(CONTROL_TYPES);

const CHECKED_CONTROL_TYPES = [CONTROL_TYPES.CHECKBOX, CONTROL_TYPES.CHECKBOX_SINGLE, CONTROL_TYPES.RADIO];

export const DEFAULT_SLOT_POSITION = {
  COMPONENT: 'COMPONENT',
  AFTER_COMPONENT: 'AFTER_COMPONENT',
};

export const isCheckedControlType = type => CHECKED_CONTROL_TYPES.includes(type);

export const isSingleCheckedControlType = type => isCheckedControlType(type) && type.includes('single');

export default {
  inheritAttrs: false,
};
</script>

<script setup>
import omit from 'lodash/omit';
import { ValidationProvider } from 'vee-validate';
import { computed, ref, useAttrs, useListeners, useSlots } from 'vue';
import SmeFormDatepicker from '@/components/atoms/SmeFormDatepicker.vue';
import SmeFormTimepicker from '@/components/atoms/SmeFormTimepicker.vue';

const TYPE_COMPONENTS = {
  [CONTROL_TYPES.CHECKBOX]: 'b-form-checkbox-group',
  [CONTROL_TYPES.CHECKBOX_SINGLE]: 'b-form-checkbox',
  [CONTROL_TYPES.DATE]: SmeFormDatepicker,
  [CONTROL_TYPES.TIME]: SmeFormTimepicker,
  [CONTROL_TYPES.RADIO]: 'b-form-radio-group',
  [CONTROL_TYPES.SELECT]: 'b-form-select',
  [CONTROL_TYPES.TEXTAREA]: 'b-form-textarea',
};

const $attrs = useAttrs();
const $listeners = useListeners();
const $slots = useSlots();

const props = defineProps({
  value: [String, Boolean, Number, Array, Object],
  type: {
    type: String,
    validator: value => PERMITTED_CONTROL_TYPES.includes(value),
    default: CONTROL_TYPES.TEXT,
  },
  name: String,
  label: String,
  description: String,
  prepend: String,
  append: String,
  rules: [Object, String],
  vid: String,
  extraState: {
    type: [Object, Boolean],
    default: undefined,
  },
  undefinedAllow: Boolean,
  undefinedLabel: {
    type: String,
    default: '-- Please select an option --',
  },
  controlClass: String,
  highlightChanged: Boolean,
  maskValid: Boolean,
  plaintext: Boolean,
  plaintextFormatter: Function,
  row: Boolean,
  validateImmediate: {
    type: Boolean,
    default: true,
  },
  defaultSlotPosition: {
    type: String,
    default: DEFAULT_SLOT_POSITION.AFTER_COMPONENT,
  },
});

const emit = defineEmits(['input']);

const $validationProvider = ref(undefined);
const passwordType = ref(props.type);

const formGroupProps = computed(() => {
  if (props.row) {
    return {
      'content-cols-md': 6,
      'label-cols-md': 6,
      'content-cols-lg': 7,
      'label-cols-lg': 5,
    };
  }

  return null;
});

const labelClass = computed(
  () =>
    `app-input__label ${
      props.highlightChanged && $validationProvider.value?.flags?.changed ? 'app-input__label--changed' : ''
    }`,
);

const controlClass = computed(() => `app-input__control ${props.controlClass || ''}`);

const controlComponent = computed(() => TYPE_COMPONENTS[props.type] || 'b-form-input');

const controlSlots = computed(() => omit($slots, ['default', 'label']));

const controlType = computed(() => {
  if (props.type === CONTROL_TYPES.CHECKBOX_SINGLE) {
    return CONTROL_TYPES.CHECKBOX;
  } else if (props.type === CONTROL_TYPES.PASSWORD) {
    return passwordType.value;
  }

  return props.type;
});

const controlValue = computed({
  get() {
    return props.value;
  },
  set(value) {
    $validationProvider.value.validate(value);
    emit('input', value);
  },
});

const getControlProps = v => {
  const controlProps = {
    ...$attrs,
    id: props.name,
    state: getControlState(v),
    type: controlType.value,
  };

  if (isCheckedControlType(props.type)) {
    controlProps.checked = props.value;
  } else {
    controlProps.value = props.value;
  }

  return controlProps;
};

const getControlState = v => {
  if (props.extraState === false) {
    return false;
  }

  if (props.rules && (v.dirty || v.validated)) {
    return v.valid ? (props.maskValid ? null : true) : false;
  }

  return null;
};

const togglePasswordType = () =>
  (passwordType.value = passwordType.value === CONTROL_TYPES.PASSWORD ? CONTROL_TYPES.TEXT : CONTROL_TYPES.PASSWORD);
</script>

<style lang="scss" scoped>
.app-input--plaintext {
  :deep(.app-input__label) {
    @media (max-width: 768px) {
      margin-bottom: 0.4rem * 0.5 !important;
    }
  }
}

.app-input--row {
  margin-bottom: 1rem;

  &::after {
    border-bottom: 1px solid var(--palette-color-default-lighten-90);
    content: '';
    display: block;
    margin: 0 (1rem * 0.5) 0;
    padding-top: 1rem;
    width: calc(100% - 1rem);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  :deep(.app-input__label) {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  @media (min-width: 768px) {
    :deep(.app-input__label) {
      margin: 0;
    }

    .app-input__plaintext {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    :deep(.app-input__label) {
      margin-bottom: 0.4rem;
    }
  }
}

:deep(.app-input__control[type='radio']) {
  .btn-outline-primary:disabled:active,
  .btn-outline-primary.disabled.active {
    background-color: var(--palette-color-brand-primary);
    border-color: var(--palette-color-brand-primary);
    color: var(--palette-color-base-white);
  }
}

:deep(.app-input__label) {
  color: var(--palette-color-default-lighten-20);
  font-weight: 500;

  > small {
    color: var(--palette-color-default-lighten-30);
    display: block;
    font-style: normal;
    margin-top: 0.1rem;
  }
}

:deep(.app-input__label--changed) {
  font-style: italic;
  font-weight: 700;

  &::before {
    content: '*';
  }
}

.app-input__append-btn {
  padding-left: 0;
  padding-right: 0;
  width: 2.5rem;
}

.app-input__feedback {
  display: block !important;
}
</style>
