import ApiClient from '@/ApiClient';
import { useStateComposable } from '@/state/composables';

const { computedState, state, reset } = useStateComposable({
  locations: [],
  errorLocations: false,
  initialLocations: false,
  loadingLocations: false,
});

const getLocations = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initialLocations) {
    return;
  }

  reset(['locations', 'errorLocations', 'initialLocations', 'loadingLocations']);

  state.initialLocations = false;
  state.loadingLocations = true;

  try {
    state.locations = ((await ApiClient.getTalentPoolLocations())?.data || []).map(location => ({
      ...location,
      invalid: !!location.properties?.postcode,
    }));
  } catch (error) {
    state.errorLocations = true;
    console.error(error);
  }

  state.loadingLocations = false;
};

export default () => {
  return {
    ...computedState,
    getLocations,
  };
};
