<template>
  <div class="terms-container">
    <h4>Amendment</h4>
    <p>
      This Text Services Amendment amends the agreement between Wagestream and the Employer whose dashboard this
      document is accessed through (“Agreement”) pursuant to which Wagestream provides a service which allows employees
      of the Employer to access their earned wages in advance of the normal pay day and access other financial tools and
      services via the Wagestream App.
    </p>
    <p>
      The Employer has requested that Wagestream sends, on its behalf, SMS or WhatsApp communications to employees of
      the Employer who have not enrolled in the Wagestream App to make them aware of and encourage them to use it (“Text
      Services”) and the Parties have agreed to amend the Agreement as set out herein and further that this is an
      effective amendment to the Agreement notwithstanding paragraph 13.5 of the Agreement.
    </p>
    <p>With effect from the date of this Amendment, the following amendments shall apply:</p>
    <ul>
      <li>
        The definition of “Awareness Plan” in Schedule 1 shall be amended to explicitly include “SMS or WhatsApp
        communications”;
      </li>
      <li>
        The definition of “Contact Information” in Schedule 1 shall be amended to include “personal and/or work mobile
        telephone numbers”; and
      </li>
      <li>
        A new paragraph 12.3.1.4. shall be included as follows: “12.3.1.4. Wagestream’s use of Contact Information in
        accordance with this Agreement.”.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WhatsAppTermsAmendmentV1',
};
</script>

<style scoped>
.terms-container {
  padding: 2rem;
}
</style>
