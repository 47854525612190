<template>
  <app-page
    title="Add Shift"
    subtitle="Add a single shift for a single employee"
    icon="calendar-circle-plus"
    :previous="[{ label: 'Shifts', to: { name: 'shifts' } }]"
  >
    <ValidationObserver v-slot="v" ref="form">
      <sme-alert level="info" class="mb-3">
        If you need to add a lot of shifts, do a
        <router-link :to="{ name: 'sme-shifts-upload' }">bulk import</router-link> instead.
      </sme-alert>

      <p v-if="!canAddSingleShift || !canAddShifts">
        You do not have the ability to add a single shift enabled. Please contact your account manager for questions.
      </p>

      <b-form @submit.prevent="v.handleSubmit(onSubmit)" v-if="canAddShifts && canAddSingleShift">
        <app-input
          v-model="shift.worked_on"
          rules="required"
          :extraState="!invalidDuplicated"
          :validate-immediate="false"
          type="date"
          name="worked_on"
          label="Date"
          @input="onWorkedOnInput"
        >
          <b-form-invalid-feedback v-if="invalidDuplicated">
            Another shift has already been added for this employee on this date
          </b-form-invalid-feedback>
        </app-input>
        <app-input
          v-model="shift.employee_id"
          rules="required"
          :options="employeesValues"
          :validate-immediate="false"
          type="select"
          name="employee_id"
          label="Employee"
        />
        <app-input
          v-model="shift.hours"
          :validate-immediate="false"
          step=".01"
          rules="required|min_value:0"
          type="number"
          name="hours"
          label="Hours Worked"
        />
        <app-input
          v-model="shift.rate"
          rules="required|min_value:0"
          type="number"
          step="0.01"
          name="wage"
          label="Gross Pay"
          prepend="£"
          :validate-immediate="false"
        />

        <page-sub-footer>
          <template #before>
            <sme-alert v-if="success && !v.dirty" level="good" class="mb-3">Shift added</sme-alert>
            <sme-alert v-else-if="error" level="danger" class="mb-3">{{ error }}</sme-alert>
          </template>
          <b-button type="submit" variant="primary" :disabled="v.invalid || saving">
            <b-spinner v-if="saving" class="mr-2" small />
            {{ saving ? 'Adding...' : 'Add' }}
          </b-button>
        </page-sub-footer>
      </b-form>
    </ValidationObserver>
  </app-page>
</template>

<script>
import uuidv4 from 'uuid/v4';
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppInput from '@/components/AppInput.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

const defaultShift = {
  worked_on: undefined,
  employee_id: null,
  hours: undefined,
  rate: undefined,
};

export default {
  name: 'ShiftAdd',
  components: { ValidationObserver, AppInput, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      error: undefined,
      invalidDuplicated: undefined,
      employees: [],
      shift: { ...defaultShift },
      saving: false,
      success: false,
      state: State.state,
      veeDictionary: {
        en: {
          attributes: {
            worked_on: 'Date',
            employee_id: 'Employee',
            hours: 'Hours Worked',
            rate: 'Hourly Rate',
          },
        },
      },
    };
  },
  computed: {
    employeesValues() {
      return this.employees
        .map(e => ({ value: e.employee_id, text: e.full_name }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  async mounted() {
    try {
      const employees = await ApiClient.getEmployees();
      this.employees = employees.employees;
    } catch (error) {
      this.error = error.message;
    }
  },
  methods: {
    async onSubmit() {
      this.saving = true;
      await tracker.trackEvent('sme_live_add_shifts');

      const newShift = {
        worked_on: this.shift.worked_on,
        employee_id: this.shift.employee_id,
        shift_id: uuidv4(),
        hours: parseFloat(this.shift.hours),
        //rate: ratePence,
        currency: 'GBP',
        // For SME favour gross salary over hourly rate to be consistent with bulk upload
        wages: parseFloat(this.shift.rate).toFixed(2),
      };

      try {
        await ApiClient.smeAddSingleShift(newShift);
        this.success = true;
        this.shift = { ...defaultShift };
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
        this.invalidDuplicated = false;
      } catch (error) {
        if (error.message.startsWith('Duplicate detected')) {
          this.invalidDuplicated = true;
        } else {
          this.error = error.message;
        }
      } finally {
        this.saving = false;
      }
    },
    onWorkedOnInput() {
      this.invalidDuplicated = false;
    },
  },
};
</script>

<script setup>
import useFeatureFlags from '@/composables/useFeatureFlags';

const { canAddShifts, canAddSingleShift } = useFeatureFlags();
</script>
