<template>
  <app-page title="Funds" subtitle="Manage the float available for salary advances" :error="error" icon="university">
    <template v-slot:custom-content>
      <b-container fluid>
        <b-row align-v="stretch">
          <!-- Account Details -->
          <b-col lg="4" order="1" order-lg="2" class="mb-4">
            <funding-account :show-withdraw-link="false" />
          </b-col>

          <!-- Tables -->
          <b-col lg="8" order="2" order-lg="1">
            <sme-card>
              <b-tabs v-model="currentTab" pills fill no-fade>
                <div v-if="loading" class="loading">
                  <app-loading />
                </div>

                <!-- Transactions Card -->
                <b-tab title="Transactions" content-class="mt-3" active>
                  <table v-if="!loading" class="card__table">
                    <tr v-if="dataTransactions.content.length === 0" class="transaction--empty">
                      <td class="card__table-col">No transactions.</td>
                    </tr>
                    <tr
                      v-for="item in dataTransactions.content"
                      class="transaction card__table-row"
                      :key="`${item.owner}${item.type}${item.credit}${item.amount}`"
                    >
                      <td class="card__table-col card__table-col--icon">
                        <font-awesome-icon
                          size="2x"
                          color="var(--palette-color-core-1-lighten-30)"
                          :icon="getTransactionIcon(item)"
                        />
                      </td>
                      <td class="card__table-col">
                        <div class="transaction__description">
                          {{ item.count }}x
                          {{
                            item.type === 'deposit'
                              ? 'Deposited funds'
                              : item.type === 'withdrawal'
                              ? 'Withdrew funds'
                              : item.type === 'stream'
                              ? 'Salary advance'
                              : item.type === 'reconcile'
                              ? 'Reconciliation'
                              : ''
                          }}
                        </div>
                        <div class="transaction__month">
                          {{ item.date }}
                        </div>
                      </td>
                      <td
                        :class="[
                          'card__table-col',
                          'transaction__amount',
                          { 'transaction__amount--minus': !item.credit },
                          { 'transaction__amount--plus': item.credit },
                        ]"
                      >
                        {{ toCurrency(item.amount * 100) }}
                      </td>
                    </tr>
                  </table>
                </b-tab>
              </b-tabs>
            </sme-card>

            <b-pagination
              v-if="!loading && currentRows < totalRows"
              class="pagination"
              v-model="currentPage"
              :per-page="currentRows"
              :total-rows="totalRows"
            />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </app-page>
</template>

<script>
import * as moment from 'moment';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import AppPage from '@/components/AppPage';
import FundingAccount from '@/components/FundingAccount';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';

const Tab = {
  TRANSACTIONS: 0,
  SALARIES: 1,
  STATEMENTS: 2,
};

export default {
  name: 'FundingDetails',
  components: {
    FundingAccount,
    AppLoading,
    AppPage,
    SmeCard,
  },
  data() {
    return {
      moment,
      toCurrency,
      loading: true,
      error: undefined,
      funding: undefined,
      currentTab: 0,
      currentRows: 20,
      currentPage: 1,
      dataTransactions: {
        page: 0,
        size: 0,
        totalPages: 0,
        totalSize: 0,
        content: [],
      },
      dataSalaries: {
        total: 0,
        items: [],
      },
      dataStatements: {
        total: 0,
        items: [],
      },
    };
  },
  async mounted() {
    await ApiClient.getUser();

    const { tasks } = await ApiClient.smeCompanyGetTaskList(State.state.company.company_id);
    const kycStatus = tasks.find(t => t.code === 'VERIFY_IDENTITY')?.status;
    this.funding = kycStatus === 'COMPLETE' ? await State.getFunding() : null;
    await this.fetchCurrentPage();
  },
  computed: {
    totalRows() {
      switch (this.currentTab) {
        case Tab.TRANSACTIONS: {
          return this.dataTransactions.totalSize;
        }
        case Tab.SALARIES: {
          return this.dataSalaries.total;
        }
        case Tab.STATEMENTS: {
          return this.dataStatements.total;
        }
        default: {
          return 0;
        }
      }
    },
  },
  watch: {
    async currentTab() {
      this.currentPage = 1;
      await this.fetchCurrentPage();
    },
    async currentPage() {
      await this.fetchCurrentPage();
    },
  },
  methods: {
    getTransactionIcon(tx) {
      switch (tx.type) {
        case 'reconcile': {
          return ['fal', 'sync'];
        }
        case 'stream': {
          return ['far', 'coins'];
        }
        case 'deposit': {
          return ['fal', 'plus-circle'];
        }
        case 'withdrawl': {
          return ['fal', 'minus-circle'];
        }
        default: {
          return ['fad', 'file-invoice-dollar'];
        }
      }
    },
    async fetchCurrentPage() {
      try {
        this.loading = true;
        switch (this.currentTab) {
          case Tab.TRANSACTIONS: {
            await this.fetchTransactions();
            break;
          }
          default: {
            break;
          }
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async fetchTransactions() {
      if (this.funding?.enabled) {
        try {
          const data = await ApiClient.smeGetSelfFundedTransactions(
            State.state.company.company_id,
            this.currentPage - 1,
            this.currentRows,
          );

          const lookup = {};
          const order = [];

          data.content.forEach(item => {
            item._formattedDate = moment(item.transactionDate).format('MMMM DD');
            if (item.credit) {
              if (item.type === 'INT_INTERC') {
                item._type = 'reconcile';
              } else {
                item._type = 'deposit';
              }
            } else {
              // FIXME: need to detect withdrawls somehow???
              item._type = 'stream';
            }

            if (!(item._formattedDate in lookup)) {
              lookup[item._formattedDate] = {};
              order.push(item._formattedDate);
            }

            const datum = lookup[item._formattedDate];
            if (!(item._type in datum)) {
              datum.date = item._formattedDate;
              datum[item._type] = { amount: 0.0, count: 0 };
            }
            datum[item._type].amount += item.amount;
            datum[item._type].count += 1;
          });

          data.content = [];
          order.forEach(item => {
            const obj = lookup[item];
            ['deposit', 'withdraw', 'stream', 'reconcile'].forEach(type => {
              if (type in obj) {
                const credit = type === 'deposit' || type === 'reconcile';
                const owner = type === 'deposit' || type === 'withdraw';

                data.content.push({
                  date: item,
                  type: type,
                  amount: obj[type].amount * (credit ? 1 : -1),
                  count: obj[type].count,
                  credit: credit,
                  owner: owner,
                });
              }
            });
          });
          this.dataTransactions = data;
        } catch {
          console.error('Failed to get self funded transactions.');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  margin-top: 16px;
}
.card__table {
  display: flex;
  color: var(--palette-color-default-lighten-30);
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
}

.card__table-row {
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
}

.card__table-row:nth-child(odd) {
  background: var(--palette-color-default-lighten-95);
}

.card__table-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 26px;
  @media (max-width: 576px) {
    margin-right: 18px;
  }
}

.card__table-col:last-child {
  margin-right: 0;
  margin-left: auto;
}

.card__table-col--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
}

.loading,
.transaction--empty,
.salary--empty,
.statement--empty {
  font-size: 24px;
  text-align: center;
  padding: 32px;
  color: var(--palette-color-default-lighten-80);
}

.transaction__description,
.salary__month {
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 576px) {
    font-size: 13px;
  }
}

.statement__title,
.statement__description,
.transaction__month,
.salary__description {
  text-transform: capitalize;
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 13px;
  }
}

.statement__title {
  font-weight: bold;
}

.transaction__amount,
.salary__total-salaries {
  font-size: 18px;
  @media (max-width: 576px) {
    font-size: 13px;
  }
}

.transaction__icon--plus,
.statement__icon,
.salary__icon {
  color: var(--palette-color-core-5);
}

.salary__total-salaries,
.transaction__amount--plus {
  color: var(--palette-color-core-1-lighten-30);
}

.transaction__icon--minus,
.transaction__amount--minus {
  color: var(--palette-color-core-1-lighten-30);
}

.statement__buttons {
  flex-direction: row;
  align-items: center;
}

.statement__download {
  font-weight: bold;
  background: var(--palette-color-core-5);
  color: var(--palette-color-core-2);
  @media (max-width: 576px) {
    padding: 8px;
  }
}

.statement__download + .statement__download {
  margin-left: 8px;
}
</style>
