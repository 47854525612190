<template>
  <Modal :title="`Add a ${isSite ? 'site' : 'brand'}`" :modalId="props.modalId || 'add-division-modal'" ref="modalRef">
    <div class="body">
      <ImagesHeader :cover-url="coverUrl" :logo-url="logoUrl" canEdit @on-upload="onUpload" />
      <div class="form-container">
        <div class="input-title">Name</div>
        <b-form-input
          class="input-section"
          v-model="divisionName"
          :placeholder="`${isSite ? 'Site' : 'Brand'} name`"
        ></b-form-input>

        <template v-if="isSite">
          <div class="input-title">Address</div>
          <b-form-input
            class="input-section"
            v-model="address.address_line_1"
            placeholder="Site address"
          ></b-form-input>

          <div class="input-title">City</div>
          <b-form-input class="input-section" v-model="address.city" placeholder="City"></b-form-input>

          <div class="input-title">Postcode</div>
          <b-form-input
            class="input-section"
            v-model="address.postcode"
            :state="isPostcodeValid"
            placeholder="Enter postcode"
          ></b-form-input>
        </template>
      </div>
      <div class="input-title">Description</div>
      <b-form-input class="input-section" v-model="description" placeholder="Description"></b-form-input>

      <!-- <div class="input-title">Tags</div> -->
      <div v-if="error" class="error">Could not update details. Please try again or contact your admin.</div>
    </div>
    <template #modal-footer>
      <b-button @click="onClose" variant="outline-primary">Cancel</b-button>
      <b-button :disabled="!minimumRequirements" @click="onCreate" variant="primary"
        >Save new {{ isSite ? 'site' : 'brand' }}</b-button
      >
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref } from 'vue';
import Modal from '@/components/Modal.vue';
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import uuidv4 from 'uuid/v4';

const modalRef = ref(null);

const divisionName = ref('');
const description = ref('');
const address = ref({});
const coverUrl = ref('');
const logoUrl = ref('');

const error = ref(false);

const emit = defineEmits(['on-close']);

const companyId = State.state.company.company_id;

const props = defineProps(['locationId', 'modalId', 'isBrand']);

const isSite = computed(() => !props.isBrand);

const minimumRequirements = computed(() => {
  if (!isSite.value) {
    return !!divisionName.value;
  }
  return !!divisionName.value && !!address.value && !!address.value.postcode && isPostcodeValid.value;
});

const isPostcodeValid = computed(() => {
  const postcodeRegex = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i;
  return address.value.postcode ? postcodeRegex.test(address.value.postcode) : undefined;
});

const onUpload = (url, type) => {
  if (type === 'logo') {
    logoUrl.value = url;
  } else {
    coverUrl.value = url;
  }
};

const onCreate = async () => {
  if (!isSite.value) {
    try {
      await ApiClient.postOrganisationalGroup({
        company_id: companyId,
        parent_id: companyId,
        properties: {
          logo_url: logoUrl.value || undefined,
          cover_image_url: coverUrl.value || undefined,
          description: description.value,
        },
        name: divisionName.value,
      });

      onClose();
    } catch (e) {
      error.value = true;
    }
  } else {
    try {
      await ApiClient.addDivision({
        name: divisionName.value,
        parent_id: props.locationId,
        division_id: uuidv4(),
        company_id: companyId,
        properties: {
          address: {
            postcode: address.value.postcode.toUpperCase(),
            address_line_1: address.value.address_line_1,
            city: address.value.city,
          },
          description: description.value,
          logo_url: logoUrl.value || undefined,
          cover_image_url: coverUrl.value || undefined,
        },
      });

      onClose();
    } catch (e) {
      error.value = true;
    }
  }
};

const onClose = async () => {
  error.value = false;
  emit('on-close');
  modalRef.value.modal.hide();
};
</script>

<style scoped lang="scss">
.body {
  padding: 1rem;
}

.input-section {
  margin-bottom: 1rem;
}

.input-title {
  font-size: 14px;
  font-weight: 600;
  margin: 6px;
}

.modal-title {
  font-weight: 600;
}

.override-button {
  margin: 10px 0;
}

.form-container {
  margin-top: 4rem;
}

.error {
  border: 1px solid var(--palette-color-danger);
  border-radius: 12px;
  background-color: var(--palette-color-danger-lighten-90);
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
}
</style>
