<template>
  <div class="shift-stat__wrapper">
    <app-stat
      :stat="currentShiftNumber"
      :previous-stat="lastMonthShiftNumber"
      description="Shifts this month"
      class="shift-stat"
    ></app-stat>
    <app-stat
      :stat="currentShiftHours"
      :previous-stat="lastMonthShiftHours"
      :formatter="getNumberFormatter(currentShiftHours)"
      description="Hours this month"
      class="shift-stat"
    />
    <app-stat
      :stat="currentShiftValue"
      :previous-stat="lastMonthShiftValue"
      description="Value this month"
      :formatter="getCurrencyFormatter(currentShiftValue)"
      class="shift-stat"
    />
  </div>
</template>

<script setup>
import moment from 'moment';
import { ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppStat from '@/components/AppStat.vue';
import { toCurrency, toCompactCurrency } from '@/utils';
import { toNumber, toInteger } from '@/utils/common';

const thisMonthStartDate = moment().startOf('month').format('YYYY-MM-DD');
const lastMonthStartDate = moment(thisMonthStartDate).subtract(1, 'months').format('YYYY-MM-DD');
const thisDateLastMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');

const currentShiftValue = ref(undefined);
const currentShiftNumber = ref(undefined);
const currentShiftHours = ref(undefined);

const lastMonthShiftValue = ref(undefined);
const lastMonthShiftNumber = ref(undefined);
const lastMonthShiftHours = ref(undefined);

const getNumberFormatter = value => {
  return value >= 10000 ? toInteger : toNumber;
};

const getCurrencyFormatter = value => {
  return value > 1000000 ? toCompactCurrency : toCurrency;
};

const getShiftSummaryData = async () => {
  const thisMonthQuery = { startDate: thisMonthStartDate, endDate: null };
  const lastMonthQuery = { startDate: lastMonthStartDate, endDate: thisDateLastMonth };

  await ApiClient.getPaginatedShifts(thisMonthQuery).then(({ data }) => {
    currentShiftNumber.value = data.count.count || 0;
    currentShiftHours.value = data.total_hours || 0;
    currentShiftValue.value = data.total_wages;
  });

  await ApiClient.getPaginatedShifts(lastMonthQuery).then(({ data }) => {
    lastMonthShiftNumber.value = data.count.count;
    lastMonthShiftHours.value = data.total_hours;
    lastMonthShiftValue.value = data.total_wages;
  });
};

getShiftSummaryData();
</script>

<style lang="scss" scoped>
.shift-stat__wrapper {
  display: flex;
  gap: 1.5rem;
}

.shift-stat {
  flex: 1;
}
</style>
