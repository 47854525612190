<template>
  <section>
    <page-list-header>
      <template #left>
        <filter-dropdown v-model="filters" :filters="filterItems">
          <inline-search v-model="searchText" @search="onSearch" />
        </filter-dropdown>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
      </template>
    </page-list-header>
    <sme-card large>
      <app-loading :loading="loading" />
      <template v-if="!loading">
        <navigable-tabs content-class="mb-1 mt-4 px-2">
          <b-tab v-for="tab in tabs" :key="tab.status" :id="tab.status">
            <template #title>
              <h4 class="d-flex align-items-center">
                {{ tab.statusName }}
                <b-badge v-if="tab.shifts.length" :variant="tab.highlight ? 'primary' : 'secondary'" class="ml-2">
                  {{ tab.shifts.length }}
                </b-badge>
              </h4>
            </template>
            <flexible-work-shift-list
              :claims="claims"
              :shifts="tab.shifts"
              :status-name="tab.statusName"
              :per-page="perPage"
              @updated="getShiftsAndClaims"
            />
          </b-tab>
        </navigable-tabs>
      </template>
    </sme-card>
  </section>
</template>

<script setup>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import NavigableTabs from '@/components/NavigableTabs.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import FlexibleWorkShiftList from '@/pages/flexible-work/components/FlexibleWorkShiftList.vue';
import State from '@/state/State';
import useRolesAndLocations from '@/state/composables/useRolesAndLocations';
import { toTitleCase } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';
import debounceAsync from '@/utils/debounceAsync';
import { CLAIM_SEARCH_FIELDS, SHIFT_SEARCH_FIELDS } from '@/utils/flexible-work';
import { SHIFT_STATUS, getShiftStatus } from '@/utils/flexible-work/Shift';

const { isEnableFlexibleWorkManagerShiftSignOff } = useFeatureFlags();
const { roles, locations, getRolesAndLocations } = useRolesAndLocations();

const STATUS_TABS = [
  SHIFT_STATUS.PENDING,
  SHIFT_STATUS.UNCLAIMED,
  SHIFT_STATUS.APPROVED,
  SHIFT_STATUS.WORKED,
  SHIFT_STATUS.FULFILLED,
  SHIFT_STATUS.UNFILLED,
];

const FILTER_KEYS = {
  ROLE: 'roles',
  LOCATION: 'location_id',
  START: 'start',
  END: 'end',
};

const PER_PAGE_OPTIONS = [25, 50, 100];

const START_DEFAULT_VALUE = toFormattedDate(moment().add(-1, 'week'));
const END_DEFAULT_VALUE = toFormattedDate(moment().add(1, 'week'));

const $router = useRouter();
const $route = useRoute();

const shifts = ref([]);
const filteredShifts = ref([]);
const claims = ref({});
const filters = ref({
  [FILTER_KEYS.START]: START_DEFAULT_VALUE,
  [FILTER_KEYS.END]: END_DEFAULT_VALUE,
});
const searchCriteria = ref('');
const searchText = ref('');
const initialized = ref(false);
const loading = ref(true);
const perPage = ref(PER_PAGE_OPTIONS[0]);
const isTabSetFromStatus = ref(false);

const tabs = computed(() => {
  return Object.values(STATUS_TABS).map(status => {
    const statusShifts = getFilteredShiftsByStatus(status);

    return {
      highlight: highlightedTabs.value.includes(status) && !!statusShifts.length,
      shifts: statusShifts,
      status,
      statusName: toTitleCase(status),
    };
  });
});

const highlightedTabs = computed(() => {
  const highlightedTabs = [SHIFT_STATUS.PENDING];

  if (canUserFulfillShift.value) {
    highlightedTabs.push(SHIFT_STATUS.APPROVED, SHIFT_STATUS.WORKED);
  }

  return highlightedTabs;
});

const filterItems = computed(() => [
  {
    key: FILTER_KEYS.START,
    label: 'Start',
    type: CONTROL_TYPES.DATE,
    defaultValue: START_DEFAULT_VALUE,
    controlProps: {
      max: filters.value[FILTER_KEYS.END] ? filters.value[FILTER_KEYS.END] : null,
    },
  },
  {
    key: FILTER_KEYS.END,
    label: 'End',
    type: CONTROL_TYPES.DATE,
    defaultValue: END_DEFAULT_VALUE,
    controlProps: {
      min: filters.value[FILTER_KEYS.START] ? filters.value[FILTER_KEYS.START] : null,
    },
  },
  {
    key: FILTER_KEYS.ROLE,
    label: 'Role',
    type: CONTROL_TYPES.CHECKBOX,
    options: roles.value.map(role => ({
      text: role.name,
      value: role.name,
    })),
    hidden: roles.value.length <= 1,
  },
  {
    key: FILTER_KEYS.LOCATION,
    label: 'Location',
    type: CONTROL_TYPES.RADIO,
    options: locations.value.map(location => ({
      text: location.name,
      value: location.division_id,
    })),
    hidden: locations.value.length <= 1,
  },
]);

const apiFilters = computed(() =>
  omitBy(
    {
      ...filters.value,
      ...(filters.value.roles ? { roles: filters.value.roles.join(',') } : null),
    },
    isNil,
  ),
);

const canUserFulfillShift = computed(
  () => State.state.claims?.a || (State.state.claims?.m && isEnableFlexibleWorkManagerShiftSignOff.value),
);

watch(searchCriteria, () => setFilteredShiftsAndClaims());

const getShiftsAndClaims = debounceAsync(async () => {
  loading.value = true;
  shifts.value = (await ApiClient.getFlexibleWorkShifts(apiFilters.value))?.shifts || [];
  claims.value = groupBy((await ApiClient.getFlexibleWorkClaims(filters.value))?.claims || [], 'shift_id');

  setFilteredShiftsAndClaims();

  loading.value = false;
}, 200);

const setFilteredShiftsAndClaims = () => {
  if (searchCriteria.value) {
    filteredShifts.value = shifts.value.filter(shift => {
      const shiftClaims = claims.value[shift.shift_id] || [];
      const shiftMatch = doSearch(shift, SHIFT_SEARCH_FIELDS);
      const claimMatch = shiftClaims.some(claim => doSearch(claim, CLAIM_SEARCH_FIELDS));

      return shiftMatch || claimMatch;
    });

    return;
  }

  filteredShifts.value = shifts.value;
};

const getFilteredShiftsByStatus = status =>
  filteredShifts.value.filter(shift => getShiftStatus(shift, claims.value[shift.shift_id]) === status);

const doSearch = (entity, fields) =>
  Object.values(pick(entity, fields)).some(value => value.toLowerCase().includes(searchCriteria.value.toLowerCase()));

const onSearch = () => (searchCriteria.value = searchText.value);

const setInitialTab = () => {
  const query = cloneDeep($route.query);

  if (isTabSetFromStatus.value || (query.tab && query.tab !== STATUS_TABS[0])) {
    return;
  }

  const initialTab = tabs.value.find(tab => !!tab.shifts.length);

  if (initialTab) {
    query.tab = initialTab.status;
  }

  if (!isEqual($route.query, query)) {
    $router.replace({ query });
  }
};

const setTabFromStatus = () => {
  let query = cloneDeep($route.query);
  const status = query.status;

  if (status) {
    query = omit(query, 'status');

    if (STATUS_TABS.includes(status)) {
      query.tab = status;
      isTabSetFromStatus.value = true;
    }
  }

  if (!isEqual($route.query, query)) {
    $router.replace({ query });
  }
};

const trackFiltersChanged = debounce(
  () => tracker.trackEvent('flexible_work_shifts_filters_changed', { filters: filters.value }),
  200,
);

onBeforeMount(async () => {
  setTabFromStatus();

  watch(
    filters,
    async () => {
      await getShiftsAndClaims();

      if (!initialized.value) {
        setInitialTab();

        initialized.value = true;
      } else {
        trackFiltersChanged();
      }
    },
    {
      deep: true,
      immediate: true,
    },
  );

  getRolesAndLocations();
});
</script>
