<template>
  <section>
    <page-sub-header title="Track" class="mt-4">
      <template #header>
        <app-input
          v-model="timeSeries"
          :options="TIME_SERIES_VALUES"
          type="radio"
          buttons
          button-variant="outline-primary"
          class="text-uppercase"
          size="sm"
        />
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="timeGroupedCountDataset.values.length">
        <b-row class="align-items-center mb-4">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedCountDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { ticks: { precision: 0 }, title: { text: timeGroupedCountDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastCountValue"
              :previous-stat="prevCountValue"
              :formatter="toCompactNumber"
              description="Employees Viewing Track"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ toCompactNumber(lastCountValue) }} of your enrolled employees viewed track.
              {{ countPrevDiffText }}
            </p>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedPercentageDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { title: { text: timeGroupedPercentageDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastPercentageValue"
              :previous-stat="prevPercentage"
              :formatter="toNumber"
              suffix="%"
              description="Track Percentage"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ lastPercentageValue }}% of your enrolled employees viewed track.
              {{ percentagePrevDiffText }}
            </p>
          </b-col>
        </b-row>
      </sme-card>
      <sme-card v-else>
        <p class="my-2 text-center">No data to display.</p>
      </sme-card>
    </template>
  </section>
</template>

<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES_VALUES, getInitialAspectRatio } from '@/utils/chart';
import { toCompactNumber, toNumber } from '@/utils/common';

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const timeDataset = ref(null);
const countDataset = ref(null);
const percentageDataset = ref(null);

const { timeSeries, timeGroupDataset, timeGroupTitle, datasetToTimeGroupedDataset, getPrevDiffText } =
  useTimeGroupDataset(timeDataset);

const timeGroupedCountDataset = computed(() => datasetToTimeGroupedDataset(countDataset));
const timeGroupedPercentageDataset = computed(() => datasetToTimeGroupedDataset(percentageDataset));
const lastCountValue = computed(() => timeGroupedCountDataset.value?.calculated.last);
const prevCountValue = computed(() => timeGroupedCountDataset.value?.calculated.prev);
const lastPercentageValue = computed(() => timeGroupedPercentageDataset.value?.calculated.last);
const prevPercentage = computed(() => timeGroupedPercentageDataset.value?.calculated.prev);
const countPrevDiffText = computed(() => getPrevDiffText(timeGroupedCountDataset));
const percentagePrevDiffText = computed(() =>
  getPrevDiffText(timeGroupedPercentageDataset, value => `${Number(toNumber(value))}%`),
);

watch([divisionId, timeSeries], () => getTrackMetrics());

const getTrackMetrics = async () => {
  loading.value = true;

  const { results } = await ApiClient.getTrackMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const track = results.find(result => result.id === 'track_users');
    const enrolled = results.find(result => result.id === 'enrolled');

    timeDataset.value = time;
    countDataset.value = {
      ...track,
      name: 'Number of employees',
    };
    percentageDataset.value = {
      name: 'Percentage (%)',
      values: enrolled.values.map((value, index) =>
        value ? Number(toNumber((track.values[index] / value) * 100)) : 0,
      ),
    };

    loading.value = false;
  }
};

onBeforeMount(() => getTrackMetrics());
</script>
