import { render, staticRenderFns } from "./KYC.vue?vue&type=template&id=5856391d&scoped=true&"
import script from "./KYC.vue?vue&type=script&lang=js&"
export * from "./KYC.vue?vue&type=script&lang=js&"
import style0 from "./KYC.vue?vue&type=style&index=0&id=5856391d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5856391d",
  null
  
)

export default component.exports