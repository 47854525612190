<template>
  <section>
    <page-sub-header title="App Features" class="mt-4" />
    <sme-card>
      <app-loading v-if="loading" />
      <template v-else>
        <bar-chart
          v-if="dataset.values.length"
          :series-x="labels"
          :series-y="dataset"
          :chart-options="{
            aspectRatio: getInitialAspectRatio(3.5, 2),
            indexAxis: 'y',
            scales: {
              x: { title: { display: true, text: dataset.name } },
              y: { grid: { display: false }, title: { display: false } },
            },
          }"
        />
        <p v-else class="my-2 text-center">No data to display.</p>
      </template>
    </sme-card>
  </section>
</template>

<script setup>
import moment from 'moment';
import { onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import { PaletteColors } from '@/Theme';
import AppLoading from '@/components/AppLoading.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import BarChart from '@/components/charts/BarChart.vue';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { getInitialAspectRatio, TIME_SERIES } from '@/utils/chart';
import { toNumber } from '@/utils/common';
import { toMonthDescriptor } from '@/utils/date';

const ALL_LABELS = {
  track_users: {
    name: 'Track',
    color: PaletteColors['core-2-lighten-20'],
  },
  stream_users: {
    name: 'Stream',
    color: PaletteColors['brand-primary'],
  },
  save_users: {
    name: getBankingInfo().save.label,
    color: PaletteColors['core-3'],
  },
  hub_users: {
    name: 'Hub',
    color: PaletteColors['core-4'],
  },
  home_users: {
    name: 'Home',
    color: PaletteColors['core-5'],
  },
};

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const dataset = ref(null);
const labels = ref(null);

watch(divisionId, () => getAppFeaturesMetrics());

const getAppFeaturesMetrics = async () => {
  loading.value = true;

  const { results } = await ApiClient.getAppFeaturesMetrics(TIME_SERIES.MONTH_TO_MONTH, divisionId.value);

  if (results?.length) {
    const uniqueUsers = results.find(result => result.id === 'unique_users').calculated.last_complete;
    const monthDescriptor = toMonthDescriptor(
      moment.unix(results.find(result => result.id === 'time').calculated.last_complete),
    );

    // remove elements that are not features
    const exclusions = ['time', 'unique_users'];
    const app_features = results.filter(result => !exclusions.includes(result.id));

    // the features should be ordered by the API with an id that match LABELS ids
    let appFeatureIdAndValues = [];
    const enabledLabels = [];

    if (uniqueUsers) {
      appFeatureIdAndValues = Object.fromEntries(
        app_features.map(x => [x.id, toNumber((x.calculated.last_complete / uniqueUsers) * 100)]),
      );

      Object.keys(appFeatureIdAndValues).forEach(key => {
        if (key in ALL_LABELS) {
          enabledLabels.push(ALL_LABELS[key]);
        }
      });
    }

    dataset.value = {
      description: `Percentage of enrolled employees viewing app features ${monthDescriptor}`,
      name: 'Percentage (%)',
      values: Object.values(appFeatureIdAndValues),
    };

    labels.value = {
      values: enabledLabels,
    };
  }

  loading.value = false;
};

onBeforeMount(() => getAppFeaturesMetrics());
</script>
