<template>
  <raise-auth-page :image="themeImage('raise-login.png')">
    <app-loading v-if="loading" />
    <LoginForm v-else :image="themeImage('raise-text-logo.svg')" is-raise />
    <template v-if="!isDisableRegistration">
      <div v-if="!loading" class="login__footer">
        <span>Don't have an account?</span>
        <router-link :to="{ name: 'register' }"> Register </router-link>
      </div>
    </template>
  </raise-auth-page>
</template>

<script>
import { themeImage } from '@/Theme';
import RaiseAuthPage from '@/components/RaiseAuthPage.vue';
import AppLoading from '@/components/AppLoading.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'RaiseLogin',
  components: {
    RaiseAuthPage,
    AppLoading,
    LoginForm,
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const { isDisableRegistration } = useFeatureFlags();

    return { isDisableRegistration };
  },
  methods: {
    themeImage,
  },
  created() {},
};
</script>

<style scoped lang="scss">
.login {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;

  &__footer {
    margin-top: 24px;
    font-weight: 400;
    text-align: center;
    color: var(--palette-color-default-lighten-30);
  }

  &__logo {
    object-fit: contain;
    margin-bottom: 36px;
    height: 40px;
    width: 100%;
  }

  &__submit {
    margin-top: 24px;
    padding: 12px 16px;
    width: 100%;
  }

  &__recover {
    margin-top: -16px;
    text-align: right;
  }

  & .form-label {
    width: 100%;
    text-align: left;
    font-weight: 400;
    color: var(--palette-color-default-lighten-30);
  }
}

.social {
  margin-top: 42px;
  width: 100%;

  &__label {
    display: inline-block;
    margin-top: 24px;
    font-size: 12px;
    width: 100%;
  }

  &__image {
    width: 95%;
  }
}
</style>
