<template>
  <app-page
    title="Xero configuration"
    subtitle="Configure your Xero integration settings. This will only work if you are a Xero customer."
  >
    <b-modal ref="disconnect-confirmation" :hide-footer="true">
      <div class="d-block text-center">
        <h3>Are you sure?</h3>
        <p>
          You will need to disconnect your App from Wagestream in your Xero settings before you can connect again. In
          the meantime, Wagestream will not be syncing from Xero.
        </p>
        <button type="submit" class="btn btn-primary" @click="hideModal()">Cancel</button>
        <button type="submit" class="btn btn-secondary" @click="disconnect()">Continue</button>
      </div>
    </b-modal>
    <sme-alert level="danger" class="mb-3">
      Changing these settings will cause Wagestream to stop syncing employees from Xero. This may result in your
      employees' data not being up-to-date, and will result in any new employees not being synced. Contact our support
      team at
      <a href="sme@wagestream.com">sme@wagestream.com</a> if you have any questions about disconnecting this
      integration.
    </sme-alert>
    <img alt="xero" class="xero-configuration__logo mb-3" src="/integrations/xero.png" />
    <b-list-group>
      <b-list-group-item>
        <strong> Status: </strong>
        {{ xeroConfigState.connected ? 'Connected' : 'Disconnected' }}
      </b-list-group-item>
      <b-list-group-item>
        <strong> Tenant Name: </strong>
        {{ xeroConfigState.tenantName || 'None' }}
      </b-list-group-item>
    </b-list-group>
    <page-sub-footer>
      <b-button variant="primary" :disabled="xeroConfigState.connected" @click="connect"> Connect </b-button>
      <b-button variant="primary" :disabled="!xeroConfigState.connected" @click="showModal"> Disconnect </b-button>
    </page-sub-footer>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'XeroConfiguration',
  components: { AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      state: State.state,
      xeroConfigState: {
        connected: false,
        tenantId: null,
      },
    };
  },
  async mounted() {
    await this.getConfiguration();
  },
  methods: {
    async disconnect() {
      await ApiClient.putXeroUnlink(this.state.company.company_id);
      this.$router.go();
    },
    async connect() {
      this.$router.push({
        name: 'sme-oauth2',
        params: { provider: 'xero' },
        query: { next: 'xero-configuration' },
      });
    },
    async getConfiguration() {
      const xeroConfigState = await ApiClient.getXeroConfiguration(this.state.company.company_id);
      this.xeroConfigState = {
        connected: xeroConfigState.connected,
        tenantId: xeroConfigState.tenant_id,
        tenantName: xeroConfigState.tenant_name,
      };
    },
    showModal() {
      this.$refs['disconnect-confirmation'].show();
    },
    hideModal() {
      this.$refs['disconnect-confirmation'].hide();
    },
  },
};
</script>

<style scoped>
.xero-configuration__logo {
  display: inline-block;
  width: 6rem;
}
</style>
