<template>
  <b-container class="pb-4 container">
    <img class="logo" :src="themeImage('logo-white.svg')" :alt="`${partnershipName} Logo`" />
    <ul class="apps-list">
      <li v-for="(app, index) in APPS" :key="index">
        <router-link
          :to="{ name: app.routeName }"
          :class="{ 'element-link': true, 'element-link-active': $route.name === app.routeName }"
        >
          <div class="icon-tile">
            <font-awesome-icon class="tile-icon" :icon="app.icon" fixed-width />
          </div>
          <span class="tile-title">{{ app.name }}</span>
        </router-link>
      </li>
    </ul>
  </b-container>
</template>

<script>
import { themeImage } from '@/Theme';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { getPartnershipData } from '@/utils/partnership';

import { isFlexPayLike, isFlexWorkLike } from '@/guards.js';

export default {
  name: 'AppSwitcher',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const { isEnableFlexibleWork, isEnableTalentPool } = useFeatureFlags();
    const { name: partnershipName } = getPartnershipData();

    const APPS = [
      {
        name: 'Flexible Pay',
        routeName: 'sme-dashboard',
        icon: ['fas', 'analytics'],
        enabled: () => isFlexPayLike(),
      },
      {
        name: 'Flexible Work' + (isEnableTalentPool.value ? ' (V1)' : ''),
        routeName: 'flexible-work',
        icon: ['fad', 'calendar-alt'],
        enabled: () => isEnableFlexibleWork.value && isFlexWorkLike(),
      },
      {
        name: 'Raise',
        routeName: 'talent-pool-root',
        icon: ['fad', 'calendar-alt'],
        enabled: () => isEnableTalentPool.value && isFlexWorkLike(),
      },
    ].filter(app => app.enabled());

    if (APPS.length === 1) {
      this.$router.push({ name: APPS[0].routeName });
    }

    return {
      partnershipName,
      APPS,
    };
  },
  methods: {
    themeImage,
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  background: radial-gradient(
      102.19% 102.19% at 89.17% -2.19%,
      var(--palette-color-core-4) 0,
      var(--palette-color-core-3-darken-20) 38.97%,
      var(--palette-color-core-1-darken-10) 53.97%
    ),
    var(--palette-color-core-1-darken-20);
}
.logo {
  margin-bottom: 40px;
  height: 70px;
}

.apps-list {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 26px;
}

.element-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 18%;
  background: var(--palette-color-base-white);
  margin-bottom: 12px;
}

.tile-icon {
  height: 28px;
  width: 28px;
}

.tile-title {
  color: var(--palette-color-base-white);
  opacity: 75%;
  transition: opacity 0.2s ease-in-out;
}

.element-link:hover .tile-title {
  opacity: 95%;
}
</style>
