<template>
  <main class="shift-content">
    <div class="shift-details">
      <div v-if="isLoading" class="loading-container">
        <app-loading />
      </div>
      <div v-else>
        <ShiftOverview v-if="!showCandidates && shift" :shift="shift" />
        <ShiftSigning
          @refetch-shift="refetchShift"
          v-if="canViewSigningFlow"
          :shift="shift"
          :key="shift.winning_claim_clocked_in_at"
        />
        <Candidates
          v-else-if="showCandidates"
          @claim-selected="onSelectClaim"
          :selected-claim-id="selectedClaimId"
          :shift="shift"
          :claims="claims"
        />
      </div>
    </div>
    <div v-if="selectedClaimId" class="selected-claim">
      <div class="details">
        <span class="name">{{ selectedClaim?.worker_name }}</span>
      </div>
      <div>
        <b-button @click="approveClaim" class="select-button" variant="primary" v-if="!errorText">
          <CheckCircle width="16px" height="16px" :color="PaletteColors['base-white']"> </CheckCircle>
          Confirm selection
        </b-button>
      </div>
      <div>
        <ErrorMessage v-if="errorText" :text="errorText || ''" />
      </div>
    </div>
  </main>
</template>

<script setup>
import ShiftSigning from '@/pages/talent-pool/shift/components/ShiftSigning.vue';
import ShiftOverview from '@/pages/talent-pool/shift/components/ShiftOverview.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import { computed, nextTick, ref, watch } from 'vue';
import { isClaimed } from '@/pages/talent-pool/utils/ShiftStatus';
import Candidates from '@/pages/talent-pool/shift/components/Candidates.vue';
import AppLoading from '@/components/AppLoading.vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { postApproveShiftClaim, getShift } from '@/queries/talent-pool/shifts';
import ErrorMessage from '@/components/ErrorMessage.vue';
import CheckCircle from '@/assets/icons/CheckCircle.vue';
import { PaletteColors } from '@/Theme';

const route = useRoute();
const router = useRouter();
const shiftId = route.params.shiftId;
const locationId = route.params.locationId;

const shift = ref(null);
const claims = ref([]);
const selectedClaimId = ref(null);
const errorText = ref('');

const rawShift = useQuery(getShift(shiftId));
const isLoading = computed(() => rawShift.isPending.value && rawShift.data.value);

const refetchShift = () => {
  const data = rawShift.refetch();
  shift.value = data;
};

// Computed values
const canViewSigningFlow = computed(() =>
  shift?.value ? shift?.value?.most_relevant_claim_status === 'APPROVED' : false,
);

const selectedClaim = computed(() => {
  if (claims.value.length > 0) {
    return claims.value.find(claim => claim.claim_id === selectedClaimId.value);
  }
  return null;
});

const showCandidates = computed(() => (shift.value ? isClaimed(shift.value) : false));

// Methods
const onSelectClaim = claimId => {
  if (selectedClaimId.value === claimId) {
    selectedClaimId.value = null;
  } else {
    selectedClaimId.value = claimId;
  }
};

const onError = error => {
  if (error?.statusCode === 409) {
    errorText.value = 'This claim has already been approved';
  } else {
    errorText.value =
      'We could not select this user for the shift. Please refresh the portal, select someone else or contact us to resolve.';
  }
};

const onSuccess = () => {
  router
    .push({
      name: 'talent-pool-shift',
      params: { locationId, shiftId: shiftId },
    })
    .catch(e => e);
};

const onApproveClaim = useMutation(postApproveShiftClaim({ onError: onError, onSuccess: onSuccess, locationId }));

const approveClaim = async () => {
  onApproveClaim.mutate(selectedClaimId.value);
};

const fetchShiftClaims = async () => {
  const { data } = await ApiClient.getCandidateRanking(shiftId);
  claims.value = data;
};

watch(rawShift.fetchStatus, async () => {
  shift.value = rawShift.data.value;
  if (rawShift.data.value && isClaimed(rawShift.data.value)) {
    await fetchShiftClaims();
  }
  nextTick();
});
</script>

<style scoped lang="scss">
.shift-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.shift-details {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  overflow: auto;
}

@media (max-width: 900px) {
  .shift-details {
    padding: 1rem;
  }
}

.selected-claim {
  background-color: var(--palette-color-base-white);
  padding: 1rem 1.5rem;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid var(--palette-color-default-lighten-90);
}

.selected-claim .details {
  flex-grow: 1;
}

.selected-claim .details .name {
  font-size: 18px;
  font-weight: 500;
}

.selected-claim .select-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loading-container {
  padding-top: 4rem;
}
</style>
