var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$route.path !== '/login/impersonate' &&
    _vm.$route.path !== '/login/forgot-password' &&
    _vm.$route.path !== '/login' &&
    _vm.$route.path.indexOf('/register') == -1 &&
    _vm.$route.path !== '/kyc' &&
    _vm.$route.path !== '/employee/comms' &&
    _vm.$route.name !== 'sme-post-signup' &&
    _vm.$route.name !== 'sme-oauth2' &&
    _vm.$route.name !== 'sme-init-integration' &&
    _vm.$route.name !== 'sme-lead-touch'
  )?_c('app-page-layout',[_c('router-view',{key:_vm.$route.path})],1):_c('router-view',{key:_vm.$route.path})
}
var staticRenderFns = []

export { render, staticRenderFns }