import { render, staticRenderFns } from "./CompanySettings.vue?vue&type=template&id=01042956&scoped=true&"
import script from "./CompanySettings.vue?vue&type=script&setup=true&lang=js&"
export * from "./CompanySettings.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CompanySettings.vue?vue&type=style&index=0&id=01042956&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01042956",
  null
  
)

export default component.exports