var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-page',{attrs:{"title":`Opt in ${_vm.employee ? _vm.employee.full_name : 'Employee'}`,"error":_vm.error,"previous":[
    { label: 'Employees', to: { name: 'employees' } },
    {
      label: _vm.employee ? _vm.employee.full_name : 'Employee',
      to: { name: 'employee', params: { employeeId: _vm.$route.params.employeeId } },
    },
  ],"icon":"user-plus"}},[_c('app-loading',{attrs:{"loading":_vm.loading}}),(!_vm.loading && !_vm.saved)?[_c('sme-alert',{staticClass:"mb-3",attrs:{"level":"warning"}},[_vm._v(" You are about to restore access to the app for "+_vm._s(_vm.employee.full_name)+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return v.handleSubmit(_vm.onSubmit)}}},[_c('app-input',{attrs:{"type":"text","name":"reason","rules":"required","label":"Reason","disabled":_vm.saving || _vm.saved,"validate-immediate":false},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('page-sub-footer',[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":v.invalid || _vm.saving || _vm.saved}},[(_vm.saving)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.saving ? 'Opting in...' : 'Opt in')+" ")],1)],1)],1)]}}],null,false,657932308)})]:_vm._e(),(_vm.saved)?_c('sme-alert',{attrs:{"level":"good"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.employee.full_name)+" has been opted back in")])]),(!_vm.integrationsState.state.integrations)?[_c('p',[_vm._v(" You will need to update your payroll system with new banking details to complete the employee's re-enrolment. ")]),(_vm.showBankingUpdate)?[_c('p',[_c('router-link',{attrs:{"to":{ name: 'employee-setup', params: { employeeId: _vm.employee.employee_id } }}},[_vm._v("Update Banking")])],1)]:_vm._e()]:_vm._e(),(_vm.reactivated)?[_c('p',[_vm._v(" The employee did not go through the opt-out process before leaving, so has been reactivated to their previous state. ")])]:[_c('p',[_vm._v("The employee's re-enrolment will be completed once we have updated their banking details.")])]],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }