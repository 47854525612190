import ApiClient from '@/ApiClient';
import { queryOptions, useQueryClient } from '@tanstack/vue-query';

export const getOffCycleConfig = () => {
  return queryOptions({
    queryKey: ['get_one_time_payment_config'],
    queryFn: async () => {
      const result = await ApiClient.getOffCycleConfig();
      return result.data;
    },
  });
};

export const getOffCyclePayment = batchId => {
  return queryOptions({
    queryKey: ['get_one_time_payment', batchId],
    queryFn: async () => {
      const result = await ApiClient.getOffCyclePaymentBatch(batchId.value);
      return result.data;
    },
  });
};

export const getOffCyclePayments = () => {
  return queryOptions({
    queryKey: ['get_one_time_payments'],
    queryFn: async () => {
      const result = await ApiClient.getOffCyclePayments();
      return result.data;
    },
  });
};

export const getOffCycleBatch = batchId => {
  return queryOptions({
    enabled: () => !!batchId.value,
    queryKey: ['get_one_time_payment_batch', batchId],
    queryFn: async () => {
      const result = await ApiClient.getOffCyclePaymentBatch(batchId.value);
      return result.data;
    },
  });
};

export const postSingleOffCyclePayment = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.createOffCycleSinglePayment(payload);
      return result;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('get_one_time_payments');
    },
  };
};

export const postOffCyclePayment = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.createOffCycleBatch(payload);
      return result;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('get_one_time_payments');
    },
  };
};

export const saveOffCyclePayment = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async ({ payload, batchId }) => {
      const result = await ApiClient.saveOffCycleBatch(batchId, payload);
      return result;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('get_one_time_payments');
    },
  };
};

export const processOffCyclePayment = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async batchId => {
      const result = await ApiClient.processOffCycleBatch(batchId);
      return result;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('get_one_time_payments');
    },
  };
};

export const deleteOffCycleBatch = () => {
  return {
    mutationFn: async batchId => {
      const result = await ApiClient.deleteOffCycleBatch(batchId);
      return result;
    },
  };
};

export const downloadOffCycleBatchInvoice = () => {
  return {
    mutationFn: async batchId => {
      const result = await ApiClient.downloadOffCycleInvoice(batchId);
      return result;
    },
  };
};
