<template>
  <router-link
    :to="{
      name: 'talent-pool-job-detail',
      params: { jobId: props.job.unfilled_shift_group_id, locationId },
    }"
    :class="['job-container', { current: props.job.unfilled_shift_group_id === currentJobId }]"
  >
    <div class="job">
      <div class="info">
        <div class="meta">
          <span class="role">{{ props.job.external_role_name }}</span>
          <span v-if="props.job.closed_at" class="closed"
            ><CheckCircleFilled
              :color="PaletteColors['default-lighten-50']"
              :width="18"
              :height="18"
            ></CheckCircleFilled
          ></span>
          <span class="live" v-else-if="isLive"
            ><Alarm :width="20" :height="20" :color="PaletteColors['default-lighten-30']"></Alarm
          ></span>
          <span class="locked" v-else-if="!isLive && !isUnlocked">
            <Users :color="PaletteColors['core-1']" :width="20" :height="20"></Users>
          </span>
          <span class="unlocked" v-else-if="isUnlocked">
            <LockOpen :color="PaletteColors['success']" :width="18" :height="18"></LockOpen>
          </span>
        </div>
      </div>
    </div>
    <div v-if="!props.last" class="line" />
  </router-link>
</template>

<script setup>
import { useRoute } from 'vue-router/composables';
import { computed } from 'vue';
import Alarm from '@/assets/icons/AlarmFilled.vue';
import { PaletteColors } from '@/Theme';
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import LockOpen from '@/assets/icons/LockOpen.vue';
import Users from '@/assets/icons/Users.vue';

const route = useRoute();
const locationId = route.params.locationId;
const currentJobId = route.params.jobId;

const props = defineProps(['job', 'last']);

const isLive = computed(() => {
  return props.job.shortlist_sealed_at === null;
});

const isUnlocked = computed(() => {
  return props.job.shortlist_unlocked_at !== null;
});
</script>

<style scoped lang="scss">
.line {
  height: 1px;
  background: var(--palette-color-default-lighten-90);
  margin: 0 1rem;
}

.job {
  padding: 1rem 12px;
  background: var(--palette-color-base-white);
  display: flex;
  align-items: center;
  color: var(--palette-color-default);
  transition: 0.2s;
}

.job-container.current .job {
  background-color: var(--palette-color-core-1-lighten-90);
  border-left: 5px solid var(--palette-color-brand-primary);
}

.job:hover {
  background-color: var(--palette-color-core-1-lighten-90);
}

.info {
  display: flex;
  font-weight: 300;
  font-size: 14px;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
}

.info .meta {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.shortlisted {
  font-weight: 400;
  color: var(--palette-color-default-lighten-20);
}

.info .price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.info .role {
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info .applicants {
  font-size: 16px;
  font-weight: 600;
}

.salary {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: end;
}

.closed {
  font-weight: 500;
  color: var(--palette-color-brand-primary);
}

.locked {
  font-weight: 500;
  color: var(--palette-color-brand-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.live {
  font-weight: 400;
  color: var(--palette-color-default-lighten-30);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.unlocked {
  font-weight: 400;
  color: var(--palette-color-default-lighten-30);
}
</style>
