<template>
  <router-link
    :to="{ name: 'talent-pool-shift-detail', params: { shiftId: props.shift.shift_id, locationId, tab } }"
    class="shift-container"
  >
    <div :class="['shift', shiftState]">
      <div class="header">
        <div>
          <transition v-if="altHeadingText" mode="out-in" name="heading-toggle">
            <span class="heading" :key="toggleHeading">{{ currentHeading }}</span>
          </transition>
          <div v-else class="heading">{{ props.shift.winning_claim_employee_name }}</div>
          <div class="role">{{ props.shift.portal_role_name }}</div>
        </div>
        <TickCircle v-if="isCompleted(props.shift)" />
        <EmployeeAvatar v-else :imageUrl="props.shift.winning_claim_employee_photo_url" :size="24" />
      </div>
      <b-progress :max="100" height="4px">
        <b-progress-bar :value="percentage" class="todos__progress-bar" />
      </b-progress>
      <div class="action">
        <span>{{ actionText }}</span>
        <ArrowRight v-if="!!getFulfilledAction(props.shift)" width="14" height="14" />
      </div>
    </div>
    <div v-if="!props.last" class="line" />
  </router-link>
</template>

<script setup>
import { useRoute } from 'vue-router/composables';
import EmployeeAvatar from '@/pages/talent-pool/components/EmployeeAvatar.vue';
import { computed, ref, watchEffect } from 'vue';
import { formatTime, formatTimeRemaining, getProgressPercentage } from '@/pages/talent-pool/utils/datetime';
import { isFuture, isPast } from 'date-fns';
import {
  isCompleted,
  canClockIn,
  canFinalise,
  getShiftState,
  getFulfilledAction,
} from '@/pages/talent-pool/utils/ShiftStatus';
import ArrowRight from '@/assets/icons/ArrowRight.vue';
import TickCircle from '@/assets/icons/TickCircle.vue';

const route = useRoute();
const locationId = route.params.locationId;

const props = defineProps(['shift']);

const toggleHeading = ref(true);

const getAltHeadingText = () => {
  if (isPast(props.shift.start_at) && isFuture(props.shift.end_at)) {
    return `${formatTimeRemaining(props.shift.end_at)} remaining`;
  } else if (isPast(props.shift.end_at)) {
    return 'Finished at ' + formatTime(props.shift.end_at);
  } else if (isFuture(props.shift.start_at)) {
    return 'Starting at ' + formatTime(props.shift.start_at);
  }
  return null;
};

const percentage = ref(getProgressPercentage(props.shift.start_at, props.shift.end_at));
const altHeadingText = ref(getAltHeadingText());

const shiftState = computed(() => getShiftState(props.shift));

const actionText = computed(() => {
  if (isCompleted(props.shift)) {
    return `Signed-off at ${formatTime(props.shift.claim_properties.fulfilled_at)}`;
  } else if (canClockIn(props.shift)) {
    return 'Click to clock-in now';
  } else if (canFinalise(props.shift)) {
    return 'Click to sign-off now';
  } else {
    return `Starts at ${formatTime(props.shift.start_at)}`;
  }
});

// confusing because the backend state if fulfilled is completed on the frontend
const tab = computed(() => (isCompleted(props.shift) ? 'completed' : 'fulfilled'));

const currentHeading = computed(() =>
  toggleHeading.value ? props.shift.winning_claim_employee_name : altHeadingText.value,
);

watchEffect(onInvalidate => {
  const timer = setInterval(() => {
    toggleHeading.value = !toggleHeading.value;
    percentage.value = getProgressPercentage(props.shift.start_at, props.shift.end_at);
    altHeadingText.value = getAltHeadingText();
  }, 4000);

  onInvalidate(() => {
    clearInterval(timer);
  });
});
</script>

<style scoped lang="scss">
.shift-container {
  min-width: 200px;
  &:hover {
    cursor: pointer;
  }
}

.shift {
  padding: 1rem 12px;
  background: var(--palette-color-base-white);
  display: flex;
  flex-direction: column;
  color: var(--palette-color-default);
  border-radius: 0.5rem;
  transition: 0.2s;
  gap: 0.3rem;
  box-shadow: 0px 2px 10px 0px rgba(34, 40, 47, 0.1);
  box-shadow: 0px 0px 2px 0px rgba(34, 40, 47, 0.05);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  margin-bottom: 0.1rem;
}

.role {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-30);
}

.shift.fulfilled {
  & .action {
    border-color: var(--palette-color-shift-states-fulfilled);
  }
  & .todos__progress-bar {
    background-color: var(--palette-color-shift-states-fulfilled);
  }
  & .progress {
    background-color: var(--palette-color-shift-states-fulfilled-lighten-50);
  }
}

.shift.completed {
  opacity: 0.6;

  & .action {
    border-color: var(--palette-color-shift-states-completed);
  }
  & .todos__progress-bar {
    background-color: var(--palette-color-shift-states-completed);
  }
  & .progress {
    background-color: var(--palette-color-shift-states-completed-lighten-50);
  }
}

.heading-toggle-leave-active {
  transition: opacity 1s ease-in;
}

.heading-toggle-enter-active {
  transition: opacity 0.5s;
}

.heading-toggle-enter,
.heading-toggle-leave-to {
  opacity: 0;
}

.heading-toggle-leave,
.heading-toggle-enter-to {
  opacity: 1;
}
</style>
