<template>
  <app-page
    back-route="/one-time-payments"
    :title="title"
    :subtitle="`Started: ${format(new Date(), 'MM/dd/yy h:mm a')}`"
  >
    <template v-slot:header>
      <ExportButton
        v-if="showExportButton"
        display-text="Export as"
        :export-data="dataset"
        :export-file-rows="exportFileRows"
        :export-headers="exportHeaders"
        :file-name="title"
      ></ExportButton>
      <b-button v-if="showDeleteButton" variant="outline-danger" v-b-modal="'confirm-delete-batch-modal'"
        >Delete batch</b-button
      >
      <Dialog
        modalId="confirm-delete-batch-modal"
        title="Are you sure you want to delete this batch?"
        description="This batch is currently in draft. Once you delete your draft batch, you will not be able to recover it."
        @on-ok="deleteBatch"
        size="lg"
      ></Dialog>
    </template>
    <template #custom-content>
      <div class="payment-method-container" v-if="showPaymentMethod">
        <div class="sub-title">Select payment method</div>
        <div class="options-row">
          <div
            :class="['payment-option', paymentMethod === 'ach' ? 'selected' : '']"
            value="ach"
            @click="paymentMethod = 'ach'"
          >
            <b-checkbox :checked="paymentMethod === 'ach'"> </b-checkbox>
            <div class="payment-option-text">
              <span class="option-title"> ACH payment </span>
              <span>Details go here</span>
            </div>
          </div>
          <div
            :class="['payment-option', paymentMethod === 'invoice' ? 'selected' : '']"
            value="invoice"
            @click="paymentMethod = 'invoice'"
          >
            <b-checkbox :checked="paymentMethod === 'invoice'"> </b-checkbox>
            <div class="payment-option-text">
              <span class="option-title"> Invoice </span>
              <span>Total will be added to your Wagestream invoice</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container" v-if="currentBatchId ? !loading : true">
        <bulk-upload
          name="one-time-payment"
          :schema="VALIDATE_FIELD_SCHEMA"
          :post-function="onPostPayments"
          @onChangeDataset="updateDataset"
          emit
          :show-title="false"
          :starting-stage="batchIdInRoute ? 'VERIFY' : 'UPLOAD'"
          :starting-data="currentBatchId ? dataset : undefined"
          post-mode="batch"
          :save-progress="onSavePayments"
          :confirm-before-upload="true"
          optional-text="Optional if enrolled"
          success-text="We've emailed your employees to let them know. We'll be in touch if we encounter any issues while processing your payments."
          confirm-text="I confirm that these payment details are correct and I would like to proceed with the payments. Once submitted, I acknowledge that these payments cannot be reversed as the employees will be notified on payment success."
        />
      </div>
    </template>
  </app-page>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { isEmailAddressValid } from '@/pages/one-time-payments/utils';
import BulkUpload from '@/components/BulkUpload.vue';
import { unFormatCurrency } from '@/utils/common';
import { format } from 'date-fns';
import AppPage from '@/components/AppPage.vue';
import ExportButton from '@/components/ExportButton.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useMutation, useQuery } from '@tanstack/vue-query';
import {
  deleteOffCycleBatch,
  getOffCycleBatch,
  getOffCycleConfig,
  postOffCyclePayment,
  processOffCyclePayment,
  saveOffCyclePayment,
} from '@/queries/one-time-payments';
import State from '@/state/State';
import Dialog from '@/components/Dialog.vue';

const title = ref('New batch');

const dataset = ref([]);
const currentStage = ref('');
const paymentMethod = ref('invoice'); // TODO: default to ACH if ACH enabled, otherwise keep as invoice
const loading = ref(true);
const paymentReasons = ref([]);

const route = useRoute();
const router = useRouter();

const batchIdInRoute = route.query['batchId'];
const currentBatchId = ref(batchIdInRoute);

const getBatchData = useQuery(getOffCycleBatch(currentBatchId));

const makePaymentMutation = useMutation(processOffCyclePayment());
const savePaymentMutation = useMutation(saveOffCyclePayment());
const createPaymentBatchMutation = useMutation(postOffCyclePayment());
const deletePaymentBatchMutation = useMutation(deleteOffCycleBatch());
const offCycleConfig = useQuery(getOffCycleConfig());

watch([offCycleConfig.fetchStatus, offCycleConfig.isPending], () => {
  if (offCycleConfig.data.value) {
    paymentReasons.value = offCycleConfig.data.value.supported_payment_types;
  }
});

const formatRawData = data => {
  return data.map(payment => {
    return {
      ...payment,
      amount: payment.amount / 100,
      reason: payment.payment_type,
      routing_number: payment.routing_number || payment.beneficiary_routing_number,
      account_number: payment.account_number || payment.beneficiary_account_number,
    };
  });
};

watch([getBatchData.fetchStatus, currentBatchId], () => {
  if (currentBatchId.value && getBatchData.data.value) {
    const batch = getBatchData.data.value;
    title.value = `Batch ${batch.batch_number}`;

    dataset.value = formatRawData(batch.payments);
    paymentMethod.value = batch.billing_type?.toLowerCase();
  }
  loading.value = false;
});

const updateDataset = (data, stage) => {
  currentStage.value = stage;
  dataset.value = data;
};

const VALIDATE_FIELD_SCHEMA = computed(() => [
  {
    key: 'employee_code',
    label: 'Employee code',
    description: 'The employee code of the employee',
    example: '1234',
    type: 'Text',
    required: true,
  },
  {
    key: 'employee_email',
    label: 'Employee email',
    description: 'The email of the employee. Required if user is not enrolled, otherwise optional',
    example: 'john.doe@company.com',
    type: 'Text',
    validator: value => isEmailAddressValid(value),
  },
  {
    key: 'routing_number',
    label: 'Routing Number',
    description: 'The routing number of the employee',
    example: '123456',
    type: 'Text',
    required: false,
  },
  {
    key: 'account_number',
    label: 'Account Number',
    description: 'The account number of the employee',
    example: '123456',
    type: 'Text',
    required: false,
  },
  {
    key: 'reason',
    label: 'Reason',
    description: 'The reason for the payment',
    example: 'bonus',
    type: 'Select',
    required: true,
    options: paymentReasons.value.map(reason => reason.name),
  },
  {
    key: 'amount',
    label: 'Amount',
    description: 'The amount to be transferred in USD',
    example: '1502.36',
    type: 'Currency',
    required: true,
    formatter: unFormatCurrency,
    validator: value => !isNaN(value),
  },
]);

const exportHeaders = computed(() => VALIDATE_FIELD_SCHEMA.value.map(field => field.label));

const exportFileRows = item => {
  return VALIDATE_FIELD_SCHEMA.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );
};

const showPaymentMethod = computed(() => {
  // TODO: update company property when we have an idea of what it is
  return currentStage.value === 'VERIFY' && State.state.company?.billing_type === 'ACH';
});

const showExportButton = computed(() => {
  return dataset.value?.length && currentStage.value.includes('VERIFY');
});

const showDeleteButton = computed(() => {
  return dataset.value?.length && currentBatchId && currentStage.value.includes('VERIFY');
});

const onPostPayments = async finalDataset => {
  const currentDataset = finalDataset || dataset.value;
  const batchId = batchIdInRoute || currentBatchId.value;

  const data = await savePaymentMutation.mutateAsync({
    batchId: batchId,
    payload: {
      payments: currentDataset.map(payment => ({
        ...payment,
        amount: payment.amount * 100,
        payment_type: payment.reason,
        routing_number: payment.routing_number || null,
        account_number: payment.account_number || null,
      })),
      off_cycle_payment_group_id: batchId,
      billing_type: paymentMethod.value.toUpperCase(),
    },
  });

  if (data.data.validation_errors && Object.keys(data.data.validation_errors).length > 0) {
    return {
      data: formatRawData(data.data.payments),
      errors: formatErrors(data.data.validation_errors, data.data.payments),
    };
  } else {
    await makePaymentMutation.mutateAsync(batchId, {});
  }
};

const formatErrors = (errors, dataset) => {
  if (!errors || errors?.length === 0) {
    return [];
  }

  return Object.entries(errors).map(([paymentId, err]) => {
    const rowIndex = dataset.findIndex(row => row.off_cycle_payment_id === paymentId);

    const errors = err.map(error => ({
      ...error,
      field: error.field === 'payment_type' ? 'reason' : error.field,
    }));

    return {
      index: rowIndex,
      fields: errors,
    };
  });
};

const onSavePayments = async finalDataset => {
  const currentDataset = finalDataset || dataset.value;
  const batchId = batchIdInRoute || currentBatchId.value;

  if (batchId) {
    const data = await savePaymentMutation.mutateAsync({
      batchId: batchId,
      payload: {
        payments: currentDataset.map(payment => ({
          ...payment,
          amount: payment.amount * 100,
          payment_type: payment.reason,
        })),
        off_cycle_payment_group_id: batchId,
        billing_type: paymentMethod.value.toUpperCase(),
      },
    });

    return {
      data: formatRawData(data.data.payments),
      errors: formatErrors(data.data.validation_errors, data.data.payments),
    };
  } else {
    const data = await createPaymentBatchMutation.mutateAsync(
      {
        payments: currentDataset.map(payment => ({
          ...payment,
          amount: payment.amount * 100,
          payment_type: payment.reason,
        })),
        billing_type: paymentMethod.value.toUpperCase(),
      },
      {
        onSuccess: data => {
          currentBatchId.value = data.data.off_cycle_payment_group_id;
        },
      },
    );
    return {
      data: formatRawData(data.data.payments),
      errors: formatErrors(data.data.validation_errors, data.data.payments),
    };
  }
};

const deleteBatch = () => {
  deletePaymentBatchMutation.mutate(currentBatchId.value, {
    onSuccess: () => {
      currentBatchId.value = null;
      dataset.value = [];
      router.push({ name: 'one-time-payments' });
    },
  });
};
</script>

<style scoped>
.content-container {
  width: 100%;
  background-color: var(--palette-color-base-white);
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 2rem;
}

.payment-method-container {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--palette-color-default-lighten-20);
}

.options-row {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.payment-option {
  font-size: 14px;
  font-weight: 400;
  background-color: var(--palette-color-base-white);
  color: var(--palette-color-default-lighten-20);
  border-radius: 12px;
  width: 50%;
  padding: 1rem;

  display: flex;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    border: 2px solid var(--palette-color-brand-primary);
  }

  .payment-option-text {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .option-title {
    font-size: 15px;
    font-weight: 500;
    color: var(--palette-color-default);
  }
}

::v-deep .custom-checkbox .custom-control-label::before {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}

::v-deep .custom-checkbox .custom-control-label::after {
  height: 1.5rem;
  width: 1.5rem;
}
</style>
