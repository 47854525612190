<template>
  <app-page
    title="Employee Setup"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
  >
    <template v-if="loading">
      <sme-alert level="info" class="mb-3"> This operation may take up to a minute, please wait... </sme-alert>
      <app-loading :loading="true" />
    </template>
    <template v-else>
      <template v-if="success">
        <sme-alert level="info" class="mb-3">
          <template v-if="!isSelfFunded()">Update your payroll system to use the new codes.</template>
          <template v-else>
            <p>
              The details below are the ones you'll need to use on payday instead of the employee's own bank account.
            </p>
            <p>
              We will reconcile any transfers automatically and forward the remainder to their acount. This happens
              transparently to the employee.
            </p>
            <p>
              These bank details will be available from your dashboard and a reminder will be sent prior to each payday.
            </p>
          </template>
        </sme-alert>

        <div class="mb-3">
          <app-input
            v-if="!isSelfFunded()"
            v-model="employee.full_name"
            type="text"
            name="full name"
            label="Employee Name"
            readonly
          ></app-input>
          <app-input
            v-else
            v-model="employee.work_email"
            type="text"
            name="full name"
            label="Email"
            readonly
          ></app-input>
          <app-input
            v-if="!isSelfFunded()"
            v-model="employee.employee_code"
            type="text"
            name="employee code"
            label="Employee Code"
            readonly
          ></app-input>

          <app-input
            v-model="newSortCode"
            type="text"
            name="sort code"
            :label="`New ${getBankingInfo.sortCode.label}`"
            readonly
          ></app-input>
          <!-- add copy -->

          <app-input
            v-model="newAccountNumber"
            type="text"
            name="account number"
            :label="`New ${getBankingInfo.accountNumber.label}`"
            readonly
          ></app-input>
          <!-- add copy -->
        </div>

        <ValidationObserver v-slot="v" v-if="!isSelfFunded()">
          <b-form @submit.prevent="v.handleSubmit(onSubmit)" id="updated">
            <ValidationProvider name="updated" :rules="{ required: { allowFalse: false } }" v-slot="f">
              <b-form-checkbox v-model="updated"
                >I have updated this employee in my company payroll file</b-form-checkbox
              >
              <b-form-invalid-feedback v-for="fError in f.errors" :key="fError">{{ fError }}</b-form-invalid-feedback>
            </ValidationProvider>
            <page-sub-footer>
              <b-button type="submit" variant="primary" :disabled="v.invalid || saving">
                <b-spinner v-if="saving" class="mr-2" small />
                {{ saving ? 'Activating...' : 'Activate' }}
              </b-button>
            </page-sub-footer>
          </b-form>
        </ValidationObserver>
        <page-sub-footer v-else>
          <router-link
            v-if="!isManagingSelf()"
            :to="{ name: 'employee', params: { employeeId: employee.employee_id } }"
          >
            <b-button type="submit" variant="primary">View Employee</b-button>
          </router-link>
          <router-link v-else :to="{ name: 'employee-setup', params: { employeeId: employee.employee_id } }">
            <b-button type="submit" variant="primary">Continue Setup</b-button>
          </router-link>
        </page-sub-footer>
      </template>

      <sme-alert v-if="!success" level="warning">
        It's taking longer than usual to connect to the bank. Please reach out directly to Support.
      </sme-alert>
    </template>
  </app-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'EnrollEmployeeLoading',
  components: { ValidationObserver, ValidationProvider, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      error: undefined,
      loading: true,
      saving: false,
      updated: false,
      employee: undefined,
      state: State.state,

      success: false,

      newSortCode: undefined,
      newAccountNumber: undefined,
    };
  },
  methods: {
    retriveEmployeeNewState(retries) {
      // Try to get the newly set sort and banking codes in SetupEmployee
      // It might take a while, so retry 3 times or show an error
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          if (employee.current_state !== 'ENROLLING' && employee.current_state !== 'ENROLLED') {
            this.$router.push({
              name: 'employee',
              params: { employeeId: employee.employee_id },
            });
          }

          this.employee = employee;
          if (employee.properties.payment_sort_code && employee.properties.payment_account_number) {
            this.newSortCode = employee.properties.payment_sort_code;
            this.newAccountNumber = employee.properties.payment_account_number;
            this.success = true;
            this.loading = false;
          } else {
            if (retries > 0) {
              setTimeout(() => this.retriveEmployeeNewState(retries - 1), 5000);
            } else {
              this.loading = false;
              this.success = false;
            }
          }
        },
        error => {
          this.error = error.message;
          this.loading = false;
          this.success = false;
        },
      );
    },
    onSubmit() {
      this.saving = true;
      const newEmployee = {
        ...this.employee,
        properties: {
          ...this.employee.properties,
          updated_payroll: true,
        },
      };
      ApiClient.updateEmployee(newEmployee).then(
        () => {
          this.$router.push({
            name: 'employee',
            params: {
              employeeId: this.employee.employee_id,
            },
          });
        },
        error => {
          this.error = error.message;
          this.loading = false;
          this.saving = false;
        },
      );
    },
    loadData() {
      this.loading = true;
      this.retriveEmployeeNewState(12);
    },
    isSelfFunded() {
      return State.state?.isSelfServeSME;
    },
    isManagingSelf() {
      return State.state?.user?.email == this.employee.work_email;
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
  },
};
</script>

<style scoped>
#updated .invalid-feedback {
  display: block;
}
</style>
