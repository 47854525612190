<template>
  <div class="stage">
    <router-link
      :class="`header-link ${totalShiftsCount === 0 && 'no-link'}`"
      :to="{ name: 'talent-pool-shift-status', params: { locationId, tab: props.tab } }"
      :event="totalShiftsCount > 0 ? 'click' : ''"
    >
      <header>
        <div>
          <span>{{ totalShiftsCount }}</span> {{ label }}
        </div>
        <font-awesome-icon v-if="totalShiftsCount > 0" :icon="['fal', 'chevron-right']" class="icon" />
      </header>
    </router-link>
    <div v-if="props.isLoading" class="no-shifts">Loading shifts...</div>
    <div class="list" v-else>
      <div v-if="urgent.length > 0" :class="['urgent']">
        <div class="label">
          <font-awesome-icon class="icon" :icon="['far', 'clock']" />{{ urgencyLabel(props.tab) }}
        </div>
        <div class="shifts">
          <ShiftCard
            v-for="(shift, index) in urgent"
            :key="shift.shift_id"
            :role="shift.portal_role_name"
            :last="index === urgent.length - 1"
            :shiftId="shift.shift_id"
            :start-at="shift.start_at"
            :end-at="shift.end_at"
            :total="shift.client_rate * shift.hours"
            :hourly="shift.client_rate"
            :claims="isClaimed(shift) ? shift.claim_count : 0"
            :fulfilledAction="getFulfilledAction(shift)"
            :tab="props.tab"
            :shift-type="shift.properties?.type"
            :status="shift.most_relevant_claim_status"
          />
        </div>
      </div>
      <ShiftCard
        v-for="(shift, index) in otherShifts"
        :key="shift.shift_id"
        :role="shift.portal_role_name"
        :last="otherShifts.length > 3 && index === otherShifts.length - 1"
        :shiftId="shift.shift_id"
        :start-at="shift.start_at"
        :end-at="shift.end_at"
        :total="shift.client_rate * shift.hours"
        :hourly="shift.client_rate"
        :claims="isClaimed(shift) ? shift.claim_count : 0"
        :fulfilledAction="getFulfilledAction(shift)"
        :tab="props.tab"
        :shift-type="shift.properties?.type"
        :status="shift.most_relevant_claim_status"
      />
      <div v-if="!otherShifts.length && !urgent.length" class="no-shifts">{{ `No ${label} shifts` }}</div>
    </div>
  </div>
</template>

<script setup>
import ShiftCard from '@/pages/talent-pool/components/ShiftCard.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { getFulfilledAction, isUrgent, urgencyLabel, isClaimed } from '@/pages/talent-pool/utils/ShiftStatus';

const route = useRoute();
const locationId = route.params.locationId;

const props = defineProps({
  label: String,
  tab: String,
  shifts: Array,
  isLoading: Boolean,
});

const urgent = computed(() => props.shifts.filter(shift => isUrgent(shift)) || []);
const otherShifts = computed(() => props.shifts.filter(shift => !isUrgent(shift)) || []);

const totalShiftsCount = computed(() => props.shifts?.length || 0);
</script>

<style scoped>
.stage {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-M, 8px);
  border: 1px solid var(--palette-color-default-lighten-90);
  background: var(--palette-color-base-white);
  overflow: hidden;
}

.stage header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
}

.stage header div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 16px;
  font-weight: 400;
}

.stage header span {
  font-size: 24px;
  font-weight: 500;
}

.stage header svg {
  width: 1rem;
  height: 1rem;
}

.stage header svg path {
  fill: var(--palette-color-default-lighten-50);
}

.stage .list {
  overflow: auto;
  height: 100%;
}

.stage .urgent {
  background: var(--palette-color-warning-lighten-90);
  padding: 0 12px 12px;
}

.stage .urgent .label {
  padding: 16px 0 12px;
  color: var(--palette-color-warning);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.stage .urgent .label .icon {
  margin-right: 0.5rem;
}

.stage .urgent.danger .label {
  color: var(--palette-color-danger);
}

.stage .shifts {
  overflow: hidden;
  border-radius: var(--radius-M, 8px);
  box-shadow: 0px 0px 3px 0px rgba(34, 40, 47, 0.1), 0px 2px 1px 0px rgba(34, 40, 47, 0.05);
}

.stage .no-shifts {
  display: flex;
  height: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  color: var(--palette-color-default-lighten-20);
}

.stage .header-link {
  display: block;
  color: var(--palette-color-default);
  background: var(--palette-color-white);

  &:hover {
    background: var(--palette-color-core-1-lighten-90);
  }

  &.no-link:hover {
    cursor: default;
    background: unset;
  }
}
</style>
