import { render, staticRenderFns } from "./ShiftStats.vue?vue&type=template&id=b1aaf8ca&scoped=true&"
import script from "./ShiftStats.vue?vue&type=script&setup=true&lang=js&"
export * from "./ShiftStats.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ShiftStats.vue?vue&type=style&index=0&id=b1aaf8ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1aaf8ca",
  null
  
)

export default component.exports