<template>
  <section>
    <page-sub-header title="Employee Shifts" class="mt-4">
      <template #header>
        <app-input
          v-model="timeSeries"
          :options="TIME_SERIES_VALUES"
          type="radio"
          buttons
          button-variant="outline-primary"
          class="text-uppercase"
          size="sm"
        />
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="hasEmployeesData" class="mb-4">
        <line-chart
          :labels="timeGroupDataset"
          :datasets="timeGroupedEmployeesDataset"
          :chart-options="
            merge(
              {
                aspectRatio: getInitialAspectRatio(3.5, 2),
                scales: { y: { title: { text: 'Percentage (%)' } } },
                plugins: {
                  legend: { display: true, position: 'right' },
                  title: { display: true, text: 'Percentage of employees with shifts' },
                },
              },
              CHART_PRESET_OPTIONS.PERCENTAGE,
            )
          "
        />
      </sme-card>
      <sme-card v-if="hasHoursData">
        <line-chart
          :labels="hoursTimeGroupDataset"
          :datasets="timeGroupedHoursDataset"
          :chart-options="{
            aspectRatio: getInitialAspectRatio(3.5, 2),
            scales: { y: { title: { text: 'Hours' } } },
            plugins: {
              legend: { display: true, position: 'right' },
              title: { display: true, text: 'Average hours per employee' },
            },
          }"
        />
      </sme-card>
      <sme-card v-if="!hasData">
        <p class="my-2 text-center">No data to display.</p>
      </sme-card>
    </template>
  </section>
</template>

<script setup>
import mean from 'lodash/mean';
import merge from 'lodash/merge';
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { PaletteColors } from '@/Theme';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart, { CHART_PRESET_OPTIONS } from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES_VALUES, getDatasetColor, getInitialAspectRatio } from '@/utils/chart';
import { generateRandomNumber, toTitleCase } from '@/utils/common';

const APPLICABLE_EMPLOYEE_STATES = ['active', 'enrolled', 'new'];
const EMPLOYEE_STATE_LABELS = {
  active: 'Active (Wagestream user)',
  enrolled: 'Enrolled (Wagestream user)',
  new: 'New (non-Wagestream user)',
};

const getAllLineStyles = () => ({
  borderDash: [6, 6],
  lineColor: PaletteColors['brand-primary'],
});

const { divisionId } = useDivisionSelection();

const props = defineProps({ preview: Boolean });

const loading = ref(true);
const timeDataset = ref(null);
const hoursTimeDataset = ref(null);
const employeesPercentageDataset = ref(null);
const hoursDataset = ref(null);

const { timeGroupDataset, timeSeries, datasetToTimeGroupedDataset } = useTimeGroupDataset(timeDataset);
const { timeGroupDataset: hoursTimeGroupDataset, datasetToTimeGroupedDataset: hoursDatasetToTimeGroupedDataset } =
  useTimeGroupDataset(hoursTimeDataset, timeSeries);

const timeGroupedEmployeesDataset = computed(() => datasetToTimeGroupedDataset(employeesPercentageDataset));
const timeGroupedHoursDataset = computed(() => hoursDatasetToTimeGroupedDataset(hoursDataset));
const hasEmployeesData = computed(() => employeesPercentageDataset.value?.some(dataset => !!dataset.values.length));
const hasHoursData = computed(() => hoursDataset.value?.some(dataset => !!dataset.values.length));
const hasData = computed(() => hasEmployeesData.value || hasHoursData.value);

watch([divisionId, timeSeries], () => getMetrics());

const getShiftEmployeesMetrics = async () => {
  const { results } = await ApiClient.getShiftEmployeesMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const states = results.filter(result => APPLICABLE_EMPLOYEE_STATES.includes(result.id));

    timeDataset.value = time;
    employeesPercentageDataset.value = states.map((state, index) => ({
      name: EMPLOYEE_STATE_LABELS[state.id],
      values: state.values.map(value => Math.round(value * 100)),
      lineColor: PaletteColors[getDatasetColor(index)],
    }));
    employeesPercentageDataset.value.push({
      ...getAllLineStyles(),
      name: 'All',
      values: Array(time.values.length)
        .fill(0)
        .map((_, index) => Math.round(mean(employeesPercentageDataset.value.map(dataset => dataset.values[index])))),
    });
  }
};

const getShiftHoursMetrics = async () => {
  const { results } = await ApiClient.getShiftHoursMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const states = results.filter(result => APPLICABLE_EMPLOYEE_STATES.includes(result.id));

    hoursTimeDataset.value = time;
    hoursDataset.value = states.map((state, index) => ({
      name: EMPLOYEE_STATE_LABELS[state.id],
      values: state.values.map(value => Math.round(value)),
      lineColor: PaletteColors[getDatasetColor(index)],
    }));
    hoursDataset.value.push({
      ...getAllLineStyles(),
      name: 'All',
      values: Array(time.values.length)
        .fill(0)
        .map((_, index) => Math.round(mean(hoursDataset.value.map(dataset => dataset.values[index])))),
    });
  }
};

const getMetrics = async () => {
  loading.value = true;

  if (!props.preview) {
    await Promise.all([getShiftEmployeesMetrics(), getShiftHoursMetrics()]);
  } else {
    getPreviewMetrics();
  }

  loading.value = false;
};

const getPreviewMetrics = () => {
  const count = 10;
  const now = moment();
  const generatePreviewPercentages = () =>
    Array(count)
      .fill()
      .map(() => generateRandomNumber(50, 80));
  const generatePreviewHours = () =>
    Array(count)
      .fill()
      .map(() => generateRandomNumber(20, 40));

  timeDataset.value = {
    values: Array(count)
      .fill()
      .map((_, index) => now.clone().subtract(index, 'week').unix()),
  };
  hoursTimeDataset.value = timeDataset.value;
  employeesPercentageDataset.value = [
    ...APPLICABLE_EMPLOYEE_STATES.map((state, index) => ({
      name: toTitleCase(state),
      values: generatePreviewPercentages(),
      lineColor: PaletteColors[getDatasetColor(index)],
    })),
    {
      ...getAllLineStyles(),
      name: 'All',
      values: generatePreviewPercentages(),
    },
  ];
  hoursDataset.value = [
    ...APPLICABLE_EMPLOYEE_STATES.map((state, index) => ({
      name: toTitleCase(state),
      values: generatePreviewHours(),
      lineColor: PaletteColors[getDatasetColor(index)],
    })),
    {
      ...getAllLineStyles(),
      name: 'All',
      values: generatePreviewHours(),
    },
  ];
};

onBeforeMount(() => getMetrics());
</script>
