<template>
  <sub-page
    v-if="hasNeverPaidEmployees"
    title="Payroll Update"
    subtitle="Update your payroll with Wagestream bank details"
  >
    <header class="d-flex flex-column flex-sm-row align-items-end mb-3">
      <sme-alert :level="ALERT_LEVELS.WARNING" class="mb-3 mb-sm-0">
        The below employees have enrolled since the last pay day and so are yet to receive a salary through us. Make
        sure you update payroll with their Wagestream accounts so that all streams can be settled. For a full list of
        <strong>all</strong> employee details, see the
        <sme-button-link :to="{ name: 'employees' }" inherit>Employees</sme-button-link> page.
      </sme-alert>
      <export-button
        :file-name="exportFilename"
        :export-data="neverPaidEmployees"
        :export-file-rows="exportFileRows"
        :export-headers="EXPORT_HEADERS"
      />
    </header>

    <sme-detailed-table
      :fields="FIELDS"
      :items="neverPaidEmployees"
      sort-by="full_name"
      tbody-tr-class="sme-detailed-table__row--link"
      sort-asc
      sticky-header
      @row-clicked="item => $router.push({ name: 'employee', params: { employeeId: item.employee_id } })"
    >
      <template #cell(details)="data">
        <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
          <strong class="sme-detailed-table__label sme-detailed-table__label--large">
            {{ data.item.full_name }}
          </strong>
          <span class="sme-detailed-table__value sme-detailed-table__value--primary">
            {{ data.item.employee_code }}
          </span>
        </div>
      </template>

      <template #cell(personal_sort_code)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal sort code</span>
          <strong class="sme-detailed-table__value">{{ data.item.beneficiary_sort_code }}</strong>
        </div>
      </template>

      <template #cell(personal_account_number)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal account</span>
          <strong class="sme-detailed-table__value">{{ data.item.beneficiary_account_number }}</strong>
        </div>
      </template>

      <template #cell(wagestream_sort_code)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Wagestream sort code</span>
          <strong class="sme-detailed-table__value">{{ data.item.payment_sort_code }}</strong>
        </div>
      </template>

      <template #cell(wagestream_account_number)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Wagestream account</span>
          <strong class="sme-detailed-table__value">{{ data.item.payment_account_number }}</strong>
        </div>
      </template>
    </sme-detailed-table>
  </sub-page>
</template>

<script setup>
import moment from 'moment/moment';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import ExportButton from '@/components/ExportButton.vue';
import SubPage from '@/components/SubPage.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeButtonLink from '@/components/atoms/SmeButtonLink.vue';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';

const $router = useRouter();
const { neverPaidEmployees, hasNeverPaidEmployees } = useEmployeesReport();

const FIELDS = [
  {
    key: 'details',
    label: 'Name',
  },
  {
    key: 'personal_sort_code',
    label: 'Personal sort code',
  },
  {
    key: 'personal_account_number',
    label: 'Personal account number',
  },
  {
    key: 'wagestream_sort_code',
    label: 'Wagestream sort code',
  },
  {
    key: 'wagestream_account_number',
    label: 'Wagestream account number',
  },
];

const EXPORT_HEADERS = [
  'Employee Code',
  'Name',
  'Personal Sort Code',
  'Personal Account No.',
  'Wagestream Sort Code',
  'Wagestream Account No.',
];

const exportFilename = computed(() => {
  const today = moment().format('YYYY-MM-DD');
  let companyName = '';
  if (State.state.company.name) {
    companyName = State.state.company.name.toLowerCase()?.replace(/ /g, '-');
  }

  return `payroll-update-list-${companyName}-${today}`;
});

const exportFileRows = item => {
  return [
    item.employee_code,
    item.full_name,
    item.beneficiary_sort_code,
    item.beneficiary_account_number,
    item.payment_sort_code,
    item.payment_account_number,
  ];
};
</script>
