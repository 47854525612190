import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const SYNC_RESULTS_FIELD_DEFINITIONS = [
  {
    key: 'sync_result_id',
    label: 'Sync ID',
  },
  {
    key: 'entity',
    label: 'Sync Type',
  },
  {
    key: 'uploader_username',
    label: 'Uploaded By',
  },
  {
    key: 'completed_at',
    label: 'Completed On',
    formatter: value => {
      if (value) {
        return moment(value).format(DATE_FORMAT);
      }
    },
  },
  {
    key: 'duration',
    label: 'Time Taken',
    formatter: value => {
      if (value) {
        return moment.utc(moment.duration(value, 'seconds').as('milliseconds')).format('HH:mm:ss');
      }
    },
  },
  {
    key: 'inserts',
    label: 'Inserted',
    tdClass: 'text-center',
  },
  {
    key: 'updates',
    label: 'Updated',
    tdClass: 'text-center',
  },
  {
    key: 'deletes',
    label: 'Deleted',
    tdClass: 'text-center',
  },
  {
    key: 'is_ok',
    label: 'Sync Successful',
    tdClass: 'text-center',
  },
];

export const SYNC_RESULTS_FIELD_KEYS = Object.values(SYNC_RESULTS_FIELD_DEFINITIONS).map(key => key.key);

// TODO: move this to backend
export const NON_CONFIGURABLE_INTEGRATIONS = ['careplanner', 'c247', 'harri', 'roubler'];
