<template>
  <sub-page title="Pay Schedules" subtitle="Tell us how your employees are paid">
    <template v-slot:header>
      <b-button v-if="!isDisablePayscheduleEditing" @click="goToCreatePaySchedule" variant="outline-primary">
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Create pay schedule
      </b-button>
    </template>

    <sme-alert v-if="errorMessage" :html="errorMessage" level="danger" class="my-3" />

    <header class="d-flex flex-column flex-sm-row align-items-end mb-3">
      <sme-alert v-if="isAnyPayPeriodStartWithin7Days" :level="ALERT_LEVELS.INFO" class="mb-3 mb-sm-0">
        You have upcoming pay schedules for review. If your pay period start date looks incorrect, please contact
        supportops@wagestream.com.
      </sme-alert>
    </header>

    <sme-detailed-table
      :fields="FIELDS"
      :items="payschedules"
      :loading="loading"
      tbody-tr-class="sme-detailed-table__row--link"
      @row-clicked="item => $router.push({ name: 'pay-schedule', params: { payScheduleId: item.pay_schedule_id } })"
    >
      <template #empty>
        <sme-alert level="warning">
          <strong>No pay schedules found.</strong>
          <template v-if="!isDisablePayscheduleEditing">Create a pay schedule.</template>
        </sme-alert>
        <b-button v-if="!isDisablePayscheduleEditing" @click="goToCreatePaySchedule" class="mt-3" variant="primary">
          <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Create pay schedule
        </b-button>
      </template>
      <template #cell(name)="data">
        <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
          <strong class="sme-detailed-table__label sme-detailed-table__label--large">
            {{ data.item.name }}
          </strong>
          <div>
            <sme-badge
              :to="{ name: 'employees', query: { [EMPLOYEE_FILTER_KEYS.PAY_SCHEDULE]: data.item.pay_schedule_id } }"
              class="sme-detailed-table__badge"
              pill
            >
              {{ getEmployeeCountText(data.item.pay_schedule_id) }}
              <font-awesome-icon :icon="['fad', 'external-link']" class="ml-1" />
            </sme-badge>
          </div>
        </div>
      </template>
      <template #cell(delete_pay_schedule)="data">
        <div class="sme-detailed-table__cell--center sme-detailed-table__cell--end">
          <div
            v-if="!isDisablePayscheduleEditing"
            tabindex="0"
            v-b-tooltip.right
            :title="deletePayscheduleTooltipTitle(data.item.pay_schedule_id)"
            :disabled="isEligibleToDelete(data.item.pay_schedule_id)"
          >
            <b-button
              class="border-0"
              size="sm"
              :disabled="!isEligibleToDelete(data.item.pay_schedule_id)"
              :variant="!isEligibleToDelete(data.item.pay_schedule_id) ? 'outline-secondary' : 'outline-primary'"
              v-b-modal.delete-pay-schedule-modal
              @click="setPayScheduleToDelete(data.item)"
            >
              <font-awesome-icon :icon="['fad', 'trash']" />
            </b-button>
          </div>
        </div>
      </template>
    </sme-detailed-table>

    <b-modal
      id="delete-pay-schedule-modal"
      ok-title="Confirm"
      ok-variant="danger"
      centered
      hide-header
      @ok="deletePaySchedule()"
      @cancel="payScheduleToDelete = undefined"
    >
      Are you sure you want to delete this pay schedule?
    </b-modal>
  </sub-page>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import SubPage from '@/components/SubPage.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeBadge from '@/components/atoms/SmeBadge.vue';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { FILTER_KEYS as EMPLOYEE_FILTER_KEYS } from '@/pages/employees/components/EmployeesList.vue';
import State from '@/state/State.js';
import useEmployees from '@/state/composables/useEmployees';
import usePayschedules from '@/state/composables/usePayschedules';
import { pluralize, toTitleCase, getPayTypeLabel } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';
import isAnyPayPeriodStartWithin7Days from '@/pages/dashboard/components/todos/DashboardTodos.vue';
import useAnalytics from '@/state/composables/useAnalytics';

const { postAnalyticsEvent } = useAnalytics();
const $router = useRouter();
const { getEmployees, employeesLoading, employees } = useEmployees();
const { getPayschedules, loading: payschedulesLoading, payschedules } = usePayschedules();
const { isDisablePayscheduleEditing, isUseUserCountInsteadOfEmployeesCount } = useFeatureFlags();

const FIELDS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'accrual_type',
    label: 'Accrual type',
    formatter: value => toTitleCase(value),
  },
  {
    key: 'pay_period_start',
    label: 'Pay period start',
    formatter: value => toFormattedDate(value),
  },
  {
    key: 'blackout_period_start',
    label: 'Blackout start',
    formatter: value => (value ? toFormattedDate(value) : 'None'),
  },
  {
    key: 'next_payday',
    label: 'Next pay day',
    formatter: value => toFormattedDate(value),
  },
  {
    key: 'pay_schedule_code',
    label: 'Pay type',
    formatter: value => getPayTypeLabel(value),
  },
  {
    key: 'delete_pay_schedule',
  },
];

const payScheduleToDelete = ref(undefined);
const errorMessage = ref('');

const loading = computed(() => employeesLoading.value || payschedulesLoading.value);

const employeePayscheduleCounts = computed(() => {
  const payscheduleCounts = {};
  payschedules.value.forEach(ps => {
    payscheduleCounts[ps.pay_schedule_id] = employees.value.filter(
      e => e.pay_schedule_id === ps.pay_schedule_id,
    ).length;
  });
  return payscheduleCounts;
});

const deletePayscheduleTooltipTitle = payScheduleId => {
  if (employeePayscheduleCounts.value[payScheduleId] > 1) {
    return 'Pay schedules with employees assigned cannot be deleted';
  } else {
    return 'Your company must have at least one pay schedule at all times';
  }
};

const setPayScheduleToDelete = paySchedule => {
  payScheduleToDelete.value = paySchedule;
};

const getEmployeeCountText = payscheduleId => {
  const numEmployees = employeePayscheduleCounts.value[payscheduleId];
  if (isUseUserCountInsteadOfEmployeesCount.value) {
    return 'View employees';
  } else {
    return `${numEmployees} ${pluralize('employee', numEmployees)}`;
  }
};

const isEligibleToDelete = payScheduleId => {
  const payScheduleEmployeeCounts = employeePayscheduleCounts.value;
  return payScheduleEmployeeCounts[payScheduleId] < 1 && Object.keys(payScheduleEmployeeCounts).length > 1;
};

const deletePaySchedule = async () => {
  try {
    await ApiClient.deleteSmePaySchedule(State.state.company.company_id, payScheduleToDelete.value);
    getPayschedules();
  } catch (error) {
    errorMessage.value = error?.message;
  }
};

const goToCreatePaySchedule = () => {
  postAnalyticsEvent({
    intent: 'navigate_pay_schedules_add_pay_schedule',
    task: 'add_pay_schedule',
    action: 'click',
  });
  $router.push({ name: 'pay-schedule', params: { payScheduleId: 'create' } });
};

getPayschedules();
getEmployees();
</script>
