<template>
  <ValidationObserver v-slot="v" slim>
    <form class="login" @submit.prevent="v.handleSubmit(login)">
      <b-row>
        <component
          :is="isDisableLandingPageLinks ? 'span' : 'a'"
          :href="isDisableLandingPageLinks ? '' : 'https://dashboard.wagestream.com/learn/'"
          class="w-100"
        >
          <img
            :class="['login__logo', { raise: isRaise }]"
            :src="image"
            :alt="`${isRaise ? 'Raise' : partnershipName} Logo`"
          />
        </component>
      </b-row>
      <sme-alert v-if="error" :level="errorLevel" class="mb-4">{{ error }}</sme-alert>
      <sme-alert v-if="sessionExpired" level="warning" class="mb-4">
        You have been logged out due to inactivity
      </sme-alert>
      <b-row v-if="!elevationId">
        <b-col>
          <app-input
            v-model="username"
            :disabled="requiresCompany || mfaId"
            :validate-immediate="false"
            label="Username or email address"
            name="username"
            placeholder="Enter username or email address"
            rules="required"
            type="text"
            mask-valid
          />
          <template v-if="!mfaId && !requiresCompany">
            <app-input
              v-model="password"
              :disabled="requiresCompany || mfaId"
              :validate-immediate="false"
              label="Password"
              name="password"
              placeholder="Enter password"
              rules="required"
              type="password"
              mask-valid
            />
            <div class="text-right">
              <router-link class="login__recover" :to="{ name: 'forgot-password' }">
                <strong>Recover Password</strong>
              </router-link>
            </div>
            <button
              type="submit"
              class="login__submit btn btn-primary float-right waves-effect waves-themed"
              :disabled="v.invalid || requiresCompany || mfaId"
              v-show="!mfaId"
            >
              Login
            </button>
          </template>
        </b-col>
      </b-row>

      <div v-if="mfaId && !requiresCompany" class="mt-3">
        <b-form @submit.prevent="v.handleSubmit(login)">
          <ValidationProvider name="challenge pin" rules="required|regex:^\d{6}$">
            <div class="form-group">
              <label for="2fa" class="form-label">2FA Pin</label>
              <app-input
                v-model="challengePin"
                type="text"
                id="2fa"
                placeholder="6 digit pin"
                description="Check your phone SMS for a 2FA pin"
                :disabled="loading"
              >
              </app-input>
            </div>
          </ValidationProvider>
          <b-button type="submit" class="login__submit" variant="primary" :disabled="v.invalid || loading"
            >Submit</b-button
          >
          <app-loading :loading="loading" />
        </b-form>
      </div>

      <div v-if="elevationId && elevationStrategy === 'OTP_EMAIL' && !requiresCompany" class="mt-3">
        <p>
          We have sent a one-time passcode to your email address. In order to verify your identity, please enter that
          passcode below
        </p>
        <b-form @submit.prevent="v.handleSubmit(solveElevation)">
          <div class="form-group">
            <ValidationProvider name="challenge pin" rules="required|regex:^\d{6}$">
              <app-input v-model="challengePin" type="text" label="Passcode" :disabled="loading"> </app-input>
            </ValidationProvider>
          </div>

          <b-button class="float-right" type="submit" variant="primary" :disabled="v.invalid || loading"
            >Submit</b-button
          >
          <app-loading :loading="loading" />
        </b-form>
      </div>

      <div v-if="requiresCompany">
        <b-row class="mt-3">
          <b-col>
            <div class="form-group">
              <label for="password" class="form-label">Select Company</label>
              <b-list-group>
                <b-list-group-item
                  v-for="company in companies"
                  :key="company.company_id"
                  @click="chooseCompany(company)"
                >
                  <p :title="company.name">{{ company.name }}</p>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Cookies from 'js-cookie';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ApiClient from '@/ApiClient';
import { getRouteNameFromClaims } from '@/Router';
import { PaletteColors, themeImage } from '@/Theme';
import socialProofImg from '@/assets/login-social-proof.png';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { getPartnershipData } from '@/utils/partnership';

export default {
  name: 'LoginForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    AppInput,
    AppLoading,
    SmeAlert,
  },
  props: {
    image: String,
    isRaise: Boolean,
  },
  data() {
    const { name: partnershipName } = getPartnershipData();

    return {
      username: undefined,
      password: undefined,
      error: undefined,
      errorLevel: 'danger',
      loading: false,
      requiresCompany: false,
      selectedCompanyId: undefined,
      companies: [],
      mfaId: undefined,
      challengePin: undefined,
      elevationId: undefined,
      elevationStrategy: undefined,
      elevationAttemptsRemaining: undefined,
      sessionExpired: this.$route.params.sessionExpired,
      socialProofImg,
      PaletteColors,
      partnershipName,
    };
  },
  setup() {
    const { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration } = useFeatureFlags();

    return { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration };
  },
  methods: {
    themeImage,
    async login() {
      this.error = undefined;
      this.loading = true;

      let payload = {};
      if (!this.mfaId) {
        payload = { username: this.username, password: this.password };
      } else {
        payload = { '2fa_pin': this.challengePin, '2fa_id': this.mfaId };
      }

      if (this.selectedCompanyId) {
        payload.company_id = this.selectedCompanyId;
      }

      payload.deviceId = Cookies.get('wagestream_device_id');

      try {
        await ApiClient.login(payload);
        this.loading = false;
        const name = getRouteNameFromClaims(State.state.claims);

        const query = this.$route?.query?.redirect ? { redirect: decodeURIComponent(this.$route.fullPath) } : {};
        this.$router.push({ name, query: query });
      } catch (error) {
        this.loading = false;

        // Multiple companies same account:
        if (error.requires_company) {
          this.requiresCompany = true;
          this.companies = error.companies;
          this.error = 'Please choose your company to sign in.';
          this.errorLevel = 'info';
        }

        // Two factour authentication:
        else if (error.requires_mfa) {
          this.mfaId = error.mfa_id;
          this.error = 'Please enter the 6 digit PIN sent to your phone via SMS.';
          this.errorLevel = 'info';
        } else if (error.requires_elevation) {
          this.setElevation({
            elevationId: error.elevation_id,
            elevationStrategy: error.elevation_strategy,
            elevationAttemptsRemaining: error.elevation_attempts_remaining,
          });
        }

        // Generic error:
        else {
          this.error = error.message || error;
          this.errorLevel = 'danger';
        }
      }
    },
    setElevation({ elevationId, elevationStrategy, elevationAttemptsRemaining }) {
      this.elevationId = elevationId;
      this.elevationStrategy = elevationStrategy;
      this.elevationAttemptsRemaining = elevationAttemptsRemaining;
    },
    async solveElevation() {
      const deviceId = Cookies.get('wagestream_device_id');
      try {
        this.loading = true;
        const { success } = await ApiClient.checkElevation(this.elevationId, this.challengePin, deviceId);
        if (success) {
          this.login();
        } else {
          if (this.elevationAttemptsRemaining === 1) {
            // If there was only 1 attempt remaining and we get a 200 response with { success: false } on
            // the post when trying resolve the elevation, we can assume the user has run out of attempts.
            // The elevation endpoint will return a 404 once attempts are exhausted and the elevation is
            // finalized, so it's not worth re-querying the elevation to check that 0 attempts remain.
            this.clearElevation();
            this.loading = false;
            this.error = 'Number of 2FA attempts exceeded. Please try to log in again.';
          } else {
            // Check the elevation to update the amount of attempts remaining
            try {
              const elevation = await ApiClient.getElevation(this.elevationId, deviceId);
              this.setElevation({
                elevationId: elevation.elevation_id,
                elevationStrategy: elevation.elevation_strategy,
                elevationAttemptsRemaining: elevation.attempts_remaining,
              });
              this.loading = false;
              this.error = `Incorrect PIN. ${elevation.attempts_remaining} attempts remaining.`;
            } catch (error) {
              if (!error.response?.status) {
                // Network error
                this.loading = false;
                this.error = error.message;
              } else {
                this.clearElevation();
                this.loading = false;
                this.error = error.response.data?.message || error.message;
              }
            }
          }
        }
      } catch (error) {
        if (!error.response?.status) {
          // Network error
          this.loading = false;
          this.error = error.message;
        } else {
          this.clearElevation();
          this.loading = false;
          this.error = error.response.data?.message || error.message;
        }
      }
    },
    clearElevation() {
      this.elevationId = undefined;
      this.elevationStrategy = undefined;
      this.elevationAttemptsRemaining = undefined;
      this.challengePin = undefined;
    },
    chooseCompany(company) {
      this.selectedCompanyId = company.id;
      this.login();
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.login {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;

  &__footer {
    margin-top: 24px;
    font-weight: 400;
    text-align: center;
    color: var(--palette-color-default-lighten-30);
  }

  &__logo {
    object-fit: contain;
    margin-bottom: 36px;
    height: 64px;
    width: 100%;

    &.raise {
      height: 40px;
    }
  }

  &__submit {
    margin-top: 24px;
    padding: 12px 16px;
    width: 100%;
  }

  &__recover {
    margin-top: -16px;
    text-align: right;
  }

  & .form-label {
    width: 100%;
    text-align: left;
    font-weight: 400;
    color: var(--palette-color-default-lighten-30);
  }
}

.social {
  margin-top: 42px;
  width: 100%;

  &__label {
    display: inline-block;
    margin-top: 24px;
    font-size: 12px;
    width: 100%;
  }

  &__image {
    width: 95%;
  }
}
</style>
