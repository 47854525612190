<template>
  <Modal :title="`Edit ${entityType}`" :modalId="props.modalId || 'divisions-modal'" ref="modalRef" @on-close="onClose">
    <div class="body">
      <ImagesHeader canEdit :cover-url="coverUrl" :logo-url="logoUrl" @on-upload="onUpload" />
      <div class="form-container">
        <div class="input-title">Name</div>
        <b-form-input class="input-section" v-model="divisionName" disabled></b-form-input>
        <template v-if="entityType === 'site'">
          <div class="input-title">Address</div>
          <b-form-input class="input-section" v-model="addressLine1"></b-form-input>

          <div class="input-title">City</div>
          <b-form-input class="input-section" v-model="city"></b-form-input>

          <div class="input-title">Postcode</div>
          <b-form-input class="input-section" :state="isPostcodeValid" v-model="postcode"></b-form-input>
        </template>

        <div class="input-title">Description</div>
        <b-form-input class="input-section" v-model="description"></b-form-input>

        <!-- <div class="input-title">Tags</div> -->
      </div>

      <div v-if="error" class="error">Could not update details. Please try again or contact your admin.</div>
    </div>

    <template #modal-footer>
      <b-button @click="onClose" variant="outline-primary">Cancel</b-button>
      <b-button variant="primary" @click="onOverride">Save {{ entityType }} details</b-button>
    </template>
  </Modal>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import Modal from '@/components/Modal.vue';
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import { ref, computed, watch } from 'vue';
import { getEntityType } from '@/pages/talent-pool/utils/getEntityType';
import { useQuery } from '@tanstack/vue-query';
import { getEntity } from '@/queries/talent-pool/locations';

const modalRef = ref(null);

const divisionName = ref('');
const description = ref('');
const addressLine1 = ref('');
const city = ref('');
const postcode = ref('');
const coverUrl = ref('');
const logoUrl = ref('');
const entityType = ref('');

const error = ref(false);
const emit = defineEmits(['on-close']);

const props = defineProps(['locationId', 'modalId']);

const entityDetails = useQuery(getEntity(props.locationId));

const isPostcodeValid = computed(() => {
  const postcodeRegex = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i;
  return postcode.value ? postcodeRegex.test(postcode.value) : undefined;
});

const onUpload = (url, type) => {
  if (type === 'logo') {
    logoUrl.value = url;
  } else {
    coverUrl.value = url;
  }
};

const onOverride = async () => {
  if (entityType.value === 'site') {
    try {
      await ApiClient.patchDivision({
        name: divisionName.value,
        division_id: props.locationId,
        properties: {
          address: {
            postcode: postcode.value.toUpperCase(),
            address_line_1: addressLine1.value,
            city: city.value,
          },
          description: description.value,
          logo_url: logoUrl.value || undefined,
          cover_image_url: coverUrl.value || undefined,
        },
      });
      onClose();
    } catch (e) {
      error.value = true;
    }
  } else {
    try {
      await ApiClient.patchOrganisationalGroup({
        properties: {
          description: description.value,
          logo_url: logoUrl.value || undefined,
          cover_image_url: coverUrl.value || undefined,
        },
        name: divisionName.value,
        entity_id: props.locationId,
      });
      onClose();
    } catch (e) {
      error.value = true;
    }
  }
};

const onClose = async () => {
  emit('on-close');
  error.value = false;
  modalRef.value.modal.hide();
};

watch(entityDetails.fetchStatus, () => {
  if (entityDetails.data.value) {
    coverUrl.value = entityDetails?.data?.value.cover_image_url;
    divisionName.value = entityDetails?.data?.value.name;
    description.value = entityDetails?.data?.value.description;
    logoUrl.value = entityDetails?.data?.value.logo_url;
    addressLine1.value = entityDetails?.data?.value.address?.address_line_1;
    city.value = entityDetails?.data?.value.address?.city;
    postcode.value = entityDetails?.data?.value.address?.postcode;
    entityType.value = getEntityType(entityDetails?.data?.value.source);
  }
});
</script>

<style scoped lang="scss">
.body {
  padding: 1rem;
}

.input-section {
  margin-bottom: 1rem;
}

.input-title {
  font-size: 14px;
  font-weight: 600;
  margin: 6px;
}

.override-logo {
  margin: 10px 0;
}

.error {
  border: 1px solid var(--palette-color-danger);
  border-radius: 12px;
  background-color: var(--palette-color-danger-lighten-90);
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
}

.form-container {
  margin-top: 4rem;
}
</style>
