<template>
  <b-form-group class="d-inline-flex">
    <b-input-group>
      <b-form-input
        v-show="isVisible"
        ref="$searchInput"
        :value="value"
        placeholder="Search"
        @blur="hideInputIfNoValue"
        @input="emit('input', $event)"
        @keydown.enter.native="handleSearch"
      />
      <template #append>
        <b-btn
          ref="$toggleButton"
          :class="!isVisible && 'rounded'"
          :title="!isVisible ? 'Toggle Search' : 'Search'"
          :variant="!isVisible ? 'light' : 'primary'"
          @blur="hideInputIfNoValue"
          @click="value ? (isVisible ? handleSearch() : toggleInput()) : toggleInput()"
        >
          <template v-if="isVisible">Search</template>
          <font-awesome-icon v-else :icon="['fal', 'search']" />
        </b-btn>
        <b-btn
          v-show="isVisible"
          ref="$clearButton"
          class="rounded-right"
          title="Clear"
          variant="outline-primary"
          @blur="hideInputIfNoValue"
          @click="handleClear"
        >
          Clear
        </b-btn>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script setup>
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { nextTick, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const $route = useRoute();
const $router = useRouter();

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  valueKey: {
    type: String,
    default: 'searchText',
  },
});

const emit = defineEmits(['input', 'search']);

const $toggleButton = ref(undefined);
const $searchInput = ref(undefined);
const $clearButton = ref(undefined);
const isVisible = ref(false);

onMounted(() => {
  const queryValue = $route.query[props.valueKey];

  if (queryValue) {
    isVisible.value = true;

    emit('input', queryValue);
    emit('search', queryValue);
  }
});

const handleSearch = () => {
  const query = $route.query;
  let nextQuery;

  if (props.value) {
    nextQuery = { ...query, [props.valueKey]: props.value };
  } else {
    nextQuery = omit(query, props.valueKey);
  }

  if (!isEqual(query, nextQuery)) {
    $router.replace({ query: nextQuery });
  }
  emit('search');
};

const handleClear = () => {
  emit('input', '');
  nextTick(() => handleSearch());
};

const toggleInput = () => {
  isVisible.value = !isVisible.value;

  if (isVisible.value) {
    nextTick(() => $searchInput.value.$el.focus());
  }
};

const hideInputIfNoValue = event => {
  if (
    !props.value &&
    event.relatedTarget !== $toggleButton.value &&
    event.relatedTarget !== $searchInput.value.$el &&
    event.relatedTarget !== $clearButton.value
  ) {
    isVisible.value = false;
  }
};
</script>
