<template>
  <section>
    <page-list-header>
      <template #left>
        <filter-dropdown v-model="filters" :filters="filterItems" />
        <app-input
          v-model="showAll"
          type="checkbox-single"
          :name="SHOW_ALL_KEY"
          class="align-self-center mb-0 ml-2"
          switch
        >
          Show All
        </app-input>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          :file-name="`funding-position-${id}`"
          :export-data="items"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </page-list-header>
    <b-table
      :items="items"
      :fields="FIELDS"
      filter="''"
      :filter-function="filterFunction"
      :current-page="currentPage"
      :per-page="perPage"
      class="mb-3"
      sort-by="reclaim_on"
      table-class="table-minimal"
      responsive
      show-empty
      sort-desc
      @filtered="onFiltered"
    >
      <template #emptyfiltered>
        <div class="my-2 text-center">
          {{ emptyMessage }}
        </div>
      </template>
      <template #cell(employee_name)="data">
        <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">
          {{ data.item.employee_name }}
        </router-link>
      </template>
      <template v-slot:cell(description)="data">
        <sme-alert
          v-if="data.item.is_error || warningCondition(data.item)"
          :level="data.item.is_error ? 'danger' : 'warning'"
          class="p-0 bg-transparent text-dark"
        >
          {{ data.item.description }}
        </sme-alert>
        <template v-else>
          {{ data.item.description }}
        </template>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="filteredItems.length"
      :per-page="perPage"
      class="justify-content-end mb-0"
    />
  </section>
</template>

<script setup>
import omit from 'lodash/omit';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import AppInput, { CONTROL_TYPES } from '@/components/AppInput.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { toCurrency } from '@/utils';
import { toFormattedDate } from '@/utils/date';

const $route = useRoute();
const $router = useRouter();

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  initialVisibleCondition: {
    type: Function,
    default: item => item.is_error,
  },
  warningCondition: {
    type: Function,
    default: item => item.is_warning,
  },
  additionalFields: {
    type: Array,
    default: () => [],
  },
});

const FIELDS = [
  {
    key: 'employee_name',
    label: 'Employee',
    sortable: true,
    thStyle: { minWidth: '10rem' },
  },
  {
    key: 'amount_due',
    label: 'Amount due',
    formatter: value => toCurrency(value),
    sortable: true,
    thStyle: { minWidth: '7rem' },
  },
  {
    key: 'reclaim_on',
    label: 'Date due',
    sortable: true,
    thStyle: { minWidth: '7rem' },
    formatter: value => toFormattedDate(value),
  },
  ...props.additionalFields,
  {
    key: 'description',
    label: 'Suggested action',
    sortable: true,
    thStyle: { minWidth: '15rem', width: '50%' },
  },
];

const FILTER_KEYS = {
  DATE_DUE_FROM: `due_from_${props.id}`,
  DATE_DUE_TO: `due_to_${props.id}`,
};

const SHOW_ALL_KEY = `show_all_${props.id}`;

const PER_PAGE_OPTIONS = [25, 50, 100];

const filters = ref({});
const showAll = ref($route.query[SHOW_ALL_KEY] || false);
const currentPage = ref(1);
const perPage = ref(PER_PAGE_OPTIONS[0]);

const filterItems = computed(() => [
  {
    key: FILTER_KEYS.DATE_DUE_FROM,
    label: 'Due From',
    type: CONTROL_TYPES.DATE,
    controlProps: {
      max: filters.value[FILTER_KEYS.DATE_DUE_TO] ? filters.value[FILTER_KEYS.DATE_DUE_TO] : null,
    },
  },
  {
    key: FILTER_KEYS.DATE_DUE_TO,
    label: 'Due To',
    type: CONTROL_TYPES.DATE,
    controlProps: {
      min: filters.value[FILTER_KEYS.DATE_DUE_FROM] ? filters.value[FILTER_KEYS.DATE_DUE_FROM] : null,
    },
  },
]);
const filterConditions = computed(() => {
  const conditions = [];

  if (!showAll.value) {
    conditions.push(props.initialVisibleCondition);
  }

  if (filters.value[FILTER_KEYS.DATE_DUE_FROM]) {
    conditions.push(item => moment(item.reclaim_on).isSameOrAfter(filters.value[FILTER_KEYS.DATE_DUE_FROM]));
  }

  if (filters.value[FILTER_KEYS.DATE_DUE_TO]) {
    conditions.push(item => moment(item.reclaim_on).isSameOrBefore(filters.value[FILTER_KEYS.DATE_DUE_TO]));
  }

  return conditions;
});

const emptyMessage = computed(() => {
  const message = [`No ${props.id}`, !showAll.value && props.items.length ? 'requiring immediate action' : '']
    .filter(value => value)
    .join(' ');

  return `${message}${!showAll.value && props.items.length ? `. Select 'Show All' to see all ${props.id}` : ''}`;
});

const exportHeaders = computed(() => FIELDS.map(field => field.label));

watch(showAll, showAll =>
  $router.replace({
    query: { ...omit($route.query, SHOW_ALL_KEY), ...(showAll ? { [SHOW_ALL_KEY]: showAll } : null) },
  }),
);

const exportFileRows = item =>
  FIELDS.map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));

const filterFunction = item =>
  filterConditions.value.length ? filterConditions.value.every(condition => condition(item)) : true;

const filteredItems = ref(props.items.map(filterFunction));

const onFiltered = items => {
  currentPage.value = 1;
  filteredItems.value = items;
};
</script>
