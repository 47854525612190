<template>
  <b-form-group v-if="filtersState.employees" class="quick-search">
    <b-input-group>
      <div class="quick-search__icon quick-search__icon--search">
        <font-awesome-icon :icon="['fal', 'search']" />
      </div>
      <vue-autosuggest
        v-model="query"
        component-attr-id-autosuggest="quicksearch"
        :input-props="{ class: 'form-control', placeholder: 'Search Employee' }"
        :suggestions="[{ data: filteredEmployees }]"
        :section-configs="{ default: { limit: 5 } }"
        @selected="onSelected"
        :get-suggestion-value="getSuggestionValue"
      >
        <template slot-scope="{ suggestion }">
          <div class="quick-search__icon mr-2">
            <font-awesome-icon :icon="['fad', 'user']" />
          </div>
          <span class="my-suggestion-item">{{ suggestion.item.full_name }}</span>
        </template>
      </vue-autosuggest>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';

import ApiClient from '@/ApiClient';

export default {
  name: 'QuickSearch',
  components: { VueAutosuggest },
  data() {
    return {
      query: '',
      limit: 5,
    };
  },
  computed: {
    filtersState() {
      return this.$store.state.filters;
    },
    filteredEmployees() {
      const query = this.query.trim().toLowerCase();
      if (query === '') {
        return this.filtersState.employees.slice(0, this.limit);
      }

      return this.filtersState.employees
        .filter(
          employee =>
            employee['employee_code'].toLowerCase().includes(query) ||
            employee['full_name'].toLowerCase().includes(query),
        )
        .slice(0, this.limit);
    },
  },
  methods: {
    onSelected(item) {
      if (!item) {
        return;
      }
      this.$router.push({ name: 'employee', params: { employeeId: item.item.employee_id } });
      ApiClient.postAnalytics('quicksearch', { query: this.query, employee_id: item.item.employee_id });
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.full_name;
    },
  },
};
</script>

<style lang="scss" scoped>
#quicksearch {
  :deep(input) {
    padding-left: 2.5rem;
  }
}

.quick-search__icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.quick-search__icon--search {
  height: 100%;
  position: absolute;
  width: 2.5rem;
}

:deep() {
  .autosuggest__results ul {
    margin-bottom: 0;
    margin-top: 0.3em;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 999;
  }

  .autosuggest__results-item {
    align-items: center;
    background-color: var(--palette-color-base-white);
    border: 1px solid var(--palette-color-default-lighten-90);
    color: var(--palette-color-default);
    display: flex;
    margin-bottom: -1px;
    padding: 0.75rem 1.25rem;
    position: relative;
    width: 100%;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      margin-bottom: 0;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: var(--palette-color-default-lighten-95);
      text-decoration: none;
      z-index: 1;
    }
  }

  .autosuggest__results-item--highlighted {
    border-color: var(--palette-color-brand-primary);
    z-index: 2;
  }
}
</style>
