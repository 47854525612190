<template>
  <div class="shifts-list">
    <page-list-header v-if="!loadingFilters">
      <template #left>
        <filter-dropdown v-model="filters" :filters="filterItems">
          <inline-search v-if="!fixedSearchText" v-model="query.searchText" @search="getShifts" />
        </filter-dropdown>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="perPageOptions" @change="onPerPageChange" />
        </b-form-group>
        <export-button
          :file-name="exportFilename"
          :export-data="shifts"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </page-list-header>

    <component :is="nested ? 'div' : 'sme-card'" class="mb-3">
      <app-loading :loading="loading" />

      <b-table
        v-if="!loading"
        ref="table"
        primary-key="shift_id"
        :items="filteredShifts"
        :fields="displayFields"
        class="mb-0"
        table-class="table-minimal"
        :tfoot-class="!shifts?.length ? 'd-none' : ''"
        show-empty
        foot-clone
        no-footer-sorting
        no-local-sorting
        responsive
      >
        <template v-slot:cell(employee_code)="data">{{ data.item.employee_code }}</template>
        <template v-slot:cell(full_name)="data">
          <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
            data.item.full_name
          }}</router-link>
        </template>
        <template v-slot:cell(remove_shift)="data">
          <div
            v-b-tooltip.right
            class="d-inline-flex"
            :disabled="data.item.can_delete"
            title="You can only delete browser upload shifts in the current pay period"
          >
            <b-button
              v-b-modal.delete-shift-modal
              :disabled="!data.item.can_delete"
              :variant="!data.item.can_delete ? 'outline-secondary' : 'outline-primary'"
              class="border-0"
              size="sm"
              @click="shiftToDelete = data.item"
            >
              <font-awesome-icon :icon="['fad', 'trash']" />
            </b-button>
          </div>
        </template>
        <template v-slot:foot(worked_on)>
          <div class="text-right">Total:</div>
        </template>
        <template v-slot:foot(employee_code)>&nbsp;</template>
        <template v-slot:foot(full_name)>&nbsp;</template>
        <template v-slot:foot(hours)>{{ filteredShifts.length ? hoursTotal : 0 }}</template>
        <template v-slot:foot(wages)>{{
          toCurrency(filteredShifts.length ? wagesTotal : 0, state.company.default_currency)
        }}</template>
        <template v-slot:foot(wages_per_hour)>&nbsp;</template>
      </b-table>
    </component>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalResults"
      :per-page="perPage"
      class="justify-content-end"
      @change="changePage"
    />

    <b-modal
      id="delete-shift-modal"
      ok-title="Confirm"
      ok-variant="danger"
      centered
      hide-header
      @cancel="shiftToDelete = undefined"
      @ok="deleteShift"
    >
      Are you sure you want to delete this shift?
    </b-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as moment from 'moment/moment';
import ApiClient from '@/ApiClient';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import { SHIFT_FILTER_KEYS, SHIFT_FIELD_DEFINITIONS } from '@/pages/shifts/constants';
import State from '@/state/State';
import { toCurrency } from '@/utils';
import { EMPLOYEE_PAY_TYPE } from '@/utils/Employee';
import { SHIFT_SOURCE } from '@/utils/Shifts';
import { toTitleCase } from '@/utils/common';
import useFeatureFlags from '@/composables/useFeatureFlags';

const { hideShiftsHoursAndRate } = useFeatureFlags();

export default {
  name: 'ShiftsList',
  components: { AppLoading, ExportButton, FilterDropdown, InlineSearch, PageListHeader, SmeCard },
  props: {
    fieldKeys: {
      type: Array,
      default: () => {
        return hideShiftsHoursAndRate.value
          ? ['employee_code', 'full_name', 'worked_on', 'wages', 'remove_shift']
          : ['employee_code', 'full_name', 'worked_on', 'hours', 'wages', 'wages_per_hour', 'remove_shift'];
      },
    },
    filterKeys: {
      type: Array,
      default: () => [
        SHIFT_FILTER_KEYS.START_DATE,
        SHIFT_FILTER_KEYS.END_DATE,
        SHIFT_FILTER_KEYS.PAY_SCHEDULE,
        SHIFT_FILTER_KEYS.SALARY_OR_HOURLY,
        SHIFT_FILTER_KEYS.SOURCE,
        SHIFT_FILTER_KEYS.DIVISIONS,
      ],
    },
    fixedFilters: Object,
    fixedSearchText: String,
    employeeId: String,
    nested: Boolean,
  },
  data() {
    return {
      filters: {
        startDate: '',
        endDate: '',
      },
      query: { offset: 0, count: 25, searchText: this.fixedSearchText || '' },
      currentPage: 1,
      perPage: 25,
      totalResults: 0,
      perPageOptions: [25, 50, 100],
      state: State.state,
      shifts: [],
      paySchedules: [],
      payPeriods: [],
      divisions: [],
      loading: true,
      loadingFilters: true,
      hoursTotal: '',
      wagesTotal: '',
      shiftToDelete: undefined,
    };
  },
  computed: {
    // hack until backend is refactored
    filteredShifts() {
      if (this.employeeId) {
        return this.shifts.filter(shift => shift.employee_id === this.employeeId);
      } else {
        return this.shifts;
      }
    },
    filterItems() {
      return [
        {
          key: SHIFT_FILTER_KEYS.START_DATE,
          label: 'From',
          type: CONTROL_TYPES.DATE,
          controlProps: {
            max: this.filters[SHIFT_FILTER_KEYS.END_DATE] ? this.filters[SHIFT_FILTER_KEYS.END_DATE] : null,
          },
        },
        {
          key: SHIFT_FILTER_KEYS.END_DATE,
          label: 'To',
          type: CONTROL_TYPES.DATE,
          controlProps: {
            min: this.filters[SHIFT_FILTER_KEYS.START_DATE] ? this.filters[SHIFT_FILTER_KEYS.START_DATE] : null,
          },
        },
        {
          key: SHIFT_FILTER_KEYS.PAY_SCHEDULE,
          label: 'Pay Schedule',
          type: CONTROL_TYPES.RADIO,
          options: this.paySchedules.map(payschedule => ({
            text: payschedule.name,
            value: payschedule.pay_schedule_id,
          })),
          hidden: !this.paySchedules.length,
        },
        {
          key: SHIFT_FILTER_KEYS.PAY_SCHEDULE_DATE,
          label: 'Pay Period',
          type: CONTROL_TYPES.RADIO,
          options: this.payPeriods.map((payPeriod, index) => {
            return {
              text: index === 0 ? 'Current' : `${payPeriod.pay_period_start} to ${payPeriod.pay_period_end}`,
              value: payPeriod.pay_schedule_date_id,
            };
          }),
          hidden: !this.payPeriods.length,
        },
        {
          key: SHIFT_FILTER_KEYS.SALARY_OR_HOURLY,
          label: 'Pay Type',
          type: CONTROL_TYPES.RADIO,
          options: Object.values(EMPLOYEE_PAY_TYPE).map(value => ({ text: toTitleCase(value), value })),
        },
        {
          key: SHIFT_FILTER_KEYS.SOURCE,
          label: 'Source',
          type: CONTROL_TYPES.RADIO,
          options: Object.values(SHIFT_SOURCE).map(value => ({ text: toTitleCase(value), value })),
        },
        {
          key: SHIFT_FILTER_KEYS.DIVISIONS,
          label: 'Divisions',
          type: CONTROL_TYPES.CHECKBOX,
          options: this.divisions.map(division => ({
            text: division.name,
            value: division.division_id,
          })),
          hidden: !this.divisions.length,
        },
      ].filter(filter => this.filterKeys.includes(filter.key));
    },
    displayFields() {
      const fieldWidth = 90 / this.fieldKeys.length - 1;
      return this.fieldKeys.map(key => {
        return {
          thStyle: { width: `${fieldWidth}%` },
          ...SHIFT_FIELD_DEFINITIONS.find(definition => key === definition.key),
        };
      });
    },
    exportFilename() {
      const now = moment().format('YYYY-MM-DD');
      let companyName = '';
      if (this.state.company.name) {
        companyName = this.state.company.name.toLowerCase()?.replace(/ /g, '-');
      }

      return `report-${now}-shifts-list-${companyName}`;
    },
    exportHeaders() {
      return this.displayFields.filter(field => field.export !== false).map(field => field.label);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterShifts();
      },
    },
  },
  async mounted() {
    this.getFilterValues();
  },
  methods: {
    toCurrency,
    getShifts: debounce(async function () {
      this.loading = true;

      const results = await ApiClient.getPaginatedShifts(this.query).then(({ data }) => {
        this.shifts = data.shifts;
        this.totalResults = data.count.count;
        this.hoursTotal = data.total_hours;
        this.wagesTotal = data.total_wages;
        this.loading = false;
      });

      return results;
    }, 500),
    async getFilterValues() {
      if (this.filterKeys.includes(SHIFT_FILTER_KEYS.PAY_SCHEDULE)) {
        this.paySchedules = (await ApiClient.getCompanyPaySchedules(this.state.company.company_id))?.data || [];
      }

      if (this.filterKeys.includes(SHIFT_FILTER_KEYS.DIVISIONS)) {
        this.divisions = (await ApiClient.getDivisions())?.data || [];
      }

      if (
        this.fixedFilters?.[SHIFT_FILTER_KEYS.PAY_SCHEDULE] &&
        this.filterKeys.includes(SHIFT_FILTER_KEYS.PAY_SCHEDULE_DATE)
      ) {
        const payPeriods =
          (
            await ApiClient.getSmePaySchedule(
              this.state.company.company_id,
              this.fixedFilters[SHIFT_FILTER_KEYS.PAY_SCHEDULE],
            )
          )?.dates || [];

        this.payPeriods = payPeriods
          .filter(payPeriod => moment(payPeriod.pay_period_start) < moment())
          .map((payPeriod, index, payPeriods) => ({
            ...payPeriod,
            pay_period_end: payPeriods[index + 1]?.pay_period_start
              ? moment(payPeriods[index + 1]?.pay_period_start)
                  .subtract(1, 'd')
                  .format('YYYY-MM-DD')
              : undefined,
          }))
          .reverse();
      }

      this.loadingFilters = false;
    },
    async changePage(newPageNumber) {
      this.query = { ...this.query, offset: this.perPage * (newPageNumber - 1), count: this.perPage };
      this.currentPage = newPageNumber;

      await this.getShifts();
    },
    async onPerPageChange() {
      this.query = { ...this.query, count: this.perPage, offset: 0 };
      this.currentPage = 1;

      await this.getShifts();
    },
    async deleteShift() {
      await ApiClient.deleteShift(this.shiftToDelete.shift_id);
      await this.getShifts();
      this.$appToast(
        `Successfully deleted shift for employee ${this.shiftToDelete.full_name} on ${this.shiftToDelete.worked_on}`,
        {
          title: 'Removed shift',
          variant: 'success',
        },
      );

      this.shiftToDelete = undefined;
    },
    async filterShifts() {
      const { pay_schedule_date: payScheduleDate, ...filters } = this.filters;

      if (payScheduleDate) {
        Object.assign(filters, this.getPayScheduleDateFilters(payScheduleDate));
      }

      this.query = {
        count: this.query.count,
        offset: 0,
        searchText: this.query.searchText,
        ...this.fixedFilters,
        ...filters,
      };
      this.currentPage = 1;

      await this.getShifts();
    },
    getPayScheduleDateFilters(payScheduleDate) {
      const payPeriod = this.payPeriods.find(payPeriod => payPeriod.pay_schedule_date_id === payScheduleDate);

      if (!payPeriod) {
        return;
      }

      let { pay_period_start: startDate, pay_period_end: endDate } = payPeriod;

      if (this.filters.startDate && moment(this.filters.startDate).isAfter(startDate)) {
        startDate = this.filters.startDate;
      }

      if (this.filters.endDate && endDate && moment(this.filters.endDate).isBefore(endDate)) {
        endDate = this.filters.endDate;
      }

      return { startDate, ...(endDate ? { endDate } : null) };
    },
    exportFileRows(item) {
      return this.displayFields
        .filter(field => field.export !== false)
        .map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));
    },
  },
};
</script>
