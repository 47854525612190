var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c(_setup.PageSubHeader,{staticClass:"mt-4",attrs:{"title":"App Features"}}),_c(_setup.SmeCard,[(_setup.loading)?_c(_setup.AppLoading):[(_setup.dataset.values.length)?_c(_setup.BarChart,{attrs:{"series-x":_setup.labels,"series-y":_setup.dataset,"chart-options":{
          aspectRatio: _setup.getInitialAspectRatio(3.5, 2),
          indexAxis: 'y',
          scales: {
            x: { title: { display: true, text: _setup.dataset.name } },
            y: { grid: { display: false }, title: { display: false } },
          },
        }}}):_c('p',{staticClass:"my-2 text-center"},[_vm._v("No data to display.")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }