<template>
  <div>
    <div id="app" class="page-wrapper">
      <div class="page-inner">
        <!-- <app-aside /> -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/ApiClient';
import State from '@/state/State';

export default {
  name: 'AppPage',
  data() {
    return {
      state: State.state,
    };
  },
  mounted() {
    if (this.state.claims?.a || this.state.claims?.m) {
      ApiClient.getIntegrations(this.state.company.company_id).catch(error => {
        this.localError = error.message;
      });
    }
    if (this.state.claims?.cid) {
      ApiClient.getFilters().catch(error => {
        this.localError = error.message;
      });
    }
  },
};
</script>

<style lang="scss">
.header-icon {
  cursor: pointer !important;
}

.fa {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Font Awesome 5 Pro';
}

.header-icon-big svg {
  font-size: 2rem !important;
}

.company-name h3 {
  margin-bottom: 0;
}

.page-header .header-icon:not(.btn):hover > [class*='fa-']:only-child,
.page-header .header-icon:not(.btn)[data-toggle='dropdown'][aria-expanded='true'] > [class*='fa-']:first-child {
  color: var(--palette-color-brand-primary) !important;
}

.header-icon:not(.btn)[data-toggle='dropdown'][aria-expanded='true']::after {
  background: var(--palette-color-brand-primary) !important;
  opacity: 0.4 !important;
  top: 0.85rem !important;
}

ol.breadcrumb {
  margin: 0;
}
</style>
