<template>
  <div>
    <page-list-header>
      <template #left>
        <inline-search v-model="searchText" @search="onSearch" />
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          :file-name="userTypePlural"
          :export-data="filteredUsers"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </page-list-header>

    <sme-card class="mb-3">
      <app-loading :loading="loading" />

      <b-table
        v-if="!loading"
        :items="companyUsers"
        :fields="fields"
        :filter="searchCriteria"
        :current-page="currentPage"
        :per-page="perPage"
        primary-key="user_id"
        class="mb-0"
        table-class="table-minimal"
        responsive
        show-empty
        @filtered="onFiltered"
      >
        <template #cell(phone)="data">
          <a :href="toTelHref(data.item.phone)">{{ data.item.phone }}</a>
        </template>
        <template #cell(email)="data">
          <a :href="toMailtoHref(data.item.email)">{{ data.item.email }}</a>
        </template>
        <template #cell(delete)="data">
          <b-button
            v-if="deleteFunction"
            v-b-modal.delete-user-modal
            class="border-0"
            size="sm"
            variant="outline-primary"
            @click="userToDelete = data.item"
          >
            <font-awesome-icon :icon="['fad', 'trash']" />
          </b-button>
        </template>
      </b-table>
    </sme-card>

    <b-pagination
      v-model="currentPage"
      :total-rows="filteredUsers.length"
      :per-page="perPage"
      class="justify-content-end"
      hide-goto-end-buttons
    />

    <b-modal
      id="delete-user-modal"
      ok-title="Confirm"
      ok-variant="danger"
      centered
      hide-header
      @cancel="userToDelete = undefined"
      @ok="deleteUser"
    >
      Are you sure you want to delete {{ userType }} {{ userToDelete?.username }}?
    </b-modal>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref, watch } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import { toMailtoHref, toTelHref } from '@/utils/common';
import { toDateFromNowText } from '@/utils/date';

const currentInstance = getCurrentInstance();

const PER_PAGE_OPTIONS = [25, 50, 100];

const props = defineProps({
  users: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  userType: {
    type: String,
    required: true,
  },
  userTypePlural: {
    type: String,
    required: true,
  },
  deleteFunction: Function,
});

const emit = defineEmits(['updated']);

const filteredUsers = ref(props.users);
const currentPage = ref(1);
const perPage = ref(PER_PAGE_OPTIONS[0]);
const searchText = ref('');
const searchCriteria = ref('');
const userToDelete = ref(undefined);

const fields = computed(() => [
  {
    key: 'username',
    label: 'Username',
    sortable: true,
  },
  {
    key: 'phone',
    label: 'Phone',
    sortable: true,
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    key: 'created_at',
    label: 'Created',
    sortable: true,
    formatter: toDateFromNowText,
  },
  {
    key: 'last_login_at',
    label: 'Last Login',
    sortable: true,
    formatter: toDateFromNowText,
  },
  ...(props.deleteFunction
    ? [
        {
          key: 'delete',
          label: '',
          class: 'text-right',
        },
      ]
    : []),
]);

const isSupportUser = user => user.username.includes('-support') || user.phone?.includes('0800 009 6354');

const exportHeaders = computed(() => fields.value.map(field => field.label));
const companyUsers = computed(() => props.users.filter(user => !isSupportUser(user)));

watch(
  () => companyUsers.value,
  users => onFiltered(users),
);

const deleteUser = async () => {
  try {
    await props.deleteFunction(State.state.company.company_id, userToDelete.value.user_id);

    currentInstance.proxy.$appToast(`Successfully removed user as ${props.userType}`, {
      title: `Deleted ${props.userType}`,
      variant: 'success',
    });

    emit('updated');
  } catch {
    currentInstance.proxy.$appToast('Please contact support if error persists', {
      title: `Error deleting ${props.userType}`,
      variant: 'danger',
    });
  }
};

const onFiltered = users => {
  filteredUsers.value = users;
  currentPage.value = 1;
};

const onSearch = () => {
  searchCriteria.value = searchText.value;
};

const exportFileRows = item => {
  return fields.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );
};
</script>
