<template>
  <app-page-layout
    v-if="
      $route.path !== '/login/impersonate' &&
      $route.path !== '/login/forgot-password' &&
      $route.path !== '/login' &&
      $route.path.indexOf('/register') == -1 &&
      $route.path !== '/kyc' &&
      $route.path !== '/employee/comms' &&
      $route.name !== 'sme-post-signup' &&
      $route.name !== 'sme-oauth2' &&
      $route.name !== 'sme-init-integration' &&
      $route.name !== 'sme-lead-touch'
    "
  >
    <router-view :key="$route.path" />
  </app-page-layout>
  <router-view v-else :key="$route.path"> </router-view>
</template>

<script>
import Cookies from 'js-cookie';
import { Userpilot } from 'userpilot';
import uuidv4 from 'uuid/v4';
import ApiClient, { isLoggedIn } from '@/ApiClient';
import AppPageLayout from '@/components/AppPageLayout.vue';
import State from '@/state/State';

export default {
  components: { AppPageLayout },
  name: 'App',
  created() {
    if (Cookies.get('wagestream_device_id') == null) {
      // expires: https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#section-4.1.2.1
      Cookies.set('wagestream_device_id', uuidv4(), { expires: 400 });
    }
  },
  mounted: function () {
    // call auth/me every 60 seconds
    if (isLoggedIn()) {
      ApiClient.getUser();

      setTimeout(() => {
        // dont show this on SME onboard flow
        // Boot intercom
        // possible race condition with getting user
        if (State.state.user?.user_id) {
          this.$intercom?.boot({
            user_id: State.state.user.user_id,
            user_hash: State.state.credentials.intercom_user_hash,
            name: State.state.user.username,
            email: State.state.user.email,
            company_id: State.state.company.company_id,
            company: {
              name: State.state.company.name,
              id: State.state.company.company_id,
              self_funded: State.state.company.properties.funding_self,
              size: 250,
            },
            self_funded: State.state.company.properties.funding_self,
            hide_default_launcher: true,
          });

          Userpilot.identify(State.state.user.user_id, {
            name: State.state.user.username,
            email: State.state.user.email,
            created_at: new Date(State.state.user.created_at).getTime(),
            company: {
              name: State.state.company.name,
              id: State.state.company.company_id,
              created_at: new Date(State.state.company.created_at).getTime(),
            },
          });

          // Front
          // console.log('FrontChat init (App.vue): '+State.state.user.email+' '+State.state.user.username+' '+State.state.credentials.front_user_hash+' '+State.state.company.name+' '+State.state.company.company_id);
          window.FrontChat?.('init', {
            chatId: 'eff9a91d417917f4d1378bcd9c9443e8',
            useDefaultLauncher: true,
            email: State.state.user.email,
            name: State.state.company.name,
            userHash: State.state.credentials.front_user_hash,
            customFields: {
              company_name: State.state.company.name,
              company_id: State.state.company.company_id,
              user_name: State.state.user.username,
            },
          });
        }
      }, 2000);
    }
    window.setInterval(() => {
      if (isLoggedIn()) {
        ApiClient.getUser();
      }
    }, process.env.VUE_APP_ME_REFRESH || 60000);
  },
  watch: {
    $route() {
      this.$intercom?.update();
      Userpilot.reload();
    },
  },
};
</script>

<style lang="scss">
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.show > .btn-primary.dropdown-toggle {
  color: var(--palette-color-base-white);
  background-color: var(--palette-color-brand-primary-darken-10) !important;
  border-color: var(--palette-color-brand-primary-darken-10);
}

@media (min-width: 576px) {
  ol.breadcrumb > li.breadcrumb-item {
    max-width: none !important;
    &:hover {
      max-width: none !important;
    }
  }
}

/* modal */
.modal-dialog {
  header {
    border-radius: 0;
  }
}
</style>
