<template>
  <page-metrics :metrics="metrics" />
</template>

<script setup>
import moment from 'moment';
import { onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import PageMetrics from '@/components/PageMetrics.vue';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES } from '@/utils/chart';
import { toPercentage } from '@/utils/common';
import { toMonthDescriptor } from '@/utils/date';

const { divisionId } = useDivisionSelection();
const bankingInfo = getBankingInfo();

const ALL_SNAPSHOT_METRICS = {
  track_users: {
    title: 'Track views',
    value: undefined,
    backgroundColor: 'core-2-lighten-20',
  },
  stream_users: {
    title: `${bankingInfo.stream.label} views`,
    value: undefined,
    backgroundColor: 'brand-primary',
  },
  save_users: {
    title: `${bankingInfo.save.label} views`,
    value: undefined,
    backgroundColor: 'core-3',
  },
  hub_users: {
    title: 'Hub views',
    value: undefined,
    backgroundColor: 'core-4',
  },
  home_users: {
    title: 'Home views',
    value: undefined,
    backgroundColor: 'core-5',
  },
};

const metrics = ref({});

watch(divisionId, () => getMetrics());

const getMetrics = async () => {
  const { results } = await ApiClient.getAppFeaturesMetrics(TIME_SERIES.MONTH_TO_MONTH, divisionId.value);
  const uniqueUsers = results.find(result => result.id === 'unique_users').calculated.last_complete;
  const monthDescriptor = toMonthDescriptor(
    moment.unix(results.find(result => result.id === 'time').calculated.last_complete),
  );

  const enabledMetrics = {};
  results.forEach(result => {
    if (result.id in ALL_SNAPSHOT_METRICS) {
      enabledMetrics[result.id] = ALL_SNAPSHOT_METRICS[result.id];
      enabledMetrics[result.id].title += ` ${monthDescriptor}`;
      enabledMetrics[result.id].value = result.calculated.last_complete;
      enabledMetrics[result.id].secondaryValue = toPercentage(result.calculated.last_complete / uniqueUsers);
    }
  });
  metrics.value = enabledMetrics;
};

onBeforeMount(() => getMetrics());
</script>
