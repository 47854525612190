<template>
  <div class="container">
    <div class="box">
      <span>Split £500 with every new business you refer to Wagestream!</span>
      <b-button
        variant="primary"
        v-b-modal="'referrals-modal'"
        @click="postAnalyticsEvent({ intent: 'open_referrals_modal', task: 'referral', action: 'click' })"
        >I'm interested!&nbsp;&nbsp;<ArrowRight height="16" width="16" :color="PaletteColors['base-white']"
      /></b-button>
    </div>
  </div>
</template>

<script setup>
import ArrowRight from '@/assets/icons/ArrowRight.vue';
import { PaletteColors } from '@/Theme';
import useAnalytics from '@/state/composables/useAnalytics';
import { onBeforeMount } from 'vue';

const { postAnalyticsEvent } = useAnalytics();

onBeforeMount(() => {
  postAnalyticsEvent({ intent: 'view_referrals_advertisement', task: 'referral', action: 'view' });
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  min-height: 10rem;
}
.box {
  width: 100%;
  height: 100%;
  color: var(--palette-color-default);
  background-color: var(--palette-color-core-1-lighten-60);
  border: 2px solid var(--palette-color-base-white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}
</style>
