<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- It is used only for formatting for i18n values here, so XSS attack is not a case. -->
  <div class="outer">
    <div class="inner">
      <!-- Step: Final Loading & Redirect -->
      <main>
        <app-loading />
        <p class="text-center">{{ info }}</p>
      </main>
    </div>
  </div>
</template>

<script>
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading';

// Like e.g. bit.ly maps incoming public marketing URLs to ultimate target
// Unlike bit.ly store them all in a map :-)
const TOUCH_POINTS = {
  'sme-a': 'https://signup.wagestream.com/sme/employers/',
  'sme-cases': 'https://signup.wagestream.com/sme/cases/',
  'sme-burger': 'https://signup.wagestream.com/sme/case-burger/',
  'sme-camden': 'https://signup.wagestream.com/sme/case-camden/',
  'sme-toolstation': 'https://signup.wagestream.com/sme/case-toolstation/',
  'sme-b': 'https://signup.wagestream.com/sme/b/',
  'sme-b-casestudy': 'https://signup.wagestream.com/sme/b/#case-study',
  'sme-b-howitworks': 'https://signup.wagestream.com/sme/b/#how-it-works-section',
  'sme-b-faq': 'https://signup.wagestream.com/sme/b/#faq',

  'ext-hospitality-shift-uptake-flexible-pay':
    'https://wagestream.com/en/resources/hospitality-shift-uptake-flexible-pay/',
  'ext-lbg-83020': 'https://www.politicshome.com/members/article/lbg-83020',
  'ext-fifth-of-uk-smes-halt-growth-and-investment-amid-economic-pressures':
    'https://www.accountancytoday.co.uk/2022/06/15/fifth-of-uk-smes-halt-growth-and-investment-amid-economic-pressures/',
  'ext-half-of-sme-retailers-are-seeing-customer-numbers-grow-as-shoppers-seek-out-supplies-and-value-for-money':
    'https://internetretailing.net/industry/half-of-sme-retailers-are-seeing-customer-numbers-grow-as-shoppers-seek-out-supplies-and-value-for-money/',
  'ext-most-small-firms-fear-long-term-fallout-uk-cost-living-crisis':
    'https://www.theguardian.com/business/2022/may/30/most-small-firms-fear-long-term-fallout-uk-cost-living-crisis',
  'ext-uk-beer-costs-soar-average-price-pint-tops-8-pounds-one-london-pub':
    'https://www.theguardian.com/food/2022/jun/04/uk-beer-costs-soar-average-price-pint-tops-8-pounds-one-london-pub',
  'ext-cost-of-living-crisis-work-case-study-natalie':
    'https://wagestream.com/en/resources/cost-of-living-crisis-work-case-study-natalie/',
  'ext-staff-money_worries_hurting_your_business':
    'http://wstream.wpengine.com/wp-content/uploads/2022/02/staff-money_worries_hurting_your_business.pdf',
  'ext-employers-support-staff-cost-of-living-crisis':
    'https://wagestream.com/en/resources/employers-support-staff-cost-of-living-crisis/?utm_source=colcasestudies&utm_medium=article&utm_campaign=costofliving&utm_content=natalie&utm_term=',
  'ext-Everything-you-need-to-fill-your-job-ads-27-faster-with-Wagestream':
    'https://wagestream.notion.site/Everything-you-need-to-fill-your-job-ads-27-faster-with-Wagestream-431cb2826df44cab98ec5eb4c01062db',
  'ext-why-employers-must-improve-employees-financial-well-being':
    'https://www.forbes.com/sites/forbesbusinesscouncil/2022/06/21/why-employers-must-improve-employees-financial-well-being/?sh=10a7e8734f5e',
  'ext-10-free-things-small-business-owners-can-do-now-to-engage-and-retain-talent':
    'https://www.forbes.com/sites/forbescoachescouncil/2022/06/21/10-free-things-small-business-owners-can-do-now-to-engage-and-retain-talent/?sh=472ead9b4542',
  'ext-cost-of-living-crisis-uk-households-charts-inflation':
    'https://www.theguardian.com/business/ng-interactive/2022/jun/21/cost-of-living-crisis-uk-households-charts-inflation',

  'ext-lloyds-bank-report':
    'https://wstream.wpengine.com/wp-content/uploads/2022/02/Wagestream-Lloyds-Bank-report-3-1.pdf',
  'ext-how-to-improve-energy-efficiency':
    'https://www.fsb.org.uk/resources-page/energy-management-plans-how-to-improve-energy-efficiency-in-your-business.html',
  'ext-cost-of-living-crisis-expenses':
    'https://wagestream.com/en/resources/cost-of-living-crisis-expenses-work-policies/',
  'ext-financial-wellbeing-in-hospitality-sector':
    'https://wstream.wpengine.com/wp-content/uploads/2022/03/Financial-wellbeing-in-hospitality-sector.pdf',
  'ext-whatsapp': 'https://api.whatsapp.com/send?phone=447775137417',

  'what-is-flexible-pay': 'https://signup.wagestream.com/sme/flexible-pay/',
  'support-with-cost-of-living': 'https://signup.wagestream.com/sme/cost-of-living/',
};

export default {
  name: 'LeadTouch',
  components: {
    AppLoading,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      info: undefined,
    };
  },
  async mounted() {
    const point = this.$route.params.point;
    await tracker.trackTouch(point);

    let query = '';
    let m = window.location.href.match(/^(.*?)\?(.*?)#(.*)$/);
    if (m) {
      query = m[2];
    } else {
      m = window.location.href.match(/^(.*?)#(.*?)\?(.*)$/);
      if (m) {
        query = m[3];
      }
    }

    let target = TOUCH_POINTS[point];
    if (target) {
      // point is a predefined code
      if (target.startsWith('ext')) {
        // no utm passing
      } else if (target.indexOf('?') == -1) {
        target = target + '?' + query;
      } else {
        target = target + '&' + query;
      }
    } else {
      // point might be a full URL
      if (point.indexOf('wagestream.com') != -1) {
        // propagate utm terms if in wagestream land
        target = 'https://' + point + '?' + query;
      } else {
        target = 'https://' + point;
      }
    }

    if (target) {
      window.location = target;
    } else {
      console.error(`Unrecognised touch point: ${point}`);
      this.info = 'Sorry, we cant find that content.';
    }
  },
};
</script>

<style scoped lang="scss">
.error {
  margin-bottom: 35px;
}

.btn {
  width: 100%;
}

.role-description,
.company-description {
  font-weight: 600;
  font-size: 20px;
  color: var(--palette-color-default-lighten-20);
}

.role-selection {
  display: block;
  margin: 15px auto;
  border: 1px solid var(--palette-color-default-lighten-90);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px;
  font-weight: normal;
  font-size: 16px;
  color: var(--palette-color-default-lighten-20);
}

.role-selection:hover,
.company-suggestion:not(.company-suggestion-disabled):hover {
  border-color: var(--palette-color-brand-primary);
  color: var(--palette-color-brand-primary);
}

.role-selection:last-child {
  margin-bottom: 0;
}

.go-back {
  cursor: pointer;
  position: absolute;
  color: var(--palette-color-default-lighten-20);
  left: 30px;
  top: 30px;
}

.password-help {
  margin-top: -10px;
  color: var(--palette-color-default-lighten-20);
  font-size: 14px;
}

.privacy-policy {
  display: block;
  margin-top: 10px;
  margin-bottom: 75px;
  text-align: center;
  color: var(--palette-color-default-lighten-20);
  font-size: 14px;
}

.login-suggestion {
  position: absolute;
  background: var(--palette-color-base-background);
  text-align: center;
  color: var(--palette-color-default-lighten-20);
  font-size: 16px;
  font-weight: 400;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
}

.login-suggestion a {
  text-decoration: underline !important;
}

.company-description,
.details-description {
  margin-bottom: 35px;
}

.company-count {
  color: var(--palette-color-default-lighten-20);
}

.company-suggestion {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  border: 1px solid var(--palette-color-default-lighten-90);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 16px;
  padding: 8px;
  color: var(--palette-color-default-lighten-20);
}

.company-suggestion-disabled {
  cursor: inherit;
  opacity: 0.5;
}

.company-suggestion input {
  display: inline-block;
  margin: auto 15px auto 5px;
}

.company-suggestion .company-info {
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.company-loading {
  margin: 65px auto 48px auto;
}

p {
  margin-top: 20px;
  color: var(--palette-color-default-lighten-20);
}

@media (max-width: 600px) {
  .inner {
    width: 100vw;
    margin-bottom: 120px;
  }
  .logo {
    margin-top: 36px;
    margin-bottom: 15px;
  }
  .role-selection {
    font-size: 14px;
  }
  .title1,
  .title2 {
    font-size: 25px;
    line-height: 30px;
  }
  .subtitle {
    margin-top: 10px;
    font-size: 16px;
  }
  .main {
    width: 90vw;
    padding: 30px 25px 40px 25px;
    margin: 25px auto;
  }
  .go-back {
    position: relative;
    top: 0;
    left: 0;
  }
  p {
    margin-top: 12px;
  }
  .details-description {
    margin-bottom: 15px;
  }
  .form-group {
    margin-bottom: 1rem;
  }
}
</style>
