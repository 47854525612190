var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-page',{attrs:{"title":`Reset ${_vm.employee ? _vm.employee.full_name : 'Employee'}'s password`,"previous":[
    { label: 'Employees', to: { name: 'employees' } },
    {
      label: _vm.employee ? _vm.employee.full_name : 'Employee',
      to: { name: 'employee', params: { employeeId: _vm.$route.params.employeeId } },
    },
  ],"error":_vm.error,"icon":"lock-open"}},[_c('app-loading',{attrs:{"loading":_vm.loading}}),(!_vm.loading && !_vm.saved)?[_c('sme-alert',{staticClass:"mb-3",attrs:{"level":"warning"}},[_vm._v(" We highly recommend that employees manage their own passwords. However, if you need to trigger a password reset for this employee, you may do so below. "),_c('br'),_vm._v(" A link will be e-mailed to the employee so they can complete the process. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return v.handleSubmit(_vm.onSubmit)}}},[_c('app-input',{attrs:{"type":"text","name":"reason","rules":"required","label":"Reason for reset","disabled":_vm.saving || _vm.saved,"validate-immediate":false},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('page-sub-footer',[_c('b-button',{attrs:{"data-testid":"reset-password-button","type":"submit","variant":"primary","disabled":v.invalid || _vm.saving || _vm.saved}},[(_vm.saving)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.saving ? 'Resetting password...' : 'Reset password')+" ")],1)],1)],1)]}}],null,false,2199300194)})]:_vm._e(),(_vm.saved)?_c('sme-alert',{attrs:{"level":"good"}},[_vm._v(" We have emailed "+_vm._s(_vm.employee.full_name)+" with a link to reset ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }