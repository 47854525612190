<template>
  <app-auth-page
    title="Give your employees flexible pay"
    :subtitle="
      !isDisableRegistration
        ? 'Give your employees the flexibility to access, track, build and better manage their pay.'
        : ''
    "
    :image="themeImage('login-tablet.png')"
    :buttons="
      [
        !isDisableRegistration && {
          text: 'Register',
          link: '/register',
          style: {
            background: PaletteColors['brand-primary'],
            color: PaletteColors['base-white'],
            outline: 'none',
          },
        },
        !isDisableLandingPageLinks && {
          text: 'Learn More →',
          link: 'https://sme.wagestream.com/learn/employers',
        },
      ].filter(button => !!button)
    "
  >
    <template v-if="!isDisablePartnerLogos" #footer>
      <span class="social__label">As used by our partners:</span>
      <img class="social__image" :src="socialProofImg" />
    </template>
    <set-password :theme-image="themeImage('logo.svg')" />
  </app-auth-page>
</template>

<script>
import { PaletteColors, themeImage } from '@/Theme';
import socialProofImg from '@/assets/login-social-proof.png';
import AppAuthPage from '@/components/AppAuthPage.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import SetPassword from '@/components/SetPassword.vue';

export default {
  name: 'Login',
  components: {
    AppAuthPage,
    SetPassword,
  },
  data() {
    return {
      socialProofImg,
      PaletteColors,
    };
  },
  setup() {
    const { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration } = useFeatureFlags();

    return { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration };
  },
  methods: {
    themeImage,
  },
};
</script>

<style scoped lang="scss">
.social {
  margin-top: 42px;
  width: 100%;

  &__label {
    display: inline-block;
    margin-top: 24px;
    font-size: 12px;
    width: 100%;
  }

  &__image {
    width: 95%;
  }
}
</style>
