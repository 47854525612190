<template>
  <user-form
    v-model="admin"
    :duplicates="errorDuplicated"
    :error="saveError"
    :cancel-url="redirectUrl"
    :hide-username="shouldHideUsername"
    @save="onSave"
    @duplicate-input="onDuplicateInput"
  ></user-form>
</template>

<script>
import uuidv4 from 'uuid/v4';
import ApiClient from '@/ApiClient';
import UserForm from '@/components/UserForm.vue';
import State from '@/state/State';

const defaultAdmin = {
  username: undefined,
  phone: null,
  email: undefined,
  password: undefined,
  confirmed: undefined,
};

export default {
  name: 'AdminAddForm',
  components: { UserForm },
  props: {
    redirectUrl: {
      type: Object,
      default: () => ({ name: 'admins' }),
    },
    hideUsername: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.shouldHideUsername = State.state.isSelfServeSME;
  },
  data() {
    return {
      error: undefined,
      saveError: undefined,
      errorDuplicated: {},
      loading: true,
      admin: { ...defaultAdmin },
      saving: false,
      success: false,
      shouldHideUsername: this.hideUsername,
    };
  },
  methods: {
    onSave() {
      this.saving = true;
      const newAdmin = {
        ...this.admin,
        user_id: uuidv4(),
      };
      if (!newAdmin.username) {
        newAdmin.username = newAdmin.email;
      }

      ApiClient.addAdmin(State.state.company.company_id, newAdmin).then(
        () => {
          this.$appToast(`${newAdmin.username}'s admin user has been added`, {
            title: 'Successfully added Admin',
            variant: 'success',
          });
          this.$router.push(this.redirectUrl);
        },
        err => {
          this.saving = false;
          this.errorDuplicated = {
            username: false,
            phone: false,
            email: false,
          };
          if (err.message.includes('already taken')) {
            if (err.message.includes('Username')) {
              this.errorDuplicated.username = true;
            }
            if (err.message.includes('users_email_key')) {
              this.errorDuplicated.email = true;
            }
            if (err.message.includes('users_phone_key')) {
              this.errorDuplicated.phone = true;
            }
          } else {
            this.saveError = err.message;
          }
          this.$appToast('Please check the form errors', {
            title: 'Error adding admin',
            variant: 'danger',
          });
        },
      );
    },
    onDuplicateInput(fieldName) {
      this.errorDuplicated[fieldName] = false;
    },
  },
};
</script>
