export const ratingStarReviewTags = {
  1: ['late', 'incompetent', 'unprofessional', 'unfriendly'],
  2: ['slow learner', 'low motivation', 'unfriendly'],
  3: ['competent', 'good listener', 'friendly'],
  4: ['quick learner', 'strong skills', 'warm'],
  5: ['hospitality pro', 'personable', 'exceptional'],
};

export const ratingStarDescriptions = {
  1: 'Bad',
  2: 'Sub-standard',
  3: 'Fair',
  4: 'Good',
  5: 'Great',
};

export const getAverageRating = ratings => {
  const ratingSum = ratings?.map(rating => rating.rating).reduce((previous, current) => previous + current, 0);

  const numberOfRatings = ratings?.length;
  const ratingAverage = numberOfRatings ? ratingSum / numberOfRatings : null;
  return ratingAverage ? ratingAverage.toFixed(1) : 0;
};
