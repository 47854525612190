<template>
  <div class="ap">
    <div class="ap__banner">
      <div class="ap__proof">
        <slot name="header"></slot>
        <slot name="title">
          <h2 class="ap__proof_title">{{ title }}</h2>
        </slot>
        <slot name="description">
          <p class="ap__proof_description">{{ subtitle }}</p>
        </slot>
        <slot name="buttons">
          <div>
            <component
              v-for="button in buttons"
              :is="button.link.startsWith('http') ? 'a' : 'router-link'"
              :style="button.style || {}"
              :href="button.link"
              :to="button.link"
              class="ap__button"
              target="__blank"
              :key="button.text"
              @click="registerClick(button.text)"
              @click.native="registerClick(button.text)"
            >
              {{ button.text }}
            </component>
          </div>
        </slot>
        <slot name="footer"></slot>
      </div>
      <slot name="image">
        <img class="ap__image" :src="image" />
      </slot>
    </div>
    <div v-if="showApContainer" class="ap__container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import tracker from '@/Tracker';
import phoneImg from '@/assets/login-phone.png';

export default {
  name: 'AppAuthPage',
  props: {
    image: {
      type: String,
      default: phoneImg,
    },
    title: {
      type: String,
      default: 'Financial wellbeing for every worker',
    },
    subtitle: {
      type: String,
      default:
        'Built around pay, proven by data, backed by charities. Just a few registration steps and you are ready to go.',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    showApContainer: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    registerClick(txt) {
      tracker.trackAnonymousEngagement('cta_click', { text: txt });
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'SemplicitaProBold';
  src: url('../assets/fonts/semplicitapro-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'SemplicitaPro';
  src: url('../assets/fonts/semplicitapro-webfont.woff') format('woff');
}

.ap {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: row;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;

  &__banner {
    display: flex;
    height: 100vh;
    overflow: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background: radial-gradient(
        102.19% 102.19% at 89.17% -2.19%,
        var(--palette-color-core-4) 0,
        var(--palette-color-core-3-darken-20) 38.97%,
        var(--palette-color-core-1-darken-10) 53.97%
      ),
      var(--palette-color-core-1-darken-20);
  }

  &__banner:before {
    position: absolute;
    max-height: 100vh;
    height: 50%;
    width: 100%;
    content: '';
    top: 0;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url('../assets/background-gradient-white.png');
    background-size: cover;
    opacity: 0.5;
  }

  &__proof {
    color: var(--palette-color-base-white);
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.35));
    font-family: SemplicitaPro, sans-serif;
    font-size: 20px;
    line-height: 1.6;
    margin: 0 48px;
    max-width: 470px;
    width: 100%;
  }

  &__proof_title {
    font-size: 52px;
    font-size: 50px;
    line-height: 1.15;
    font-weight: 600;
    margin-bottom: 32px;
    margin-top: 16px;
  }

  &__proof_description {
    margin-bottom: 50px;
  }

  &__image {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.35));
    margin-right: 64px;
    margin-left: 16px;
    width: 300px;
  }

  &__button {
    display: inline-block;
    color: var(--palette-color-base-white);
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 16px;
    outline: 1px solid var(--palette-color-base-white);
    margin-right: 16px;
    border-radius: 8px;
    padding: 15px 24px;
    transition: box-shadow 0.25s;
    text-align: center;
    font-size: 16px;
  }

  &__container {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 48px 64px;
    flex-direction: column;
    justify-content: center;
    background: var(--palette-color-base-white);
    max-height: 100vh;
    max-width: 500px;
    min-width: 400px;
    width: 33vw;
    flex-grow: 1;
    z-index: 1;
  }

  & h1 {
    text-align: center;
    margin-bottom: 0.5em;
    opacity: 0.9;
  }

  @media (max-width: 1250px) {
    &__image {
      display: none;
    }
  }

  @media (max-width: 900px) {
    & {
      justify-content: center;
      align-items: center;
    }

    &__banner {
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 0;
    }

    &__proof {
      display: none;
    }

    &__container {
      flex-grow: 0;
      width: 100%;
      max-height: 90vh;
      border-radius: 16px;
      padding: 48px 32px;
      margin: 0 32px;
      min-width: 0;
    }

    & h1 {
      font-size: 1.5em;
    }
  }
}
</style>
