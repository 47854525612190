import State from '@/state/State';
import usePayschedules from '@/state/composables/usePayschedules';
import { TODO_ACTIONS } from '@/utils/Todo';

export const { payschedules, getPayschedules } = usePayschedules();

export const PERIODICITY = {
  WEEKLY: 'WEEKLY',
  FORTNIGHT: 'FORTNIGHT',
  FOUR_WEEKLY: 'FOUR_WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const PERIODICITY_LABELS = {
  [PERIODICITY.WEEKLY]: 'Weekly',
  [PERIODICITY.FORTNIGHT]: 'Fortnight',
  [PERIODICITY.FOUR_WEEKLY]: 'Four weekly',
  [PERIODICITY.MONTHLY]: 'Monthly',
};

export const PERIODICITY_VALUES = {
  [PERIODICITY.WEEKLY]: 7,
  [PERIODICITY.FORTNIGHT]: 14,
  [PERIODICITY.FOUR_WEEKLY]: 28,
  [PERIODICITY.MONTHLY]: undefined,
};

export const SALARY_TYPE = {
  MIXED: 'mixed',
  HOURLY: 'hourly',
  SALARY: 'salary',
};

export const SALARY_TYPE_LABELS = {
  [SALARY_TYPE.MIXED]: 'Mixed',
  [SALARY_TYPE.HOURLY]: 'Shift',
  [SALARY_TYPE.SALARY]: 'Salaried',
};

export const isPayscheduleExpiring = payscheduleId =>
  State.state.todos.some(todo => todo.action === TODO_ACTIONS.UPDATE_PAYSCHEDULE && todo.fk === payscheduleId);

export const employeeIsOnPayscheduleInBlackout = async employee => {
  await getPayschedules(true);

  const employeePayschedule = payschedules.value.find(
    payschedule => payschedule.pay_schedule_id === employee.pay_schedule_id,
  );

  if (!employeePayschedule) {
    return false;
  }

  const blackoutPeriodDays = State?.state?.company?.properties.payroll_blackout_days;
  const blackoutPeriodStart = employeePayschedule?.blackout_period_start;

  if (blackoutPeriodDays) {
    const nextPayday = new Date(employeePayschedule.next_payday);
    const now = new Date();
    const daysUntilNextPayday = Math.ceil((nextPayday - now) / (1000 * 60 * 60 * 24));
    return daysUntilNextPayday <= blackoutPeriodDays;
  } else if (blackoutPeriodStart) {
    return new Date() >= new Date(blackoutPeriodStart) && new Date() <= new Date(employeePayschedule.next_payday);
  } else {
    return false;
  }
};
