import Admins from '@/pages/Admins.vue';
import Advances from '@/pages/Advances.vue';
import CompanySettings from '@/pages/CompanySettings.vue';
import EmailActions from '@/pages/EmailActions';
import EmployeeDeactivate from '@/pages/EmployeeDeactivate.vue';
import EmployeeInvite from '@/pages/EmployeeInvite.vue';
import EmployeeOptIn from '@/pages/EmployeeOptIn.vue';
import EmployeeOptOut from '@/pages/EmployeeOptOut.vue';
import EmployeePause from '@/pages/EmployeePause.vue';
import EmployeeResetPassword from '@/pages/EmployeeResetPassword.vue';
import EmployeeRevertBanking from '@/pages/EmployeeRevertBanking.vue';
import EmployeeUnpause from '@/pages/EmployeeUnpause.vue';
import EmployeesUpload from '@/pages/EmployeesUpload.vue';
import EnrollEmployee from '@/pages/EnrollEmployee.vue';
import EnrollEmployeeLoading from '@/pages/EnrollEmployeeLoading.vue';
import ForgotPassword from '@/pages/ForgotPassword.vue';
import FundingDetails from '@/pages/FundingDetails.vue';
import FundingWithdraw from '@/pages/FundingWithdraw.vue';
import Impersonate from '@/pages/Impersonate.vue';
import ImportEmployees from '@/pages/ImportEmployees.vue';
import IntegrationsOauth2 from '@/pages/IntegrationsOauth2.vue';
import KYC from '@/pages/KYC.vue';
import LeadTouch from '@/pages/LeadTouch.vue';
import Login from '@/pages/Login.vue';
import RaiseLogin from '@/pages/talent-pool/login/index.vue';
import Profile from '@/pages/Profile.vue';
import Referral from '@/pages/Referral.vue';
import RegisterXeroFallback from '@/pages/RegisterXeroFallback.vue';
import SftpBulkUpload from '@/pages/SftpBulkUpload';
import ShiftsAdd from '@/pages/ShiftAdd.vue';
import ShiftsUpload from '@/pages/ShiftsUpload.vue';
import VerifyOwnership from '@/pages/VerifyOwnership.vue';
import WhatsAppRedirect from '@/pages/WhatsAppRedirect.vue';
import WhatsAppTermsAmendmentV1 from '@/pages/WhatsAppTermsAmendmentV1.vue';
import XeroConfiguration from '@/pages/XeroConfiguration.vue';
import XeroDocumentation from '@/pages/XeroDocumentation.vue';
import XeroRegistration from '@/pages/XeroRegistration.vue';

import Absences from '@/pages/absences/Absences.vue';
import AdminAdd from '@/pages/adminAdd/AdminAdd.vue';
import AnalyticsApp from '@/pages/analyticsApp/AnalyticsApp.vue';
import AnalyticsBankPay from '@/pages/analyticsBankPay/AnalyticsBankPay.vue';
import AnalyticsCompare from '@/pages/analyticsCompare/AnalyticsCompare.vue';
import AnalyticsHomepage from '@/pages/analyticsHomepage/AnalyticsHomepage.vue';
import AnalyticsShifts from '@/pages/analyticsShifts/AnalyticsShifts.vue';
import BankPay from '@/pages/bankPay/BankPay.vue';
import Dashboard from '@/pages/dashboard/Dashboard.vue';
import Divisions from '@/pages/divisions';
import DivisionsAdd from '@/pages/divisions/add';
import Division from '@/pages/divisions/division';
import DivisionEmployeeAdd from '@/pages/divisions/division/employees/add';
import DivisionManagerAdd from '@/pages/divisions/division/managers/add';
import Employee from '@/pages/employee/Employee.vue';
import EmployeeAdd from '@/pages/employeeAdd/EmployeeAdd.vue';
import Employees from '@/pages/employees/Employees.vue';
import FlexibleWork from '@/pages/flexible-work';
import FlexibleWorkBlockEmployee from '@/pages/flexible-work/block-employee';
import FlexibleWorkBlockedEmployees from '@/pages/flexible-work/blocked-employees';
import FlexibleWorkOnboarding from '@/pages/flexible-work/onboarding';
import FlexibleWorkPreferencesBulk from '@/pages/flexible-work/preferences/bulk';
import FlexibleWorkRolesAndLocations from '@/pages/flexible-work/roles-and-locations';
import FlexibleWorkShift from '@/pages/flexible-work/shift';
import FlexibleWorkShiftsBulk from '@/pages/flexible-work/shifts/bulk';
import FundingPosition from '@/pages/funding-position/index.vue';
import Integrations from '@/pages/integrations/Integrations.vue';
import OffCyclePayments from '@/pages/offCyclePayments/index.vue';
import PayWithWagestream from '@/pages/payWithWagestream/PayWithWagestream.vue';
import PaySchedule from '@/pages/payschedule/PaySchedule.vue';
import PaySchedules from '@/pages/payschedules/PaySchedules.vue';
import Register from '@/pages/register';
import RegisterLanding from '@/pages/register/landing';
import RegisterRedirect from '@/pages/register/landing/Redirect';
import RegisterBookDemo from '@/pages/register/landing/BookDemo';
import RegisterCompany from '@/pages/register/company';
import RegisterSubscription from '@/pages/register/subscription';
import RegisterTerms from '@/pages/register/terms';
import RegisterUser from '@/pages/register/user';
import RegisterIntegrate from '@/pages/registerIntegrate/RegisterIntegrate.vue';
import ResetPassword from '@/pages/ResetPassword.vue';
import Shifts from '@/pages/shifts/Shifts.vue';
import StreamedWages from '@/pages/streamed-wages/index.vue';
import EmployeePayments from '@/pages/employee-payments/index.vue';
import Subscription from '@/pages/subscription';
import TalentPool from '@/pages/talent-pool';
import TalentPoolSchedule from '@/pages/talent-pool/schedule';
import TalentPoolMap from '@/pages/talent-pool/map';
import TalentPoolLocationSettings from '@/pages/talent-pool/location-settings';
import TalentPoolTeam from '@/pages/talent-pool/team';
import TalentPoolShift from '@/pages/talent-pool/shift';
import TalentPoolJobs from '@/pages/talent-pool/jobs';
import TalentPoolSignOff from '@/pages/talent-pool/sign-off';
import TalentPoolClockIn from '@/pages/talent-pool/clock-in';
import Tenancy from '@/pages/tenancy';
import OneTimePayments from '@/pages/one-time-payments';
import SuccessfulStripeIntentRedirect from '@/pages/SuccessfulStripeIntentRedirect.vue';

import useFeatureFlags from '@/composables/useFeatureFlags';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import JobsOnboarding from '@/pages/talent-pool/jobs-onboarding';
import JobOnboardingDetails from '@/pages/talent-pool/jobs-onboarding/JobOnboardingDetails.vue';
import JobOnboardingAccess from '@/pages/talent-pool/jobs-onboarding/JobOnboardingAccess.vue';
import Shift from '@/pages/talent-pool/shift/components/Shift.vue';
import JobDetails from '@/pages/talent-pool/jobs/components/JobDetails.vue';
import { getShiftState } from '@/pages/talent-pool/utils/ShiftStatus';
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { orderJobPostings } from '@/queries/talent-pool/jobs';
import TalentPoolJobsGetStarted from '@/pages/talent-pool/jobs-get-started';
import TalentPoolJobUnlock from '@/pages/talent-pool/jobs/JobUnlock';
import HelpCenter from '@/pages/helpCenter/HelpCenter.vue';
import LastDayPayReport from '@/pages/lastDayPayReport/LastDayPayReport.vue';
import PayMultiple from '@/pages/one-time-payments/components/pay-multiple.vue';
import SingleBatch from '@/pages/one-time-payments/components/single-batch.vue';
import FileUpload from '@/components/FileUpload.vue';

const PartnersLanding = () => import(/* webpackChunkName: "partners" */ '@/pages/PartnersLanding.vue');
const ApiDocumentation = () => import(/* webpackChunkName: "partners" */ '@/pages/PartnersApiDocumentation.vue');

const {
  isDisablePayscheduleEditing,
  isDisablePayWithWagestream,
  isDisableRegistration,
  isEnableStreamedWagesPage,
  isEnableEmployeePaymentsPage,
  isEnableBankPayHistory,
  isEnableLastDayPay,
  offCyclePaymentsVersion,
} = useFeatureFlags();

const { locations, getLocations } = useTalentPoolLocations();

const registerGuard = () => !isDisableRegistration.value;

export const publicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/raise-login',
    name: 'raise-login',
    component: RaiseLogin,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/referral',
    name: 'referral',
    component: Referral,
  },
  {
    path: '/login/impersonate',
    name: 'login-impersonate',
    component: Impersonate,
  },
  {
    path: '/login/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  // registration
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/landing',
    name: 'register-landing',
    component: RegisterLanding,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/redirect',
    name: 'register-redirect',
    component: RegisterRedirect,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/book-demo',
    name: 'register-book-demo',
    component: RegisterBookDemo,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/user',
    name: 'register-user',
    component: RegisterUser,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/company',
    name: 'register-company',
    component: RegisterCompany,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/terms',
    name: 'register-terms',
    component: RegisterTerms,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/subscription',
    name: 'register-subscription',
    component: RegisterSubscription,
    meta: {
      guards: [registerGuard],
    },
  },
  {
    path: '/register/integrate',
    name: 'sme-register-page-integrate',
    component: RegisterIntegrate,
  },
  // misc
  {
    path: '/touch/:point(.*)',
    name: 'sme-lead-touch',
    component: LeadTouch,
  },
  {
    path: '/partners',
    name: 'sme-partners',
    component: PartnersLanding,
  },
  {
    path: '/partners/api',
    name: 'sme-partners-api',
    component: ApiDocumentation,
  },
  {
    path: '/xero-registration',
    name: 'xero-registration',
    component: XeroRegistration,
  },
  {
    path: '/xero-documentation',
    name: 'xero-documentation',
    component: XeroDocumentation,
  },
  {
    path: '/xero-companies-house',
    name: 'xero-companies-house',
    component: RegisterXeroFallback,
    props: true,
  },

  {
    path: '/whatsapp-enroll',
    name: 'sme-whats-app-redirect',
    component: WhatsAppRedirect,
  },
  {
    path: '/terms/whatsapp-v1',
    name: 'sme-whats-app-terms-amendment-v1',
    component: WhatsAppTermsAmendmentV1,
  },
  // FIXME: I had to move these to unauthorized to get unit test to pass because couldnt mock endpoint auth.
  //        This is certainly my own incompetence but having these unprotected in UI routing is not a risk.
  {
    path: '/authorize/:provider/:company_id?',
    name: 'sme-oauth2',
    component: IntegrationsOauth2,
  },
  {
    path: '/hire-talent',
    name: 'talent-pool-job-onboarding',
    component: JobsOnboarding,
  },
  {
    path: '/hire-talent/details',
    name: 'talent-pool-job-onboarding-details',
    component: JobOnboardingDetails,
  },
  {
    path: '/hire-talent/access/:posting_id',
    name: 'talent-pool-job-onboarding-access',
    component: JobOnboardingAccess,
  },
];

export const flexpayRoutes = [
  {
    path: '/dashboard',
    redirect: '/sme-dashboard',
  },
  {
    path: '/sme-dashboard',
    name: 'sme-dashboard',
    component: Dashboard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/divisions',
    name: 'divisions',
    component: Divisions,
  },
  {
    path: '/divisions/add',
    name: 'divisions-add',
    component: DivisionsAdd,
    props: true,
  },
  {
    path: '/divisions/:divisionId',
    name: 'division',
    component: Division,
    props: true,
  },
  {
    path: '/divisions/:divisionId/managers/add',
    name: 'division-managers-add',
    component: DivisionManagerAdd,
    props: true,
  },
  {
    path: '/divisions/:divisionId/employees/add',
    name: 'division-employees-add',
    component: DivisionEmployeeAdd,
    props: true,
  },
  {
    path: '/employees',
    name: 'employees',
    component: Employees,
  },
  {
    path: '/employees/add',
    name: 'employees-add',
    component: EmployeeAdd,
  },
  {
    path: '/employees/bulk',
    name: 'employees-upload',
    component: EmployeesUpload,
  },
  {
    path: '/employees/upload',
    redirect: '/employees/bulk',
  },
  {
    path: '/employees/:employeeId',
    name: 'employee',
    component: Employee,
    props: true,
  },
  {
    path: '/employees/:employeeId/setup', //used when enrolling an employee
    name: 'employee-setup',
    component: EnrollEmployee,
    props: true,
  },
  {
    path: '/employees/:employeeId/activate', //used to poll employee for bank details after enrolling employee
    name: 'employee-activate',
    component: EnrollEmployeeLoading,
    props: true,
  },
  {
    path: '/employees/:employeeId/opt-out',
    name: 'employee-opt-out',
    component: EmployeeOptOut,
    props: true,
  },
  {
    path: '/employees/:employeeId/opt-in',
    name: 'employee-opt-in',
    component: EmployeeOptIn,
    props: true,
  },
  {
    path: '/employees/:employeeId/revert-banking', // for reverting bank details on user's payroll. currently unused
    name: 'employee-revert-banking',
    component: EmployeeRevertBanking,
    props: true,
  },
  {
    path: '/employees/:employeeId/pause',
    name: 'employee-pause',
    component: EmployeePause,
    props: true,
  },
  {
    path: '/employees/:employeeId/unpause',
    name: 'employee-unpause',
    component: EmployeeUnpause,
    props: true,
  },
  {
    path: '/employees/:employeeId/reset-password',
    name: 'employee-reset-password',
    component: EmployeeResetPassword,
    props: true,
  },
  {
    path: '/employees/:employeeId/invite',
    name: 'employee-invite',
    component: EmployeeInvite,
    props: true,
  },
  {
    path: '/employees/:employeeId/deactivate',
    name: 'employee-deactivate',
    component: EmployeeDeactivate,
    props: true,
  },
  {
    path: '/advances',
    name: 'sme-advances',
    component: Advances,
  },
  {
    path: '/streams',
    name: 'streams',
    component: StreamedWages,
    meta: {
      guards: [() => isEnableStreamedWagesPage.value],
    },
  },
  {
    path: '/employe-payments',
    name: 'employee-payments',
    component: EmployeePayments,
    meta: {
      guards: [() => isEnableEmployeePaymentsPage.value],
    },
  },
  {
    path: '/one-time-payments',
    name: 'one-time-payments',
    component: OneTimePayments,
    meta: {
      guards: [() => offCyclePaymentsVersion.value.includes('v2')],
    },
  },
  {
    path: '/one-time-payments/bulk-upload',
    name: 'one-time-payments-bulk',
    component: PayMultiple,
    meta: {
      guards: [() => offCyclePaymentsVersion.value.includes('v2')],
    },
  },
  {
    path: '/one-time-payments/:batchId',
    name: 'one-time-payment',
    component: SingleBatch,
    meta: {
      guards: [() => offCyclePaymentsVersion.value.includes('v2')],
    },
  },
  {
    path: '/shifts',
    name: 'shifts',
    component: Shifts,
  },
  {
    path: '/absences',
    name: 'absences',
    component: Absences,
  },
  {
    path: '/admins',
    name: 'admins',
    component: Admins,
  },
  {
    path: '/admins/add',
    name: 'admins-add',
    component: AdminAdd,
  },
  {
    path: '/company-info/pay-schedules/:payScheduleId',
    name: 'pay-schedule',
    component: PaySchedule,
    meta: {
      guards: [
        router => {
          if (!isDisablePayscheduleEditing.value) {
            return true;
          }
          return !(router.history.pending.params.payScheduleId === 'create');
        },
      ],
    },
  },
  {
    path: '/setup/employees',
    name: 'sme-employees-add',
    component: ImportEmployees,
  },
  {
    path: '/sme/funds',
    name: 'sme-funds',
    component: FundingDetails,
  },
  {
    path: '/funding-position',
    name: 'funding-position',
    component: FundingPosition,
  },
  {
    path: '/sme/funds/withdraw',
    name: 'sme-funds-withdraw',
    component: FundingWithdraw,
  },
  {
    path: '/sme/verify-ownership',
    name: 'sme-verify-ownership',
    component: VerifyOwnership,
  },

  {
    path: '/kyc',
    name: 'sme-kyc',
    component: KYC,
  },
  {
    path: '/sme/integrations/:provider?',
    name: 'sme-integrations',
    component: Integrations,
  },
  {
    path: '/sme/integrations-tenancy/:state',
    name: 'tenancy',
    component: Tenancy,
    props: true,
  },
  {
    path: '/shifts/bulk',
    name: 'sme-shifts-upload',
    component: ShiftsUpload,
  },
  {
    path: '/shifts/single',
    name: 'sme-shift-add',
    component: ShiftsAdd,
  },
  {
    path: '/sme/payday/',
    name: 'sme-pay-day',
    component: PaySchedules,
  },
  {
    path: 'last-day-pay',
    name: 'last-day-pay',
    component: LastDayPayReport,
    guard: [() => isEnableLastDayPay.value],
  },
  {
    path: '/sme/payment-run/',
    name: 'sme-payment-run',
    component: PayWithWagestream,
    meta: {
      guards: [() => !isDisablePayWithWagestream.value],
    },
  },
  {
    path: '/sme/off-cycle-payments/',
    name: 'sme-off-cycle-payments',
    component: OffCyclePayments,
  },
  {
    path: '/sme/bank-pay/',
    name: 'sme-bank-pay',
    component: BankPay,
    meta: {
      guards: [() => isEnableBankPayHistory.value],
    },
  },
  {
    path: '/sme/subscription/',
    name: 'sme-subscription',
    component: Subscription,
  },
  {
    path: '/successful-stripe-intent-redirect',
    name: 'successful-stripe-intent-redirect',
    component: SuccessfulStripeIntentRedirect,
  },
  {
    path: '/company-settings',
    name: 'company-settings',
    component: CompanySettings,
  },
  {
    path: '/xero-configuration',
    name: 'xero-configuration',
    component: XeroConfiguration,
  },
  {
    path: '/analytics',
    name: 'analytics-homepage',
    component: AnalyticsHomepage,
  },
  {
    path: '/analytics/app',
    name: 'analytics-app',
    component: AnalyticsApp,
  },
  {
    path: '/analytics/shifts',
    name: 'analytics-shifts',
    component: AnalyticsShifts,
  },
  {
    path: '/analytics/bank-pay',
    name: 'analytics-bank-pay',
    component: AnalyticsBankPay,
  },
  {
    path: '/analytics/compare',
    name: 'analytics-compare',
    component: AnalyticsCompare,
  },
  {
    path: '/email-actions/:token',
    name: 'email-actions',
    component: EmailActions,
  },
  {
    path: '/legacy/bulk',
    name: 'sftp-bulk-upload',
    component: SftpBulkUpload,
  },
  {
    path: '/file-upload',
    name: 'file-upload',
    component: FileUpload,
  },
  {
    path: '/help-center/:slug?',
    name: 'help-center',
    component: HelpCenter,
    meta: {
      guards: [() => State.state.company.properties.portal.can_view_help_center],
    },
  },
];

export const flexworkRoutes = [
  {
    path: '/flexible-work/onboarding',
    name: 'flexible-work-onboarding',
    component: FlexibleWorkOnboarding,
  },
  {
    path: '/flexible-work/preferences/bulk',
    name: 'flexible-work-preferences-bulk',
    component: FlexibleWorkPreferencesBulk,
  },
  {
    path: '/flexible-work/roles-and-locations',
    name: 'flexible-work-roles-and-locations',
    component: FlexibleWorkRolesAndLocations,
  },
  {
    path: '/flexible-work/blocked-employees',
    name: 'flexible-work-blocked-employees',
    component: FlexibleWorkBlockedEmployees,
  },
  {
    path: '/flexible-work/block-employee',
    name: 'flexible-work-block-employee',
    component: FlexibleWorkBlockEmployee,
  },
  {
    path: '/flexible-work/shift/add',
    name: 'flexible-work-shift-add',
    component: FlexibleWorkShift,
    props: { isAddMode: true },
  },
  {
    path: '/flexible-work/shift/:shiftId',
    name: 'flexible-work-shift',
    component: FlexibleWorkShift,
  },
  {
    path: '/flexible-work/shifts/bulk',
    name: 'flexible-work-shifts-bulk',
    component: FlexibleWorkShiftsBulk,
  },
  {
    path: '/flexible-work',
    name: 'flexible-work',
    component: FlexibleWork,
  },
  {
    path: '/fw',
    name: 'talent-pool-root',
    beforeEnter: async (to, from, next) => {
      if (from?.query?.redirect?.length && from.query.redirect !== '/') {
        next(decodeURI(from.query.redirect));
        return;
      }

      if (locations.value.length === 0) {
        await getLocations(); // Fetch only if initial is true
      }
      if (locations.value.length > 0) {
        const firstLocationId = locations.value[0].location_id;
        next({ name: 'talent-pool-map', params: { locationId: firstLocationId } });
      } else {
        next('/error');
      }
    },
  },
  {
    path: '/fw/:locationId',
    name: 'talent-pool',
    component: TalentPool,
  },
  {
    path: '/fw/:locationId/shift/:shiftId',
    name: 'talent-pool-shift',
    beforeEnter: async (to, from, next) => {
      const { data } = await ApiClient.getTalentPoolShift(to.params.shiftId);
      if (data) {
        next({
          name: 'talent-pool-shift-detail',
          params: {
            locationId: to.params.locationId,
            shiftId: to.params.shiftId,
            tab: getShiftState(data),
          },
        });
      } else {
        next('/error');
      }
    },
  },
  {
    path: '/fw/:locationId/jobs/get-started',
    name: 'talent-pool-jobs-get-started',
    component: TalentPoolJobsGetStarted,
  },
  {
    path: '/fw/:locationId/jobs',
    name: 'talent-pool-jobs',
    component: TalentPoolJobs,
    children: [
      {
        path: ':jobId',
        name: 'talent-pool-job-detail',
        component: JobDetails,
      },
    ],
    beforeEnter: async (to, from, next) => {
      if (to.name !== 'talent-pool-job-detail') {
        const { data } = await ApiClient.getJobPostings(State.state.company.company_id);
        if (data?.length) {
          const firstJob = orderJobPostings(data.filter(item => item.location_id === to.params.locationId))[0];

          if (!firstJob) {
            next({
              name: 'talent-pool-jobs-get-started',
              params: {
                locationId: to.params.locationId,
              },
            });
            return;
          }

          next({
            name: 'talent-pool-job-detail',
            params: {
              locationId: to.params.locationId,
              jobId: firstJob?.unfilled_shift_group_id,
            },
          });
        } else {
          next({
            name: 'talent-pool-jobs-get-started',
            params: {
              locationId: to.params.locationId,
            },
          });
          return;
        }
      }
      next();
    },
  },
  {
    path: '/fw/:locationId/shifts/:tab',
    name: 'talent-pool-shift-status',
    component: TalentPoolShift,
    children: [
      {
        path: ':shiftId',
        name: 'talent-pool-shift-detail',
        component: Shift,
      },
    ],
  },
  {
    path: '/fw/:locationId/sign-off/:shiftId',
    name: 'talent-pool-sign-off',
    component: TalentPoolSignOff,
  },
  {
    path: '/fw/:locationId/clock-in/:shiftId',
    name: 'talent-pool-clock-in',
    component: TalentPoolClockIn,
  },
  {
    path: '/fw/:locationId/schedule',
    name: 'talent-pool-schedule',
    component: TalentPoolSchedule,
  },
  {
    path: '/fw/:locationId/map',
    name: 'talent-pool-map',
    component: TalentPoolMap,
  },
  {
    path: '/fw/:locationId/settings',
    name: 'talent-pool-settings',
    component: TalentPoolLocationSettings,
  },
  {
    path: '/fw/:locationId/team',
    name: 'talent-pool-team',
    component: TalentPoolTeam,
  },
  {
    path: '/fw/:locationId/jobs/:jobId/unlock',
    name: 'talent-pool-job-unlock',
    component: TalentPoolJobUnlock,
  },
];

export const sharedRoutes = [
  // TODO
];
