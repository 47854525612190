<template>
  <app-page title="Help Center" icon="info-circle" class="help-center-page">
    <template #custom-content>
      <div class="content-container">
        <div v-if="articlesQuery?.isLoading?.value" class="spinner">Loading...</div>
        <div v-else-if="articlesQuery?.error?.value" class="error">{{ articlesQuery?.error?.value }}</div>
        <div v-else>
          <div class="header-container">
            <div class="header-container--title">
              <font-awesome-icon v-if="!isHome" @click="onBack" class="icon" :icon="['fas', 'arrow-left']" />
              <h1>{{ article.title || 'Home' }}</h1>
            </div>
            <div>
              <div class="simple-search">
                <b-form-input
                  type="search"
                  placeholder="Search articles"
                  v-model="searchTerm"
                  @input="debouncedSearch"
                  @focus="showDropdown"
                  @blur="hideDropdown"
                  ref="searchInput"
                >
                </b-form-input>
                <div v-if="dropdownVisible" class="dropdown-wrapper">
                  <div class="fixed-child">
                    <a
                      v-for="(result, index) in searchResults"
                      :key="index"
                      @mousedown.prevent
                      @click="onClickArticle(result.slug)"
                      class="dropdown-item"
                    >
                      {{ result.title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isHome">
            <div v-if="article.related_articles && article.related_articles.length">
              <ul class="related-article-list grid">
                <li
                  v-for="relatedArticle in article.related_articles"
                  :key="relatedArticle.slug"
                  class="related-article-item card"
                >
                  <router-link :to="`/help-center/${relatedArticle.slug}`">
                    <h3>{{ relatedArticle.title }}</h3>
                    <p>{{ relatedArticle.description }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="content-inner">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="article.contents" class="article-contents"></div>
            <div v-if="article.contents && article.type == 'article' && !hasRated" class="feedback-section">
              <p>Was this article helpful?</p>
              <div class="feedback-buttons">
                <b-button variant="light" @click="rateArticle('yes')" title="Yes">
                  <ThumbsUp :color="PaletteColors['brand-primary']" /> Yes
                </b-button>
                <b-button variant="light" @click="rateArticle('no')" title="No">
                  <ThumbsDown :color="PaletteColors['brand-primary']" /> No
                </b-button>
              </div>
            </div>
            <div v-if="article.related_articles && article.related_articles.length">
              <h2>Related Articles</h2>
              <ul class="related-article-list grid">
                <li
                  v-for="relatedArticle in article.related_articles"
                  :key="relatedArticle.slug"
                  class="related-article-item card"
                >
                  <router-link :to="`/help-center/${relatedArticle.slug}`">
                    <h3>{{ relatedArticle.title }}</h3>
                    <p>{{ relatedArticle.description }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-page>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useRoute, useRouter } from 'vue-router/composables';
import { getArticle } from '@/queries/helpCenter';
import AppPage from '@/components/AppPage.vue';
import ApiClient from '@/ApiClient';
import ThumbsUp from '@/assets/icons/ThumbsUp.vue';
import ThumbsDown from '@/assets/icons/ThumbsDown.vue';
import { PaletteColors } from '@/Theme';

const route = useRoute();
const router = useRouter();
const slug = ref(route.params.slug || 'home');
const isHome = ref(slug.value === 'home');

const searchTerm = ref('');
const dropdownVisible = ref(false);
const searchInput = ref(null);

let debounceTimeout;
const customDebounce = (func, delay) => {
  return (...args) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const debouncedSearch = customDebounce(() => {
  onSearch(searchTerm.value);
}, 300);

const showDropdown = () => {
  dropdownVisible.value = true;
};

const hideDropdown = () => {
  dropdownVisible.value = false;
};

const articlesQuery = useQuery(getArticle(slug.value, false));
const searchResults = ref([]);

const article = ref(articlesQuery?.value || {});

const hasRated = ref(false);
const updateHasRated = () => {
  const ratedArticles = JSON.parse(window.localStorage.getItem('rated-articles')) || {};
  const threeDaysAgo = Date.now() - 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

  for (const slug in ratedArticles) {
    if (ratedArticles[slug] < threeDaysAgo) {
      delete ratedArticles[slug];
    }
  }

  window.localStorage.setItem('rated-articles', JSON.stringify(ratedArticles));
  hasRated.value = !!ratedArticles[article.value.slug];
};

watch(article, updateHasRated);

const rateArticle = helpful => {
  ApiClient.postAnalytics('rate-article', {
    articleSlug: article.value.slug,
    helpful: helpful,
  });

  let ratedArticles = {};
  try {
    ratedArticles = JSON.parse(window.localStorage.getItem('rated-articles')) || {};
  } catch (e) {
    ApiClient.postAnalytics('rate-article-failed', {
      msg: 'Failed to parse rated-articles from localStorage',
      error: JSON.stringify(e),
    });
  }

  ratedArticles[article.value.slug] = Date.now();

  window.localStorage.setItem('rated-articles', JSON.stringify(ratedArticles));

  hasRated.value = true;
};

const onSearch = async () => {
  if (!searchTerm.value) {
    searchResults.value = [];
    return;
  }
  const result = await ApiClient.searchArticles(searchTerm.value, false);
  searchResults.value = result.data;
  showDropdown(true);
};

const onClear = () => {
  searchResults.value = [];
  searchTerm.value = '';
};

const onClickArticle = slug => {
  onClear();
  router.push(`/help-center/${slug}`);
};

const onBack = () => {
  router.go(-1);
};

onMounted(() => {
  slug.value = route.params.slug || 'home';
  isHome.value = slug.value === 'home';
});

watch(articlesQuery.fetchStatus, () => {
  if (articlesQuery?.data.value) {
    article.value = articlesQuery.data.value;
  }
});
</script>

<style scoped>
.content-inner {
  display: flex;
  flex-direction: column;
}

.feedback-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  align-self: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background: var(--palette-color-base-white);
}

.feedback-buttons {
  display: flex;
  gap: 1rem;
}

.content-container {
  width: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-container--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-container h1 {
  margin: 0;
  margin-left: 16px;
  font-size: 24px;
}

.spinner {
  text-align: center;
  padding: 20px;
}

.error {
  color: var(--palette-color-danger);
  text-align: center;
  padding: 20px;
}

.article-list,
.related-article-list {
  list-style-type: none;
  padding: 0;
}

.article-item,
.related-article-item {
  margin: 10px 0;
}

.article-item a,
.related-article-item a {
  text-decoration: none;
  color: var(--palette-color-default);
  text-align: center;
}

.article-item a:hover,
.related-article-item a:hover {
  text-decoration: underline;
}

.article-contents {
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.card {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.card p {
  margin: 0.5rem 0 0 0;
  color: var(--palette-color-default-lighten-20);
  font-size: 14px;
}

.search-container {
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: var(--palette-color-default-lighten-90);
}

.search-input {
  padding: 10px;
  padding-left: 2rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-wrapper {
  position: absolute;
  right: 0;
  background-color: var(--palette-color-base-white);
  border: 1px solid var(--palette-color-default-lighten-90);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--palette-color-default-lighten-80);
  text-wrap: wrap;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: var(--palette-color-default-lighten-90);
}

.form-group {
  margin-bottom: 0;
}
</style>

<style>
/* Styles for Article content */

.article-contents {
  blockquote {
    background-color: var(--palette-color-base-white);
    padding: 1rem 1rem 0.25rem 1rem;
    border-radius: 5px;
  }

  p:empty,
  li:empty {
    display: none;
  }

  ol,
  ul {
    padding-left: 30px;
    margin-bottom: 22px;

    li {
      margin-bottom: 14px;
      padding-left: 4px;
    }
  }
}
</style>
