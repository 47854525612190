<template>
  <div class="raise-rating-container">
    <div class="explainer" v-if="props.explainer">Raise score</div>
    <div class="raise-rating">
      <Smiley :color="color" :width="imageSize" :height="imageSize"></Smiley>
      <div class="rating-text" :style="[{ color: color, fontSize: textSize }]">
        <span>
          {{ ratingText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PaletteColors } from '@/Theme';
import Smiley from '@/assets/icons/Smiley.vue';
import { computed } from 'vue';

const props = defineProps({
  rating: {
    required: true,
  },
  explainer: { type: String, required: false },
  size: { type: String, required: false },
});

const color = computed(() => {
  if (props.rating > 3.5) {
    return PaletteColors['success-darken-10'];
  }
  if (props.rating > 2) {
    return PaletteColors['success'];
  }
  return PaletteColors['warning'];
});

const imageSize = computed(() => {
  if (props.size === 'EXTRA-SMALL') {
    return '14px';
  }
  if (props.size === 'SMALL') {
    return '18px';
  }
  return '20px';
});

const textSize = computed(() => {
  if (props.size === 'EXTRA-SMALL') {
    return '12px';
  }
  if (props.size === 'SMALL') {
    return '14px';
  }
  return '15px';
});

const ratingText = computed(() => {
  if (props.rating > 3.5) {
    return 'Excellent';
  }
  if (props.rating > 2) {
    return 'Great';
  }
  return 'Good';
});
</script>

<style scoped lang="scss">
.raise-rating-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.raise-rating {
  display: flex;
  align-items: center;
  gap: 0.15rem;
}

.rating-text {
  display: flex;
  font-weight: 500;
}

.explainer {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  letter-spacing: -0.01em;
}
</style>
