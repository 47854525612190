<template>
  <sme-card title="Tasks" class="todos">
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <sme-alert v-if="outstandingTodosCount === 0" level="good">
        You have no outstanding tasks at this time. Nice one!
      </sme-alert>
      <sme-alert v-else-if="isOnboarded && isSoftLaunch" level="warning">
        Your company has onboarded. We're just finishing getting your account set up. You will be able to enroll your
        employees shortly.
      </sme-alert>
      <sme-alert v-else-if="isOnboarded" level="warning">
        Your company has onboarded. However, you must complete the following tasks to ensure a good experience for your
        employees.
      </sme-alert>
      <sme-alert v-else level="danger" large>
        <h3>Finish setting up</h3>
        <p>You must complete the following tasks before your employees can start using the app.</p>
        <b-progress :max="100" height="1.25rem">
          <b-progress-bar :value="percentageOnboarded" :label="`${percentageOnboarded}%`" class="todos__progress-bar" />
        </b-progress>
      </sme-alert>
      <dashboard-todos-list v-if="outstandingTodosCount > 0" class="mt-3" />
    </template>

    <template v-if="!loading && outstandingTodosCount > 0" #badge>
      <b-badge pill :variant="countVariant">{{ outstandingTodosCount }}</b-badge>
    </template>
  </sme-card>
</template>

<script setup>
import { computed } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import DashboardTodosList from '@/pages/dashboard/components/todos/DashboardTodosList';
import useLaunchState from '@/state/composables/useLaunchState';

const { isOnboarded, isSoftLaunch, loading, outstandingTodosCount, percentageOnboarded } = useLaunchState();

const countVariant = computed(() => (!isOnboarded.value ? ALERT_LEVELS.DANGER : ALERT_LEVELS.WARNING));
</script>

<style lang="scss" scoped>
.todos__progress-bar {
  background-color: var(--palette-color-danger);
}

:deep(.badge) {
  color: var(--palette-color-base-white);
}
</style>
