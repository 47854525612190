<template>
  <app-page title="Shifts" subtitle="View and (optionally) manage timesheets" icon="calendar-alt">
    <template v-slot:after-header>
      <shift-status-alerts showShiftUploadDetail="true" class="mt-3" />
    </template>

    <template v-slot:infobox>
      <sme-info-box title="About shifts" id="about-shifts" save-state>
        <p>Shifts are the unit of work we use to calculate earned wages:</p>
        <ul>
          <li>For salaried employees, we automatically calculate a daily "shift" based on their salary.</li>
          <li>For shift workers, we need to be informed of when shifts occurred and how much was earned.</li>
        </ul>
        <p>
          <strong>The employee experience is greatly improved the sooner we know about worked shifts.</strong>
        </p>
        <p>
          You can delete shifts by clicking the {{ bankingInfo.binIcon }} next to the shift. Sometimes this option is
          disabled, as some shifts are not eligible for deletion:
        </p>
        <ul>
          <li>
            You can only delete shifts that were uploaded on the portal. Any shifts that were synced from your
            {{ bankingInfo.rota.rotaProvider }} should be deleted on your {{ bankingInfo.rota.rotaProvider }} - this
            will be reflected in the portal on the next sync
          </li>
          <li>
            You can only delete shifts from the current pay period. This is because we will have already settled your
            employees' {{ bankingInfo.stream.label.toLowerCase() + 's' }} from previous pay periods
          </li>
        </ul>
      </sme-info-box>

      <sme-info-box title="How can I add worked shifts?" id="about-worked-shifts" save-state>
        <p>
          The easiest way to manage shifts is to use one of our
          <router-link :to="{ name: 'sme-integrations' }">real-time integrations</router-link> with popular
          {{ bankingInfo.rota.label }} software.
        </p>
        <p>
          Alternatively, you can manually provide us shift data -- either
          <router-link :to="{ name: 'sme-shift-add' }">individually</router-link> or
          <router-link :to="{ name: 'sme-shifts-upload' }">in bulk</router-link> -- at your preferred schedule.
        </p>
      </sme-info-box>
      <sme-info-box title="How can I edit shifts?" id="editing-shifts" save-state>
        <p>Instead of directly editing shifts, please delete them and re-add them.</p>
        <p>
          If you are integrated with a {{ bankingInfo.rota.rotaProvider }}, you will need to update the shifts on their
          platform. Any updates that you make will be reflected on this page.
        </p>
      </sme-info-box>
    </template>
    <template v-slot:header v-if="State.state.claims.a">
      <b-button
        v-if="canAddShifts && canAddSingleShift"
        :to="{ name: 'sme-shift-add' }"
        variant="outline-primary"
        :disabled="!State.state.paySchedule"
      >
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Add Shift
      </b-button>
      <b-button
        v-if="canAddShifts"
        :to="{ name: 'sme-shifts-upload' }"
        variant="outline-primary"
        :disabled="!State.state.paySchedule"
      >
        <font-awesome-icon :icon="['fal', 'upload']" class="mr-2" /> Bulk Import
      </b-button>
      <b-button
        v-if="canIntegrate && IntegrationsState.state.loaded && !IntegrationsState.state.integrations"
        :to="{ name: 'sme-integrations' }"
        variant="outline-primary"
      >
        <font-awesome-icon :icon="['fad', 'chart-network']" class="mr-2" />Setup Integration
      </b-button>
    </template>

    <template v-slot:before-content>
      <b-col sm="12" class="mb-4">
        <shift-stats />
      </b-col>
    </template>

    <template v-slot:custom-content>
      <b-col>
        <b-card>
          <shifts-list v-if="!displayNetPayShifts" class="mt-3" />
          <net-pay-shifts-list v-if="displayNetPayShifts" class="mt-3" />
        </b-card>
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import { computed } from 'vue';
import { getBankingInfo } from '@/Banking';
import AppPage from '@/components/AppPage.vue';
import ShiftStatusAlerts from '@/components/ShiftStatusAlerts.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import ShiftStats from '@/pages/shifts/components/ShiftStats.vue';
import ShiftsList from '@/pages/shifts/components/ShiftsList.vue';
import NetPayShiftsList from '@/pages/shifts/components/NetPayShiftsList.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';

const { canAddShifts, canAddSingleShift, canIntegrate, displayNetPayShifts } = useFeatureFlags();
const bankingInfo = computed(() => getBankingInfo());
</script>
