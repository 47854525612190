<template>
  <section>
    <page-sub-header title="Division Shifts" class="mt-4" />
    <sme-card large>
      <app-loading :loading="loading" />
      <template v-if="!loading">
        <template v-if="datasets.length">
          <h4 class="sme-card__title mb-3">Division Quadrants</h4>
          <ul class="mb-4">
            <li>Low adoption + Low hours → Unclear <strong>(Bottom Left)</strong></li>
            <li>Low adoption + High hours → Push adoption to see greater benefit <strong>(Top Left)</strong></li>
            <li>
              High adoption + Low hours → Offer more hours to WS users and ensure overtime access is available.
              Education piece to help them understand the link between working and streaming
              <strong>(Bottom Right)</strong>
            </li>
            <li>High adoption + High hours → All good <strong>(Top Right)</strong></li>
          </ul>
          <bubble-chart :data="data" :chart-options="chartOptions" />
        </template>
        <template v-else>
          <sme-alert v-if="State.state.claims.a" level="warning">
            <strong>No divisions found. </strong>
            <router-link :to="{ name: 'divisions-add' }">Add a division</router-link> now.
          </sme-alert>
          <p v-else class="my-2 text-center">No divisions to compare.</p>
        </template>
      </template>
    </sme-card>
  </section>
</template>

<script setup>
import max from 'lodash/max';
import mean from 'lodash/mean';
import merge from 'lodash/merge';
import min from 'lodash/min';
import { computed, ref } from 'vue';
import ApiClient from '@/ApiClient';
import { PaletteColors } from '@/Theme';
import AppLoading from '@/components/AppLoading.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import BubbleChart from '@/components/charts/BubbleChart.vue';
import State from '@/state/State';
import { hexToRGB } from '@/utils/color';
import { generateRandomNumber, toClampedNumber, toNumber } from '@/utils/common';

const DEFAULT_ANNOTATION_CONFIG = {
  borderColor: PaletteColors['brand-primary'],
  borderDash: [6, 6],
  borderWidth: 3,
  drawTime: 'beforeDatasetsDraw',
  leave(ctx) {
    ctx.hovered = false;
    ctx.chart.update();
  },
  label: {
    display: ctx => ctx.hovered,
    drawTime: 'afterDatasetsDraw',
    position: ctx => ctx.hoverPosition,
  },
  type: 'line',
};

const props = defineProps({ preview: Boolean });

const datasets = ref([]);
const loading = ref(true);

const data = computed(() => ({ datasets: datasets.value }));

const chartOptions = computed(() => {
  const suggestedXMax = 100;
  const suggestedXMin = 0;
  const suggestedYMax = max(datasets.value.map(dataset => dataset.data[0].y)) * 1.1;
  const suggestedYMin = min(datasets.value.map(dataset => dataset.data[0].y)) * 0.9;
  const xAverage = getAverage(data.value.datasets, 'x');
  const yAverage = getAverage(data.value.datasets, 'y');

  return {
    scales: {
      x: {
        suggestedXMax,
        suggestedXMin,
        title: {
          display: true,
          text: 'Adoption percentage (%)',
        },
        ticks: { precision: 0 },
      },
      y: {
        suggestedYMax,
        suggestedYMin,
        title: {
          display: true,
          text: 'Diff hours worked (enrolled vs not)',
        },
        ticks: { precision: 0 },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          line1: merge(
            {
              scaleID: 'x',
              value: xAverage,
              label: {
                content: `Average adoption percentage: ${xAverage}%`,
              },
              enter(ctx, event) {
                ctx.hoverPosition = (event.y / ctx.chart.chartArea.height) * 100 + '%';
                ctx.hovered = true;
                ctx.chart.update();
              },
            },
            DEFAULT_ANNOTATION_CONFIG,
          ),
          line2: merge(
            {
              scaleID: 'y',
              value: yAverage,
              label: {
                content: `Average diff hours worked (enrolled vs not): ${yAverage}`,
              },
              enter(ctx, event) {
                ctx.hoverPosition = (event.x / ctx.chart.chartArea.width) * 100 + '%';
                ctx.hovered = true;
                ctx.chart.update();
              },
            },
            DEFAULT_ANNOTATION_CONFIG,
          ),
        },
      },
      tooltip: {
        callbacks: {
          label: context => [
            [`${context.dataset.label}`],
            [`Employees: ${context.dataset.data[0].rRaw}`],
            [`Adoption percentage: ${context.parsed.x}%`],
            [`Diff hours worked (enrolled vs not): ${context.parsed.y}`],
          ],
        },
      },
    },
  };
});

const getShiftDivisionsMetrics = async () => {
  loading.value = true;

  if (!props.preview) {
    const { data } = await ApiClient.getShiftDivisionsMetrics();

    if (data) {
      datasets.value = data.map(divisionData => ({
        label: divisionData.division_name,
        data: [
          {
            r: toClampedNumber(divisionData.employee_count / 10, 5, 25),
            rRaw: divisionData.employee_count,
            x: toNumber(divisionData.enrolled_percent * 100),
            y: toNumber(divisionData.hours_diff),
          },
        ],
        backgroundColor: hexToRGB(PaletteColors['brand-primary'], 0.5),
        borderColor: PaletteColors['brand-primary'],
      }));
    }
  } else {
    getPreviewShiftDivisionsMetrics();
  }

  loading.value = false;
};

const getPreviewShiftDivisionsMetrics = () => {
  datasets.value = Array(10)
    .fill()
    .map(() => ({
      label: '',
      data: [
        {
          r: generateRandomNumber(5, 15),
          rRaw: generateRandomNumber(5, 15),
          x: generateRandomNumber(40, 80),
          y: generateRandomNumber(-20, 20),
        },
      ],
      backgroundColor: hexToRGB(PaletteColors['brand-primary'], 0.5),
      borderColor: PaletteColors['brand-primary'],
    }));
};

const getAverage = (datasets, key) =>
  toNumber(mean(datasets.map(dataset => mean(dataset.data.map(data => data[key])))));

getShiftDivisionsMetrics();
</script>
