<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-form-timepicker
        v-bind="$attrs"
        :button-variant="!($attrs.disabled || $attrs.readonly) ? 'light' : 'dark'"
        :value="value"
        button-only
        hide-header
        @input="onTimepickerInput"
      />
    </b-input-group-prepend>
    <b-form-input v-bind="$attrs" v-on="$listeners" :placeholder="TIME_FORMAT" :value="value" type="text" />
  </b-input-group>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import moment from 'moment';

const TIME_FORMAT = 'HH:mm';
const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';

defineProps({ value: String });
const emit = defineEmits(['input']);

const onTimepickerInput = value => emit('input', moment(value, TIME_FORMAT_WITH_SECONDS).format(TIME_FORMAT));
</script>
