<template>
  <b-modal
    v-model="showModal"
    id="terms-modal"
    size="lg"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-escape
    centered
  >
    <SmeAlert v-if="error" level="danger">{{ error }}</SmeAlert>
    <AppLoading v-if="loading" />
    <Terms
      :termsAccepted="termsAccepted"
      @update:termsAccepted="termsAccepted = $event"
      :diligenceAccepted="diligenceAccepted"
      @update:diligenceAccepted="diligenceAccepted = $event"
      :terms-accepted-text="modalText"
    />
    <div class="terms__cta-container">
      <b-button class="terms__continue" variant="primary" :disabled="!canSubmit" @click="handleTermsSubmit"
        >I accept</b-button
      >
      <b-button
        v-if="canDismiss === undefined ? true : canDismiss"
        class="terms__continue"
        variant="secondary"
        @click="handleTermsDismiss"
        >Dismiss</b-button
      >
    </div>
  </b-modal>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import Terms from '@/components/Terms.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

const props = defineProps(['termsAmendment', 'canDismiss']);

const termsAccepted = ref(false);
const diligenceAccepted = ref(true);
const termsUrl = ref(null);
const agreementId = ref(null);
const loading = ref(false);
const error = ref(false);
const hasSigned = ref(false);
const hasDismissed = ref(
  State?.state?.user?.properties?.[props.termsAmendment.userProperty] ||
    State?.state?.user?.email?.includes('@wagestream.co.uk'),
);

const shouldSignAddendum = computed(() => {
  return !!State?.state?.company?.properties?.[props.termsAmendment.companyProperty];
});

const modalText = computed(() => {
  return props.termsAmendment.getText(termsUrl.value);
});

const showModal = computed(() => !loading.value && shouldSignAddendum.value && !hasDismissed.value && !hasSigned.value);

const canSubmit = computed(() => termsAccepted.value && diligenceAccepted.value);

const handleTermsDismiss = async () => {
  await ApiClient.patchUserProperties({ [props.termsAmendment.userProperty]: new Date().toISOString() });
  await ApiClient.getUser();
  hasDismissed.value =
    State?.state?.user?.properties[props.termsAmendment.userProperty] ||
    State?.state?.user?.email?.includes('@wagestream.co.uk');
};

const handleTermsSubmit = async () => {
  try {
    await ApiClient.acceptTerms(agreementId.value, false);
  } catch (e) {
    error.value = e?.message || 'Unable to accept terms amendment, please try again later';
    return;
  }
  const companyHasSigned = await getAcceptedAgreements();
  hasSigned.value = companyHasSigned;
};

const getAcceptedAgreements = async () => {
  const agreements = await ApiClient.getAcceptedAgreements();
  const companyHasSigned = agreements?.some(
    agreement => agreement?.agreement_id.toLowerCase() === props.termsAmendment.id.toLowerCase(),
  );
  return companyHasSigned;
};

onBeforeMount(async () => {
  loading.value = true;
  try {
    const { contents_url, agreement_id } = await ApiClient.getAgreement(props.termsAmendment.id);
    const companyHasSigned = await getAcceptedAgreements();
    termsUrl.value = contents_url;
    agreementId.value = agreement_id;
    hasSigned.value = companyHasSigned;
  } catch (e) {
    error.value = e?.message || 'Unable to fetch terms. Please try again later';
  }
  loading.value = false;
});
</script>

<style lang="scss" scoped>
::v-deep #terms-modal___BV_modal_content_ {
  padding: 2rem;
}

.terms__continue {
  width: 40%;
  margin-top: 1rem;
}

.terms__cta-container {
  display: flex;
  justify-content: space-around;
}
</style>
