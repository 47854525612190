export const CLAIM_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  WORKED: 'WORKED',
  FULFILLED: 'FULFILLED',
};

export const ASSIGNED_CLAIM_STATUSES = [CLAIM_STATUS.APPROVED, CLAIM_STATUS.WORKED, CLAIM_STATUS.FULFILLED];

export const isAssignedClaim = claim => ASSIGNED_CLAIM_STATUSES.includes(claim.status);

export const getApprovedClaim = claims => claims?.find(claim => claim.status === CLAIM_STATUS.APPROVED);
export const getWorkedClaim = claims => claims?.find(claim => claim.status === CLAIM_STATUS.WORKED);
export const getFulfilledClaim = claims => claims?.find(claim => claim.status === CLAIM_STATUS.FULFILLED);
export const getAssignedClaim = claims => claims?.find(isAssignedClaim);
export const getUnassignedClaims = claims => claims?.filter(claim => !isAssignedClaim(claim)) || [];

export const hasApprovedClaim = claims => !!getApprovedClaim(claims);
export const hasWorkedClaim = claims => !!getWorkedClaim(claims);
export const hasFulfilledClaim = claims => !!getFulfilledClaim(claims);
export const hasAssignedClaim = claims => !!getAssignedClaim(claims);
export const hasPendingClaims = claims =>
  !!(claims?.length && claims.every(claim => claim.status === CLAIM_STATUS.PENDING));
