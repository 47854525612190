<template>
  <b-modal
    :id="props.modalId"
    :size="props.size"
    :no-close-on-backdrop="props.noCloseOnBackdrop"
    :no-close-on-escape="props.noCloseOnEscape"
    :hide-footer="props.hideFooter"
    :centered="props.centered"
    cancel-disabled
    ok-disabled
    ref="modal"
  >
    <template #modal-header>
      <div class="custom-modal-header">
        <span class="title">{{ props.title }}</span>
        <SquareButton @click="close" class="close-button"><Cross /></SquareButton>
      </div>
    </template>
    <div class="content">
      <template v-if="multiColumn">
        <div class="modal-left" :style="{ width: leftColumnWidth }">
          <slot name="modal-left"></slot>
        </div>
        <div class="modal-right">
          <div class="default-content">
            <slot></slot>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="default-content">
          <slot></slot>
        </div>
      </template>
    </div>
    <template #modal-footer>
      <div class="custom-modal-footer">
        <slot name="modal-footer"></slot>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { ref } from 'vue';
import Cross from '@/assets/icons/Cross.vue';
import SquareButton from '@/components/SquareButton.vue';

const props = defineProps({
  title: { type: String, default: '' },
  modalId: { type: String, required: true },
  size: { type: String, default: 'lg' },
  hideFooter: { type: Boolean, default: false },
  noCloseOnBackdrop: { type: Boolean, default: false },
  noCloseOnEscape: { type: Boolean, default: false },
  centered: { type: Boolean, default: true },
  multiColumn: { type: Boolean, default: false },
  leftColumnWidth: { type: String, default: '50%' },
});
const modal = ref(null);
const emit = defineEmits(['on-close']);
defineExpose({ modal });

const close = () => {
  emit('on-close');
  modal.value.hide();
};
</script>

<style scoped lang="scss">
.custom-modal-header {
  border-bottom: 1px solid var(--palette-color-default-lighten-90) !important;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 1rem 0.5rem;
  width: 100%;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 0.5625rem;
    right: 0.5625rem;
  }
}

.content {
  display: flex;
  max-height: 75dvh;
  width: 100%;
  overflow: auto;
}

.content .modal-left {
  width: 50%;
}

.content .modal-right {
  flex-grow: 1;
}

.default-content {
  flex-grow: 1;
}

::v-deep {
  .modal-content {
    overflow: hidden;
  }

  .modal-dialog {
    header.modal-header,
    footer.modal-footer,
    .modal-body {
      padding: 0;
    }
  }

  .modal-alert {
    header.modal-header,
    footer.modal-footer,
    .modal-body {
      border-top: none;
    }
  }

  footer.modal-footer > * {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .content .modal-left {
    width: 100%;
  }
}

.custom-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 0.75rem;
  padding: 1rem;
  border-top: 1px solid var(--palette-color-default-lighten-90);
  background: var(--palette-color-base-white);

  > * {
    margin: 0;
  }
}
</style>
