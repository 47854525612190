import { isPast, isFuture, subHours } from 'date-fns';

// shift states on the portal are different to the ones on the backend, which is why we have a specific portal shift state
// unclaimed: no claims
// claimed: pending
// fulfilled: approved
// completed: fulfilled
// unfilled: unfilled, rejected, denied and end_at < now
export const PortalShiftState = {
  UNCLAIMED: 'unclaimed',
  CLAIMED: 'claimed',
  FULFILLED: 'fulfilled',
  COMPLETED: 'completed',
  UNFILLED: 'unfilled',
};

export const isUnclaimed = shift => {
  return ['UNFILLED', 'REJECTED', 'DENIED'].includes(shift.most_relevant_claim_status) && isFuture(shift.end_at);
};

export const isClaimed = shift => {
  return shift.most_relevant_claim_status === 'PENDING' && isFuture(shift.end_at);
};

export const isFulfilled = shift => {
  return shift.most_relevant_claim_status === 'APPROVED';
};

export const isNoShow = shift => {
  return shift.most_relevant_claim_status === 'NO_SHOW';
};

export const isCompleted = shift => {
  return shift.most_relevant_claim_status === 'FULFILLED';
};

export const canStart = shift => {
  return shift?.most_relevant_claim_status === 'APPROVED' && shift?.assigned_to;
};

export const isUnfilled = shift => {
  return (
    ['UNFILLED', 'REJECTED', 'DENIED', 'PENDING'].includes(shift.most_relevant_claim_status) && isPast(shift.end_at)
  );
};

export const getShiftState = shift => {
  if (isUnclaimed(shift)) {
    return PortalShiftState.UNCLAIMED;
  }
  if (isClaimed(shift)) {
    return PortalShiftState.CLAIMED;
  }
  if (isFulfilled(shift)) {
    return PortalShiftState.FULFILLED;
  }
  if (isCompleted(shift)) {
    return PortalShiftState.COMPLETED;
  }
  if (isUnfilled(shift)) {
    return PortalShiftState.UNFILLED;
  }
  if (isNoShow(shift)) {
    return PortalShiftState.UNFILLED;
  }
};

export const needsApplications = shift => {
  const now = new Date();
  const startTime = new Date(shift.start_at);
  const twoDaysBefore = subHours(startTime, 48);
  return ['UNFILLED', 'REJECTED', 'DENIED'].includes(shift.most_relevant_claim_status) && twoDaysBefore < now;
};

export const needsFilling = shift => {
  const now = new Date();
  const startTime = new Date(shift.start_at);
  const oneDaysBefore = subHours(startTime, 24);
  return shift.most_relevant_claim_status === 'PENDING' && oneDaysBefore < now;
};

export const canClockIn = shift => {
  const now = new Date();
  const startTime = new Date(shift.start_at);
  const endTime = new Date(shift.end_at);
  const twoHoursBefore = subHours(startTime, 2);

  return (
    shift.most_relevant_claim_status === 'APPROVED' &&
    !shift.winning_claim_clocked_in_at &&
    twoHoursBefore < now &&
    isFuture(endTime)
  );
};

export const canFinalise = shift => {
  return (
    !shift.claim_properties?.admin_sign_off && shift.most_relevant_claim_status === 'APPROVED' && isPast(shift.start_at)
  );
};

export const getFulfilledAction = shift => {
  if (canClockIn(shift)) {
    return 'Clock-in';
  }
  if (canFinalise(shift)) {
    return 'Sign-off';
  }
  return null;
};

export const isUrgent = shift => {
  if (canClockIn(shift) || canFinalise(shift) || needsApplications(shift) || needsFilling(shift)) {
    return true;
  }
  return false;
};

export const urgencyLabel = tab => {
  if (tab === 'fulfilled') {
    return 'Clock-in and sign-off';
  }
  if (tab === 'unclaimed') {
    return 'Happening soon';
  }
  if (tab === 'claimed') {
    return 'Select a worker for your upcoming shift';
  }
};
