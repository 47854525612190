<template>
  <b-tabs v-model="activeTabIndex" v-bind="$attrs" no-fade @input="setActiveTab">
    <slot></slot>
  </b-tabs>
</template>

<script setup>
import { nextTick, onMounted, ref, useSlots, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const $route = useRoute();
const $router = useRouter();
const $slots = useSlots();

const activeTabIndex = ref(0);

watch(
  () => $route.query.tab,
  tab => setActiveTabIndex(tab),
);

const setActiveTab = (index, replace = false) => {
  const $mountedTabs = $slots.default().filter(node => node.elm && node.elm.id);
  const $tab = $mountedTabs[index];
  const tabId = $tab?.elm.id;

  if (tabId) {
    $tab.componentInstance.$emit('shown');

    if ($route.query.tab !== tabId) {
      $router[replace ? 'replace' : 'push']({ query: { ...$route.query, tab: tabId } });
    }
  }
};

const setActiveTabIndex = tab => {
  const $mountedTabs = $slots.default().filter(node => node.elm && node.elm.id);
  const tabIndex = tab ? $mountedTabs.findIndex(node => node.elm.id === tab) : -1;

  nextTick(() => {
    if (tabIndex > -1) {
      activeTabIndex.value = tabIndex;
    } else {
      setActiveTab(0, true);
    }
  });
};

onMounted(() => setActiveTabIndex($route.query.tab));
</script>
