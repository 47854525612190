import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=a454927e&scoped=true&"
import script from "./RegisterPage.vue?vue&type=script&setup=true&lang=js&"
export * from "./RegisterPage.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./RegisterPage.vue?vue&type=style&index=0&id=a454927e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a454927e",
  null
  
)

export default component.exports