<template>
  <div>
    <div v-if="termsAcceptedText" class="registration-terms__checkbox">
      <AppInput type="checkbox-single" v-model="termsAccepted" name="checkbox-terms">
        <label class="registration-terms__checkbox-text" for="checkbox-terms">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="termsAcceptedText" />
        </label>
      </AppInput>
    </div>
    <div v-if="diligenceAcceptedText" class="registration-terms__checkbox">
      <AppInput type="checkbox-single" v-model="diligenceAccepted" name="checkbox-diligence">
        <label class="registration-terms__checkbox-text" for="checkbox-diligence">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="diligenceAcceptedText" />
        </label>
      </AppInput>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import AppInput from '@/components/AppInput.vue';

const emit = defineEmits(['update:termsAccepted', 'update:diligenceAccepted']);

const props = defineProps(['termsAccepted', 'diligenceAccepted', 'termsAcceptedText', 'diligenceAcceptedText']);

const stateTermsAccepted = ref(props.termsAccepted);
const stateDiligenceAccepted = ref(props.diligenceAccepted);

const termsAccepted = computed({
  get: () => props.termsAccepted,
  set: newValue => (stateTermsAccepted.value = newValue),
});
const diligenceAccepted = computed({
  get: () => props.diligenceAccepted,
  set: newValue => {
    stateDiligenceAccepted.value = newValue;
  },
});

watch(stateTermsAccepted, () => {
  emit('update:termsAccepted', stateTermsAccepted.value);
});

watch(stateDiligenceAccepted, () => {
  emit('update:diligenceAccepted', stateDiligenceAccepted.value);
});
</script>

<style lang="scss" scoped>
.registration-terms__checkbox {
  margin-top: 1rem;

  .registration-terms__checkbox-text {
    margin-left: 1rem;
    font-size: large;
    font-weight: 300;
  }
}

::v-deep .input-group {
  flex-wrap: nowrap;
  align-items: center;
}
</style>
