<template>
  <component :is="to ? 'router-link' : 'span'" class="sme-toast" :to="to">
    <font-awesome-icon :icon="['fas', icon]" class="mr-1" />
    <p class="sme-toast__text">
      {{ text.pre }} <strong v-if="text.emphasis">{{ text.emphasis }}</strong> {{ text.post }}
    </p>
    <font-awesome-icon v-if="to" :icon="['fad', 'external-link']" class="sme-toast__link-icon ml-2" />
  </component>
</template>

<script>
const TEXT_KEYS = {
  PRE: 'pre',
  EMPHASIS: 'emphasis',
  POST: 'post',
};

const TEXT_KEYS_LIST = [TEXT_KEYS.PRE, TEXT_KEYS.EMPHASIS, TEXT_KEYS.POST];
</script>

<script setup>
defineProps({
  icon: {
    type: String,
    required: true,
  },
  text: {
    type: Object,
    required: true,
    validator: text => Object.keys(text).every(key => TEXT_KEYS_LIST.includes(key)),
  },
  to: Object,
});
</script>

<style lang="scss" scoped>
.sme-toast {
  align-items: center;
  align-self: flex-start;
  background-color: var(--palette-color-base-white);
  border-radius: 5px;
  color: var(--palette-color-default-lighten-20);
  display: flex;
  flex-direction: row;
  margin-right: 0.75rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:is(a) {
    &:hover {
      color: var(--palette-color-brand-primary);
    }
  }
}

.sme-toast__text {
  margin: 0 0 0 0.25rem;
}

.sme-toast__link-icon {
  color: var(--palette-color-brand-primary);
}
</style>
