const defaultState = {
  loaded: false,
  loading: true,
  integrations: undefined,
};

const IntegrationsState = {
  state: { ...defaultState },
  setIntegrations(data) {
    if (data.length > 0) {
      this.state.integrations = data;
    } else {
      this.state.integrations = undefined;
    }
    this.state.loaded = true;
    this.state.loading = true;
  },
  logout() {
    this.state = { ...defaultState };
  },
  setLoading() {
    this.loading = true;
  },
  getBrowserUploadEntities() {
    if (!this.state.integrations) {
      return undefined;
    }
    const integration = this.state.integrations.find(integration => integration.partner === 'BROWSER_UPLOAD');
    if (!integration) {
      return [];
    } else {
      const integrations = Object.keys(integration.file_headers).map(key => key.replace('_input', '')) || [];
      return integrations;
    }
  },
  getRequiredColumns(entity) {
    if (!this.state.integrations) {
      return undefined;
    }

    const integration = this.state.integrations.find(integration => integration.file_headers[entity + '_input']);
    if (!integration) {
      return undefined;
    }

    return integration.file_headers[entity + '_input'];
  },
  getIsAirflowPlatformEnabled() {
    const integration = this.state.integrations?.find(i => i.partner === 'BROWSER_UPLOAD');
    return integration?.properties.integration_platform === 'AIRFLOW';
  },
};

export default IntegrationsState;
