import { render, staticRenderFns } from "./Envelope.vue?vue&type=template&id=7e0d4cfd&"
import script from "./Envelope.vue?vue&type=script&setup=true&lang=js&"
export * from "./Envelope.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports