<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- It is used only for formatting for i18n values here, so XSS attack is not a case. -->
  <div class="outer">
    <div class="inner">
      <header class="header">
        <img class="logo" src="@/components/assets/wagestream-logo-small-purple.png" alt="Wagestream Logo" />
        <div class="title1">Give your clients</div>
        <div class="title2">power over pay</div>
        <div class="subtitle">Partner and integrate with Wagestream</div>
      </header>
      <main class="main">
        <h1>Xero Integration</h1>
        <p>If you are already a Xero user, you can sign up to Wagestream using your Xero account.</p>
        <h2>Contents</h2>
        <ol id="contents-list">
          <li><a href="#payroll">Xero payroll users</a></li>
          <li><a href="#non-payroll">Non- Xero payroll users</a></li>
          <li><a href="#sign-up">Sign up with Xero</a></li>
          <li><a href="#troubleshooting">Troubleshooting</a></li>
        </ol>
        <h2 id="payroll">Xero payroll users</h2>
        <p>
          If you use Xero Payroll, you can use the Wagestream Xero App to register with Wagestream. Additionally,
          Wagestream will automatically "sync" your employees and pay calendars from Xero payroll. This provides us with
          the information that we need to start calculating an employee's earned wages, the first step of allowing your
          employees to access their earned wages on demand. Once you're inside the Wagestream dashboard you can take a
          look around, and decide on whether Wagestream is right for you and your employees - there is no obligation,
          and no upfront cost.
        </p>
        <h2 id="non-payroll">2. Non- Xero payroll users</h2>
        <p>
          If you use Xero Payroll, you can use the Wagestream Xero App to register with Wagestream. This will get you
          into the Wagestream dashboard. From there, you can integrate with one of our partner rota providers to
          automatically sync employees from there, or upload employees manually.
        </p>
        <h2 id="sign-up">3. Sign up with Xero</h2>
        <p>
          Using the Wagestream Xero App is very easy. Start the sign up process by clicking "Add App" from within the
          Xero App store, or by navigating to
          <a href="https://dashboard.wagestream.com/register">https://dashboard.wagestream.com /register</a> and
          clicking "sign up with Xero". Sign in with Xero and select your Xero organization when prompted. Once you
          confirm your organization, Wagestream will load for 5-10 seconds while we create your account. You will then
          be prompted to accept the Wagestream terms and conditions. Once you do, you will be inside the Wagestream
          dashboard - simple as that!
        </p>
        <p>
          Once you have reached the Wagestream dashboard, check your emails. This is the email account that you use to
          sign in with Xero. You should have a password reset link, which you should use to reset your password. You can
          use your email and password combination to sign into Wagestream from this point onwards.
        </p>
        <p>
          If you wish to view your Xero integration settings, you can navigate to
          <a href="https://dashboard.wagestream.com/xero-configuration"
            >https://dashboard.wagestream.com/xero-configuration</a
          >
          once you have signed in. This will show you whether your Wagestream account is currently connected to Xero.
          <strong
            >If you choose to use Wagestream to provide earned wage access to your employees, we would strongly
            recommend that you don't disconnect your account using this method.</strong
          >
          If you wish to disconnect your account, please contact <a href="sme@wagestream.com">sme@wagestream.com</a>
        </p>
        <h2 id="payroll">4. Troubleshooting</h2>
        <p>
          If you encounter problems with the Wagestream App please contact
          <a href="sme@wagestream.com">sme@wagestream.com</a>
          - we would be more than happy to get you set up as quickly as possible.
        </p>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XeroDocumentation',
};
</script>

<style scoped lang="scss">
* {
  text-align: justify;
  text-justify: inter-word;
}

.outer {
  background: var(--palette-color-base-background);
  position: relative;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
}

.outer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.inner {
  max-width: 588px;
  padding: 16px;
  margin: 0 auto;
}

.logo {
  display: block;
  margin: 45px auto 25px auto;
  height: 51px;
  width: 78px;
}

.title1,
.title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
}

.title1 {
  color: var(--palette-color-default-lighten-20);
}

.title2 {
  color: var(--palette-color-brand-primary);
}

@media (max-width: 768px) {
  .title1,
  .title2 {
    font-size: 36px;
  }
}

.subtitle {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: var(--palette-color-default-lighten-20);
  margin-top: 24px;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 70px 0;
}

@media (max-width: 768px) {
  .steps {
    margin: 30px 0 30px 0 !important;
    padding: 0 16px;
  }
}

.steps__item {
  color: var(--palette-color-base-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9000px;
  background: var(--palette-color-default-lighten-30);
  min-height: 32px;
  min-width: 32px;
  font-weight: 300;
  font-size: 25px;
  opacity: 0.25;
}

.steps__line {
  border-top: 1px solid var(--palette-color-default-lighten-30);
  width: 85px;
  opacity: 0.5;
}

.steps__item--active {
  opacity: 1;
}

.steps__line:last-child {
  display: none;
}

.main {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: auto;
  background: var(--palette-color-base-white);
  color: var(--palette-color-default-lighten-30);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-weight: 300;
  font-size: 14px;
  margin: 48px 0;
  padding: 84px;
  // width: 588px;
}

code {
  padding: 0;
}

@media (max-width: 768px) {
  .main {
    padding: 32px;
    // width: auto;
  }
}

.main h2,
.main h3,
.main h4,
.main h5,
.main h6,
strong {
  color: var(--palette-color-default);
}
</style>
