import State from '@/state/State';

// This strings won't be translated. Use translated strings here depending on each country's language.
const bankingByCountry = [
  {
    // GB must come first (the default)
    countryCodes: ['GB'],
    sortCode: {
      placeholder: 'XXXXXX',
      regex: /^[0-9]{6}$/,
      label: 'Sort Code',
      description: 'Enter 6 digit sort code # without any spaces',
    },
    accountNumber: {
      placeholder: 'XXXXXXXX',
      regex: /^\d{8}$/,
      label: 'Account Number',
      description: 'Enter 8 digit account # without any spaces',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'bin icon',
  },
  {
    countryCodes: ['GBEUR'],
    sortCode: {
      placeholder: 'XXXXXXXX',

      regex: /^[A-Z]{6}[0-9]{2,5}$/,
      label: 'BIC',
      description: 'Enter 8 or 11 digit BIC/SWIFT code',
    },
    accountNumber: {
      placeholder: 'XXXXXXXXXXXXXX',
      regex: /^GB\d{2}[A-Z]{4}\d{14}$/,
      label: 'IBAN Account Number',
      description: 'Enter IBAN account # without any spaces',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'bin icon',
  },
  {
    countryCodes: ['IE'],
    sortCode: {
      placeholder: 'XXXXXXXX',
      regex: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      label: 'BIC',
      description: 'Enter 8 or 11 digit BIC/SWIFT code',
    },
    accountNumber: {
      placeholder: 'XXXXXXXXXXXXXX',
      regex: /^\S{2}\d{2}[A-Z]{4}\d{14}$/,
      label: 'IBAN Account Number',
      description: 'Enter IBAN account # without any spaces',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'bin icon',
  },
  {
    countryCodes: ['NL'],
    sortCode: {
      placeholder: 'XXXXXXXX',
      regex: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      label: 'BIC',
      description: 'Enter 8 or 11 digit BIC/SWIFT code',
    },
    accountNumber: {
      placeholder: 'XXXXXXXXXXXXXX',
      regex: /^\S{2}[a-zA-Z0-9]{1,20}$/,
      label: 'IBAN Account Number',
      description: 'Enter IBAN account # without any spaces',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    binIcon: 'bin icon',
  },
  {
    countryCodes: ['ES'],
    sortCode: {
      placeholder: 'XXXXXXXX',
      regex: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      label: 'BIC',
      description: 'Código BIC entre 8 y 11 caracteres',
    },
    accountNumber: {
      placeholder: 'XXXXXXXXXXXXXX',
      regex: /^ES\d{22}$/,
      label: 'IBAN Account Number',
      description: 'Número IBAN sin ningún espacio',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'bin icon',
  },
  {
    countryCodes: ['US'],
    sortCode: {
      placeholder: 'XXXXXXXXX',
      regex: /^[0-9]{9}$/,
      label: 'Routing Number',
      description: 'Enter 9 digit routing # without any spaces',
    },
    accountNumber: {
      placeholder: 'XXXXX',
      regex: /^[0-9]{5,17}$/,
      label: 'Account Number',
      description: 'Enter account # between 5 and 17 digits without any spaces',
    },
    bankDetails: 'Direct Deposit Details',
    currencyIcon: 'dollar-sign',
    save: {
      label: 'Save',
      potLabel: 'savings account',
    },
    stream: {
      label: 'Transfer',
      pastTense: 'transferred',
    },
    rota: {
      label: 'time and attendance',
      rotaProvider: 'time and attendance system',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'trash icon',
    accountType: {
      label: 'Account Type',
      options: [
        {
          text: 'Checking',
          value: 'C',
        },
        {
          text: 'Savings',
          value: 'S',
        },
      ],
      placeholder: 'Select Account Type',
      description: 'Select the type of account you are using',
    },
  },
  {
    countryCodes: ['AU'],
    sortCode: {
      placeholder: 'XXXXXX',
      regex: /^[0-9]{6}$/,
      label: 'BSB',
      description: 'Enter the 6 digit BSB without any hyphens',
    },
    accountNumber: {
      placeholder: 'XXXXXXXXXX',
      regex: /^[0-9]{6,10}$/,
      label: 'Account Number',
      description: 'Enter account # between 6 and 10 digits without any spaces',
    },
    bankDetails: 'Bank Details',
    currencyIcon: 'sterling-sign',
    save: {
      label: 'Build',
      potLabel: 'build pot',
    },
    stream: {
      label: 'Stream',
      pastTense: 'streamed',
    },
    sdl: {
      label: 'Loans',
      smallLabel: 'loan',
    },
    rota: {
      label: 'rota',
      rotaProvider: 'rota provider',
    },
    absence: {
      label: 'absence',
      absenceProvider: 'absence provider',
    },
    binIcon: 'bin icon',
  },
];

export function getBankingInfo(countryCode) {
  return (
    bankingByCountry.find(info =>
      info.countryCodes.includes(countryCode || State.state.company.properties.payment_country_code),
    ) || bankingByCountry[0]
  );
}

export function getBankingInfoByCurrency(currency) {
  let countryCode;
  switch (currency) {
    case 'GBP':
      countryCode = 'GB';
      break;
    case 'EUR':
      countryCode = 'GBEUR';
      break;
    case 'AUD':
      countryCode = 'AU';
      break;
  }
  return getBankingInfo(countryCode);
}
