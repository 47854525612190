<template>
  <section class="app-schedule">
    <header class="app-schedule__header">
      <nav class="app-schedule__nav">
        <b-button variant="light" @click="emit('reset')">Today</b-button>
        <nav class="app-schedule__sub-nav">
          <b-button variant="light" class="app-schedule__sub-nav-button" title="Previous" @click="emit('previous')">
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
          </b-button>
          <h2 class="app-schedule__sub-nav-title">
            {{ toFormattedDate(dates[0], 'ddd, D MMM') }} - {{ toFormattedDate(dates[dates.length - 1], 'ddd, D MMM') }}
          </h2>
          <b-button variant="light" class="app-schedule__sub-nav-button" title="Next" @click="emit('next')">
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </b-button>
        </nav>
      </nav>
      <ul class="app-schedule__dates">
        <li v-for="date in formattedDates" :key="date" class="app-schedule__dates-date">
          {{ date }}
        </li>
      </ul>
    </header>
    <slot></slot>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { toFormattedDate } from '@/utils/date';

const props = defineProps({
  dates: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['next', 'previous', 'reset']);

const formattedDates = computed(() => props.dates.map(date => toFormattedDate(date, 'ddd, D MMM')));
</script>

<style lang="scss" scoped>
.app-schedule {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  box-shadow: var(--depth-2);
  max-width: 100%;
  overflow: auto;
}

.app-schedule__header {
  border-bottom: 1px solid var(--palette-color-default-lighten-95);
  min-width: 1024px;
  padding-top: 0.5rem;
}

.app-schedule__nav {
  align-items: center;
  border-bottom: 1px solid var(--palette-color-default-lighten-95);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 0 0.5rem 12.5%;
}

.app-schedule__sub-nav {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.app-schedule__sub-nav-button {
  border-radius: 50%;
  height: 2rem;
  padding: 0;
  width: 2rem;
}

.app-schedule__sub-nav-title {
  color: var(--palette-color-default);
  font-size: 16px;
  margin: 0 1rem;
}

.app-schedule__dates {
  display: flex;
  flex-basis: 100%;
  margin: 0;
  padding-left: 12.5%;
}

.app-schedule__dates-date {
  border-left: 1px solid var(--palette-color-default-lighten-95);
  flex: 1;
  list-style-type: none;
  padding: 0.5rem 0;
  text-align: center;

  &:first-child {
    padding-left: 0.66rem;
  }

  &:last-child {
    padding-right: 0.66rem;
  }
}

.app-schedule__body {
  min-width: 1024px;
}

.app-schedule__row {
  display: flex;

  &:first-child {
    .app-schedule__date {
      padding-top: 0.66rem;
    }
  }

  &:last-child {
    .app-schedule__date {
      padding-bottom: 0.66rem;
    }
  }
}

.app-schedule__row-header {
  padding: 1rem;
  width: 12.5%;
}

.app-schedule__row-header-title {
  color: var(--palette-color-default);
  font-size: 14px;
}

.app-schedule__row-header-subtitle {
  color: var(--palette-color-default-lighten-20);
  font-size: 12px;
}

.app-schedule__dates-row {
  display: flex;
  flex: 1;
  margin: 0;
  padding-left: 0;
}

.app-schedule__date {
  background-color: var(--palette-color-base-background);
  flex: 1;
  list-style-type: none;
  min-height: 100%;
  padding: 0.33rem;

  &:first-child {
    padding-left: 0.66rem;
  }

  &:last-child {
    padding-right: 0.66rem;
  }
}

.app-schedule__card {
  background-color: var(--palette-color-base-white);
  border-radius: 4px;
  box-shadow: var(--depth-2);
  cursor: pointer;
  padding: 0.66rem;

  & + & {
    margin-top: 0.66rem;
  }
}
</style>
