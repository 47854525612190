import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { useStateComposable } from '@/state/composables';
import { convertPayschedules } from '@/utils/convertPayschedules';

const { computedState, state, reset } = useStateComposable({
  payschedules: [],
  error: false,
  initial: true,
  loading: false,
});

const getPayschedules = async fetchOnlyIfInitial => {
  if (fetchOnlyIfInitial && !state.initial) {
    return;
  }

  reset();

  state.initial = false;
  state.loading = true;

  try {
    const payschedules = (await ApiClient.getAllCompanyPayschedules(State.state.company.company_id))?.data || [];
    state.payschedules = convertPayschedules(payschedules);
  } catch (error) {
    state.error = true;
    console.error(error);
  }

  state.loading = false;
};

export default () => {
  return {
    ...computedState,
    getPayschedules,
  };
};
