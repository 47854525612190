<template>
  <div class="job-tag" v-if="props.groupType === 'job_posting'" @click="goToParent()">
    <Link width="20" height="20" /> Job listing: {{ props.roleName }}
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router/composables';
import Link from '@/assets/icons/Link.vue';

const props = defineProps({
  locationId: String,
  groupId: String,
  groupType: String,
  roleName: String,
});
const router = useRouter();

const goToParent = () => {
  if (props.groupType === 'job_posting') {
    router.push({ name: 'talent-pool-job-detail', params: { jobId: props.groupId, locationId: props.locationId } });
  }
};
</script>

<style scoped>
.job-tag {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: var(--palette-color-base-white);
  color: var(--palette-color-brand-primary);
  padding: 6px 12px 6px 8px;
  border-radius: 50px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-color-default-lighten-90);
  transition: 0.2s;

  svg {
    fill: var(--palette-color-brand-primary);
  }

  &:hover {
    cursor: pointer;
    border-color: var(--palette-color-brand-primary);
    box-shadow: 0px 0px 2px 0px rgba(34, 40, 47, 0.05), 0px 2px 10px 0px rgba(34, 40, 47, 0.1);
  }
}
</style>
