<template>
  <b-form-group>
    <SmeCard v-for="option in options" :key="option.title" class="app-radio-cards__group">
      <label class="app-radio-cards__label" :for="option.title">
        <div class="app-radio-cards__text">
          <h5 class="app-radio-cards__text-title">{{ option.title }}</h5>
          <p v-if="option.body" class="app-radio-cards__text-description">{{ option.body }}</p>
        </div>
        <b-form-radio
          :id="option.title"
          :value="option.title"
          :checked="value"
          @input="emit('input', $event)"
          type="radio"
          class="app-radio-cards__radio"
        />
      </label>
    </SmeCard>
  </b-form-group>
</template>

<script setup>
import { computed } from 'vue';
import SmeCard from '@/components/atoms/SmeCard';

const emit = defineEmits(['input']);
const props = defineProps(['value', 'options']);
const options = computed(() => props.options);
</script>

<style scoped lang="scss">
.app-radio-cards__group {
  border: 1px solid var(--palette-color-default-lighten-80);
  padding: 0;
}

.app-radio-cards__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 0;

  &:hover {
    cursor: pointer;
  }
}

.app-radio-cards__text {
  display: flex;
  flex-direction: column;
  color: var(--palette-color-default-lighten-30);
  padding: 1rem;
  padding-right: 3rem;
}

.app-radio-cards__text-title {
  font-size: x-large;
  color: var(--palette-color-default-lighten-20);
  font-weight: 400;
}

.app-radio-cards__text-title:last-child {
  margin: 0;
}

.app-radio-cards__text-description {
  font-size: large;
  color: var(--palette-color-default-lighten-20);
  font-weight: 300;
}

.app-radio-cards__radio {
  position: relative;
  padding: 0;
  margin-right: 1rem;
}
</style>
