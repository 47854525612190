<template>
  <b-form-group class="list-dropdown" :label="label" label-class="list-dropdown__label" :label-for="id">
    <b-dropdown :id="id" ref="$dropdown" class="w-100" variant="light" no-caret>
      <template #button-content>
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />
        <strong>Select {{ valueText || 'Values' }}</strong>
        <b-badge v-if="value.length" class="ml-2" variant="primary">{{ value.length }}</b-badge>
      </template>
      <template #default>
        <b-dropdown-form @submit.prevent="$dropdown.hide(true)">
          <div class="list-dropdown__list-container">
            <section class="list-dropdown__list">
              <div class="list-dropdown__btns">
                <b-dropdown-item-button @click="selectAllItems()">
                  <font-awesome-icon :icon="['fal', 'check']" class="mr-2" /> All {{ valueText || 'Values' }}
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="value.length" @click="clearItems()">
                  <font-awesome-icon :icon="['fal', 'circle-xmark']" class="mr-2" /> Clear
                </b-dropdown-item-button>
              </div>
              <b-dropdown-divider class="mb-3" />
              <app-input v-model="listValue" type="checkbox" :options="options" stacked />
            </section>
          </div>
        </b-dropdown-form>
      </template>
    </b-dropdown>
  </b-form-group>
</template>

<script setup>
import { computed, ref } from 'vue';
import AppInput from '@/components/AppInput.vue';

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  value: {
    type: Array,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  valueText: String,
  label: String,
});

const emit = defineEmits(['input']);

const $dropdown = ref(undefined);

const clearItems = () => (listValue.value = []);

const selectAllItems = () => (listValue.value = props.options.map(option => option.value));

const listValue = computed({
  get: () => props.value,
  set: value => emit('input', value),
});
</script>

<style lang="scss" scoped>
.list-dropdown {
  :deep(.input-group) {
    width: 100%;

    .custom-control {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

:deep(.list-dropdown__label) {
  font-weight: 500;
}

.list-dropdown__list-container {
  display: flex;
  min-width: 30rem;

  @media (max-width: 576px) {
    min-width: 20rem;
  }
}

.list-dropdown__list {
  flex: 1;
  max-height: 20rem;
  min-height: 100%;
  overflow: auto;
  padding-bottom: 0.75rem;
}

.list-dropdown__btns {
  display: flex;
  justify-content: center;
}
</style>
