<template>
  <div class="salaries-list">
    <page-list-header>
      <template #left>
        <app-input v-model="expandFields" name="Expand Fields" type="checkbox-single" class="my-2" switch>
          Expand Fields
        </app-input>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          :file-name="exportFilename"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
          :export-data="processedSalaries"
        />
      </template>
    </page-list-header>

    <b-table
      primary-key="salary_id"
      :items="processedSalaries"
      :fields="fields"
      class="mb-3"
      table-class="table-minimal"
      :tfoot-class="!processedSalaries?.length ? 'd-none' : ''"
      foot-clone
      show-empty
      responsive
    >
      <template v-slot:cell(off_cycle_payment)="data">
        <div v-if="data.item.is_off_cycle_payment" v-b-tooltip.top class="d-inline-flex" title="Off-Cycle Payment">
          <font-awesome-icon :icon="['fad', 'donate']" class="off-cycle-payment__icon" />
        </div>
      </template>

      <template v-slot:cell(download)="data">
        <b-button
          class="border-0"
          size="sm"
          variant="outline-primary"
          @click="downloadSalaryStatement(data.item.salary_id)"
        >
          <font-awesome-icon :icon="['fad', 'download']" />
        </b-button>
      </template>
      <template v-slot:foot()>&nbsp;</template>
      <template v-slot:foot(salary_in)>{{ toCurrency(getFieldTotal('salary_in')) }}</template>
      <template v-slot:foot(withdrawals_repaid)>{{ toCurrency(getFieldTotal('withdrawals_repaid')) }}</template>
      <template v-slot:foot(credits_applied)>{{ toCurrency(getFieldTotal('credits_applied')) }}</template>
      <template v-slot:foot(combined_stream_fee)>{{ toCurrency(getFieldTotal('combined_stream_fee')) }}</template>
      <template v-slot:foot(payment_out)>{{ toCurrency(getFieldTotal('payment_out')) }}</template>
      <template v-slot:foot(outstanding_withdrawals)>{{
        toCurrency(getFieldTotal('outstanding_withdrawals'))
      }}</template>
      <template v-slot:foot(overdue_withdrawals)>{{ toCurrency(getFieldTotal('overdue_withdrawals')) }}</template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="processedSalaries.length"
      :per-page="perPage"
      class="justify-content-end"
    />
  </div>
</template>

<script setup>
import sumBy from 'lodash/sumBy';
import { computed, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import ExportButton from '@/components/ExportButton.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import { toCurrency } from '@/utils';
import { toFormattedDate } from '@/utils/date';

const SIMPLE_FIELDS = [
  {
    key: 'timestamp',
    label: 'Pay Date',
    formatter: value => toFormattedDate(value),
  },
  {
    key: 'salary_in',
    label: 'Earnings',
    formatter: value => toCurrency(value),
  },
  {
    key: 'combined_stream_fee',
    label: 'Repaid',
    formatter: value => toCurrency(value),
  },
  {
    key: 'payment_out',
    label: 'Pay',
    formatter: value => toCurrency(value),
  },
  {
    key: 'off_cycle_payment',
    label: '',
    thStyle: { width: '5%' },
  },
  {
    key: 'download',
    label: '',
    tdClass: 'text-right',
    thStyle: { width: '5%' },
  },
];

const EXPANDED_FIELDS = [
  {
    key: 'timestamp',
    label: 'Pay Date',
    formatter: value => toFormattedDate(value),
  },
  {
    key: 'salary_in',
    label: 'Earnings',
    formatter: value => toCurrency(value),
  },
  {
    key: 'withdrawals_repaid',
    label: 'Repaid',
    formatter: value => toCurrency(value),
  },
  {
    key: 'credits_applied',
    label: 'Credits',
    formatter: value => toCurrency(value),
  },
  {
    key: 'payment_out',
    label: 'Pay',
    formatter: value => toCurrency(value),
  },
  {
    key: 'outstanding_withdrawals',
    label: 'Outstanding Withdrawals',
    formatter: value => toCurrency(value),
  },
  {
    key: 'overdue_withdrawals',
    label: 'Overdue Withdrawals',
    formatter: value => toCurrency(value),
  },
  {
    key: 'off_cycle_payment',
    label: '',
    thStyle: { width: '5%' },
  },
  {
    key: 'download',
    label: '',
    tdClass: 'text-right',
    thStyle: { width: '5%' },
  },
];

const PER_PAGE_OPTIONS = [25, 50, 100];

const props = defineProps({
  salaries: {
    type: Array,
    required: true,
  },
  employeeName: {
    type: String,
    required: true,
  },
  offCyclePayments: {
    type: Array,
    required: false,
  },
});

const expandFields = ref(false);
const currentPage = ref(1);
const perPage = ref(25);

const processedSalaries = computed(() => {
  const salaries = props.salaries.map(salary => ({
    ...salary,
    combined_stream_fee: salary.withdrawals_repaid + salary.fee_collected,
  }));

  const mappedOffCyclePayments = props.offCyclePayments?.map(payment => ({
    timestamp: payment.expected_date,
    salary_in: payment.amount_pence,
    payment_out: payment.amount_pence,
    is_off_cycle_payment: true,
  }));

  const salariesPlusOffCycle = salaries.concat(mappedOffCyclePayments);
  salariesPlusOffCycle.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

  return salariesPlusOffCycle;
});

const fields = computed(() => {
  let fields = expandFields.value ? EXPANDED_FIELDS : SIMPLE_FIELDS;

  if (!props.offCyclePayments.length) {
    fields = fields.filter(field => field.key !== 'off_cycle_payment');
  }
  const fixedFields = fields.filter(field => !!field.thStyle?.width);

  return fields.map(field => ({
    ...field,
    ...(!field.thStyle?.width
      ? {
          thStyle: {
            minWidth: '5rem',
            width: `${(100 - 5) / fields.length - fixedFields.length}%`,
          },
        }
      : null),
  }));
});

const exportFields = computed(() => fields.value.filter(field => field.key !== 'download'));
const exportHeaders = computed(() => exportFields.value.map(field => field.label));
const exportFilename = computed(() => {
  const now = toFormattedDate(new Date());
  const employeeNameSlug = props.employeeName?.toLowerCase()?.replace(/ /g, '-') || '';

  return `statements-${now}-${employeeNameSlug}`.substring(0, 30);
});

const getFieldTotal = field => sumBy(processedSalaries.value, salary => salary[field]);

const downloadSalaryStatement = salaryId => ApiClient.downloadEmployeeSalaryStatement(salaryId);

const exportFileRows = item =>
  fields.value.map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));
</script>

<style>
.off-cycle-payment__icon {
  color: var(--palette-color-brand-primary);
}
</style>
