<template>
  <sme-card large>
    <sme-alert level="info" class="mb-3">
      This vendor requires you to do a bit of manual configuration on their website before allowing us to integrate.
      Just follow these instructions.
    </sme-alert>
    <img :alt="provider" class="integrations-submit-api-credentials__logo mb-3" :src="icon" />
    <SmeAlert v-if="error" level="danger" class="mb-3">
      We were unable to connect your integation. Please contact support at
      <a href="mailto:sme@wagestream.com">sme@wagestream.com</a> for assistance.
    </SmeAlert>
    <template v-else>
      <ValidationObserver v-slot="v" tag="div">
        <b-form @submit.prevent="">
          <div v-if="provider == 'rotaready'">
            <div class="mb-3">
              <p>
                <strong>NB:</strong> You will need to have “Full Access” to your RotaReady account to request the API
                Credentials.
              </p>
              <ol class="pl-3">
                <li>Go to you RotaReady Live Chat.</li>
                <li>
                  Request API credentials of Key and Key Secret for Wagestream (be specific it is for Wagestream).
                </li>
                <li>Request the credentials are sent to you.</li>
                <li>Input the credentials in the below fields.</li>
                <li>Hit "Connect".</li>
              </ol>
            </div>
            <app-input
              v-model="configs.rotaready.realm"
              :validate-immediate="false"
              type="text"
              name="realm"
              rules="required"
              label="Realm"
              description="Your organisation realm in Rotaready"
            />
            <app-input
              v-model="configs.rotaready.apiKey"
              :validate-immediate="false"
              type="text"
              name="apiKey"
              rules="required"
              label="API Key"
              description="The API key you created for the integration."
            />
            <app-input
              v-model="configs.rotaready.apiSecret"
              :validate-immediate="false"
              type="text"
              name="apiSecret"
              rules="required"
              label="API Secret"
              description="The API secret you created for the integration."
            />
          </div>
          <div v-if="provider == 's4labour'">
            <div class="mb-3">
              <ol class="pl-3">
                <li>
                  Either email <a href="mailto:s4labour@s4labour.co.uk">s4labour@s4labour.co.uk</a> or reach out to your
                  designated S4Labour Account Manager and request the following: Org ID, API Username, and API Password
                  for use by Wagestream.
                </li>
                <li>Insert the returned information from S4 Labour into the field boxes below.</li>
                <li>Hit “Connect”.</li>
              </ol>
            </div>
            <app-input
              v-model="configs.s4labour.organisationId"
              :validate-immediate="false"
              type="text"
              name="orgid"
              rules="required"
              label="Organisation ID"
              description="Your organisation ID in S4Labour"
            />
            <app-input
              v-model="configs.s4labour.apiUser"
              :validate-immediate="false"
              type="text"
              name="apiUser"
              rules="required"
              label="API User"
              description="The username created for your integration. Not your username."
            />
            <app-input
              v-model="configs.s4labour.apiPassword"
              :validate-immediate="false"
              type="text"
              name="apiPass"
              rules="required"
              label="API Password"
              description="The password created for your integration. Not your password."
            />
          </div>
          <div v-if="provider == 'hibob'">
            <div class="mb-3">
              <p>
                You need to set up an "API service user" in HiBob so that we can access your employee and payroll
                information. This process is well documented on the Hibob website.
              </p>
              <p>
                <strong>NB: Hibob can only be used for salaried employees.</strong>
              </p>
              <ol class="pl-3">
                <li>
                  Create a service user, as described
                  <a target="_blank" href="https://apidocs.hibob.com/docs/api-service-users">here</a>.
                </li>
                <li>Leave the service user with the default permissions and roles.</li>
                <li>Ensure that the "lifecycle" permission is enabled.</li>
                <li>Copy the service user ID and Token into the form below.</li>
                <li>Hit "Connect".</li>
              </ol>
            </div>
            <app-input
              v-model="configs.hibob.serviceUserId"
              :validate-immediate="false"
              type="text"
              name="serviceUserId"
              rules="required"
              label="Service user ID"
              description="The generated service user"
              placeholder="E.g. SERVICE-1234"
            />
            <app-input
              v-model="configs.hibob.serviceUserToken"
              :validate-immediate="false"
              type="text"
              name="serviceUserToken"
              rules="required"
              label="Service user token"
              description="The access token for the service user."
              placeholder="E.g. TGh4iGnz5UaHKOyzOyOlyk"
            />
            <app-input
              v-model="configs.hibob.maxSalary"
              :validate-immediate="false"
              type="text"
              name="maxSalary"
              rules="required"
              label="Maximum salary"
              description="Limit yearly salary available for streaming. Leave blank for no limit."
              placeholder="E.g. 30000"
            />
          </div>
          <div v-if="provider == 'fourth.v1'">
            <div class="mb-3">
              <ol class="pl-3">
                <li>
                  Contact your Fourth account manager and request the following: Org ID, API Username, and API Password
                </li>
                <li>Insert the returned information from Fourth into the field boxes below.</li>
                <li>Hit “Connect”.</li>
              </ol>
            </div>
            <app-input
              v-model="configs['fourth.v1'].orgId"
              :validate-immediate="false"
              type="text"
              name="orgid"
              rules="required"
              label="Organisation ID"
              description="Your organisation ID"
            />
            <app-input
              v-model="configs['fourth.v1'].apiUser"
              :validate-immediate="false"
              type="text"
              name="apiUser"
              rules="required"
              label="API User"
              description="The username created for your integration. Not your username."
            />
            <app-input
              v-model="configs['fourth.v1'].apiPass"
              :validate-immediate="false"
              type="text"
              name="apiPass"
              rules="required"
              label="API Password"
              description="The password created for your integration. Not your password."
            />
          </div>
          <div v-if="provider == 'adp_ihcm'">
            <div class="mb-3">
              <ol class="pl-3">
                <li>Contact your ADP account manager and request the following: Client ID, Client Secret</li>
                <li>Insert the returned information from ADP into the field boxes below.</li>
                <li>Hit “Connect”.</li>
              </ol>
            </div>
            <app-input
              v-model="configs.adp_ihcm.clientId"
              :validate-immediate="false"
              type="text"
              name="clientId"
              rules="required"
              label="Client ID"
              description="The Client ID associated with your ADP account. Not your username."
            />
            <app-input
              v-model="configs.adp_ihcm.clientSecret"
              :validate-immediate="false"
              type="text"
              name="clientSecret"
              rules="required"
              label="Client Secret"
              description="The Client Secret associated with your ADP account. Not your password."
            />
          </div>

          <page-sub-footer>
            <b-button type="submit" variant="primary" :disabled="v.invalid || loading" @click="onSubmit">
              <b-spinner v-if="saving" class="mr-2" small />
              {{ loading ? 'Connecting...' : 'Connect' }}
            </b-button>
            <b-button v-if="secondaryCta" variant="outline-primary" @click="goBack">
              {{ secondaryCta }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
  </sme-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppInput from '@/components/AppInput.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import useAnalytics from '@/state/composables/useAnalytics';

export default {
  name: 'IntegrationsSubmitApiCredentials',
  components: { ValidationObserver, AppInput, SmeAlert, PageSubFooter, SmeCard },
  props: ['provider', 'icon', 'companyId', 'secondaryCta'],
  data() {
    return {
      configs: {
        s4labour: {
          organisationId: null,
          apiUser: null,
          apiPassword: null,
        },
        planday: {
          clientId: null,
          refreshToken: null,
        },
        rotaready: {
          realm: null,
          apiKey: null,
          apiSecret: null,
        },
        hibob: {
          serviceUserToken: null,
          serviceUserId: null,
          maxSalary: null,
        },
        'fourth.v1': {
          orgId: null,
          apiUser: null,
          apiPass: null,
        },
        adp_ihcm: {
          clientId: null,
          clientSecret: null,
        },
      },
      loading: false,
      saving: false,
      error: false,
    };
  },
  setup() {
    const { postAnalyticsEvent } = useAnalytics();
    return {
      postAnalyticsEvent,
    };
  },
  methods: {
    async goBack() {
      this.$emit('secondary-cta');
    },
    async onSubmit() {
      this.loading = true;
      await tracker.trackEvent('sme_activation_enable_integration');

      const payload = this.configs[this.provider];
      try {
        await ApiClient.configureIntegration(this.provider, this.companyId, payload);
      } catch {
        this.error = true;
        this.loading = false;
        return;
      }
      await ApiClient.getUser();
      this.postAnalyticsEvent({
        intent: `complete_add_integration_password`,
        task: 'add_integration',
        action: 'click',
      });
      this.$emit('primary-cta');
    },
  },
};
</script>

<style scoped>
.integrations-submit-api-credentials__logo {
  display: inline-block;
  height: 3rem;
  max-width: 10rem;
  object-fit: contain;
}
</style>
