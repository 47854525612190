<template>
  <app-page title="Shift Analytics" icon="calendar-alt" class="analytics-shifts">
    <template v-if="!loading" #custom-content>
      <b-col>
        <analytics-nav />
        <section class="analytics-shifts__content" :class="[preview && 'analytics-shifts__content--preview']">
          <snapshot-metrics :preview="preview" />
          <employee-shift-metrics :preview="preview" />
          <division-shift-metrics v-if="State.state.claims.a" :preview="preview" />
          <sme-card v-if="preview" class="analytics-shifts__overlay">
            <sme-alert level="info" class="mb-3">
              <p>
                <strong>No shift data found.</strong>
              </p>
              <p>
                To unlock shift insights, you need to provide us with your shift data. Set up an integration with your
                rota software now to automatically import your shifts.
              </p>
            </sme-alert>
            <b-button :to="{ name: 'sme-integrations' }" variant="primary" class="align-self-baseline">
              Setup integration
            </b-button>
          </sme-card>
        </section>
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import AnalyticsNav from '@/pages/analyticsHomepage/components/AnalyticsNav.vue';
import DivisionShiftMetrics from '@/pages/analyticsShifts/components/DivisionShiftMetrics.vue';
import EmployeeShiftMetrics from '@/pages/analyticsShifts/components/EmployeeShiftMetrics.vue';
import SnapshotMetrics from '@/pages/analyticsShifts/components/SnapshotMetrics.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';

const loading = ref(true);
const preview = ref(false);

onBeforeMount(async () => {
  await ApiClient.getIntegrations(State.state.company.company_id);

  if (!IntegrationsState.state.integrations?.some(integration => integration.partner.includes('FOURTH'))) {
    preview.value = true;
  }

  loading.value = false;
});
</script>

<style lang="scss">
.analytics-shifts__content--preview {
  > *:not(.analytics-shifts__overlay) {
    filter: blur(0.4rem) opacity(0.4) grayscale(1);
    pointer-events: none;
  }
}

.analytics-shifts__overlay {
  left: 0;
  margin: 0 auto !important;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 6.5rem;
  width: 40rem !important;
}
</style>
