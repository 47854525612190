<template>
  <header role="banner">
    <div class="left">
      <router-link :to="{ name: 'talent-pool', locationId }">
        <img class="raise-logo" :src="themeImage('raise-logo-without-icon.svg')" alt="Raise" />
      </router-link>
    </div>

    <div class="tabs">
      <router-link
        :class="['tab', { active: $route.name === 'talent-pool-map' }]"
        :to="{ name: 'talent-pool-map', params: { locationId: currentLocationId } }"
      >
        <MapPin />
        Talent
      </router-link>
      <router-link
        v-if="isEnableRaiseJobs"
        :class="['tab', { active: isActive('talent-pool-jobs') || isActive('talent-pool-jobs-get-started') }]"
        :to="
          !isActive('talent-pool-jobs') ? { name: 'talent-pool-jobs', params: { locationId: currentLocationId } } : ''
        "
      >
        <Newspaper />
        Jobs
      </router-link>
      <router-link
        v-if="isShiftsTabEnabled"
        :class="[
          'tab',
          { active: $route.name === 'talent-pool', 'semi-active': $route.name === 'talent-pool-shift-detail' },
        ]"
        :to="{ name: 'talent-pool', params: { locationId: currentLocationId } }"
      >
        <Rows />
        Shifts
      </router-link>
      <div v-else class="tab-disabled">
        <Rows />
        Shifts
        <div class="coming-soon">Coming soon</div>
      </div>
      <router-link
        :class="['tab', { active: $route.name === 'talent-pool-schedule' }]"
        :to="{ name: 'talent-pool-schedule', params: { locationId: currentLocationId } }"
      >
        <Calendar />
        Schedule
      </router-link>
    </div>

    <div class="right">
      <router-link
        :class="[
          'tab',
          { active: isSettingsActive() },
          { 'semi-active': $route.name === 'talent-pool-settings' },
          { 'semi-active': $route.name === 'talent-pool-team' },
        ]"
        :to="{ name: 'talent-pool-settings', params: { locationId: companyId || currentLocationId } }"
      >
        <Settings />
        Settings
      </router-link>
      <UserMenu :canManageTeam="claims.fwa || claims.fwm" />
    </div>

    <!-- Mobile header -->
    <div class="mobile-header">
      <LocationSelector />
      <div class="buttons">
        <b-button
          :to="{ name: 'talent-pool-settings', params: { locationId: currentLocationId } }"
          class="aligned-btn"
          variant="outline-primary"
        >
          Manage your site
        </b-button>
        <b-button v-b-modal="'add-shift-modal'" variant="primary">
          <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Add shift
        </b-button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { themeImage } from '@/Theme';
import LocationSelector from '@/pages/talent-pool/components/LocationSelector.vue';
import { useRoute } from 'vue-router/composables';
import State from '@/state/State';
import { computed, onMounted, ref, watch } from 'vue';
import UserMenu from '@/pages/talent-pool/components/UserMenu.vue';
import Calendar from '@/assets/icons/Calendar.vue';
import Rows from '@/assets/icons/Rows.vue';
import Newspaper from '@/assets/icons/Newspaper.vue';
import MapPin from '@/assets/icons/MapPin.vue';
import Settings from '@/assets/icons/Settings.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import ApiClient from '@/ApiClient';
import { getEntityType } from '@/pages/talent-pool/utils/getEntityType';

const route = useRoute();
const { locationId } = route.params;
const { isEnableRaiseJobs } = useFeatureFlags();

const entityType = ref('');
const companyId = ref('');
const defaultSiteLocationId = ref('');

const claims = State.state.claims;

const { locations, getLocations } = useTalentPoolLocations();

const isActive = routeName =>
  route.matched.some(record => {
    return record.name === routeName;
  });

const isSettingsActive = () => route.name === 'talent-pool-settings' && locationId === companyId.value;

const isShiftsTabEnabled = !!State.state.company.properties.portal.talent_pool_shifts_enabled;

const fetchEntity = async () => {
  const { data } = await ApiClient.getTalentPoolEntityDetails(locationId);
  entityType.value = getEntityType(data.source);
};

watch(locations, () => {
  if (locations.value?.length) {
    defaultSiteLocationId.value = locations.value[0].location_id;
    companyId.value = locations.value[0].company_id;
  }
});

const currentLocationId = computed(() => {
  return entityType.value === 'company' || entityType.value === 'brand' ? defaultSiteLocationId.value : locationId;
});

onMounted(async () => {
  await fetchEntity();
  getLocations();
});
</script>

<style scoped lang="scss">
header {
  box-shadow: none;
  transition: top 0.25s;
  width: 100%;
  display: flex;
  background-color: var(--palette-color-base-white);
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  align-items: center;
  height: 4.5rem;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  flex-grow: 1;
}

/* Tab default */
a.tab {
  display: flex;
  align-items: center;
  color: var(--palette-color-default);
  background: var(--palette-color-base-white);
  transition: all 0.1s ease-out;
  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-color-default-lighten-90);
  border-radius: 999px;
  padding: 0.625rem 1.5rem;
  gap: 0.375rem;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--palette-color-default-lighten-30);
  }

  &:not(.active):hover {
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  }

  &:not(.active, .semi-active):hover {
    border-color: var(--palette-color-base-white);
  }
}

.tab-disabled {
  display: flex;
  align-items: center;
  color: var(--palette-color-default);
  background: var(--palette-color-base-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-color-default-lighten-90);
  border-radius: 999px;
  padding: 0.625rem 1.5rem;
  gap: 0.375rem;
  opacity: 0.8;
}

/* Tab semi-active */
a.tab.semi-active {
  color: var(--palette-color-brand-primary);
  border-color: var(--palette-color-brand-primary);

  svg {
    fill: currentColor;
  }
}

/* Tab active */
a.tab.active {
  color: var(--palette-color-base-white);
  background-color: var(--palette-color-brand-primary);
  border-color: var(--palette-color-brand-primary);

  svg {
    fill: currentColor;
  }

  &:hover {
    cursor: default;
  }
}

.left {
  flex: 1;
  display: flex;
  padding: 2rem 1.5rem;
}

.right {
  flex: 1;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.mobile-header {
  display: none;
  width: 100%;
}

.mobile-header .buttons {
  display: flex;
  gap: 0.75rem;
}

.raise-logo {
  width: 100px;
  margin-right: 1rem;
  margin-bottom: 0.375rem;
}

@media (max-width: 900px) {
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
  }

  .left {
    display: none;
  }

  .tabs {
    display: none;
  }

  .right {
    display: none;
  }
}

.coming-soon {
  border: 1px solid var(--palette-color-default-lighten-90);
  background: var(--palette-color-default-lighten-90);
  color: var(--palette-color-default-lighten-30);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 0.15rem 0.25rem;
  border-radius: 6px;
}
</style>
