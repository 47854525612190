<template>
  <div class="register-page">
    <div class="register-page__banner">
      <div class="register-page__banner__content">
        <img class="register-page__logo" :src="themeImage('logo-white-small.svg')" />
        <div class="register-page__banner-text">
          <h2 class="register-page__banner-title">{{ titleText || 'Financial wellbeing for every worker' }}</h2>
          <transition name="slide-fade" mode="out-in">
            <div class="register-page__banner-description" :key="changingText">
              {{ changingText }}
            </div>
          </transition>
        </div>
      </div>
      <div class="register-banner-content d-flex justify-content-center w-100">
        <slot name="banner-content"></slot>
      </div>

      <h3 class="link-bottom">
        <slot name="banner-cta-bottom"></slot>
      </h3>
    </div>
    <div class="register-page__component">
      <div class="my-auto">
        <div class="register-page__component-title">
          <slot name="title"></slot>
        </div>
        <SmeAlert v-if="props.error" level="danger" class="register-page__error"> {{ props.error }}</SmeAlert>
        <div>
          <AppLoading v-if="props.loading" />
          <slot v-else name="component" />
          <b-button
            v-if="!props.noContinue"
            class="register-page__continue"
            variant="primary"
            :disabled="!props.canSubmit || props.loading"
            @click="$event => emit('submit', $event)"
            >Continue</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { themeImage } from '@/Theme';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { onBeforeMount, ref } from 'vue';

const TEXT = [
  `Plans start from £72 a month. Take advantage of a 3 month free trial for a limited time. No commitment required.`,
  `Fairer financial services for your employees. Give your employees access to Flexible Pay, Discounts, Savings and Financial Coaching in a single app.`,
  `52% of surveyed members say they are more likely to stay with their current employer for longer because they have access to Wagestream.`,
  `Seemlessly integrate with your payroll, HR and workforce management systems. Our platform is ISO 27001 certified and connects to over 30 systems.`,
  `Built around pay, proven by data, backed by charities. We partner with leading researchers to improve the financial wellbeing of the working population.`,
  `Support your employees. 90% of surveyed members say that their financial situation has improved since their employer started using Wagestream.`,
  `Financial services with inclusion at the core. Most financial services are designed for the top 1%. The Wagestream platform works for everyone.`,
  `Supercharge your recruitment. Companies that use Wagestream hire 27% faster on average, according to data taken from indeed.com.`,
];

const emit = defineEmits(['submit']);

const props = defineProps(['canSubmit', 'error', 'loading', 'noContinue', 'titleText', 'videoSource']);

const changingText = ref(TEXT[0]);

onBeforeMount(() => {
  let i = 1;
  setInterval(() => {
    changingText.value = TEXT[i];
    i = (i + 1) % TEXT.length;
  }, 5000);
});
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'SemplicitaProBold';
  src: url('@/assets/fonts/semplicitapro-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'SemplicitaPro';
  src: url('@/assets/fonts/semplicitapro-webfont.woff') format('woff');
}

.register-page {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100vw;
}

.register-page__banner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: radial-gradient(
      102.19% 102.19% at 89.17% -2.19%,
      var(--palette-color-core-4) 0,
      var(--palette-color-core-3-darken-20) 38.97%,
      var(--palette-color-core-1-darken-10) 53.97%
    ),
    var(--palette-color-core-1-darken-20);

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  & .register-banner-content > * {
    width: 95%;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 3rem;
    z-index: 2;

    @media (max-width: 1268px) {
      margin-top: 2rem;
      width: 100%;
    }
  }

  &:before {
    position: absolute;
    max-height: 100vh;
    height: 50%;
    width: 50vw;
    content: '';
    top: 0;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url('@/assets/background-gradient-white.png');
    background-size: cover;
    opacity: 0.5;
  }
}

.register-page__logo {
  flex-shrink: 0;
  position: relative;
  left: 0;
  top: 0;
  margin: 2rem;
  width: 6rem;
}

.register-page__banner-text {
  color: var(--palette-color-base-white);
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.35));
  font-family: SemplicitaPro, sans-serif;
  font-size: large;
  line-height: 1.6;
  margin: 0 2rem;
  width: 100%;
}

.register-page__banner-title {
  font-size: xxx-large;
  line-height: 1.15;
  font-weight: 600;
  margin: 1rem 0;
}

.register-page__component-title {
  display: flex;
  flex-direction: column;
  align-items: left;

  & > * {
    margin-bottom: 1rem;
  }
}

.register-page__error {
  margin-bottom: 1rem;
  white-space: pre-wrap;
}

.register-page__component {
  overflow: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: var(--palette-color-base-white);
  height: 100vh;
  width: 50vw;
  flex-grow: 1;
  z-index: 1;

  @media (max-width: 1268px) {
    height: auto;
    overflow: initial;
  }
}

.register-page__continue {
  margin-top: 1.5rem;
  width: 100%;
  font-size: large;
}

@media (max-width: 1268px) {
  .register-page {
    flex-direction: column;
    justify-items: center;
    align-items: start;
  }

  .register-page__banner {
    width: 100vw;
    height: 30rem;
    overflow: hidden;
    padding: 1rem;
    &:before {
      width: 100vw;
    }

    @media (max-width: 1268px) {
      height: auto;
    }
  }

  .register-page__banner-text {
    margin: 0;
  }
  .register-page__banner-title {
    margin: 2rem 0;
  }

  .register-page__logo {
    display: none;
  }

  .register-page__component {
    width: 100vw;
    padding: 4rem 1rem;
    justify-content: start;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.link-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 1rem;

  & * {
    border: none;
  }

  @media (max-width: 1268px) {
    display: none;
  }
}

.register-page__banner-description {
  height: 80px;
}
</style>
