<template>
  <register-company @continue="$event => callback($event)"></register-company>
</template>

<script>
import RegisterCompany from '@/pages/register/company';

export default {
  name: 'RegisterXeroFallback',
  components: { RegisterCompany },
  props: ['code', 'state'],
  methods: {
    callback(companyNumber) {
      const newState = JSON.stringify({ ...this.state, company_number: companyNumber });
      window.location.replace(`${window.location.origin}/xero-registration?code=${this.code}&state=${newState}`);
    },
  },
};
</script>
