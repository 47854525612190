<template>
  <app-page
    title="Bulk import employees"
    subtitle="Add or update employees in bulk by uploading a file."
    icon="user-plus"
    :previous="[{ label: 'Employees', to: { name: 'employees' } }]"
  >
    <app-loading v-if="loading" />
    <bulk-upload v-else name="employee" :schema="FIELD_SCHEMA" :post-function="upload" />
  </app-page>
</template>

<script setup>
import moment from 'moment';
import { email, regex } from 'vee-validate/dist/rules';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import BulkUpload from '@/components/BulkUpload.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import { NAME_REGEX } from '@/utils/Employee';
import { unFormatCurrency } from '@/utils/common';
import useAnalytics from '@/state/composables/useAnalytics';
import { formatDateIfValid } from '@/utils/date';

const { postAnalyticsEvent } = useAnalytics();
const $router = useRouter();

const FIELD_SCHEMA = [
  {
    key: 'employee_code',
    label: 'employee_code',
    description: 'The employee code',
    example: 'WS-00001',
    type: 'Text',
    required: true,
  },
  {
    key: 'full_name',
    label: 'full_name',
    description: 'The full name of the employee, as used in your payroll system',
    example: 'John Doe',
    type: 'Text',
    required: true,
    validator: value => regex.validate(value, { regex: NAME_REGEX }),
  },
  {
    key: 'email',
    label: 'email',
    description: 'The email address of the employee',
    example: 'john.doe@wagestream.com',
    type: 'Email',
    required: true,
    validator: value => email.validate(value),
  },
  {
    key: 'salary',
    label: 'salary',
    description: `The employee's <strong>yearly</strong> salary in GBP<br /> <small>(Only applicable for salaried employees. Leave blank for shift/hourly workers)</small>`,
    example: '15000',
    type: 'Currency',
    formatter: value => {
      const unformattedValue = unFormatCurrency(value);
      return value ? (!isNaN(unformattedValue) ? Math.round(unformattedValue) : value) : value;
    },
    validator: value => Number.isInteger(value),
  },
  {
    key: 'sort_code',
    label: 'sort_code',
    description: "The employee's 6 digit sort code without any spaces",
    example: '123456',
    type: 'Number',
    formatter: value => (value ? String(value).padStart(6, 0) : value),
    validator: value => regex.validate(value, { regex: getBankingInfo().sortCode.regex }),
  },
  {
    key: 'account_number',
    label: 'account_number',
    description: "The employee's 8 digit account number without any spaces",
    example: '12345678',
    type: 'Number',
    formatter: value => (value ? String(value).padStart(8, 0) : value),
    validator: value => regex.validate(value, { regex: getBankingInfo().accountNumber.regex }),
  },
  {
    key: 'terminated_on',
    label: 'terminated_on',
    description:
      'The date that the employee is leaving the company in the format <strong>YYYY-MM-DD</strong>. You can change your date format below.',
    example: '2021-01-01',
    type: 'Date',
    formatter: value => (value ? formatDateIfValid(value) : value),
    validator: value => moment(value, 'YYYY-MM-DD', true).isValid(),
  },
  {
    key: 'started_on',
    label: 'started_on',
    description:
      'The date that the employee is starting with the company in the format <strong>YYYY-MM-DD</strong>. You can change your date format below.',
    example: '2021-01-01',
    type: 'Date',
    formatter: value => (value ? formatDateIfValid(value) : value),
    validator: value => moment(value, 'YYYY-MM-DD', true).isValid(),
  },
];

const loading = ref(false);

const upload = async (companyId, fileName, fileContent, metadata) => {
  await tracker.trackEvent('sme_live_add_employees');
  const added = ApiClient.smeEmployeesAddBulk(companyId, fileName, fileContent, metadata);
  postAnalyticsEvent({
    intent: 'complete_add_bulk_employees',
    task: 'add_employees',
    action: 'click',
  });
  return added;
};

onBeforeMount(async () => {
  loading.value = true;
  postAnalyticsEvent({
    intent: 'view_add_bulk_employees',
    task: 'add_employees',
    action: 'view',
  });
  await ApiClient.getIntegrations(State.state.company.company_id);
  const entities = IntegrationsState.getBrowserUploadEntities();
  if (entities?.includes('employees')) {
    $router.replace({ name: 'sftp-bulk-upload', query: { entity: 'employees' } });
  }
  loading.value = false;
});
</script>
