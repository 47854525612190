var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{attrs:{"role":"banner"}},[_c('div',{staticClass:"left"},[_c('router-link',{attrs:{"to":{ name: 'talent-pool', locationId: _setup.locationId }}},[_c('img',{staticClass:"raise-logo",attrs:{"src":_setup.themeImage('raise-logo-without-icon.svg'),"alt":"Raise"}})])],1),_c('div',{staticClass:"tabs"},[_c('router-link',{class:['tab', { active: _vm.$route.name === 'talent-pool-map' }],attrs:{"to":{ name: 'talent-pool-map', params: { locationId: _setup.currentLocationId } }}},[_c(_setup.MapPin),_vm._v(" Talent ")],1),(_setup.isEnableRaiseJobs)?_c('router-link',{class:['tab', { active: _setup.isActive('talent-pool-jobs') || _setup.isActive('talent-pool-jobs-get-started') }],attrs:{"to":!_setup.isActive('talent-pool-jobs') ? { name: 'talent-pool-jobs', params: { locationId: _setup.currentLocationId } } : ''}},[_c(_setup.Newspaper),_vm._v(" Jobs ")],1):_vm._e(),(_setup.isShiftsTabEnabled)?_c('router-link',{class:[
        'tab',
        { active: _vm.$route.name === 'talent-pool', 'semi-active': _vm.$route.name === 'talent-pool-shift-detail' },
      ],attrs:{"to":{ name: 'talent-pool', params: { locationId: _setup.currentLocationId } }}},[_c(_setup.Rows),_vm._v(" Shifts ")],1):_c('div',{staticClass:"tab-disabled"},[_c(_setup.Rows),_vm._v(" Shifts "),_c('div',{staticClass:"coming-soon"},[_vm._v("Coming soon")])],1),_c('router-link',{class:['tab', { active: _vm.$route.name === 'talent-pool-schedule' }],attrs:{"to":{ name: 'talent-pool-schedule', params: { locationId: _setup.currentLocationId } }}},[_c(_setup.Calendar),_vm._v(" Schedule ")],1)],1),_c('div',{staticClass:"right"},[_c('router-link',{class:[
        'tab',
        { active: _setup.isSettingsActive() },
        { 'semi-active': _vm.$route.name === 'talent-pool-settings' },
        { 'semi-active': _vm.$route.name === 'talent-pool-team' },
      ],attrs:{"to":{ name: 'talent-pool-settings', params: { locationId: _setup.companyId || _setup.currentLocationId } }}},[_c(_setup.Settings),_vm._v(" Settings ")],1),_c(_setup.UserMenu,{attrs:{"canManageTeam":_setup.claims.fwa || _setup.claims.fwm}})],1),_c('div',{staticClass:"mobile-header"},[_c(_setup.LocationSelector),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"aligned-btn",attrs:{"to":{ name: 'talent-pool-settings', params: { locationId: _setup.currentLocationId } },"variant":"outline-primary"}},[_vm._v(" Manage your site ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('add-shift-modal'),expression:"'add-shift-modal'"}],attrs:{"variant":"primary"}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'plus']}}),_vm._v("Add shift ")],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }