var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AppPage,{attrs:{"title":"Add Employees","previous":[
    { label: 'Divisions', to: { name: 'divisions' } },
    {
      label: _setup.division ? _setup.division.name : 'Division',
      to: { name: 'division', params: { divisionId: _setup.division ? _setup.division.division_id : '' } },
    },
  ]}},[(_setup.loading)?_c(_setup.AppLoading):_c(_setup.EmployeesListSimple,{key:_setup.listKey,attrs:{"employees":_setup.processedEmployees,"current-page-initial":_setup.listCurrentPage,"fields":_setup.TABLE_FIELDS,"primaryKey":"division_id"},on:{"input:currentPage":function($event){_setup.listCurrentPage = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }