<template>
  <div v-if="!loading" class="jobs-wrapper">
    <div class="heading">
      <h2>Looking for {{ toTitleCase(props.job.job_title) }}s?</h2>
      <span
        >We have
        <strong>{{ props.job.worker_count }} highly rated {{ toTitleCase(props.job.job_title) }}s</strong>
        with matching experience open for work.</span
      >
      <SquareButton transparent @click="onDismiss"><Cross /></SquareButton>
    </div>

    <div class="content">
      <div class="workers-wrapper">
        <PromoEmployeeCard v-for="worker in props.job?.workers" :key="worker.worker_id" :claim="worker" />
      </div>
      <div class="job-tile-wrapper">
        <div class="job-tile">
          <img class="company-logo" :src="logoUrl" v-if="logoUrl" alt="Company Logo" />
          <div class="texts">
            <p>{{ toTitleCase(props.job.job_title) }}</p>
            <span>{{ props.job?.source_payload?.salarySnippet?.text?.replace(/[^a-z0-9 £\.,_-]/gim, '') }}</span>
          </div>
          <b-button variant="primary" v-b-modal="'help-modal'" @click="onConvert('post_on_raise')"
            >Post this job on Raise</b-button
          >
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="left"><img class="raise-logo" :src="themeImage('raise-text-logo.svg')" alt="Raise" /></div>
      <span>Get workers for Trial Shifts and Interviews</span>
      <div class="right">
        <b-button variant="outline-primary" v-b-modal="'help-modal'" @click="onConvert('get_started')">
          Get started <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-1"
        /></b-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { themeImage } from '@/Theme';
import SquareButton from '@/components/SquareButton.vue';
import Cross from '@/assets/icons/Cross.vue';
import { onMounted, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getEntity } from '@/queries/talent-pool/locations';
import State from '@/state/State';
import PromoEmployeeCard from '@/pages/dashboard/components/raisePromo/PromoEmployeeCard.vue';
import { toTitleCase } from '@/utils/common';
import ApiClient from '@/ApiClient';

const props = defineProps(['job']);

const emit = defineEmits(['dismiss']);
const companyId = State.state.company.company_id;
const loading = ref(false);
const entityDetails = useQuery(getEntity(companyId));
const logoUrl = ref('');
const entity = ref(null);

watch(entityDetails.fetchStatus, () => {
  loading.value = entityDetails.isFetching;
  if (entityDetails.data.value) {
    entity.value = entityDetails.data.value;
    logoUrl.value = entityDetails.data.value.logo_url;
  }
});

const onDismiss = async () => {
  await ApiClient.postAnalytics('click_jobs_promo_dismiss', {});
  emit('dismiss');
};

const onConvert = async btn => {
  await ApiClient.postAnalytics('click_jobs_promo_convert', { btn: btn });
};

onMounted(async () => {
  await ApiClient.postAnalytics('raise_jobs_promo_mounted', {});
});
</script>

<style lang="scss" scoped>
.jobs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  background: var(--palette-color-default-lighten-95);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  border: 1px solid var(--palette-color-default-lighten-90);
  box-shadow: var(--depth-2);
}

.heading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  gap: 0.25rem;

  h2 {
    font-weight: 500;
    color: var(--palette-color-default);
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    line-height: 20px;

    strong {
      font-weight: 600;
      color: var(--palette-color-default);
    }
  }
  button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.content {
  position: relative;
  width: 100%;
}

.content .workers-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.75rem;
  mask-mode: alpha;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.content .job-tile-wrapper {
  position: absolute;
  inset: 0;
  margin: auto auto;
  width: fit-content;
  height: fit-content;
  z-index: 3;

  &::after {
    content: '';
    position: absolute;
    inset: -8px;
    margin: auto auto;
    z-index: -1;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background: linear-gradient(60deg, var(--palette-color-brand-primary) 0%, var(--palette-color-core-5) 100%);
    border-radius: 0.75rem;
    box-shadow: 0px 0px 3px 0px rgba(34, 40, 47, 0.1), 0px 4px 20px 0px rgba(34, 40, 47, 0.15);
  }
}

.job-tile {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: var(--palette-color-base-white);
  gap: 0.75rem;
  z-index: 3;

  img.company-logo {
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--palette-color-default-lighten-90);
    border-radius: 50%;
  }

  .texts {
    color: var(--palette-color-default);

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin: 0;
    }

    span {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
    }
  }
}

.footer {
  display: flex;
  width: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  padding: 0.75rem 1rem;
  align-items: center;

  .left,
  .right {
    display: flex;
    flex: 1;
  }

  .right {
    justify-content: flex-end;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--palette-color-default);
  }
}
</style>
