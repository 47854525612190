<template>
  <app-page
    subtitle="We will not contact employees until you are ready to invite them to download the app"
    title="Upload employees"
    icon="user-plus"
    :previous="[{ label: 'Employees', to: { name: 'employees' } }]"
  >
    <template v-slot:custom-content>
      <section class="setup-employees text-center">
        <b-container fluid>
          <b-row>
            <b-col>
              <sme-card class="h-100" title="Setup an integration" large>
                <font-awesome-icon :icon="['fad', 'chart-network']" class="setup-employees__icon" />
                <p class="mx-4 mb-4">
                  Set up an integration with your rota software now to automatically import your employees.
                </p>
                <b-button @click="navigateToIntegrations" variant="primary">Setup Integration</b-button>
              </sme-card>
            </b-col>
            <b-col md="auto">
              <span class="setup-employees__separator">
                <span class="setup-employees__separator-text">or</span>
              </span>
            </b-col>
            <b-col>
              <sme-card class="h-100" title="Bulk import employees" large>
                <font-awesome-icon :icon="['fal', 'cloud-upload']" class="setup-employees__icon" />
                <p class="mx-4 mb-4">
                  Don't have an integration, or your integration isn't supported? You can manually upload a file of
                  employees.
                </p>
                <b-button @click="navigateToEmployeesUpload" variant="outline-primary">Bulk Import</b-button>
              </sme-card>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from '@/components/AppPage.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import { useRouter } from 'vue-router/composables';
import useAnalytics from '@/state/composables/useAnalytics';
import { onBeforeMount } from 'vue';

const { postAnalyticsEvent } = useAnalytics();
const $router = useRouter();

const navigateToIntegrations = () => {
  postAnalyticsEvent({
    intent: `navigate_integrations`,
    task: 'add_integration',
    action: 'click',
  });
  $router.push({ name: 'sme-integrations' });
};

const navigateToEmployeesUpload = () => {
  postAnalyticsEvent({
    intent: `navigate_upload_employees`,
    task: 'add_employees',
    action: 'click',
  });
  $router.push({ name: 'employees-upload' });
};

onBeforeMount(() => {
  postAnalyticsEvent({
    intent: `view_import_employees`,
    task: 'add_employees',
    action: 'view',
  });
});
</script>

<style lang="scss" scoped>
.setup-employees__icon {
  color: var(--palette-color-core-5);
  height: 4rem;
  margin: 0 auto 1.5rem;
  width: 4rem;

  @media (min-width: 768px) {
    height: 5rem;
    width: 5rem;
  }
}

.setup-employees__separator {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &::before {
    border-left: 1px dashed var(--palette-color-default-lighten-80);
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;

    @media (max-width: 768px) {
      border-left: 0;
      border-top: 1px dashed var(--palette-color-default-lighten-80);
      height: auto;
      left: 0;
      top: 50%;
      width: 100%;
    }
  }
}

.setup-employees__separator-text {
  background-color: var(--palette-color-default-lighten-95);
  padding: 0.5rem 0;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}
</style>
