<template>
  <BubbleChartGenerator :chart-data="data" :chart-options="chartOptions" />
</template>

<script setup>
import { Chart as ChartJS, LinearScale, PointElement, Tooltip } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import merge from 'lodash/merge';
import { computed } from 'vue';
import { Bubble as BubbleChartGenerator } from 'vue-chartjs';
import { getDefaultGridStyles } from '@/utils/chart';

ChartJS.register(LinearScale, PointElement, Tooltip, annotationPlugin);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  chartOptions: Object,
});

const chartOptions = computed(() => {
  const defaultGridStyles = getDefaultGridStyles();

  return merge(
    {
      scales: {
        x: merge({}, defaultGridStyles),
        y: merge({}, defaultGridStyles),
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    props.chartOptions,
  );
});
</script>
