<template>
  <app-page
    title="Update Opt Out Employee Bank Details"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !success">
      <sme-alert level="warning" class="mb-3">
        <strong>
          Please update your payroll details with the employee's current {{ getBankingInfo.sortCode.label }} and
          {{ getBankingInfo.accountNumber.label }}.
        </strong>
        <br />
        These will replace the details of their Wagestream account that are currently set up in your system.
      </sme-alert>

      <app-input
        v-model="employee.full_name"
        type="text"
        name="employee name"
        label="Employee Name"
        readonly
      ></app-input>

      <app-input
        v-model="employee.employee_code"
        type="text"
        name="employee code"
        label="Employee Code"
        readonly
      ></app-input>

      <app-input
        v-model="employee.properties.beneficiary_sort_code"
        type="text"
        name="sort code"
        readonly
        :label="getBankingInfo.sortCode.label"
      ></app-input>

      <app-input
        v-model="employee.properties.beneficiary_account_number"
        type="text"
        name="account number"
        readonly
        :label="getBankingInfo.accountNumber.label"
      ></app-input>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)" id="updated">
          <ValidationProvider v-slot="f" name="updated" :rules="{ required: { allowFalse: false } }">
            <b-form-checkbox v-model="updated">I have updated this employee in my company payroll file</b-form-checkbox>
            <b-form-invalid-feedback v-for="fError in f.errors" :key="fError">{{ fError }}</b-form-invalid-feedback>
          </ValidationProvider>

          <page-sub-footer>
            <b-button
              data-testid="revertbanking-button"
              type="submit"
              variant="primary"
              :disabled="v.invalid || saving || success"
            >
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Opting out...' : 'Opt out' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <sme-alert v-if="!loading && success" level="good">Employee opt-out has been completed</sme-alert>
  </app-page>
</template>

<script>
import * as moment from 'moment/moment';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'EmployeeBankingSetup',
  components: { ValidationObserver, ValidationProvider, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      error: undefined,
      saving: false,
      success: false,
      registration: undefined,
      employee: undefined,
      state: State.state,
      updated: undefined,
    };
  },
  methods: {
    onSubmit() {
      this.saving = true;
      const newEmployee = {
        ...this.employee,
        properties: {
          ...this.employee.properties,
          optout_banking_updated: moment.utc().format(),
        },
      };
      ApiClient.updateEmployee(newEmployee)
        .then(() => {
          ApiClient.employeeOptOutComplete(this.employee.employee_id).then(
            () => {
              this.success = true;
              this.saving = false;
            },
            error => {
              this.error = error.message;
              this.saving = false;
            },
          );
        })
        .catch(error => {
          this.error = error.message;
          this.saving = false;
        });
      // TODO: refresh global TODOs
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
  },
};
</script>

<style>
#updated .invalid-feedback {
  display: block;
}
</style>
