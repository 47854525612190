import { render, staticRenderFns } from "./RaiseRating.vue?vue&type=template&id=3560a458&scoped=true&"
import script from "./RaiseRating.vue?vue&type=script&setup=true&lang=js&"
export * from "./RaiseRating.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./RaiseRating.vue?vue&type=style&index=0&id=3560a458&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3560a458",
  null
  
)

export default component.exports