var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sme-box",class:{
    'sme-box--bordered': _vm.bordered,
    'sme-box--compact': _vm.compact,
    'sme-box--scroll': _vm.scroll,
    [`sme-box--scroll-${_vm.scrollVariant}`]: _vm.scroll,
    [`sme-box--${_vm.variant}`]: _vm.variant,
  }},[(_vm.title)?_c('header',{staticClass:"sme-box__header",class:_vm.headerClass},[(_vm.to)?_c('router-link',{staticClass:"sme-box__link",attrs:{"to":_vm.to}},[_c('h4',{staticClass:"sme-box__heading"},[_vm._v(_vm._s(_vm.title))]),_c('font-awesome-icon',{staticClass:"sme-box__link-icon",attrs:{"icon":['fad', 'external-link']}})],1):_c('h4',{staticClass:"sme-box__heading"},[_vm._v(_vm._s(_vm.title))])],1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }