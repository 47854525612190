<template>
  <Modal :title="props.title || 'Add shift'" :modalId="props.modalId" size="xl" :multi-column="true" ref="modalRef">
    <template #modal-left>
      <l-map
        @ready="mapReady"
        class="map"
        id="add-shift-map"
        :zoom="12"
        :center="center"
        :options="{
          zoomControl: false,
          dragging: false,
          scrollWheelZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
          touchZoom: false,
          attributionControl: false,
        }"
        ref="map"
      >
        <l-tile-layer :url="url"></l-tile-layer>
      </l-map>
    </template>
    <div class="form-container">
      <div>
        <div class="input-title">Function</div>
        <b-form-select v-model="roleFunction" :options="functionOptions">
          <template #first v-if="!props.existingRoleId">
            <option value="" disabled>Select a function</option>
          </template>
        </b-form-select>
      </div>
      <div>
        <div class="input-title">Role</div>
        <b-form-select v-model="role" :options="roleOptions" :disabled="isJobShift">
          <template #first>
            <option value="">Please select a role</option>
          </template>
        </b-form-select>
        <div class="form-subtext" v-if="selectedRole">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9985 12.4305C11.9985 12.8452 11.6632 13.1805 11.2485 13.1805H6.507C6.09225 13.1805 5.757 12.8452 5.757 12.4305C5.757 12.0157 6.09225 11.6805 6.507 11.6805H7.1115V9.822H6.507C6.09225 9.822 5.757 9.486 5.757 9.072C5.757 8.658 6.09225 8.322 6.507 8.322H7.1115V6.70125C7.10325 6.099 7.32225 5.51025 7.72875 5.05425C8.136 4.5975 8.6955 4.31175 9.30525 4.2495C9.91275 4.19025 10.5202 4.356 11.0107 4.7235C11.5013 5.091 11.8335 5.625 11.946 6.2265C12.0218 6.63375 11.7533 7.02525 11.346 7.10175C10.9342 7.1775 10.5473 6.909 10.4708 6.5025C10.4272 6.2715 10.2998 6.066 10.1115 5.92425C9.92325 5.78325 9.69 5.718 9.456 5.742C9.222 5.76525 9.006 5.8755 8.84925 6.051C8.6925 6.22725 8.60775 6.4545 8.6115 6.69075V8.32125H9.894C10.3088 8.32125 10.644 8.65725 10.644 9.07125C10.644 9.48525 10.3088 9.82125 9.894 9.82125H8.6115V11.6798H11.2492C11.6632 11.6805 11.9985 12.0157 11.9985 12.4305ZM16.5 9C16.5 13.1423 13.1423 16.5 9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9ZM15 9C15 5.69175 12.3082 3 9 3C5.69175 3 3 5.69175 3 9C3 12.3082 5.69175 15 9 15C12.3082 15 15 12.3082 15 9Z"
              fill="currentColor"
            />
          </svg>
          {{ toRawCurrency(selectedRole.client_rate) }}
          per hour
        </div>
      </div>
      <div>
        <div class="input-title">Site</div>
        <b-form-select v-model="location" :options="locationOptions" :disabled="isJobShift" />
      </div>
      <div>
        <div class="input-title">Date</div>
        <b-form-datepicker v-model="date" />
      </div>
      <div>
        <div class="input-title">Time</div>
        <div class="time-container">
          <div>
            <b-form-input type="time" class="input-section" placeholder="HH:MM" v-model="startTime"></b-form-input>
          </div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.8391 3.7857C11.5137 3.46027 10.986 3.46027 10.6606 3.7857C10.3351 4.11114 10.3351 4.63878 10.6606 4.96422L14.863 9.16663H3.12484C2.6646 9.16663 2.2915 9.53972 2.2915 9.99996C2.2915 10.4602 2.6646 10.8333 3.12484 10.8333H14.863L10.6606 15.0357C10.3351 15.3611 10.3351 15.8888 10.6606 16.2142C10.986 16.5397 11.5137 16.5397 11.8391 16.2142L17.4641 10.5892C17.7895 10.2638 17.7895 9.73614 17.4641 9.4107L11.8391 3.7857Z"
              fill="currentColor"
            />
          </svg>
          <div>
            <b-form-input type="time" class="input-section" placeholder="HH:MM" v-model="endTime"></b-form-input>
          </div>
        </div>
        <div v-if="startTimePassed" class="form-subtext danger">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 4.3125C9.41421 4.3125 9.75 4.64829 9.75 5.0625V8.25H12.9375C13.3517 8.25 13.6875 8.58579 13.6875 9C13.6875 9.41421 13.3517 9.75 12.9375 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9V5.0625C8.25 4.64829 8.58579 4.3125 9 4.3125Z"
              fill="currentColor"
            />
          </svg>
          Start time cannot be in the past
        </div>
        <div v-else-if="hours" class="form-subtext">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 4.3125C9.41421 4.3125 9.75 4.64829 9.75 5.0625V8.25H12.9375C13.3517 8.25 13.6875 8.58579 13.6875 9C13.6875 9.41421 13.3517 9.75 12.9375 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9V5.0625C8.25 4.64829 8.58579 4.3125 9 4.3125Z"
              fill="currentColor"
            />
          </svg>
          Duration {{ formatHoursAndMinutes(hours) }} {{ multiDayShift ? ' (multi-day)' : '' }}
        </div>
      </div>
      <div class="full-width">
        <div class="group-title">
          <div class="input-title">Notes</div>
          <div class="helper-text">Optional</div>
        </div>
        <b-form-textarea class="input-section" v-model="notes"></b-form-textarea>
      </div>
    </div>

    <template #modal-footer>
      <div class="footer">
        <div>
          <div v-if="selectedRole && hours" class="total">
            <span class="label">Total value</span>
            <span class="value">£{{ toRawCurrency(selectedRole.client_rate * hours, 'GBP') }}</span>
          </div>
        </div>
        <div class="buttons">
          <b-button variant="outline-primary" @click="$bvModal.hide(props.modalId)">Cancel</b-button>
          <b-button
            :loading="addShiftMutation.isPending"
            @click="props.isEdit ? editShift() : addShift()"
            variant="primary"
            :disabled="addDisabled"
            >{{ props.isEdit ? 'Edit' : 'Add' }} shift</b-button
          >
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router/composables';
import uuidv4 from 'uuid/v4';
import { set, isAfter, addDays, endOfDay, isPast, parseISO, format, isEqual } from 'date-fns';
import { toRawCurrency } from '@/utils';
import Modal from '@/components/Modal.vue';
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { formatHoursAndMinutes } from '@/pages/talent-pool/utils/datetime';
import { postAddShift, updateShift } from '@/queries/talent-pool/shifts';
import { useMutation } from '@tanstack/vue-query';
import router from '@/Router';
import { getFunctionOptions, getRoleOptionsBasedOnFunction } from '@/pages/talent-pool/utils/roles';

const url = process.env.VUE_APP_MAPBOX_MAP_URL + `?access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`;

const props = defineProps({
  defaultDate: Date,
  defaultRole: String,
  isEdit: Boolean,
  title: {
    type: String,
    default: 'Add shift',
  },
  redirectOnSuccess: {
    type: Boolean,
    default: true,
  },
  shift: Object,
  modalId: { type: String, required: true },
});

const modalRef = ref(null);
const map = ref(null);

const route = useRoute();
const locationId = route.params.locationId;
const roles = ref([]);
const role = ref('');
const roleFunction = ref('');
const location = ref('');
const date = ref(props?.shift?.start_at ? parseISO(props?.shift?.start_at) : new Date());
const startTime = ref(props.shift?.start_at ? format(props.shift.start_at, 'HH:mm') : '');
const endTime = ref(props.shift?.end_at ? format(props.shift.end_at, 'HH:mm') : '');
const notes = ref(props.shift?.properties?.notes || '');

const companyId = State.state.company.company_id;

const { getLocations, locations } = useTalentPoolLocations();

watchEffect(() => {
  if (props.defaultDate) {
    date.value = props.defaultDate;
  }
  if (props.defaultRole) {
    role.value = props.defaultRole;
  }
});

const mapReady = () => {
  // Hack to fix map not rendering properly in Modal
  setTimeout(function () {
    window.dispatchEvent(new Event('resize'));
  }, 250);
};

const isJobShift = computed(() => {
  return ['interview', 'trial_shift'].includes(props.shift?.properties?.type);
});

const center = computed(() => {
  const currentLocation = location.value && locations.value.find(l => l.location_id === location.value);
  return currentLocation ? [currentLocation.latitude, currentLocation.longitude] : [51.515419, -0.141099];
});

const selectedRole = computed(() => role.value && roles.value.find(r => r.internal_job_role_id === role.value));

const functionOptions = computed(() => {
  return getFunctionOptions(roles.value);
});

const roleOptions = computed(() => {
  return getRoleOptionsBasedOnFunction(roles.value, roleFunction.value);
});

const startDateTime = computed(() => {
  if (startTime.value === '') {
    return null;
  }

  const [startHour, startMinute] = startTime.value.split(':').map(Number);
  const startDateTime = set(date.value, {
    hours: startHour,
    minutes: startMinute || 0,
    seconds: 0,
    milliseconds: 0,
  });
  return startDateTime;
});

const endDateTime = computed(() => {
  if (startTime.value === '' || endTime.value === '') {
    return null;
  }
  const [endHour, endMinute] = endTime.value.split(':').map(Number);
  let endDateTime = set(date.value, {
    hours: endHour,
    minutes: endMinute || 0,
    seconds: 0,
    milliseconds: 0,
  });

  // If end time is before start time, add 1 day
  if (endDateTime < startDateTime.value) {
    endDateTime = addDays(endDateTime, 1);
  }

  return endDateTime;
});

const hours = computed(() => {
  const start = startDateTime.value;
  const end = endDateTime.value;
  if (start === null || end === null) {
    return null;
  }
  return (end - start) / (1000 * 60 * 60);
});

const multiDayShift = computed(() => {
  return startDateTime.value && endDateTime.value && isAfter(endDateTime.value, endOfDay(startDateTime.value));
});

const startTimePassed = computed(() => {
  return startDateTime.value && isPast(startDateTime.value);
});

const addDisabled = computed(() => {
  return !role.value || !location.value || !startTime.value || !endTime.value || startTimePassed.value;
});

const onSuccess = data => {
  modalRef.value.modal.hide();

  if (props.redirectOnSuccess) {
    router
      .push({
        name: 'talent-pool-shift',
        params: { locationId: data.location_id, shiftId: data.shift_id },
      })
      .catch(() => {});
  }
};

const addShiftMutation = useMutation(postAddShift(onSuccess));
const editShiftMutation = useMutation(updateShift(onSuccess));

const addShift = async () => {
  addShiftMutation.mutate({
    location_id: location.value,
    internal_role_id: role.value,
    external_role_name: null,
    company_id: companyId,
    shift_code: uuidv4(),
    currency: 'GBP',
    start_at: startDateTime.value,
    end_at: endDateTime.value,
    hours: hours.value,
    assigned_to: null,
    properties: notes.value ? { notes: notes.value } : {},
  });
};

const checkIfDifference = (originalValue, newValue) => {
  return originalValue === newValue ? null : newValue;
};

const editShift = async () => {
  const values = {
    location_id: checkIfDifference(props.shift?.location_id, location.value),
    internal_role_id: checkIfDifference(props.shift?.internal_role_id, role.value),
    start_at: isEqual(props.shift?.start_at, startDateTime.value) ? null : startDateTime.value,
    end_at: isEqual(props.shift?.end_at, endDateTime.value) ? null : endDateTime.value,
    hours: checkIfDifference(props.shift?.hours, hours.value),
    properties: checkIfDifference(props.shift?.properties?.notes, notes.value)
      ? {
          ...props.shift?.properties,
          notes: notes.value,
        }
      : null,
  };

  const payload = Object.fromEntries(Object.entries(values).filter(([, value]) => value !== null));

  editShiftMutation.mutate({
    shift_id: props.shift.shift_id,
    ...payload,
  });
};

const fetchRoles = async () => {
  const { data } = await ApiClient.getTalentPoolRoles();
  roles.value = data.sort((a, b) => a.role.role.localeCompare(b.role.role));
  role.value =
    roles.value.find(role => role.internal_job_role_id === props.shift?.internal_role_id)?.internal_job_role_id || '';
  roleFunction.value =
    roles.value?.find(roleOption => roleOption.internal_job_role_id === role.value)?.role?.function || '';
};

const locationOptions = computed(() => {
  return locations.value.map(location => ({
    value: location.location_id,
    text: location.name,
  }));
});

onMounted(() => {
  getLocations().then(() => {
    if (locations.value.length > 0) {
      if (props.shift?.location_id) {
        location.value = locations.value.find(
          location => location.location_id === props.shift?.location_id,
        ).location_id;
      } else {
        location.value = locations.value.find(location => location.location_id === locationId).location_id;
      }
    }
  });
  fetchRoles();
});
</script>

<style scoped lang="scss">
.input-title {
  font-size: 14px;
  font-weight: 500;
  margin: 6px;
}

.modal-title {
  font-weight: 600;
}

.override-button {
  margin: 10px 0;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
}

.full-width {
  grid-column: 1 / -1;
}

.form-subtext {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.form-subtext.danger {
  color: var(--palette-color-danger);
}

.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helper-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
}

.time-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.time-container div {
  display: flex;
  flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.footer .label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.footer .value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.total {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.map {
  display: block;
  height: 100%;
  width: 100%;
  min-height: 460px;
}
</style>
