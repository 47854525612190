<template>
  <app-login-box>
    <template v-slot:default>
      <h1>Login (impersonating)</h1>
      <app-loading :loading="loading" />
    </template>
  </app-login-box>
</template>

<script>
import { handleLogin } from '@/ApiClient';
import { getRouteNameFromClaims } from '@/Router';
import AppLoading from '@/components/AppLoading.vue';
import AppLoginBox from '@/components/AppLoginBox.vue';
import State from '@/state/State';

export default {
  name: 'Impersonate',
  components: { AppLoading, AppLoginBox },
  data() {
    return {
      loading: true,
      accessToken: undefined,
      refreshToken: undefined,
      error: undefined,
    };
  },
  async mounted() {
    const { access_token: accessToken, refresh_token: refreshToken, next: nextParam } = this.$route.query; // queryString.parse(location.search);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    try {
      await handleLogin(this.accessToken, this.refreshToken);

      history.replaceState({}, 'Partner Portal - Impersonating', '/');
      this.$router.push(nextParam || { name: getRouteNameFromClaims(State.state.claims) });
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'login' });
    }
  },
};
</script>
