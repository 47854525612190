<template>
  <span class="app-loading-spinner" :class="[done && 'app-loading-spinner--done']">
    <span class="app-loading-spinner__icon-wrapper">
      <font-awesome-icon :icon="done ? DONE_ICON : icon" size="2xl" />
    </span>
  </span>
</template>

<script setup>
const DONE_ICON = ['far', 'check'];

defineProps({
  icon: {
    type: Array,
    default: () => ['fas', 'arrow-down-to-line'],
  },
  done: Boolean,
});
</script>

<style lang="scss" scoped>
.app-loading-spinner {
  align-items: center;
  display: inline-flex;
  height: 6.5rem;
  justify-content: center;
  position: relative;
  width: 6.5rem;

  &::after {
    animation: spin 2s ease-in-out infinite;
    border-radius: 50%;
    border: 0.4rem solid var(--palette-color-default-lighten-90);
    border-top: 0.4rem solid var(--palette-color-brand-primary);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.app-loading-spinner__icon-wrapper {
  align-items: center;
  background-color: var(--palette-color-brand-primary);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--palette-color-base-white);
  display: inline-flex;
  height: 62.5%;
  justify-content: center;
  width: 62.5%;
}

.app-loading-spinner--done {
  &::after {
    border-color: var(--palette-color-success);
  }

  .app-loading-spinner__icon-wrapper {
    background-color: var(--palette-color-success);
  }
}
</style>
