<template>
  <SmeCard class="content-card">
    <div class="d-flex align-items-center">
      <p class="my-0 mr-5">Roughly how many employees work at your company?</p>
      <select class="form-control w-25" id="company-size" v-model="companySize">
        <option v-for="size in prices" :key="size" :value="size">{{ size }}</option>
      </select>
    </div>

    <SmeCard class="mt-3">
      <h4>Available plans:</h4>
      <div v-for="plan in plans" :key="plan">
        <div class="d-flex align-items-center">
          <p class="mt-1 mb-0">
            <strong>{{ plan }}</strong> - {{ PLAN_METADATA[plan].prices[companySize] }} + VAT / month.
          </p>
        </div>
      </div>
    </SmeCard>

    <SmeCard>
      <div class="d-flex w-100">
        <div class="features-column-header mobile-width"></div>
        <div class="d-flex flex-grow-1">
          <div :key="plan" class="d-flex align-items-center flex-column flex-fill mb-0" v-for="plan in plans">
            <p class="my-0">
              <strong class="mobile-font-size">{{ plan }}</strong>
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex w-100">
        <div class="d-flex flex-column mt-1 mobile-width">
          <div
            v-for="feature in Object.keys(PLAN_METADATA[firstPlan].features)"
            :key="feature"
            class="font-weight-bold mb-1 d-flex align-items-center row-height"
          >
            <p class="m-0">{{ feature }}</p>
          </div>
        </div>
        <div class="d-flex flex-grow-1">
          <div v-for="plan in plans" :key="plan" class="d-flex flex-column mt-1 flex-fill align-items-center">
            <div v-for="feature in features" :key="feature" class="d-flex align-items-center mb-1 row-height">
              <CheckBold
                v-if="PLAN_METADATA[plan].features[feature]"
                class="mr-1"
                :color="PaletteColors['brand-primary']"
              />
              <Cross v-else class="mr-2" />
            </div>
          </div>
        </div>
      </div>
    </SmeCard>
  </SmeCard>
</template>

<script setup>
import { computed, ref } from 'vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import CheckBold from '@/assets/icons/CheckBold.vue';
import Cross from '@/assets/icons/Cross.vue';
import { PaletteColors } from '@/Theme';
import { PLAN_METADATA } from '@/utils/pricing';

const firstPlan = computed(() => Object.keys(PLAN_METADATA)[0]);
const plans = computed(() => Object.keys(PLAN_METADATA));
const prices = computed(() => Object.keys(PLAN_METADATA[firstPlan.value].prices));
const features = computed(() => Object.keys(PLAN_METADATA[firstPlan.value].features));

const companySize = ref(prices.value[0]);
</script>

<style scoped lang="scss">
.content-card {
  background-color: rgba(255, 255, 255, 0.85);
}

.row-height {
  height: 1.5rem;
}

.mobile-font-size {
  @media (max-width: 500px) {
    font-size: 8px;
  }
}

.mobile-width {
  width: 200px;
  @media (max-width: 500px) {
    width: 150px;
  }
}
</style>
