<template>
  <section>
    <page-sub-header title="Set up a new Integration" title-tag="h2" />
    <b-row>
      <b-col v-for="integration in displayedIntegrations" :key="integration.provider" md="6" lg="4" class="mb-4">
        <integration-card :integration="integration">
          <template #footer>
            <b-button v-if="integration.enabled" class="integration-card__button" variant="outline-primary" disabled>
              Enabled
            </b-button>
            <b-button
              v-else
              :variant="isNonConfigurableIntegration(integration) ? 'primary' : 'outline-primary'"
              class="integration-card__button"
              @click="configureIntegration(integration)"
            >
              {{ isNonConfigurableIntegration(integration) ? 'Enable' : 'Configure' }}
            </b-button>
          </template>
        </integration-card>
      </b-col>
    </b-row>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import IntegrationCard from '@/pages/integrations/components/IntegrationCard.vue';
import { NON_CONFIGURABLE_INTEGRATIONS } from '@/pages/integrations/constants';

const props = defineProps({
  availableIntegrations: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['configure-integration']);

const displayedIntegrations = computed(() => props.availableIntegrations.filter(integration => integration.display));

const configureIntegration = integration => emit('configure-integration', integration);

const isNonConfigurableIntegration = integration =>
  integration.oauth2 || NON_CONFIGURABLE_INTEGRATIONS.includes(integration.provider);
</script>
