<template>
  <main id="js-page-content" role="main" class="page-content home">
    <ol class="breadcrumb page-breadcrumb">
      <li class="breadcrumb-item active">Partner Portal</li>
    </ol>
    <div class="subheader">
      <h1 class="subheader-title">
        <i class="fal fa-info-circle"></i> Page Not Found
        <small>The page you are looking for does not exist</small>
      </h1>
    </div>
  </main>
</template>
<script>
export default {
  name: 'PageNotFound',
};
</script>
