import cloneDeep from 'lodash/cloneDeep';
import { computed, reactive } from 'vue';

const composables = [];

window.composables = composables;

const resetState = (state, defaultState) => Object.keys(state).forEach(key => (state[key] = defaultState[key]));

export const resetStates = () => composables.forEach(composable => composable.reset());

const toComputedState = state => {
  const computedState = {};

  Object.keys(state).forEach(key => (computedState[key] = computed(() => state[key])));

  return computedState;
};

export const useStateComposable = state => {
  const defaultState = cloneDeep(state);
  const reactiveState = reactive(state);
  const computedState = toComputedState(reactiveState);
  const composable = {
    computedState,
    state: reactiveState,
    reset: (keys = []) => {
      let targetState = state;
      if (keys.length > 0) {
        targetState = keys.reduce((subset, key) => (key in state ? { ...subset, [key]: state[key] } : subset), {});
      }
      return resetState(targetState, defaultState);
    },
  };

  composables.push(composable);

  return composable;
};
