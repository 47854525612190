<template>
  <app-auth-page
    title="Give your employees flexible pay"
    :subtitle="
      !isDisableRegistration
        ? 'Give your employees the flexibility to access, track, build and better manage their pay.'
        : ''
    "
    :buttons="
      [
        !isDisableRegistration && {
          text: 'Register',
          link: '/register',
          style: {
            background: PaletteColors['brand-primary'],
            color: PaletteColors['base-white'],
            outline: 'none',
          },
        },
        {
          text: 'Book a demo',
          link: '/referral?demo',
          style: {
            background: PaletteColors['brand-primary'],
            color: PaletteColors['base-white'],
            outline: 'none',
          },
        },
        !isDisableLandingPageLinks && {
          text: 'Learn More →',
          link: 'https://dashboard.wagestream.com/learn/employers',
        },
      ].filter(button => !!button)
    "
    :showApContainer="false"
  >
    <template v-if="!isDisablePartnerLogos" #footer>
      <span class="social__label">As used by our partners:</span>
      <img class="social__image" :src="socialProofImg" />
    </template>
  </app-auth-page>
</template>

<script>
import { PaletteColors } from '@/Theme';
import socialProofImg from '@/assets/login-social-proof.png';
import AppAuthPage from '@/components/AppAuthPage.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';

export default {
  name: 'Referral',
  components: {
    AppAuthPage,
  },
  data() {
    return {
      socialProofImg,
      PaletteColors,
    };
  },
  mounted() {
    this.openCalendly();
  },
  methods: {
    openCalendly() {
      if (window.location.href.indexOf('?demo') != -1) {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/sme-demo/wagestream-sme-demo',
        });
      }
    },
  },
  setup() {
    const { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration } = useFeatureFlags();

    return { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration };
  },
  watch: {
    '$route.query.demo'() {
      this.openCalendly();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ap__proof {
  @media (max-width: 900px) {
    display: block;
  }
}
.social {
  margin-top: 42px;
  width: 100%;

  &__label {
    display: inline-block;
    margin-top: 24px;
    font-size: 12px;
    width: 100%;
  }

  &__image {
    width: 95%;
  }
}
</style>
