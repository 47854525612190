<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- It is used only for formatting for i18n values here, so XSS attack is not a case. -->
  <div class="outer">
    <div class="inner">
      <!-- Step: Final Loading & Redirect -->
      <main>
        <app-loading />
        <p>{{ info }}</p>
      </main>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import imgLogo from '@/components/assets/wagestream-logo-small-purple.png';
import State from '@/state/State';

export default {
  name: 'IntegrationsOauth2',
  components: {
    AppLoading,
  },
  mixins: [],
  data() {
    return {
      imgLogo,
      info: '',
    };
  },
  mounted() {
    const provider = this.$route.params.provider;
    let company_id = this.$route.params.company_id;
    let next = this.$route.query.next;
    const code = this.$route.query.code;
    const state = this.$route.query.state;
    let callback = window.location.toString().split('?')[0];

    // if doesn't support fragments in a redirect url
    if (provider === 'workforce') {
      const base = window.location.origin;
      callback = `${base}/utils/redirectWorkforce.html`;
    } else if (provider === 'xero') {
      const base = window.location.origin;
      callback = `${base}/utils/redirectXero.html`;
      // special case because the only way to get here is via an unexposed link
      next = `${base}/sme-dashboard`;
    }

    if (company_id === undefined) {
      company_id = State.state?.company?.company_id;
    }

    try {
      if (code) {
        this.info = `Setting up integration...`;
        ApiClient.completeOauth2(provider, company_id, code, state, callback).then(data => {
          window.location = data.url;
        });
      } else {
        this.info = `Redirecting to provider...`;
        ApiClient.startOauth2(provider, company_id, next, callback).then(data => {
          window.location = data.url;
        });
      }
    } catch (ex) {
      this.info = ex.toString();
    }
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.outer {
  background: var(--palette-color-base-background);
  position: relative;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
}

.outer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.inner {
  width: 588px;
  margin: 0 auto;
}

.logo {
  display: block;
  margin: 45px auto 25px auto;
  height: 51px;
  width: 78px;
}

.title1,
.title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
}

.title1 {
  color: var(--palette-color-default-lighten-20);
}

.title2 {
  color: var(--palette-color-brand-primary);
}

.subtitle {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: var(--palette-color-default-lighten-20);
  margin-top: 24px;
}

.main {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: auto;
  /*background: var(--palette-color-base-white);*/
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  margin: 48px 0;
  padding: 84px;
  width: 588px;
}

.error {
  margin-bottom: 35px;
}

p {
  margin-top: 20px;
  color: var(--palette-color-default-lighten-20);
}

@media (max-width: 600px) {
  .inner {
    width: 100vw;
    margin-bottom: 120px;
  }
  .logo {
    margin-top: 36px;
    margin-bottom: 15px;
  }
  .role-selection {
    font-size: 14px;
  }
  .title1,
  .title2 {
    font-size: 25px;
    line-height: 30px;
  }
  .subtitle {
    margin-top: 10px;
    font-size: 16px;
  }
  .main {
    width: 90vw;
    padding: 30px 25px 40px 25px;
    margin: 25px auto;
  }
  p {
    margin-top: 12px;
  }
}
</style>
