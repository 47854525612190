import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';

const FLEXIBLE_WORK_ENTITY = {
  LOCATION: 'LOCATION',
  ROLE: 'ROLE',
  UNFILLED_SHIFT: 'UNFILLED_SHIFT',
};

export const FLEXIBLE_WORK_STATUS = {
  LIVE: 'live',
  TRIAL: 'trial',
};

export const SHIFTS_VIEW_TYPE = {
  SCHEDULE: 'schedule',
  LIST: 'list',
};
export const SHIFTS_VIEW_TYPES = Object.values(SHIFTS_VIEW_TYPE);

export const SHIFT_SEARCH_FIELDS = ['postcode', 'role_names', 'location_name', 'start_at'];
export const CLAIM_SEARCH_FIELDS = ['user_name'];

const ONE_MILE_IN_METERS = 1609.34;
export const DEFAULT_PROXIMATE_DISTANCE_IN_METERS = ONE_MILE_IN_METERS * 5;

export const isLive = () => State.state.company?.properties?.flexible_work?.status === FLEXIBLE_WORK_STATUS.LIVE;

export const isIntegrated = () =>
  IntegrationsState.state.integrations
    ?.filter(integration => !integration.partner.includes('BROWSER_UPLOAD'))
    .some(integration =>
      Object.values(FLEXIBLE_WORK_ENTITY).every(entity => integration.properties?.entities?.[entity] || false),
    ) || false;

export const isSynced = () => false;
