<template>
  <Modal :title="modalTitle" :modalId="modalId" size="xl" ref="modalRef" left-column-width="60%" :multi-column="true">
    <template #modal-left>
      <div class="left-container">
        <div class="full-width">
          <div class="group-title">
            <div class="input-title">Display name</div>
          </div>
          <b-form-input type="text" v-model="displayName" placeholder="The display name for your job"></b-form-input>
        </div>
        <div>
          <div class="multi-column">
            <div class="multi-column-title">
              <div class="input-title">Function</div>
              <b-form-select v-model="roleFunction" :options="functionOptions">
                <template #first v-if="!props.existingRoleId">
                  <option value="" disabled>Select a function</option>
                </template>
              </b-form-select>
            </div>
            <div class="multi-column-title">
              <div class="input-title">Role</div>
              <b-form-select v-model="role" :options="roleOptions" :disabled="!roleFunction">
                <template v-if="!props.existingRoleId">
                  <option value="" disabled>Select a role</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div>
          <div class="multi-column">
            <div class="multi-column-title">
              <div class="input-title">Site</div>
              <b-form-select v-model="location" :options="locationOptions" />
            </div>
            <div class="multi-column-title">
              <div class="input-title">Pay</div>
              <b-form-input type="text" v-model="payAmount" placeholder="£25 - 32k"></b-form-input>
              <div v-if="parseFloat(payAmount)" class="pay-display-warning">
                Please input exactly as you'd like displayed, including the currency
              </div>
            </div>
            <div class="multi-column-title">
              <div class="input-title">Pay period</div>
              <b-form-select v-model="payFrequency" :options="PAY_FREQUENCY_OPTIONS">
                <template #first v-if="!props.existingPayFrequency">
                  <option value="" disabled>Select pay frequency</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="full-width">
          <div class="group-title">
            <div class="input-title">Description</div>
          </div>
          <quillEditor
            v-model="description"
            @change="onEditorChange"
            :options="{
              modules: {
                toolbar: ['bold', 'italic', 'underline'], // toggled buttons
              },
              height: '10rem',
              placeholder:
                'Share some information about your job here. You might want to include some information on key skills, levels of experience, benefits and the schedule.',
            }"
          ></quillEditor>
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="job-card">
        <div
          class="top-container"
          :style="{
            backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.8) 52%, white 100%), url(${selectedLocation?.properties?.cover_image_url})`,
          }"
        >
          <div class="job-tag-container">
            <div class="job-tag">Job</div>
          </div>
          <div class="job-title">{{ State?.state?.company?.name }}</div>
          <div class="location-info">
            {{
              selectedLocation?.name ||
              'site name here' + ', ' + selectedLocation?.properties?.postcode ||
              'postcode here'
            }}
          </div>
        </div>
        <div class="bottom-container">
          <div class="logo-container">
            <div class="logo-box">
              <div class="logo" :style="{ backgroundImage: `url(${selectedLocation?.properties?.logo_url})` }"></div>
            </div>
          </div>
          <div class="role">{{ displayName || '< display name >' }}</div>
          <div class="wages">{{ payAmount || '< pay >' }}</div>
          <div class="tag">
            <CheckCircleFilled :color="PaletteColors['success']"></CheckCircleFilled>
            {{ displayName ? `${displayName} full time` : '' }}
            {{ payAmount || payFrequency ? `(${payAmount} ${payFrequency})` : '' }}
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <!-- uncomment when process is complete -->
      <!-- <div>Based on engagement, we post jobs live for 5 days. We will let you know when we have gathered your shortlist so you can take the next steps.</div> -->
      <div class="footer">
        <div class="buttons">
          <b-button variant="outline-primary" @click="cancel">Cancel</b-button>
          <b-button @click="addJobPosting" variant="primary" :disabled="addDisabled"> {{ ctaText }} </b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import ApiClient from '@/ApiClient';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import Modal from '@/components/Modal.vue';
import { useMutation } from '@tanstack/vue-query';
import { postJobPosting, patchJobPosting } from '@/queries/talent-pool/jobs.js';
import { quillEditor } from 'vue3-quill';
import State from '@/state/State';
import { PaletteColors } from '@/Theme';
import { getFunctionOptions, getRoleOptionsBasedOnFunction } from '@/pages/talent-pool/utils/roles.js';
import { PAY_FREQUENCY_OPTIONS } from '@/pages/talent-pool/utils/jobs.js';

const emit = defineEmits(['hide']);

const postJob = useMutation(postJobPosting());
const patchJob = useMutation(patchJobPosting());

const route = useRoute();
const router = useRouter();

const props = defineProps({
  existingJobId: {
    type: String,
  },
  existingDisplayName: {
    type: String,
  },
  existingRoleId: {
    type: String,
  },
  existingLocationId: {
    type: String,
  },
  existingPayFrequency: {
    type: String,
  },
  existingPayAmount: {
    type: String,
  },
  existingDescription: {
    type: String,
  },
  sourceId: {
    type: String,
  },
  source: {
    type: String,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
});

const modalRef = ref(null);
const locationId = route.params.locationId;
const roles = ref([]);
const displayName = ref(props.existingDisplayName || '');
const role = ref(props.existingRoleId || '');
const roleFunction = ref('');
const location = ref(props.existingLocationId || '');
const payFrequency = ref(props.existingPayFrequency || '');
const payAmount = ref(props.existingPayAmount || '');
const description = ref(props.existingDescription || '');

const { getLocations, locations } = useTalentPoolLocations();

const modalId = computed(() => (props.editMode ? 'edit-job-modal' : 'add-job-modal'));

const modalTitle = computed(() => (props.editMode ? 'Edit job details' : 'Post a new job'));
const ctaText = computed(() => (props.editMode ? 'Save job details' : 'Post new job'));

const functionOptions = computed(() => {
  return getFunctionOptions(roles.value);
});

const roleOptions = computed(() => {
  return getRoleOptionsBasedOnFunction(roles.value, roleFunction.value);
});

const addDisabled = computed(() => {
  return (
    !role.value ||
    !location.value ||
    !payFrequency.value ||
    !payAmount.value ||
    !description.value ||
    !displayName.value
  );
});

const onEditorChange = ({ html }) => {
  description.value = html;
};

const addJobPosting = async () => {
  const payload = {
    location_id: location.value,
    source: 'raise_manual_job_posting',
    internal_job_role_id: role.value,
    external_role_name: displayName.value,
    pay_frequency: payFrequency.value,
    pay_amount: payAmount.value,
    description_html: description.value,
  };

  if (props.sourceId) {
    payload.source_id = props.sourceId;
  }

  if (props.source) {
    payload.source = props.source;
  }

  let posting;
  if (props.editMode) {
    if (!props.existingJobId) {
      throw new Error('No existing job id provided for editing');
    }
    posting = await patchJob.mutateAsync({ jobId: props.existingJobId, payload });
  } else {
    posting = await postJob.mutateAsync({ payload });
  }

  router.push({ name: 'talent-pool-job-detail', params: { locationId, jobId: posting.unfilled_shift_group_id } });
  modalRef.value.modal.hide();
  emit('hide');
};

const cancel = () => {
  modalRef.value.modal.hide();
  emit('hide');
};

const fetchRoles = async () => {
  const { data } = await ApiClient.getTalentPoolRoles();

  roles.value = data.sort((a, b) => a.role.role.localeCompare(b.role.role));
  roleFunction.value =
    roles.value?.find(roleOption => roleOption.internal_job_role_id === role.value)?.role?.function || '';
};

const locationOptions = computed(() => {
  return locations.value
    .map(location => ({
      value: location.location_id,
      text: location.name,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
});

const selectedLocation = computed(() => {
  return locations.value.find(loc => loc.location_id === location.value);
});

onMounted(async () => {
  await getLocations();

  // if a location id isn't passed, default to the current location
  if (!props.existingLocationId) {
    location.value = locations.value.find(location => location.location_id === locationId).location_id;
  }

  await fetchRoles();
});
</script>

<style scoped lang="scss">
.input-title {
  font-size: 14px;
  font-weight: 500;
  margin: 6px;
}

.modal-title {
  font-weight: 600;
}

.full-width {
  flex-grow: 1;
}

.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multi-column {
  display: flex;
  gap: 0.5rem;
}

.multi-column > div {
  flex: 1;
}

.multi-column-title {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: left;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.left-container {
  margin: 1rem;
}

.job-card {
  margin: 3rem 1rem 1rem 0rem;
  box-shadow: -2px -1px 10px 10px rgba(34, 40, 47, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0 3.5rem 0;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.job-title {
  font-size: 16px;
  font-weight: 600;
}

.location-info {
  font-size: 14px;
  font-weight: 400;
}

.role {
  margin-top: 2rem;
  font-size: 16px;
  font-weight: 600;
}

.wages {
  font-size: 28px;
  font-weight: 700;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  text-align: center;
  text-wrap: balance;
  max-width: 20rem;
}

.tag {
  border-radius: 90px;
  border: 1px solid var(--palette-color-base-white);
  box-shadow: 0px 2px 10px 0px var(--palette-color-default-lighten-90);
  color: var(--palette-color-success);
  background-color: var(--palette-color-success-lighten-90);
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 80%;
  text-align: center;
}

.bottom-container {
  background: linear-gradient(var(--palette-color-core-1-lighten-90) 50%, var(--palette-color-base-white) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -1rem 0 0 0;
  border-radius: 16px;
  padding-bottom: 5rem;
}

.job-tag {
  background-color: var(--palette-color-default);
  color: var(--palette-color-base-white);

  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: -0.01em;

  border-radius: 50px;
  padding: 0.15rem 0.5rem;
}

.job-tag-container {
  display: flex;
  align-self: flex-end;
  margin-right: 1rem;
}

.logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  transition: all 0.5s ease-out 0.5s;
  height: 4rem;
  width: 4rem;
  border-radius: 99%;
  box-shadow: 0px 2px 10px 0px rgba(34, 40, 47, 0.1);
}

.logo-box {
  border-radius: 50px;
  background: var(--palette-color-base-white);
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
}

.pay-display-warning {
  font-size: 11px;
  font-weight: 300;
  color: var(--palette-color-warning);
  margin: 0 0.5rem;
}

::v-deep .ql-toolbar,
::v-deep .ql-container {
  border-color: var(--palette-color-default-lighten-90) !important;
}
::v-deep .ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}

::v-deep .ql-container {
  height: 10rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
