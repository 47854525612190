<template>
  <div class="page">
    <div class="card" v-if="route.name === 'talent-pool-job-onboarding-details'">
      <div class="header">
        <div @click="onGoBack()" class="back-icon"><ArrowLeft></ArrowLeft></div>
        <img class="raise-logo" :src="themeImage('raise-logo-without-icon.svg')" alt="Raise" />
      </div>
      <div v-if="submitted">
        <div class="heading">
          <TickCircle width="4.5rem" height="4.5rem" :color="PaletteColors['success']" />
          <h1>You're all set!</h1>
        </div>
        <p class="text-center">Your posting is live and visible to our pool of motivated candidates.</p>
        <p class="text-center">
          We'll be back in touch in a few days with a shortlist of verified top tier candidates.
        </p>
        <p class="text-center cta">
          Click to track progress of the posting
          <router-link
            :to="{ name: 'talent-pool-job-onboarding-access', params: { posting_id: postingId }, query: utmParams }"
            >here</router-link
          >.
        </p>
      </div>
      <div v-else>
        <div v-if="loading">
          <app-loading :loading="loading" />
          <div class="description">We're finding the best matches around you</div>
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
          </div>
        </div>
        <div v-else>
          <div class="heading">
            <div v-if="candidates.length > 5">
              <div class="title">
                We have found
                <span class="num">
                  {{ candidates.length }}
                </span>
                matches for you
              </div>
              <div class="description font">Want to hire them?</div>
              <div class="description">Post for free, pay to meet</div>
            </div>
            <h1 class="title" v-else>Post for free, pay to meet</h1>
          </div>

          <div class="inputs-wrapper">
            <div class="input-wrapper">
              <div class="input-title">Position</div>
              <b-form-input v-model="roleAlias" type="text"></b-form-input>
            </div>
            <div class="input-wrapper">
              <div class="input-title">Company or brand</div>
              <b-form-input v-model="companyBrand" type="text" placeholder="e.g. McDonalds"></b-form-input>
            </div>

            <div class="multi-column">
              <div class="input-wrapper">
                <div class="input-title">Site address</div>
                <b-form-input v-model="siteAddress" type="text" placeholder="e.g. 10 Downing Street"></b-form-input>
              </div>
              <div class="input-wrapper">
                <div class="input-title">Postcode</div>
                <b-form-input v-model="postcode" type="text"></b-form-input>
              </div>
            </div>

            <div class="multi-column">
              <div class="input-wrapper">
                <div class="input-title">Pay</div>
                <b-form-input type="text" v-model="payAmount" placeholder="£25 - 32k"></b-form-input>
                <div v-if="parseFloat(payAmount)" class="pay-display-warning">
                  Please input exactly as you'd like displayed, including the currency
                </div>
              </div>
              <div class="input-wrapper">
                <div class="input-title">Pay period</div>
                <b-form-select v-model="payFrequency" :options="PAY_FREQUENCY_OPTIONS">
                  <template #first>
                    <option value="" disabled>Select a pay frequency</option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="input-wrapper">
              <div class="input-title">Description</div>
              <div>
                <quillEditor
                  v-model="description"
                  @change="onEditorChange"
                  :options="{
                    modules: {
                      toolbar: ['bold', 'italic', 'underline'], // toggled buttons
                    },
                    placeholder:
                      'Share some information about your job here. You might want to include some information on key skills, levels of experience, benefits and the schedule.',
                  }"
                />
              </div>
            </div>

            <div class="input-wrapper">
              <div class="input-title">Your email</div>
              <b-form-input v-model="userEmail" type="text" placeholder="you@somewhere.com"></b-form-input>
            </div>
          </div>

          <div class="button-wrapper">
            <b-button @click="submit" variant="primary" :disabled="addDisabled"> Find me the perfect matches </b-button>
          </div>
        </div>
      </div>
      <!-- end -->
    </div>
    <l-map
      v-if="route.name === 'talent-pool-job-onboarding-details'"
      class="map"
      id="job-onboarding-map"
      @update:zoom="zoomUpdated"
      :center="center"
      :zoom="zoom"
      :options="{
        zoomControl: false,
        dragging: true,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        boxZoom: false,
        touchZoom: false,
        attributionControl: false,
      }"
      ref="map"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker
        v-for="employee in candidates"
        :key="employee.opaque_id"
        :lat-lng="[employee.worker_latitude, employee.worker_longitude]"
      >
        <l-icon class-name="worker-marker" :icon-size="[84, 47]" :icon-anchor="[0, 47]">
          <div v-if="zoom > 12" :class="['worker']">
            <RaiseRating :rating="employee.internal_rating" size="EXTRA-SMALL" />
          </div>
          <div class="point"></div>
        </l-icon>
      </l-marker>
    </l-map>
    <router-view />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { PaletteColors, themeImage } from '@/Theme';
import { useRoute, useRouter } from 'vue-router/composables';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import AppLoading from '@/components/AppLoading.vue';
import { quillEditor } from 'vue3-quill';
import ApiClient from '@/ApiClient';
import TickCircle from '@/assets/icons/TickCircle.vue';
import { PAY_FREQUENCY_OPTIONS } from '@/pages/talent-pool/utils/jobs.js';
import tracker from '@/Tracker';
import ArrowLeft from '@/assets/icons/ArrowLeft.vue';
import RaiseRating from '@/pages/talent-pool/components/RaiseRating.vue';
import { getUtmParams } from '@/utils';

const route = useRoute();
const router = useRouter();

const map = ref(null);
const url = process.env.VUE_APP_MAPBOX_MAP_URL + `?access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`;

const submitted = ref(false);
const zoom = ref(13);
const loading = ref(true);
const companyBrand = ref('');
const siteAddress = ref('');
const userEmail = ref('');
const payFrequency = ref('');
const payAmount = ref('');
const description = ref('');

const centreLat = ref(51.515419);
const centreLon = ref(-0.141099);
const candidates = ref([]);
const postingId = ref(null);

const progress = ref(0);

const role = ref(route.query.role);
const roleAlias = ref(route.query.role);
const roleFunction = ref(route.query.function);
const postcode = ref(route.query.postcode.toUpperCase());

const addDisabled = computed(() => {
  return (
    payFrequency.value === '' ||
    payAmount.value === '' ||
    description.value === '' ||
    companyBrand.value === '' ||
    siteAddress.value === '' ||
    userEmail.value === ''
  );
});

const utmParams = computed(() => {
  return getUtmParams();
});

const onEditorChange = ({ html }) => {
  description.value = html;
};

const onGoBack = () => {
  router.back();
};

const submit = async () => {
  loading.value = true;

  try {
    window.dataLayer.push({
      event: 'raise_gtm_tryout',
      role: role.value,
      function: roleFunction.value,
      postcode: postcode.value,
    });
    await tracker.trackAnonymousEngagement('raise_gtm_tryout', {
      role: role.value,
      function: roleFunction.value,
      postcode: postcode.value,
    });
  } catch (e) {
    //
  }

  const payload = {
    admin_email: userEmail.value,
    company_name: companyBrand.value,
    brand_name: companyBrand.value,
    site_name: companyBrand.value,
    site_address_line: siteAddress.value,
    site_city: null,
    site_postcode: postcode.value.toUpperCase(),
    pay_frequency: payFrequency.value,
    pay_amount: payAmount.value,
    description: description.value,
    role_alias: roleAlias.value,
    role: role.value,
    function: roleFunction.value,
    utm: utmParams.value,
  };

  const resp = await ApiClient.postTalentPoolTryOut(payload);

  postingId.value = resp.data.unfilled_shift_group_id;
  submitted.value = true;
  loading.value = false;
};

const center = computed(() => {
  return [centreLat.value, centreLon.value];
});

const zoomUpdated = newZoom => {
  zoom.value = newZoom;
};

onMounted(async () => {
  if (!postcode.value || !roleFunction.value || !role.value) {
    // Even if we don't have all the data, we can still show the form
    loading.value = false;
    return;
  }
  const { data } = await ApiClient.getTalentPoolExample(postcode.value, roleFunction.value, role.value);

  if (data.coverage && data.coverage.length) {
    // TODO: should really centre on site postcode and show location glyph
    candidates.value = data.coverage;
    let clat = 0.0;
    let clon = 0.0;
    for (const candidate of candidates.value) {
      clat += candidate.worker_latitude;
      clon += candidate.worker_longitude;
    }
    centreLat.value = clat / candidates.value.length;
    centreLon.value = clon / candidates.value.length;
  }

  const interval = setInterval(() => {
    if (progress.value < 100) {
      progress.value += 2.5;
    } else {
      loading.value = false;
      clearInterval(interval);
    }
  }, 100);
});
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

.card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 40%;
  max-width: 500px;
  min-width: 400px;
  height: fit-content;
  top: 0;
  left: 4vw;
  bottom: 0;
  margin: auto;
  padding: 2rem;
  background-color: var(--palette-color-base-white);
  border-radius: 0.75rem;
  z-index: 1;
  max-height: calc(100vh - 1rem);
  overflow-y: auto;
}

.card .raise-logo {
  width: 100px;
}

.card h1 {
  text-align: center;
  color: var(--palette-color-default);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 650px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .input-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .multi-column {
    display: flex;
    gap: 0.5rem;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  padding-top: 1rem;

  p {
    margin: 0;
    color: var(--palette-color-default-lighten-20);
  }
}

.map {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.heading {
  width: 100%;
  max-width: 650px;
  padding-bottom: 0.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}

.description {
  color: var(--palette-color-default-lighten-20);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}

::v-deep .ql-toolbar,
::v-deep .ql-container {
  border-color: var(--palette-color-default-lighten-90) !important;
}
::v-deep .ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}

::v-deep .ql-container {
  height: 5.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.worker-marker {
  width: auto !important;
}

.worker-marker .point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--palette-color-default-lighten-20);
}

.worker-marker .worker {
  background-color: var(--palette-color-base-white);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -38px;
  margin-bottom: 3px;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.pay-display-warning {
  font-size: 12px;
  color: var(--palette-color-default-lighten-20);
}

.cta {
  font-size: 16px;
  font-weight: 500;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  .back-icon {
    position: absolute;
    left: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .raise-logo {
    margin: 0 auto;
  }
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: var(--palette-color-default-lighten-90);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: var(--palette-color-success);
  width: 0;
  transition: width 0.1s linear;
}

.num {
  color: var(--palette-color-warning);
  font-weight: 700;
}
</style>
