var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.props.companyLogos && _setup.props.companyLogos?.length > 0)?_c('div',{staticClass:"logos-wrapper"},_vm._l((_vm.companyLogos),function(logo,index){return _c(logo !== '' ? 'img' : 'div',{key:logo + '-' + index,tag:"component",staticClass:"logo",style:({
      left: `${32 * index}px`,
      zIndex: (_vm.companyLogos ? _vm.companyLogos?.length : 1) - index,
      width: `${52 - index * 8}px`,
      height: `${52 - index * 8}px`,
      backgroundColor: logo === '' ? 'var(--palette-color-default-lighten-95)' : 'var(--palette-color-base-white)',
    }),attrs:{"src":logo !== '' ? logo : undefined}})}),1):_c('div',{staticClass:"img-placeholder"})
}
var staticRenderFns = []

export { render, staticRenderFns }