<template>
  <section>
    <page-list-header>
      <template #left>
        <filter-dropdown v-model="localFilters" :filters="filterItems" :key="filtersKey">
          <inline-search v-if="isShiftView" v-model="searchText" @search="onSearch" />
        </filter-dropdown>
      </template>
    </page-list-header>
    <app-schedule :dates="dates" @previous="goPrevious" @next="goNext" @reset="reset">
      <section class="app-schedule__body">
        <div v-if="loading" class="py-1">
          <app-loading :loading="loading" />
        </div>
        <template v-if="!loading">
          <div v-if="!Object.keys(scheduleData)?.length" class="py-4 text-center">
            No {{ filterConditions.length ? 'matching' : '' }} shifts found
          </div>
          <section v-for="(rowData, name) in scheduleData" :key="name" class="app-schedule__row">
            <header class="app-schedule__row-header">
              <h3 class="app-schedule__row-header-title">{{ name }}</h3>
            </header>
            <section class="app-schedule__dates-row">
              <article v-for="(fieldData, date) in rowData" :key="date" class="app-schedule__date">
                <template v-if="isShiftView">
                  <flexible-work-schedule-shift
                    v-for="shift in fieldData"
                    :key="shift.shift_id"
                    :shift="shift"
                    :claims="claims[shift.shift_id]"
                    class="app-schedule__card"
                    @updated="getShiftsAndClaims"
                  />
                </template>
                <flexible-work-schedule-summary
                  v-else
                  :summary="fieldData"
                  class="app-schedule__card"
                  @view-list="status => showSummaryInList(fieldData.date, name, status)"
                />
              </article>
            </section>
          </section>
        </template>
      </section>
    </app-schedule>
    <footer v-if="isShiftView" class="mt-3">
      <flexible-work-shift-status-key light />
    </footer>
  </section>
</template>

<script setup>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppSchedule from '@/components/AppSchedule.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import FlexibleWorkScheduleShift from '@/pages/flexible-work/components/FlexibleWorkScheduleShift.vue';
import FlexibleWorkScheduleSummary from '@/pages/flexible-work/components/FlexibleWorkScheduleSummary.vue';
import FlexibleWorkShiftStatusKey from '@/pages/flexible-work/components/FlexibleWorkShiftStatusKey.vue';
import State from '@/state/State';
import useRolesAndLocations from '@/state/composables/useRolesAndLocations';
import { toTitleCase } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';
import { CLAIM_SEARCH_FIELDS, SHIFTS_VIEW_TYPE, SHIFT_SEARCH_FIELDS } from '@/utils/flexible-work';
import { hasApprovedClaim, hasAssignedClaim, hasPendingClaims, hasWorkedClaim } from '@/utils/flexible-work/Claim';
import { SHIFT_STATUS, getShiftStatus } from '@/utils/flexible-work/Shift';
import { sortKeys } from '@/utils/object';

const $router = useRouter();
const $route = useRoute();
const { roles, locations, getRolesAndLocations } = useRolesAndLocations();

const SCHEDULE_VIEW = {
  SHIFT: 'SHIFT',
  SUMMARY: 'SUMMARY',
};

const FILTER_KEYS = {
  ROLE: 'roles',
  LOCATION: 'location_id',
  STATUS: 'status',
  START: 'start',
  END: 'end',
};

const STATUS_FILTERS = Object.values(SHIFT_STATUS);

const DAYS = 7;
const START_DEFAULT_VALUE = toFormattedDate(moment().weekday(1));
const END_DEFAULT_VALUE = toFormattedDate(moment(START_DEFAULT_VALUE).add(DAYS, 'days'));
let initialStart = START_DEFAULT_VALUE;
let initialEnd = END_DEFAULT_VALUE;
const queryStart = $route.query[FILTER_KEYS.START];
const queryEnd = $route.query[FILTER_KEYS.END];

if (
  queryStart &&
  queryEnd &&
  moment(queryStart).weekday() === 1 &&
  moment(queryEnd).diff(queryStart, 'days') === DAYS
) {
  initialStart = queryStart;
  initialEnd = queryEnd;
}

const shifts = ref([]);
const filteredShifts = ref([]);
const claims = ref({});
const initialized = ref(false);
const loading = ref(true);
const filters = ref({
  [FILTER_KEYS.START]: initialStart,
  [FILTER_KEYS.END]: initialEnd,
});
const localFilters = ref({});
const filtersKey = ref(0);
const searchCriteria = ref('');
const searchText = ref('');

const scheduleView = computed(() => (State.state.claims?.a ? SCHEDULE_VIEW.SUMMARY : SCHEDULE_VIEW.SHIFT));
const isShiftView = computed(() => scheduleView.value === SCHEDULE_VIEW.SHIFT);
const scheduleData = computed(() =>
  scheduleView.value === SCHEDULE_VIEW.SHIFT ? shiftsByLocation.value : summaryByLocation.value,
);

const shiftsByLocation = computed(() => {
  const shiftsByLocation = sortKeys(groupBy(filteredShifts.value, 'location_name'));

  return Object.fromEntries(
    Object.entries(shiftsByLocation).map(([location, locationShifts]) => {
      const locationShiftsByDate = Array(DAYS).fill();

      dates.value.forEach(
        (date, index) =>
          (locationShiftsByDate[index] = sortBy(
            locationShifts.filter(shift => moment(date).diff(toFormattedDate(shift.start_at), 'days') === 0),
            ['start_at', 'hours'],
          )),
      );

      return [location, locationShiftsByDate];
    }),
  );
});

const summaryByLocation = computed(() => {
  const shiftsByLocation = sortKeys(groupBy(filteredShifts.value, 'location_name'));

  return Object.fromEntries(
    Object.entries(shiftsByLocation).map(([location, locationShifts]) => {
      const locationSummaryByDate = Array(DAYS).fill();

      dates.value.forEach((date, index) => {
        const locationShiftsByDate = locationShifts.filter(
          shift => moment(date).diff(toFormattedDate(shift.start_at), 'days') === 0,
        );

        locationSummaryByDate[index] = {
          applications: locationShiftsByDate.reduce(
            (acc, shift) => (acc += claims.value[shift.shift_id]?.length || 0),
            0,
          ),
          approved: locationShiftsByDate.filter(shift => hasApprovedClaim(claims.value[shift.shift_id])).length,
          assigned: locationShiftsByDate.filter(shift => hasAssignedClaim(claims.value[shift.shift_id])).length,
          pending: locationShiftsByDate.filter(shift => hasPendingClaims(claims.value[shift.shift_id])).length,
          worked: locationShiftsByDate.filter(shift => hasWorkedClaim(claims.value[shift.shift_id])).length,
          count: locationShiftsByDate.length,
          date,
        };
      });

      return [location, locationSummaryByDate];
    }),
  );
});

const dates = computed(() =>
  Array(DAYS)
    .fill()
    .map((_, i) => moment(filters.value[FILTER_KEYS.START]).add(i, 'days')),
);

const filterItems = computed(() => {
  const filterItems = [];

  if (isShiftView.value) {
    filterItems.push({
      key: FILTER_KEYS.STATUS,
      label: 'Status',
      type: CONTROL_TYPES.RADIO,
      options: STATUS_FILTERS.map(status => ({
        text: toTitleCase(status),
        value: status,
      })),
    });
  }

  filterItems.push({
    key: FILTER_KEYS.ROLE,
    label: 'Role',
    type: CONTROL_TYPES.CHECKBOX,
    options: roles.value.map(role => ({
      text: role.name,
      value: role.name,
    })),
    hidden: roles.value.length <= 1,
  });

  if (!isShiftView.value) {
    filterItems.push({
      key: FILTER_KEYS.LOCATION,
      label: 'Location',
      type: CONTROL_TYPES.RADIO,
      options: locations.value.map(location => ({
        text: location.name,
        value: location.division_id,
      })),
      hidden: locations.value.length <= 1,
    });
  }

  return filterItems;
});

const filterConditions = computed(() => {
  const conditions = [];

  if (searchCriteria.value) {
    conditions.push(shift => {
      const shiftClaims = claims.value[shift.shift_id] || [];
      const shiftMatch = doSearch(shift, SHIFT_SEARCH_FIELDS);
      const claimMatch = shiftClaims.some(claim => doSearch(claim, CLAIM_SEARCH_FIELDS));

      return shiftMatch || claimMatch;
    });
  }

  if (localFilters.value[FILTER_KEYS.STATUS]) {
    conditions.push(
      shift => getShiftStatus(shift, claims.value[shift.shift_id]) === localFilters.value[FILTER_KEYS.STATUS],
    );
  }

  if (localFilters.value[FILTER_KEYS.ROLE]?.length) {
    conditions.push(shift => localFilters.value[FILTER_KEYS.ROLE].some(role => shift.role_names.includes(role)));
  }

  if (localFilters.value[FILTER_KEYS.LOCATION]) {
    conditions.push(shift => shift.location_id === localFilters.value[FILTER_KEYS.LOCATION]);
  }

  return conditions;
});

watch(filters, () => getShiftsAndClaims(), { deep: true });
watch(
  localFilters,
  () => {
    setFilteredShiftsAndClaims();

    if (initialized.value) {
      trackFiltersChanged();
    }
  },
  { deep: true },
);
watch(filterItems, () => filtersKey.value++, { deep: true });
watch(searchCriteria, () => setFilteredShiftsAndClaims());

const getShiftsAndClaims = async () => {
  loading.value = true;
  shifts.value = (await ApiClient.getFlexibleWorkShifts(filters.value))?.shifts || [];
  claims.value = groupBy((await ApiClient.getFlexibleWorkClaims(filters.value))?.claims || [], 'shift_id');

  setFiltersQuery();
  setFilteredShiftsAndClaims();

  if (!initialized.value) {
    initialized.value = true;
  }

  loading.value = false;
};

const setFilteredShiftsAndClaims = () => {
  filteredShifts.value = shifts.value.filter(filterFunction);
};

const goPrevious = () => {
  filters.value = {
    [FILTER_KEYS.START]: toFormattedDate(moment(filters.value[FILTER_KEYS.START]).add(DAYS * -1, 'days')),
    [FILTER_KEYS.END]: toFormattedDate(moment(filters.value[FILTER_KEYS.END]).add(DAYS * -1, 'days')),
  };
};

const goNext = () => {
  filters.value = {
    [FILTER_KEYS.START]: toFormattedDate(moment(filters.value[FILTER_KEYS.START]).add(DAYS, 'days')),
    [FILTER_KEYS.END]: toFormattedDate(moment(filters.value[FILTER_KEYS.END]).add(DAYS, 'days')),
  };
};

const reset = () => {
  filters.value = {
    [FILTER_KEYS.START]: START_DEFAULT_VALUE,
    [FILTER_KEYS.END]: END_DEFAULT_VALUE,
  };
};

const setFiltersQuery = () => {
  if ($route.query.start === filters.value[FILTER_KEYS.START] && $route.query.end === filters.value[FILTER_KEYS.END]) {
    return;
  }

  $router.replace({ query: { ...$route.query, ...filters.value } });
};

const filterFunction = item =>
  filterConditions.value.length ? filterConditions.value.every(condition => condition(item)) : true;

const doSearch = (entity, fields) =>
  Object.values(pick(entity, fields)).some(value => value.toLowerCase().includes(searchCriteria.value.toLowerCase()));

const onSearch = () => (searchCriteria.value = searchText.value);

const showSummaryInList = (date, locationName, status) =>
  $router.push({
    name: 'flexible-work',
    query: omitBy(
      {
        ...$route.query,
        view: SHIFTS_VIEW_TYPE.LIST,
        [FILTER_KEYS.LOCATION]: locations.value.find(location => location.name === locationName)?.division_id,
        [FILTER_KEYS.STATUS]: status,
        [FILTER_KEYS.START]: toFormattedDate(moment(date)),
        [FILTER_KEYS.END]: toFormattedDate(moment(date).add(1, 'days')),
      },
      isNil,
    ),
  });

const trackFiltersChanged = debounce(
  () => tracker.trackEvent('flexible_work_schedule_filters_changed', { filters: localFilters.value }),
  200,
);

onBeforeMount(() => {
  let query = cloneDeep($route.query);

  if (query.tab) {
    const status = query.tab;

    query = omit(query, 'tab');

    if (isShiftView.value && STATUS_FILTERS.includes(status)) {
      query[FILTER_KEYS.STATUS] = status;
    }
  }

  if (!isEqual($route.query, query)) {
    $router.replace({ query });
  }

  getShiftsAndClaims();
  getRolesAndLocations();
});
</script>
