import { differenceInMilliseconds, intervalToDuration, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';

export const formatDate = dateString => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

export const formatDateSegments = dateString => {
  const date = new Date(dateString);
  const dayNumber = date.getDate();
  const dayName = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  return { dayNumber, dayName, month };
};

export const formatTimeRange = (startAt, endAt) => {
  const options = { hour: 'numeric', minute: 'numeric' };
  const startTime = new Date(startAt).toLocaleTimeString('en-US', options);
  const endTime = new Date(endAt).toLocaleTimeString('en-US', options);

  return `${startTime} - ${endTime}`.toLocaleLowerCase();
};

export const formatTime = dateTime => {
  const options = { hour: 'numeric', minute: 'numeric' };

  const time = new Date(dateTime).toLocaleTimeString('en-US', options);

  return `${time}`.toLocaleLowerCase();
};

export function timeToMinutes(timeStr) {
  const time = new Date(timeStr);
  return time.getHours() * 60 + time.getMinutes();
}

export function formatTimeRemaining(endISODateTime) {
  // Parse the ISO date time string to a Date object
  const endTime = new Date(endISODateTime);

  // Calculate the time remaining in milliseconds
  const timeRemaining = differenceInMilliseconds(endTime, new Date());

  // Guard clause for past dates
  if (timeRemaining <= 0) {
    return '0s';
  }

  // Convert milliseconds to hours, minutes, and seconds
  const { hours, minutes, seconds, days, weeks } = intervalToDuration({ start: 0, end: timeRemaining });

  // Format the time remaining
  let formattedTime = '';
  if (weeks > 0) {
    formattedTime += `${weeks} weeks `;
  }
  if (days > 0) {
    formattedTime += `${days} days `;
  }
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    // Include minutes if hours are present, set to 0 if undefined
    formattedTime += `${minutes || 0}m `;
  }
  formattedTime += `${seconds || 0}s`; // Seconds are always included, set to 0 if undefined
  return formattedTime.trim();
}

export const convertTimeToDatetime = (newTime, existingDate) => {
  let newDate = existingDate;
  const [hours, minutes] = newTime.split(':');
  newDate = setHours(newDate, Number(hours));
  newDate = setMinutes(newDate, Number(minutes));
  newDate = setSeconds(newDate, 0);
  return newDate;
};

export const formatHoursAndMinutes = hours => {
  const roundedHours = Math.floor(hours);
  const minutes = Math.round((hours - roundedHours) * 60);

  let formattedTime = '';
  if (roundedHours > 0) {
    formattedTime += `${roundedHours}h`;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}mins`;
  }

  return formattedTime;
};

export const setDateTimeToUTC = dateTime => {
  if (!dateTime) {
    return null;
  }
  if (dateTime instanceof Date) {
    return dateTime.toISOString().replace('.000', '');
  }
  return dateTime.replace('.000', '');
};

export const getProgressPercentage = (startDateString, endDateString) => {
  const start = new Date(startDateString);
  const end = new Date(endDateString);
  const now = Date.now();

  const total = end - start;
  const elapsed = now - start;

  return Math.min(100, (elapsed / total) * 100);
};

export const formatDuration = (startDate, endDate) => {
  const start = parseISO(startDate);
  const end = endDate ? parseISO(endDate) : new Date();

  const duration = intervalToDuration({ start, end });

  if (Object.keys(duration).length === 0) {
    return '';
  }

  const durationYears = duration.years;

  if (!durationYears) {
    const durationMonths = duration.months;

    if (!durationMonths) {
      const durationDays = duration.days;
      const durationDaysString = durationDays === 1 ? `${durationDays} day` : `${durationDays} days`;
      return durationDaysString;
    }
    const durationMonthsString = durationMonths === 1 ? `${durationMonths} month` : `${durationMonths} months`;
    return durationMonthsString;
  }

  const durationString = durationYears === 1 ? `${durationYears} year` : `${durationYears} years`;

  return durationString;
};
