<template>
  <main>
    <div v-if="loading" class="loading-container">
      <app-loading />
    </div>
    <div v-else class="job-details" role="tablist">
      <div v-if="job?.data?.value?.closed_at">
        <div v-if="job?.data?.value?.successful_applicant_name" class="closed-container">
          <span class="closed-info"
            >Congratulations on selecting {{ job?.data?.value?.successful_applicant_name }} for this role! 🎉
          </span>
          <span class="closed-info">Now you can contact them through email or text.</span>
          <span class="closed-info">Make them an offer or start them on your internal candidate selection process</span>
          <div class="contact-details">
            <span class="contact-detail"
              ><Phone :color="PaletteColors['brand-primary']"></Phone>
              {{ job?.data?.value?.successful_applicant_phone }}</span
            >
            <span class="contact-detail"
              ><Envelope :color="PaletteColors['brand-primary']"></Envelope
              >{{ job?.data?.value?.successful_applicant_email }}</span
            >
          </div>
        </div>
        <div v-else class="closed-container">
          <span class="closed-info">You've closed this job posting.</span>
          <span>
            <OpenPositions></OpenPositions>
          </span>
        </div>
      </div>
      <div class="accordions" v-else>
        <Shortlist :postingId="jobId" posting-type="job"></Shortlist>
      </div>
      <!-- <div class="accordions" v-if="isUnlocked">
        <InterviewViaRaise :shortlisted-candidates="shortlist?.data?.value?.profiles || []"></InterviewViaRaise>
      </div> -->
    </div>
    <add-interview-or-trial-shift-modal
      v-if="
        job.data.value?.location_id && job.data.value?.internal_job_role_id && job.data.value?.unfilled_shift_group_id
      "
      :jobId="job.data.value.unfilled_shift_group_id"
      :roleId="job.data.value.internal_job_role_id"
      :locationId="job.data.value.location_id"
    ></add-interview-or-trial-shift-modal>
    <NextStepsModal :open-tab="tabToOpen" :contact-details="contactDetails"></NextStepsModal>
  </main>
</template>

<script setup>
import OpenPositions from '@/pages/talent-pool/components/OpenPositions/index.vue';
import Envelope from '@/assets/icons/Envelope.vue';
import Phone from '@/assets/icons/Phone.vue';
import { useRoute } from 'vue-router/composables';
import AppLoading from '@/components/AppLoading.vue';
import { useQuery } from '@tanstack/vue-query';
import { getJobPosting } from '@/queries/talent-pool/jobs.js';
import { getShortlist } from '@/queries/talent-pool/shortlists.js';
import { computed, watch, ref } from 'vue';
import AddInterviewOrTrialShiftModal from '@/pages/talent-pool/jobs/components/AddInterviewOrTrialShiftModal.vue';
import { PaletteColors } from '@/Theme';
import NextStepsModal from '@/pages/talent-pool/jobs/components/NextStepsModal.vue';
import Shortlist from '@/pages/talent-pool/components/Shortlist';

const route = useRoute();
const jobId = route.params.jobId;

const job = useQuery(getJobPosting(jobId));
const shortlist = useQuery(getShortlist(jobId));

const loading = ref(true);
const tabToOpen = ref('');

watch([job.fetchStatus, job.isPending, job.isSuccess, shortlist.isSuccess, shortlist.fetchStatus], async () => {
  if (job.isSuccess && shortlist.isSuccess) {
    loading.value = false;
  }
});

const contactDetails = computed(() => {
  return shortlist?.data?.value?.profiles?.map(profile => ({
    name: profile.worker_name,
    ...profile.contact_details,
  }));
});
</script>

<style scoped lang="scss">
main {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.job-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  gap: 1rem;
}

.loading-container {
  padding-top: 4rem;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--palette-color-default-lighten-20);
  padding: 1.5rem;
  gap: 0.5rem;
  text-align: center;

  svg {
    fill: var(--palette-color-default-lighten-20);
  }
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Applicants */

.applicants {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.applicants .applicant-card {
  width: fit-content;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 0.75rem;
    margin-top: 0.75rem;

    > button {
      width: 100%;
    }
  }
}

.unlock {
  border-radius: 10px;
  box-shadow: 0px 2px 1px 0px rgba(34, 40, 47, 0.05), 0px 0px 3px 0px rgba(34, 40, 47, 0.1);
  background-color: var(--palette-color-base-white);
  overflow: hidden;
  padding: 2rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  max-width: 260px;
  text-align: center;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-around;
  font-size: 16px;

  .lock-open {
    display: none;
  }

  &:hover {
    cursor: pointer;
    border-color: var(--palette-color-default-lighten-80);
    box-shadow: 0px 0px 2px 0px rgba(34, 40, 47, 0.05), 0px 2px 10px 0px rgba(34, 40, 47, 0.1);

    .lock {
      display: none;
    }

    .lock-open {
      display: block;
    }
  }
}

.shortlisted .add-interview-or-shift-title-wrapper {
  display: flex;
  align-items: center;

  .title {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--palette-color-default);
  }

  h2,
  p {
    margin-block: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.01em;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
  }

  button {
    width: fit-content;
  }
}

.closed-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 3rem 0;
}

.closed-info {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.contact-details {
  margin-top: 1rem;
}

.contact-detail {
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}

.sub-text {
  font-size: 16px;
  font-weight: 400;
}

.countdown {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: var(--palette-color-brand-primary);
  margin: 1rem 0;
}
</style>
