<template>
  <div class="sme-bulk-upload">
    <template v-if="stage === STAGES.UPLOAD">
      <page-sub-header
        v-if="props.showTitle"
        :title="`Import your ${humanizedName} data`"
        title-tag="h2"
        class="mb-3"
      />
      <ul class="pl-3">
        <b-table-simple class="my-3" table-class="table-minimal" responsive small>
          <b-tr class="large">
            <b-th>Field</b-th>
            <b-th></b-th>
            <b-th>Description</b-th>
            <b-th>Example</b-th>
          </b-tr>
          <b-tr v-for="field in schema" :key="field.key">
            <b-th>
              {{ field.label }}
            </b-th>
            <b-td>
              <div class="sme-bulk-upload__field-descriptor m-0">
                <b-badge v-if="field?.required" class="mr-2" variant="danger">Required</b-badge>
                <b-badge v-else class="mr-2">{{ props.optionalText || 'Optional' }}</b-badge>
                <span>
                  {{ field.type }}
                  <small v-if="!!field?.options?.length" class="d-block">
                    {{ field.options.join(' | ') }}
                  </small>
                </span>
              </div>
            </b-td>
            <b-td class="large">
              <!-- eslint-disable vue/no-v-html -->
              <span v-if="field.description" v-html="field.description"></span>
              <!-- eslint-enable vue/no-v-html -->
            </b-td>
            <b-td>
              {{ field.example }}
            </b-td>
          </b-tr>
        </b-table-simple>
        <b-table-lite
          v-if="viewExample"
          :fields="schemaFields"
          :items="schemaSampleDataset"
          :thead-tr-class="['large']"
          class="my-3"
          bordered
          responsive
          small
        />
        <div v-if="viewDateFormat" class="my-3">
          <sme-alert level="danger" class="my-3">
            Be careful when choosing a different date format. If you choose the wrong format, your dates will be
            incorrect.
          </sme-alert>
          <select v-model="dateFormat" class="form-control configuration-select" id="date-format">
            <option v-for="format in DATE_FORMATS" :key="format" :value="format">{{ format }}</option>
          </select>
        </div>
      </ul>
      <sme-file-input
        :id="`${name}-file-upload`"
        :accepted-file-types="ACCEPTED_FILE_TYPES"
        :file="file"
        class="mt-3"
        upload-text="Drop file here or <u>click here to upload</u>"
        @change="handleFileUpload"
      />
      <sme-alert v-if="errorMessage" :html="errorMessage" level="danger" class="mt-3" />
      <ul class="pl-3 mt-3">
        <li>File <strong>must</strong> be one of the following formats: {{ ACCEPTED_FILE_TYPE_EXTENSIONS }}</li>
        <li>File <strong>must</strong> have column labels in the first row</li>
        <li>
          File should contain the following columns (don't worry if the column labels don't match exactly, you'll be
          able to match them later)
        </li>
        <sme-alert level="info" class="my-3">
          Need to support more fields or formats? Get in touch with your account manager and we can help.
        </sme-alert>
        <p class="mt-3">
          Don't have a file or template yet?
          <sme-button-link @click="generateTemplateCSV">
            <font-awesome-icon class="mx-1" :icon="['fad', 'download']" /> Download our template CSV
          </sme-button-link>
        </p>
        <sme-button-link
          @click="
            viewExample = !viewExample;
            viewDateFormat = false;
          "
          class="mr-3"
          >View example file</sme-button-link
        >
        <sme-button-link
          v-if="hasDateField"
          @click="
            viewDateFormat = !viewDateFormat;
            viewExample = false;
          "
          >I need a different date format</sme-button-link
        >
      </ul>
      <page-sub-footer v-if="rawDataset.length" right>
        <b-button variant="outline-primary" @click="reset"> Upload a different file </b-button>
        <b-button variant="primary" @click="goToStage(STAGES.MATCH_COLUMNS)"> Next </b-button>
      </page-sub-footer>
    </template>

    <template v-if="stage === STAGES.MATCH_COLUMNS">
      <page-sub-header title="Match your columns" title-tag="h2" class="mb-3" />
      <div class="mb-3">
        <sme-alert v-if="hasColumnErrors" level="danger"> {{ ERROR_MESSAGES.COLUMNS_UNMATCHED }}</sme-alert>
        <sme-alert v-else level="good"> All required columns have been matched. </sme-alert>
      </div>
      <b-table-simple borderless responsive small>
        <b-tr class="large">
          <b-th v-for="field in schemaFields" :key="field.key" :style="field.thStyle">
            <label :for="`header-match-${field.key}`" class="mb-2">
              {{ field.key }}
              <small class="sme-bulk-upload__field-descriptor">
                <b-badge v-if="field?.required" class="mr-1" variant="danger">Required</b-badge>
                <b-badge v-else class="mr-1">{{ props.optionalText || 'Optional' }}</b-badge>
                {{ field.type }}
              </small>
            </label>
            <b-form-select
              v-model="datasetMappings[field.key]"
              :id="`header-match-${field.key}`"
              :options="datasetHeaders"
              :state="field?.required && !datasetMappings[field.key] ? false : undefined"
            >
              <template #first>
                <option value="">Select your column</option>
              </template>
            </b-form-select>
          </b-th>
        </b-tr>
      </b-table-simple>
      <h3 class="sme-bulk-upload__title my-3">Preview</h3>
      <b-table-lite
        :fields="mappedSchemaFields"
        :items="mappedDataset"
        :thead-tr-class="['large']"
        sticky-header="12.5rem"
        bordered
        responsive
        small
      />
      <page-sub-footer right>
        <b-button variant="outline-primary" @click="goToStage(STAGES.UPLOAD)"> Back </b-button>
        <b-button variant="outline-primary" v-if="props.saveProgress" @click="onSave">
          <b-spinner v-if="isSaving" class="mr-2" small /> {{ isSaving ? 'Saving...' : 'Save' }}
        </b-button>
        <b-button id="match-cols-next" variant="primary" :disabled="hasColumnErrors" @click="goToStage(STAGES.VERIFY)">
          Next
        </b-button>
      </page-sub-footer>
    </template>

    <template v-if="stage === STAGES.VERIFY">
      <page-sub-header :title="`Verify your ${humanizedName} data`" title-tag="h2" class="mb-3" />
      <sme-alert v-if="dateFormat !== 'YYYY-MM-DD' && !hasFieldErrors" level="warning" class="mb-3">
        Your date data was uploaded with the format <strong>{{ dateFormat }}</strong
        >. We have converted it to <strong>YYYY-MM-DD</strong>. Please double check that our conversion is correct.
      </sme-alert>
      <div class="mb-3">
        <sme-alert level="danger" class="mb-3" :html="ERROR_MESSAGES.VALIDATION" v-if="hasFieldErrors" />
        <div class="d-flex justify-content-between align-content-center">
          <b-form-checkbox v-model="filterErrored" name="filter-errored" :disabled="!hasFieldErrors">
            Only show rows with errors
          </b-form-checkbox>
          <b-button
            size="sm"
            v-b-modal="'confirm-delete-modal'"
            :disabled="selectedRows.length === 0"
            variant="outline-danger"
            >Delete selected ({{ selectedRows.length }})</b-button
          >
        </div>
      </div>
      <b-table
        class="table-editable"
        :fields="[SELECT_ACTION_FIELD].concat(mappedSchemaFields).concat([ACTIONS_FIELD])"
        :items="dataset"
        :thead-tr-class="['large']"
        :tbody-tr-class="getRowClassNames"
        sticky-header="31rem"
        bordered
        responsive
        small
      >
        <template #head()="{ field: { label, required, type } }">
          {{ label }}
          <small class="sme-bulk-upload__field-descriptor">
            <b-badge v-if="required" class="mr-1" variant="danger">Required</b-badge>
            <b-badge v-else class="mr-1">{{ props.optionalText || 'Optional' }}</b-badge>
            {{ type }}
          </small>
        </template>
        <template #head(select)>&nbsp;</template>
        <template #head(actions)>&nbsp;</template>
        <template #cell()="{ item, field: { key, type, options } }">
          <b-form-select
            v-if="!!options?.length"
            v-model="item[key]"
            :id="getRowFieldId(item, key)"
            :options="options"
            :state="hasFieldError(item, key) ? false : undefined"
            @change="clearFieldUploadError(item, key)"
          >
            <template #first>
              <option value="">Select {{ key }}</option>
            </template>
          </b-form-select>
          <b-form-input
            v-else
            v-model="item[key]"
            :id="getRowFieldId(item, key)"
            :state="hasFieldError(item, key) ? false : undefined"
            :class="{ 'text-right': isNumberFieldType(type) && !hasFieldError(item, key) }"
            @input="clearFieldUploadError(item, key)"
          />
          <b-tooltip
            v-if="hasFieldError(item, key)"
            :target="getRowFieldId(item, key)"
            :delay="{ show: 50, hide: 0 }"
            custom-class="sme-bulk-upload__tooltip"
            placement="rightbottom"
            variant="danger"
            html
          >
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="getFieldError(item, key)"></span>
            <!-- eslint-enable vue/no-v-html -->
          </b-tooltip>
        </template>
        <template #cell(select)="row">
          <b-form-checkbox @change="onSelectRow(row)" v-model="row.rowSelected" />
        </template>
        <template #cell(actions)="row">
          <b-button
            v-b-modal="'confirm-delete-modal'"
            class="mx-2"
            size="sm"
            title="Delete row"
            variant="outline-primary border-0"
            @click="rowToDelete = row"
          >
            <font-awesome-icon :icon="['fad', 'trash']" />
          </b-button>
        </template>
      </b-table>
      <page-sub-footer right>
        <template #before>
          <sme-alert v-if="errorMessage" :html="errorMessage" level="danger" />
        </template>
        <b-button variant="outline-primary" :disabled="uploading" @click="goToStage(STAGES.MATCH_COLUMNS)">
          Back
        </b-button>
        <b-button variant="outline-primary" @click="onSave" v-if="props.saveProgress">
          <b-spinner v-if="isSaving" class="mr-2" small />
          {{ isSaving ? 'Saving...' : 'Save' }}
        </b-button>
        <b-button
          v-if="props.confirmBeforeUpload"
          id="import-next-prompt"
          variant="primary"
          :disabled="hasFieldErrors || uploading"
          v-b-modal="'confirm-import-modal'"
        >
          <b-spinner v-if="uploading" class="mr-2" small />
          {{ uploading ? 'Importing...' : 'Import' }}
        </b-button>
        <b-button v-else variant="primary" :disabled="hasFieldErrors || uploading" @click="handleUpload">
          <b-spinner v-if="uploading" class="mr-2" small />
          {{ uploading ? 'Importing...' : 'Import' }}
        </b-button>
      </page-sub-footer>
    </template>

    <template v-if="stage === STAGES.COMPLETE">
      <div class="success-container">
        <CheckCircleFilled :color="PaletteColors['success']" width="40px" height="40px"></CheckCircleFilled>
        <div class="success-title">Success!</div>
        <div class="success-subtitle">{{ props.successText || 'Import another file or go back to see your data' }}</div>
      </div>
      <page-sub-footer right>
        <b-button variant="outline-primary" @click="reset"> Import another file </b-button>
      </page-sub-footer>
    </template>

    <b-modal
      id="confirm-delete-modal"
      ok-title="Delete"
      ok-variant="danger"
      centered
      hide-header
      @cancel="rowToDelete = undefined"
      @ok="selectedRows.length === 0 ? handleDeleteRow() : handleDeleteRows()"
    >
      {{
        selectedRows.length === 0
          ? 'Are you sure you want to delete this row?'
          : `Are you sure you want to delete ${selectedRows.length} rows?`
      }}
    </b-modal>
    <b-modal
      id="confirm-import-modal"
      ok-title="Yes, import my data"
      ok-variant="primary"
      centered
      hide-header
      @ok="handleUpload"
      size="lg"
    >
      <div class="font-weight-bold title">Are you sure you want to import this data?</div>
      <div class="warning-text">
        {{ props.confirmText || 'Once you import, you cannot reverse the changes.' }}
      </div>
    </b-modal>
  </div>
</template>

<script>
export const POST_MODE = {
  FILE: 'FILE',
  JSON: 'JSON',
};

export default {};
</script>

<script setup>
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { computed, onMounted, ref } from 'vue';
import { read as xlsxRead, utils as xlsxUtils } from 'xlsx';
import ApiClient from '@/ApiClient';
import PageSubFooter from '@/components/PageSubFooter.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert';
import SmeButtonLink from '@/components/atoms/SmeButtonLink';
import SmeFileInput, { FILE_TYPES, FILE_TYPE_EXTENSIONS } from '@/components/atoms/SmeFileInput';
import State from '@/state/State';
import { DATE_FORMATS } from '@/utils/date';
import moment from 'moment';
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import { PaletteColors } from '@/Theme';

const ACCEPTED_FILE_TYPES = [FILE_TYPES.CSV, FILE_TYPES.XLS, FILE_TYPES.XLSX];
const ACCEPTED_FILE_TYPE_EXTENSIONS = ACCEPTED_FILE_TYPES.map(fileType => `.${FILE_TYPE_EXTENSIONS[fileType]}`).join(
  ', ',
);

const STAGES = {
  UPLOAD: 'UPLOAD',
  MATCH_COLUMNS: 'MATCH_COLUMNS',
  VERIFY: 'VERIFY',
  COMPLETE: 'COMPLETE',
};

const ACTIONS_FIELD = { class: 'auto align-middle', key: 'actions', label: '' };
const SELECT_ACTION_FIELD = { class: 'auto align-middle pl-2', key: 'select', label: '' };

const ERROR_MESSAGES = {
  COLUMNS_UNMATCHED: 'Some required columns have not been matched.',
  FATAL: 'An error occurred uploading your file. Please contact support for assistance.',
  FATAL_MESSAGE: message => `An error occurred uploading your file:<br /> <em>${message}</em>`,
  FIELD_REQUIRED: 'Field is required',
  FIELD_FORMAT: 'Invalid field format',
  FIELD_VALUE_INVALID: 'Invalid field value',
  MIN_COLUMNS: "The file you're uploading does not contain enough columns.",
  NO_DATA: "The file you're uploading does not contain any data.",
  VALIDATION: `Your data contains validation errors.`,
};

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  schema: {
    type: Array,
    required: true,
  },
  metadata: Object,
  postFunction: Function,
  postMode: {
    type: String,
    default: POST_MODE.FILE,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  startingStage: {
    type: String,
  },
  startingData: {
    type: Array,
  },
  saveProgress: {
    type: Function,
  },
  confirmBeforeUpload: {
    type: Boolean,
    default: false,
  },
  confirmText: {
    type: String,
  },
  optionalText: {
    type: String,
  },
  successText: {
    type: String,
  },
});

const emit = defineEmits(['uploaded', 'onChangeDataset', 'onSaveData']);

const stage = ref(props.startingStage || STAGES.UPLOAD);
const viewExample = ref(false);
const file = ref(undefined);
const rawDataset = ref(props.startingData || []);
const dataset = ref(props.startingData || []);
const datasetMappings = ref({});
const filterErrored = ref(false);
const rowToDelete = ref(undefined);
const errorMessage = ref('');
const uploading = ref(false);
const uploadErrors = ref([]);
const dateFormat = ref('YYYY-MM-DD');
const viewDateFormat = ref(false);
const selectedRows = ref([]);
const isSaving = ref(false);

const schemaFields = computed(() =>
  props.schema.map(field => ({ ...field, thStyle: { width: 100 / props.schema.length + '%' } })),
);
const hasDateField = computed(() => props.schema.some(field => field.type === 'Date'));
const mappedSchemaFields = computed(() =>
  schemaFields.value
    .reduce((fields, field) => [...fields, ...(datasetMappings.value[field.key] ? [field] : [])], [])
    .map((field, index, fields) => ({ ...field, thStyle: { width: 100 / fields.length + '%' } })),
);
const schemaRequired = computed(() => props.schema.filter(field => field?.required));
const schemaSampleDataset = computed(() => [
  props.schema.reduce((row, field) => ({ ...row, [field.key]: field.example }), {}),
]);
const datasetHeaders = computed(() => (rawDataset.value?.length > 0 ? Object.keys(rawDataset.value[0]) : []));
const mappedDataset = computed(() =>
  rawDataset.value.map(row =>
    mappedSchemaFields.value.reduce(
      (mappedRow, field) => ({ ...mappedRow, [field.key]: row[datasetMappings.value[field.key]] }),
      {},
    ),
  ),
);

const bypassValidation = computed(() => {
  // TODO this should use a feature flag
  return State.state.company.properties.portal?.bypass_file_upload_validation || false;
});
const hasColumnErrors = computed(() => {
  if (bypassValidation.value) {
    return false;
  }
  return schemaRequired.value.some(field => !datasetMappings.value[field.key]);
});
const hasFieldErrors = computed(() => dataset.value?.some(hasRowError));
const humanizedName = computed(() => props.name.replaceAll('-', ' '));

onMounted(() => {
  if (props.startingStage) {
    setDataset(rawDataset?.value);
    emit('onChangeDataset', dataset.value, stage.value);
  }
});

const handleFileUpload = event => {
  reset();
  file.value = event.target?.files?.[0] || event.dataTransfer?.files?.[0];

  if (!file.value) {
    setErrorMessage(ERROR_MESSAGES.FATAL);
    return;
  }

  if (file.value.type === FILE_TYPES.CSV) {
    if (bypassValidation.value) {
      Papa.parse(file.value, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          rawDataset.value = results.data;
          dataset.value = results.data;
          goToStage(STAGES.MATCH_COLUMNS);
        },
      });
      return;
    }

    try {
      let untitledHeaderIndex = 1;

      Papa.parse(file.value, {
        header: true,
        skipEmptyLines: true,
        transformHeader: header => (!header ? `Untitled column (${untitledHeaderIndex++})` : header),
        complete: results => {
          let data = results.data;

          if (data.some(row => '__parsed_extra' in row)) {
            data = expandCSVRows(data);
          }

          setDataset(data);
        },
      });
    } catch {
      setErrorMessage(ERROR_MESSAGES.FATAL);
    }
  } else {
    const fileReader = new FileReader();

    fileReader.onloadend = handleFileRead;
    fileReader.onerror = () => setErrorMessage(ERROR_MESSAGES.FATAL);

    fileReader.readAsArrayBuffer(file.value);
  }
};

const handleFileRead = event => {
  try {
    const result = event.target.result;
    const workbook = xlsxRead(result, { cellDates: true, type: 'buffer' });
    let data = xlsxUtils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      blankrows: false,
      dateNF: 'yyyy-mm-dd',
      raw: false,
      defval: '',
    });

    if (data.some(row => Object.keys(row).some(key => key.includes('__EMPTY')))) {
      data = expandXLSXRows(data);
    }

    setDataset(data);
  } catch {
    setErrorMessage(ERROR_MESSAGES.FATAL);
  }
};

const handleUpload = async () => {
  uploading.value = true;

  setErrorMessage('');

  try {
    let response;

    if (props.postMode === POST_MODE.FILE) {
      const data = Papa.unparse(dataset.value);
      const arrayBuffer = await new Blob([data], { type: 'text/csv;charset=utf-8;' }).arrayBuffer();
      const fileContent = new Uint8Array(arrayBuffer);
      const fileName = props.name + '.csv';
      const uploadParams = [State.state.company.company_id, fileName, fileContent, props.metadata];

      const postFunction = bypassValidation.value ? ApiClient.postFile : props.postFunction || ApiClient.postFile;
      response = await postFunction(...uploadParams);
    } else {
      response = await props.postFunction(dataset.value);
    }

    if (response?.errors?.length) {
      throw { validation_errors: response.errors };
    }

    emit('uploaded', response);
    goToStage(STAGES.COMPLETE);
  } catch (error) {
    if (error?.validation_errors) {
      uploadErrors.value = error.validation_errors;
    } else {
      setErrorMessage(error?.message ? ERROR_MESSAGES.FATAL_MESSAGE(error.message) : ERROR_MESSAGES.FATAL);
    }
  }

  uploading.value = false;
};

const handleDeleteRow = () => {
  const index = getRowIndex(rowToDelete.value.item);

  rawDataset.value.splice(index, 1);
  dataset.value.splice(index, 1);
  rowToDelete.value = undefined;

  removeRowUploadError(index);

  uploadErrors.value = uploadErrors.value.map(uploadError => ({
    ...uploadError,
    index: uploadError.index > index ? uploadError.index - 1 : uploadError.index,
  }));

  emit('onChangeDataset', dataset.value);
  if (dataset.value.length === 0) {
    reset();
  }
};

const handleDeleteRows = () => {
  const indicesToDelete = selectedRows.value.map(row => getRowIndex(row.item));

  dataset.value = dataset.value.filter((_, index) => !indicesToDelete.includes(index));
  rawDataset.value = rawDataset.value.filter((_, index) => !indicesToDelete.includes(index));

  uploadErrors.value = uploadErrors.value
    .map(uploadError => ({
      ...uploadError,
      index: indicesToDelete.includes(uploadError.index)
        ? -1
        : uploadError.index > Math.min(...indicesToDelete)
        ? uploadError.index - indicesToDelete.length
        : uploadError.index,
    }))
    .filter(uploadError => uploadError.index !== -1);

  emit('onChangeDataset', dataset.value);
  selectedRows.value = [];

  if (dataset.value.length === 0) {
    reset();
  }
};

const onSelectRow = row => {
  if (row.rowSelected) {
    selectedRows.value.push(row);
  } else {
    selectedRows.value.splice(selectedRows.value.indexOf(row), 1);
  }
};

const goToStage = toStage => {
  setErrorMessage('');

  if (toStage === STAGES.MATCH_COLUMNS && stage.value === STAGES.VERIFY) {
    applyFieldChangesToRawDataset();
  } else if (toStage === STAGES.VERIFY) {
    dataset.value = preFormatDataset();
    onSave();
  }

  stage.value = toStage;
  emit('onChangeDataset', rawDataset.value, stage.value);
};

const onSave = async () => {
  if (props.saveProgress) {
    isSaving.value = true;
    const dataToSave = dataset.value || rawDataset.value;

    try {
      const data = await props.saveProgress(dataToSave);

      dataset.value = data.data;
      uploadErrors.value = data.errors;
      setDataset(dataset.value);
    } finally {
      isSaving.value = false;
    }
  }
};

const reset = () => {
  file.value = undefined;
  rawDataset.value = [];
  dataset.value = [];
  datasetMappings.value = {};
  filterErrored.value = false;
  rowToDelete.value = undefined;
  errorMessage.value = '';
  uploadErrors.value = [];
  selectedRows.value = [];

  goToStage(STAGES.UPLOAD);
};

const generateTemplateCSV = () =>
  saveAs(
    new Blob([props.schema.map(field => '"' + field.key + '"').join(',') + '\n'], { type: 'text/csv;charset=utf-8;' }),
    `${props.name}-template.csv`,
  );

const expandCSVRows = data =>
  data.map(({ __parsed_extra, ...row }) => ({
    ...row,
    ...__parsed_extra.reduce((extra, value, index) => ({ ...extra, [`Unknown column (${index + 1})`]: value }), {}),
  }));

const expandXLSXRows = data =>
  data.map(row => {
    let index = 1;

    return Object.entries(row).reduce(
      (row, [key, value]) => ({
        ...row,
        [key.includes('__EMPTY') ? `Unknown column (${index++})` : key]: value,
      }),
      {},
    );
  });

const handleDateFormat = data => {
  const newData = [...data];
  const dateFields = props.schema.filter(field => field.type === 'Date').map(field => field.key);
  data.forEach(row => {
    dateFields.forEach(field => {
      if (field in row && row[field] && row[field] !== '') {
        const datePart = row[field].substr(0, dateFormat.value.length);
        const parsedDate = moment(datePart, dateFormat.value, true);
        row[field] = parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : row[field];
      }
    });
  });
  return newData;
};

const setDataset = data => {
  rawDataset.value = data;
  datasetMappings.value = props.schema.reduce(
    (mappings, field) => ({
      ...mappings,
      [field.key]: datasetHeaders.value.find(header => header.toLowerCase() === field.key) || '',
    }),
    {},
  );

  if (!file.value || !props.startingData) {
    return;
  }

  if (rawDataset.value?.length === 0) {
    setErrorMessage(ERROR_MESSAGES.NO_DATA);
  } else if (datasetHeaders.value.length < schemaRequired.value.length) {
    setErrorMessage(ERROR_MESSAGES.MIN_COLUMNS);
  } else if (props.saveProgress) {
    return;
  } else {
    goToStage(STAGES.MATCH_COLUMNS);
  }
};

const preFormatDataset = () => {
  const data = handleDateFormat(mappedDataset.value);
  return data.map(row =>
    Object.entries(row).reduce((formattedRow, [key, value]) => {
      const formatter = getSchemaField(key)?.formatter;
      const validator = getSchemaField(key)?.validator;

      // only format if the formatted value is valid, otherwise just return the invalid value
      if (formatter && validator && validator(formatter(value))) {
        return { ...formattedRow, [key]: formatter(value) };
      } else {
        return { ...formattedRow, [key]: value };
      }
    }, {}),
  );
};

const applyFieldChangesToRawDataset = () => {
  rawDataset.value = rawDataset.value.map((rawRow, index) =>
    Object.entries(datasetMappings.value).reduce(
      (row, [key, mapping]) => ({ ...row, [mapping]: dataset.value[index][key] }),
      rawRow,
    ),
  );
};

const setErrorMessage = message => (errorMessage.value = message);

const getSchemaField = key => props.schema.find(field => field.key === key);

const getRowIndex = row => dataset.value.indexOf(row);

const getRowFieldId = (row, key) => `row-${getRowIndex(row)}-field-${key}`;

const getRowClassNames = row =>
  [
    hasRowError(row) ? 'error' : '',
    filterErrored.value && hasFieldErrors.value && !hasRowError(row) ? 'hidden' : '',
  ].filter(className => !!className);

const getFieldError = (row, key) => {
  const field = getSchemaField(key);
  const index = getRowIndex(row);
  const value = row[key];
  const fieldUploadError = getFieldUploadError(index, key);

  if (field?.required && !value) {
    return ERROR_MESSAGES.FIELD_REQUIRED;
  } else if (value && field?.validator && !field?.validator(value)) {
    return field.errorMessages?.fieldFormat ? field.errorMessages.fieldFormat(value) : ERROR_MESSAGES.FIELD_FORMAT;
  } else if (value && !!field?.options?.length && !field?.options.includes(value)) {
    return field.errorMessages?.fieldValueInvalid
      ? field.errorMessages.fieldValueInvalid(value)
      : ERROR_MESSAGES.FIELD_VALUE_INVALID;
  } else if (fieldUploadError) {
    return fieldUploadError.error;
  }
};

const hasFieldError = (row, key) => !!getFieldError(row, key);

const hasRowError = row => {
  if (bypassValidation.value) {
    return false;
  }
  return Object.keys(row).some(key => hasFieldError(row, key));
};

const getRowUploadError = index => uploadErrors.value.find(uploadError => parseInt(uploadError.index) === index);

const removeRowUploadError = index => {
  const rowUploadError = getRowUploadError(index);

  if (rowUploadError) {
    uploadErrors.value.splice(uploadErrors.value.indexOf(rowUploadError), 1);
  }
};

const getFieldUploadError = (index, key) =>
  getRowUploadError(index)?.fields.find(fieldError => fieldError.field === key);

const clearFieldUploadError = (row, key) => {
  const index = getRowIndex(row);
  const rowUploadError = getRowUploadError(index);

  if (rowUploadError) {
    rowUploadError.fields = rowUploadError.fields.filter(fieldError => fieldError.field !== key);

    if (rowUploadError.fields.length === 0) {
      removeRowUploadError(index);
    }
  }
};

const isNumberFieldType = type => {
  if (!type) {
    return false;
  }

  return type.includes('Currency') || type.includes('Number');
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 15px;
}

.warning-text {
  font-size: 14px;

  margin: 1rem 0;
}
.sme-bulk-upload {
  :deep(table) {
    tr {
      &.error {
        background-color: var(--palette-color-danger-lighten-90);
      }

      &.hidden {
        display: none;
      }

      &.large {
        th {
          padding-bottom: 0.6rem;
          padding-top: 0.6rem;
        }
      }
    }

    td,
    th {
      min-width: 7.5rem;

      &.auto {
        min-width: 0;
      }

      &.large {
        min-width: 20rem;
      }
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-editable {
    :deep(table) {
      td {
        max-width: none;
        padding: 0;
      }

      .custom-select,
      .form-control {
        background-color: transparent;
        border-radius: 0;

        &:-webkit-box-shadow {
          -webkit-box-shadow: none !important;
        }
      }

      .custom-select:not(:focus):not(.is-invalid),
      .was-validated .custom-select:not(:invalid),
      .form-control:not(:focus):not(.is-invalid),
      .was-validated .form-control:not(:invalid) {
        border-color: transparent;
      }
    }
  }
}

.sme-bulk-upload__title {
  color: var(--palette-color-default);
}

.sme-bulk-upload__field-descriptor {
  align-items: baseline;
  display: flex;
  margin: 0.1rem 0;
  flex-direction: column;
}

.configuration-select {
  max-width: 10rem;
  padding: 0.5rem;
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.success-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--palette-color-default);
}

.success-subtitle {
  text-align: center;
  color: var(--palette-color-default);
  font-size: 14px;
  width: 60%;
}
</style>

<style lang="scss">
.sme-bulk-upload__tooltip {
  .tooltip-inner {
    border-radius: 0 !important;
  }
}
</style>
