<!-- eslint-disable vue/no-deprecated-dollar-listeners-api -->

<template>
  <div class="page-content-fw-wrapper">
    <div class="page-content-fw-wrapper-inner">
      <!-- Flexible Work Header -->
      <NavHeader />

      <!-- Main -->
      <main id="js-page-content" role="main" class="page-content-fw streams">
        <div class="page-content-fw-inner">
          <slot></slot>
        </div>
      </main>

      <!-- Mobile nav -->
      <footer class="mobile-footer">
        <router-link
          :class="{ 'fw-footer-tab': true, 'tab--active': $route.name === 'talent-pool' }"
          :to="{ name: 'talent-pool', params: { locationId } }"
        >
          <Rows width="22" height="22" color="currentColor" />
          <span>Dashboard</span>
        </router-link>
        <router-link
          :class="{ 'fw-footer-tab': true, 'tab--active': $route.name === 'talent-pool-schedule' }"
          :to="{ name: 'talent-pool-schedule' }"
        >
          <Calendar width="22" height="22" color="currentColor" />
          <span>Schedule</span>
        </router-link>
      </footer>
    </div>
    <GetStartedModal />
    <AddShiftModal v-if="$route.name !== 'talent-pool-schedule'" modal-id="add-shift-modal" />
    <AddTeamMemberModal />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router/composables';
import NavHeader from '@/pages/talent-pool/components/NavHeader.vue';
import GetStartedModal from '@/pages/talent-pool/components/GetStartedModal.vue';
import AddShiftModal from '@/pages/talent-pool/components/AddShiftModal.vue';
import AddTeamMemberModal from '@/pages/talent-pool/team/components/AddTeamMemberModal.vue';
import Calendar from '@/assets/icons/Calendar.vue';
import Rows from '@/assets/icons/Rows.vue';

const route = useRoute();
const locationId = route.params.locationId;
</script>

<style scoped lang="scss">
.page-content-fw-wrapper {
  min-height: calc(100vh + env(safe-area-inset-bottom) * 1.25);
  background-color: var(--palette-color-default-lighten-95);
  flex-direction: row;
  width: 100%;
}

.page-content-fw-wrapper-inner {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100vw;
  height: 100vh;
}

.page-content-fw {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.page-content-fw-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-footer {
  display: none;
}

.fw-footer-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--palette-color-default-lighten-50);
}

.fw-footer-tab.tab--active {
  color: var(--palette-color-core-1);

  img {
    color: var(--palette-color-core-1);
    height: 20px;
    width: 20px;
  }
}

@media (max-width: 900px) {
  .page-content-fw {
    margin-bottom: 56px;
  }

  .mobile-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 56px;
    box-shadow: 0px 2px 10px 0px rgba(34, 40, 47, 0.1), 0px 0px 2px 0px rgba(34, 40, 47, 0.05);
    background-color: var(--palette-color-base-white);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0.5rem 1.5rem;
  }
}
</style>
