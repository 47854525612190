const readEnv = (key, fallback = undefined) => {
  const value = process.env[`VUE_APP_${key}`];
  return value !== undefined ? value : fallback;
};

class Config {
  apiBaseUrl = readEnv('API_BASE_URL', 'http://localhost:8001/api/v1');
  apiBaseAuthUrl = readEnv('API_BASE_AUTH_URL', this.apiBaseUrl);
  features = {
    PAY_SUBSCRIPTION_LAST: readEnv('FEATURE_PAY_SUBSCRIPTION_LAST', 'true') === 'true',
  };
}

export default new Config();
