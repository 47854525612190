<template>
  <sme-box
    :title="data.payschedule.name"
    :to="{ name: 'pay-schedule', params: { payScheduleId: data.payschedule.pay_schedule_id } }"
    class="payschedules-tab"
    header-class="mb-2"
  >
    <dashboard-stats :stats="stats" class="my-2" />
    <div class="payschedules-tab__toast-container">
      <sme-toast
        :icon="currencyIcon"
        :text="{
          emphasis: getPayTypeLabel(data.payschedule.pay_schedule_code),
        }"
      />
      <sme-toast
        icon="calendar-days"
        :text="{ pre: 'Next pay day', emphasis: toFormattedDate(data.payschedule.next_payday) }"
      />
      <sme-toast
        v-if="!isUseUserCountInsteadOfEmployeesCount"
        icon="users"
        :text="{ emphasis: data.employees.length, post: 'employees' }"
        :to="{ name: 'employees', query: { [EMPLOYEE_FILTER_KEYS.PAY_SCHEDULE]: data.payschedule.pay_schedule_id } }"
      />
    </div>
  </sme-box>
</template>

<script setup>
import { computed } from 'vue';
import { getBankingInfo } from '@/Banking';
import SmeBox from '@/components/atoms/SmeBox';
import SmeToast from '@/components/atoms/SmeToast';
import useFeatureFlags from '@/composables/useFeatureFlags';
import DashboardStats from '@/pages/dashboard/components/DashboardStats';
import { FILTER_KEYS as EMPLOYEE_FILTER_KEYS } from '@/pages/employees/components/EmployeesList.vue';
import { getPayTypeLabel, pluralize } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';

const { isUseUserCountInsteadOfEmployeesCount } = useFeatureFlags();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const stats = computed(() => [
  {
    value: props.data.missedSalaries.length,
    explanation: `missed ${pluralize('salary', props.data.missedSalaries.length, 'salaries')}`,
  },
  { value: props.data.invalidBankDetails.length, explanation: 'invalid bank details' },
  { value: props.data.failedSalaries.length, explanation: 'failed salaries' },
]);
const currencyIcon = computed(() => getBankingInfo().currencyIcon);
</script>

<style lang="scss" scoped>
.payschedules-tab__toast-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
