<template>
  <div class="wrapper">
    <div class="split-view-container">
      <app-loading :loading="!props.entity" v-if="!props.entity" />
      <div class="split-view" v-else>
        <div class="left" :style="!showRightPanel && 'width: 100%;'">
          <ImagesHeader :coverUrl="props.entity.cover_image_url" :logoUrl="props.entity.logo_url" />
          <LocationInfo
            v-if="props.entity"
            :entity="props.entity"
            entity-type="site"
            modal-id="divisions-modal"
          ></LocationInfo>

          <!-- Reviews & Roles -->
          <div class="division-info-container">
            <InfoCard title="Workers' feedback" :selected="showReviews" @click="onToggleReviewsSection">
              <template slot="description">
                <StarFilled :color="PaletteColors['warning']" />
                <p>
                  {{ `${getAverageRating(reviews.data.value)}/5 (${reviews.data.value?.length}) reviews` }}
                </p>
              </template>
            </InfoCard>
            <InfoCard
              title="Roles"
              :description="(roles?.length || 0) + ' roles'"
              :selected="showRolesSection"
              @click="onToggleRolesSection"
            >
              <template slot="description">
                <p>{{ roles?.length || 0 }} roles</p>
              </template>
            </InfoCard>
          </div>
        </div>
        <div class="right" v-if="showRightPanel">
          <RolesSection
            v-if="showRolesSection"
            :roles="roles"
            @go-back="onToggleRolesSection"
            :locationId="props.entityId"
          />
          <ReviewsSection
            v-if="reviews.data.value?.length && showReviews"
            :reviews="reviews.data.value"
            @go-back="onToggleReviewsSection"
          />
        </div>
      </div>
      <EditDivisionModal :location-id="props.entityId" @on-close="fetchEntity" />
    </div>
  </div>
</template>

<script setup>
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import InfoCard from '@/pages/talent-pool/location-settings/components/InfoCard.vue';
import RolesSection from '@/pages/talent-pool/components/screen-sections/RolesSection.vue';
import ApiClient from '@/ApiClient';
import { onMounted, ref } from 'vue';
import EditDivisionModal from '@/pages/talent-pool/components/EditDivisionModal.vue';
import ReviewsSection from '@/pages/talent-pool/components/screen-sections/ReviewsSection.vue';
import AppLoading from '@/components/AppLoading.vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { PaletteColors } from '@/Theme';
import { getLocationRatings, getEntity } from '@/queries/talent-pool/locations';
import { getAverageRating } from '@/pages/talent-pool/utils/ratings';
import StarFilled from '@/assets/icons/StarFilled.vue';
import LocationInfo from '@/pages/talent-pool/location-settings/components/LocationInfo.vue';

const reviews = useQuery(getLocationRatings(props.entityId));
const queryClient = useQueryClient();

const showRightPanel = ref(false);

const roles = ref([]);
const showRolesSection = ref(false);

const showReviews = ref(false);
const props = defineProps({
  entityId: String,
  entity: { type: Object, required: false },
});

const fetchRoles = async () => {
  const { data } = await ApiClient.getTalentPoolSiteRoles(props.entityId);
  roles.value = data
    .filter(role => role.mapped_external_job_roles[0].external_job_role_id !== null)
    .sort((a, b) => a.internal_job_role_id.localeCompare(b.internal_job_role_id));
};

onMounted(async () => {
  await fetchRoles();
});

const onToggleRolesSection = () => {
  if (showRolesSection.value === true) {
    showRolesSection.value = false;
    showRightPanel.value = false;
    return;
  }
  showRolesSection.value = true;
  showReviews.value = false;
  showRightPanel.value = true;
};

const onToggleReviewsSection = () => {
  if (showReviews.value === true) {
    showReviews.value = false;
    showRightPanel.value = false;
    return;
  }
  showReviews.value = true;
  showRightPanel.value = true;
  showRolesSection.value = false;
};

const fetchEntity = async () => {
  await queryClient.fetchQuery(getEntity(props.entityId));
};
</script>

<style scoped>
.wrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.split-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.split-view {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.add-a-child {
  display: flex;
  align-items: center;
  gap: 10px;

  .plus {
    font-size: 18px;
  }
}

.left {
  padding: 1rem;
  width: 50%;
  background-color: var(--palette-color-base-white);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  overflow-y: auto;
}

.right {
  width: 50%;
  background-color: var(--palette-color-default-lighten-95);
  overflow-y: auto;
  border-left: 1px solid var(--palette-color-default-lighten-90);
}

.division-info-container {
  display: flex;
  gap: 0.75rem;

  div {
    flex: 1;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media (max-width: 900px) {
  .split-view-container {
    height: auto;
  }

  .split-view {
    display: flex;
    flex-direction: column;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }
}
</style>
