var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c(_setup.PageSubHeader,{staticClass:"mt-4",attrs:{"title":"Employee Shifts"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(_setup.AppInput,{staticClass:"text-uppercase",attrs:{"options":_setup.TIME_SERIES_VALUES,"type":"radio","buttons":"","button-variant":"outline-primary","size":"sm"},model:{value:(_setup.timeSeries),callback:function ($$v) {_setup.timeSeries=$$v},expression:"timeSeries"}})]},proxy:true}])}),(_setup.loading)?_c(_setup.SmeCard,[_c(_setup.AppLoading)],1):[(_setup.hasEmployeesData)?_c(_setup.SmeCard,{staticClass:"mb-4"},[_c(_setup.LineChart,{attrs:{"labels":_setup.timeGroupDataset,"datasets":_setup.timeGroupedEmployeesDataset,"chart-options":_setup.merge(
            {
              aspectRatio: _setup.getInitialAspectRatio(3.5, 2),
              scales: { y: { title: { text: 'Percentage (%)' } } },
              plugins: {
                legend: { display: true, position: 'right' },
                title: { display: true, text: 'Percentage of employees with shifts' },
              },
            },
            _setup.CHART_PRESET_OPTIONS.PERCENTAGE,
          )}})],1):_vm._e(),(_setup.hasHoursData)?_c(_setup.SmeCard,[_c(_setup.LineChart,{attrs:{"labels":_setup.hoursTimeGroupDataset,"datasets":_setup.timeGroupedHoursDataset,"chart-options":{
          aspectRatio: _setup.getInitialAspectRatio(3.5, 2),
          scales: { y: { title: { text: 'Hours' } } },
          plugins: {
            legend: { display: true, position: 'right' },
            title: { display: true, text: 'Average hours per employee' },
          },
        }}})],1):_vm._e(),(!_setup.hasData)?_c(_setup.SmeCard,[_c('p',{staticClass:"my-2 text-center"},[_vm._v("No data to display.")])]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }