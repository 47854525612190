<template>
  <ValidationObserver v-slot="v" slim>
    <b-form class="reset-password-form" @submit.prevent="v.handleSubmit(resetPassword)">
      <b-row>
        <img class="logo" :src="themeImage" alt="Company Logo" />
      </b-row>
      <sme-alert v-if="error" :level="errorLevel" class="mb-4">{{ error }}</sme-alert>
      <b-row>
        <b-col>
          <app-input
            v-model="password"
            :validate-immediate="false"
            label="Password"
            name="password"
            description="Must be at least 12 characters with a capital letter, number and special character"
            rules="password"
            type="password"
            mask-valid
          />
          <app-input
            v-model="confirmPassword"
            :validate-immediate="false"
            label="Confirm Password"
            name="confirm-password"
            rules="required|confirmed:password"
            type="password"
            mask-valid
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button type="submit" class="submit" variant="primary" :disabled="v.invalid || loading">Submit</b-button>
          <app-loading :loading="loading" />
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ApiClient from '@/ApiClient';

export default {
  name: 'SetPassword',
  components: {
    ValidationObserver,
    AppInput,
    AppLoading,
  },
  props: {
    themeImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      loading: false,
      error: undefined,
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      const response = await ApiClient.resetPassword(
        this.password,
        this.$route.query.reset_id,
        this.$route.query.reset_token,
      );
      if (response?.data?.is_valid) {
        // set a timeout to make it more obvious that the password has been reset
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 2000);
      } else {
        this.error = 'Unable to reset your password.';
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  object-fit: contain;
  margin-bottom: 36px;
  height: 64px;
  width: 100%;
}

.submit {
  margin-top: 24px;
  padding: 12px 16px;
  width: 100%;
}
</style>
