import { computed, onBeforeMount } from 'vue';
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { useStateComposable } from '@/state/composables';

const { computedState, state } = useStateComposable({
  divisions: [],
  division: undefined,
  error: false,
});

const divisionId = computed(() => state.division?.division_id);
const hasDivisions = computed(() => !!state.divisions.length);

const getDivisions = async () => {
  try {
    state.divisions = (await ApiClient.getDivisions())?.data || [];
  } catch (error) {
    state.error = true;
    console.error(error);
  }
};

const setDivision = division => (state.division = division);

export default () => {
  onBeforeMount(async () => {
    if (State.state.claims?.a && !state.divisionId) {
      await getDivisions();
    } else if (State.state.claims?.m && !state.divisionId) {
      state.divisions = State.state.divisions;
      setDivision(state.divisions[0]);
    }
  });

  return {
    ...computedState,
    divisionId,
    hasDivisions,
    setDivision,
  };
};
