<template>
  <app-page title="Pay" subtitle="Configure the way to pay your employees" icon="money-check-alt">
    <template v-slot:infobox>
      <sme-info-box title="What is a pay schedule?" id="about-pay-schedules" save-state>
        <p>
          Pay schedules are how we know when pay days fall, how earnings accrue, and how to assign advances to pay
          cheques.
        </p>
        <ul>
          <li>Each employee is assigned to one pay schedule.</li>
          <li>You can have multiple pay schedules as required, e.g. one for shift workers and one for salaried.</li>
        </ul>
        <template v-if="!isDisablePayscheduleEditing">
          <p>
            Pay schedules can be created via <strong>Add New Payschedule</strong> button. Individual dates can be edited
            by clicking on the pay schedule.
          </p>
          <p>
            It is important to ensure your payschedules are correct and up to date. You can modify them at any time.
          </p>
        </template>
      </sme-info-box>

      <sme-info-box
        title="What do I need to do for payroll?"
        id="about-payroll"
        save-state
        v-if="!hideStreamAccountRelatedItems"
      >
        <p>
          Wagestream is not a payroll deduction. Instead it works by processing net pay, which is more convenient for
          employers and employees.
        </p>
        <p><strong>This means that all enrolled employees must be paid into their Wagestream bank accounts</strong>.</p>
        <p>
          We will reconcile any advances and {{ bankingInfo.save.potLabel }} contributions before paying the remainder
          into employees' personal bank accounts. This process is instantaneous and completely transparent for
          employees.
        </p>

        <p>
          Unless automated by one of our <router-link :to="{ name: 'sme-integrations' }">integrations</router-link>,
          employers are responsible for keeping the bank details synchronised with their payroll system.
        </p>
      </sme-info-box>
    </template>

    <template v-slot:header>
      <b-button :to="{ name: 'sme-bank-pay' }" variant="primary" v-if="isEnableBankPayHistory">
        <font-awesome-icon :icon="['fad', 'calendar-days']" class="mr-2" />Bank Payments
      </b-button>
      <b-button :to="{ name: 'sme-off-cycle-payments' }" variant="primary" v-if="showOffCyclePayments">
        <font-awesome-icon :icon="['far', 'coins']" class="mr-2" />Off-Cycle Payments
      </b-button>
      <span
        v-if="!isDisablePayWithWagestream"
        v-b-tooltip.top
        :title="!isBankingSetup && 'You must finish setting up your account to Pay With Wagestream'"
        :disabled="isBankingSetup"
      >
        <b-button :to="{ name: 'sme-payment-run' }" variant="primary" :disabled="!isBankingSetup">
          <font-awesome-icon :icon="['fal', 'upload']" class="mr-2" />Pay with Wagestream
        </b-button>
      </span>
    </template>

    <template v-slot:custom-content>
      <b-col>
        <pay-schedules-table />
        <pay-schedules-yet-to-be-paid v-if="!hideStreamAccountRelatedItems" />
        <pay-schedules-failed-salaries v-if="!hideStreamAccountRelatedItems" />
        <pay-schedules-missed-salaries v-if="!hideStreamAccountRelatedItems" />
        <pay-schedules-invalid-bank-details v-if="!hideStreamAccountRelatedItems" />
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import { computed } from 'vue';
import { getBankingInfo } from '@/Banking';
import AppPage from '@/components/AppPage';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import PaySchedulesFailedSalaries from '@/pages/payschedules/components/PaySchedulesFailedSalaries.vue';
import PaySchedulesMissedSalaries from '@/pages/payschedules/components/PaySchedulesMissedSalaries.vue';
import PaySchedulesTable from '@/pages/payschedules/components/PaySchedulesTable.vue';
import PaySchedulesYetToBePaid from '@/pages/payschedules/components/PaySchedulesYetToBePaid.vue';
import PaySchedulesInvalidBankDetails from '@/pages/payschedules/components/PaySchedulesInvalidBankDetails.vue';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';

const { getEmployeesReport } = useEmployeesReport();
const {
  isDisablePayscheduleEditing,
  isDisablePayWithWagestream,
  isDisableOffCyclePayments,
  isEnableBankPayHistory,
  hideStreamAccountRelatedItems,
  offCyclePaymentsVersion,
} = useFeatureFlags();

const showOffCyclePayments = computed(
  () => offCyclePaymentsVersion.value === 'off' && !isDisableOffCyclePayments.value,
);

const startDate = new Date();
startDate.setDate(startDate.getDate() - 35);
const START_DATE = startDate.toISOString().split('T')[0];
const END_DATE = new Date().toISOString().split('T')[0];

const isBankingSetup = computed(() => !!State.state.company.properties?.modulr);
const bankingInfo = getBankingInfo();

getEmployeesReport(START_DATE, END_DATE);
</script>
