<template>
  <app-page
    :title="`Opt in ${employee ? employee.full_name : 'Employee'}`"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="user-plus"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning" class="mb-3">
        You are about to restore access to the app for {{ employee.full_name }}
      </sme-alert>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="reason"
            type="text"
            name="reason"
            rules="required"
            label="Reason"
            :disabled="saving || saved"
            :validate-immediate="false"
          />
          <page-sub-footer>
            <b-button type="submit" variant="primary" :disabled="v.invalid || saving || saved">
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Opting in...' : 'Opt in' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <sme-alert v-if="saved" level="good">
      <p>
        <strong>{{ employee.full_name }} has been opted back in</strong>
      </p>
      <template v-if="!integrationsState.state.integrations">
        <p>
          You will need to update your payroll system with new banking details to complete the employee's re-enrolment.
        </p>
        <template v-if="showBankingUpdate">
          <p>
            <router-link :to="{ name: 'employee-setup', params: { employeeId: employee.employee_id } }"
              >Update Banking</router-link
            >
          </p>
        </template>
      </template>
      <template v-if="reactivated">
        <p>
          The employee did not go through the opt-out process before leaving, so has been reactivated to their previous
          state.
        </p>
      </template>
      <template v-else>
        <p>The employee's re-enrolment will be completed once we have updated their banking details.</p>
      </template>
    </sme-alert>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import IntegrationsState from '@/state/IntegrationsState.js';
import State from '@/state/State.js';

export default {
  name: 'EmployeeOptIn',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
      reason: undefined,
      reactivated: false,
      state: State.state,
      integrationsState: IntegrationsState,
      showBankingUpdate: undefined,
    };
  },
  methods: {
    async onSubmit() {
      this.saving = true;

      if (!this.employee.properties?.optout_completed_at) {
        const payload = { delete_previous_banking: false };
        try {
          await ApiClient.reactivateEmployee(this.employee.employee_id, payload);
          this.reactivated = true;
          this.saved = true;
        } catch (error) {
          this.error = error.message;
        }
      } else {
        try {
          const data = await ApiClient.employeeOptIn(this.employee.employee_id, this.reason);

          if (data.ok) {
            this.saved = true;
          } else {
            this.error = `There was an error opting back in ${this.employee.full_name}`;
          }
        } catch (error) {
          this.error = error.message;
        }
      }

      this.saving = false;
      this.loading = false;
    },
    checkBanking() {
      // Refresh TODOs
      ApiClient.getUser();
      this.showBankingUpdate = this.state.todos.some(
        todo => todo.action === 'employee.setup' && todo.employee_id === this.employee.employee_id,
      );
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
