<template>
  <app-page
    title="Employee Opt Out"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="user-minus"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !success">
      <sme-alert level="warning" class="mb-3">
        You are about to remove access to the app for {{ employee.full_name }}
      </sme-alert>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="reason"
            type="text"
            name="reason"
            label="Reason for opting out"
            rules="required"
            :validate-immediate="false"
          />
          <app-input
            v-model="leaver"
            v-if="canDisableEmployees"
            type="checkbox-single"
            name="leaver"
            label="Is the employee leaving the company?"
            rules="required"
            :validate-immediate="false"
          />
          <app-input
            v-if="leaver"
            v-model="terminationDate"
            type="date"
            name="terminationDate"
            label="When is their last day of employment?"
            rules="required"
            :validate-immediate="false"
          />
          <page-sub-footer>
            <b-button type="submit" variant="danger" :disabled="v.invalid || saving">
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Opting out...' : 'Opt out' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <div v-if="success">
      <sme-alert level="good">
        <p>
          <strong>Employee has been deactivated.</strong>
        </p>
        <p>
          We have notified the employee that they no longer have access to the app. We have not shared the reason. If
          you would like to, you can email {{ employee.full_name }}
          at
          <a :href="toMailtoHref(employee.work_email)">{{ employee.work_email }}</a>
          to let them know more information.
        </p>
      </sme-alert>
      <div v-if="!completedOptOut" class="mt-3">
        <template v-if="readyToRevertBanking">
          <sme-alert level="info" class="mb-3">
            To complete opt-out you will need to change the employee's bank details in your payroll system.
          </sme-alert>
          <div class="d-flex justify-content-center">
            <router-link
              tag="button"
              class="btn btn-primary"
              :to="{ name: 'employee-revert-banking', params: { employeeId: employee.employee_id } }"
              >Complete Opt-Out</router-link
            >
          </div>
        </template>
        <sme-alert v-else level="info">
          When the employee has zero outstanding transfers you will be required to update their banking details to
          complete the opt-out process.
        </sme-alert>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { toMailtoHref } from '@/utils/common';

export default {
  name: 'EmployeeOptOut',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      error: undefined,
      saving: false,
      success: false,
      reason: undefined,
      leaver: false,
      terminationDate: undefined,
      completedOptOut: undefined,
      employee: undefined,
      state: State.state,
    };
  },
  async mounted() {
    await this.checkifEverEnrolled(this.$route.params.employeeId);
    this.loadData();
  },
  setup() {
    const { canDisableEmployees } = useFeatureFlags();

    return {
      canDisableEmployees,
    };
  },
  methods: {
    toMailtoHref,
    async onSubmit() {
      this.saving = true;

      try {
        await ApiClient.employeeOptOut(this.employee.employee_id, { reason: this.reason });

        if (!this.everEnrolled) {
          try {
            await ApiClient.employeeOptOutComplete(this.employee.employee_id);
            this.completedOptOut = true;
          } catch (error) {
            this.error = error.message;
          }
        }

        if (this.leaver) {
          try {
            await ApiClient.employeeSetTerminationDate(this.employee.employee_id, this.terminationDate);
          } catch (error) {
            this.error = error.message;
          }
        }
      } catch (error) {
        this.error = error.message;
      }

      await ApiClient.getUser();
      this.success = true;
      this.saving = false;
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
    toMailTo(email) {
      return `mailto:${email}`;
    },
    checkifEverEnrolled(employeeId) {
      ApiClient.checkEmployeeEverEnrolled(employeeId).then(
        everEnrolled => {
          this.everEnrolled = everEnrolled;
        },
        error => {
          this.error = error.message;
        },
      );
    },
  },
  computed: {
    readyToRevertBanking() {
      if (!this.employee) {
        return false;
      }
      const todos = State.state.todos;
      return todos.some(todo => todo.action === 'employee.revertbanking' && todo.fk === this.employee.employee_id);
    },
  },
};
</script>
