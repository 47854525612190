<template>
  <b-modal ref="modal" id="salary-sign-off-modal" size="lg" hide-header hide-footer no-close-on-backdrop centered>
    <div>
      <h4>Your weekly salary sign-off</h4>
      <p>
        To continue accruing salary for your employees, please confirm our records are correct. This week, you have...
      </p>
      <dashboard-stats v-if="error" :stats="salariedOnlyStat" />
      <dashboard-stats v-else :stats="employeeStats" />
      <p>
        <strong>
          If this is incorrect, please opt out any leavers immediately from the
          <router-link :to="{ name: 'employees' }">
            Employees <font-awesome-icon class="sme-box__link-icon" :icon="['fad', 'external-link']" />
          </router-link>
          page.
        </strong>
      </p>
      <b-button variant="primary" @click="() => handleClick('confirm')">Confirm</b-button>
      <b-button variant="secondary" class="ml-3" @click="() => handleClick('close')">Close</b-button>
    </div>
  </b-modal>
</template>

<script setup>
import { onBeforeMount, ref, computed } from 'vue';
import ApiClient from '@/ApiClient';
import DashboardStats from '@/pages/dashboard/components/DashboardStats';
import { pluralize } from '@/utils/common';

const error = ref(null);
const summary = ref(null);
const leavers = ref(0);
const joiners = ref(0);
const modal = ref(null);
const employeeStats = ref([]);

const salariedOnlyStat = computed(() => {
  return [
    { value: props.salariedEmployees, explanation: `salaried ${pluralize('employee', props.salariedEmployees)}` },
  ];
});

const props = defineProps({
  salariedEmployees: Number,
});
const emit = defineEmits(['confirm', 'reset']);

const handleClick = async confirmation => {
  if (confirmation === 'confirm') {
    emit('confirm');
  }
  emit('reset');
  hideModal();
};

const showModal = () => {
  modal.value.show();
};

const hideModal = () => {
  modal.value.hide();
};

const getFundingPositionSummary = async () => {
  summary.value = (await ApiClient.getFundingPositionSummary())?.data || {};
  joiners.value = summary.value?.newStarters.data?.length;
  leavers.value = summary.value?.leaversWithBalance.data?.length;
};

const setEmployeeStats = () => {
  employeeStats.value = [
    { value: props.salariedEmployees, explanation: `salaried ${pluralize('employee', props.salariedEmployees)}` },
    { value: joiners.value, explanation: `new ${pluralize('starter', joiners.value)}` },
    { value: leavers.value, explanation: `${pluralize('leaver', leavers.value)}` },
  ];
};

onBeforeMount(async () => {
  try {
    await getFundingPositionSummary();
    setEmployeeStats();
  } catch {
    error.value = 'Unable to show salary sign-off information.';
  }
  showModal();
});
</script>
