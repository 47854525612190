<template>
  <article
    class="flexible-work-schedule-shift"
    :class="`flexible-work-schedule-shift--${status.toLowerCase()}`"
    @click="opened = true"
  >
    <div class="flexible-work-schedule-shift__content">
      <h4 class="flexible-work-schedule-shift__title">
        {{ shift.role_names.join(', ') }}
      </h4>
      <ul class="flexible-work-schedule-shift__details">
        <li class="flexible-work-schedule-shift__detail">
          {{ toFormattedDate(shift.start_at, 'HH:mm') }} - {{ toFormattedDate(shift.end_at, 'HH:mm') }}
        </li>
        <li class="flexible-work-schedule-shift__detail flexible-work-schedule-shift__detail--large">
          <flexible-work-shift-status :shift="shift" :claims="claims" light />
        </li>
      </ul>
    </div>

    <b-modal v-model="opened" size="lg" body-class="p-3" header-class="pb-0" hide-footer centered>
      <flexible-work-shift :shift="shift" :claims="claims" standalone @updated="onUpdated" />
    </b-modal>
  </article>
</template>

<script setup>
import { computed, ref } from 'vue';
import FlexibleWorkShift from '@/pages/flexible-work/components/FlexibleWorkShift.vue';
import FlexibleWorkShiftStatus from '@/pages/flexible-work/components/FlexibleWorkShiftStatus.vue';
import { toFormattedDate } from '@/utils/date';
import { getShiftStatus } from '@/utils/flexible-work/Shift';

const props = defineProps({
  shift: {
    type: Object,
    required: true,
  },
  claims: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['updated']);

const opened = ref(false);

const status = computed(() => getShiftStatus(props.shift, props.claims));

const onUpdated = () => {
  opened.value = false;

  emit('updated');
};
</script>

<style lang="scss" scoped>
.flexible-work-schedule-shift {
  text-align: center;

  &:hover {
    color: var(--palette-color-default);

    :deep(.flexible-work-shift-status) {
      color: inherit;
    }
  }

  &--approved,
  &--worked {
    color: var(--palette-color-success);

    &:hover {
      color: var(--palette-color-success-darken-10);
    }
  }

  &--fulfilled {
    color: var(--palette-color-warning);

    &:hover {
      color: var(--palette-color-warning-darken-10);
    }
  }

  &--pending {
    background-color: var(--palette-color-core-1-lighten-70);
    border: 1px solid var(--palette-color-core-1-lighten-60);
    color: var(--palette-color-brand-primary);

    &:hover {
      color: var(--palette-color-brand-primary-darken-10);
    }
  }

  &--unfilled {
    opacity: 0.5;

    &:hover {
      opacity: 0.6;
    }
  }
}

.flexible-work-schedule-shift__title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.1rem;
}

.flexible-work-schedule-shift__details {
  margin: 0;
  padding-left: 0;
}

.flexible-work-schedule-shift__detail {
  font-size: 12px;
  list-style-type: none;

  &--large {
    font-size: 13px;
    margin-top: 0.375rem;
  }
}
</style>
