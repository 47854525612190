import moment from 'moment';
import { computed, ref } from 'vue';
import { TIME_SERIES, TIME_SERIES_DATE_FORMAT, TIME_SERIES_UNIT } from '@/utils/chart';

export default (times, existingTimeSeries, defaultTimeSeries) => {
  const timeSeries = existingTimeSeries || ref(defaultTimeSeries || TIME_SERIES.MONTH_TO_MONTH);
  const timeSeriesUnit = computed(() => TIME_SERIES_UNIT[timeSeries.value]);
  const timeSeriesFormat = computed(() => TIME_SERIES_DATE_FORMAT[timeSeries.value]);

  const timeGroupDataset = computed(() => {
    if (!times.value) {
      return null;
    }

    let values = times.value.values || [];

    if (times.value.calculated) {
      const lastComplete = times.value.calculated.last_complete;
      const lastCompleteIndex = times.value.values.lastIndexOf(lastComplete);

      values = times.value.values.slice(0, lastCompleteIndex + 1);
    }

    return {
      ...times.value,
      values: values.map(value => moment.unix(value).utc().format(timeSeriesFormat.value)),
    };
  });

  const timeGroupTitle = computed(() => {
    if (!times.value) {
      return '';
    }

    if (timeSeries.value === TIME_SERIES.WEEK_TO_WEEK) {
      return `For the week ${moment
        .unix(times.value.calculated.last_complete)
        .utc()
        .isoWeekday(1)
        .format('MMMM Do, YYYY')}`;
    } else {
      return `In ${moment.unix(times.value.calculated.last_complete).utc().format('MMMM')}`;
    }
  });

  const datasetToTimeGroupedDataset = (dataset, formatter = value => value) => {
    if (!dataset.value) {
      return null;
    }

    if (Array.isArray(dataset.value)) {
      return dataset.value.map(dataset => ({
        ...dataset,
        values: sliceValuesToMatchTime(dataset.values).map(value => formatter(value)),
      }));
    }

    const values = sliceValuesToMatchTime(dataset.value.values).map(value => formatter(value));

    return {
      ...dataset.value,
      calculated: {
        last: values[values.length - 1],
        prev: values[values.length - 2],
      },
      values,
    };
  };

  const getPrevDiffText = (timeGroupedDataset, formatter = value => value) => {
    if (!timeGroupedDataset.value) {
      return '';
    }

    const { last, prev } = timeGroupedDataset.value.calculated;

    if (prev === undefined) {
      return '';
    }

    const diff = formatter(Math.abs(last - prev));

    return [
      'That is',
      last === prev ? 'the same as' : `${diff} ${last >= prev ? 'more' : 'less'}`,
      `than the previous ${timeSeriesUnit.value}.`,
    ].join(' ');
  };

  const sliceValuesToMatchTime = values => values.slice(0, timeGroupDataset.value.values.length);

  return {
    timeSeries,
    timeSeriesUnit,
    timeGroupDataset,
    timeGroupTitle,
    datasetToTimeGroupedDataset,
    getPrevDiffText,
  };
};
