<template>
  <div class="streamed-wages-list">
    <sme-alert v-if="dataTruncated" level="warning" class="mb-3">
      Data has been truncated, so totals may not be accurate. Please narrow your search criteria.
    </sme-alert>
    <page-list-header>
      <template #left>
        <filter-dropdown v-model="filters" :filters="filterItems">
          <inline-search v-if="!employeeId" v-model="searchText" @search="onSearch" />
        </filter-dropdown>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="STREAM_PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          :export-data="filteredTransfers"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
          file-name="streamed-wages"
        />
      </template>
    </page-list-header>
    <component :is="componentType" class="mb-3">
      <app-loading :loading="loading" />

      <b-table
        v-if="!loading"
        :items="transfers"
        :fields="fieldDefinitions"
        filter="''"
        :filter-function="filterFunction"
        :current-page="currentPage"
        :per-page="perPage"
        class="mb-0"
        table-class="table-minimal"
        :tfoot-class="!transfers?.length ? 'd-none' : ''"
        primary-key="transfer_id"
        foot-clone
        no-footer-sorting
        responsive
        show-empty
        @filtered="update"
      >
        <template v-slot:cell(full_name)="data">
          <router-link :to="{ name: 'employee', params: { employeeId: data?.item?.employee_id } }">
            {{ data?.item?.full_name }}
          </router-link>
        </template>
        <template v-slot:foot()>&nbsp;</template>
        <template v-slot:foot(net_amount)>{{ totalNetAmount }}</template>
      </b-table>
    </component>

    <b-pagination
      v-model="currentPage"
      :total-rows="filteredTransfers.length"
      :per-page="perPage"
      class="justify-content-end"
    />
  </div>
</template>

<script setup>
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import {
  STREAM_FILTER_KEYS,
  STREAM_SEARCH_FIELDS,
  STREAM_API_FILTER_KEYS,
  STREAM_FIELD_DEFINITIONS,
  STREAM_FIELD_KEYS,
  STREAM_PER_PAGE_OPTIONS,
} from '@/pages/streamed-wages/constants';
import usePayschedules from '@/state/composables/usePayschedules';
import { toCurrency } from '@/utils';
import { EMPLOYEE_PAY_TYPE } from '@/utils/Employee';
import { toTitleCase } from '@/utils/common';
import debounceAsync from '@/utils/debounceAsync';

const { getPayschedules, payschedules } = usePayschedules();

const STARTS_ON_DEFAULT_VALUE = moment().startOf('month').format('YYYY-MM-DD');
const ENDS_ON_DEFAULT_VALUE = moment().format('YYYY-MM-DD');

const props = defineProps({
  nested: {
    type: Boolean,
    default: false,
  },
  employeeId: {
    type: String,
  },
  filterKeys: {
    type: Array,
    default: () => Object.values(STREAM_FILTER_KEYS),
  },
  fieldKeys: {
    type: Array,
  },
});

const transfers = ref([]);
const filteredTransfers = ref([]);
const divisions = ref([]);
const employeeFilters = ref(null);
const loading = ref(true);
const initialized = ref(false);
const filters = ref({
  [STREAM_FILTER_KEYS.STARTS_ON]: STARTS_ON_DEFAULT_VALUE,
  [STREAM_FILTER_KEYS.ENDS_ON]: ENDS_ON_DEFAULT_VALUE,
});
const searchCriteria = ref('');
const searchText = ref('');
const currentPage = ref(1);
const perPage = ref(STREAM_PER_PAGE_OPTIONS[0]);

const hasSavingsWithdrawals = computed(() => transfers.value.some(transfer => transfer.properties.safestream));
const dataTruncated = computed(() => filteredTransfers.value.length >= 10000);

const fieldDefinitions = computed(() => {
  // If there are no savings withdrawals, hide the type column as it is unnecessary
  const fields = hasSavingsWithdrawals.value
    ? STREAM_FIELD_DEFINITIONS
    : STREAM_FIELD_DEFINITIONS.filter(({ key }) => key !== 'type');

  return fields.filter(field => (props.fieldKeys || Object.values(STREAM_FIELD_KEYS)).includes(field.key));
});

const exportHeaders = computed(() => fieldDefinitions.value.map(field => field.label));

const filterItems = computed(() =>
  [
    {
      key: STREAM_FILTER_KEYS.STARTS_ON,
      label: 'From',
      type: CONTROL_TYPES.DATE,
      defaultValue: STARTS_ON_DEFAULT_VALUE,
      controlProps: {
        max: filters.value[STREAM_FILTER_KEYS.ENDS_ON] ? filters.value[STREAM_FILTER_KEYS.ENDS_ON] : null,
      },
    },
    {
      key: STREAM_FILTER_KEYS.ENDS_ON,
      label: 'To',
      type: CONTROL_TYPES.DATE,
      defaultValue: ENDS_ON_DEFAULT_VALUE,
      controlProps: {
        min: filters.value[STREAM_FILTER_KEYS.STARTS_ON] ? filters.value[STREAM_FILTER_KEYS.STARTS_ON] : null,
      },
    },
    {
      key: STREAM_FILTER_KEYS.SALARY_OR_HOURLY,
      label: 'Pay Type',
      type: CONTROL_TYPES.RADIO,
      options: Object.values(EMPLOYEE_PAY_TYPE).map(value => ({ text: toTitleCase(value), value })),
    },
    {
      key: STREAM_FILTER_KEYS.PAY_SCHEDULE,
      label: 'Pay Schedule',
      type: CONTROL_TYPES.RADIO,
      options: payschedules.value.map(payschedule => ({
        text: payschedule.name,
        value: payschedule.pay_schedule_id,
      })),
      hidden: !payschedules.value.length,
    },
    {
      key: STREAM_FILTER_KEYS.DIVISION,
      label: 'Division',
      type: CONTROL_TYPES.CHECKBOX,
      options: divisions.value.map(division => ({
        text: division.name,
        value: division.division_id,
      })),
      hidden: !divisions.value.length,
    },
  ].filter(filter => props.filterKeys.includes(filter.key)),
);

const componentType = computed(() => (props.nested ? 'div' : SmeCard));

const appliedApiFilters = computed(() => omitBy(pick(filters.value, STREAM_API_FILTER_KEYS), value => isNil(value)));

const filterConditions = computed(() => {
  const conditions = [];

  if (searchCriteria.value) {
    conditions.push(transfer => {
      return Object.values(pick(transfer, STREAM_SEARCH_FIELDS)).some(value =>
        value.toLowerCase().startsWith(searchCriteria.value.toLowerCase()),
      );
    });
  }

  if (filters.value[STREAM_FILTER_KEYS.SALARY_OR_HOURLY]) {
    conditions.push(
      transfer =>
        employeeFilters.value.salary_or_hourly?.[filters.value[STREAM_FILTER_KEYS.SALARY_OR_HOURLY]]?.includes(
          transfer?.employee_id,
        ) || false,
    );
  }

  if (filters.value[STREAM_FILTER_KEYS.DIVISION]?.length) {
    conditions.push(transfer => {
      return filters.value[STREAM_FILTER_KEYS.DIVISION].some(divisionFilter => {
        const divisionName = divisions.value.find(division => division.division_id === divisionFilter)?.name;

        return employeeFilters.value.division_name?.[divisionName]?.includes(transfer?.employee_id) || false;
      });
    });
  }

  return conditions;
});

const totalNetAmount = computed(() => toCurrency(sumBy(filteredTransfers.value, transfer => transfer.net_amount)));

watch(transfers, transfers => update(transfers.filter(filterFunction)));

const getTransfers = debounceAsync(async () => {
  const params = props.employeeId
    ? { ...appliedApiFilters.value, employee_id: props.employeeId }
    : appliedApiFilters.value;
  const rawTransfers = (await ApiClient.getTransfers(params))?.data || [];
  transfers.value = rawTransfers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
}, 200);

const getDivisions = async () => (divisions.value = (await ApiClient.getDivisions())?.data || []);

const getEmployeeFilters = async () =>
  (employeeFilters.value = (await ApiClient.getFilters())?.filters_employees || {});

const update = transfers => {
  filteredTransfers.value = transfers;
  currentPage.value = 1;
};

const filterFunction = item =>
  filterConditions.value.length ? filterConditions.value.every(condition => condition(item)) : true;

const onSearch = () => (searchCriteria.value = searchText.value);

const exportFileRows = item =>
  fieldDefinitions.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );

onBeforeMount(async () => {
  watch(
    appliedApiFilters,
    async (newFilters, prevFilters) => {
      loading.value = true;

      if (!initialized.value) {
        await Promise.all([getTransfers(), getDivisions(), getPayschedules(), getEmployeeFilters()]);
      } else {
        if (!isEqual(newFilters, prevFilters)) {
          await getTransfers();
        }
      }

      loading.value = false;
      initialized.value = true;
    },
    {
      deep: true,
      immediate: true,
    },
  );
});
</script>
