<template>
  <div class="role-card">
    <div class="role" v-b-toggle="'role-card' + props.role.internal_job_role_id">
      <font-awesome-icon :icon="['fas', 'angle-right']" class="when-closed icon" />
      <font-awesome-icon :icon="['fas', 'angle-down']" class="when-opened icon" />
      {{ props.role.mapped_external_job_roles.map(role => role.name).join(', ') }}
    </div>
    <b-collapse :id="'role-card' + props.role.internal_job_role_id">
      <div class="title">Uniform</div>
      <div class="uniform">
        <div class="uniform-item">
          <img :src="themeImage('shirt.svg')" />
          <span class="uniform-title">Top</span>
          <div v-if="canEdit">
            <b-form-input class="mb-2" v-model="topOverride" />
            <b-form-checkbox v-model="topProvided">Provided</b-form-checkbox>
          </div>
          <span v-else>{{ topProvided ? 'Provided' : topOverride }}</span>
        </div>
        <div class="uniform-item">
          <img :src="themeImage('trousers.svg')" />
          <span class="uniform-title">Trousers</span>
          <div v-if="canEdit">
            <b-form-input class="mb-2" v-model="trousersOverride" />
            <b-form-checkbox v-model="trousersProvided">Provided</b-form-checkbox>
          </div>
          <span v-else>{{ trousersProvided ? 'Provided' : trousersOverride }}</span>
        </div>
        <div class="uniform-item">
          <img :src="themeImage('shoes.svg')" />
          <span class="uniform-title">Shoes</span>
          <div v-if="canEdit">
            <b-form-input class="mb-2" v-model="shoesOverride" />
            <b-form-checkbox v-model="shoesProvided">Provided</b-form-checkbox>
          </div>
          <span v-else>{{ shoesProvided ? 'Provided' : shoesOverride }}</span>
        </div>
      </div>
      <div class="title">Description</div>
      <b-form-textarea v-if="canEdit" v-model="descriptionOverride" />
      <div v-else class="description">{{ descriptionOverride }}</div>
      <div
        v-for="external_role in props.role.external_roles"
        :key="external_role.external_job_role_id"
        class="role internal-role"
      >
        <!-- <font-awesome-icon :icon="['fas', 'angle-right']" /> -->
        {{ external_role.role }}
      </div>

      <div class="button-cont" v-if="!canEdit">
        <b-button variant="primary" @click="canEdit = true">Edit</b-button>
      </div>
      <div class="button-cont" v-if="canEdit">
        <b-button variant="outline-primary" :disabled="saving" @click="canEdit = false">Cancel</b-button>
        <b-button variant="primary" :disabled="saving" @click="saveChanges">Save changes</b-button>
      </div>
    </b-collapse>
  </div>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import { themeImage } from '@/Theme';
import { ref } from 'vue';

const props = defineProps({
  role: Object,
  locationId: String,
});

const getUniformItem = item => {
  const uniform =
    props?.role.overrides?.uniform?.length > 0 ? props?.role.overrides?.uniform : props?.role.defaults?.uniform;
  return uniform?.find(i => i.item === item) || '';
};

const canEdit = ref(false);
const saving = ref(false);
const descriptionOverride = ref(props?.role.overrides?.description || props?.role.defaults?.description || '');
const topOverride = ref(getUniformItem('Shirt').value);
const topProvided = ref(getUniformItem('Shirt').is_provided);
const trousersOverride = ref(getUniformItem('Trousers').value);
const trousersProvided = ref(getUniformItem('Trousers').is_provided);
const shoesOverride = ref(getUniformItem('Shoes').value);
const shoesProvided = ref(getUniformItem('Shoes').is_provided);

const saveChanges = async () => {
  saving.value = true;
  await ApiClient.saveTalentPoolSiteRoleOverrides(props.locationId, props.role.internal_job_role_id, {
    description: descriptionOverride.value,
    uniform: [
      { item: 'Shirt', value: topOverride.value, is_provided: topProvided.value },
      { item: 'Trousers', value: trousersOverride.value, is_provided: trousersProvided.value },
      { item: 'Shoes', value: shoesOverride.value, is_provided: shoesProvided.value },
    ],
  });
  saving.value = false;
  canEdit.value = false;
};
</script>

<style scoped>
.role-card {
  padding: 1rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 12px;
  background-color: var(--palette-color-base-white);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.role {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.internal-role {
  gap: 5px;
  border: 1px solid var(--palette-color-default-lighten-90);
  padding: 6px;
  border-radius: 4px;
}

.icon {
  margin-right: 10px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-top: 8px;
}

.uniform {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--palette-color-default-lighten-30);
  font-size: 14px;
  margin: 6px 0;
}

.uniform-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--palette-color-default-lighten-90);
  padding: 16px;
  border-radius: 8px;
  flex-grow: 1;
}

.uniform-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  margin: 4px 0;
  color: var(--palette-color-default);
}

.read-more {
  color: var(--palette-color-brand-primary);
  font-weight: bold;
  margin: 1rem 0;
}

.description {
  margin: 6px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.button-cont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
  gap: 6px;
}

@media (max-width: 800px) {
  header .inline-header pre {
    width: 0;
    visibility: collapse;
  }
}
</style>
