<template>
  <div class="brand-container">
    <div class="brand">
      <img :src="props.brand.logo_url" class="logo" />
      <div class="name">{{ props.brand.name }}</div>
      <b-button
        variant="outline-primary"
        class="edit-brand-button"
        v-b-modal="`divisions-modal-${props.brand.entity_id}`"
        ><Edit :color="PaletteColors['brand-primary']" width="16px" height="16px"></Edit> Edit brand</b-button
      >
    </div>
    <div class="sites-container">
      <EntityCard
        v-for="childSite in props.brand.children"
        :key="childSite.name"
        :name="childSite.name"
        :entity-id="childSite.entity_id"
        :cover-url="childSite.cover_image_url"
        :rating="childSite.rating"
      />
      <AddSiteButton :modal-id="`add-division-modal-${props.brand.entity_id}`" />
    </div>
    <EditDivisionModal
      @on-close="fetchEntity"
      :location-id="props.brand.entity_id"
      :modal-id="`divisions-modal-${props.brand.entity_id}`"
    />
    <AddDivisionModal
      @on-close="fetchEntity"
      :location-id="props.brand.entity_id"
      :modal-id="`add-division-modal-${props.brand.entity_id}`"
    ></AddDivisionModal>
  </div>
</template>

<script setup>
import AddDivisionModal from '@/pages/talent-pool/components/AddDivisionModal.vue';
import { PaletteColors } from '@/Theme';
import Edit from '@/assets/icons/Edit.vue';
import EntityCard from '@/pages/talent-pool/location-settings/components/EntityCard.vue';
import AddSiteButton from '@/pages/talent-pool/location-settings/components/AddSiteButton.vue';
import EditDivisionModal from '@/pages/talent-pool/components/EditDivisionModal.vue';

const props = defineProps(['brand']);

const emit = defineEmits(['on-close']);

const fetchEntity = async () => {
  emit('on-close');
};
</script>

<style scoped>
.brand-container {
  display: flex;
}

.brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
}

.name {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  margin: 0.5rem 0;
}

.sites-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 20rem));
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
}

.logo {
  object-fit: contain;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid var(--palette-color-default-lighten-90);
  background-color: var(--palette-color-base-white);
  display: flex;
  align-items: left;
  justify-content: left;
}

.edit-brand-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem 0;

  &:hover {
    svg {
      fill: var(--palette-color-base-white);
    }
  }
}
</style>
