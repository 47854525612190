<template>
  <Dialog modalId="next-steps-modal" size="lg" title="Time to take the next step" okBtnLabel="I'll do this">
    <template #content>
      <div class="tabs">
        <div @click="tabToOpen = TABS.ATS" :class="['tab', tabToOpen === TABS.ATS && 'active']">Make an offer</div>
        <div @click="tabToOpen = TABS.RAISE" :class="['tab', tabToOpen === TABS.RAISE && 'active']">
          Use Raise to set up interviews
        </div>
        <div @click="tabToOpen = TABS.CONTACT" :class="['tab', tabToOpen === TABS.CONTACT && 'active']">
          Continue in your ATS system
        </div>
      </div>
      <div class="tab-content">
        <div class="hire-content" v-if="tabToOpen === TABS.ATS">
          <p>You can continue the hiring process through your own system.</p>
          <p>
            We will provide you the names and contact details of the candidates and you can contact them to schedule
            interviews and make offers.
          </p>
          <b-button variant="outline-primary" @click="showContactDetails = true" v-if="!showContactDetails"
            >View contact details</b-button
          >
          <div v-if="showContactDetails">
            <p class="font-weight-bold">Here are the contact details of the top candidates</p>
            <div class="contact-details">
              <div v-for="contact in props.contactDetails" :key="contact.name" class="contact-item">
                <div class="name">{{ contact.name }}</div>
                <div class="detail">{{ contact.email }}</div>
                <div class="detail">{{ contact.phone }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="hire-content" v-if="tabToOpen === TABS.RAISE">
          <p>
            Create trial shifts and interviews through us. We'll handle the scheduling and reminders, and you can make
            your offer after you've met the candidates.
          </p>
          <p>
            Trial shifts allow you to see a candidate in action, while interviews are typically shorter and more focused
            on getting to know the candidate.
          </p>
          <p>
            You can select which of the candidates you want to invite to a trial shift or interview, and they can book a
            slot from the options you provide.
          </p>
          <p>Once you've met the candidates, you can make an offer to the one you want to hire.</p>
        </div>
        <div class="hire-content" v-if="tabToOpen === TABS.CONTACT">
          <p>If you're ready to hire, or just want to chat with the candidates, you can contact them directly now.</p>
          <b-button variant="outline-primary" @click="showContactDetails = true" v-if="!showContactDetails"
            >View contact details</b-button
          >
          <div v-if="showContactDetails">
            <p class="font-weight-bold">Here are the contact details of the top candidates</p>
            <div class="contact-details">
              <div v-for="contact in props.contactDetails" :key="contact.name" class="contact-item">
                <div class="name">{{ contact.name }}</div>
                <div class="detail">{{ contact.email }}</div>
                <div class="detail">{{ contact.phone }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import Dialog from '@/components/Dialog.vue';

const TABS = {
  ATS: 'ATS',
  RAISE: 'RAISE',
  CONTACT: 'CONTACT',
};

const props = defineProps({
  tabToOpen: String,
  contactDetails: Array,
});

const showContactDetails = ref(false);
const tabToOpen = ref(props.tabToOpen || TABS.ATS);
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  border-bottom: 2px solid var(--palette-color-core-1-lighten-50);
}

.tab {
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background-color: var(--palette-color-core-1-lighten-50);
  }
}

.tab.active {
  background-color: var(--palette-color-core-1-lighten-50);
}

.tab-content {
  padding: 1rem;
}

.hire-content {
  font-size: 14px;
}

.name {
  font-weight: 600;
}

.contact-details {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
