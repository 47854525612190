export const DB_SALARY_SERVICE_ADDENDUM = {
  id: 'a2c0a4b4-950b-43f4-bdac-99a7a20918ef',
  companyProperty: 'should_accept_salary_service_addendum_db',
  userProperty: 'salary_service_addendum_dismissed_at',
  getText: termsUrl =>
    `Please click <strong>'I accept'</strong> to accept the terms of the new <a href='${termsUrl}' target='_blank'>Salary Service Addendum</a>. This Salary Service Addendum supersedes any previous Salary Service Addendum or equivalent agreement entered into between Fourth (in its own right and as agent for and on behalf of Wagestream) and you relating to the Wagestream Service.`,
};

export const DB_TERMS_OF_BUSINESS_AMENDMENT = {
  id: '20A6EE87-277C-41C9-9C52-2B2A084F4CAF',
  companyProperty: 'should_accept_db_terms_of_business_amendment',
  userProperty: 'db_terms_of_business_amendment_dismissed_at',
  getText: termsUrl =>
    `Please review and click <strong>'I accept'</strong> to accept our new <a href='${termsUrl}' target='_blank'>Terms of Business.</a>.`,
};

export const HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT = {
  id: 'E4A96A2F-9524-49DC-B606-44EBF9F18AC5',
  companyProperty: 'should_accept_healthcare_terms_of_business_amendment',
  userProperty: 'healthcare_terms_of_business_amendment_dismissed_at',
  getText: termsUrl =>
    `Please review and click <strong>'I accept'</strong> to accept our new <a href='${termsUrl}' target='_blank'>Terms of Business.</a>.`,
};

export const SME_TERMS_OF_BUSINESS_AMENDMENT = {
  id: 'EA4DFD6E-3DE5-4E70-BBA5-5AC0DA5B5069',
  companyProperty: 'should_accept_sme_terms_of_business_amendment',
  userProperty: 'sme_terms_of_business_amendment_dismissed_at',
  getText: termsUrl =>
    `Please review and click <strong>'I accept'</strong> to accept our new <a href='${termsUrl}' target='_blank'>Terms of Business.</a>.`,
};
