<template>
  <sme-card v-if="!loading" class="sme-payment-run-bulk-upload">
    <sme-alert v-if="!isBankingSetup" level="danger">
      You cannot create a payment run until you have set up banking.
    </sme-alert>

    <sme-alert v-else-if="employeesCount === 0" level="danger">
      You cannot create a payment run without
      <sme-button-link :to="{ name: 'employees' }" inherit>employees</sme-button-link>.
    </sme-alert>

    <template v-else-if="uploadError">
      <sme-alert level="danger">
        We were unable to create the payment run due to the following error: <br />
        {{ uploadError }}
      </sme-alert>
      <page-sub-footer>
        <b-button variant="outline-primary" @click="reset"> Import another file </b-button>
      </page-sub-footer>
    </template>

    <template v-else-if="paymentRun">
      <sme-alert level="good" large>
        <p class="mb-3">
          Payment run created successfully. Here is your reference and instructions to make the bulk payment.
        </p>
        <dl class="sme-payment-run-bulk-upload__reference">
          <dt>Reference</dt>
          <dd>{{ paymentRun.reference }}</dd>
          <dt>Amount to send</dt>
          <dd>{{ toCurrency(paymentRun.amount * 100) }}</dd>
          <dt>Bank account</dt>
          <dd>{{ paymentRun.account_number }}</dd>
          <dt>Sort code</dt>
          <dd>{{ paymentRun.sort_code }}</dd>
        </dl>
      </sme-alert>
      <page-sub-footer>
        <b-button variant="outline-primary" @click="reset"> Import another file </b-button>
      </page-sub-footer>
    </template>

    <template v-else-if="fileData.length">
      <page-sub-header title="Confirm your payment run" title-tag="h2" class="mb-3" />
      <sme-alert level="good" class="mb-3">
        Everything looks good. Please confirm the following details are correct.
      </sme-alert>
      <b-table-lite
        :fields="UPLOAD_FIELD_SCHEMA"
        :items="fileData"
        table-class="table-minimal"
        thead-tr-class="large"
        responsive
        small
      >
        <template #cell(net_salary)="row">
          {{ toCurrency(row.item.net_pay_pence) }}
        </template>
      </b-table-lite>
      <b-form-checkbox v-model="payImmediate" name="pay-immediate">
        Check this box if you want to pay immediately without waiting for pay day
      </b-form-checkbox>
      <page-sub-footer>
        <b-button v-b-modal="'confirm-cancel-modal'" variant="outline-danger" :disabled="uploading"> Cancel </b-button>
        <b-button variant="primary" :disabled="uploading" @click="uploadPaymentRun">
          <b-spinner v-if="uploading" class="mr-2" small />
          {{ uploading ? 'Confirming...' : 'Confirm' }}
        </b-button>
      </page-sub-footer>
    </template>

    <bulk-upload
      v-else
      name="payment"
      :schema="VALIDATE_FIELD_SCHEMA"
      :post-function="ApiClient.validatePaymentRunCSV"
      @uploaded="handleCSVValidationResponse"
      emit
    />

    <b-modal id="confirm-cancel-modal" ok-title="Confirm" ok-variant="danger" centered hide-header @ok="reset">
      Are you sure you want to cancel this payment run?
    </b-modal>
  </sme-card>
</template>

<script>
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import BulkUpload from '@/components/BulkUpload.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeButtonLink from '@/components/atoms/SmeButtonLink.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import { toCurrency } from '@/utils';
import { unFormatCurrency } from '@/utils/common';

const VALIDATE_FIELD_SCHEMA = [
  {
    key: 'employee_code',
    label: 'employee_code',
    description: 'The employee code',
    example: 'WS-00001',
    type: 'Text',
    required: true,
  },
  {
    key: 'net_pay',
    label: 'net_pay',
    description: 'The net pay to be transferred in GBP',
    example: '1502.36',
    type: 'Currency',
    required: true,
    formatter: unFormatCurrency,
    validator: value => !isNaN(value),
  },
];

const UPLOAD_FIELD_SCHEMA = [
  {
    key: 'employee_code',
    label: 'Employee Code',
  },
  {
    key: 'employee_name',
    label: 'Employee Name',
  },
  {
    key: 'net_salary',
    label: 'Net Pay',
  },
];

const FILENAME = 'payment-run.csv';

export default {
  name: 'PayWithWagestreamBulkUpload',
  components: {
    BulkUpload,
    PageSubFooter,
    PageSubHeader,
    SmeAlert,
    SmeButtonLink,
    SmeCard,
  },
  emits: ['created'],
  mixins: [],
  data() {
    return {
      VALIDATE_FIELD_SCHEMA,
      UPLOAD_FIELD_SCHEMA,
      ApiClient,
      fileContent: undefined,
      fileData: [],
      payImmediate: false,
      paymentRun: undefined,
      uploading: false,
      uploadError: '',
    };
  },
  computed: {
    isBankingSetup() {
      return !!State.state.company?.properties?.modulr;
    },
  },
  setup() {
    const { employeesCount, getEnrolmentSnapshot, employeeStateCountsLoading } = useEmployees();

    getEnrolmentSnapshot();

    return { employeesCount, loading: employeeStateCountsLoading };
  },
  methods: {
    toCurrency,
    handleCSVValidationResponse({ content, data }) {
      this.fileContent = content;
      this.fileData = data;

      tracker.trackEngagement('pay_with_wagestream_upload');
    },
    async uploadPaymentRun() {
      this.uploading = true;

      try {
        this.paymentRun = await ApiClient.uploadPaymentRunCSV(
          State.state.company.company_id,
          FILENAME,
          this.fileContent,
          this.payImmediate ? true : '',
        );

        this.$emit('created');
      } catch (error) {
        this.uploadError = error.message;
      } finally {
        this.uploading = false;

        tracker.trackEngagement('pay_with_wagestream_submit');
      }
    },
    reset() {
      this.fileContent = undefined;
      this.fileData = [];
      this.payImmediate = false;
      this.paymentRun = undefined;
      this.uploadError = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.sme-payment-run-bulk-upload {
  :deep(table) {
    tr {
      &.large {
        th {
          padding-bottom: 0.6rem;
          padding-top: 0.6rem;
        }
      }
    }
  }
}

.sme-payment-run-bulk-upload__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.sme-payment-run-bulk-upload__reference {
  margin-bottom: -0.5rem;
  overflow: hidden;

  @media (min-width: 576px) {
    column-count: 2;
    column-gap: 1.5rem;
  }

  dt {
    font-weight: 400;
  }

  dd {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
}
</style>
