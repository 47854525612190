import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faCalendarDays as fasCalculatorDays,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faSterlingSign as fasSterlingSign,
  faDollarSign as fasDollarSign,
  faFileLines as fasFileLines,
  faStar as fasStar,
  faMoneyBillTransfer as fasMoneyBillTransfer,
  faUserShield as fasUserShield,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAnalytics as fadAnalytics,
  faBars as fadBars,
  faCalendarAlt as fadCalendarAlt,
  faCalendarCirclePlus as fadCalendarCirclePlus,
  faChartLine as fadChartLine,
  faChartSimple as fadChartSimple,
  faChartNetwork as fadChartNetwork,
  faClipboardList as fadClipboardList,
  faCogs as fadCogs,
  faDonate as fadDonate,
  faDownload as fadDownload,
  faDownLeftAndUpRightToCenter as fadDownLeftAndUpRightToCenter,
  faEnvelope as fadEnvelope,
  faExchange as fadExchange,
  faExternalLink as fadExternalLink,
  faEye as fadEye,
  faFileInvoiceDollar as fadFileInvoiceDollar,
  faInfoCircle as fadInfoCircle,
  faList as fadList,
  faLockOpen as fadLockOpen,
  faMobileScreenButton as fadMobileScreenButton,
  faMoneyCheckAlt as fadMoneyCheckAlt,
  faPause as fadPause,
  faPenToSquare as fadPenToSquare,
  faPlay as fadPlay,
  faQuestion as fadQuestion,
  faReceipt as fadReceipt,
  faRepeat as fadRepeat,
  faSitemap as fadSitemap,
  faTrash as fadTrash,
  faUniversity as fadUniversity,
  faUser as fadUser,
  faUserCircle as fadUserCircle,
  faUserEdit as fadUserEdit,
  faUserMinus as fadUserMinus,
  faUserPlus as fadUserPlus,
  faUsers as fadUsers,
  faUserClock as fadUserClock,
  faUsersCog as fadUsersCog,
  faChartPie as fadChartPie,
  faUserGroup as fadUserGroup,
  faBan as fadBan,
  faRotate as fadRotate,
  faUpload as fadUpload,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCalendarDay as falCalendarDay,
  faCheck as falCheck,
  faChevronDown as falChevronDown,
  faChevronRight as falChevronRight,
  faChevronUp as falChevronUp,
  faCircleCheck as falCircleCheck,
  faCircleXmark as falCircleXmark,
  faCloudUpload as falCloudUpload,
  faCog as falCog,
  faCopy as falCopy,
  faExclamationTriangle as falExclamationTriangle,
  faEye as falEye,
  faHand as falHand,
  faHandBackFist as falHandBackFist,
  faHandHolding as falHandHolding,
  faHandHoldingDollar as falHandHoldingDollar,
  faInfoCircle as falInfoCircle,
  faMailBulk as falMailBulk,
  faMinusCircle as falMinusCircle,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faQuestionCircle as falQuestionCircle,
  faReceipt as falReceipt,
  faSearch as falSearch,
  faSync as falSync,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faUndo as falUndo,
  faUpload as falUpload,
  faUserSlash as falUserSlash,
  faBan as falBan,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCalendar,
  faCheck,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faCoins,
  faCreditCard,
  faExchange,
  faExclamationCircle,
  faEye,
  faHand,
  faGaugeMax,
  faLocationDot,
  faMinusCircle,
  faMobileAlt,
  faPlusCircle,
  faTag,
  faUser,
  faUserSlash,
  faHandBackFist,
  faHandHolding,
  faHandHoldingDollar,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAnalytics as fasAnalytics,
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faAngleRight as fasAngleRight,
  faArrowDownToLine as fasArrowDownToLine,
  faComment as fasComment,
  faEnvelope as fasEnvelope,
  faFile as fasFile,
  faInfo as fasInfo,
  faLink as fasLink,
  faCircle as fasCircle,
  faPause as fasPause,
  faShareSquare as fasShareSquare,
  faTimes as fasTimes,
  faUserTimes as fasUserTimes,
  faUsers as fasUsers,
  faVideo as fasVideo,
  faRotate as fasRotate,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  // duotone
  fadAnalytics,
  fadBars,
  fadCalendarAlt,
  fadCalendarCirclePlus,
  fadChartLine,
  fadChartSimple,
  fadChartNetwork,
  fadClipboardList,
  fadCogs,
  fadDonate,
  fadDownload,
  fadDownLeftAndUpRightToCenter,
  fadEnvelope,
  fadExchange,
  fadExternalLink,
  fadEye,
  fadFileInvoiceDollar,
  fadInfoCircle,
  fadList,
  fadLockOpen,
  fadMobileScreenButton,
  fadMoneyCheckAlt,
  fadPause,
  fadPenToSquare,
  fadPlay,
  fadQuestion,
  fadReceipt,
  fadRepeat,
  fadSitemap,
  fadTrash,
  fadUniversity,
  fadUser,
  fadUserCircle,
  fadUserEdit,
  fadUserMinus,
  fadUserPlus,
  fadUsers,
  fadUserClock,
  fadUsersCog,
  fadChartPie,
  fadUserGroup,
  fadBan,
  fadRotate,
  fadUpload,

  // light
  falCalendarDay,
  falCheck,
  falChevronDown,
  falChevronRight,
  falChevronUp,
  falCircleCheck,
  falCircleXmark,
  falCloudUpload,
  falCog,
  falCopy,
  falExclamationTriangle,
  falEye,
  falHand,
  falHandBackFist,
  falHandHolding,
  falHandHoldingDollar,
  falInfoCircle,
  falMailBulk,
  falMinusCircle,
  falPlus,
  falPlusCircle,
  falQuestionCircle,
  falReceipt,
  falSearch,
  falSync,
  falTimes,
  falTimesCircle,
  falUndo,
  falUpload,
  falUserSlash,
  falBan,

  // regular
  faCalendar,
  faCheck,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faCoins,
  faCreditCard,
  faExchange,
  faExclamationCircle,
  faEye,
  faHand,
  faHandBackFist,
  faHandHolding,
  faHandHoldingDollar,
  faGaugeMax,
  faLocationDot,
  faMinusCircle,
  faMobileAlt,
  faPlusCircle,
  faTag,
  faUser,
  faUserSlash,

  // solid
  fasAnalytics,
  fasAngleDown,
  fasAngleUp,
  fasAngleRight,
  fasArrowLeft,
  fasArrowRight,
  fasArrowDownToLine,
  fasCalculatorDays,
  fasCircleExclamation,
  fasCircleCheck,
  fasCircleInfo,
  fasComment,
  fasCircle,
  fasDollarSign,
  fasEnvelope,
  fasFile,
  fasInfo,
  fasLink,
  fasPause,
  fasShareSquare,
  fasSterlingSign,
  fasRotate,
  fasTimes,
  fasUserTimes,
  fasUsers,
  fasVideo,
  fasFileLines,
  fasStar,
  fasMoneyBillTransfer,
  fasUserShield,
);
