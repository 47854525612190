import { createTour, createTourButton, createTourText } from '@/utils/Tour';

export const tour = createTour();

tour.addSteps([
  {
    title: 'Ready to set-up?',
    text: createTourText([
      'Before you can launch to your team, there are just a few easy steps we need you to complete first...',
    ]),
    buttons: [createTourButton('Start a quick tour', tour.next)],
  },
  {
    text: createTourText([
      'Every time you log in, all of your urgent tasks will sit here.',
      'It is currently populated with the steps you need to complete before your employees can start using the app.',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      'First, we need you to set up your subscription. There are three pricing plans to choose from, each with their own unique benefits.',
      'Once this is complete, we can start setting up your account...',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.subscription.setup"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      'We need you to add the pay schedule(s) that your employees are on. These need to be correct in order for your employees to be able to use the app.',
      'Once this is complete, we can then start syncing your employees and their shifts...',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.configure.payschedule"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      'If you use one of the time and attendance providers we integrate with, you can select them from within this section to automatically import your employees and their shifts.',
      'If not, no worries, you can import your employees and their shifts via CSV.',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.add.employees"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      'Next, we need to verify the identity of your directors.',
      'If this is you, you can upload your proof of identity directly, alternatively you can send instructions via email once you click this button too.',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.kyc.pending"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      'To enable your employees to use Flexible Pay, you will need to fund your account.',
      'Each payday, we automatically reimburse this account with the amount your employees have accessed during the last pay period.',
    ]),
    buttons: [createTourButton('Previous', tour.back, 'btn btn-outline-primary'), createTourButton('Next', tour.next)],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.fund.pending"]',
      on: 'top',
    },
  },
  {
    text: createTourText([
      '...and just like that, you’re ready to launch.',
      'Click to invite your team (you even have the option of WhatsApp invites) and start enjoying the numerous benefits of our financial tools and services.',
      'P.s If 50% of your employees sign-up, you automatically unlock premium shopping discounts at over 100 high street brands.',
    ]),
    buttons: [
      createTourButton('Previous', tour.back, 'btn btn-outline-primary'),
      createTourButton('Finish', tour.cancel),
    ],
    attachTo: {
      element: '[data-id="dashboard.todos.todo.send.invites"]',
      on: 'top',
    },
  },
]);

export default tour;
