import { PaletteColors } from '@/Theme';
import { hexToRGB, toRGBParts } from '@/utils/color';

export const TIME_SERIES = {
  WEEK_TO_WEEK: 'w2w',
  MONTH_TO_MONTH: 'm2m',
};

export const TIME_SERIES_VALUES = Object.values(TIME_SERIES);

export const DEFAULT_DATE_FORMAT = 'DD MMM';

export const TIME_SERIES_DATE_FORMAT = {
  [TIME_SERIES.WEEK_TO_WEEK]: DEFAULT_DATE_FORMAT,
  [TIME_SERIES.MONTH_TO_MONTH]: 'MMM YY',
};

export const TIME_SERIES_UNIT = {
  [TIME_SERIES.WEEK_TO_WEEK]: 'week',
  [TIME_SERIES.MONTH_TO_MONTH]: 'month',
};

export const DEFAULT_TOOLTIP_STYLES = {
  boxPadding: 5,
};

export const getDefaultGridStyles = () => ({
  grid: {
    borderColor: PaletteColors['default-lighten-80'],
    borderDash: [2, 2],
    color: PaletteColors['default-lighten-80'],
  },
  ticks: {
    color: PaletteColors['default-lighten-30'],
    padding: 5,
  },
  title: {
    color: PaletteColors['default-lighten-30'],
  },
});

export const getDatasetColor = index => {
  const datasetColorList = Object.entries(PaletteColors)
    .filter(([key]) => (key.startsWith('brand') || key.startsWith('core')) && !key.includes('lighten'))
    .map(([value]) => value)
    .reverse();

  return datasetColorList[index % datasetColorList.length];
};

export const generateGradientBackground = (ctx, color) => {
  const canvas = ctx.chart.ctx;
  const height = canvas.canvas.height;
  const gradient = canvas.createLinearGradient(0, height / 8, 0, height);
  const [r, g, b] = toRGBParts(hexToRGB(color));

  gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.5)`);
  gradient.addColorStop(0.25, `rgba(${r}, ${g}, ${b}, 0.3)`);
  gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`);

  return gradient;
};

export const getInitialAspectRatio = (defaultRatio, smRatio) => (window.outerWidth > 768 ? defaultRatio : smRatio);
