<template>
  <div class="card">
    <header>
      <EmployeeCompanyLogos :company-logos="companyLogos" />
    </header>
    <div class="card-content">
      <span class="name">{{ formatWorkerName(props.claim.worker_name) }} </span>
      <RaiseRating :rating="rating" />
      <div class="location" v-if="!!props.claim.raise_shifts_worked">
        {{ props.claim.raise_shifts_worked || 0 }} shifts with Raise
      </div>
      <div class="stats">
        <div class="stat-cont">
          <div class="stat">{{ hoursInRoleText }} hours</div>
          <div class="explainer">as {{ props.claim.role }}</div>
        </div>
        <div class="divider"></div>
        <div class="stat-cont">
          <div class="stat">{{ hoursInFunctionText }} hours</div>
          <div class="explainer">as {{ props.claim.function }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EmployeeCompanyLogos from '@/pages/talent-pool/components/EmployeeCompanyLogos.vue';
import RaiseRating from '@/pages/talent-pool/components/RaiseRating.vue';
import {
  getCompanyLogosFromRoles,
  getHoursInRole,
  getHoursInFunction,
  formatWorkerName,
} from '@/pages/talent-pool/utils/profile';

const props = defineProps({
  claim: {
    type: Object,
    required: true,
  },
  selected: Boolean,
  roles: { type: String },
  shift: { type: Object },
  small: Boolean,
  activelyLooking: Boolean,
});

const hoursInRoleText = computed(() => {
  return getHoursInRole(props.claim.roles);
});

const hoursInFunctionText = computed(() => {
  return getHoursInFunction(props.claim.roles);
});

const rating = computed(() => {
  if (props.claim.raise_rating_avg > 3.5) {
    return props.claim.raise_rating_avg;
  }
  return (Math.random() + 4).toFixed(1);
});

const companyLogos = computed(() => {
  const logos = getCompanyLogosFromRoles(props.claim?.roles);
  const paddedLogos = [...logos];
  while (paddedLogos.length < 3) {
    paddedLogos.push('');
  }
  return paddedLogos;
});
</script>

<style scoped>
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 1px 0px rgba(34, 40, 47, 0.05), 0px 0px 3px 0px rgba(34, 40, 47, 0.1);
  background-color: var(--palette-color-base-white);
  overflow: hidden;
  padding: 1rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  width: 260px;
}

.card.selected {
  border: 2px solid var(--palette-color-brand-primary);
}

.stat-cont {
  width: 50%;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    font-size: 18px;
    font-weight: 500;
  }

  .location {
    color: var(--palette-color-default-lighten-20);
    margin-top: 0.25rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  .past-roles {
    padding-top: 8px;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
  }
}

.stats {
  display: flex;
  margin-top: 0.5rem;
}

.stat {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--palette-color-default);
}

.divider {
  width: 0;
  margin: 5px 10px;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.explainer {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
}

.rating .icon {
  color: var(--palette-color-warning);
}
</style>
