<template>
  <section class="filters-dropdown">
    <section class="filter-dropdown__list">
      <div v-if="$slots.default" class="filter-dropdown__list-item filter-dropdown__list-item--slot">
        <slot></slot>
      </div>
      <b-dropdown ref="$dropdown" class="filter-dropdown__list-item" variant="light" no-caret>
        <template #button-content>
          <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />
          <strong>Add Filter</strong>
        </template>
        <template #default>
          <b-dropdown-form @submit.prevent="$dropdown.hide(true)">
            <div class="filter-dropdown__filters">
              <nav class="filter-dropdown__nav">
                <button
                  v-for="filter in visibleFilters"
                  :key="filter.key"
                  class="filter-dropdown__nav-button"
                  :class="{ 'filter-dropdown__nav-button--selected': filter.key === selectedFilter?.key }"
                  type="button"
                  @click.capture.stop="selectedFilter = filter"
                >
                  {{ filter.label }}
                  <font-awesome-icon :icon="['fal', 'chevron-right']" class="ml-auto" />
                </button>
              </nav>

              <section v-if="selectedFilter" class="filter-dropdown__selected">
                <app-input
                  v-if="selectedFilter.type !== 'date'"
                  v-bind="getFilterControlProps(selectedFilter)"
                  v-model="filterValues[selectedFilter.key]"
                  :name="selectedFilter.key"
                  :type="selectedFilter.type"
                  autocomplete="off"
                />
                <b-calendar
                  v-else
                  v-bind="getFilterControlProps(selectedFilter)"
                  v-model="filterValues[selectedFilter.key]"
                  block
                  hide-header
                />
              </section>
            </div>
          </b-dropdown-form>
        </template>
      </b-dropdown>
      <b-button
        v-for="(filterValue, key) in appliedFilters"
        :key="key"
        class="filter-dropdown__list-item"
        :title="getFilter(key).defaultValue ? 'Reset' : 'Remove'"
        variant="light"
        @click="removeFilter(key)"
      >
        {{ getFilter(key).label }}<template v-if="!TRUE_REGEX.test(filterValue)">: {{ filterValue }} </template>
        <font-awesome-icon
          v-if="getFilter(key).defaultValue && getFilter(key).defaultValue !== filterValue"
          :icon="['fal', 'undo']"
          class="ml-2"
        />
        <font-awesome-icon
          v-else-if="getFilter(key).defaultValue === undefined"
          :icon="['fal', 'times']"
          class="ml-2"
        />
      </b-button>
    </section>
  </section>
</template>

<script setup>
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import AppInput, { CONTROL_TYPES, isCheckedControlType, isSingleCheckedControlType } from '@/components/AppInput.vue';
import { FALSE_REGEX, TRUE_REGEX } from '@/utils/common';

const ARRAY_FILTER_TYPES = [CONTROL_TYPES.CHECKBOX];

const $route = useRoute();
const $router = useRouter();

const props = defineProps({
  filters: {
    type: Array,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['input']);

const $dropdown = ref(undefined);

const visibleFilters = computed(() => props.filters.filter(filter => filter.hidden !== true));
const selectedFilter = ref(visibleFilters.value[0]);

const filterValues = computed({
  get: () => props.value,
  set: value => emit('input', value),
});

const filterKeys = computed(() => props.filters.map(filter => filter.key));

const appliedFilters = computed(() => {
  return Object.entries(props.value).reduce((acc, [key, value]) => {
    if (value) {
      const filter = getFilter(key);

      if (filter.options?.length) {
        if (isArrayFilterType(filter)) {
          if (value.length) {
            acc[key] = value.map(optionValue => getFilterOptionText(filter, optionValue)).join(', ');
          }
        } else {
          acc[key] = getFilterOptionText(filter, value);
        }
      } else if (!filter.options) {
        acc[key] = value;
      }
    }

    return acc;
  }, {});
});

onMounted(() => {
  setFilterValuesFromQuery();
  nextTick(() => watchFiltersAndUpdateQuery());
});

const setFilterValuesFromQuery = () => {
  const query = $route.query;

  const queryFilterValues = props.filters.reduce((acc, filter) => {
    const value = query[filter.key];

    if (value) {
      if (isArrayFilterType(filter)) {
        acc[filter.key] = Array.isArray(value) ? value : [value];
      } else {
        acc[filter.key] = value;
      }
    }

    return acc;
  }, {});

  filterValues.value = { ...filterValues.value, ...queryFilterValues };
};

const watchFiltersAndUpdateQuery = () => {
  watch(
    () => filterValues.value,
    values => {
      const query = $route.query;
      const externalQuery = pickBy(query, (value, key) => !filterKeys.value.includes(key));
      const nextQuery = {
        ...omitBy(values, value => !value || FALSE_REGEX.test(value) || isNil(value)),
        ...externalQuery,
      };

      if (!isEqual(query, nextQuery)) {
        $router.replace({ query: nextQuery });
      }
    },
    { deep: true },
  );
};

const getFilter = key => props.filters.find(filter => filter.key === key);

const removeFilter = key => (filterValues.value[key] = getFilter(key).defaultValue);

const getFilterControlProps = filter => ({
  ...(filter.options ? { options: filter.options } : null),
  ...(isCheckedControlType(filter.type) ? { stacked: true } : null),
  ...(isSingleCheckedControlType(filter.type) ? { switch: true } : null),
  ...filter.controlProps,
});

const getFilterOptionText = (filter, optionValue) =>
  filter.options.find(option => option.value === optionValue)?.text || optionValue;

const isArrayFilterType = filter => ARRAY_FILTER_TYPES.includes(filter.type);
</script>

<style lang="scss" scoped>
.filters-dropdown {
  :deep(.dropdown-menu) {
    padding: 0;

    .dropdown-menu {
      transition: none;
    }

    .dropdown-menu.show {
      opacity: 1;
      visibility: visible;
    }
  }

  :deep(.input-group) {
    width: 100%;

    .custom-control {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.filter-dropdown__list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.filter-dropdown__list-item--slot {
  align-items: flex-start;
  display: flex;
  gap: 0.5rem;
}

.filter-dropdown__filters {
  display: flex;
  min-width: 30rem;

  @media (max-width: 576px) {
    min-width: 20rem;
  }
}

.filter-dropdown__nav {
  flex-basis: 40%;
  flex-shrink: 0;
}

.filter-dropdown__nav-button {
  align-items: center;
  background: none;
  border: 0;
  border-bottom: 1px solid var(--palette-color-default-lighten-95);
  border-right: 1px solid var(--palette-color-default-lighten-95);
  display: flex;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 0;
  text-align: left;
  width: 100%;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.filter-dropdown__nav-button--selected {
  background-color: var(--palette-color-base-white);
  border-right-color: var(--palette-color-base-white);
  color: var(--palette-color-brand-primary);
}

.filter-dropdown__selected {
  flex: 1;
  max-height: 20rem;
  min-height: 100%;
  overflow: auto;
  padding: 0.75rem 0 0.75rem 1rem;
}
</style>
