<template>
  <div>
    <sme-alert level="warning" class="mb-3">
      {{
        employee.properties.paused || employee.properties.pause_scheduled_at
          ? 'Employee already paused. Please unpause employee to schedule a lump sum pause'
          : `You are about to schedule a pause of access to the app for ${employee.full_name} because of an advance lump sum`
      }}
    </sme-alert>
    <div>
      Pause app access for the employee due to them receiving a lump sum in advance instead of their regular pay
      schedule, usually due to the summer break.
      <br />
      This will prevent the employee from accruing new wages in the app until the pause is lifted at the start of the
      pay period, after the summer break.
    </div>
    <span class="date-picker-header mt-5 mb-5">
      <app-input
        v-model="lumpSumStartDate"
        type="select"
        name="Final Paycheck before the Summer Break"
        label="Final Paycheck before the Summer Break"
        class="mb-0"
        :disabled="employee.properties.paused || employee.properties.pause_scheduled_at"
        :options="paycheckOptions"
      />
      <div class="align-middle pt-4">➡</div>
      <app-input
        v-model="lumpSumEndDate"
        type="select"
        name="First Paycheck after the Summer Break"
        label="First Paycheck after the Summer Break"
        :disabled="employee.properties.paused || employee.properties.pause_scheduled_at"
        :options="paycheckOptions"
      />
    </span>
    <page-sub-footer>
      <b-button
        type="submit"
        variant="primary"
        @click="submitLumpSumSchedule"
        :disabled="!lumpSumStartDate || !lumpSumEndDate || lumpSumStartDate === lumpSumEndDate || saving || saved"
      >
        <b-spinner v-if="saving" class="mr-2" small />
        {{ saving ? 'Submitting...' : 'Submit' }}
      </b-button>
    </page-sub-footer>
    <sme-alert v-if="saved" level="good" class="mt-3">
      {{ employee.full_name }}'s pause has been scheduled successfully!</sme-alert
    >
  </div>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';

export default {
  name: 'EmployeeLumpSumPay',
  components: { AppInput, PageSubFooter, SmeAlert },
  props: {
    employee: Object,
    futurePayPeriods: Array,
  },
  data() {
    return {
      saving: false,
      saved: false,
      lumpSumStartDate: undefined,
      lumpSumEndDate: undefined,
      paycheckOptions: this.futurePayPeriods.map(ps => ps.paid_on),
    };
  },
  methods: {
    submitLumpSumSchedule() {
      this.saving = true;
      ApiClient.postLumpSumSchedule(this.employee.employee_id, {
        start_paid_on_date: this.lumpSumStartDate,
        end_paid_on_date: this.lumpSumEndDate,
        pay_schedule_id: this.employee.pay_schedule_id,
      }).then(
        () => {
          this.saving = false;
          this.saved = true;
          window.setTimeout(window.location.reload(), 3000);
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.date-picker-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1rem;
}
</style>
