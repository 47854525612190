<template>
  <app-page
    :title="`Reset ${employee ? employee.full_name : 'Employee'}'s password`"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    :error="error"
    icon="lock-open"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning" class="mb-3">
        We highly recommend that employees manage their own passwords. However, if you need to trigger a password reset
        for this employee, you may do so below.
        <br />
        A link will be e-mailed to the employee so they can complete the process.
      </sme-alert>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="reason"
            type="text"
            name="reason"
            rules="required"
            label="Reason for reset"
            :disabled="saving || saved"
            :validate-immediate="false"
          ></app-input>
          <page-sub-footer>
            <b-button
              data-testid="reset-password-button"
              type="submit"
              variant="primary"
              :disabled="v.invalid || saving || saved"
            >
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Resetting password...' : 'Reset password' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <sme-alert v-if="saved" level="good"> We have emailed {{ employee.full_name }} with a link to reset </sme-alert>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'EmployeeResetPassword',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
      state: State.state,
      reason: undefined,
    };
  },
  methods: {
    onSubmit() {
      this.saving = true;

      ApiClient.employeeResetPassword(
        this.employee.employee_id,
        this.reason,
        this.state.user.user_id,
        this.employee.work_email,
      ).then(
        () => {
          this.saving = false;
          this.saved = true;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
