<template>
  <app-page title="Pay salaries" subtitle="Pay your employees in bulk with a single payment to us" icon="university">
    <template v-slot:infobox>
      <sme-info-box title="Why would I pay my employees through Wagestream?" id="why-pay-with" save-state>
        <p>There are two reasons to have Wagestream disburse salaries:</p>

        <ol>
          <li>
            A critical aspect when administering Wagestream is keeping your payroll system and Wagestream synchronised.
            If paying through Wagestream this is no longer your responsibility.
          </li>

          <li>
            For businesses that pay employees through Internet banking rather than BACS, paying through Wagestream
            removes the substantial time and effort burden of authorising individual transfers every pay day.
          </li>
        </ol>
      </sme-info-box>

      <sme-info-box title="How do I pay my employees through Wagestream?" id="how-to-pay" save-state>
        <p>The process is quite simple:</p>
        <ol>
          <li>Provide us with data on the employees to be paid and their net pay.</li>

          <li>After the details are confirmed, we will instruct you how to transfer the sum amount to Wagestream.</li>
          <li>
            Once payment is received we can disburse to empoloyees, either immediately or at 12AM on their next pay day.
          </li>
        </ol>
        <p>The bank account that receives and holds your transfer is dedicated for use by your business only.</p>
      </sme-info-box>
    </template>

    <template v-slot:custom-content>
      <b-container fluid class="payment-run-wrapper" ref="stepsWrapper">
        <b-row align-v="stretch">
          <!-- Account Details -->
          <b-col lg="4" order="1" order-lg="2" class="mb-4 mb-lg-0">
            <pay-with-wagestream-account :show-withdraw-link="false" />
          </b-col>

          <!-- Data upload and confirm -->
          <b-col lg="8" order="2" order-lg="1">
            <pay-with-wagestream-bulk-upload @created="getPaymentRunHistory" />
          </b-col>
        </b-row>

        <!-- Historical and outstanding runs -->
        <b-row class="mt-4">
          <b-col cols="12" class="payment-runs-row">
            <sme-card title="In-flight and historical salary runs" class="payment-runs" large>
              <app-loading :loading="paymentHistoryLoading" />
              <template v-if="!paymentHistoryLoading && paymentRunHistory">
                <b-table
                  ref="table"
                  :items="paymentRunHistory"
                  :fields="payHistoryFields"
                  :hover="$attrs.hover !== undefined ? $attrs.hover : true"
                  responsive="sm"
                  borderless
                  show-empty
                  v-bind="$attrs"
                  v-on="$listeners"
                  @row-clicked="rowClickHandler"
                  :tbody-tr-class="rowClass"
                  class="payment-items"
                >
                  <template #cell(status)="row">
                    <div class="payment-cell">
                      <span>
                        <strong>Reference: </strong>
                        <strong class="h3 font-weight-bold">{{ row.item.reference }}</strong>
                      </span>
                      <span :class="`pay-status badge ${getBadgeClass(row.item.status)}`">
                        {{ getTextStatus(row.item.status) }}
                      </span>
                    </div>
                  </template>

                  <template #cell(details)="row">
                    <div class="payment-cell">
                      <span>
                        Amount to pay<br />
                        <strong class="h3 text-primary">{{ formatAmount(row.item.amount_paid) }}</strong>
                      </span>
                      <small class="hide-mobile text-muted">Created: {{ formatDate(row.item.created_at) }}</small>
                    </div>
                  </template>

                  <template #cell(received)="row">
                    <div class="payment-cell">
                      <span
                        >Amount received<br />
                        <strong class="h3 text-primary">{{ formatAmount(row.item.amount_received) }}</strong></span
                      >
                      <small class="hide-mobile text-muted">
                        Received: <span>{{ formatDate(row.item.received_at) }}</span>
                      </small>
                    </div>
                  </template>

                  <template #cell(view_details)>
                    <div class="payment-cell arrow"></div>
                  </template>

                  <template #row-details="row">
                    <div class="employee-wrapper">
                      <div v-if="payRunEligibleToForce(row.item)" class="contact-support">
                        <small> This pay run is waiting until pay day. Click here to run it now. </small>
                        <b-button
                          type="button"
                          variant="light"
                          @click.prevent="forcePaymentRun(row.item)"
                          class="ml-3"
                          size="sm"
                        >
                          <font-awesome-icon :icon="['fad', 'play']" />
                        </b-button>
                      </div>
                      <div v-else-if="payRunEligibleForDeletion(row.item)" class="contact-support">
                        <small> You have not paid into this salary run. Click here to remove it. </small>
                        <b-button
                          type="button"
                          variant="light"
                          @click.prevent="deletePaymentRun(row.item)"
                          class="ml-3"
                          size="sm"
                        >
                          <font-awesome-icon :icon="['fad', 'trash']" />
                        </b-button>
                      </div>
                      <div v-else class="contact-support">
                        <small>Notice anything wrong with this salary run?</small>
                        <b-button type="button" variant="light" class="ml-3" size="sm" @click.prevent="contactSupport">
                          Contact support
                        </b-button>
                      </div>

                      <div class="employee-table-wrapper">
                        <b-table
                          ref="table-employees"
                          :items="getEmployeeList(row.item)"
                          :fields="employeeListFields"
                          responsive="sm"
                          :hover="undefined"
                          borderless
                          show-empty
                          v-bind="$attrs"
                          v-on="$listeners"
                          class="employee-table"
                        >
                          <template #cell(employee_code)="data">
                            <span>
                              {{ data.item.employee_code }}
                            </span>
                          </template>

                          <template #cell(employee_name)="data">
                            <span>
                              {{ data.item.employee_name }}
                            </span>
                          </template>

                          <template #cell(salary_paid_on)="data">
                            <span>
                              {{ formatDate(data.item.salary_paid_on) }}
                            </span>
                          </template>

                          <template #cell(net_salary)="data">
                            <span>
                              {{ formatAmount(data.item.net_pay_pence) }}
                            </span>
                          </template>

                          <template #cell(status)="data">
                            <span :class="`pay-status badge ${getBadgeClass(data.item.payment_status)}`">
                              {{ getTextStatus(data.item.payment_status) }}
                            </span>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </template>
                </b-table>
              </template>
            </sme-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </app-page>
</template>

<script>
import * as moment from 'moment/moment';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import AppPage from '@/components/AppPage';
import SmeCard from '@/components/atoms/SmeCard.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import PayWithWagestreamAccount from '@/pages/payWithWagestream/components/PayWithWagestreamAccount.vue';
import PayWithWagestreamBulkUpload from '@/pages/payWithWagestream/components/PayWithWagestreamBulkUpload.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';
import { contactSupport } from '@/utils/support';

export default {
  name: 'PayWithWagestream',
  components: {
    AppLoading,
    AppPage,
    SmeCard,
    SmeInfoBox,
    PayWithWagestreamAccount,
    PayWithWagestreamBulkUpload,
  },
  data() {
    return {
      state: State.state,
      csvVerificationErrors: null,
      csvFileContents: null,
      paymentRunResponse: null,
      paymentRunError: null,
      uploadingFile: false,
      paymentRunHistory: null,
      paymentHistoryLoading: false,
      payImmediate: '',
      csvPassedValidation: false,
      employeesInCSVFile: null,
      payHistoryFields: [
        {
          key: 'status',
          label: 'Status',
          thClass: 'd-none',
          thStyle: { display: 'none' },
          tdClass: 'hide-mobile',
        },
        {
          key: 'details',
          label: 'Details',
          thClass: 'd-none',
          thStyle: { display: 'none' },
        },
        {
          key: 'received',
          label: 'Received',
          thClass: 'd-none',
          thStyle: { display: 'none' },
        },
        {
          key: 'view_details',
          label: 'View Details',
          thClass: 'd-none',
          thStyle: { display: 'none' },
        },
      ],
      employeeListFields: [
        {
          key: 'employee_code',
          label: 'Employee Code',
        },
        {
          key: 'employee_name',
          label: 'Name',
        },
        {
          key: 'salary_paid_on',
          label: 'Paid on',
        },
        {
          key: 'net_salary',
          label: 'Net pay',
        },
        {
          key: 'status',
          label: '',
          tdClass: 'last-table-column',
        },
      ],
      employeeCSVListFields: [
        {
          key: 'employee_code',
          label: 'Employee Code',
        },
        {
          key: 'employee_name',
          label: 'Employee Name',
        },
        {
          key: 'net_salary',
          label: 'Net Salary',
        },
      ],
      activePayHistoryRow: {
        reference: null,
        index: null,
        open: false,
      },
    };
  },
  async mounted() {
    this.getPaymentRunHistory();
  },
  methods: {
    contactSupport,
    getEmployeeList(rowItem) {
      const employeeList = [];

      if (rowItem.pay_immediate.length > 0) {
        return rowItem.pay_immediate;
      }

      if (Object.keys(rowItem.pay_scheduled).length > 0) {
        for (const payScheduleItemKey in rowItem.pay_scheduled) {
          employeeList.push(...rowItem.pay_scheduled[payScheduleItemKey]);
        }
      }

      return employeeList;
    },
    getTextStatus(status) {
      return status.replace('_', ' ').toUpperCase();
    },
    getBadgeClass(status) {
      return `badge-${status.replace('_', '-').toLowerCase()}`;
    },
    async getPaymentRunHistory() {
      this.paymentHistoryLoading = true;
      try {
        const history = await ApiClient.getPaymentRunHistory(this.state.company.company_id);
        this.paymentRunHistory = history;
      } catch (error) {
        console.error('Could not get payment run history', error.message);
      } finally {
        this.paymentHistoryLoading = false;
      }
    },
    async deletePaymentRun(item) {
      try {
        await ApiClient.deletePaymentRun(this.state.company.company_id, item.reference);
        await this.getPaymentRunHistory();
      } catch (error) {
        console.error('Could not delete payment run', error.message);
      }
    },
    async forcePaymentRun(item) {
      try {
        await ApiClient.forcePaymentRun(this.state.company.company_id, item.reference);
        await this.getPaymentRunHistory();
      } catch (error) {
        console.error('Could not force payment run', error.message);
      }
    },
    formatAmount(amount) {
      return toCurrency(amount, this.state.company.default_currency);
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('DD MMMM YYYY');
      }
      return '';
    },
    rowClickHandler(item, index) {
      item._showDetails = !item._showDetails;
      const { reference } = item;
      if (!reference) {
        return;
      }

      this.activePayHistoryRow = {
        reference,
        index,
        open: !this.activePayHistoryRow.open,
      };
    },
    rowClass(item) {
      const trClasses = ['tr-card'];
      if (!item) {
        return trClasses;
      }
      if (item.reference === this.activePayHistoryRow.reference) {
        trClasses.push('selected-row');
      }

      if (item.reference === this.activePayHistoryRow.reference && item._showDetails) {
        trClasses.push('open');
      }
      return trClasses;
    },
    payRunEligibleForDeletion(item) {
      return item.status === 'AWAITING_PAYMENT';
    },
    payRunEligibleToForce(item) {
      return item.status === 'AWAITING_PAYDAY';
    },
  },
};
</script>

<style scoped lang="scss">
.payment-run-wrapper {
  position: relative;

  .payment-runs {
    position: relative;
  }

  .payment-items {
    margin: 0;
    /*max-height: 500px;*/
    /*overflow-y: scroll;*/

    ::v-deep .tr-card {
      border: 1px solid var(--palette-color-default-lighten-80);
      border-radius: 8px;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      background: var(--palette-color-base-white);

      .badge {
        /*padding: 0.7rem 0.9rem;*/
        padding: 5px;
        color: var(--palette-color-default);
        border: solid 1px var(--palette-color-default);
        text-transform: uppercase;
        font-weight: 800;
        display: block;
        font-size: 0.7rem;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        border-radius: 4px;
        letter-spacing: 1px;

        &-awaiting-payment,
        &-unknown {
          color: var(--palette-color-default-lighten-30);
          background: var(--palette-color-default-lighten-95);
          border: solid 1px var(--palette-color-default-lighten-30);
        }

        &-paid,
        &-succeeded {
          color: var(--palette-color-brand-primary);
          background: var(--palette-color-core-1-lighten-70);
          border: solid 1px var(--palette-color-brand-primary);
        }

        &-pending,
        &-in-progress,
        &-awaiting-payday {
          color: var(--palette-color-core-4);
          background: var(--palette-color-core-4-lighten-90);
          border: solid 1px var(--palette-color-core-4);
        }

        &-failed {
          color: var(--palette-color-danger);
          background: var(--palette-color-danger-lighten-90);
          border: solid 1px var(--palette-color-danger);
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.b-table-has-details {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;

        .arrow {
          &::after {
            transform: rotate(400deg) translateY(-50%);
          }
        }

        &:hover + .b-table-details {
          box-shadow: inset 1px 0 0 var(--palette-color-default-lighten-90),
            inset -1px 0 0 var(--palette-color-default-lighten-90), 0 1px 2px 0 var(--palette-color-default-lighten-60),
            0 1px 3px 1px var(--palette-color-default-lighten-80);

          &::before {
            display: block;
            content: '';
            width: calc(100% - 2px);
            height: 6px;
            position: absolute;
            left: 1px;
            top: -2px;
            background-color: var(--palette-color-base-white);
          }
        }
      }

      &.b-table-details {
        position: relative;
        margin-top: -10px;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        & > td {
          display: block;
          width: 100%;
        }

        &:hover {
          cursor: default;

          &::before {
            display: block;
            content: '';
            width: calc(100% - 2px);
            height: 2px;
            position: absolute;
            left: 1px;
            top: -2px;
            background-color: var(--palette-color-base-white);
          }
        }

        .employee-wrapper {
          border-top: solid 1px var(--palette-color-default-lighten-80);
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          .employee-table {
            padding-bottom: 1rem;
            border-bottom: solid 1px var(--palette-color-default-lighten-80);

            .last-table-column {
              padding-right: 1rem;
              text-align: right;
            }
          }

          .totals {
            align-self: flex-end;
            padding-right: 1rem;
            font-size: 1rem;
          }

          .contact-support {
            align-self: flex-end;
            padding-right: 1rem;
            margin-top: 0.5rem;
          }
        }
      }

      &.selected-row {
        position: relative;
        box-shadow: 0px 0px 0px 5px var(--palette-color-brand-primary);

        &.b-table-has-details:hover + .b-table-details {
          box-shadow: 0px 0px 0px 5px var(--palette-color-brand-primary);

          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -1px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        &.b-table-has-details + .b-table-details {
          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -5px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        .b-table-details:hover {
          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -5px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        &.open {
          .arrow {
            &::after {
              transform: rotate(400deg) translateY(-50%);
            }
          }

          .hide-mobile {
            display: block;
          }

          .hide-desktop {
            display: none;
          }
        }
      }

      .hide-desktop {
        display: none;

        @media (max-width: 576px) {
          display: block;
        }
      }

      .hide-mobile {
        display: block;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }

    .payment-cell {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &.arrow {
        position: relative;
        height: 100%;

        &::after {
          display: block;
          content: '';
          width: 14px;
          height: 14px;
          position: absolute;
          right: 10px;
          top: 50%;
          border-right: 2px solid var(--palette-color-base-black);
          border-bottom: 2px solid var(--palette-color-base-black);
          transform: rotate(315deg) translateY(-50%);
          transition: all 0.3s ease-in-out;
          transform-origin: top;
        }
      }
    }

    ::v-deep .table-hover {
      .employee-table {
        tr:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.account__balance-caption {
  margin-top: 12px;
  padding-left: 20px;
  text-align: left;
  font-size: 20px;
  @media (max-width: 768px) {
    text-align: left;
  }
}
</style>
