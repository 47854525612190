<template>
  <article class="sme-info-box" :class="[light && 'sme-info-box--light']">
    <h3 class="sme-info-box__title">
      <button @click="toggle" class="sme-info-box__button">
        <font-awesome-icon
          :icon="['far', open ? 'minus-circle' : 'plus-circle']"
          size="lg"
          class="sme-info-box__icon"
        />
        {{ title }}
      </button>
    </h3>
    <div
      class="sme-info-box__content"
      :class="{
        'sme-info-box__content--closed': !open,
        'sme-info-box__content--flush': flush,
      }"
    >
      <slot></slot>
    </div>
  </article>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router/composables';
import { TRUE_REGEX } from '@/utils/common';

const $route = useRoute();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  isGrouped: {
    type: Boolean,
    default: false,
  },
  saveState: {
    type: Boolean,
    default: false,
  },
  flush: {
    type: Boolean,
    default: false,
  },
  light: {
    type: Boolean,
    default: false,
  },
});

const open = ref(false);

const identifier = `${$route.name}-${props.id}-info`;

onMounted(() => {
  if (props.saveState && localStorage?.infoBoxStates) {
    const infoBoxStates = JSON.parse(localStorage.infoBoxStates);

    if (!Object.prototype.hasOwnProperty.call(infoBoxStates, identifier)) {
      open.value = props.defaultOpen || open.value;
      saveLocalStorageState(infoBoxStates);
      return;
    }

    const savedOpenStateString = infoBoxStates[identifier];
    open.value = TRUE_REGEX.test(savedOpenStateString);
    return;
  }

  if (props.defaultOpen) {
    open.value = true;
  }

  if (props.saveState) {
    saveLocalStorageState();
  }
});

const toggle = () => {
  open.value = !open.value;

  if (props.saveState) {
    saveLocalStorageState({}, true);
  }
};

const saveLocalStorageState = (infoBoxStatesObject = {}, fromLocalStorage) => {
  if (localStorage) {
    const resultStatesObject = {
      ...((fromLocalStorage && JSON.parse(localStorage.infoBoxStates)) || infoBoxStatesObject),
      [identifier]: open.value,
    };

    localStorage.infoBoxStates = JSON.stringify(resultStatesObject);
  }
};
</script>

<style scoped lang="scss">
@use 'sass:color';

.sme-info-box {
  border: 1px solid var(--palette-color-default-lighten-80);
  color: var(--palette-color-default-lighten-20);
  margin-bottom: 1rem;

  &--light {
    border-color: var(--palette-color-default-lighten-90);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-of-type {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:not(:last-of-type) {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.sme-info-box__title {
  margin: 0;
}

.sme-info-box__button {
  align-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: inherit;
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  justify-content: space-between;
  padding: 0.66rem 1rem;
  text-align: left;
  width: 100%;

  &:hover {
    color: var(--palette-color-brand-primary);
  }

  &:focus {
    outline: none;
  }
}

.sme-info-box__icon {
  margin: 0 0 0 0.5rem;
}

.sme-info-box__content {
  animation: animateFadeInUp 0.7s;
  margin-top: 0.5rem;
  padding: 0rem 1rem 1rem;

  &--flush {
    margin-top: 0;
    padding: 0;
  }

  a {
    font-weight: 400;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.sme-info-box__content--closed {
  display: none;
}
</style>
