export const getEntityType = apiType => {
  if (apiType === 'company_organizational_groups') {
    return 'brand';
  }

  if (apiType === 'companies') {
    return 'company';
  }

  return 'site';
};
