import { loadStripe } from '@stripe/stripe-js';
import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { useStateComposable } from '@/state/composables';
import { PLAN_METADATA, getStripeToPortal } from '@/utils/pricing';

const PLAN_ID_PREFIX = 'prod_';
const PLAN_VARIANTS_PREFIX = 'per-seat-v2';

const { computedState, state, reset } = useStateComposable({
  plans: [],
  stripeKey: null,
  stripe: null,
  error: false,
  initial: true,
  loading: false,
});

const filteredPlans = computed(() => formatPaymentPlans(state.plans).sort((a, b) => a.mappedOrder - b.mappedOrder));

// TODO this needs a refactor, there is no need to check stripe for plans anymore
const formatPaymentPlans = plans => {
  const newPlans = [];

  const variant = State.state.company?.properties?.sme_properties?.registration_variant;
  let perSeatPlans = [];

  if (process.env.NODE_ENV === 'production') {
    if (variant) {
      perSeatPlans = plans.filter(plan => plan.id === `${PLAN_ID_PREFIX}${variant}`);
    } else {
      perSeatPlans = plans.filter(plan => plan.metadata.variant.startsWith(PLAN_VARIANTS_PREFIX));
    }
  } else {
    perSeatPlans = plans;
  }

  perSeatPlans.forEach(plan => {
    newPlans.push({
      ...plan,
      title: plan.mappedName,
      body: `${plan.mappedPrice}. ${plan.mappedDescription || 'Your agreed plan.'}`,
    });
  });
  return newPlans;
};

const getPaymentPlans = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initial) {
    return;
  }

  reset();

  state.initial = false;
  state.loading = true;

  let response;
  try {
    response = await ApiClient.smeGetPaymentPlans();
  } catch (error) {
    state.error = error?.message || 'Unable to fetch subscription plans. Please try again later';
    state.loading = false;
    return;
  }
  state.stripeKey = response.publishableKey;
  state.stripe = await loadStripe(state.stripeKey);
  const plans = response.plans;

  // limit plan to relevant plan defined in pricing.js
  plans.forEach(plan => {
    const portalPlan = getStripeToPortal(plan.id.replace(PLAN_ID_PREFIX, ''));
    if (portalPlan) {
      plan.mappedName = PLAN_METADATA[portalPlan].name;
      plan.mappedDescription = PLAN_METADATA[portalPlan].description;
      plan.mappedPrice = PLAN_METADATA[portalPlan].price;
      plan.mappedOrder = PLAN_METADATA[portalPlan].order;
    }
  });
  const mappedPlans = plans.filter(plan => plan.mappedName);

  state.plans = mappedPlans;
  state.loading = false;
};

const getPaymentPlan = planId => filteredPlans.value.find(plan => plan.id === `${PLAN_ID_PREFIX}${planId}`);
const hasPaymentPlan = planId => !!getPaymentPlan(planId);

export default () => {
  return {
    ...computedState,
    filteredPlans,
    getPaymentPlans,
    getPaymentPlan,
    hasPaymentPlan,
  };
};
