<template>
  <div>
    <main id="js-page-content" role="main" class="page-content login">
      <div class="blankpage-form-field">
        <div
          class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4"
        >
          <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
            <img
              :src="themeImage('logo-white-small.svg')"
              class="wagestream-icon"
              :alt="`${partnershipName} Logo`"
              aria-roledescription="logo"
            />
            <span class="page-logo-text mr-1">Welcome to Partner Portal</span>
          </a>
        </div>
        <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
          <slot></slot>
        </div>
        <div class="blankpage-footer text-center">
          <slot name="footer"></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { themeImage } from '@/Theme';
import { getPartnershipData } from '@/utils/partnership';

const { name: partnershipName } = getPartnershipData();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// From now on copy pasted from Smart Admin login-page.css
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

img.wagestream-icon {
  max-width: 2rem;
}

body {
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  letter-spacing: 0.1px;
}
.page-content {
  color: var(--palette-color-default-lighten-30);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 400;
}
strong {
  font-weight: 500;
}
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 300;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 2px 0 1.5rem;
}
.h2 small,
.h3 small,
h2 small,
h3 small {
  font-size: 0.9375rem;
}
.h4 small,
h4 small {
  font-size: 0.875rem;
}
.h5 small,
.h6 small,
h5 small,
h6 small {
  font-size: 0.8125rem;
}
.text-contrast {
  color: var(--palette-color-default-lighten-20);
}
html body {
  background: var(--palette-color-base-background);
}

html body .blankpage-logo {
  padding: 20px 0 30px;
}
html body .blankpage-form-field {
  position: fixed;
  top: calc(4.125rem + 45%);
  left: 50%;
  width: 320px;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
html body .blankpage-form-field .card {
  margin: 0;
  padding: 13.5px 20px 15.5px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
html body .blankpage-form-field .blankpage-btn {
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 15px;
  margin: 10px 0 5px;
}
html body .blankpage-footer {
  padding: 10px 0;
  font-size: 0.75rem;
  color: var(--palette-color-brand-primary);
}
html body .login-footer {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.6875rem;
}
@media screen and (max-device-width: 800px) {
  html body #bgvid {
    display: none;
  }
}

/* switcher */
.switcher {
  width: auto;
  float: right;
  margin: 1rem;
}
</style>
