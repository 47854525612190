<template>
  <div class="email-action-page">
    <app-loading v-if="loading" />

    <sme-card v-else class="email-action-success" large>
      <font-awesome-icon :icon="['fas', 'circle-check']" class="check-icon mb-3" />
      <h1>Your request has been received</h1>
      <p class="mb-2">Have a question? Please contact <a href="mailto:sme@wagestream.com">support</a>.</p>
    </sme-card>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import SmeCard from '@/components/atoms/SmeCard';

const loading = ref(true);
const $route = useRoute();

const performEmailAction = () => {
  const token = $route.params.token;
  ApiClient.performEmailAction(token);
  loading.value = false;
};

onBeforeMount(() => performEmailAction());
</script>

<style scoped lang="scss">
.email-action-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--palette-color-core-1-lighten-50);
}

.email-action-success {
  align-self: center;
  align-items: center;
  width: auto;
}

.check-icon {
  font-size: 42px;
  color: var(--palette-color-brand-primary);
}
</style>
