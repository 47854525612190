<template>
  <b-modal id="referrals-modal" size="lg" hide-footer width="800px" centered>
    <div class="header-container">
      <h1>Split £500 with every new business you refer to Wagestream</h1>
    </div>
    <div class="body-container intro">
      <p>
        That's £250 for you, and £250 for them - to spend as you wish. Minimum 5 employees at referred business, paid
        out when both companies go live with Wagestream. Limited time offer.
      </p>
    </div>
    <img class="main-image" :src="themeImage('referrals-app-image.png')" alt="Referrals Advertisement" />
    <div class="body-container explainer">
      <h1>How does it work?</h1>
      <ol>
        <li>Share the link below</li>
        <li>We'll walk them through the platform</li>
        <li>We'll send you both £250 when as soon as both of you launch with Wagestream</li>
      </ol>
      <b-button class="get-link" @click="onCopyPromoCode"
        ><File height="16" width="16" color="white" class="mr-2" />{{ linkText }}</b-button
      >
    </div>
    <div class="socials">
      <b-button @click="openLink(LINKEDIN_LINK)">
        <img class="linkedin" :src="themeImage('linkedin.png')" alt="linkedin"
      /></b-button>
      <b-button @click="openLink(WHATSAPP_LINK)">
        <img class="whatsapp" :src="themeImage('whatsapp.png')" alt="whatsapp"
      /></b-button>
      <b-button @click="openLink(EMAIL_LINK)">
        <img class="email" :src="themeImage('email.png')" alt="email"
      /></b-button>
      <b-button @click="openLink(FACEBOOK_LINK)">
        <img class="facebook" :src="themeImage('facebook.png')" alt="facebook"
      /></b-button>
    </div>
  </b-modal>
</template>

<script setup>
import { nextTick, ref } from 'vue';
import { themeImage } from '@/Theme';
import File from '@/assets/icons/File.vue';
import { convertUuidToReferralCode } from '@/utils.js';
import State from '@/state/State';
import useAnalytics from '@/state/composables/useAnalytics';

const getUrlWithChannel = channel => {
  return encodeURIComponent(`${REFERRAL_LINK}?ws_channel=${channel}`);
};

const REFERRAL_LINK = `${window.location.host}/register/landing?ws_code=${encodeURI(
  convertUuidToReferralCode(State?.state?.company?.company_id),
)}&typeformId=01J8FBRTTWT7D8BD4W18CN0GZH&ws_source=referral`;
const LINKEDIN_LINK = `https://www.linkedin.com/shareArticle?mini=true&url=${getUrlWithChannel(
  'linkedin',
)}&title=Split £500 when you sign up for Wagestream!&summary=Use this referral link to sign up to Wagestream, and split £500 with your referrer when you launch!&source=`;
const WHATSAPP_LINK = `https://api.whatsapp.com/send?text=Use this referral link to sign up to Wagestream, and split £500 with your referrer when you launch!%0a${getUrlWithChannel(
  'whatsapp',
)}`;
const EMAIL_LINK = `mailto:?&subject=Use this referral link to sign up to Wagestream, and split £500 with your referrer when you launch!&body=${getUrlWithChannel(
  'email',
)}`;
const FACEBOOK_LINK = `https://www.facebook.com/sharer/sharer.php?u=${getUrlWithChannel('facebook')}`;

const { postAnalyticsEvent } = useAnalytics();

const linkText = ref(REFERRAL_LINK);

const openLink = link => {
  postAnalyticsEvent({
    intent: `open_${link}`,
    task: 'referral',
    action: 'click',
  });
  window.open(link, '_blank');
};

const onCopyPromoCode = () => {
  postAnalyticsEvent({
    intent: 'copy_promo_code',
    task: 'referral',
    action: 'click',
  });
  linkText.value = 'Copied to clipboard!';
  navigator.clipboard.writeText(REFERRAL_LINK);
  nextTick(() => {
    setTimeout(() => {
      linkText.value = REFERRAL_LINK;
    }, 2000);
  });
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  background-color: var(--palette-color-brand-primary);
  text-align: center;
}

::v-deep .modal-header {
  padding: 0 0.5rem;
  font-size: large;
}

::v-deep .modal-body {
  margin-top: 1rem;
  padding: 0;
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  & > h1 {
    color: var(--palette-color-base-white);
    font-weight: 500;
  }
}

.body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-size: 16px;

  & > * {
    width: 90%;
  }
}

.intro {
  color: var(--palette-color-base-white);
}

.explainer {
  // needed to match background image
  /* stylelint-disable-next-line color-no-hex */
  background-color: #fdfdfd;

  & h1 {
    margin: 0;
  }

  & ol {
    margin: 1rem 0;
    text-align: start;
  }

  & .get-link {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: var(--palette-color-brand-primary);
    border-color: transparent;
    font-weight: 500;
    margin: 0.5rem 0;
    padding-left: 5rem;
    padding-right: 5rem;
    width: 60%;
    font-size: large;
  }
}

.main-image {
  width: 100%;
}

.socials {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  // needed to match background image
  /* stylelint-disable-next-line color-no-hex */
  background-color: #fdfdfd;
  gap: 0.5rem;
  border-radius: 0 0 10px 10px;

  & button {
    color: var(--palette-color-base-black);
    background-color: transparent;
    border-color: transparent;

    & p {
      margin: 0;
      font-size: small;
    }

    & img {
      height: 50px;
      width: 50px;
    }

    .linkedin {
      /* stylelint-disable-next-line color-no-hex */
      color: #0077b5;
    }

    .whatsapp {
      /* stylelint-disable-next-line color-no-hex */
      color: #25d366;
    }

    .email {
      /* stylelint-disable-next-line color-no-hex */
      color: #0077b5;
    }

    .facebook {
      /* stylelint-disable-next-line color-no-hex */
      color: #1877f2;
    }
  }
}
</style>
