<template>
  <section>
    <page-sub-header title="Your existing Integrations" title-tag="h2" />
    <b-row>
      <b-col v-for="integration in enabledIntegrations" :key="integration.provider" md="6" lg="4" class="mb-4">
        <integration-card :integration="integration" show-detail>
          <template #footer>
            <b-button
              v-if="integration.provider === 'xero'"
              class="integration-card__button"
              variant="outline-primary"
              @click="configureXero"
            >
              Configure
            </b-button>
          </template>
        </integration-card>
      </b-col>
    </b-row>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router/composables';
import PageSubHeader from '@/components/PageSubHeader.vue';
import IntegrationCard from '@/pages/integrations/components/IntegrationCard.vue';

const $router = useRouter();

defineProps({
  enabledIntegrations: {
    type: Array,
    required: true,
  },
});

const configureXero = () => $router.push({ name: 'xero-configuration' });
</script>
