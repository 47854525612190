<template>
  <router-link v-if="to" :to="to" :class="classes">
    <slot></slot>
  </router-link>
  <button v-else type="button" :class="classes" @click="emit('click', $event)">
    <slot></slot>
  </button>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  inherit: {
    type: Boolean,
    default: false,
  },
  noUnderline: {
    type: Boolean,
    default: false,
  },
  to: Object,
});

const emit = defineEmits(['click']);

const classes = computed(() => ({
  'sme-button-link': true,
  'sme-button-link--inherit': props.inherit,
  'sme-button-link--no-underline': props.noUnderline,
}));
</script>

<style lang="scss" scoped>
.sme-button-link {
  background: none;
  color: var(--palette-color-brand-primary);
  cursor: pointer;
  border: 0;
  display: inline;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-decoration: underline !important;

  &--inherit {
    color: inherit;
  }

  &--no-underline {
    text-decoration: none !important;

    &:not(.sme-button-link--inherit) {
      &:hover {
        color: var(--palette-color-brand-primary);
      }
    }
  }

  &:hover {
    text-decoration: none !important;
  }
}
</style>
