import isNil from 'lodash/isNil';

export const FALSE_REGEX = /false/;
export const TRUE_REGEX = /true/;

export const pluralize = (str, count, pluralized = `${str}s`) => (count === 1 ? str : pluralized);

export const toTitleCase = str => {
  if (str) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  return '';
};

export const decodeHtml = html => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;

  return txt.value;
};

export const getPayTypeLabel = payscheduleCode => {
  if (payscheduleCode === 'hourly_and_salary') {
    return 'Mixed';
  } else if (!payscheduleCode) {
    return 'Mixed';
  } else if (!isNaN(payscheduleCode.slice(-1))) {
    return toTitleCase(payscheduleCode.slice(0, -1));
  }
  return toTitleCase(payscheduleCode);
};

export const formatSortCode = (sortCode, currency) => {
  if (currency === 'USD') {
    return sortCode;
  }

  if (sortCode) {
    const first = sortCode.slice(0, 2);
    const second = sortCode.slice(2, 4);
    const third = sortCode.slice(4, 6);
    return `${first}-${second}-${third}`;
  }
};

export const unFormatCurrency = value => (!isNil(value) ? String(value).replace(/[,£]/g, '') : '');

export const toNumber = value => value.toLocaleString('en-GB', { maximumFractionDigits: 2 });
export const toClampedNumber = (value, min, max) => Math.min(Math.max(value, min), max);
export const toInteger = value => value.toLocaleString('en-GB', { maximumFractionDigits: 0 });
export const toCompactNumber = value => {
  if (isNaN(value)) {
    return value;
  }

  try {
    return Intl.NumberFormat('en', { compactDisplay: 'short', notation: 'compact' }).format(value);
  } catch {
    return value;
  }
};
export const toPercentage = value => {
  if (isNaN(value)) {
    value = 0;
  }

  return value.toLocaleString('en-UK', { style: 'percent' });
};
export const toMailtoHref = email => `mailto:${email}`;
export const toTelHref = tel => `tel:${tel}`;

export const generateRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const currencySymbolFromCode = code => {
  if (code === 'USD') {
    return '$';
  }
  return '£';
};
