<template>
  <b-modal
    ref="modal"
    id="flexible-work-rate-employee-modal"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="() => hideModal(false)"
  >
    <h2>Onboarding checklist for {{ employeeBeingOnboarded.user_name }}</h2>
    <AppLoading v-if="!checklistModel" />
    <SmeAlert v-else-if="Object.keys(checklistModel).length === 0" level="danger">
      <p>Please contact your administrator to set up an onboarding checklist.</p>
    </SmeAlert>
    <template v-else>
      <div>
        <div v-for="(value, key) in checklistModel" :key="key" class="flexible-work-rate-employee-modal__row">
          <span>{{ key }}</span>
          <AppInput v-model="checklistModel[key]" type="checkbox-single" :name="key" />
        </div>
        <b-button variant="primary" class="mt-2" :disabled="isDisabled" @click="() => hideModal(true)">
          Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from 'vue';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';

const modal = ref(null);
const checklistModel = ref({});

defineProps({
  employeeBeingOnboarded: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['onboarding']);

const isDisabled = computed(() => {
  return Object.values(checklistModel.value).some(value => value === false);
});

const showModal = () => {
  modal.value.show();
};

const hideModal = submit => {
  modal.value.hide();
  emit('onboarding', submit);
};

onMounted(async () => {
  showModal();
});

onBeforeMount(async () => {
  const checklistData = ((await ApiClient.getFlexibleWorkCompanyOnboardingChecklist()) || {})?.checklist;
  const model = {};
  checklistData.forEach(checklistItem => {
    model[checklistItem] = false;
  });
  checklistModel.value = model;
});
</script>

<style lang="scss" scoped>
.flexible-work-rate-employee-modal__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem 1rem 0;
  font-size: medium;
}
</style>
