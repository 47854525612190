<template>
  <sme-box variant="white" class="flexible-work-secondary-entity-list p-0">
    <header class="flexible-work-secondary-entity-list__header d-flex align-items-center justify-content-between mb-4">
      <h4 class="sme-box__heading d-flex align-items-center">
        {{ name }}s
        <b-badge class="ml-2" variant="secondary">
          {{ items.length }}
        </b-badge>
      </h4>
      <b-button v-if="!isIntegrated()" variant="outline-primary" @click="handleCreateItem">
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Add {{ name }}
      </b-button>
    </header>
    <template v-if="loading || items.length">
      <sme-box class="p-0" scroll-variant="large" variant="white" bordered scroll>
        <app-loading :loading="loading" />
        <template v-if="!loading">
          <section class="flexible-work-secondary-entity-list__items">
            <article
              v-for="item in items"
              :key="item.name"
              class="flexible-work-secondary-entity-list__item"
              :class="{ 'flexible-work-secondary-entity-list__item--invalid': item.invalid }"
            >
              <div class="flexible-work-secondary-entity-list__item-content mr-3">
                <h4 class="flexible-work-secondary-entity-list__item-title m-0">
                  {{ item.name }}
                  <span v-if="item.invalid" class="flexible-work-secondary-entity-list__item-invalid-message mt-1">
                    {{ messages.invalidItem }}
                  </span>
                </h4>
                <div v-if="name === 'Location'" class="flexible-work-shift__claim-rating-container">
                  <b-form-rating
                    class="flexible-work-shift__claim-rating p-0"
                    :value="averageLocationRating(item)"
                    readonly
                    show-value
                    precision="2"
                  />
                  <span>({{ totalLocationRatings(item) || 0 }})</span>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <p
                  v-html="getItemNotes(item, '<br />') || ''"
                  class="flexible-work-secondary-entity-list__item-text mt-1 m-0"
                ></p>
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <b-button class="ml-auto" variant="outline-primary" @click="handleEditItem(item)">
                <font-awesome-icon :icon="['fad', 'pen-to-square']" />
                <span class="d-none d-md-inline ml-2">Edit</span>
              </b-button>
              <b-button
                v-if="!isIntegrated()"
                class="ml-2 border-0"
                variant="outline-primary"
                @click="handleDeleteItem(item)"
              >
                <font-awesome-icon :icon="['fad', 'trash']" />
              </b-button>
            </article>
          </section>
        </template>
      </sme-box>
    </template>

    <b-modal
      v-model="updating"
      :title="`Edit ${name}`"
      body-class="pt-0"
      size="lg"
      centered
      hide-footer
      @hidden="resetUpdateItem"
    >
      <template v-if="updatingItem">
        <sme-alert v-if="errors.length" :level="ALERT_LEVELS.DANGER" class="mb-4">
          <p>An error occurred creating your {{ name.toLowerCase() }}.</p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="errors.join('<br />')"></p>
        </sme-alert>
        <ValidationObserver v-slot="v">
          <b-form @submit.prevent="v.handleSubmit(updateItem)">
            <app-input
              v-for="field in manualFields"
              v-model="updatingItem[field.key]"
              :key="field.key"
              :label="getFieldName(field)"
              :name="getFieldName(field)"
              :plaintext="isIntegrated() || !field.editable"
              :type="CONTROL_TYPES.TEXT"
              rules="required"
            />
            <app-input
              v-model="updatingItem.properties.notes"
              :type="CONTROL_TYPES.TEXTAREA"
              label="Notes"
              name="notes"
              rows="5"
            />
            <footer class="d-flex justify-content-end">
              <b-button :disabled="saving" type="submit" variant="primary">
                <b-spinner v-if="saving" class="mr-2" small />
                {{ saving ? 'Saving...' : 'Save' }}
              </b-button>
            </footer>
          </b-form>
        </ValidationObserver>
      </template>
    </b-modal>

    <b-modal
      v-if="!isIntegrated()"
      v-model="creating"
      :title="`Add ${name}`"
      body-class="pt-0"
      size="lg"
      centered
      hide-footer
      @hidden="resetCreateItem"
    >
      <sme-alert v-if="errors.length" :level="ALERT_LEVELS.DANGER" class="mb-4">
        <p>An error occurred creating your {{ name.toLowerCase() }}.</p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="errors.join('<br />')"></p>
      </sme-alert>
      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(createItem)">
          <app-input
            v-for="field in manualFields"
            v-model="creatingItem[field.key]"
            :key="field.key"
            :label="getFieldName(field)"
            :name="getFieldName(field)"
            :type="CONTROL_TYPES.TEXT"
            :validate-immediate="false"
            rules="required"
          />
          <app-input
            v-model="creatingItem.properties.notes"
            :placeholder="`Any additional notes or requirements about this ${name.toLowerCase()} for employees using the app`"
            :type="CONTROL_TYPES.TEXTAREA"
            label="Notes"
            name="notes"
            rows="5"
          />
          <footer class="d-flex justify-content-end">
            <b-button :disabled="saving" type="submit" variant="primary">
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Saving...' : 'Save' }}
            </b-button>
          </footer>
        </b-form>
      </ValidationObserver>
    </b-modal>

    <b-modal
      v-model="deleting"
      ok-title="Confirm"
      ok-variant="danger"
      centered
      hide-header
      @hidden="resetDeleteItem"
      @ok="deleteItem"
    >
      Are you sure you want to delete {{ name.toLowerCase() }} {{ deletingItem?.name }}?
    </b-modal>
  </sme-box>
</template>

<script setup>
import { ValidationObserver } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import tracker from '@/Tracker';
import AppInput, { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import SmeBox from '@/components/atoms/SmeBox.vue';
import useToast from '@/composables/useToast';
import State from '@/state/State';
import { toTitleCase } from '@/utils/common';
import { isIntegrated } from '@/utils/flexible-work';

const entityDefaults = () => ({
  company_id: State.state.company.company_id,
  properties: {
    notes: '',
  },
});

const $router = useRouter();
const { TOAST_VARIANT, doToast } = useToast();

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  messages: {
    type: Object,
    default: () => ({}),
  },
  name: {
    type: String,
    required: true,
  },
  createFunction: Function,
  createTo: Object,
  deleteFunction: {
    type: Function,
    required: true,
  },
  updateFunction: Function,
  updateTo: [Function, Object],
  loading: Boolean,
});

const emit = defineEmits(['updated']);

const errors = ref([]);
const creating = ref(false);
const deleting = ref(false);
const saving = ref(false);
const updating = ref(false);
const creatingItem = ref(entityDefaults());
const deletingItem = ref(undefined);
const updatingItem = ref(undefined);

const manualFields = computed(() => props.fields.filter(field => field.manual));

const getFieldName = field => field.label || toTitleCase(field.key);

const totalLocationRatings = item => item.properties?.ratings?.length || 0;
const averageLocationRating = item =>
  item.properties?.ratings?.reduce((acc, rating) => acc + rating.rating, 0) / totalLocationRatings(item) || 0;

const getItemNotes = (item, separator = '\n') => `Notes: ${item.properties?.notes?.join(separator) || 'None'}`;

const handleCreateItem = () => {
  if (props.createTo) {
    $router.push(props.createTo);
    return;
  }

  creating.value = true;
};

const createItem = async () => {
  const item = creatingItem.value;

  saving.value = true;

  try {
    await props.createFunction({
      ...item,
      properties: {
        ...item.properties,
        notes: item.properties.notes.replace(/Notes: /g, '').split('\n') || [],
      },
    });
    doToast(`Created ${props.name}`, `Successfully created ${item.name}`);
    tracker.trackEvent(`flexible_work_${props.name.toLowerCase()}_created`, { notes: item.properties?.notes || '' });
    emit('updated');

    creating.value = false;
  } catch (error) {
    onError(error);
  } finally {
    saving.value = false;
  }
};

const resetCreateItem = () => {
  creatingItem.value = entityDefaults();
  errors.value = [];
};

const handleEditItem = item => {
  if (props.updateTo) {
    $router.push(typeof props.updateTo === 'function' ? props.updateTo(item) : props.updateTo);
    return;
  }

  updatingItem.value = {
    ...item,
    properties: {
      ...item.properties,
      notes: getItemNotes(item),
    },
  };
  updating.value = true;
};

const resetUpdateItem = () => {
  updatingItem.value = undefined;
  errors.value = [];
};

const handleDeleteItem = item => {
  deletingItem.value = item;
  deleting.value = true;
};

const deleteItem = async () => {
  try {
    await props.deleteFunction(deletingItem.value);
    doToast(`Deleted ${props.name}`, `Successfully deleted ${deletingItem.value.name}`);
    tracker.trackEvent(`flexible_work_${props.name.toLowerCase()}_deleted`);
    emit('updated');
  } catch {
    doToast(`Error Deleting ${props.name}`, 'Please contact support if this error persists.', TOAST_VARIANT.DANGER);
  } finally {
    resetDeleteItem();
  }
};

const resetDeleteItem = () => (deletingItem.value = undefined);

const updateItem = async () => {
  const item = updatingItem.value;

  saving.value = true;

  try {
    await props.updateFunction({
      ...item,
      properties: {
        ...item.properties,
        notes: item.properties.notes.replace(/Notes: /g, '').split('\n') || [],
      },
    });
    doToast(`Updated ${props.name}`, `Successfully updated ${item.name}`);
    tracker.trackEvent(`flexible_work_${props.name.toLowerCase()}_updated`, { notes: item.properties?.notes || '' });
    emit('updated');

    updating.value = false;
  } catch (error) {
    onError(error);
  } finally {
    saving.value = false;
  }
};

const onError = error => {
  if (error?.errors) {
    errors.value = error.errors.map(error => error.error) || [
      'An unknown error occurred. Please contact support if this error persists.',
    ];
  }
};
</script>

<style lang="scss" scoped>
.flexible-work-secondary-entity-list__header {
  @media (min-width: 992px) {
    min-height: 2.5rem;
  }
}
.flexible-work-secondary-entity-list__item {
  align-items: center;
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  display: flex;
  margin: 0 1rem;
  padding: 1rem 0;

  &:last-child {
    border-bottom: 0;
  }
}

.flexible-work-secondary-entity-list__item--invalid {
  background-color: var(--palette-color-danger-lighten-90);
  margin: 0;
  padding: 1rem;

  .flexible-work-secondary-entity-list__item-title {
    color: var(--palette-color-danger);
    font-weight: 500;
  }
}

.flexible-work-secondary-entity-list__item-content {
  flex: 1;
  overflow: hidden;
}

.flexible-work-secondary-entity-list__item-title {
  color: var(--palette-color-default);
  font-size: 14px;
}

.flexible-work-secondary-entity-list__item-invalid-message {
  color: var(--palette-color-danger);
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.flexible-work-secondary-entity-list__item-text {
  color: var(--palette-color-default-lighten-20);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flexible-work-shift__claim-rating-container {
  display: flex;
  align-items: center;
  align-content: center;
  width: 80%;
}

.flexible-work-shift__claim-rating {
  border: none;
}

:deep(.b-rating-star) {
  padding: 0 !important;
  justify-content: start !important;
}
</style>
