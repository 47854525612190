import axios from 'axios';
import uuidv4 from 'uuid/v4';
import Config from '@/Config';
import router from '@/Router';
import { gtmIsMounted } from '@/mixins/GoogleTagManager';
import OnboardingState from '@/state/OnboardingState.js';
import State from '@/state/State';
import { portalEnvs } from '@/ApiClient';

// Allowlist for what we can steal from query
const SAFE_QUERY_PARAMS = {
  utm_source: true,
  utm_content: true,
  utm_campaign: true,
  utm_medium: true,
  via: true,
  pv: true,
  cid: true,
};

const ACTIVE_CAMPAIGN_FIELDS = {
  'Company Name': '48',
  'Mobile Number': '135',
  'Landing Page': '162',
  utm_source: '43',
  utm_content: '45',
  utm_campaign: '44',
  utm_medium: '46',
  via: '142',

  businessType: '140',
  employees: '170',
  wagestreamGoal: '171',
};

const ACTIVE_CAMPAIGN_EVENTS = {
  sme_registration_email: 'SME Initial Email Address',
  sme_registration_details: 'SME Registration',
  sme_registration_company: 'SME Company Creation',
  sme_registration_payschedules: 'Created SME Pay Schedule',
  sme_registration_terms: 'Accepted SME Terms',
  sme_registration_subscribe: 'SME Subscribed',

  // TODO: sme_activation_xxx
  // TODO: some active campaign events are generated server side?
};

class Tracker {
  constructor() {
    this.isEnabled = process.env.NODE_ENV === 'production';
    this.isAnalyticsEnabled = !!process.env.VUE_APP_ANALYTICS_URL;
  }

  get trackingEmail() {
    return (
      State.state?.company?.properties?.sme_properties?.registration_email ||
      State.state?.company?.funding_alert_email ||
      OnboardingState.state.userEmail
    );
  }

  get trackingId() {
    return (
      State.state?.company?.properties?.sme_properties?.registration_id ||
      State.state?.company?.company_id ||
      OnboardingState.state.registrationId
    );
  }

  async trackContact(contact, params) {
    if (!this.trackingEmail) {
      return;
    }

    const data = {
      contact: {
        email: this.trackingEmail,
        ...contact,
      },
      tags: ['SME'],
    };

    data.contact.fieldValues = data.contact.fieldValues || [];

    if (params) {
      for (const key in params) {
        if (ACTIVE_CAMPAIGN_FIELDS[key]) {
          data.contact.fieldValues.push({
            field: ACTIVE_CAMPAIGN_FIELDS[key],
            value: params[key],
          });
        }
      }
    }

    if (this.isEnabled) {
      await axios.post(`${Config.apiBaseUrl}/activecampaign/contact`, data);
    } else {
      // eslint-disable-next-line no-console
      console.debug('tracker.trackContact', data);
    }
  }

  async trackEngagement(name, params) {
    if (!this.trackingId) {
      return;
    }

    const payload = {
      event: name,
      params: params,
      user_id: this.trackingId,
    };

    if (this.isEnabled && this.isAnalyticsEnabled) {
      await this.postAnalytics(payload);
    } else {
      // eslint-disable-next-line no-console
      console.debug('tracker.trackEngagement', payload);
    }
  }

  async trackAnonymousEngagement(name, params) {
    const payload = {
      event: name,
      params: params,
      user_id: 'DC68EDCE-9817-4A14-9BE2-DBE6BE56289F',
    };

    if (this.isEnabled && this.isAnalyticsEnabled) {
      await this.postAnalytics(payload);
    } else {
      // eslint-disable-next-line no-console
      console.debug('tracker.trackAnonymousEngagement', payload);
    }
  }

  async trackTouch(touchPointId) {
    const query = router.currentRoute?.query || {};
    const params = { touchPointId: touchPointId };

    // Allowlist query params to event (no PII)
    for (const key in query) {
      if (SAFE_QUERY_PARAMS[key]) {
        params[key] = query[key];
      }
    }

    let trackId = localStorage.getItem('sme.lead.id');
    if (trackId == null) {
      trackId = uuidv4();
      localStorage.setItem('sme.lead.id', trackId);
    }

    const payload = {
      event: 'sme_lead_touch',
      params: params,
      user_id: trackId,
    };

    if (this.isEnabled && this.isAnalyticsEnabled) {
      await this.postAnalytics(payload);
    } else {
      // eslint-disable-next-line no-console
      console.debug('tracker.trackTouch', payload);
    }
  }

  async trackEvent(name, params) {
    if (name === 'sme_registration_email') {
      // Capture contact email and any UTM terms etc
      const query = router.currentRoute?.query || {};
      const ref = OnboardingState.state.referrerUrl ? OnboardingState.state.referrerUrl.split('?') : [''];
      const contact = {};

      if (OnboardingState.state.userFirstName) {
        // Was provided in query string so send to AC now rather than later
        contact['firstName'] = OnboardingState.state.userFirstName;
        contact['lastName'] = OnboardingState.state.userLastName;
      }

      // Add referring page for AC
      if (ref[0]) {
        query['Landing Page'] = ref[0];
      }

      await this.trackContact(contact, query);

      if (!params) {
        params = {};
      }
      // Allowlist query params to event (no PII)
      for (const key in query) {
        if (SAFE_QUERY_PARAMS[key]) {
          params[key] = query[key];
        }
      }
    } else if (name === 'sme_registration_details') {
      // Update contact with additional PII data
      await this.trackContact(
        {
          firstName: OnboardingState.state.userFirstName,
          lastName: OnboardingState.state.userLastName,
          phone: OnboardingState.state.userPhone,
        },
        {
          'Mobile Number': OnboardingState.state.userPhone,
          'Company Name': OnboardingState.state.companyName,
        },
      );
    } else if (name == 'sme_registration_details_more') {
      await this.trackContact({}, params);
    }

    if (!this.trackingId) {
      return;
    }

    // post to our own analytics, including params payload
    const payload = {
      event: name,
      params: params,
      user_id: this.trackingId,
    };

    if (this.isEnabled) {
      // Publish to tag manager
      if (gtmIsMounted) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: name,
          // 'params': params,
        });
      }

      // Publish to ActiveCampaign
      if (ACTIVE_CAMPAIGN_EVENTS[name]) {
        const data = {
          event: ACTIVE_CAMPAIGN_EVENTS[name],
          eventdata: null,
          email: this.trackingEmail,
        };

        await axios.post(`${Config.apiBaseUrl}/activecampaign/event`, data);
      }

      if (this.isAnalyticsEnabled) {
        await this.postAnalytics(payload);
      } else {
        // eslint-disable-next-line no-console
        console.debug('tracker.trackEvent', payload);
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug('tracker.trackEvent', payload);
    }
  }

  async postAnalytics(payload) {
    try {
      if (process.env.VUE_APP_ANALYTICS_URL) {
        payload['app_id'] = document.location.hostname;
        payload['params'] = payload['params'] || {};
        payload['params'].environment = portalEnvs[document.location.hostname] || 'dev';
        await axios.post(process.env.VUE_APP_ANALYTICS_URL, payload);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

const tracker = new Tracker();

export default tracker;
