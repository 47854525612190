<template>
  <Modal title="Company details" modalId="company-modal" ref="modalRef" @on-close="onClose">
    <div class="body">
      <ImagesHeader :coverUrl="coverImageUrl" :logoUrl="logoUrl" @on-upload="onUpload" canEdit />
      <div class="form-container">
        <div class="input-title">Name</div>
        <b-form-input class="input-section" v-model="companyName" disabled></b-form-input>

        <div class="input-title">Description</div>
        <b-form-input class="input-section" v-model="description"></b-form-input>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="outline-primary" @click="onClose">Cancel</b-button>
      <b-button @click="onOverride" variant="primary">Save company details</b-button>
    </template>
  </Modal>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import Modal from '@/components/Modal.vue';
import { getEntity } from '@/queries/talent-pool/locations';
import State from '@/state/State';
import { useQuery } from '@tanstack/vue-query';
import { onMounted, ref, watch } from 'vue';

const modalRef = ref(null);

const description = ref('');
const companyName = ref('');
const logoUrl = ref('');
const coverImageUrl = ref('');

const companyId = State.state.company.company_id;

const entityDetails = useQuery(getEntity(companyId));

const emit = defineEmits(['on-close']);

const onUpload = (url, type) => {
  if (type === 'logo') {
    logoUrl.value = url;
  } else {
    coverImageUrl.value = url;
  }
};

const onOverride = async () => {
  await ApiClient.patchCompany({
    entity_id: companyId,
    description: description.value,
    logo_url: logoUrl.value || undefined,
    cover_image_url: coverImageUrl.value || undefined,
  });

  emit('on-close');
  modalRef.value.modal.hide();
};

const onClose = async () => {
  emit('on-close');
  modalRef.value.modal.hide();
};

watch(entityDetails.fetchStatus, () => {
  if (entityDetails.data.value) {
    description.value = entityDetails.data.value.description;
    logoUrl.value = entityDetails.data.value.logo_url;
    coverImageUrl.value = entityDetails.data.value.cover_image_url;
  }
});

onMounted(() => {
  companyName.value = State.state.company.name;
});
</script>

<style scoped lang="scss">
.body {
  padding: 1rem;
}

.input-section {
  margin-bottom: 1rem;
}

.input-title {
  font-size: 14px;
  font-weight: 600;
  margin: 6px;
}

.override-button {
  margin: 10px 0;
}

.form-container {
  margin-top: 4rem;
}
</style>
