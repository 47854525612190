<template>
  <app-page title="Add Division" :previous="[{ label: 'Divisions', to: { name: 'divisions' } }]">
    <ValidationObserver v-slot="v">
      <b-form @submit.prevent="v.handleSubmit(addDivision)">
        <app-input
          v-model="division.name"
          :validate-immediate="false"
          name="Name"
          label="Name"
          rules="required"
          type="text"
        />
        <app-input
          v-model="division.properties.postcode"
          :rules="`${isEnableFlexibleWork ? 'required|' : ''}postcode`"
          :validate-immediate="false"
          label="Postcode"
          name="Postcode"
          type="text"
        />
        <app-input
          v-if="isEnableFlexibleWork"
          v-model="division.properties.notes"
          :validate-immediate="false"
          label="Location Notes"
          name="Location Notes"
          rows="5"
          type="textarea"
        />
        <AppInput
          v-if="isEnableFlexibleWork"
          v-model="division.properties.weeks_advance_for_shift_fill"
          :type="CONTROL_TYPES.TEXT"
          rules="numeric|min_value:0|max_value:52|required"
          :validate-immediate="false"
          label="How many weeks in advance can this Location look for shifts?"
          name="Weeks in advance for shift fill"
        />
        <PageSubFooter>
          <template v-if="errorMessage" #before>
            <SmeAlert :level="ALERT_LEVELS.DANGER">
              {{ errorMessage }}
            </SmeAlert>
          </template>
          <b-button type="submit" variant="primary" :disabled="saving">
            <b-spinner v-if="saving" class="mr-2" small />
            {{ saving ? 'Adding...' : 'Add' }}
          </b-button>
          <b-button variant="outline-primary" @click="goBackFallback(backTo)"> Cancel </b-button>
        </PageSubFooter>
      </b-form>
    </ValidationObserver>
  </app-page>
</template>

<script setup>
import uuidv4 from 'uuid/v4';
import { ValidationObserver } from 'vee-validate';
import { getCurrentInstance, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppInput, { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { goBackFallback } from '@/utils/navigation';

const currentInstance = getCurrentInstance();
const $router = useRouter();
const { isEnableFlexibleWork } = useFeatureFlags();

const props = defineProps({
  backTo: {
    type: Object,
    default: () => ({ name: 'divisions' }),
  },
});

const division = ref({
  name: '',
  properties: {
    postcode: undefined,
  },
});
const errorMessage = ref('');
const saving = ref(false);

const addDivision = async () => {
  errorMessage.value = '';
  saving.value = true;

  try {
    await ApiClient.addDivision({
      ...division.value,
      company_id: State.state.company.company_id,
      division_id: uuidv4(),
      properties: {
        ...division.value.properties,
        ...(division.value.properties.notes ? { notes: division.value.properties.notes.split('\n') } : null),
        ...(division.value.properties.weeks_advance_for_shift_fill
          ? { weeks_advance_for_shift_fill: division.value.properties.weeks_advance_for_shift_fill }
          : null),
      },
    });
    currentInstance.proxy.$appToast(`${division.value.name} division has been added`, {
      title: 'Successfully added Division',
      variant: 'success',
    });
    if (isEnableFlexibleWork.value) {
      tracker.trackEvent('flexible_work_location_created', { notes: division.value.properties.notes || '' });
    }
    $router.push(props.backTo);
  } catch (error) {
    errorMessage.value = error?.message || 'An error occurred adding the division.';
  } finally {
    saving.value = false;
  }
};
</script>
