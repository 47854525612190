<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- It is used only for formatting for i18n values here, so XSS attack is not a case. -->
  <div class="outer">
    <div class="inner">
      <header class="header">
        <img class="logo" src="@/components/assets/wagestream-logo-small-purple.png" alt="Wagestream Logo" />
        <div class="title1">Give your employees</div>
        <div class="title2">power over pay</div>
        <div class="subtitle">Become a partner in a few easy steps</div>
      </header>
      <template v-if="loading">
        <app-loading />
      </template>
      <template v-else>
        <main class="main">
          <slot></slot>
        </main>
      </template>
    </div>
  </div>
</template>

<script>
import AppLoading from '@/components/AppLoading';

export default {
  name: 'RegisterIntegratePage',
  components: {
    AppLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: undefined,
    },
    steps: {
      type: Number,
      default: 6,
    },
  },
};
</script>

<style scoped lang="scss">
.outer {
  background: var(--palette-color-base-background);
  position: relative;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
}

.outer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.inner {
  max-width: 588px;
  padding: 16px;
  margin: 0 auto;
}

.logo {
  display: block;
  margin: 45px auto 25px auto;
  height: 51px;
  width: 78px;
}

.title1,
.title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
}

.title1 {
  color: var(--palette-color-default-lighten-20);
}

.title2 {
  color: var(--palette-color-brand-primary);
}

@media (max-width: 768px) {
  .title1,
  .title2 {
    font-size: 36px;
  }
}

.subtitle {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: var(--palette-color-default-lighten-20);
  margin-top: 24px;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 70px 0;
}

@media (max-width: 768px) {
  .steps {
    margin: 30px 0 30px 0 !important;
    padding: 0 16px;
  }
}

.steps__item {
  color: var(--palette-color-base-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9000px;
  background: var(--palette-color-default-lighten-30);
  min-height: 32px;
  min-width: 32px;
  font-weight: 300;
  font-size: 25px;
  opacity: 0.25;
}

.steps__line {
  border-top: 1px solid var(--palette-color-default-lighten-30);
  width: 85px;
  opacity: 0.5;
}

.steps__item--active {
  opacity: 1;
}

.steps__line:last-child {
  display: none;
}

.main {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: auto;
  background: var(--palette-color-base-white);
  color: var(--palette-color-default-lighten-30);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-weight: 300;
  font-size: 14px;
  margin: 48px 0;
  padding: 84px;
  // width: 588px;
}

@media (max-width: 768px) {
  .main {
    padding: 32px;
    // width: auto;
  }
}

.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6,
strong {
  color: var(--palette-color-default);
}
</style>
