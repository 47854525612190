import debounce from 'lodash/debounce';

export default (func, wait) => {
  const debounced = debounce(async (resolve, reject, self, args) => {
    try {
      const result = await func.bind(self)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
};
