<script setup>
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { useRoute } from 'vue-router/composables';
import { onMounted } from 'vue';
import useAnalytics from '@/state/composables/useAnalytics';

const { postAnalyticsEvent } = useAnalytics();
const $route = useRoute();

onMounted(async () => {
  const sessionId = $route.query.sessionId;
  const redirectUrl = $route.query.redirectUrl;
  const pricingPlan = $route.query.pricingPlan;
  postAnalyticsEvent({
    intent: 'successful_payment_intent',
    task: 'add_subscription',
    action: 'navigate',
  });
  await ApiClient.postLogSuccessfulStripePaymentIntent(State?.state?.company?.company_id, {
    sessionId,
    pricingPlan,
  });
  window.location.href = redirectUrl;
});
</script>
