<template>
  <app-page title="Advances" subtitle="Employee earnings and advances" icon="chart-line">
    <template v-slot:custom-content>
      <b-col>
        <AppAlert level="warning" class="mb-3">
          This page is currently under development, and is likely to change or be replaced in the near future. Please
          contact support if you have any questions.
        </AppAlert>
        <page-list-header>
          <template #left>
            <filter-dropdown v-model="filters" :filters="filterItems">
              <inline-search v-model="searchText" @search="onSearch" />
            </filter-dropdown>
          </template>
          <template #right>
            <b-form-group label-cols="auto" label="Per page" class="mb-0">
              <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
            </b-form-group>
            <export-button
              :export-data="filteredWages"
              :export-file-rows="exportFileRows"
              :export-headers="exportHeaders"
              file-name="advances"
            />
          </template>
        </page-list-header>

        <sme-card class="mb-3">
          <app-loading :loading="loading" />

          <b-table
            v-if="!loading"
            :items="wages"
            :fields="FIELDS"
            filter="''"
            :filter-function="filterFunction"
            :current-page="currentPage"
            :per-page="perPage"
            class="mb-0"
            table-class="table-minimal"
            :tfoot-class="!wages?.length ? 'd-none' : ''"
            foot-clone
            no-footer-sorting
            responsive
            show-empty
            @filtered="update"
          >
            <template v-slot:cell(employee_id)="data">
              <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
                data.item.employee_code
              }}</router-link>
            </template>
            <template v-slot:cell(full_name)="data">
              <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
                data.item.full_name
              }}</router-link>
            </template>
            <template v-slot:foot()>&nbsp;</template>
            <template v-slot:foot(hours)>{{ totalHours }}</template>
            <template v-slot:foot(wages)>{{ toCurrency(totalWages) }}</template>
            <template v-slot:foot(transfer_amount)>{{ toCurrency(totalTransferAmount) }}</template>
            <template v-slot:foot(transfer_percent)>{{ averageTransferPercent }}</template>
          </b-table>
        </sme-card>

        <b-pagination
          v-model="currentPage"
          :total-rows="filteredWages.length"
          :per-page="perPage"
          class="justify-content-end"
        />
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import AppAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import { toCurrency } from '@/utils';
import { toNumber, toPercentage } from '@/utils/common';

const FIELDS = [
  {
    key: 'employee_code',
    label: 'Employee code',
    sortable: true,
  },
  {
    key: 'full_name',
    label: 'Employee',
    sortable: true,
  },
  {
    key: 'hours',
    label: 'Total hours',
    sortable: true,
    formatter: toNumber,
  },
  {
    key: 'wages',
    label: 'Total earned',
    sortable: true,
    formatter: toCurrency,
  },
  {
    key: 'transfer_amount',
    label: 'Total streamed',
    sortable: true,
    formatter: toCurrency,
  },
  {
    key: 'transfer_percent',
    label: 'Percent streamed',
    sortable: true,
    formatter: toPercentage,
  },
];

const SEARCH_FIELDS = ['full_name', 'employee_code'];

const FILTER_KEYS = {
  STARTS_ON: 'starts_on',
  ENDS_ON: 'ends_on',
};

const STARTS_ON_DEFAULT_VALUE = moment().startOf('month').format('YYYY-MM-DD');
const ENDS_ON_DEFAULT_VALUE = moment().format('YYYY-MM-DD');

const PER_PAGE_OPTIONS = [25, 50, 100];

const wages = ref([]);
const filteredWages = ref([]);
const totalHours = ref(0);
const totalWages = ref(0);
const totalTransferAmount = ref('');
const averageTransferPercent = ref('');
const loading = ref(true);
const filters = ref({
  [FILTER_KEYS.STARTS_ON]: STARTS_ON_DEFAULT_VALUE,
  [FILTER_KEYS.ENDS_ON]: ENDS_ON_DEFAULT_VALUE,
});
const searchText = ref('');
const searchCriteria = ref('');
const currentPage = ref(1);
const perPage = ref(PER_PAGE_OPTIONS[0]);

const exportHeaders = computed(() => FIELDS.map(field => field.label));

const filterItems = computed(() => [
  {
    key: FILTER_KEYS.STARTS_ON,
    label: 'From',
    type: CONTROL_TYPES.DATE,
    defaultValue: STARTS_ON_DEFAULT_VALUE,
    controlProps: {
      max: filters.value[FILTER_KEYS.ENDS_ON] ? filters.value[FILTER_KEYS.ENDS_ON] : null,
    },
  },
  {
    key: FILTER_KEYS.ENDS_ON,
    label: 'To',
    type: CONTROL_TYPES.DATE,
    defaultValue: ENDS_ON_DEFAULT_VALUE,
    controlProps: {
      min: filters.value[FILTER_KEYS.STARTS_ON] ? filters.value[FILTER_KEYS.STARTS_ON] : null,
    },
  },
]);

watch(
  () => wages.value,
  wages => update(wages.filter(filterFunction)),
);
watch(
  () => filters.value,
  () => getWages(),
  { deep: true },
);

const getWages = debounce(async () => {
  loading.value = true;
  wages.value = (await ApiClient.getWages(filters.value))?.data || [];
  loading.value = false;
}, 200);

const update = wages => {
  filteredWages.value = wages;
  currentPage.value = 1;
  totalHours.value = toNumber(filteredWages.value.reduce((acc, value) => acc + parseInt(value.hours), 0));
  totalWages.value = filteredWages.value.reduce((acc, value) => acc + parseInt(value.wages), 0);
  totalTransferAmount.value = filteredWages.value.reduce((acc, value) => acc + parseInt(value.transfer_amount), 0);

  if (filteredWages.value.length > 0) {
    averageTransferPercent.value = toPercentage(
      filteredWages.value.reduce((acc, value) => acc + parseFloat(value.transfer_percent), 0) /
        filteredWages.value.length,
    );
  } else {
    averageTransferPercent.value = '';
  }
};

const filterFunction = item =>
  Object.values(pick(item, SEARCH_FIELDS)).some(value =>
    value.toLowerCase().includes(searchCriteria.value.toLowerCase()),
  );

const onSearch = () => {
  searchCriteria.value = searchText.value;
};

const exportFileRows = item => {
  return FIELDS.map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));
};

getWages();
</script>
