<template>
  <router-link
    :to="{
      name: 'talent-pool-shift-detail',
      params: { shiftId: props.shift?.shift_id, locationId, tab: getShiftState(props.shift) },
    }"
    :class="['shift', getShiftState(props.shift)]"
  >
    <div class="details">
      <span class="time">{{ formatTimeRange(props.shift?.start_at, props.shift?.end_at) }}</span>
      <span class="role">{{ props.shift?.portal_role_name }}</span>
    </div>
    <div v-if="isCompleted(props.shift)" class="action">
      <EmployeeAvatar :size="20" :image-url="props.shift?.winning_claim_employee_photo_url" />
      <span>{{ props.shift?.winning_claim_employee_name }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9714 4.02864C14.2317 4.28899 14.2317 4.7111 13.9714 4.97145L6.97138 11.9714C6.71103 12.2318 6.28892 12.2318 6.02858 11.9714L2.52858 8.47145C2.26823 8.2111 2.26823 7.78899 2.52858 7.52864C2.78892 7.26829 3.21103 7.26829 3.47138 7.52864L6.49998 10.5572L13.0286 4.02864C13.2889 3.76829 13.711 3.76829 13.9714 4.02864Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div v-else-if="shiftActionable" class="action">
      <div v-if="isClaimed(props.shift)" class="claim-count">
        {{ props.shift?.claim_count }}
      </div>
      <span>{{ actionText }}</span>
      <svg
        v-if="shiftActionable"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M9.47138 3.02864C9.21103 2.76829 8.78892 2.76829 8.52858 3.02864C8.26823 3.28899 8.26823 3.7111 8.52858 3.97145L11.8905 7.33337H2.49998C2.13179 7.33337 1.83331 7.63185 1.83331 8.00004C1.83331 8.36823 2.13179 8.66671 2.49998 8.66671H11.8905L8.52858 12.0286C8.26823 12.289 8.26823 12.7111 8.52858 12.9714C8.78892 13.2318 9.21103 13.2318 9.47138 12.9714L13.9714 8.47145C14.2317 8.2111 14.2317 7.78899 13.9714 7.52864L9.47138 3.02864Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </router-link>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EmployeeAvatar from '@/pages/talent-pool/components/EmployeeAvatar.vue';
import { useRoute } from 'vue-router/composables';
import { formatTimeRange } from '@/pages/talent-pool/utils/datetime';
import {
  isClaimed,
  isFulfilled,
  isCompleted,
  getShiftState,
  getFulfilledAction,
} from '@/pages/talent-pool/utils/ShiftStatus';

const route = useRoute();
const locationId = route.params.locationId;

const props = defineProps(['shift']);

const shiftActionable = computed(() => {
  if (isFulfilled(props.shift)) {
    return !!getFulfilledAction(props.shift);
  } else if (isClaimed(props.shift)) {
    return true;
  } else {
    return false;
  }
});

const actionText = computed(() => {
  if (isFulfilled(props.shift)) {
    return getFulfilledAction(props.shift);
  } else if (isClaimed(props.shift)) {
    return 'Approve a worker';
  } else {
    return undefined;
  }
});
</script>

<style lang="scss" scoped>
.shift {
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 4px;
  color: var(--palette-color-default);
  min-height: 82px;
  transition: all 0.1s ease-out;
}

.shift:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  transition: all 0.1s ease-out;
}

.shift.unclaimed {
  border-color: var(--palette-color-shift-states-unclaimed);
}

.shift.claimed {
  border-color: var(--palette-color-shift-states-claimed);
}

.shift.fulfilled {
  border-color: var(--palette-color-shift-states-fulfilled);
}

.shift.completed {
  border-color: var(--palette-color-shift-states-completed);
}

.shift.unfilled {
  border-color: var(--palette-color-shift-states-unfilled);
}

.shift.completed,
.shift.unfilled {
  opacity: 0.6;
}

.details {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.details .role {
  font-size: 13px;
  font-weight: 400;
}

.details .time {
  font-size: 10px;
  font-weight: 500;
  color: var(--palette-color-default-lighten-30);
  text-transform: lowercase;
}

.action {
  padding: 6px 0.5rem;
  display: flex;
  align-items: center;
  gap: 6px;
}

.shift.claimed .action {
  border-radius: 4px;
  background-color: var(--palette-color-shift-states-claimed-lighten-50);
  color: var(--palette-color-shift-states-claimed);
}

.shift.fulfilled .action {
  border-radius: 4px;
  background-color: var(--palette-color-shift-states-fulfilled-lighten-50);
  color: var(--palette-color-shift-states-fulfilled);
}

.shift.completed .action {
  border-radius: 4px;
  background-color: var(--palette-color-shift-states-completed-lighten-50);
  color: var(--palette-color-shift-states-completed);
}

.shift .action span {
  flex-grow: 1;
}

.action .claim-count {
  width: 20px;
  height: 20px;
  background-color: var(--palette-color-shift-states-claimed);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--palette-color-base-white);
}
</style>
