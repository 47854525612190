import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import { useStateComposable } from '@/state/composables';
import {
  isNewEmployee,
  isPendingEmployee,
  isEnrolledEmployee,
  isSalariedEmployee,
  getStateCounts,
} from '@/utils/Employee';

const { computedState, state, reset } = useStateComposable({
  employees: [],
  assignments: {},
  disabledEmployees: {},
  employeeStateCounts: {
    ENROLLED: 0,
    PENDING: 0,
    NEW: 0,
    ELIGIBLE: 0,
  },
  employeesLoading: true,
  disabledEmployeesLoading: true,
  employeeStateCountsLoading: true,
  employeesError: false,
  disabledEmployeesError: false,
  employeeStateCountsError: false,
  employeesInitial: true,
  disabledEmployeesInitial: true,
  employeeStateCountsInitial: true,
});

const employeesCount = computed(() => state.employeeStateCounts.ELIGIBLE);
const enrolledEmployees = computed(() => state.employees.filter(isEnrolledEmployee));
const salariedEmployeesCount = computed(() => enrolledEmployees.value.filter(isSalariedEmployee).length);
const enrollmentPercentage = computed(() => {
  return state.employeeStateCounts.ELIGIBLE === 0
    ? 0
    : Math.round((state.employeeStateCounts.ENROLLED / state.employeeStateCounts.ELIGIBLE) * 100);
});
const newEmployees = computed(() => state.employees.filter(isNewEmployee));
const pendingEmployees = computed(() => state.employees.filter(isPendingEmployee));
const reEnrollingEmployees = computed(() =>
  state.employees.filter(
    employee => !!employee.properties.beneficiary_account_number && !!employee.properties.beneficiary_invalid,
  ),
);

const getEmployees = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.employeesInitial) {
    return;
  }

  reset(['employees', 'employeesLoading', 'employeesError']);

  state.employeesInitial = false;
  state.employeesLoading = true;

  try {
    const employees = await getRawEmployees();

    const assignments = {};

    employees.forEach(employee => {
      const userId = employee.user_id;
      if (userId in assignments) {
        assignments[userId].push(employee);
      } else {
        assignments[userId] = [employee];
      }
    });

    const assignedEmployees = [];
    Object.keys(assignments).forEach(assignmentCode => {
      const assignmentEmployees = assignments[assignmentCode];
      assignmentEmployees.forEach(employee => {
        const newEmployee = { ...employee, assignments: assignmentEmployees };
        assignedEmployees.push(newEmployee);
      });
    });

    state.assignments = assignments;
    state.employees = assignedEmployees;
  } catch (error) {
    state.employeesError = true;
    console.error(error);
  }

  state.employeesLoading = false;
};

const getEnrolmentSnapshot = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.employeeStateCountsInitial) {
    return;
  }

  reset(['employeeStateCounts', 'employeeStateCountsLoading', 'employeeStateCountsError']);

  state.employeeStateCountsInitial = false;
  state.employeeStateCountsLoading = true;

  try {
    const enrolmentSnapshot = await getRawEnrolmentSnapshot();

    state.employeeStateCounts = getStateCounts(enrolmentSnapshot);
  } catch (error) {
    state.employeeStateCountsError = true;
    console.error(error);
  }

  state.employeeStateCountsLoading = false;
};

const getRawEmployees = async () => (await ApiClient.getAllEmployees())?.data || [];

const getRawEnrolmentSnapshot = async () => (await ApiClient.getEnrolmentSnapshot())[0] || [];

export default () => {
  return {
    ...computedState,
    employeesCount,
    enrolledEmployees,
    enrollmentPercentage,
    newEmployees,
    pendingEmployees,
    reEnrollingEmployees,
    salariedEmployeesCount,
    getEmployees,
    getEnrolmentSnapshot,
  };
};
