<template>
  <app-page title="Admins" subtitle="Configure who else in your company can manage the portal" icon="users-cog">
    <template v-slot:header>
      <b-button :to="{ name: 'admins-add' }" variant="outline-primary">
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Add Admin
      </b-button>
    </template>

    <template #custom-content>
      <b-col>
        <users-list
          :users="admins"
          :loading="loading"
          :delete-function="deleteAdmin"
          user-type="admin"
          user-type-plural="admins"
          @updated="getAdmins"
        />
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import UsersList from '@/components/UsersList.vue';

const admins = ref([]);
const loading = ref(true);

onBeforeMount(async () => await getAdmins());

const getAdmins = async () => {
  loading.value = true;
  admins.value = (await ApiClient.getAdmins())?.data || [];
  loading.value = false;
};

const deleteAdmin = async (companyId, userId) => await ApiClient.deleteAdmin(companyId, userId);
</script>
