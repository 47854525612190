<template>
  <div class="banner">
    <div class="title">These candidates want to work for you</div>
    <div class="subtitle">Unlock the shortlist to see their full work history and contact details</div>
    <b-button variant="primary" v-b-modal="'unlock-modal'" class="unlock-button" @click="trackUnlock()">
      <Lock class="lock" :color="PaletteColors['base-white']" width="20" height="20"></Lock>
      Unlock for £{{ toRawCurrency(props.shortlist.properties.total_cost_excl_vat) }}
    </b-button>
    <div class="money-back">Don't hire via Raise within 6 weeks? Your money back, guaranteed</div>
  </div>
</template>

<script setup>
import Lock from '@/assets/icons/Lock.vue';
import { PaletteColors } from '@/Theme';
import tracker from '@/Tracker';
import { toRawCurrency } from '@/utils';

const props = defineProps({
  shortlist: Object,
});

const trackUnlock = () => {
  tracker.trackEvent('shortlist_unlock_click', {
    posting_id: props.shortlist.talent_pool_posting_id,
  });
};
</script>

<style scoped lang="scss">
.banner {
  background: linear-gradient(
    259.72deg,
    var(--palette-color-warning-lighten-90) 0%,
    var(--palette-color-danger-lighten-80) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 3px solid var(--palette-color-base-white);
}

.title {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.subtitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
}
.unlock-button {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.money-back {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
}
</style>
