<template>
  <b-input-group class="sme-form-datepicker">
    <b-input-group-prepend>
      <b-form-datepicker
        v-bind="datePickerProps"
        v-on="$listeners"
        :button-variant="!($attrs.disabled || $attrs.readonly) ? 'light' : 'dark'"
        :value="value"
        class="sme-form-datepicker__picker"
        ref="$datepicker"
        button-only
        hide-header
      />
    </b-input-group-prepend>
    <b-form-input
      v-bind="$attrs"
      :class="['sme-form-datepicker__input', !$attrs.disabled && 'bg-white']"
      :value="value"
      type="text"
      readonly
      @click="onInputClick"
    />
  </b-input-group>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref, useAttrs } from 'vue';

const DEFAULT_DATEPICKER_PROPS = {
  hideHeader: true,
};

const $attrs = useAttrs();

const props = defineProps({
  value: String,
  isNullable: Boolean,
});

const $datepicker = ref(undefined);

const datePickerProps = computed(() => {
  const datePickerProps = { ...DEFAULT_DATEPICKER_PROPS, ...$attrs };

  if (props.isNullable) {
    Object.assign(datePickerProps, {
      labelResetButton: 'Clear',
      resetButton: true,
      resetButtonVariant: 'outline-primary',
      resetValue: null,
    });
  } else {
    Object.assign(datePickerProps, {
      initialDate: new Date(),
    });
  }

  return datePickerProps;
});

const onInputClick = () => $datepicker.value.$el?.querySelector('button')?.click();
</script>

<style lang="scss" scoped>
.sme-form-datepicker__input {
  &:not(:disabled) {
    cursor: pointer;
  }
}
</style>
