<template>
  <b-modal
    ref="modal"
    id="flexible-work-rate-employee-modal"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="hideModal"
  >
    <h2>How was working with {{ employeeBeingRated.user_settings.full_name }}?</h2>
    <div class="flexible-work-rate-employee-modal__container">
      <h4>Your ratings are anonymous and help your employer to match the right people to the right shifts.</h4>
      <b-form-rating
        class="flexible-work-rate-employee-modal__rating mt-4 mb-5"
        v-model="rating"
        variant="warning"
        size="lg"
        @change="handleClick"
      />
    </div>
  </b-modal>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const modal = ref(null);
const rating = ref(0);

defineProps({
  employeeBeingRated: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['rating']);

const handleClick = async () => {
  await setTimeout(() => {
    hideModal();
  }, 1000);
};

const showModal = () => {
  modal.value.show();
};

const hideModal = () => {
  modal.value.hide();
  emit('rating', rating.value);
};

onMounted(async () => {
  showModal();
});
</script>

<style scoped>
.flexible-work-rate-employee-modal__rating {
  border: none;
}

.flexible-work-rate-employee-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

:deep(#flexible-work-rate-employee-modal) {
  margin-top: 10rem;
}

:deep(.modal-dialog) {
  min-width: 40rem;
  min-height: 15rem;
}

:deep(.b-rating-star) {
  font-size: 4rem;
}
</style>
