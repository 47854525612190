<template>
  <section class="division-metrics">
    <page-sub-header title="Compare Divisions" class="mt-4" />
    <sme-card>
      <app-loading :loading="loadingDivisions" />
      <template v-if="!loadingDivisions">
        <template v-if="divisionOptions.length">
          <b-row class="mb-4">
            <b-col sm="4" class="mb-3 mb-sm-0">
              <app-input
                v-model="selectedMetric"
                type="select"
                name="metric"
                label="Metric"
                :options="METRIC_OPTIONS"
                undefined-allow
                undefined-label="-- Please select a metric --"
              />
            </b-col>
            <b-col sm="3" class="mb-3 mb-sm-0">
              <app-input
                v-model="timePeriod"
                type="radio"
                name="time_period"
                label="Time Period"
                :options="TIME_PERIOD_OPTIONS"
                buttons
                button-variant="outline-primary"
                control-class="w-100"
              />
            </b-col>
            <b-col sm="4">
              <list-dropdown
                v-model="selectedDivisions"
                :options="divisionOptions"
                value-text="Divisions"
                id="select_divisions"
                label="Divisions"
              />
            </b-col>
            <b-col sm="1" class="d-flex align-items-end">
              <b-button
                :disabled="!selectedMetric || !selectedDivisions.length"
                :download="`divisions-${selectedMetricSlug}-chart.png`"
                :href="chartImage"
                title="Export to image"
                variant="primary"
              >
                <font-awesome-icon class="icon" :icon="['fad', 'download']" />
              </b-button>
            </b-col>
          </b-row>
          <sme-card v-if="!selectedMetric || !selectedDivisions.length">
            <p class="my-2 text-center">
              No data to display. Please select a metric, time period and division(s) to see results.
            </p>
          </sme-card>
          <template v-else>
            <line-chart
              class="mb-4 mt-2"
              :labels="timeGroupDataset"
              :datasets="selectedDivisionMetricDatasets"
              :chart-options="chartOptions"
            />
          </template>
        </template>
        <template v-else>
          <sme-alert v-if="State.state.claims.a" level="warning">
            <strong>No divisions to compare. </strong>
            <router-link :to="{ name: 'divisions-add' }">Add a division</router-link> now.
          </sme-alert>
          <p v-else class="my-2 text-center">No divisions to compare.</p>
        </template>
      </template>
    </sme-card>
  </section>
</template>

<script setup>
import cloneDeep from 'lodash/cloneDeep';
import kebabCase from 'lodash/kebabCase';
import { computed, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ListDropdown from '@/components/ListDropdown.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import State from '@/state/State';
import PaletteColors from '@/styles/palette-colors.module.scss';
import { getDatasetColor, getInitialAspectRatio, TIME_SERIES } from '@/utils/chart';

const METRICS = {
  ADOPTION: 'Adoption',
  APP_LOGINS: 'App Logins',
  BUILD: 'Build',
  STREAM: 'Stream',
  TRACK: 'Track',
};

const METRIC_TIMESTAMP_ID_MAPPING = 'time';

const METRIC_DATASET_ID_MAPPINGS = {
  [METRICS.ADOPTION]: 'adopted',
  [METRICS.APP_LOGINS]: 'unique_users_in_app',
  [METRICS.BUILD]: 'pots_opened',
  [METRICS.STREAM]: 'streamers',
  [METRICS.TRACK]: 'track_users',
};

const METRIC_API_MAPPINGS = {
  [METRICS.ADOPTION]: 'getAdoptionMetrics',
  [METRICS.APP_LOGINS]: 'getAppLoginsMetrics',
  [METRICS.BUILD]: 'getBuildMetrics',
  [METRICS.STREAM]: 'getStreamMetrics',
  [METRICS.TRACK]: 'getTrackMetrics',
};

const METRIC_LABEL_MAPPINGS = {
  [METRICS.ADOPTION]: 'Number of employees',
  [METRICS.APP_LOGINS]: 'Number of logins',
  [METRICS.BUILD]: 'Number of builders',
  [METRICS.STREAM]: 'Number of streamers',
  [METRICS.TRACK]: 'Number of employees',
};

const METRIC_OPTIONS = Object.values(METRICS).map(metric => ({
  text: metric,
  value: metric,
}));

const TIME_PERIOD_OPTIONS = [
  {
    text: 'Week',
    value: TIME_SERIES.WEEK_TO_WEEK,
  },
  {
    text: 'Month',
    value: TIME_SERIES.MONTH_TO_MONTH,
  },
];

const loadingDivisions = ref(true);
const selectedMetric = ref(undefined);
const divisions = ref([]);
const selectedDivisions = ref([]);
const selectedDivisionMetricDatasets = ref({});
const timePeriod = ref(TIME_SERIES.MONTH_TO_MONTH);
const timeDataset = ref({});
const chartImage = ref(null);

const divisionOptions = computed(() =>
  divisions.value.map(division => ({
    text: division.name,
    value: division.division_id,
  })),
);

const { timeGroupDataset } = useTimeGroupDataset(timeDataset, timePeriod);

const selectedMetricSlug = computed(() => kebabCase(selectedMetric.value));

const chartOptions = computed(() => ({
  animation: {
    onComplete(event) {
      chartImage.value = event.chart.toBase64Image();
    },
  },
  aspectRatio: getInitialAspectRatio(2.5, 2),
  scales: {
    x: { grid: { display: false } },
    y: {
      ticks: { precision: 0 },
      title: { display: true, text: METRIC_LABEL_MAPPINGS[selectedMetric.value] },
    },
  },
  plugins: {
    legend: { display: true, position: 'bottom' },
  },
}));

watch(
  [selectedDivisions, selectedMetric, timePeriod],
  async ([newSelectedDivisions, newSelectedMetric, newTimePeriod], [, prevSelectedMetric, prevTimePeriod]) => {
    if (!newSelectedMetric || !newSelectedDivisions.length) {
      selectedDivisionMetricDatasets.value = {};
      return;
    }

    let newSelectedDivisionMetricDatasets;

    if (newSelectedMetric !== prevSelectedMetric || newTimePeriod !== prevTimePeriod) {
      newSelectedDivisionMetricDatasets = {};
      await Promise.all(
        newSelectedDivisions.map(
          async division => (newSelectedDivisionMetricDatasets[division] = await getDivisionMetrics(division)),
        ),
      );
    } else {
      newSelectedDivisionMetricDatasets = cloneDeep(selectedDivisionMetricDatasets.value);

      Object.keys(selectedDivisionMetricDatasets.value).forEach(division => {
        if (!newSelectedDivisions.includes(division)) {
          delete newSelectedDivisionMetricDatasets[division];
        }
      });

      await Promise.all(
        newSelectedDivisions.map(async division => {
          if (!(division in newSelectedDivisionMetricDatasets)) {
            newSelectedDivisionMetricDatasets[division] = await getDivisionMetrics(division);
          }
        }),
      );
    }

    let longestTimeDivision;
    for (const divisionId in newSelectedDivisionMetricDatasets) {
      const timeValueArray = newSelectedDivisionMetricDatasets[divisionId].timeDataset?.values;

      if (!longestTimeDivision || timeValueArray > longestTimeDivision.timeDataset.values) {
        longestTimeDivision = newSelectedDivisionMetricDatasets[divisionId];
      }
    }
    timeDataset.value = longestTimeDivision.timeDataset;
    let currentIndex = 0;

    selectedDivisionMetricDatasets.value = Object.keys(newSelectedDivisionMetricDatasets).map(divisionId => {
      const divisionMetricDataset = newSelectedDivisionMetricDatasets[divisionId].metricDataset;
      const timeDatasetLength = timeDataset.value.values.length;

      if (divisionMetricDataset.values.length < timeDatasetLength) {
        const difference = timeDatasetLength - divisionMetricDataset.values.length;
        for (let i = 0; i < difference; i++) {
          divisionMetricDataset.values.unshift(0);
        }
      }

      currentIndex += 1;

      return {
        name: divisions.value.find(div => div.division_id === divisionId).name,
        values: divisionMetricDataset.values,
        lineColor: PaletteColors[getDatasetColor(currentIndex)],
      };
    });
  },
);

const getDivisionMetrics = async divisionId => {
  if (!selectedMetric.value) {
    return;
  }

  const { results } = await ApiClient[METRIC_API_MAPPINGS[selectedMetric.value]](timePeriod.value, divisionId);

  const timeDataset = results?.find(result => result.id === METRIC_TIMESTAMP_ID_MAPPING) || null;
  const metricDataset = results?.find(result => result.id === METRIC_DATASET_ID_MAPPINGS[selectedMetric.value]) || null;
  return { timeDataset, metricDataset };
};

const getDivisions = async () => {
  loadingDivisions.value = true;

  if (State.state.claims.m) {
    divisions.value = State.state.divisions;
  } else {
    divisions.value = (await ApiClient.getDivisions())?.data || [];
  }

  loadingDivisions.value = false;
};

getDivisions();
</script>
