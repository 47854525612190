<template>
  <div class="page">
    <app-loading :loading="loading" v-if="loading" />

    <div class="left-column">
      <img class="raise-logo" :src="themeImage('raise-logo-without-icon.svg')" alt="Raise" />
      <div class="heading">
        <h1>Here are your candidates!</h1>
      </div>
      <p class="text primary">Pay to unlock these candidates and move forward.</p>
      <p class="text secondary">Posting jobs is always free on Raise.</p>
    </div>
    <div class="right-column">
      <Shortlist :postingId="postingId" postingType="job" :isPublic="true"></Shortlist>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import { themeImage } from '@/Theme';
import Shortlist from '@/pages/talent-pool/components/Shortlist/index.vue';
import { useRoute } from 'vue-router/composables';
import tracker from '@/Tracker';

const route = useRoute();
const loading = ref(false);
const postingId = ref(route.params.posting_id);

onMounted(async () => {
  await tracker.trackAnonymousEngagement('raise_gtm_access', {
    posting_id: route.params.posting_id,
  });
});
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 100%;
  width: 30%;
  padding: 4rem;
}

.left-column .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  text-align: center;

  h1 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
  }
}

.text {
  text-align: center;
  margin: 0;

  &.primary {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  }

  &.secondary {
    color: var(--palette-color-default-ligthen-20);
    font-size: 16px;
    line-height: 24px;
  }
}

.right-column {
  width: 70%;
  height: 100%;
  background-color: var(--palette-color-default-lighten-95);
  padding: 2rem 1rem;
}
</style>
