<template>
  <div
    :style="{
      backgroundColor: getReasonBackgroundColor(props.reason),
      color: getReasonTextColor(props.reason),
    }"
    class="reason"
  >
    {{ props.reasonOptions?.find(r => props.reason === r.name)?.display_value || props.reason }}
  </div>
</template>

<script setup>
import { PaletteColors } from '@/Theme';

const props = defineProps({
  reason: {
    type: String,
    required: true,
  },
  reasonOptions: {
    type: Array,
    required: true,
  },
});

const getReasonBackgroundColor = currentReason => {
  if (currentReason === 'missing-hours') {
    return PaletteColors['warning-lighten-90'];
  } else if (currentReason === 'LAST_DAY_PAY') {
    return PaletteColors['danger-lighten-80'];
  } else {
    return PaletteColors['default'];
  }
};

const getReasonTextColor = currentReason => {
  if (currentReason === 'missing-hours') {
    return PaletteColors['warning-darken-10'];
  } else if (currentReason === 'LAST_DAY_PAY') {
    return PaletteColors['danger-darken-10'];
  } else {
    return PaletteColors['base-white'];
  }
};
</script>

<style scoped>
.reason {
  text-transform: capitalize;
  width: fit-content;
  padding: 0.15rem 0.5rem;
  border-radius: 4rem;
  font-weight: 500;
  text-align: right;
}
</style>
