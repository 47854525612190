<template>
  <sub-page
    v-if="hasInvalidBankDetailsEmployees"
    title="Invalid Bank Details"
    subtitle="We have detected that the following employees have invalid bank details"
  >
    <header class="d-flex flex-column flex-sm-row align-items-end mb-3 justify-content-sm-between">
      <sme-alert :level="ALERT_LEVELS.DANGER" class="mb-3 mb-sm-0 w-100">
        Our internal checks have flagged these users' banking details as invalid. Please check that the correct banking
        details are held for an open and unblocked account, both in your systems and the Wagestream Portal.
      </sme-alert>
      <export-button
        :file-name="exportFilename"
        :export-data="invalidBankDetailsEmployees"
        :export-file-rows="exportFileRows"
        :export-headers="EXPORT_HEADERS"
      />
    </header>

    <sme-detailed-table
      :fields="FIELDS"
      :items="invalidBankDetailsEmployees"
      sort-by="full_name"
      tbody-tr-class="sme-detailed-table__row--link"
      sort-asc
      sticky-header
      @row-clicked="item => $router.push({ name: 'employee', params: { employeeId: item.employee_id } })"
    >
      <template #cell(details)="data">
        <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
          <strong class="sme-detailed-table__label sme-detailed-table__label--large">
            {{ data.item.employee_name }}
          </strong>
          <span class="sme-detailed-table__value sme-detailed-table__value--primary">
            {{ data.item.employee_code }}
          </span>
        </div>
      </template>

      <template #cell(rejection_reasons)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Rejection reasons:</span>
          <strong class="sme-detailed-table__value">{{
            toTitleCase(data.item.rejection_reasons.join(' ').replace('-', ' '))
          }}</strong>
        </div>
      </template>

      <template #cell(account_number)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal account:</span>
          <strong class="sme-detailed-table__value">{{ data.item.account_number }}</strong>
        </div>
      </template>

      <template #cell(sort_code)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal sort code:</span>
          <strong class="sme-detailed-table__value">{{ data.item.sort_code }}</strong>
        </div>
      </template>
    </sme-detailed-table>
  </sub-page>
</template>

<script setup>
import moment from 'moment/moment';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import ExportButton from '@/components/ExportButton.vue';
import SubPage from '@/components/SubPage.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import { toTitleCase } from '@/utils/common';

const $router = useRouter();
const { invalidBankDetailsEmployees, hasInvalidBankDetailsEmployees } = useEmployeesReport();

const FIELDS = [
  {
    key: 'employee_name',
    label: 'Name',
  },
  {
    key: 'sort_code',
    label: 'Sort Code',
  },
  {
    key: 'account_number',
    label: 'Account No.',
  },
  {
    key: 'reason_invalid',
    label: 'Reason Invalid',
  },
];

const EXPORT_HEADERS = ['Employee Code', 'Name', 'Sort Code', 'Account No.', 'Reason Invalid'];

const exportFilename = computed(() => {
  const today = moment().format('YYYY-MM-DD');
  let companyName = '';
  if (State.state.company.name) {
    companyName = State.state.company.name.toLowerCase()?.replace(/ /g, '-');
  }

  return `payroll-update-list-${companyName}-${today}`;
});

const exportFileRows = item => {
  return [item.employee_code, item.employee_name, item.sort_code, item.account_number, item.reason_invalid];
};
</script>
