<template>
  <div class="user-menu-container">
    <div v-b-toggle.user-menu class="avatar" aria-expanded="false" data-toggle="dropdown">
      <UserFilled />
    </div>

    <b-collapse id="user-menu" class="dropdown-menu show user-menu">
      <router-link v-if="props.canManageTeam" :to="{ name: 'talent-pool-team' }" class="item">
        <div class="icon-wrapper">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.99971 5.5835C6.25081 5.5835 4.83305 7.00126 4.83305 8.75016C4.83305 9.68071 5.23442 10.5175 5.87348 11.0969C5.75463 11.1576 5.63813 11.2236 5.52434 11.2946C4.78195 11.7583 4.18471 12.4211 3.80065 13.2077C3.6391 13.5385 3.77635 13.9377 4.1072 14.0993C4.43805 14.2608 4.83723 14.1236 4.99878 13.7927C5.27325 13.2306 5.70008 12.7569 6.23065 12.4255C6.76121 12.0941 7.37418 11.9185 7.99972 11.9185C8.62526 11.9185 9.23822 12.0941 9.76879 12.4255C10.2993 12.7569 10.7262 13.2306 11.0007 13.7927C11.1622 14.1236 11.5614 14.2608 11.8922 14.0993C12.2231 13.9377 12.3603 13.5385 12.1988 13.2077C11.8147 12.4211 11.2175 11.7583 10.4751 11.2946C10.3613 11.2236 10.2448 11.1576 10.1259 11.0969C10.765 10.5175 11.1664 9.68071 11.1664 8.75016C11.1664 7.00126 9.74862 5.5835 7.99971 5.5835ZM6.16638 8.75016C6.16638 7.73764 6.98719 6.91683 7.99971 6.91683C9.01224 6.91683 9.83305 7.73764 9.83305 8.75016C9.83305 9.76268 9.01224 10.5835 7.99971 10.5835C6.98719 10.5835 6.16638 9.76268 6.16638 8.75016Z"
              fill="currentColor"
            />
            <path
              d="M3.33509 3.98228C3.57573 3.90396 3.83369 3.89575 4.07883 3.9586C4.32397 4.02145 4.54615 4.15277 4.71943 4.33721C4.89271 4.52164 5.00992 4.75158 5.05737 5.00016C5.12641 5.36182 5.47556 5.59904 5.83722 5.53C6.19888 5.46097 6.43609 5.11182 6.36706 4.75016C6.27216 4.25301 6.03773 3.79312 5.69118 3.42425C5.34462 3.05538 4.90024 2.79275 4.40997 2.66705C3.9197 2.54135 3.40377 2.55777 2.92248 2.71439C2.4412 2.87101 2.01442 3.16137 1.69202 3.55153C1.36962 3.9417 1.16492 4.41556 1.10182 4.91774C1.03873 5.41992 1.11985 5.92969 1.33571 6.38748C1.44972 6.62926 1.59881 6.85126 1.77713 7.04683L1.77386 7.04846C1.16009 7.35534 0.626523 7.8014 0.215713 8.35106C-0.00470801 8.64598 0.0556864 9.06375 0.350607 9.28417C0.645528 9.50459 1.0633 9.4442 1.28372 9.14928C1.57016 8.76602 1.94219 8.455 2.37014 8.24103C2.7981 8.02705 3.27013 7.91603 3.7486 7.91683C4.11679 7.91745 4.41576 7.61947 4.41638 7.25128L4.41638 7.24949C4.41632 7.18728 4.40774 7.12708 4.39175 7.06997C4.31308 6.78907 4.05503 6.58319 3.74904 6.5835C3.49597 6.58375 3.24806 6.51198 3.03427 6.37658C2.82048 6.24117 2.64963 6.04772 2.5417 5.81882C2.43377 5.58993 2.39321 5.33504 2.42475 5.08395C2.4563 4.83286 2.55865 4.59593 2.71985 4.40085C2.88105 4.20577 3.09444 4.06059 3.33509 3.98228Z"
              fill="currentColor"
            />
            <path
              d="M11.9206 3.9586C12.1657 3.89575 12.4237 3.90396 12.6643 3.98228C12.905 4.06059 13.1184 4.20577 13.2796 4.40085C13.4408 4.59593 13.5431 4.83286 13.5747 5.08395C13.6062 5.33504 13.5657 5.58993 13.4577 5.81882C13.3498 6.04772 13.179 6.24117 12.9652 6.37658C12.7514 6.51198 12.5035 6.58375 12.2504 6.5835C11.8822 6.58312 11.5834 6.8813 11.583 7.24949C11.583 7.34153 11.6015 7.42924 11.6352 7.50904C11.6983 7.65882 11.8147 7.78071 11.9606 7.85104C12.0484 7.89336 12.1468 7.91701 12.2508 7.91683C12.7293 7.91603 13.2013 8.02705 13.6293 8.24103C14.0572 8.455 14.4293 8.76602 14.7157 9.14927C14.9361 9.4442 15.3539 9.50459 15.6488 9.28417C15.9437 9.06375 16.0041 8.64598 15.7837 8.35106C15.3729 7.8014 14.8393 7.35534 14.2256 7.04846L14.2223 7.04683C14.4006 6.85126 14.5497 6.62925 14.6637 6.38748C14.8796 5.92969 14.9607 5.41992 14.8976 4.91774C14.8345 4.41556 14.6298 3.9417 14.3074 3.55153C13.985 3.16137 13.5582 2.87101 13.0769 2.71439C12.5957 2.55777 12.0797 2.54135 11.5895 2.66705C11.0992 2.79275 10.6548 3.05538 10.3082 3.42425C9.96169 3.79312 9.72727 4.25301 9.63237 4.75016C9.56333 5.11182 9.80055 5.46097 10.1622 5.53C10.5239 5.59904 10.873 5.36182 10.9421 5.00016C10.9895 4.75158 11.1067 4.52164 11.28 4.33721C11.4533 4.15277 11.6755 4.02145 11.9206 3.9586Z"
              fill="currentColor"
            />
          </svg>
        </div>
        Team
      </router-link>
      <router-link :to="{ name: 'sme-dashboard' }" class="item">
        <img class="ws-logo" :src="themeImage('logo-small.svg')" :alt="`${partnershipName} Logo`" />
        Switch to {{ partnershipName }}
      </router-link>
      <div @click="signOut" class="sign-out item">
        <div class="icon-wrapper">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.8409 8.92034C11.592 8.92034 11.3903 8.71361 11.3903 8.45857V7.53503C11.3903 7.27999 11.592 7.07326 11.8409 7.07326C12.0897 7.07326 12.2914 7.27999 12.2914 7.53503V8.45857C12.2914 8.71432 12.0897 8.92034 11.8409 8.92034Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.53201 2.22819V13.7689C9.53201 14.0552 9.66162 14.318 9.88621 14.4899C10.0401 14.6064 10.2203 14.6668 10.4054 14.6668C10.4906 14.6668 10.5773 14.6533 10.6618 14.627L14.5546 13.4144C15.0204 13.2702 15.3337 12.8368 15.3337 12.3367V3.66323C15.3337 3.16452 15.021 2.73188 14.5553 2.58624L10.6591 1.37286C10.3901 1.28903 10.1094 1.34089 9.88482 1.51139C9.66093 1.68118 9.53201 1.94261 9.53201 2.22819ZM14.0375 3.81313V12.1868L10.8289 13.1863V2.81358L14.0375 3.81313Z"
              fill="currentColor"
            />
            <path
              d="M8.1672 2.93721H6.56949V4.57471C6.56949 4.94484 6.2756 5.24605 5.91516 5.24605C5.55403 5.24605 5.26014 4.94484 5.26014 4.57471V2.72763C5.26014 2.10247 5.75643 1.59452 6.36571 1.59452H8.16789C8.52902 1.59452 8.82292 1.89574 8.82292 2.26587C8.82223 2.6367 8.52833 2.93721 8.1672 2.93721Z"
              fill="currentColor"
            />
            <path
              d="M6.56949 11.4196C6.56949 11.0494 6.27629 10.7482 5.91516 10.7482C5.55333 10.7482 5.26013 11.0487 5.25944 11.4188V13.2659C5.25944 13.8904 5.75573 14.399 6.36501 14.399H8.1672C8.52833 14.399 8.82222 14.0978 8.82222 13.7277C8.82222 13.3583 8.52833 13.0571 8.1672 13.0571H6.56949V11.4196Z"
              fill="currentColor"
            />
            <path
              d="M3.35745 4.97181L0.853103 7.53854C0.604955 7.79358 0.604955 8.20633 0.853103 8.46137L3.35745 11.0281C3.60629 11.2824 4.00901 11.2824 4.25715 11.0281C4.5053 10.7731 4.5053 10.3603 4.25715 10.106L2.83828 8.65247H7.98144C8.33287 8.65247 8.61775 8.36049 8.61775 8.00031C8.61775 7.64013 8.33287 7.34815 7.98144 7.34815H2.83828L4.25715 5.89393C4.5053 5.63889 4.5053 5.22614 4.25715 4.97181C4.00831 4.71748 3.6056 4.71748 3.35745 4.97181Z"
              fill="currentColor"
            />
          </svg>
        </div>
        Sign out
      </div>
    </b-collapse>
  </div>
</template>

<script setup>
import { themeImage } from '@/Theme';
import { getPartnershipData } from '@/utils/partnership';
import { logout } from '@/ApiClient';
import UserFilled from '@/assets/icons/UserFilled.vue';

const props = defineProps(['canManageTeam']);

const { name: partnershipName } = getPartnershipData();

const signOut = () => {
  logout();
};
</script>

<style scoped lang="scss">
.user-menu-container {
  position: relative;
  cursor: pointer;
}

.avatar {
  border-radius: 50%;
  padding: 0.375rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-color-default-lighten-90);
  background-color: var(--palette-color-base-white);
  width: 2.75rem;
  height: 2.75rem;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--palette-color-default);
  }
}

.avatar.collapsed:hover {
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

.avatar.not-collapsed {
  background-color: var(--palette-color-brand-primary);
  border-color: var(--palette-color-brand-primary);

  svg {
    fill: var(--palette-color-base-white);
  }

  &:hover {
    background-color: var(--palette-color-brand-primary-darken-10);

    svg {
      opacity: 0.75;
    }
  }
}

.btn-group {
  border: 0 !important;
}

.user-menu {
  padding: 0;
  left: unset !important;
  right: 0 !important;
  top: calc(0.5rem + 100%) !important;
  min-width: 240px !important;
}

.user-menu .item {
  padding: 0.75rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  color: var(--palette-color-default);
  transition: 0.2s;
}

.user-menu .item:hover {
  background-color: var(--palette-color-default-lighten-95);
}

.user-menu .item:last-child {
  border-bottom: 0;
}

.icon-wrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu .sign-out {
  color: var(--palette-color-danger);
}

.dropdown-menu {
  border-radius: 0.5rem;
}

.icon {
  font-size: 44px;
  color: var(--palette-color-default-lighten-50);
}

.ws-logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--palette-color-default-lighten-90);
  object-fit: contain;
}
</style>
