import moment from 'moment';
import {
  hasApprovedClaim,
  hasAssignedClaim,
  hasFulfilledClaim,
  hasPendingClaims,
  hasWorkedClaim,
} from '@/utils/flexible-work/Claim';

export const SHIFT_STATUS = {
  UNCLAIMED: 'UNCLAIMED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  WORKED: 'WORKED',
  FULFILLED: 'FULFILLED',
  UNFILLED: 'UNFILLED',
};

export const SHIFT_STATUS_ICON_MAPPINGS = {
  [SHIFT_STATUS.UNFILLED]: 'user-slash',
  [SHIFT_STATUS.UNCLAIMED]: 'hand-back-fist',
  [SHIFT_STATUS.PENDING]: 'hand',
  [SHIFT_STATUS.APPROVED]: 'hand',
  [SHIFT_STATUS.WORKED]: 'hand-holding',
  [SHIFT_STATUS.FULFILLED]: 'hand-holding-dollar',
};

export const getShiftStatus = (shift, claims) => {
  switch (true) {
    case moment().isAfter(shift.end_at) && !hasAssignedClaim(claims):
      return SHIFT_STATUS.UNFILLED;
    case hasPendingClaims(claims):
      return SHIFT_STATUS.PENDING;
    case hasApprovedClaim(claims):
      return SHIFT_STATUS.APPROVED;
    case hasWorkedClaim(claims):
      return SHIFT_STATUS.WORKED;
    case hasFulfilledClaim(claims):
      return SHIFT_STATUS.FULFILLED;
    default:
      return SHIFT_STATUS.UNCLAIMED;
  }
};

export const isShiftInThePast = shift => moment().isAfter(shift.end_at);
