<template>
  <RegisterPage :noContinue="true">
    <template #banner-content>
      <video controls>
        <source src="https://22239477.fs1.hubspotusercontent-na1.net/hubfs/22239477/Wagestream_FWF_Video.mp4" />
      </video>
    </template>
    <template #banner-cta-bottom>
      <a href="https://wagestream.com/en/mission" target="_blank">Find out more about our mission</a>
    </template>
    <template #component>
      <div class="d-flex flex-column">
        <h1>Book a Demo with us</h1>
        <p class="px-5">
          Why not book in a short call with a member of our team? This is a no-obligation chance to ask any questions
          you might have about Wagestream, and find out how it might benefit your employees and your organization.
        </p>
        <b-button
          class="book-demo"
          variant="primary"
          href="https://wagestream.com/meetings/sme-demo/30"
          target="_blank"
          @click="postAnalyticsEvent({ intent: 'click_registration_book_demo', action: 'click' })"
          >Book a Demo</b-button
        >
      </div>
      <Logos />
    </template>
  </RegisterPage>
</template>

<script setup>
import { nextTick, onMounted } from 'vue';
import useAnalytics from '@/state/composables/useAnalytics';
import RegisterPage from '@/pages/register/components/RegisterPage.vue';
import Logos from '@/pages/register/components/Logos.vue';

const { postAnalyticsEvent } = useAnalytics();

onMounted(() => {
  postAnalyticsEvent({
    intent: 'view_registration_book_demo',
    action: 'view',
  });

  nextTick(() => {
    const meetingsScript = document.createElement('script');
    meetingsScript.setAttribute('src', 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');
    document.head.appendChild(meetingsScript);
  });
});
</script>

<style scoped lang="scss">
h1,
p {
  text-align: center;
}

.book-demo {
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  padding: 0 33px;
}

.meetings-iframe-container {
  border-radius: 10px;
}
</style>
