<template>
  <BarChartGenerator :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
export const CHART_PRESET_OPTIONS = {
  CURRENCY: {
    scales: {
      y: {
        ticks: {
          callback: label => (!isNaN(label) ? toCurrency(label * 100) : label),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context =>
            `${context.dataset.label ? `${context.dataset.label}: ` : ''}${
              !isNaN(context.parsed.y) ? toCurrency(context.parsed.y * 100) : ''
            }`,
        },
      },
    },
  },
};
</script>

<script setup>
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import merge from 'lodash/merge';
import { computed } from 'vue';
import { Bar as BarChartGenerator } from 'vue-chartjs';
import { PaletteColors } from '@/Theme';
import { toCurrency } from '@/utils';
import { DEFAULT_TOOLTIP_STYLES, getDefaultGridStyles } from '@/utils/chart';
import { hexToRGB, isValidHex } from '@/utils/color';

const DEFAULT_DATASET_STYLES = {
  borderRadius: 8,
  borderWidth: 1,
  maxBarThickness: 35,
};

ChartJS.register(CategoryScale, Legend, LinearScale, BarElement, Title, Tooltip);

const props = defineProps({
  seriesX: {
    type: Object,
    required: true,
  },
  seriesY: {
    type: [Array, Object],
    required: true,
  },
  chartDatasetOptions: Object,
  chartOptions: Object,
});

const chartData = computed(() => ({
  labels: props.seriesX.values.map(value => value.name),
  datasets: (Array.isArray(props.seriesY)
    ? props.seriesY.map(dataset => ({
        data: dataset.values,
        label: dataset.name,
        backgroundColor: hexToRGB(getColor(dataset.color), 0.5),
        borderColor: getColor(dataset.color),
      }))
    : [
        {
          data: props.seriesY.values,
          label: props.seriesY.name,
          backgroundColor: props.seriesX.values.map(value => hexToRGB(getColor(value.color), 0.5)),
          borderColor: props.seriesX.values.map(value => getColor(value.color)),
        },
      ]
  ).map(dataset => merge(dataset, DEFAULT_DATASET_STYLES, props.chartDatasetOptions)),
}));

const chartOptions = computed(() => {
  const defaultGridStyles = getDefaultGridStyles();

  return merge(
    {
      aspectRatio: 2,
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: merge({}, defaultGridStyles),
        y: merge({}, defaultGridStyles, {
          title: {
            display: !!props.seriesY.name,
            text: props.seriesY.name,
          },
        }),
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: merge({}, DEFAULT_TOOLTIP_STYLES, {
          callbacks: {
            label: context => {
              let label = context.dataset.label ? `${context.dataset.label}: ` : '';
              const rawValue = context.raw;

              if (Array.isArray(rawValue)) {
                label += props.seriesX.values[context.dataIndex].isNegative
                  ? context.raw[0] - context.raw[1]
                  : context.raw[1];
              } else {
                label += rawValue;
              }

              return label;
            },
          },
        }),
        title: {
          color: PaletteColors['default-lighten-30'],
          display: !!props.seriesY.description,
          text: props.seriesY.description,
        },
      },
    },
    props.chartOptions,
  );
});

const getColor = color => (isValidHex(color) ? color : PaletteColors['brand-primary']);
</script>
