<template>
  <div>
    <table class="custom-table">
      <thead>
        <tr>
          <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in filteredUsers" :key="user.key">
          <td>
            <div>
              <strong>{{ user.username }}</strong> <strong v-if="user.user_id === currentUserId">(You)</strong>
            </div>
            <div class="email">{{ user.email }}</div>
          </td>
          <td>{{ user.phone }}</td>
          <td>{{ user.rolename }}</td>
          <td>{{ user.sites.join(', ') }}</td>
          <td>
            <b-button
              v-if="user.user_id !== currentUserId && !props.hideDelete"
              @click="deleteUserRole = user"
              v-b-modal="'confirm-delete-modal'"
              title="Delete user"
              class="delete-btn"
              variant="outline-danger"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.49967 5.83337C6.86786 5.83337 7.16634 6.13185 7.16634 6.50004V10.5C7.16634 10.8682 6.86786 11.1667 6.49967 11.1667C6.13148 11.1667 5.83301 10.8682 5.83301 10.5V6.50004C5.83301 6.13185 6.13148 5.83337 6.49967 5.83337Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.49967 5.83337C9.86786 5.83337 10.1663 6.13185 10.1663 6.50004V10.5C10.1663 10.8682 9.86786 11.1667 9.49967 11.1667C9.13148 11.1667 8.83301 10.8682 8.83301 10.5V6.50004C8.83301 6.13185 9.13148 5.83337 9.49967 5.83337Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.49967 0.833374C6.05765 0.833374 5.63372 1.00897 5.32116 1.32153C5.0086 1.63409 4.83301 2.05801 4.83301 2.50004V2.83337H2.49967C2.13148 2.83337 1.83301 3.13185 1.83301 3.50004C1.83301 3.86823 2.13148 4.16671 2.49967 4.16671H2.83301V13C2.83301 13.3095 2.95592 13.6062 3.17472 13.825C3.39351 14.0438 3.69026 14.1667 3.99967 14.1667H11.9997C12.3091 14.1667 12.6058 14.0438 12.8246 13.825C13.0434 13.6062 13.1663 13.3095 13.1663 13V4.16671H13.4997C13.8679 4.16671 14.1663 3.86823 14.1663 3.50004C14.1663 3.13185 13.8679 2.83337 13.4997 2.83337H11.1663V2.50004C11.1663 2.05801 10.9907 1.63409 10.6782 1.32153C10.3656 1.00897 9.9417 0.833374 9.49967 0.833374H6.49967ZM9.83301 2.83337V2.50004C9.83301 2.41164 9.79789 2.32685 9.73538 2.26434C9.67286 2.20183 9.58808 2.16671 9.49967 2.16671H6.49967C6.41127 2.16671 6.32648 2.20183 6.26397 2.26434C6.20146 2.32685 6.16634 2.41164 6.16634 2.50004V2.83337H9.83301ZM4.16634 4.16671V12.8334H11.833V4.16671H4.16634Z"
                  fill="currentColor"
                /></svg
            ></b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <Dialog
      modalId="confirm-delete-modal"
      title="Are you sure you want to delete this user?"
      okBtnLabel="Delete"
      okBtnVariant="danger"
      :okBtnDisabled="deletingUser"
      @on-ok="deleteUser"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import Dialog from '@/components/Dialog.vue';

const props = defineProps({
  users: Object,
  search: String,
  currentUserId: String,
  hideDelete: Boolean,
});

const emit = defineEmits(['deleteUser']);

const deleteUserRole = ref(null);
const deletingUser = ref(false);

const fields = [
  { key: 'email', label: 'Email' },
  { key: 'phone', label: 'Phone number' },
  { key: 'role', label: 'Role' },
  // { key: 'brands', label: 'Brands' } // TODO: Not supported on BE yet
  { key: 'sites', label: 'Sites' },
];

const filteredUsers = computed(() => {
  if (!props.search) {
    return props.users;
  }
  return Object.values(props.users).filter(user => user.email.toLowerCase().includes(props.search.toLowerCase()));
});

const deleteUser = async () => {
  deletingUser.value = true;
  await emit('deleteUser', deleteUserRole.value);
  deletingUser.value = false;
};
</script>

<style scoped lang="scss">
/* Custom table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  padding: 1rem 0.5rem;
}

.custom-table tbody tr {
  transition: background-color 0.2s;
}

.custom-table tbody tr:hover {
  background-color: var(--palette-color-default-lighten-95);
}

.custom-table th {
  background-color: var(--palette-color-primary-lighten-10);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.delete-btn {
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email {
  opacity: 70%;
}
</style>
