<template>
  <b-nav v-if="visibleItems.length" class="page-tabs mb-4" justified tabs>
    <b-nav-item v-for="(item, index) in visibleItems" :active="item.active" :key="`${item.to}${index}`" :to="item.to">
      <font-awesome-icon :icon="['fad', item.icon]" class="page-tabs__icon" />
      {{ item.name }}
    </b-nav-item>
  </b-nav>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const $router = useRouter();
const $route = useRoute();

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const visibleItems = computed(() =>
  props.items
    .filter(item => item.visible)
    .map(item => ({
      ...item,
      active:
        typeof item.to === 'object'
          ? item.to.query
            ? $router.resolve(item.to)?.route.fullPath === $route.fullPath
            : $router.resolve(item.to)?.route.path === $route.path
          : $route.name === item.to,
      to: typeof item.to === 'object' ? item.to : { name: item.to },
    })),
);
</script>

<style lang="scss" scoped>
.page-tabs {
  :deep(.nav-link) {
    align-items: center;
    display: flex;
    font-size: 14px !important;
    justify-content: center;
    padding-bottom: 0.8rem !important;
    padding-top: 0.8rem !important;

    @media (min-width: 768px) {
      font-size: 16px !important;
    }

    &.active {
      background-color: var(--palette-color-base-background) !important;
      border-bottom-color: var(--palette-color-base-background) !important;
      color: var(--palette-color-brand-primary) !important;
    }
  }
}

.page-tabs__icon {
  font-size: 14px;
  margin-right: 0.8rem;

  @media (max-width: 576px) {
    display: none;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
}
</style>
