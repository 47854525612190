<template>
  <app-page title="Add an employee" subtitle="Invite an employee to join wagestream" :error="error" icon="user-plus">
    <template v-slot:infobox>
      <sme-info-box title="What happens after I invite an employee?" id="after-invitation" save-state>
        <p>The employee will receive an email inviting them to download the app.</p>
        <p>Once they install the app, you will <strong>enroll</strong> them to complete the setup of their account.</p>
        <p>
          You will be notified whenever an employee enrolls and will be prompted if any action is required on your part.
        </p>
      </sme-info-box>

      <sme-info-box title="Are shift and salaried employees handled differently?" id="shift-and-salaried" save-state>
        <p>Yes.</p>
        <p>
          Salaried workers accrue earned wages according to their known salaries. The <strong>start date</strong> below
          can be used to control when this accrual should begin.
        </p>
        <p>
          Shift workers only accrue earned wages when we are informed about worked
          <router-link :to="{ name: 'shifts' }">shifts</router-link>. This can be done manually or automated via one of
          our <router-link :to="{ name: 'sme-integrations' }">rota integrations</router-link>.
        </p>
      </sme-info-box>

      <sme-info-box title="What is an employee code?" id="employee-code" save-state>
        <p>
          All employees must have a unique <strong>employee code</strong>. We will generate these for you by default
          (e.g. WS-00001) however we recommend that you change them to correspond to the same identifiers used in e.g.
          your payroll software.
        </p>
        <p>
          You need to ensure that your choice of employee code allows us to attribute worked
          <router-link :to="{ name: 'shifts' }">shifts</router-link> to employees. That is, the same code should appear
          in both.
        </p>
        <p>
          This is all handled automatically by
          <router-link :to="{ name: 'sme-integrations' }">integrations</router-link> so is primarily a concern for
          companies that perform manual shift uploads.
        </p>
      </sme-info-box>
    </template>

    <template v-if="loading">
      <app-loading />
    </template>
    <template v-else>
      <p v-if="!canAddSingleEmployee || !canAddEmployees">
        You do not have the ability to add a single employee enabled. Please contact your account manager for questions.
      </p>

      <employee-add-form
        v-if="canAddEmployees && canAddSingleEmployee"
        v-model="employee"
        :balance="balance"
        :field-permissions="fieldPermissions"
        :save-error="saveError"
        :saving="saving"
        :state="state"
        :integrations-state="integrationsState"
        :hide-earnings="hideEarnings"
        :collapse-advanced-settings="false"
        @save="onSave"
      >
      </employee-add-form>
    </template>
  </app-page>
</template>

<script>
import uuidv4 from 'uuid/v4';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import { employeeHandle } from '@/mixins/EmployeeHandle.js';
import EmployeeAddForm from '@/pages/employeeAdd/components/EmployeeAddForm.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import useFeatureFlags from '@/composables/useFeatureFlags';
import useAnalytics from '@/state/composables/useAnalytics';

export default {
  name: 'EmployeeAdd',
  components: {
    AppLoading,
    AppPage,
    EmployeeAddForm,
    SmeInfoBox,
  },
  mixins: [employeeHandle],
  data() {
    return {
      employee: undefined,
      balance: undefined,
      fieldPermissions: undefined,
      error: undefined,
      loading: true,
      saveError: undefined,
      saving: false,
      state: State.state,
      integrationsState: IntegrationsState.state,
    };
  },
  setup() {
    const { canAddEmployees, canAddSingleEmployee } = useFeatureFlags();
    const { postAnalyticsEvent } = useAnalytics();

    return {
      canAddEmployees,
      canAddSingleEmployee,
      postAnalyticsEvent,
    };
  },
  computed: {
    hideEarnings() {
      return this.state.company.properties.hide_new_earnings && this.employee.current_state === 'NEW';
    },
  },
  mounted() {
    this.postAnalyticsEvent({
      intent: 'view_add_single_employee',
      task: 'add_employees',
      action: 'view',
    });
    this.employee = this.setEmployeeDefaults({});
    ApiClient.getCompanyFieldPermissions(this.state.company.company_id).then(
      fieldPermissions => {
        this.fieldPermissions = fieldPermissions;
        this.loading = false;
      },
      error => {
        this.error = error.message;
        this.loading = false;
      },
    );
  },
  methods: {
    onSave() {
      this.saveError = undefined;
      this.saving = true;
      this.ensureNoEmptyStringsInEmployee();
      const newEmployee = {
        ...this.employee,
        employee_id: uuidv4(),
        pay_schedule_id: this.state.company.default_pay_schedule_id,
        company_id: this.state.company.company_id,
      };
      this.postAnalyticsEvent({
        intent: 'complete_add_single_employee',
        task: 'add_employees',
        action: 'click',
      });
      ApiClient.postEmployee(newEmployee).then(
        data => {
          this.saving = false;
          let message = `${this.employee.full_name} has been added. We have emailed login instructions to this new employee.`;
          let noAutoHide = false;
          if (data.password) {
            message += `<br/>The one-time passphrase for this new employee is: <strong>${data.password}</strong>`;
            noAutoHide = true;
          }
          this.$appToast(message, {
            title: 'Successfully added Employee',
            variant: 'success',
            noAutoHide: noAutoHide,
          });
          this.$router.push({ name: 'employee', params: { employeeId: newEmployee.employee_id } });
        },
        error => {
          if (error.message.includes('uniq__employees__company_id_employee_code')) {
            this.saveError = `Employee ID ${this.employee.employee_code} already exists`;
          } else if (error.message.includes('uniq__employees__company_id_work_phone')) {
            this.saveError = `Employee with phone number ${this.employee.work_phone} already exists`;
          } else if (error.message.includes('uniq__employees__company_id_work_email')) {
            this.saveError = `Employee with email ${this.employee.work_email} already exists`;
          } else {
            this.saveError = error.message;
          }
          this.saving = false;
        },
      );
    },
  },
};
</script>
