import { toCurrency } from '@/utils';
import { toFormattedDateTime } from '@/utils/date';

export const LAST_DAY_PAY_FIELD_DEFINITIONS = [
  {
    key: 'employee_id',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'full_name',
    label: 'Employee',
    sortable: true,
  },
  {
    key: 'final_net_pay_amount',
    label: 'Final Net Pay',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'final_total_debt',
    label: 'Final Total Debt',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'final_employee_salary',
    label: 'Employee Received (Final Salary)',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'company_owes_us',
    label: 'Balance Owed to Wagestream',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'latest_salary_amount',
    label: 'Last Streamed Salary Amount',
    sortable: true,
    formatter: value => toCurrency(value),
  },
  {
    key: 'completed_at',
    label: 'Timestamp',
    sortable: true,
    formatter: value => (value ? toFormattedDateTime(value) : '-'),
  },
];

export const LAST_DAY_PAY_FIELD_KEYS = {
  EMPLOYEE_ID: 'employee_id',
  FULL_NAME: 'full_name',
  FINAL_NET_PAY_AMOUNT: 'final_net_pay_amount',
  FINAL_TOTAL_DEBT: 'final_total_debt',
  FINAL_EMPLOYEE_SALARY: 'final_employee_salary',
  COMPANY_OWES_US: 'company_owes_us',
  LATEST_SALARY_AMOUNT: 'latest_salary_amount',
  COMPLETED_AT: 'completed_at',
};

export const LAST_DAY_PAY_FILTER_KEYS = {
  STARTS_ON: 'starts_on',
  ENDS_ON: 'ends_on',
};

export const LAST_DAY_PAY_API_FILTER_KEYS = [LAST_DAY_PAY_FILTER_KEYS.STARTS_ON, LAST_DAY_PAY_FILTER_KEYS.ENDS_ON];

export const LAST_DAY_PAY_SEARCH_FIELDS = ['full_name', 'employee_id'];

export const LAST_DAY_PAY_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
