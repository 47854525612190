import { render, staticRenderFns } from "./SettingsDropdown.vue?vue&type=template&id=1cdf1710&scoped=true&"
import script from "./SettingsDropdown.vue?vue&type=script&setup=true&lang=js&"
export * from "./SettingsDropdown.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SettingsDropdown.vue?vue&type=style&index=0&id=1cdf1710&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cdf1710",
  null
  
)

export default component.exports