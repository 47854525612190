<template>
  <article class="flexible-work-schedule-summary" @click="emit('view-list')">
    <div class="flexible-work-schedule-summary__content">
      <h4 class="flexible-work-schedule-summary__title">{{ summary.count }} {{ pluralize('shift', summary.count) }}</h4>
      <ul class="flexible-work-schedule-summary__details">
        <li
          class="flexible-work-schedule-summary__detail"
          :class="{
            'flexible-work-schedule-summary__detail--applications flexible-work-schedule-summary__detail--clickable':
              summary.applications > 0,
          }"
          @click="
            emit(
              'view-list',
              summary.applications ? (summary.pending ? CLAIM_STATUS.PENDING : assignedStatusOfInterest) : null,
            )
          "
        >
          <font-awesome-icon :icon="['fal', 'hand']" class="mr-1" />
          {{ summary.applications }} {{ pluralize('application', summary.applications) }}
        </li>
        <li
          class="flexible-work-schedule-summary__detail flexible-work-schedule-summary__detail--large"
          :class="{
            'flexible-work-schedule-summary__detail--assigned flexible-work-schedule-summary__detail--clickable':
              summary.assigned > 0,
          }"
          @click="emit('view-list', assignedStatusOfInterest)"
        >
          <font-awesome-icon :icon="['fal', 'circle-check']" class="mr-1" /> {{ summary.assigned }} assigned
        </li>
      </ul>
    </div>
  </article>
</template>

<script setup>
import { computed } from 'vue';
import { pluralize } from '@/utils/common';
import { CLAIM_STATUS } from '@/utils/flexible-work/Claim';

const props = defineProps({
  summary: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['view-list']);

const assignedStatusOfInterest = computed(() =>
  props.summary.assigned
    ? props.summary.approved || props.summary.worked
      ? CLAIM_STATUS.APPROVED
      : CLAIM_STATUS.FULFILLED
    : null,
);
</script>

<style lang="scss" scoped>
.flexible-work-schedule-summary {
  &:hover {
    .flexible-work-schedule-summary__title,
    .flexible-work-schedule-summary__detail:not(.flexible-work-schedule-summary__detail--clickable) {
      color: var(--palette-color-brand-primary-darken-10);
    }
  }
}

.flexible-work-schedule-summary__title {
  color: var(--palette-color-default);
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.flexible-work-schedule-summary__details {
  margin: 0;
  padding-left: 0;
}

.flexible-work-schedule-summary__detail {
  color: var(--palette-color-default-lighten-30);
  font-size: 13px;
  list-style-type: none;
  margin-top: 0.25rem;

  &--applications {
    color: var(--palette-color-brand-primary);

    &:hover {
      color: var(--palette-color-brand-primary-darken-10);
    }
  }

  &--assigned {
    color: var(--palette-color-success);

    &:hover {
      color: var(--palette-color-success-darken-10);
    }
  }
}
</style>
