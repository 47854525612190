<template>
  <div class="overview">
    <div class="details">
      <div class="shift-info">
        <div class="shift-info-header">
          <span>{{ props.shift?.portal_role_name }}</span>
          <div class="buttons">
            <b-button
              v-if="props.shift?.most_relevant_claim_status === 'UNFILLED' && isFuture(props.shift?.start_at)"
              v-b-modal="'edit-shift-modal'"
              variant="outline-primary aligned-btn"
              size="sm"
              class="edit-button"
            >
              <Edit width="16px" height="16px" :color="PaletteColors['brand-primary']"></Edit>
              Edit</b-button
            >
            <b-button
              v-if="props.shift?.can_be_deleted"
              v-b-modal="'confirm-delete-modal'"
              variant="outline-danger aligned-btn"
              size="sm"
            >
              <svg
                class="mr-1"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.49967 5.83337C6.86786 5.83337 7.16634 6.13185 7.16634 6.50004V10.5C7.16634 10.8682 6.86786 11.1667 6.49967 11.1667C6.13148 11.1667 5.83301 10.8682 5.83301 10.5V6.50004C5.83301 6.13185 6.13148 5.83337 6.49967 5.83337Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.49967 5.83337C9.86786 5.83337 10.1663 6.13185 10.1663 6.50004V10.5C10.1663 10.8682 9.86786 11.1667 9.49967 11.1667C9.13148 11.1667 8.83301 10.8682 8.83301 10.5V6.50004C8.83301 6.13185 9.13148 5.83337 9.49967 5.83337Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.49967 0.833374C6.05765 0.833374 5.63372 1.00897 5.32116 1.32153C5.0086 1.63409 4.83301 2.05801 4.83301 2.50004V2.83337H2.49967C2.13148 2.83337 1.83301 3.13185 1.83301 3.50004C1.83301 3.86823 2.13148 4.16671 2.49967 4.16671H2.83301V13C2.83301 13.3095 2.95592 13.6062 3.17472 13.825C3.39351 14.0438 3.69026 14.1667 3.99967 14.1667H11.9997C12.3091 14.1667 12.6058 14.0438 12.8246 13.825C13.0434 13.6062 13.1663 13.3095 13.1663 13V4.16671H13.4997C13.8679 4.16671 14.1663 3.86823 14.1663 3.50004C14.1663 3.13185 13.8679 2.83337 13.4997 2.83337H11.1663V2.50004C11.1663 2.05801 10.9907 1.63409 10.6782 1.32153C10.3656 1.00897 9.9417 0.833374 9.49967 0.833374H6.49967ZM9.83301 2.83337V2.50004C9.83301 2.41164 9.79789 2.32685 9.73538 2.26434C9.67286 2.20183 9.58808 2.16671 9.49967 2.16671H6.49967C6.41127 2.16671 6.32648 2.20183 6.26397 2.26434C6.20146 2.32685 6.16634 2.41164 6.16634 2.50004V2.83337H9.83301ZM4.16634 4.16671V12.8334H11.833V4.16671H4.16634Z"
                  fill="currentColor"
                />
              </svg>
              Delete</b-button
            >
          </div>
        </div>
        <div v-if="props.shift?.assigned_to && props.shift?.winning_claim_employee_name" class="assigned-employee">
          <EmployeeAvatar :size="20" :imageUrl="props.shift?.winning_claim_employee_photo_url" />
          <span>{{ props.shift?.winning_claim_employee_name }}</span>
        </div>
        <GroupTag
          :locationId="props.shift?.location_id"
          :group-id="props.shift?.group_id"
          :group-type="props.shift?.group_type"
          :role-name="props.shift?.group_external_role_name"
        ></GroupTag>
        <div class="date">
          <Calendar width="20" height="20" />
          <span>{{ formatDate(props.shift?.start_at) }}</span>
        </div>
        <div class="time">
          <Clock width="20" height="20"></Clock>
          <span
            >{{
              formatTimeRange(
                props.shift?.claim_properties?.actual_start_at || props.shift?.start_at,
                props.shift?.claim_properties?.actual_end_at || props.shift?.end_at,
              )
            }}
            ({{ props.shift?.hours.toFixed(1) }} hours)</span
          >
        </div>
        <div class="time" v-if="currentLocation">
          <MapPin width="20" height="20"></MapPin>
          <span class="location-name">{{ state.company.name }}, {{ currentLocation.name }}</span>
        </div>

        <!-- <div class="mb-3">
                    {{ props.shift?.properties.notes.join(', ') }}
                  </div> TODO: blocked by editing shift details -->
      </div>
      <div class="shift-totals">
        <div>
          <span class="label">Shift total</span>
          <span class="hourly-label">Hourly rate</span>
        </div>
        <div>
          <span class="value"
            >£{{ toRawCurrency(props.shift?.client_rate * props.shift?.hours, props.shift?.currency) }}</span
          >
          <span class="hourly-value">£{{ toRawCurrency(props.shift?.client_rate, props.shift?.currency) }}</span>
        </div>
      </div>
    </div>
    <Dialog
      modalId="confirm-delete-modal"
      title="Are you sure you want to delete this shift?"
      okBtnLabel="Delete"
      okBtnVariant="danger"
      :okBtnDisabled="onDeleteShift?.isPending.value"
      @on-ok="handleDeleteShift"
    />
    <AddShiftModal
      :redirect-on-success="true"
      title="Edit shift"
      :shift="props.shift"
      modal-id="edit-shift-modal"
      :is-edit="true"
    ></AddShiftModal>
  </div>
</template>

<script setup>
import Edit from '@/assets/icons/Edit.vue';
import GroupTag from '@/pages/talent-pool/shift/components/GroupTag.vue';
import Clock from '@/assets/icons/Clock.vue';
import Calendar from '@/assets/icons/Calendar.vue';
import State from '@/state/State';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import { useRoute, useRouter } from 'vue-router/composables';
import { computed, onMounted } from 'vue';
import { toRawCurrency } from '@/utils';
import EmployeeAvatar from '@/pages/talent-pool/components/EmployeeAvatar.vue';
import { formatDate, formatTimeRange } from '@/pages/talent-pool/utils/datetime';
import { useMutation } from '@tanstack/vue-query';
import { deleteShift } from '@/queries/talent-pool/shifts';
import MapPin from '@/assets/icons/MapPin.vue';
import { PaletteColors } from '@/Theme';
import AddShiftModal from '@/pages/talent-pool/components/AddShiftModal.vue';
import { isFuture } from 'date-fns';
import Dialog from '@/components/Dialog.vue';

const { locations, getLocations } = useTalentPoolLocations();

const props = defineProps(['shift']);
const router = useRouter();
const route = useRoute();
const state = State.state;
const locationId = route.params.locationId;
const tab = route.params.tab;

const currentLocation = computed(() => {
  if (locations.value.length > 0) {
    return locations.value.find(location => location.location_id === locationId);
  }
  return null;
});

const onDeleteShift = useMutation(deleteShift({ locationId }));

const handleDeleteShift = async () => {
  try {
    onDeleteShift.mutate(props.shift.shift_id);
  } finally {
    router
      .push({
        name: 'talent-pool-shift-status',
        params: { locationId, tab },
      })
      .catch(() => {});
  }
};

onMounted(() => {
  getLocations();
});
</script>

<style scoped lang="scss">
.shift-details .overview {
  padding: 1rem 1.5rem;
  background-color: var(--palette-color-base-white);
  width: 100%;
  border-radius: var(--radius-M, 0.5rem);
  display: flex;
  gap: 2rem;
}

@media (max-width: 900px) {
  .shift-details .overview {
    flex-direction: column;
    gap: 1rem;
  }
}

.overview .details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.overview .details .shift-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  padding-bottom: 1rem;
}

@media (max-width: 900px) {
  .overview .details .shift-info {
    padding-bottom: 1.5rem;
  }
}

.overview .details .shift-info .shift-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overview .details .shift-info .shift-info-header span {
  font-size: 22px;
  font-weight: 400;
}

.overview .details .shift-info .date {
  font-size: 14px;
  font-weight: 300;
}

.overview .details .shift-info .date svg {
  margin-right: 0.5rem;
}

.overview .details .shift-info .time {
  font-size: 14px;
  font-weight: 300;
}

.overview .details .shift-info .time svg {
  margin-right: 0.5rem;
}

.overview .details .shift-totals {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overview .details .shift-totals > div {
  display: flex;
  flex-direction: column;
}

.overview .details .shift-totals .label {
  font-size: 16px;
  font-weight: 500;
}

.overview .details .shift-totals .hourly-label {
  font-size: 14px;
  font-weight: 300;
  color: var(--palette-color-default-lighten-20);
}

.overview .details .shift-totals .value {
  font-size: 22px;
  font-weight: 400;
}

.overview .details .shift-totals .hourly-value {
  font-size: 14px;
  font-weight: 300;
  color: var(--palette-color-default-lighten-20);
  text-align: right;
}

.overview .map-container {
  height: 152px;
  width: 284px;
  background-color: var(--palette-color-default-lighten-90);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--palette-color-default-lighten-50);
  border-radius: 4px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .overview .map-container {
    width: 100%;
    height: auto;
    max-height: 200px;
  }
}

.overview .location {
  display: flex;
  flex-direction: column;
}

.overview .location .location-name {
  font-size: 16px;
  font-weight: 600;
}

.overview .location .location-address {
  font-size: 14px;
  font-weight: 300;
}

.assigned-employee {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.aligned-btn {
  display: flex;
  align-items: center;
}

.modal-text {
  font-size: 15px;
  padding: 1rem;
  display: flex;
  font-weight: 500;
}

.buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .edit-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
</style>
