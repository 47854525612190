<template>
  <div ref="$map" class="flexible-work-shift-audience-map"></div>
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader';
import { ref, watch } from 'vue';
import { PaletteColors } from '@/Theme';
import { DEFAULT_PROXIMATE_DISTANCE_IN_METERS } from '@/utils/flexible-work';
import styles from '@/utils/map/styles.json';

const props = defineProps({ location: Object });

const initialized = ref(false);
const $map = ref(undefined);
let map, marker, circle;

watch(
  () => props.location,
  async location => {
    let latLng;

    if (!location) {
      return;
    } else {
      latLng = { lat: location.latitude, lng: location.longitude };
    }

    if (initialized.value) {
      map.setOptions({ center: latLng });
      circle.setOptions({ center: latLng });
      marker.setPosition(latLng);
      return;
    }

    const loader = new Loader({ apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY });
    const [{ Point, Size }, { Circle, Map }, { Marker }] = await Promise.all([
      loader.importLibrary('core'),
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
    ]);

    map = new Map($map.value, {
      center: latLng,
      disableDefaultUI: true,
      styles,
      zoom: 11,
    });

    circle = new Circle({
      center: latLng,
      fillColor: PaletteColors['brand-primary'],
      fillOpacity: 0.25,
      map,
      radius: DEFAULT_PROXIMATE_DISTANCE_IN_METERS,
      strokeColor: PaletteColors['brand-primary'],
      strokeOpacity: 0.8,
      strokeWeight: 2,
    });

    marker = new Marker({
      icon: {
        url: require('@/assets/map-pin-location.svg'),
        scaledSize: new Size(24, 32),
        origin: new Point(0, 0),
        anchor: new Point(12, 16),
      },
      map,
      position: latLng,
    });

    initialized.value = true;
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.flexible-work-shift-audience-map {
  background-color: var(--palette-color-base-background);
  padding-bottom: 62.5%;
}
</style>
