<template title="Sync Results">
  <div>
    <page-list-header>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="perPageOptions" @change="onPerPageChange" />
        </b-form-group>
      </template>
    </page-list-header>

    <sme-card class="mb-3">
      <b-table
        ref="table"
        :items="currentSyncResults"
        :fields="displayFields"
        class="mb-0"
        table-class="table-minimal"
        :tfoot-class="!currentSyncResults?.length ? 'd-none' : ''"
        show-empty
        no-footer-sorting
        no-local-sorting
        responsive
      >
        <template v-slot:cell(is_ok)="data">
          <div class="d-inline-flex">
            <font-awesome-icon
              class="success"
              v-if="data?.item?.summary?.is_ok === true"
              :icon="['fas', 'circle-check']"
            />
            <font-awesome-icon
              class="error"
              v-if="data?.item?.summary?.is_ok === false"
              :icon="['fas', 'circle-exclamation']"
            />
          </div>
        </template>
      </b-table>
    </sme-card>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalResults"
      :per-page="perPage"
      class="justify-content-end"
      @change="changePage"
    />
  </div>
</template>

<script>
import moment from 'moment/moment';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import { SYNC_RESULTS_FIELD_DEFINITIONS, SYNC_RESULTS_FIELD_KEYS } from '@/pages/integrations/constants';
import State from '@/state/State';

export default {
  name: 'SyncResultsList',
  components: { PageListHeader, SmeCard },
  props: {
    syncResults: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    this.updateSyncResults();
  },
  data() {
    return {
      query: { searchText: '', offset: 0, count: 25 },
      currentPage: 1,
      perPage: 25,
      currentSyncResults: [],
      totalResults: 0,
      perPageOptions: [5, 25, 50, 100],
      state: State.state,
    };
  },
  computed: {
    displayFields() {
      return SYNC_RESULTS_FIELD_KEYS.map(key => {
        return {
          ...SYNC_RESULTS_FIELD_DEFINITIONS.find(definition => key === definition.key),
        };
      });
    },
    exportFilename() {
      const now = moment().format('YYYY-MM-DD');
      let companyName = '';
      if (this.state.company.name) {
        companyName = this.state.company.name.toLowerCase()?.replace(/ /g, '-');
      }

      return `report-${now}-sync-results-list-${companyName}`;
    },
    exportHeaders() {
      return this.displayFields.filter(field => field.export !== false).map(field => field.label);
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.updateSyncResults();
      },
    },
  },
  methods: {
    updateSyncResults() {
      const queriedSyncResults =
        this.query.searchText?.length > 0
          ? this.syncResults.filter(item => item.sync_result_id.includes(this.query.searchText))
          : this.syncResults;

      this.totalResults = queriedSyncResults.length;
      this.currentSyncResults = queriedSyncResults.slice(this.query.offset, this.query.offset + this.query.count);
    },
    changePage(newPageNumber) {
      this.query = { ...this.query, offset: this.perPage * (newPageNumber - 1), count: this.perPage };
      this.currentPage = newPageNumber;
    },
    onPerPageChange() {
      this.query = { ...this.query, count: this.perPage, offset: 0 };
      this.currentPage = 1;
    },
    exportFileRows(item) {
      return this.displayFields
        .filter(field => field.export !== false)
        .map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));
    },
  },
};
</script>

<style scoped>
.success {
  color: var(--palette-color-success);
}
.error {
  color: var(--palette-color-danger);
}
</style>
