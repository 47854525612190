<template>
  <div :class="['location-selector-container', { compact: props.compact }]">
    <a
      v-b-toggle.location-menu
      title="Change location"
      aria-expanded="false"
      data-toggle="dropdown"
      data-testid="locationSelector"
    >
      <div class="location-selector">
        <img
          class="location-logo"
          :src="currentLocation.logo_url"
          v-if="currentLocation?.logo_url"
          :alt="`${currentLocation.name} Logo`"
        />
        <div v-else class="location-logo default"></div>
        <div v-if="!props.compact" class="location-info">
          <div class="show" v-if="currentLocation">
            <span class="name">{{ currentLocation.name }}</span>
          </div>
          <span v-else class="name">Loading...</span>
          <UserRatingDisplay :rating="rating" small v-if="rating"></UserRatingDisplay>
        </div>
        <font-awesome-icon v-if="!props.compact" :icon="['fal', 'chevron-down']" class="ml-1 icon" />
      </div>
    </a>
    <b-collapse id="location-menu" class="dropdown-menu dropdown-lg show location-menu" x-placement="bottom-start">
      <div class="first-layer">
        <div v-for="location in organisation" :key="location.name">
          <div
            v-b-toggle.location-sub-menu
            @click="onClick(location)"
            @mouseover="onMouseOver(location)"
            class="dropdown-item location-item fw-400"
          >
            <div class="details-inner">
              <img
                v-if="location.logo_url"
                class="location-menu-logo"
                :src="location.logo_url"
                :alt="`${location.name} Logo`"
              />
              <img v-else :src="themeImage('camera.svg')" class="location-default-logo" />
              <span>
                {{ location.name }}
                <span class="entity-count">
                  {{ getEntityCount(location) }}
                </span>
              </span>
            </div>
            <div class="chevron-wrapper" v-if="location.children?.length > 0">
              <font-awesome-icon :icon="['fal', 'chevron-right']" class="ml-1 icon" />
            </div>
            <b-collapse
              class="location-sub-menu"
              id="location-sub-menu"
              ref="toggle"
              v-if="location.children?.length > 0 && locationOnHover === location.entity_id"
            >
              <div
                v-for="subLocation in location.children"
                :key="subLocation.name"
                @click="goToPage(subLocation.entity_id)"
                class="dropdown-item location-item fw-400"
              >
                <div class="details-inner">
                  <img
                    v-if="subLocation.logo_url"
                    class="location-menu-logo"
                    :src="subLocation.logo_url"
                    :alt="`${subLocation.name} Logo`"
                  />
                  <img v-else :src="themeImage('camera.svg')" class="location-default-logo" />
                  <span>
                    {{ subLocation.name }}
                    <span class="entity-count">
                      {{ getEntityCount(subLocation) }}
                    </span>
                  </span>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <router-link
        v-if="companyId"
        :to="{ name: 'talent-pool-settings', params: { locationId: companyId } }"
        class="settings-link dropdown-item fw-400 settings"
      >
        <font-awesome-icon :icon="['fal', 'cog']" class="mr-2 icon" />
        <span>Company settings</span>
      </router-link>
    </b-collapse>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router/composables';
import { onMounted, ref } from 'vue';
import { getAverageRating } from '@/pages/talent-pool/utils/ratings';
import ApiClient from '@/ApiClient';
import UserRatingDisplay from '@/pages/talent-pool/components/UserRatingDisplay.vue';
import { themeImage } from '@/Theme';
import { getEntityType } from '@/pages/talent-pool/utils/getEntityType';

const props = defineProps(['routeName', 'compact']);

const route = useRoute();
const router = useRouter();
const locationId = route.params.locationId;
const rating = ref(null);
const organisation = ref([]);
const currentLocation = ref(null);
const locationOnHover = ref('');
const companyId = ref('');
const toggle = ref(null);

const getRating = async () => {
  const { data } = await ApiClient.getTalentPoolLocationRating(locationId);
  rating.value = getAverageRating(data);
};

const getOrganisation = async () => {
  const { data } = await ApiClient.getTalentPoolOrganisation();
  organisation.value =
    data.children.filter(org => getEntityType(org.source) !== 'brand' || org.children?.length > 0) || [];
  companyId.value = data.company_id;
};

const getLocationDetails = async () => {
  const { data } = await ApiClient.getTalentPoolEntityDetails(locationId);
  currentLocation.value = data;
};

const getEntityCount = location => {
  if (route.fullPath.includes('job')) {
    return location.job_count;
  }
  if (route.fullPath.includes('map')) {
    return '';
  }
  return location.shift_count;
};

const onClick = entity => {
  if (entity?.children?.length > 0) {
    locationOnHover.value = entity.entity_id;
  } else {
    router.push({
      name: props.routeName || 'talent-pool',
      params: { locationId: entity.entity_id },
    });
  }
};

const goToPage = entityId => {
  router.push({
    name: props.routeName || 'talent-pool',
    params: { locationId: entityId },
  });
};

const onMouseOver = entity => {
  if (entity?.children?.length > 0) {
    locationOnHover.value = entity.entity_id;
  }
};

onMounted(() => {
  getLocationDetails();
  getRating();
  getOrganisation();
});
</script>

<style scoped lang="scss">
.location-selector-container {
  position: relative;
  cursor: pointer;
}

.location-selector {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
}

.compact .location-selector {
  padding: 0;
  justify-content: center;
}

.location-selector:hover {
  background-color: var(--palette-color-default-lighten-95);
}

.location-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.location-sub-menu {
  position: absolute;
  left: 100%;
  width: 100%;
  border: 0;
  overflow-y: auto;
  border-radius: 0.5rem;
  background-color: var(--palette-color-base-white);
  padding: 0.3125rem 0;
  box-shadow: 0 0 15px 1px rgba(94, 51, 171, 0.2);
  z-index: 2;
  max-height: 70vh;
}

.base-dropdown {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-group {
  border: 0 !important;
}

.location-menu {
  top: 4.5rem;
}

.compact .location-menu {
  top: 3rem;
}

.dropdown-menu.dropdown-lg {
  border-radius: 0.5rem;
}

.first-layer {
  max-height: 60vh;
  overflow-y: auto;
  z-index: 1;
}

.settings {
  position: sticky;
  bottom: 0;
  background-color: var(--palette-color-base-white);
}

.location-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0.75rem;

  &:hover,
  &:focus {
    font-weight: 600 !important;
    color: var(--palette-color-default);
    background: var(--palette-color-default-lighten-95);
  }
}

.location-item .chevron-wrapper {
  display: flex;
  align-items: center;
}

.location-item .details-inner {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

@media (max-width: 900px) {
  .location-info {
    align-items: flex-start;
    flex-grow: 1;
  }

  .location-menu {
    left: 1rem;
  }
}

.icon {
  font-size: 16px;
  color: var(--palette-color-default-lighten-50);
}

.location-info .name {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--palette-color-default);
}

.location-info .location-rating {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  display: flex;
  align-items: center;
}

.location-logo {
  height: 60px;
  width: 60px;
  object-fit: contain;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 50%;
  background-color: var(--palette-color-base-white);
}

.compact .location-logo {
  height: 44px;
  width: 44px;
}

.location-menu-logo,
.location-default-logo {
  height: 36px;
  width: 36px;
  object-fit: contain;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 50%;
  background-color: var(--palette-color-base-white);
}

.location-default-logo {
  object-fit: scale-down;
}

.location-info .location-rating svg {
  height: 16px;
  width: 16px;
  color: var(--palette-color-warning);
}

.settings-link {
  display: flex;
  align-items: center;
  color: var(--palette-color-default);

  &:hover {
    font-weight: 600 !important;
    color: var(--palette-color-default);
    background: var(--palette-color-default-lighten-95);
  }
}

.settings-link .icon {
  font-size: 20;
  color: var(--palette-color-base-black);
}

.entity-count {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--palette-color-core-1-lighten-30);
  margin-left: 1rem;
}
</style>
