<template>
  <Modal title="Invite colleague" modalId="add-colleague-modal" ref="modalRef">
    <div class="form-container">
      <div>
        <div class="input-title">First name</div>
        <b-form-input class="input-section" placeholder="Enter first name" v-model="firstName"></b-form-input>
      </div>
      <div>
        <div class="input-title">Last name</div>
        <b-form-input class="input-section" placeholder="Enter last name" v-model="lastName"></b-form-input>
      </div>
      <div>
        <div class="input-title">Email address</div>
        <b-form-input class="input-section" placeholder="Enter email address" v-model="email"></b-form-input>
      </div>
      <div>
        <div class="input-title">Phone number</div>
        <b-form-input class="input-section" placeholder="Enter phone number" v-model="phone"></b-form-input>
      </div>
      <div class="full-width">
        <div class="group-title">
          <div class="input-title">Role</div>
        </div>
        <div class="roles-container">
          <div @click="selectedRole = 'FLEXWORK_ADMIN'" class="role">
            <div :class="['circle', { selected: selectedRole === 'FLEXWORK_ADMIN' }]">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8736 3.79289C15.2641 4.18342 15.2641 4.81658 14.8736 5.20711L7.87361 12.2071C7.48309 12.5976 6.84992 12.5976 6.4594 12.2071L2.9594 8.70711C2.56887 8.31658 2.56887 7.68342 2.9594 7.29289C3.34992 6.90237 3.98309 6.90237 4.37361 7.29289L7.1665 10.0858L13.4594 3.79289C13.8499 3.40237 14.4831 3.40237 14.8736 3.79289Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <label>Admin</label>
            <p>
              This role is for people who need similar access to the account owner. This role can see and manage almost
              everything.
            </p>
          </div>
          <div @click="selectedRole = 'FLEXWORK_MANAGER'" class="role">
            <div :class="['circle', { selected: selectedRole === 'FLEXWORK_MANAGER' }]">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8736 3.79289C15.2641 4.18342 15.2641 4.81658 14.8736 5.20711L7.87361 12.2071C7.48309 12.5976 6.84992 12.5976 6.4594 12.2071L2.9594 8.70711C2.56887 8.31658 2.56887 7.68342 2.9594 7.29289C3.34992 6.90237 3.98309 6.90237 4.37361 7.29289L7.1665 10.0858L13.4594 3.79289C13.8499 3.40237 14.4831 3.40237 14.8736 3.79289Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <label>Manager</label>
            <p>This role allows people to manage roles & shifts for multiple brands and sites.</p>
          </div>
          <div @click="selectedRole = 'FLEXWORK_SIGNER'" class="role">
            <div :class="['circle', { selected: selectedRole === 'FLEXWORK_SIGNER' }]">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8736 3.79289C15.2641 4.18342 15.2641 4.81658 14.8736 5.20711L7.87361 12.2071C7.48309 12.5976 6.84992 12.5976 6.4594 12.2071L2.9594 8.70711C2.56887 8.31658 2.56887 7.68342 2.9594 7.29289C3.34992 6.90237 3.98309 6.90237 4.37361 7.29289L7.1665 10.0858L13.4594 3.79289C13.8499 3.40237 14.4831 3.40237 14.8736 3.79289Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <label>Signer</label>
            <p>This role only allows people to sign-off shifts at specific sites.</p>
          </div>
        </div>
      </div>
      <div v-if="selectedRole && selectedRole !== 'FLEXWORK_ADMIN'" class="full-width">
        <div class="input-title">Site</div>
        <b-form-select v-model="location" :options="locationOptions" />
      </div>
    </div>

    <template #modal-footer>
      <div class="footer">
        <div></div>
        <div class="buttons">
          <b-button variant="outline-primary" @click="$bvModal.hide('add-colleague-modal')">Cancel</b-button>
          <b-button @click="invite" variant="primary" :disabled="addDisabled">Invite colleague</b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import Modal from '@/components/Modal.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import uuidv4 from 'uuid/v4';
import { FLEXWORK_ROLES } from '@/pages/talent-pool/utils/portalUsers';

const modalRef = ref(null);

const route = useRoute();
const locationId = route.params.locationId;

const emit = defineEmits(['added']);

const location = ref(null);
const selectedRole = ref(null);
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phone = ref('');
const addDisabled = ref(false);

const { getLocations, locations } = useTalentPoolLocations();

const locationOptions = computed(() => {
  return locations.value.map(location => ({
    value: location.location_id,
    text: location.name,
  }));
});

const invite = async () => {
  addDisabled.value = true;
  await ApiClient.addTalentPoolPortalUser({
    role_id: FLEXWORK_ROLES[selectedRole.value],
    email: email.value,
    username: email.value,
    password: uuidv4() + 'P',
    phone: phone.value,
    division_id: location.value,
  });
  emit('added');
  addDisabled.value = false;
  modalRef.value.modal.hide();
};

onMounted(() => {
  getLocations().then(() => {
    if (locations.value.length > 0) {
      location.value = locations.value.find(location => location.location_id === locationId).location_id;
    }
  });
});
</script>

<style scoped lang="scss">
.input-title {
  font-size: 14px;
  font-weight: 500;
  margin: 6px;
}

.modal-title {
  font-weight: 600;
}

.override-button {
  margin: 10px 0;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
}

.full-width {
  grid-column: 1 / -1;
}

.form-subtext {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.form-subtext.danger {
  color: var(--palette-color-danger);
}

.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helper-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
}

.time-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.time-container div {
  display: flex;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.footer .label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.footer .value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.total {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.roles-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.role {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 8px;
  padding: 12px;

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--palette-color-default-lighten-20);
    margin: 0;
  }
}

.circle {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 1px solid var(--palette-color-default-lighten-60);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;

  svg {
    display: none;
  }

  &.selected {
    background-color: var(--palette-color-brand-primary);
    color: var(--palette-color-base-white);

    svg {
      display: block;
    }
  }
}
</style>
