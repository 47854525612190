<template>
  <b-dropdown ref="$dropdown" class="settings-dropdown" title="Settings" variant="light" no-caret>
    <template #button-content>
      <font-awesome-icon :icon="['fal', 'cog']" />
    </template>
    <b-dropdown-form class="settings-dropdown__form" @submit.prevent="$dropdown.hide(true)">
      <app-input
        v-for="setting in settings"
        :key="setting.key"
        v-bind="getSettingControlProps(setting)"
        v-model="settingsValues[setting.key]"
        :name="setting.key"
        :type="setting.type"
      >
        <template #default v-if="setting.type === CONTROL_TYPES.CHECKBOX_SINGLE && setting.label">
          {{ setting.label }}
        </template>
      </app-input>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import AppInput, { CONTROL_TYPES, isSingleCheckedControlType } from '@/components/AppInput.vue';
import { FALSE_REGEX, TRUE_REGEX } from '@/utils/common';

const $route = useRoute();

const props = defineProps({
  settings: {
    type: Array,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['input']);

const identifier = `${$route.name}-${props.id}-settings`;

const $dropdown = ref(undefined);

const settingsValues = computed({
  get: () => props.value,
  set: value => emit('input', value),
});

onMounted(() => {
  setSettingsValuesFromStorage();
  nextTick(() => watchSettingsAndUpdateStorage());
});

const watchSettingsAndUpdateStorage = () => {
  watch(
    () => settingsValues.value,
    values => {
      if (localStorage) {
        localStorage.settingsDropdownStates = JSON.stringify({
          ...JSON.parse(localStorage.settingsDropdownStates || '{}'),
          [identifier]: values,
        });
      }
    },
    { deep: true },
  );
};

const setSettingsValuesFromStorage = () => {
  const settingsDropdownStates = JSON.parse(localStorage?.settingsDropdownStates || '{}');

  if (Object.prototype.hasOwnProperty.call(settingsDropdownStates, identifier)) {
    const storageSettingsValues = settingsDropdownStates[identifier];

    Object.entries(storageSettingsValues).forEach(
      ([key, value]) => (storageSettingsValues[key] = castSettingStorageValue(value)),
    );

    settingsValues.value = storageSettingsValues;
  }
};

const castSettingStorageValue = value => {
  if (FALSE_REGEX.test(value)) {
    return false;
  } else if (TRUE_REGEX.test(value)) {
    return true;
  } else if (!isNaN(value)) {
    return Number(value);
  }

  return value;
};

const getSettingControlProps = filter => ({
  ...(isSingleCheckedControlType(filter.type) ? { switch: true } : null),
});
</script>

<style lang="scss" scoped>
.settings-dropdown__form {
  min-width: 17.5rem;
}
</style>
