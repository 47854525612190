import State from '@/state/State';
import useFeatureFlags from '@/composables/useFeatureFlags';

export const FLEXWORK_SIGNER_ONLY_ROUTES = ['talent-pool-sign-off', 'talent-pool-clock-in'];

const { isEnableFlexibleWork, isEnableTalentPool } = useFeatureFlags();

export const isFlexPayLike = (claims = null) => {
  if (claims == null) {
    claims = State.state.claims;
  }
  return claims?.a || claims?.m || claims?.is;
};

export const isFlexWorkLike = (claims = null) => {
  if (claims == null) {
    claims = State.state.claims;
  }
  return (isEnableFlexibleWork.value || isEnableTalentPool.value) && (claims?.fwa || claims?.fwm);
};

export const isFlexBoth = (claims = null) => isFlexPayLike(claims) && isFlexWorkLike(claims);

export const isFlexEither = (claims = null) => isFlexPayLike(claims) || isFlexWorkLike(claims);

export const isFlexWorkLikeIncludingSigner = (claims = null) => isFlexWorkLike || claims?.fws;
