<template>
  <Modal
    :title="`${props.claim?.worker_name?.split(' ')[0]}'s profile`"
    :modalId="props.modalId"
    hide-footer
    ref="modal"
  >
    <div class="error-container" v-if="errorText">
      <ErrorMessage :text="errorText" />
    </div>
    <OldWorkerProfile
      v-if="props.claim.claim_id"
      :claim="props.claim"
      @claim-selected="approveClaim"
    ></OldWorkerProfile>
    <WorkerProfile v-else :claim="props.claim" @claim-selected="approveClaim" :full-profile="props.fullProfile" />
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import WorkerProfile from '@/pages/talent-pool/components/WorkerProfile';
import { useRoute, useRouter } from 'vue-router/composables';
import { useMutation } from '@tanstack/vue-query';
import { postApproveShiftClaim } from '@/queries/talent-pool/shifts.js';
import ErrorMessage from '@/components/ErrorMessage.vue';
import Modal from '@/components/Modal.vue';
import OldWorkerProfile from '@/pages/talent-pool/components/WorkerProfile/OldWorkerProfile.vue';

const route = useRoute();
const router = useRouter();
const locationId = route.params.locationId;
const tab = route.params.tab;

const modal = ref(null);
const errorText = ref('');

const props = defineProps(['claim', 'modalId', 'fullProfile']);

const onSuccess = () => {
  modal.value.hide();
  router
    .push({
      name: 'talent-pool-shift-status',
      params: { locationId, tab },
    })
    .catch(e => e);
};

const onError = error => {
  if (error?.statusCode === 409) {
    errorText.value = 'This claim has already been approved';
  } else {
    errorText.value =
      'We could not select this user for the shift. Please refresh the portal, select someone else or contact us to resolve.';
  }
};

const onApproveClaim = useMutation(postApproveShiftClaim({ locationId, onError, onSuccess }));

const approveClaim = async () => {
  onApproveClaim.mutate(props.claim.claim_id);
};
</script>

<style scoped lang="scss">
.error-container {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
}
</style>
