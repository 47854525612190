<template>
  <app-page title="Employees" subtitle="Invite and manage employees' access" icon="users">
    <template v-slot:infobox>
      <sme-info-box title="About employees" id="about-employees" save-state>
        <p>
          Here we provide an overview of the employees you have invited and whether they have
          <strong>enrolled</strong> by downloading the app.
        </p>
        <p>You will be notified whenever employees enrol and prompted if any action is required on your part.</p>
      </sme-info-box>

      <sme-info-box title="How do I invite employees?" id="inviting-employees" save-state>
        <p>
          The easiest way to manage employees is to use one of our
          <router-link :to="{ name: 'sme-integrations' }">real-time integrations</router-link> with popular
          {{ getBankingInfo.rota.label }} software.
        </p>
        <p v-if="canAddEmployees">
          Alternatively, you can manually <router-link :to="{ name: 'employees-add' }">upload employees</router-link> as
          required.
        </p>
      </sme-info-box>

      <sme-info-box
        title="How can I remove access to the app for an employee?"
        id="disabling-employees"
        is-grouped
        save-state
      >
        <p>
          You can remove an employee's access (whether they are enrolled or not) by choosing
          <strong>'Opt Out'</strong>, either below or on the employee's individual page. The employee is then notified
          of the change and they will no longer have access.
        </p>
        <p>
          If the employee has never been enrolled, their opt-out will be automatically completed and you don't need to
          do anything else. Otherwise, you will be required to update their banking details in your payroll system. When
          they have no outstanding transfers, you will be able to finish the opt-out via the
          <strong>'Complete'</strong> button.
        </p>
        <p>
          Employees opted out within the current pay period will be displayed below. To see those opted out before this,
          select <strong>'Show Leavers and Opt Outs'</strong> from the filter settings.
        </p>
      </sme-info-box>

      <sme-info-box
        title="Why can I still see Leavers or Opt Outs when I have asked to hide them?"
        id="disabling-employees-visibility"
        is-grouped
        save-state
      >
        <p>
          Even if you have toggled "Show leavers and opt outs", we will show you employees that have Opted Out or
          Terminated in the last 35 days. This gives you a chance to complete any actions that you might need to, like
          completing an Opt Out
        </p>
      </sme-info-box>
    </template>

    <template v-slot:header v-if="state.claims.a">
      <b-button
        v-if="canAddEmployees && canAddSingleEmployee"
        @click="goToAddEmployee"
        variant="outline-primary"
        :disabled="!state?.paySchedule"
      >
        <font-awesome-icon :icon="['fad', 'user-plus']" class="mr-2" />Add Employee
      </b-button>

      <span id="bulk-upload-employees-button">
        <b-button
          v-if="canAddEmployees"
          @click="goToBulkUpload"
          variant="outline-primary"
          :disabled="!state?.paySchedule"
        >
          <font-awesome-icon :icon="['fal', 'upload']" class="mr-2" /> Bulk Import
        </b-button>
      </span>
      <b-tooltip v-if="!state?.paySchedule" target="bulk-upload-employees-button"
        >Please add a pay schedule to enable this feature</b-tooltip
      >
      <b-button
        v-if="isEnableShowBankingFile && state?.claims?.a"
        @click="downloadBankingReport"
        variant="outline-primary"
      >
        <font-awesome-icon :icon="['fad', 'download']" class="mr-2" />Download Banking Report
      </b-button>
      <b-button
        v-if="canIntegrate && !integrationsState.state.integrations"
        :to="{ name: 'sme-integrations' }"
        variant="outline-primary"
      >
        <font-awesome-icon :icon="['fad', 'chart-network']" class="mr-2" />Setup Integration
      </b-button>
    </template>

    <template v-slot:before-content>
      <b-col sm="12" class="mb-4">
        <page-metrics :metrics="employeeMetrics" />
      </b-col>
    </template>

    <template v-slot:custom-content>
      <b-col>
        <employees-list />
      </b-col>
    </template>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import AppPage from '@/components/AppPage.vue';
import PageMetrics from '@/components/PageMetrics.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import EmployeesList from '@/pages/employees/components/EmployeesList.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useAnalytics from '@/state/composables/useAnalytics';

export default {
  name: 'Employees',
  components: { AppPage, EmployeesList, PageMetrics, SmeInfoBox },
  data() {
    return {
      state: State.state,
      integrationsState: IntegrationsState,
    };
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
    employeeMetrics() {
      return [
        {
          value: this.employeeStateCounts.ELIGIBLE,
          title: 'Eligible',
        },
        {
          value: this.employeeStateCounts.ENROLLED,
          title: 'Enrolled',
        },
        {
          value: this.employeeStateCounts.ENROLLING,
          title: 'Enrolling',
        },
      ];
    },
  },
  setup() {
    const { employeeStateCounts, employeeStateCountsLoading, getEnrolmentSnapshot } = useEmployees();
    const { postAnalyticsEvent } = useAnalytics();

    getEnrolmentSnapshot();

    const { canAddEmployees, canAddSingleEmployee, isEnableEmployeeForm, isEnableShowBankingFile, canIntegrate } =
      useFeatureFlags();

    return {
      employeeStateCounts,
      employeeStateCountsLoading,
      canAddEmployees,
      canAddSingleEmployee,
      canIntegrate,
      isEnableEmployeeForm,
      isEnableShowBankingFile,
      postAnalyticsEvent,
    };
  },
  methods: {
    downloadBankingReport() {
      let fileName = 'banking.csv';
      const browser_integration = this.integrationsState.state.integrations.find(
        integration => integration['partner'] == 'BROWSER_UPLOAD' && 'daily_sftp_report' in integration['properties'],
      );
      if (browser_integration) {
        fileName = browser_integration['properties']['daily_sftp_report'];
      }
      ApiClient.downloadBankingReport(this.state.company.company_id, fileName);
    },
    goToAddEmployee() {
      this.postAnalyticsEvent({
        intent: 'navigate_employees_add_employee_single',
        task: 'add_employees',
        action: 'click',
      });
      this.$router.push({ name: 'employees-add' });
    },
    goToBulkUpload() {
      this.postAnalyticsEvent({
        intent: 'navigate_employees_add_employee_bulk',
        task: 'add_employees',
        action: 'click',
      });
      this.$router.push({ name: 'employees-upload' });
    },
  },
};
</script>
