import uuidv4 from 'uuid/v4';

const defaultState = {
  registrationId: null,

  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPassword: '',
  userReferral: '',
  userPhone: '',

  companyNumber: null,
  companyName: '',

  paydayDayOfMonth: null,
  paydayDayOfWeek: null,
  salaryOrHourly: null,
  accrualType: null,
  payPeriodStart: null,
  periodicity: null,

  termsAccepted: false,
  subscriptionPlanChosen: null,
  promoCode: null,

  via: null,
  rewardfulCode: null,
  couponCode: null,
  pv: null,
  acId: null, // active campaign ID
  referrerUrl: null,

  // set from server responses
  userId: null,
  companyId: null,
  payScheduleId: null,
  termsUrl: '',
  termsId: '',
  agreementId: null,
  subscriptionId: null,

  // more info
  employees: '',
  businessType: '',
  wagestreamGoal: '',

  //provider
  provider: null,
  partnership: null,
  partnershipProperties: {},
};

const OnboardingState = {
  state: { ...defaultState },
  load() {
    const json = localStorage.getItem('sme.registration.state');
    if (json) {
      this.state = JSON.parse(json);
    }
    if (this.state.registrationId === null) {
      // If user has engaged with marketing content, use that ID, else generate new
      this.state.registrationId = localStorage.getItem('sme.lead.id') || uuidv4();
    }
    return this;
  },
  save() {
    localStorage.setItem('sme.registration.state', JSON.stringify(this.state));
    return this;
  },
};

export default OnboardingState;
