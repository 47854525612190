<template>
  <div>
    <PageListHeader>
      <template #left>
        <inline-search v-model="searchText" @search="onSearch" />
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          file-name="employees"
          :export-data="filteredEmployees"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </PageListHeader>

    <SmeCard class="mb-3">
      <AppLoading :loading="loading" />

      <b-table
        v-if="!loading"
        :items="filteredEmployees"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :primary-key="primaryKey"
        class="mb-0"
        table-class="table-minimal"
        responsive
        show-empty
      >
        <template #cell(employee_code)="data">
          <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
            data.item.employee_code
          }}</router-link>
        </template>
        <template #cell(full_name)="data">
          <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
            data.item.full_name
          }}</router-link>
        </template>
        <template #cell(current_state)="data">
          {{ toTitleCase(convertBackendStatusToDisplayStatus(data.item)) }}
        </template>
        <template #cell(add)="data">
          <b-button
            v-if="data.item.addFunction"
            class="border-0"
            size="sm"
            variant="outline-primary"
            @click="() => data.item.addFunction(data.item)"
          >
            <font-awesome-icon :icon="['fad', 'user-plus']" />
          </b-button>
        </template>
        <template #cell(delete)="data">
          <b-button
            v-if="data.item.deleteFunction"
            v-b-modal.delete-modal
            class="border-0"
            size="sm"
            variant="outline-primary"
            @click="employeeToDelete = data.item"
          >
            <font-awesome-icon :icon="['fad', 'trash']" />
          </b-button>
        </template>

        <template #row-details="data">
          <SmeAlert :level="data.item._showDetailsAlertVariant || ALERT_LEVELS.WARNING">
            <span>{{ data.item._showDetails }}</span>
          </SmeAlert>
        </template>
      </b-table>
    </SmeCard>

    <b-pagination
      v-model="currentPage"
      :total-rows="filteredEmployees.length"
      :per-page="perPage"
      class="justify-content-end"
      hide-goto-end-buttons
    />

    <b-modal
      id="delete-modal"
      ok-title="Confirm"
      ok-variant="danger"
      centered
      hide-header
      @cancel="employeeToDelete = undefined"
      @ok="() => employeeToDelete.deleteFunction(employeeToDelete)"
    >
      Are you sure?
    </b-modal>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import { toTitleCase } from '@/utils/common';
import { convertBackendStatusToDisplayStatus } from '@/utils/Employee';

const PER_PAGE_OPTIONS = [25, 50, 100];

const props = defineProps({
  employees: {
    type: Array,
    default: () => [],
  },
  fields: {
    type: Array,
    default: () => [],
  },
  primaryKey: {
    type: String,
  },
  currentPageInitial: Number,
});

const emit = defineEmits(['input:currentPage']);

const employeeToDelete = ref(undefined);
const filteredEmployees = ref(props.employees);
const loading = ref(false);
const searchText = ref('');
const currentPage = ref(props.currentPageInitial || 1);
const perPage = ref(25);

const fields = computed(() => {
  const fields = [...props.fields];

  if (props.employees.some(employee => employee.deleteFunction)) {
    fields.push({
      key: 'delete',
      label: '',
      class: 'text-right',
    });
  }

  if (props.employees.some(employee => employee.addFunction)) {
    fields.push({
      key: 'add',
      label: '',
      class: 'text-right',
    });
  }

  return fields;
});
const exportHeaders = computed(() => fields.value.map(field => field.label));

watch(currentPage, value => emit('input:currentPage', value));

const onSearch = () => {
  const filtered = props.employees.filter(employee => {
    return employee.full_name.toLowerCase().includes(searchText.value.toLowerCase());
  });
  filteredEmployees.value = filtered;
};

const exportFileRows = item => {
  return fields.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );
};
</script>

<style scoped lang="scss">
:deep(.b-table-details) {
  td {
    padding: 0 0 0.75rem 0;
  }
}
</style>
