<template>
  <div class="employees-chart">
    <div class="d-none d-sm-block employees-chart__chart-container">
      <Doughnut
        :chart-data="chartData"
        :chart-options="CHART_OPTIONS"
        :plugins="CHART_PLUGINS"
        class="employees-chart__chart"
      />
      <div class="employees-chart__stat">
        <div class="employees-chart__stat-value">{{ totalEmployees }}</div>
        <div class="employees-chart__stat-label">Eligible employees</div>
      </div>
    </div>

    <ul class="d-sm-none employees-chart__standalone-legend">
      <li class="employees-chart__standalone-legend-item employees-chart__standalone-legend-item--not-enrolled">
        Not enrolled ({{ series[0] }})
      </li>
      <li class="employees-chart__standalone-legend-item employees-chart__standalone-legend-item--pending">
        Enrolling ({{ series[1] }})
      </li>
      <li class="employees-chart__standalone-legend-item employees-chart__standalone-legend-item--enrolled">
        Enrolled ({{ series[2] }})
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { computed } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { PaletteColors } from '@/Theme';

ChartJS.register(ArcElement, CategoryScale, Legend, Title, Tooltip);

const CHART_OPTIONS = {
  circumference: 180,
  cutout: 80,
  elements: {
    arc: {
      borderRadius: 0,
      spacing: 1,
    },
  },
  layout: {
    padding: 0,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      color: 'white',
      font: {
        weight: 'bold',
      },
      formatter: value => (value ? value : ''),
    },
    legend: {
      position: 'right',
    },
  },
  rotation: -90,
};

const CHART_PLUGINS = [ChartDataLabels];

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
  totalEmployees: {
    type: Number,
    required: true,
  },
});

const chartData = computed(() => ({
  labels: ['Not enrolled', 'Enrolling', 'Enrolled'],
  datasets: [
    {
      backgroundColor: [PaletteColors['core-3'], PaletteColors['core-4'], PaletteColors['core-5']],
      data: props.series,
    },
  ],
}));
</script>

<style lang="scss" scoped>
$legend: (
  not-enrolled: var(--palette-color-core-3),
  pending: var(--palette-color-core-4),
  enrolled: var(--palette-color-core-5),
);

.employees-chart {
  margin-bottom: 1.5rem;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.employees-chart__chart-container {
  margin: 0 auto;
  position: relative;
  width: fit-content;
}

.employees-chart__chart {
  height: 15rem;
  margin: -2.5rem 0;
  position: relative;
  width: 25rem;
  z-index: 1;
}

.employees-chart__stat {
  bottom: 3.75rem;
  left: 0;
  line-height: 1.3;
  position: absolute;
  text-align: center;
  width: 16.75rem;
}

.employees-chart__stat-value {
  color: var(--palette-color-brand-primary);
  font-size: 2.1em;
}

.employees-chart__stat-label {
  font-size: small;
  font-weight: 600;
  white-space: nowrap;
}

.employees-chart__standalone-legend {
  font-weight: 600;
  margin: 0;
  padding-left: 1.5rem;
}

@each $state, $color in $legend {
  .employees-chart__standalone-legend-item--#{$state} {
    color: $color;
  }
}
</style>
