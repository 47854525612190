<template>
  <div>
    <sme-card v-if="loading">
      <app-loading loading />
    </sme-card>
    <div v-else-if="!hidden" ref="$map" class="flexible-work-map"></div>
  </div>
</template>

<script>
export const MARKER_TYPE = {
  LOCATION: 'LOCATION',
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYEE_OTHER: 'EMPLOYEE_OTHER',
};
</script>

<script setup>
import { Loader } from '@googlemaps/js-api-loader';
import { computed, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useRolesAndLocations from '@/state/composables/useRolesAndLocations';
import styles from '@/utils/map/styles.json';

const $router = useRouter();
const { employees, employeesLoading, getEmployees } = useEmployees();
const { locations, loadingLocations, getLocations } = useRolesAndLocations();

const MARKER_ICON_MAP = {
  [MARKER_TYPE.LOCATION]: 'map-pin-location',
  [MARKER_TYPE.EMPLOYEE]: 'map-pin-employee',
  [MARKER_TYPE.EMPLOYEE_OTHER]: 'map-pin-employee-other',
};

const MARKER_Z_INDEX_MAP = {
  [MARKER_TYPE.LOCATION]: 3,
  [MARKER_TYPE.EMPLOYEE]: 2,
  [MARKER_TYPE.EMPLOYEE_OTHER]: 1,
};

const markers = ref([]);
const loading = ref(true);
const center = ref(undefined);
const infoWindows = ref([]);
const hidden = ref(false);
const $map = ref(undefined);

const mappedMarkers = computed(() =>
  markers.value.map(marker => ({
    ...marker,
    lat: marker.latitude,
    lng: marker.longitude,
  })),
);

watch(loading, async () => {
  if (loading.value) {
    return;
  }

  if (!mappedMarkers.value.length) {
    hidden.value = true;
    return;
  }

  const loader = new Loader({ apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY });
  const [{ event, LatLngBounds, Point, Size }, { InfoWindow, Map }, { Marker }] = await Promise.all([
    loader.importLibrary('core'),
    loader.importLibrary('maps'),
    loader.importLibrary('marker'),
  ]);
  const map = new Map($map.value, {
    center: center.value,
    disableDefaultUI: true,
    styles,
    zoom: 12,
  });
  const bounds = new LatLngBounds();

  mappedMarkers.value.forEach(mappedMarker => {
    bounds.extend(mappedMarker);

    const marker = new Marker({
      icon: {
        url: require(`@/assets/${MARKER_ICON_MAP[mappedMarker.type]}.svg`),
        scaledSize: new Size(24, 32),
        origin: new Point(0, 0),
        anchor: new Point(12, 16),
      },
      map,
      position: mappedMarker,
      zIndex: MARKER_Z_INDEX_MAP[mappedMarker.type],
    });

    if (mappedMarker.link) {
      const infoWindow = new InfoWindow({
        content: `<a href="${mappedMarker.link}">${mappedMarker.name}</a>`,
      });

      infoWindows.value.push(infoWindow);

      marker.addListener('click', () => {
        infoWindows.value.forEach(_infoWindow => _infoWindow.close());
        infoWindow.open({ anchor: marker, map });
      });
    }
  });

  map.fitBounds(bounds);
});

const initMapData = () => {
  const _markers = [];

  watch(
    () => loadingLocations.value || employeesLoading.value,
    async loadingData => {
      if (loadingData) {
        return;
      }

      if (locations.value.length === 1) {
        center.value = {
          lat: locations.value[0].latitude,
          lng: locations.value[0].longitude,
        };
      }

      const allProximityEmployees = {};

      await Promise.all(
        locations.value.map(async location => {
          _markers.push({
            ...location,
            ...(State.state.claims?.a ? { link: getLocationLink(location) } : null),
            type: MARKER_TYPE.LOCATION,
          });

          ((await ApiClient.getFlexibleWorkLocationProximityMetrics(location.division_id))?.employees || []).forEach(
            employee => (allProximityEmployees[employee.employee_id] = employee),
          );
        }),
      );

      Object.values(allProximityEmployees).forEach(proximityEmployee => {
        const employee = employees.value.find(employee => employee.employee_id === proximityEmployee.employee_id);

        _markers.push({
          ...proximityEmployee,
          ...(employee
            ? {
                link: getEmployeeLink(employee),
                name: employee.full_name,
              }
            : null),
          type: employee ? MARKER_TYPE.EMPLOYEE : MARKER_TYPE.EMPLOYEE_OTHER,
        });
      });

      markers.value = _markers;
      loading.value = false;
    },
  );

  getLocations(true);
  getEmployees();
};

const getLocationLink = location =>
  $router.resolve({ name: 'division', params: { divisionId: location.division_id } })?.route.path;
const getEmployeeLink = employee =>
  $router.resolve({ name: 'employee', params: { employeeId: employee.employee_id } })?.route.path;

nextTick(() => initMapData());
</script>

<style lang="scss" scoped>
.flexible-work-map {
  background-color: var(--palette-color-base-background);
  box-shadow: var(--depth-2);
  border-radius: 8px;
  padding-bottom: 40%;

  @media (max-width: 576px) {
    padding-bottom: 56.25%;
  }
}
</style>
