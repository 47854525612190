<template>
  <article class="flexible-work-shift">
    <header
      class="flexible-work-shift__header"
      :class="[showShiftApproachingAlert && 'flexible-work-shift__header--emphasis']"
    >
      <button v-if="canExpand && !standalone" class="flexible-work-shift__toggle" @click="expanded = !expanded">
        <font-awesome-icon :icon="['fal', `chevron-${expanded ? 'up' : 'down'}`]" />
      </button>
      <div>
        <h3 class="flexible-work-shift__title mb-2">
          {{ shift.role_names.join(', ') }}
        </h3>
        <ul class="flexible-work-shift__meta m-0">
          <li class="flexible-work-shift__meta-item">
            <font-awesome-icon :icon="['far', 'calendar']" class="flexible-work-shift__meta-icon mr-2" />
            {{ toFormattedDate(shift.start_at) }} {{ toFormattedDate(shift.start_at, 'HH:mm') }} -
            {{ toFormattedDate(shift.end_at, 'HH:mm') }}
          </li>
          <li class="flexible-work-shift__meta-item">
            <font-awesome-icon :icon="['far', 'location-dot']" class="flexible-work-shift__meta-icon mr-2" />
            {{ shift.location_name }}
          </li>
          <li class="flexible-work-shift__meta-item">
            <flexible-work-shift-status :shift="shift" :claims="claims" />
          </li>
        </ul>
      </div>
      <aside
        class="flexible-work-shift__aside"
        :class="[showShiftApproachingAlert && 'flexible-work-shift__aside--emphasis']"
      >
        <template v-if="showShiftApproachingAlert">
          <font-awesome-icon :icon="['far', 'clock']" />
          This shift date is approaching. You may try to Boost or increase its remuneration.
        </template>
        <div class="flexible-work-shift__buttons">
          <b-button
            :to="{ name: 'flexible-work-shift', params: { shiftId: shift.shift_id } }"
            variant="outline-primary"
          >
            <font-awesome-icon :icon="['fad', canEditFields || canEditImmaterialFields ? 'pen-to-square' : 'eye']" />
            <span class="d-none d-md-inline ml-2">{{
              canEditFields || canEditImmaterialFields ? 'Edit' : 'View'
            }}</span>
          </b-button>
          <b-button v-if="isUnclaimed && isEnableFlexibleWorkBoost" variant="primary">
            <font-awesome-icon :icon="['far', 'gauge-max']" />
            <span class="d-none d-md-inline ml-2">Boost</span>
          </b-button>
        </div>
      </aside>
    </header>
    <section v-if="canExpand && expanded">
      <template v-if="isAssigned">
        <flexible-work-shift-claim
          :shift="shift"
          :claim="assignedClaim"
          :can-action="canClaimsBeActioned"
          :standalone="standalone"
          @updated="emit('updated')"
        />
        <template v-if="unassignedClaims.length">
          <section class="p-4">
            <sme-info-box
              :id="`flexible-work-shift-alternative-claims-${shift.shift_id}`"
              :title="`${unassignedClaims.length} more ${unassignedClaims.length === 1 ? 'person' : 'people'} applied`"
              default-open
              flush
              light
              save-state
            >
              <flexible-work-shift-claim
                v-for="claim in unassignedClaims"
                :key="claim.claim_id"
                :shift="shift"
                :claim="claim"
                :can-action="canClaimsBeActioned"
                alternative
                @updated="emit('updated')"
              />
            </sme-info-box>
          </section>
        </template>
      </template>
      <template v-else>
        <flexible-work-shift-claim
          v-for="claim in claims"
          :key="claim.claim_id"
          :shift="shift"
          :claim="claim"
          :can-action="canClaimsBeActioned"
          :standalone="standalone"
          @updated="emit('updated')"
        />
      </template>
    </section>
  </article>
</template>

<script setup>
import moment from 'moment';
import { computed, ref } from 'vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import FlexibleWorkShiftClaim from '@/pages/flexible-work/components/FlexibleWorkShiftClaim.vue';
import FlexibleWorkShiftStatus from '@/pages/flexible-work/components/FlexibleWorkShiftStatus.vue';
import { toFormattedDate } from '@/utils/date';
import { isIntegrated } from '@/utils/flexible-work';
import {
  getAssignedClaim,
  getUnassignedClaims,
  hasAssignedClaim,
  hasFulfilledClaim,
  hasWorkedClaim,
} from '@/utils/flexible-work/Claim';
import { isShiftInThePast } from '@/utils/flexible-work/Shift';

const { isEnableFlexibleWorkBoost } = useFeatureFlags();

const props = defineProps({
  shift: {
    type: Object,
    required: true,
  },
  claims: {
    type: Array,
    default: () => [],
  },
  standalone: Boolean,
});

const emit = defineEmits(['updated']);

const hasClaims = computed(() => !!props.claims.length);
const isApproaching = computed(() => moment().add(1, 'day').isAfter(props.shift.start_at));
const isInThePast = computed(() => isShiftInThePast(props.shift));
const isFulfilled = computed(() => hasFulfilledClaim(props.claims));
const isUnclaimed = computed(() => !props.claims.length);
const isWorked = computed(() => hasWorkedClaim(props.claims));
const isAssigned = computed(() => hasAssignedClaim(props.claims));
const assignedClaim = computed(() => getAssignedClaim(props.claims));
const unassignedClaims = computed(() => getUnassignedClaims(props.claims));
const showShiftApproachingAlert = computed(
  () => isEnableFlexibleWorkBoost.value && isUnclaimed.value && isApproaching.value,
);
const canClaimsBeActioned = computed(() => !isFulfilled.value);
const canExpand = computed(() => props.claims.length && canClaimsBeActioned.value);
const canEditFields = computed(() => props.isAddMode || (!isIntegrated() && !hasClaims.value && !isInThePast.value));
const canEditImmaterialFields = computed(
  () => canEditFields.value || (!isInThePast.value && !isFulfilled.value && !isWorked.value),
);

const expanded = ref((canExpand.value && props.standalone) || false);
</script>

<style lang="scss" scoped>
.flexible-work-shift {
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 8px;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

:deep() {
  .flexible-work-shift__header {
    align-items: center;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    position: relative;
  }

  .flexible-work-shift__header--emphasis {
    @media (max-width: 1024px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .flexible-work-shift__toggle {
    background: none;
    border: 0;
    font-size: 18px;
    padding: 0;
    width: 1rem;

    &::after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .flexible-work-shift__title {
    align-items: center;
    color: var(--palette-color-default);
    display: flex;
    font-size: 16px;
    gap: 0.5rem;
  }

  .flexible-work-shift__text {
    color: var(--palette-color-default-lighten-20);
  }

  .flexible-work-shift__meta {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
    padding: 0;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }

  .flexible-work-shift__meta-item {
    align-items: center;
    display: flex;
  }

  .flexible-work-shift__meta-icon {
    font-size: 15px;
  }

  .flexible-work-shift__aside {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-left: auto;
    position: relative;

    > button {
      position: relative;
      z-index: 1;
    }
  }

  .flexible-work-shift__aside--emphasis {
    background-color: var(--palette-color-core-1-lighten-90);
    border-radius: 8px;
    color: var(--palette-color-brand-primary);
    max-width: calc(50% + 3rem);
    padding: 0.5rem 1rem;

    @media (max-width: 1024px) {
      flex-basis: 100%;
      max-width: none;
      width: 100%;

      .flexible-work-shift__buttons {
        margin-left: auto;
      }
    }
  }
}
</style>

<style lang="scss">
.flexible-work-shift__buttons {
  align-items: center;
  display: flex;
  gap: 1rem;
  white-space: nowrap;

  & button {
    min-width: 8rem;
  }

  & a {
    min-width: 8rem;
  }
}
</style>
