<template>
  <b-modal
    :id="props.modalId"
    :size="props.size"
    :no-close-on-backdrop="props.noCloseOnBackdrop"
    :no-close-on-escape="props.noCloseOnEscape"
    hide-header
    centered
    ok-title="props.okBtnLabel"
    ok-variant="danger"
    :ok-disabled="props.okBtnDisabled"
    @ok="handleOk"
    ref="modal"
  >
    <div class="content">
      <h2>{{ props.title }}</h2>
      <p v-if="!$scopedSlots.content">{{ props.description }}</p>
      <slot name="content"></slot>
    </div>
    <template #modal-footer>
      <div class="custom-modal-footer">
        <b-button @click="handleCancel">{{ props.cancelBtnLabel }}</b-button>
        <b-button :variant="props.okBtnVariant" :disabled="props.okBtnDisabled" @click="handleOk">{{
          props.okBtnLabel
        }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  modalId: { type: String, required: true },
  size: { type: String, default: 'md' },
  title: { type: String, required: true },
  description: { type: String, default: '' },
  okBtnLabel: { type: String, default: 'Ok' },
  okBtnVariant: { type: String, default: 'primary', options: 'primary' | 'danger' },
  okBtnDisabled: { type: Boolean, default: false },
  cancelBtnLabel: { type: String, default: 'Cancel' },
  noCloseOnBackdrop: { type: Boolean, default: false },
  noCloseOnEscape: { type: Boolean, default: false },
});
const modal = ref(null);
const emit = defineEmits(['on-cancel', 'on-ok']);

const handleCancel = () => {
  emit('on-cancel');
  modal.value.hide();
};

const handleOk = () => {
  emit('on-ok');
  modal.value.hide();
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  max-height: 75dvh;
  padding: 1rem;
  width: 100%;
  overflow: auto;
  color: var(--palette-color-default);

  h2,
  p {
    margin-block: 0;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}

.custom-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 0.75rem;
  padding: 0.5rem 1rem 1rem 1rem;
  background: var(--palette-color-base-white);

  > * {
    margin: 0;
  }
}
</style>
