<template>
  <section class="tag-input mb-4" :class="['row' in $attrs && $attrs.row !== false && 'tag-input--row']">
    <app-input
      v-bind="controlAttrs"
      v-model="controlValue"
      v-on="controlListeners"
      :label="label"
      :name="name"
      :type="controlType"
      class="mb-0"
    >
      <template #prepend>
        <b-input-group-text>
          <font-awesome-icon :icon="['far', 'tag']" />
        </b-input-group-text>
      </template>
    </app-input>

    <template v-if="!($attrs.plaintext && !!$attrs['plaintext-formatter'])">
      <ul v-if="value.length" class="tag-input__tags mt-3">
        <li v-for="tag in value" :key="tag" class="tag-input__tag">
          <b-button variant="light" size="sm" @click="$attrs.disabled || $attrs.plaintext ? null : removeTag(tag)">
            {{ getTextForValue(tag) }}
            <font-awesome-icon v-if="!($attrs.disabled || $attrs.plaintext)" :icon="['fal', 'times']" class="ml-2" />
          </b-button>
        </li>
      </ul>
      <p v-else-if="$attrs.plaintext">-</p>
    </template>
  </section>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed, nextTick, ref, useAttrs } from 'vue';
import AppInput, { CONTROL_TYPES } from '@/components/AppInput.vue';

const DEFAULT_PLACEHOLDER = 'Add a tag...';

const $attrs = useAttrs();

const props = defineProps({
  label: String,
  name: String,
  value: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['input']);

const controlValue = ref(undefined);

const controlOptions = computed(() => props.options.filter(option => !props.value.includes(option.value)));
const controlPlaceholder = computed(() => $attrs.placeholder || DEFAULT_PLACEHOLDER);
const controlType = computed(() => (props.options.length ? CONTROL_TYPES.SELECT : CONTROL_TYPES.TEXT));
const controlAttrs = computed(() => ({
  ...$attrs,
  ...(controlType.value === CONTROL_TYPES.SELECT
    ? {
        options: controlOptions.value,
        undefinedAllow: true,
        undefinedLabel: controlPlaceholder.value,
      }
    : { placeholder: controlPlaceholder.value }),
}));
const controlListeners = computed(() =>
  controlType.value === CONTROL_TYPES.SELECT
    ? { input: addTag }
    : {
        keydown: event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            addTag();
          }
        },
      },
);

const addTag = () => {
  if (!controlValue.value) {
    return;
  }
  emit('input', [...new Set([...props.value, controlValue.value])]);
  nextTick(() => (controlValue.value = undefined));
};

const removeTag = tagToRemove => {
  emit(
    'input',
    props.value.filter(tag => tag !== tagToRemove),
  );
};

const getTextForValue = value => {
  const option = props.options.find(option => option.value === value);
  return option?.text || '';
};
</script>

<style lang="scss" scoped>
.tag-input--row {
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  padding-bottom: 1rem;

  &:deep(.app-input--row)::after {
    display: none;
  }

  .tag-input__tags {
    @media (min-width: 768px) {
      padding-left: calc((6 / 12) * 100% + 0.5rem);
    }

    @media (min-width: 992px) {
      padding-left: calc((5 / 12) * 100% + 0.5rem);
    }
  }
}

.tag-input__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.tag-input__tag {
  list-style-type: none;
}
</style>
