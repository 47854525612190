<template>
  <app-page
    title="Withdraw"
    icon="university"
    subtitle="Withdraw funds from your current balance"
    :previous="[{ label: 'Funds', to: { name: 'sme-funds' } }]"
    :error="error"
  >
    <template v-slot:custom-content>
      <b-container fluid>
        <b-row align-v="stretch">
          <!-- Account Details -->
          <b-col lg="4" order="1" order-lg="2" class="mb-4 mb-lg-0">
            <funding-account />
          </b-col>

          <!-- Form -->
          <b-col lg="8" order="2" order-lg="1">
            <sme-card>
              <ValidationObserver v-slot="v">
                <div class="mt-2 mb-2">
                  <strong>How much do you want to withdraw from your current balance?</strong>
                </div>
                <app-input
                  v-model="withdrawAmount"
                  rules="required|min_value:1"
                  description="Withdraw Amount (in £)"
                  type="number"
                />
                <strong>Your bank details</strong><br /><br />
                <app-input v-model="withdrawName" rules="required" description="Account Name" type="text" />
                <app-input
                  v-model="withdrawSortCode"
                  rules="required|numeric|min:6|max:6"
                  description="Sort Code"
                  type="text"
                />
                <app-input
                  v-model="withdrawNumber"
                  rules="required|numeric|min:8|max:8"
                  description="Account Number"
                  type="text"
                />
                <b-container fluid class="mt-6">
                  <b-row align-v="stretch">
                    <b-col class="p-0 pr-2">
                      <button class="btn btn-outline-primary w-100" @click="$router.go(-1)">Cancel</button>
                    </b-col>
                    <b-col class="p-0 pl-2">
                      <button class="btn btn-primary w-100" :disabled="v.invalid" @click="handleWithdraw">
                        Send Request
                      </button>
                    </b-col>
                  </b-row>
                </b-container>
              </ValidationObserver>
            </sme-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import AppInput from '@/components/AppInput';
import AppPage from '@/components/AppPage';
import FundingAccount from '@/components/FundingAccount';
import SmeCard from '@/components/atoms/SmeCard.vue';

export default {
  name: 'FundingWithdraw',
  components: {
    ValidationObserver,
    FundingAccount,
    AppInput,
    AppPage,
    SmeCard,
  },
  data() {
    return {
      error: undefined,
      withdrawAmount: 0,
      withdrawName: '',
      withdrawSortCode: '',
      withdrawNumber: '',
    };
  },
  methods: {
    async handleWithdraw() {
      // todo add actual API client
      alert('TODO');
    },
  },
};
</script>
