<template>
  <app-page
    :title="`Invite ${employee ? employee.full_name : 'Employee'}`"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="envelope"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning">
        You are about to reset the password and re-invite {{ employee.full_name }}
      </sme-alert>
      <page-sub-footer>
        <b-button @click="invite" variant="primary" :disabled="saving || saved">
          <b-spinner v-if="saving" class="mr-2" small />
          {{ saving ? 'Sending invite...' : 'Send invite' }}
        </b-button>
      </page-sub-footer>
    </template>
    <sme-alert v-if="saved" level="good">
      An invitation has been sent to <strong>{{ employee.work_email }}</strong>
    </sme-alert>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';

export default {
  name: 'EmployeeInvite',
  components: { AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
    };
  },
  methods: {
    invite() {
      this.saving = true;

      ApiClient.employeeInvite(this.employee.employee_id).then(
        data => {
          if (data.employee_invited) {
            this.saving = false;
            this.saved = true;
          } else {
            this.error = `There was an error inviting ${this.employee.full_name}`;
            this.loading = false;
          }
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
