import State from '@/state/State';

export const toCurrency = (value, currency) => {
  if (currency == null && State.state?.company?.default_currency) {
    currency = State.state.company.default_currency;
  }

  switch (currency) {
    case 'EUR':
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value / 100);
    case 'USD':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value / 100);
    case 'CAD':
      return new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 2,
      }).format(value / 100);
    case 'AUD':
      return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
      }).format(value / 100);
    default:
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
      }).format(value / 100);
  }
};

export const toRawCurrency = value => {
  return new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
  })
    .format(value / 100)
    .replace('GBP', '')
    .trim();
};

export const toCompactCurrency = value => {
  let currency = 'GBP';

  if (State.state?.company?.default_currency) {
    currency = State.state.company.default_currency;
  }

  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(value / 100);
    case 'CAD':
      return new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
      }).format(value / 100);
    default:
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      }).format(value / 100);
  }
};

export const getUtmParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of urlParams) {
    if (key.startsWith('utm_')) {
      params[key] = value;
    }
  }
  return params;
};

export const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of urlParams) {
    params[key] = value;
  }
  return params;
};

export const convertUuidToReferralCode = uuid => {
  const hex = uuid.replace(/-/g, '');
  // eslint-disable-next-line no-undef
  let decimal = BigInt('0x' + hex);
  const digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  while (decimal > 0n) {
    result = digits[Number(decimal % 62n)] + result;
    decimal /= 62n;
  }
  return result;
};

export const convertReferralCodeToUuid = encoded => {
  const digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let decimal = 0n;
  for (const char of encoded) {
    // eslint-disable-next-line no-undef
    decimal = decimal * 62n + BigInt(digits.indexOf(char));
  }

  const hex = decimal.toString(16).padStart(32, '0');
  return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
};
