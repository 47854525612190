<template>
  <app-page title="App Analytics" icon="mobile-screen-button" class="analytics-app">
    <template #header>
      <divisions-dropdown />
    </template>
    <template #custom-content>
      <b-col>
        <analytics-nav />
        <snapshot-metrics />
        <track-metrics />
        <stream-metrics />
        <build-metrics />
        <sdl-metrics v-if="isEnableSdl" />
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from '@/components/AppPage.vue';
import BuildMetrics from '@/pages/analyticsApp/components/BuildMetrics.vue';
import SnapshotMetrics from '@/pages/analyticsApp/components/SnapshotMetrics.vue';
import StreamMetrics from '@/pages/analyticsApp/components/StreamMetrics.vue';
import TrackMetrics from '@/pages/analyticsApp/components/TrackMetrics.vue';
import AnalyticsNav from '@/pages/analyticsHomepage/components/AnalyticsNav.vue';
import DivisionsDropdown from '@/pages/analyticsHomepage/components/DivisionsDropdown.vue';
import SdlMetrics from '@/pages/analyticsApp/components/SdlMetrics.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';

const { isEnableSdl } = useFeatureFlags();
</script>
