<template>
  <sub-page
    v-if="missedSalaryEmployees.length"
    title="Missed Salaries"
    subtitle="We have not received salaries for some of your employees"
  >
    <header class="d-flex flex-column flex-sm-row align-items-end mb-3">
      <sme-alert :level="ALERT_LEVELS.DANGER" class="mb-3 mb-sm-0" v-if="hideStreamAccountRelatedItems">
        To rectify, please check with your banking provider that the salary was successfully sent to the user's
        Wagestream bank details. If payment appears successful, please reach out to
        <a href="mailto:client.support@wagestream.com">client.support@wagestream.com</a> with as much information as
        possible about the transaction.
      </sme-alert>
      <export-button
        :file-name="exportFilename"
        :export-data="missedSalaryEmployees"
        :export-file-rows="exportFileRows"
        :export-headers="EXPORT_HEADERS"
      />
    </header>

    <sme-detailed-table
      :fields="FIELDS"
      :items="missedSalaryEmployees"
      sort-by="outstanding_debt"
      tbody-tr-class="sme-detailed-table__row--link"
      sort-desc
      sticky-header
      @row-clicked="item => $router.push({ name: 'employee', params: { employeeId: item.employee_id } })"
    >
      <template #cell(employee_name)="data">
        <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
          <strong class="sme-detailed-table__label sme-detailed-table__label--large">
            {{ data.item.employee_name }}
          </strong>
          <span class="sme-detailed-table__value sme-detailed-table__value--primary">
            {{ data.item.employee_code }}
          </span>
        </div>
      </template>
      <template #cell(pay_schedule_name)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Pay schedule:</span>
          <router-link
            :to="{ name: 'pay-schedule', params: { payScheduleId: data.item.pay_schedule_id } }"
            class="sme-detailed-table__value"
          >
            <strong>{{ data.item.pay_schedule_name }}</strong>
          </router-link>
        </div>
      </template>
    </sme-detailed-table>
  </sub-page>
</template>

<script setup>
import moment from 'moment/moment';
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router/composables';
import ExportButton from '@/components/ExportButton.vue';
import SubPage from '@/components/SubPage.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import { toCurrency } from '@/utils';
import useFeatureFlags from '@/composables/useFeatureFlags';

const $router = useRouter();
const { payscheduleReports, getEmployeesReport } = useEmployeesReport();
const { hideStreamAccountRelatedItems } = useFeatureFlags();

const FIELDS = [
  {
    key: 'employee_name',
    label: 'Name',
  },
  {
    key: 'pay_schedule_name',
    label: 'Pay schedule',
  },
  {
    key: 'last_expected_payday',
    label: 'Pay day',
  },
  {
    key: 'outstanding_debt',
    label: 'Outstanding',
    formatter: value => toCurrency(value),
  },
];

const EXPORT_HEADERS = FIELDS.map(field => field.label);

const missedSalaryEmployees = computed(() => {
  return payscheduleReports.value.flatMap(report => {
    report.missedSalaries.forEach(employee => (employee.pay_schedule_name = report.payschedule.name));

    return report.missedSalaries;
  });
});

const exportFilename = computed(() => {
  const today = moment().format('YYYY-MM-DD');
  let companyName = '';
  if (State.state.company.name) {
    companyName = State.state.company.name.toLowerCase()?.replace(/ /g, '-');
  }

  return `payroll-missed-salaries-list-${companyName}-${today}`;
});

const exportFileRows = item =>
  FIELDS.map(field => (field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key]));

onBeforeMount(async () => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 40);
  await getEmployeesReport(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
});
</script>
