import ApiClient from '@/ApiClient';
import { queryOptions, useQueryClient } from '@tanstack/vue-query';
import { compareDesc, endOfToday, isPast, isFuture, startOfToday } from 'date-fns';

const LIMIT = 1000;

export const getShiftsStats = locationId => {
  return queryOptions({
    queryKey: ['get_shift_stats', locationId],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolLocationShiftFulfillmentSummary(locationId);
      return result.data;
    },
  });
};

export const getShift = (shiftId, disable) => {
  return queryOptions({
    enabled: !disable,
    queryKey: ['get_shift', shiftId],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolShift(shiftId);
      return result.data;
    },
  });
};

export const getShifts = (payload, onSelect) => {
  return queryOptions({
    queryKey: ['get_shifts', payload],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolShiftsByLocation({ limit: LIMIT, ...payload });

      return result.data;
    },
    select: onSelect,
    refetchOnMount: false,
  });
};

export const getShiftsForJob = jobId => {
  return getShifts({ group_id: jobId });
};

export const postBulkShifts = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.postBulkTalentPoolShifts(payload.payload);
      return result.data;
    },
    onSuccess: async () => {
      onSuccess();
      await queryClient.invalidateQueries('get_shifts');
    },
  };
};

export const getTodaysShifts = locationId => {
  return getShifts({
    location_id: locationId,
    ends_after: startOfToday().toISOString(),
    starts_before: endOfToday().toISOString(),
  });
};

export const getOpenShifts = locationId => {
  return getShifts(
    {
      location_id: locationId,
      most_relevant_claim_statuses: ['UNFILLED', 'REJECTED', 'DENIED'],
      starts_after: startOfToday().toISOString(),
    },
    data => data.filter(shift => isFuture(shift.end_at)),
  );
};

export const getPendingShifts = locationId => {
  return getShifts(
    {
      location_id: locationId,
      most_relevant_claim_statuses: ['PENDING'],
      starts_after: startOfToday().toISOString(),
    },
    data => data.filter(shift => isFuture(shift.end_at)),
  );
};

export const getApprovedShifts = locationId => {
  return getShifts({
    location_id: locationId,
    most_relevant_claim_statuses: ['APPROVED'],
  });
};

export const getFulfilledShifts = (locationId, reverse) => {
  return getShifts(
    {
      location_id: locationId,
      most_relevant_claim_statuses: ['FULFILLED'],
    },
    data => (reverse ? data.sort((a, b) => compareDesc(a.start_at, b.start_at)) : data),
  );
};

export const getUnfilledShifts = (locationId, reverse) => {
  return getShifts(
    {
      location_id: locationId,
      most_relevant_claim_statuses: ['UNFILLED', 'REJECTED', 'DENIED', 'PENDING', 'NO_SHOW'],
    },
    data => {
      const filteredData = data.filter(shift => isPast(shift.end_at));
      return reverse ? filteredData.sort((a, b) => compareDesc(a.start_at, b.start_at)) : filteredData;
    },
  );
};

export const postAddShift = onSuccess => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.postTalentPoolShift(payload);
      return result.data;
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries(getUnfilledShifts(data.location_id).queryKey);
      onSuccess(data);
    },
  };
};

export const updateShift = onSuccess => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.updateTalentPoolShift(payload.shift_id, payload);
      return result.data;
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries(getUnfilledShifts(data.location_id).queryKey);
      onSuccess(data);
    },
  };
};

export const postRatings = () => {
  return {
    mutationFn: async payload => {
      const result = await ApiClient.addTalentPoolShiftClaimRating(
        payload.assignedTo,
        payload.shiftId,
        payload.rating,
        { tags: payload.tags },
      );
      return result.data;
    },
    onError: () => {
      return null;
    },
  };
};

export const postSignOffShift = ({ onError, onSuccess, locationId }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.signOffTalentPoolShiftClaim(payload.assignedTo, payload.data);
      return result.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(getApprovedShifts(locationId).queryKey);
      onSuccess();
    },
    onError: onError,
  };
};

export const deleteShift = ({ locationId }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async shiftId => {
      const result = await ApiClient.deleteTalentPoolShift(shiftId);
      return result.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(getUnfilledShifts(locationId).queryKey);
    },
  };
};

export const postApproveShiftClaim = ({ onError, onSuccess, locationId }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async claimId => {
      const result = await ApiClient.approveTalentPoolShiftClaim(claimId);
      return result.data;
    },
    onSuccess: async data => {
      onSuccess(data);
      await queryClient.invalidateQueries(getPendingShifts(locationId).queryKey);
    },
    onError: onError,
  };
};

export const getClockInInfo = assignedTo => {
  return {
    queryKey: ['get_clock_in_info', assignedTo],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolShiftClockIn(assignedTo);
      return result.data;
    },
  };
};

export const shiftClockIn = ({ onSuccess, shiftId, onError }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async ({ assignedTo, payload }) => {
      const result = await ApiClient.postTalentPoolShiftClockIn(assignedTo, payload);
      return result.data;
    },
    onSuccess: async data => {
      onSuccess(data);
      await queryClient.invalidateQueries(getShift(shiftId).queryKey);
    },
    onError: onError,
  };
};

export const noShow = ({ shiftId, locationId, onSuccess }) => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async claimId => {
      const result = await ApiClient.postNoShow(claimId);
      return result.data;
    },
    onSuccess: async data => {
      onSuccess(data);
      await queryClient.invalidateQueries(getShift(shiftId).queryKey);
      await queryClient.invalidateQueries(getFulfilledShifts(locationId).queryKey);
    },
  };
};
