<template>
  <div class="pay-schedule-table">
    <slot name="before"></slot>
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <header class="pay-schedule-table__header">
        <div class="pay-schedule-table__payschedule-name">
          <app-input
            v-model="localPayscheduleName"
            type="text"
            name="payschedule-name"
            rules="required|min:2"
            label="Payschedule name"
            placeholder=""
            :disabled="isDisablePayscheduleEditing"
            :validate-immediate="false"
          />
        </div>
        <app-input
          v-model="localPayscheduleSalaryOrHourly"
          type="radio"
          name="salary or hourly"
          rules="required"
          label="Payschedule type"
          :options="salaryOptions"
          buttons
          button-variant="outline-primary"
          :disabled="isDisablePayscheduleEditing"
        />
        <app-input
          v-model="localPayschedulePeriodicity"
          type="select"
          name="Period"
          rules="required"
          label="Period"
          :options="periodicityOptions"
          :disabled="isDisablePayscheduleEditing"
        />
        <div class="pay-schedule-table__blackout-period">
          <app-input
            v-model="deltaBlackoutPeriod"
            type="select"
            name="Blackout period"
            rules="required"
            :options="payscheduleDays"
            :disabled="isDisablePayscheduleEditing"
          >
            <template #label>
              <span
                v-b-tooltip
                title="The number of days before each pay day that employees cannot enroll. This allows you to prepare your pay run without updating employee bank details"
                class="mr-1"
              >
                <font-awesome-icon :icon="['fal', 'question-circle']"></font-awesome-icon>
              </span>
              Blackout period days
            </template>
          </app-input>
        </div>
      </header>

      <b-row>
        <template v-if="hasPayPeriods">
          <b-col lg="6">
            <div class="pay-schedule-table__table-container" ref="payPeriodTableContainer">
              <header v-if="!create && hasDatesInPast" class="pay-schedule-table__table-header m-3">
                <b-button variant="outline-primary" @click="showDatesInPast = !showDatesInPast">
                  {{ showDatesInPast ? 'Hide' : 'Show' }} past pay periods
                </b-button>
              </header>
              <b-table
                class="mb-0"
                :items="localItems"
                :hover="$attrs.hover !== undefined ? $attrs.hover : true"
                :fields="fields"
                borderless
                @row-clicked="(item, index) => setActiveRowIndex(index)"
                table-class="pay-period-table"
                :tbody-tr-class="getRowClasses"
                thead-tr-class="d-none"
              >
                <template #cell(pay_period_start)="row">
                  <div>
                    <label :for="`pay-start-${row.index}`" class="mb-1">Period Start</label>
                    <div class="pay-schedule-table__period-dates">
                      <app-input
                        v-model="localItems[row.index].pay_period_start"
                        :name="`pay-start-${row.index}`"
                        :disabled="
                          (!create && getIsDateInThePast(row.item.pay_period_end)) || isDisablePayscheduleEditing
                        "
                        type="date"
                        class="m-0"
                        rules="required"
                        mask-valid
                        @input="onPayPeriodStartChange(row.index)"
                        @shown="setActiveRowIndex(row.index)"
                      ></app-input>
                      <label class="mx-2 mb-0">to</label>
                      <template v-if="isLastPeriod(row.index)">
                        <span class="mr-2">-</span>
                        <span v-b-tooltip title="Period end is not required for the last pay period of a pay schedule.">
                          <font-awesome-icon :icon="['fal', 'question-circle']"></font-awesome-icon>
                        </span>
                      </template>
                      <span v-else class="pay-schedule-table__period-end-date">
                        {{ toFormattedDate(localItems[row.index].pay_period_end) }}
                      </span>
                    </div>
                  </div>
                </template>

                <template #cell(paid_on)="row">
                  <label :for="`paid-on-${row.index}`" class="mb-1">Paid on</label>
                  <div class="pay-schedule-table__period-dates">
                    <app-input
                      v-model="localItems[row.index].paid_on"
                      type="date"
                      :name="`paid-on-${row.index}`"
                      :disabled="
                        (!create && getIsDateInThePast(row.item.pay_period_end)) || isDisablePayscheduleEditing
                      "
                      class="m-0"
                      rules="required"
                      mask-valid
                      @input="onPayPeriodPaidOnChange(row.index)"
                      @shown="setActiveRowIndex(row.index)"
                    ></app-input>
                    <b-button
                      v-if="getIsPayPeriodDeletable(row.index) && !isDisablePayscheduleEditing"
                      title="Delete this pay period"
                      @click="onDeletePayPeriod"
                      variant="outline-primary"
                      class="ml-auto border-0"
                      ><font-awesome-icon :icon="['fad', 'trash']"
                    /></b-button>
                  </div>
                </template>

                <template #row-details="row">
                  <sme-alert class="pay-schedule-table__alert" level="danger" flush>
                    <template v-if="rowErrors[row.index].paidOn">
                      <span>{{ rowErrors[row.index].paidOn }}</span> <br />
                    </template>
                    <template v-if="rowErrors[row.index].start">
                      <span>{{ rowErrors[row.index].start }}</span> <br />
                    </template>
                    <template v-if="rowErrors[row.index].end">
                      <span>{{ rowErrors[row.index].end }}</span> <br />
                    </template>
                  </sme-alert>
                </template>
              </b-table>
              <footer v-if="!isDisablePayscheduleEditing" class="pay-schedule-table__table-footer">
                <b-button class="mt-4 mb-2" variant="outline-primary" @click="onAddPayPeriod"
                  >Add a pay period</b-button
                >
              </footer>
            </div>
          </b-col>
          <b-col v-if="activePayPeriod" lg="6" class="pay-schedule-table__calendar-column">
            <b-calendar
              :date-info-fn="getDateClasses"
              :max="activePayPeriodCalendarMax"
              :min="activePayPeriodCalendarMin"
              :value="activePayPeriod.paid_on"
              class="pay-schedule-table__calendar"
              :class="{ 'pay-schedule-table__calendar--hide-nav': isActivePayPeriodCalendarSingleMonth }"
              nav-button-variant="primary"
              selected-variant="primary"
              today-variant="primary"
              block
              hide-header
              readonly
            >
              <template v-slot:nav-prev-month>Prev Month</template>
              <template v-slot:nav-next-month>Next Month</template>
            </b-calendar>
          </b-col>
        </template>
      </b-row>

      <page-sub-footer v-if="!loading" :flush="!hasPayPeriods" :flush-alert="isDisablePayscheduleEditing">
        <template #before>
          <sme-alert v-if="!hasPayPeriods" level="warning">
            <strong>This pay schedule is empty.</strong>
            <template v-if="!isDisablePayscheduleEditing">Add your upcoming pay periods and pay days.</template>
          </sme-alert>
        </template>

        <template v-if="!isDisablePayscheduleEditing">
          <b-button v-if="!hasPayPeriods" variant="primary" @click="onAddPayPeriod">Add a pay period</b-button>
          <b-button v-else-if="create" variant="primary" @click="onCreatePaySchedule"> Create payschedule </b-button>
          <b-button v-else variant="primary" @click="onUpdatePaySchedule"> Update payschedule </b-button>
        </template>
      </page-sub-footer>
    </template>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment/moment';
import uuidv4 from 'uuid/v4';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import {
  PERIODICITY,
  PERIODICITY_LABELS,
  PERIODICITY_VALUES,
  SALARY_TYPE,
  SALARY_TYPE_LABELS,
} from '@/utils/Payschedule';
import { getPayTypeLabel } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'PayScheduleForm',
  components: { AppLoading, AppInput, PageSubFooter, SmeAlert },
  inheritAttrs: false,
  props: {
    create: Boolean,
    loading: Boolean,
    paySchedule: Object,
  },
  emits: ['create-pay-schedule', 'update-pay_schedule'],
  data() {
    return {
      DATE_FORMAT,
      localPayscheduleSalaryOrHourly: SALARY_TYPE.MIXED,
      localPayscheduleName: '',
      localPayschedulePeriodicity: PERIODICITY.MONTHLY,
      localItems: [],
      deltaBlackoutPeriod: 0,
      state: State.state,
      activeRowIndex: undefined,
      fields: [
        {
          key: 'pay_period_start',
          label: 'Pay Period Start',
        },
        {
          key: 'paid_on',
          label: 'Paid On',
        },
      ],
      salaryOptions: Object.values(SALARY_TYPE).map(value => {
        return { value, text: SALARY_TYPE_LABELS[value] };
      }),
      periodicityOptions: Object.values(PERIODICITY).map(value => {
        return { value, text: PERIODICITY_LABELS[value] };
      }),
      rowErrors: [],
      showDatesInPast: false,
    };
  },
  computed: {
    activePayPeriod() {
      return this.localItems?.[this.activeRowIndex] || undefined;
    },
    activePayPeriodCalendarMin() {
      if (this.activePayPeriod) {
        const dates = [this.activePayPeriod.pay_period_start, this.activePayPeriod.paid_on].map(date => moment(date));

        return moment.min(dates).format(DATE_FORMAT);
      }

      return undefined;
    },
    activePayPeriodCalendarMax() {
      if (this.activePayPeriod) {
        if (this.isLastPeriod(this.activeRowIndex)) {
          const dates = [this.activePayPeriod.pay_period_start, this.activePayPeriod.paid_on].map(date => moment(date));

          return moment.max(dates).endOf('month').format(DATE_FORMAT);
        }

        const dates = [this.activePayPeriod.pay_period_end, this.activePayPeriod.paid_on].map(date => moment(date));

        return moment.max(dates).format(DATE_FORMAT);
      }

      return undefined;
    },
    isActivePayPeriodCalendarSingleMonth() {
      return (
        this.activePayPeriod && moment(this.activePayPeriodCalendarMin).isSame(this.activePayPeriodCalendarMax, 'month')
      );
    },
    hasPayPeriods() {
      return !!this.localItems?.length;
    },
    hasDatesInPast() {
      return this.localItems?.some(item => this.getIsDateInThePast(item.pay_period_end));
    },
    payscheduleDays() {
      const dateDiff = this.localItems
        .map(d => moment(d.paid_on).diff(d.pay_period_start, 'days'))
        .filter(diff => diff > 0);
      const minDays = dateDiff.length ? Math.min(...dateDiff) : 1;
      return [...Array(minDays).keys()];
    },
  },
  watch: {
    localItems: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (val.length && this.activeRowIndex === undefined) {
            this.setInitialActiveRowIndex();
          }

          this.setRowErrors();
        }
      },
      immediate: true,
    },
    localPayschedulePeriodicity: {
      handler() {
        if (!this.localItems.length) {
          return;
        }

        const lastItemIndex = this.localItems.length - 1;
        const lastItemStart = this.localItems[lastItemIndex].pay_period_start;

        this.localItems[lastItemIndex].pay_period_end = this.getEstimatedPeriodEnd(lastItemStart).format(DATE_FORMAT);
      },
      immediate: true,
    },
    paySchedule: {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.setLocalItems();
          this.setBlackoutPeriod();
        }
      },
      immediate: true,
    },
  },
  setup() {
    const { isDisablePayscheduleEditing } = useFeatureFlags();

    return { isDisablePayscheduleEditing };
  },
  mounted() {
    this.setLocalItems();
    this.setRowErrors();
    this.setBlackoutPeriod();
  },
  methods: {
    toFormattedDate,
    onCreatePaySchedule() {
      const paySchedule = {
        dates: this.localItems.map(item => ({
          blackout_period_start: moment(item.paid_on).subtract(this.deltaBlackoutPeriod, 'days').format(DATE_FORMAT),
          paid_on: item.paid_on,
          pay_period_start: item.pay_period_start,
          pay_schedule_date_id: uuidv4(),
          pay_schedule_id: null,
          properties: null,
        })),
        name: this.localPayscheduleName,
        periodicity: this.localPayschedulePeriodicity,
        properties: {
          pay_schedule_code: this.localPayscheduleSalaryOrHourly,
        },
      };

      this.$emit('create-pay-schedule', paySchedule);
    },
    onUpdatePaySchedule() {
      const paySchedule = {
        ...this.paySchedule,
        dates: this.localItems.map(item => ({
          blackout_period_start: moment(item.paid_on).subtract(this.deltaBlackoutPeriod, 'days').format(DATE_FORMAT),
          paid_on: item.paid_on,
          pay_period_start: item.pay_period_start,
          pay_schedule_date_id: item.pay_schedule_date_id || uuidv4(),
          pay_schedule_id: item.pay_schedule_id || this.paySchedule.pay_schedule_id,
          properties: item.properties || null,
        })),
        name: this.localPayscheduleName,
        periodicity: this.localPayschedulePeriodicity,
        properties: {
          ...this.paySchedule.properties,
          pay_schedule_code: this.localPayscheduleSalaryOrHourly,
        },
      };

      this.$emit('update-pay-schedule', paySchedule);
    },
    onAddPayPeriod() {
      let start, end, paidOn;

      if (this.hasPayPeriods) {
        const lastItem = this.localItems[this.localItems.length - 1];
        const lastItemStart = moment(lastItem.pay_period_start);
        const lastItemEnd = moment(lastItem.pay_period_end);
        const lastItemPaidOn = moment(lastItem.paid_on);
        const lastDaysStartToPaidOn = lastItemPaidOn.diff(lastItemStart, 'days');
        const newItemStart = lastItemEnd.clone().add(1, 'days');

        start = newItemStart.format(DATE_FORMAT);
        end = this.getEstimatedPeriodEnd(newItemStart).format(DATE_FORMAT);
        paidOn = newItemStart.clone().add(lastDaysStartToPaidOn, 'days').format(DATE_FORMAT);
      } else {
        start = this.getInitialPeriodStart().format(DATE_FORMAT);
        end = this.getEstimatedPeriodEnd(start).format(DATE_FORMAT);
        paidOn = this.getInitialPeriodPaidOn(start, end).format(DATE_FORMAT);
      }

      const newItems = cloneDeep(this.localItems || []).concat([
        {
          paid_on: paidOn,
          pay_period_start: start,
          pay_period_end: end,
        },
      ]);

      this.activeRowIndex = newItems.length - 1;
      this.localItems = newItems;

      this.$nextTick(() => {
        this.$refs.payPeriodTableContainer.scrollTop = this.$refs.payPeriodTableContainer.scrollHeight;
      });
    },
    onDeletePayPeriod() {
      this.localItems = this.localItems.slice(0, -1);

      if (this.localItems.length) {
        if (this.activeRowIndex > this.localItems.length - 1) {
          this.activeRowIndex = this.localItems.length - 1;
        }

        const lastItemIndex = this.localItems.length - 1;
        const lastItemStart = this.localItems[lastItemIndex].pay_period_start;

        this.localItems[lastItemIndex].pay_period_end = this.getEstimatedPeriodEnd(lastItemStart).format(DATE_FORMAT);
      }
    },
    async onPayPeriodPaidOnChange(index) {
      const paidOn = moment(this.localItems[index].paid_on);
      const nextPaidOn = this.localItems[index + 1] ? moment(this.localItems[index + 1].paid_on) : null;
      let nextPaidOnLimitBreached = false;

      if (nextPaidOn) {
        nextPaidOnLimitBreached = paidOn.diff(nextPaidOn, 'days') >= 0;
      }

      if (nextPaidOnLimitBreached) {
        this.rowErrors[index].paidOn = 'Cannot set paid on to be after a subsequent period paid on!';
        this.localItems[index]._showDetails = true;
      }

      this.validatePayPeriodPaidOnAgainstStart(index);
    },
    async onPayPeriodStartChange(index) {
      const start = moment(this.localItems[index].pay_period_start);
      const end = moment(this.localItems[index].pay_period_end);
      const isEndPastStart = start.diff(end, 'days') >= 0;
      const isFirstAndOnlyPeriod = index === 0 && this.localItems.length === 1;

      this.clearRowError(index, 'start');

      if (isEndPastStart) {
        if (isFirstAndOnlyPeriod) {
          this.rowErrors[index].start = "First period must be current, it can't be in the future!";
          this.localItems[index]._showDetails = true;
        } else if (!this.isLastPeriod(index)) {
          this.rowErrors[index].start = 'Period start must come before period end!';
          this.localItems[index]._showDetails = true;
        }
      }

      if (index > 0) {
        const previousStart = moment(this.localItems[index - 1].pay_period_start);
        const previousEnd = moment(this.localItems[index - 1].pay_period_end);
        const isConflictedWithPreviousPeriod = start.diff(previousStart, 'days') < 2;
        const isDisconnectedFromPreviousPeriod = start.diff(previousEnd, 'days') !== 1;

        if (isConflictedWithPreviousPeriod) {
          this.rowErrors[index].start = 'Period start must come after the previous period!';
          this.localItems[index]._showDetails = true;
        }

        if (isDisconnectedFromPreviousPeriod) {
          this.localItems[index - 1].pay_period_end = start.add(-1, 'days').format(DATE_FORMAT);

          if (this.isLastPeriod(index)) {
            this.localItems[index].pay_period_end = this.getEstimatedPeriodEnd(start).format(DATE_FORMAT);
          }
        }
      }

      this.validatePayPeriodPaidOnAgainstStart(index);
    },
    validatePayPeriodPaidOnAgainstStart(index) {
      const paidOn = moment(this.localItems[index].paid_on);
      const start = moment(this.localItems[index].pay_period_start);
      const isBeforeStart = moment(paidOn).diff(start, 'days') < 0;

      this.clearRowError(index, 'paidOn');

      if (isBeforeStart && !this.rowErrors[index].start) {
        this.rowErrors[index].paidOn = 'Cannot set paid on to be before period start!';
        this.localItems[index]._showDetails = true;
      }
    },
    setLocalItems() {
      const payType = this.paySchedule?.properties?.pay_schedule_code || SALARY_TYPE.MIXED;
      if (payType) {
        this.localPayscheduleSalaryOrHourly = getPayTypeLabel(payType).toLowerCase();
      }
      this.localPayscheduleName = this.paySchedule?.name || '';
      this.localPayschedulePeriodicity = this.paySchedule?.periodicity || PERIODICITY.MONTHLY;

      const localItems =
        this.paySchedule?.dates?.map(item => ({
          _showDetails: false,
          pay_period_end: '',
          ...item,
        })) || [];

      if (localItems.length > 0) {
        for (let i = 0; i < localItems.length - 1; i++) {
          localItems[i].pay_period_end = moment(localItems[i + 1].pay_period_start)
            .subtract(1, 'days')
            .format(DATE_FORMAT);
        }

        if (localItems.length > 1) {
          const lastItem = localItems[localItems.length - 1];
          const lastItemStart = moment(lastItem.pay_period_start);

          localItems[localItems.length - 1].pay_period_end =
            this.getEstimatedPeriodEnd(lastItemStart).format(DATE_FORMAT);
        }
      }

      this.localItems = localItems;
    },
    setInitialActiveRowIndex() {
      const dateNotInPastIndex = this.localItems.findIndex(item => !this.getIsDateInThePast(item.pay_period_end));
      this.activeRowIndex = dateNotInPastIndex !== -1 ? dateNotInPastIndex : 0;
    },
    setRowErrors() {
      if (this.hasPayPeriods) {
        this.rowErrors = this.localItems.map((item, index) => {
          return {
            paidOn: '',
            start: '',
            end: '',
            ...this.rowErrors[index],
          };
        });
      } else {
        this.rowErrors = [];
      }
    },
    clearRowError(index, field) {
      this.rowErrors[index][field] = '';

      if (Object.values(this.rowErrors[index]).every(error => !error)) {
        this.localItems[index]._showDetails = false;
      }
    },
    hasRowError(index) {
      return this.rowErrors[index] ? Object.values(this.rowErrors[index]).some(error => !!error) : false;
    },
    setActiveRowIndex(index) {
      this.activeRowIndex = index;
    },
    getDateClasses(date) {
      const classes = [];
      const currentCalendarDate = moment(date);
      const minCalendarDate = moment(this.activePayPeriod.pay_period_start);
      const maxCalendarDate = moment(this.activePayPeriod.pay_period_end);

      if (
        (this.isLastPeriod(this.activeRowIndex) && currentCalendarDate >= minCalendarDate) ||
        (!this.isLastPeriod(this.activeRowIndex) &&
          currentCalendarDate >= minCalendarDate &&
          currentCalendarDate <= maxCalendarDate)
      ) {
        classes.push('pay-schedule-table__calendar-range');
      }

      return classes;
    },
    getRowClasses(item, type) {
      let classes = [];
      const index = this.localItems.indexOf(item);

      if (type === 'row') {
        classes = classes.concat([
          'pay-schedule-table__period',
          this.activeRowIndex === index ? 'pay-schedule-table__period--selected' : '',
          this.hasRowError(index) ? 'pay-schedule-table__period--invalid' : '',
        ]);
      } else if (type === 'row-details') {
        classes.push('pay-schedule-table__alert-wrapper');
      }

      if (!this.create && this.getIsDateInThePast(item.pay_period_end)) {
        classes = classes.concat(['pay-schedule-table__period--disabled', !this.showDatesInPast ? 'd-none' : '']);
      }

      return classes.filter(className => !!className);
    },
    getIsPayPeriodDeletable(index) {
      if (
        this.isLastPeriod(index) &&
        (this.create || !this.getIsDateInThePast(this.localItems[index].pay_period_end))
      ) {
        return true;
      }

      return false;
    },
    getIsDateInThePast(dateValue) {
      if (!dateValue) {
        return true;
      }

      return moment().diff(moment(dateValue), 'seconds') >= 0;
    },
    getInitialPeriodStart() {
      switch (this.localPayschedulePeriodicity) {
        case PERIODICITY.WEEKLY:
          return moment().startOf('week');
        case PERIODICITY.FORTNIGHT:
          return moment().startOf('week').subtract(1, 'weeks');
        case PERIODICITY.FOUR_WEEKLY:
          return moment().startOf('week').subtract(3, 'weeks');
        default:
          return moment().startOf('month');
      }
    },
    getInitialPeriodPaidOn(start, end) {
      switch (this.localPayschedulePeriodicity) {
        case PERIODICITY.WEEKLY:
        case PERIODICITY.FORTNIGHT:
        case PERIODICITY.FOUR_WEEKLY:
          return moment(end).subtract(3, 'days');
        default:
          return moment(start).add(27, 'days');
      }
    },
    getEstimatedPeriodEnd(start) {
      if (this.localPayschedulePeriodicity === PERIODICITY.MONTHLY) {
        return moment(start).add(1, 'months').subtract(1, 'days');
      }

      return moment(start).add(PERIODICITY_VALUES[this.localPayschedulePeriodicity] - 1, 'days');
    },
    isLastPeriod(index) {
      return index === this.localItems.length - 1;
    },
    setBlackoutPeriod() {
      if (this.paySchedule && this.paySchedule.dates && this.paySchedule.dates.length > 0) {
        const firstDate = this.paySchedule.dates[0];
        if (firstDate.blackout_period_start && firstDate.paid_on) {
          this.deltaBlackoutPeriod = moment(firstDate.paid_on).diff(firstDate.blackout_period_start, 'days');
        } else {
          this.deltaBlackoutPeriod = 0;
        }
      } else {
        this.deltaBlackoutPeriod = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-schedule-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pay-schedule-table__header {
  border-bottom: solid 1px var(--palette-color-default-lighten-80);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: left;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  & > * {
    margin-bottom: 0;
    width: 13.875rem;
  }
}

.pay-schedule-table__payschedule-name {
  width: 17.5rem;
}

.pay-schedule-table__table-container {
  height: 27.5rem;
  overflow-y: scroll;
  padding-bottom: 0;
  position: relative;

  @media (min-height: 1000px) {
    height: 32.5rem;
  }

  @media (max-width: 992px) {
    height: 25rem;
  }
}

.pay-schedule-table__table-header {
  display: flex;
  justify-content: center;
}

.pay-schedule-table__table-footer {
  background-color: var(--palette-color-base-white);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: sticky;
  width: 100%;
}

:deep(.pay-period-table) {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

:deep(.pay-schedule-table__period) {
  box-shadow: inset 1px 0px 0px 0px var(--palette-color-default-lighten-80);
  cursor: pointer;

  label {
    color: var(--palette-color-default-lighten-20);
  }

  > td:first-child,
  .app-input {
    width: 10rem;
  }

  & td,
  th {
    padding-right: 0.5rem;
  }

  &.pay-schedule-table__period--invalid {
    box-shadow: inset 0 0 0 2px var(--palette-color-danger) !important;
  }

  &.pay-schedule-table__period--selected {
    box-shadow: inset 0 0 0 2px var(--palette-color-brand-primary);

    &:not(.pay-schedule-table__period--invalid):hover {
      box-shadow: inset 0 0 0 2px var(--palette-color-core-1-darken-20);
    }
  }
}

.pay-schedule-table__period-dates {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.pay-schedule-table__period-end-date {
  white-space: nowrap;
}

:deep(.pay-schedule-table__alert-wrapper) {
  box-shadow: none !important;

  td {
    padding: 0;
  }
}

.pay-schedule-table__alert {
  margin-top: -0.5rem;
}

.pay-schedule-table__calendar-column {
  margin: 0.5rem 0;

  @media (max-width: 992px) {
    display: none;
  }
}

:deep(.pay-schedule-table__calendar) {
  .b-calendar-grid-caption,
  .b-calendar-grid-weekdays {
    padding: 0.5rem 0;
  }

  .b-calendar-grid-body {
    div[role='button'] {
      pointer-events: none;
    }
  }

  button {
    &.disabled {
      visibility: hidden;
    }

    &[title='Previous year'],
    &[title='Next year'] {
      display: none;
    }
  }

  &.pay-schedule-table__calendar--hide-nav {
    .b-calendar-nav {
      display: none !important;
    }
  }
}

:deep(.pay-schedule-table__calendar-range) {
  background-color: var(--palette-color-default-lighten-95);

  th,
  td {
    background-color: inherit;
  }
}

:deep(.dropdown-toggle) {
  z-index: 0 !important;
}
</style>
