import { toCurrency } from '@/utils';
import { toFormattedDate } from '@/utils/date';

export const STREAM_FIELD_DEFINITIONS = [
  {
    key: 'employee_code',
    label: 'Employee code',
    sortable: true,
  },
  {
    key: 'full_name',
    label: 'Employee',
    sortable: true,
  },
  {
    key: 'created_at',
    label: 'Date',
    sortable: true,
    formatter: value => toFormattedDate(value),
  },
  {
    key: 'type',
    label: 'Type',
    formatter: (_value, _key, item) => (item.properties.safestream ? 'Savings Withdrawal' : 'Transfer'),
  },
  {
    key: 'net_amount',
    label: 'Amount',
    sortable: true,
    formatter: value => toCurrency(value),
  },
];

export const STREAM_FIELD_KEYS = {
  EMPLOYEE_CODE: 'employee_code',
  FULL_NAME: 'full_name',
  NET_AMOUNT: 'net_amount',
  CREATED_AT: 'created_at',
  TYPE: 'type',
};

export const STREAM_FILTER_KEYS = {
  STARTS_ON: 'starts_on',
  ENDS_ON: 'ends_on',
  SALARY_OR_HOURLY: 'salary_or_hourly',
  PAY_SCHEDULE: 'pay_schedule_id',
  DIVISION: 'division',
};

export const STREAM_API_FILTER_KEYS = [
  STREAM_FILTER_KEYS.STARTS_ON,
  STREAM_FILTER_KEYS.ENDS_ON,
  STREAM_FILTER_KEYS.PAY_SCHEDULE,
];

export const STREAM_SEARCH_FIELDS = ['created_at', 'full_name', 'employee_code'];

export const STREAM_PER_PAGE_OPTIONS = [25, 50, 100];
