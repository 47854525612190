<template>
  <div :class="['profile-container', { vertical: props.vertical }]" v-if="!loading">
    <div class="left">
      <div :class="['left-container', props.vertical && 'w-100']">
        <div class="detail-section">
          <img :src="profile?.worker_photo_url" v-if="profile?.worker_photo_url" class="profile-image" />
          <span v-if="profile?.worker_tagline">{{ profile?.worker_tagline }}</span>
          <span class="worker-name">{{ formatWorkerName(profile?.worker_name) }}</span>
          <RaiseRating :rating="profile?.internal_rating_for_worker || getInternalRating(profile)"> </RaiseRating>
          <UserRatingDisplay
            :rating="profile?.raise_rating_avg || profile?.raise_rating_avg"
            v-if="profile?.raise_rating_avg || profile?.raise_rating_avg"
          />

          <span v-if="profile?.distance_km" class="subheading location">
            <span v-if="profile?.worker_local_authority"> {{ profile?.worker_local_authority }}, </span>
            <span v-if="profile?.distance_km !== undefined"
              >{{ parseFloat(profile?.distance_km).toFixed(1) }} km away</span
            ></span
          >
        </div>
        <div class="detail-section">
          <span
            v-if="matchingRole?.days_in_role || matchingRole?.hours_in_role"
            class="light-subheading experience-text"
            >{{ getAllShiftsWorkedTimeSpan(matchingRole?.days_in_role, matchingRole?.hours_in_role) }} experience</span
          >
          <span v-if="daysInRole || hoursInRole"
            ><span class="stat-bold">{{ getAllShiftsWorkedTimeSpan(daysInRole, hoursInRole) }}</span>
            as
            {{ profile?.role }}</span
          >
          <span v-if="daysInFunction || hoursInFunction"
            ><span class="stat-bold">{{ getAllShiftsWorkedTimeSpan(daysInFunction, hoursInFunction) }}</span>
            as
            {{ profile?.function }}</span
          >
          <span>
            <span class="stat-bold">{{ totalShifts }} shift{{ totalShifts === 1 ? '' : 's' }}</span> completed</span
          >
        </div>
      </div>
      <div :class="['left-container', props.vertical && 'w-100']" v-if="profile?.worker_skills">
        <div class="stat-bold">Key skills</div>
        <div class="tag-container">
          <div class="tag" v-for="skill in profile?.worker_skills" :key="skill">
            <CheckCircleFilled :color="PaletteColors['base-white']"></CheckCircleFilled>
            {{ skill }}
          </div>
        </div>
      </div>
      <b-button class="pick-button" variant="primary" @click="emit('claim-selected')">
        <img :src="themeImage('check-circle-white.svg')" alt="Raise" />
        Pick {{ profile?.worker_name?.split(' ')?.[0] }} for this shift</b-button
      >
    </div>
    <div class="right">
      <div class="section">
        <div v-for="[roleName, experience] in profileExperience" :key="roleName">
          <ExperienceByRole
            v-if="roleName !== 'undefined'"
            :roleName="roleName"
            :experience="experience"
          ></ExperienceByRole>
        </div>
      </div>
      <div class="section info" v-if="profile?.worker_profile_questions">
        <span class="heading">Learn more about {{ formatWorkerName(profile?.worker_name) }}</span>
        <div
          v-for="(question, index) in Object.values(profile?.worker_profile_questions)"
          :key="question.question + index"
        >
          <div class="question">{{ question.question }}</div>
          <div class="answer">{{ question.answer }}</div>
        </div>
      </div>
    </div>
  </div>
  <app-loading v-else :loading="loading" />
</template>

<script setup>
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import { computed, onMounted, ref, watch } from 'vue';
import UserRatingDisplay from '@/pages/talent-pool/components/UserRatingDisplay.vue';
import { themeImage, PaletteColors } from '@/Theme';
import {
  formatWorkerName,
  getAllShiftsWorkedTimeSpan,
  getDaysInFunction,
  getDaysInRole,
  getHoursInFunction,
  getHoursInRole,
  buildProfileByRole,
  getInternalRating,
} from '@/pages/talent-pool/utils/profile';
import AppLoading from '@/components/AppLoading.vue';
import ExperienceByRole from '@/pages/talent-pool/components/WorkerProfile/ExperienceByRole.vue';
import RaiseRating from '@/pages/talent-pool/components/RaiseRating.vue';
import ApiClient from '@/ApiClient';

const props = defineProps({
  claim: Object,
  employeeId: String,
  vertical: Boolean,
});

const emit = defineEmits(['claim-selected']);

const matchingRole = ref({});
const profile = ref('');
const profileExperience = ref('');
const loading = ref(false);

const employeeId = props.claim ? props.claim.worker_id : props.employeeId;

const fetchCandidateDetails = async () => {
  loading.value = true;
  const { data } = await ApiClient.getEmployeeProfile(employeeId);
  profile.value = data;
  profileExperience.value = Object.entries(buildProfileByRole(data));

  if (props.claim) {
    matchingRole.value = props.claim.roles?.find(role => role?.is_function_match);
  }

  loading.value = false;
};

const hoursInRole = computed(() => {
  return getHoursInRole(profile.value.roles);
});

const hoursInFunction = computed(() => {
  return getHoursInFunction(profile.value.roles);
});

const daysInRole = computed(() => {
  return getDaysInRole(profile.value.roles);
});

const daysInFunction = computed(() => {
  return getDaysInFunction(profile.value.roles);
});

const totalShifts = computed(() => {
  const verified_shifts = profile.value?.wagestream_history?.reduce((acc, curr) => acc + curr?.total_shifts, 0) || 0;
  const raise_shifts = profile.value?.raise_history?.reduce((acc, curr) => acc + curr.num_shifts, 0) || 0;
  return verified_shifts + raise_shifts;
});

onMounted(() => {
  fetchCandidateDetails();
});

watch(
  () => props.claim,
  () => {
    fetchCandidateDetails();
  },
);
</script>

<style scoped lang="scss">
.profile-container {
  background-color: var(--palette-color-default-lighten-95);
  display: flex;
  justify-content: space-around;
  border-radius: 0 0 8px 8px;
  flex-grow: 1;
  gap: 1rem;
  padding: 1rem;
}

.profile-container.vertical {
  flex-direction: column;
  padding: 0;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: min-content;
  flex: 1 0 auto;
}

.left-container {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1rem;
  gap: 0.75rem;
}

.detail-section {
  display: flex;
  flex-direction: column;
}

.experience-text {
  color: var(--palette-color-default-lighten-30);
  margin-bottom: 0.25rem;
}

.vertical .left {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  min-width: unset;
}

.worker-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-top: 1rem;
}

.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pick-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.light-subheading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-30);
}

.stat-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.location {
  margin-top: 0.25rem;
}

.question {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 0.25rem;
}

.answer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 0.25rem;
  color: var(--palette-color-default-lighten-20);
}

.profile-image {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--palette-color-base-white);
  margin: 0.5rem 0;
}

.heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: var(--palette-color-default-lighten-20);
  gap: 0.25rem;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: var(--palette-color-brand-primary);
  border-radius: 99px;
  padding: 0.125rem 0.75rem 0.125rem 0.25rem;
  color: var(--palette-color-base-white);
  gap: 0.5rem;
}
</style>
