<template>
  <div
    class="sme-box"
    :class="{
      'sme-box--bordered': bordered,
      'sme-box--compact': compact,
      'sme-box--scroll': scroll,
      [`sme-box--scroll-${scrollVariant}`]: scroll,
      [`sme-box--${variant}`]: variant,
    }"
  >
    <header v-if="title" class="sme-box__header" :class="headerClass">
      <router-link v-if="to" :to="to" class="sme-box__link">
        <h4 class="sme-box__heading">{{ title }}</h4>
        <font-awesome-icon class="sme-box__link-icon" :icon="['fad', 'external-link']" />
      </router-link>
      <h4 v-else class="sme-box__heading">{{ title }}</h4>
    </header>
    <slot></slot>
  </div>
</template>

<script>
const VARIANTS = {
  WHITE: 'white',
};

const SCROLL_VARIANTS = {
  DEFAULT: 'default',
  LARGE: 'large',
};
</script>

<script setup>
defineProps({
  title: String,
  to: Object,
  bordered: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  scroll: {
    type: Boolean,
    default: false,
  },
  scrollVariant: {
    type: String,
    default: SCROLL_VARIANTS.DEFAULT,
    validator: scrollVariant => Object.values(SCROLL_VARIANTS).includes(scrollVariant),
  },
  variant: {
    type: String,
    validator: variant => Object.values(VARIANTS).includes(variant),
  },
  headerClass: String,
});
</script>

<style lang="scss" scoped>
$variants: (
  'white': var(--palette-color-base-white),
);

.sme-box {
  background-color: var(--palette-color-default-lighten-95);
  border-radius: 5px;
  padding: 1rem;

  :last-child {
    margin-bottom: 0 !important;
  }

  & + & {
    margin-top: 1rem;
  }
}

.sme-box--bordered {
  border: 1px solid var(--palette-color-default-lighten-90);
}

.sme-box--compact {
  padding: 0.5rem 1rem;
}

.sme-box--scroll {
  max-height: 7.5rem;
  overflow-y: auto;
}

.sme-box--scroll-large {
  max-height: 17.5rem;

  @media (min-width: 768px) {
    max-height: 25rem;
  }
}

@each $variant, $background-color in $variants {
  .sme-box--#{$variant} {
    background-color: $background-color;
  }
}

.sme-box__header {
  margin-bottom: 1rem;
}

.sme-box__link {
  align-items: center;
  color: inherit;
  display: inline-flex;
  flex-direction: row;
  justify-items: center;

  &:hover {
    color: var(--palette-color-brand-primary);
  }
}

.sme-box__heading {
  color: var(--palette-color-default);
  margin-bottom: 0;
}

.sme-box__link-icon {
  color: var(--palette-color-brand-primary);
  margin-left: 0.75rem;
}
</style>
