import useFeatureFlags from '@/composables/useFeatureFlags';

const {
  isDisableIntegratedEmployeeFieldsStrict,
  isEnableExpandedEmployeeStreamingFormFields,
  isEnableEmployeeForm,
  canUpdateBanking,
  allowEditingAddress,
} = useFeatureFlags();

const EXPANDED_STREAMING_FIELDS = ['max_transfers_month', 'max_transfer_percent', 'min_transfer_balance'];

export const employeeFieldPermissions = {
  props: {
    integrationsState: Object,
  },
  data() {
    return {
      optionalEmployeeFields: ['payroll', 'custom_username', 'mailing_address'],
    };
  },
  methods: {
    isExpandedStreamingFieldsVisible() {
      return (
        isEnableExpandedEmployeeStreamingFormFields.value &&
        EXPANDED_STREAMING_FIELDS.some(field => this.isFieldVisible(field, true))
      );
    },
    getFieldPermissions(fieldName) {
      return this.fieldPermissions.filter(x => x.field === fieldName)[0];
    },
    isFieldVisible(fieldName, ignoreEditable = false) {
      if (!ignoreEditable && this.editable === false) {
        return false;
      }

      // field permissions overrides defaults
      const fieldPermissions = this.getFieldPermissions(fieldName);
      if (fieldPermissions && !fieldPermissions.is_used) {
        return false;
      }

      // Show set optional employee fields
      const setOptionalEmployeeFields = this.state.company.properties.optional_employee_fields || [];
      if (this.optionalEmployeeFields.includes(fieldName)) {
        return setOptionalEmployeeFields.includes(fieldName);
      }

      // We don't need this because we wrap the fields in a v-if="!hideEarnings"
      // but leaving it here for completeness
      if (this.hideEarnings && fieldName === 'salary_properties') {
        return false;
      }

      // For address fields we just want to check the feature flag
      if (fieldName === 'address') {
        return allowEditingAddress.value;
      }

      return true;
    },
    isFieldEditable(fieldName) {
      if (this.saving) {
        return false;
      }

      // field permissions overrides defaults
      // todo let's get rid of this at some point
      const fieldPermissions = this.getFieldPermissions(fieldName);
      if (fieldPermissions) {
        return fieldPermissions.source === 'PARTNER_PORTAL';
      }

      // Current optional fields are not editable
      if (this.optionalEmployeeFields.includes(fieldName)) {
        return false;
      }

      const salaryOnActivationFields = ['salary_properties'];
      if (this.state.company.properties.salary_on_activation && salaryOnActivationFields.includes(fieldName)) {
        return true;
      }

      const blockBankingUpdatesFields = ['bank_details'];
      if (!canUpdateBanking.value && blockBankingUpdatesFields.includes(fieldName)) {
        return false;
      }

      const managersReadOnly = [
        'max_transfer_percent',
        'max_transfer_percent',
        'min_transfer_balance',
        'monthly_transfer_limit',
        'max_transfers_month',
        'salary_properties',
        'bank_details',
        'properties.flexible_work_preferences.roles',
        'properties.flexible_work_preferences.postcode',
        'properties.flexible_work_preferences.radius',
      ];
      if (this.state.claims.m && managersReadOnly.includes(fieldName)) {
        return false;
      }

      if (isEnableEmployeeForm.value) {
        return true;
      }

      const integratedReadOnly = [
        'full_name',
        'employee_code',
        'division_name',
        'division_assigned_at',
        'work_phone',
        'work_email',
        'started_on',
        'salary_properties',
      ];
      const integratedReadOnlyStrict = [...integratedReadOnly, 'bank_details'];

      const ignoredIntegrations = ['BROWSER_UPLOAD', 'B2B_UPLOAD'];

      if (this.integrationsState.integrations) {
        const employee_integrations = this.integrationsState.integrations.filter(
          integration =>
            integration.properties?.entities?.EMPLOYEES == true ||
            integration.properties?.entities?.EXPANDED_EMPLOYEES == true,
        );
        const integrationPartners = employee_integrations.map(integration => integration.partner);
        // make sure the integrations partner is not in the ignored list
        if (integrationPartners.find(partner => !ignoredIntegrations.includes(partner))) {
          // then check the field name
          if (integratedReadOnly.includes(fieldName)) {
            return false;
          } else if (isDisableIntegratedEmployeeFieldsStrict.value && integratedReadOnlyStrict.includes(fieldName)) {
            return false;
          }
        }
      }

      return true;
    },
    isCreatingEmployee() {
      // FIXME: Heuristic to detect if this form is being shown for existing employee or not
      return this.$route.params.employeeId === undefined;
    },
  },
};
