import { render, staticRenderFns } from "./SkeletonShiftCard.vue?vue&type=template&id=2463761c&scoped=true&"
import script from "./SkeletonShiftCard.vue?vue&type=script&setup=true&lang=js&"
export * from "./SkeletonShiftCard.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SkeletonShiftCard.vue?vue&type=style&index=0&id=2463761c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2463761c",
  null
  
)

export default component.exports