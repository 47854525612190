<template>
  <div class="wrapper">
    <app-loading :loading="loading" v-if="loading" />
    <div class="page-inner" v-else>
      <ImagesHeader :coverUrl="props.entity.cover_image_url" :logoUrl="props.entity.logo_url" />
      <LocationInfo v-if="entity" :entity="entity" entity-type="company" modal-id="company-modal"></LocationInfo>

      <!-- Brands and Locations -->
      <div class="section">
        <div class="inline-header">
          <h2 class="number-of-children">{{ brands?.length }} brand{{ brands?.length === 1 ? '' : 's' }}</h2>
          <b-button variant="primary" class="add-a-child" v-b-modal.add-brand>
            <div class="plus">+</div>
            Add a brand
          </b-button>
        </div>
        <div class="brands" v-if="brands?.length">
          <div v-for="(child, index) in brands" :key="child.entity_id">
            <div v-if="index !== 0" class="divider"></div>
            <BrandContainer :brand="child" @on-close="fetchEntity"> </BrandContainer>
          </div>
        </div>
        <div class="other-locations" v-if="unbrandedChildren?.length">Other locations</div>
        <div class="grid">
          <EntityCard
            v-for="childSite in unbrandedChildren"
            :key="childSite.name"
            :name="childSite.name"
            :entity-id="childSite.entity_id"
            :cover-url="childSite.cover_image_url"
            :rating="childSite.rating"
          />
        </div>
      </div>

      <TeamSection />
    </div>
    <EditCompanyModal @on-close="fetchEntity" />
    <AddDivisionModal modal-id="add-brand" :is-brand="true" :location-id="entity.location_id" @on-close="fetchEntity" />
  </div>
</template>

<script setup>
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import EntityCard from '@/pages/talent-pool/location-settings/components/EntityCard.vue';
import { computed, ref } from 'vue';
import EditCompanyModal from '@/pages/talent-pool/components/EditCompanyModal.vue';
import AddDivisionModal from '@/pages/talent-pool/components/AddDivisionModal.vue';
import AppLoading from '@/components/AppLoading.vue';
import TeamSection from '@/pages/talent-pool/location-settings/components/TeamSection.vue';

import LocationInfo from '@/pages/talent-pool/location-settings/components/LocationInfo.vue';
import BrandContainer from '@/pages/talent-pool/location-settings/components/BrandContainer.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { getEntity } from '@/queries/talent-pool/locations';

const loading = ref(false);

const queryClient = useQueryClient();

const props = defineProps({
  entityId: String,
  entity: Object,
});

const brands = computed(() =>
  props.entity?.children
    .filter(child => child.source === 'company_organizational_groups')
    .sort((a, b) => a.name.localeCompare(b.name)),
);

const unbrandedChildren = computed(() =>
  props.entity?.children.filter(child => child.source === 'divisions').sort((a, b) => a.name.localeCompare(b.name)),
);

const fetchEntity = async () => {
  await queryClient.fetchQuery(getEntity(props.entityId));
};
</script>

<style scoped>
.wrapper {
  height: 100%;
  overflow: scroll;
}

.page-inner {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--palette-color-base-white);
  gap: 2.5rem;
}

.number-of-children {
  color: var(--palette-color-default);
  font-weight: 500;
  margin: 0;
}

.add-a-child {
  display: flex;
  align-items: center;
  gap: 10px;

  .plus {
    font-size: 18px;
  }
}

.inline-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inline-header .title {
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}

.grid {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.tags {
  display: none;
  align-items: center;
  gap: 0.5rem;
  margin: 0.6rem 0;
}

.tags .tag {
  padding: 2px 1rem;
  background-color: var(--palette-color-default-lighten-95);
  border-radius: 99px;
  font-size: 13px;
  color: var(--palette-color-default-lighten-20);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media (max-width: 900px) {
  .split-view {
    display: flex;
    flex-direction: column;
  }
}

.other-locations {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;

  color: var(--palette-color-default-lighten-20);
  margin: 1rem 0 0.5rem 0;
}

.divider {
  width: 100%;
  margin: 2rem 0;
  background-color: var(--palette-color-default-lighten-90);
  height: 1px;
}
</style>
