var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AppPage,{attrs:{"title":"Bank Pay Analytics","icon":"calendar-days","previous":[{ label: 'Analytics', to: { name: 'analytics-homepage' } }]},scopedSlots:_vm._u([{key:"custom-content",fn:function(){return [_c('b-col',[_c(_setup.AnalyticsNav),(_setup.loading)?_c(_setup.AppLoading):_vm._e(),(!_setup.loading)?[(_setup.unfinalisedShiftsNextWeek !== 0)?_c(_setup.SmeAlert,{staticClass:"mb-4",attrs:{"level":"danger"}},[_vm._v(" There are "+_vm._s(_setup.unfinalisedShiftsNextWeek)+" shifts that need to be finalised in order for employees to be paid in the next Bank Pay cycle. Go to Allocate HealthRoster to sign them off. ")]):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"md":"12"}},[_c(_setup.SmeCard,{attrs:{"title":`Bank Pay Funnel for the week ${_setup.moment(_setup.timeSeries.calculated.last_complete * 1000).format(
                'MMMM Do, YYYY',
              )}`}},[_c('p',[_vm._v(" The number of employees who are paid Bank Pay payments may not correlate to the number of Employees who have worked Bank shifts. This may be due to the shifts not being finalised, or employees opting out of receiving Bank Pay payments. Employees may have also streamed their wages before Bank Pay pay day. ")]),_c(_setup.BarChart,{key:_setup.employeesFunnel.id,attrs:{"series-x":_setup.EmployeeFunnelLabels,"series-y":_setup.employeesFunnel,"chart-dataset-options":{ borderWidth: 2, borderSkipped: false, maxBarThickness: 125 },"chart-options":{
                  aspectRatio: _setup.getInitialAspectRatio(2.5, 1.5),
                  scales: { x: { grid: { display: 0 } } },
                }}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c(_setup.SmeCard,{staticClass:"h-100",attrs:{"title":"Employees receiving Bank Pay payments"}},[_c('p',[_vm._v(" These are the number of employees who have worked Bank shifts that have been finalised and have been paid using Bank Pay. ")]),_c(_setup.LineChart,{key:_setup.employeesOverTime.id,staticClass:"mt-auto",attrs:{"labels":_setup.timeSeries,"datasets":_setup.employeesOverTime,"chart-options":{
                  scales: { y: { title: { text: _setup.employeesOverTime.name } } },
                  plugins: { title: { text: _setup.employeesOverTime.description } },
                },"fill-gradient":""}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c(_setup.SmeCard,{staticClass:"h-100",attrs:{"title":"Bank shifts worked by enrolled employees"}},[_c('p',[_vm._v(" These are the number of employees who have worked Bank shifts that have been finalised and have been paid using Bank Pay. ")]),_c(_setup.LineChart,{key:_setup.withShiftsOverTime.id,staticClass:"mt-auto",attrs:{"labels":_setup.timeSeries,"datasets":_setup.withShiftsOverTime,"chart-options":{
                  scales: { y: { title: { text: _setup.withShiftsOverTime.name } } },
                  plugins: { title: { text: _setup.withShiftsOverTime.description } },
                },"fill-gradient":""}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c(_setup.SmeCard,{staticClass:"h-100",attrs:{"title":"Opted out employees with Bank shifts"}},[_c('p',[_vm._v(" These are the number of employees who have worked Bank shifts that have been finalised, but who have chosen to opt out of the Bank Pay feature. Therefore they have not been paid using Bank Pay. ")]),_c(_setup.LineChart,{key:_setup.optOutsOverTime.id,staticClass:"mt-auto",attrs:{"labels":_setup.timeSeries,"datasets":_setup.optOutsOverTime,"chart-options":{
                  scales: { y: { title: { text: _setup.optOutsOverTime.name } } },
                  plugins: { title: { text: _setup.optOutsOverTime.description } },
                },"fill-gradient":""}})],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c(_setup.SmeCard,{staticClass:"h-100",attrs:{"title":"Finalised Bank shifts"}},[_c('p',[_vm._v(" Shifts must be finalised before we can pay Bank shifts. This chart shows the percentage of finalised Bank shifts against all Bank shifts. ")]),_c(_setup.LineChart,{key:_setup.finalisedOverTime.id,staticClass:"mt-auto",attrs:{"labels":_setup.timeSeries,"datasets":_setup.finalisedOverTime,"chart-options":{
                  scales: { y: { title: { text: _setup.finalisedOverTime.name } } },
                  plugins: { title: { text: _setup.finalisedOverTime.description } },
                },"fill-gradient":""}})],1)],1)],1)]:_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }