<template>
  <span class="flexible-work-shift-status" :class="`flexible-work-shift-status--${status.toLowerCase()}`">
    <font-awesome-icon :icon="[light ? 'fal' : 'far', statusIcon]" class="flexible-work-shift-status__icon mr-1" />
    {{ statusText }}
  </span>
</template>

<script setup>
import { computed } from 'vue';
import { getAssignedClaim } from '@/utils/flexible-work/Claim';
import { SHIFT_STATUS, SHIFT_STATUS_ICON_MAPPINGS, getShiftStatus } from '@/utils/flexible-work/Shift';

const SHIFT_STATUS_TEXT = {
  [SHIFT_STATUS.UNFILLED]: 'Unfilled',
  [SHIFT_STATUS.UNCLAIMED]: '0 applied',
  [SHIFT_STATUS.PENDING]: () => `${props.claims.length} applied`,
  [SHIFT_STATUS.APPROVED]: () => assignedToUserName.value,
  [SHIFT_STATUS.WORKED]: () => assignedToUserName.value,
  [SHIFT_STATUS.FULFILLED]: () => assignedToUserName.value,
};

const props = defineProps({
  shift: {
    type: Object,
    required: true,
  },
  claims: {
    type: Array,
    default: () => [],
  },
  light: Boolean,
});

const status = computed(() => getShiftStatus(props.shift, props.claims));
const statusIcon = computed(() => SHIFT_STATUS_ICON_MAPPINGS[status.value]);
const statusText = computed(() =>
  typeof SHIFT_STATUS_TEXT[status.value] === 'function'
    ? SHIFT_STATUS_TEXT[status.value]()
    : SHIFT_STATUS_TEXT[status.value],
);
const assignedToUserName = computed(() => getAssignedClaim(props.claims)?.user_name);
</script>

<style lang="scss" scoped>
.flexible-work-shift-status {
  &--approved,
  &--worked {
    color: var(--palette-color-success);
  }

  &--fulfilled {
    color: var(--palette-color-warning);
  }

  &--pending {
    color: var(--palette-color-brand-primary);
  }

  &--unfilled {
    opacity: 0.5;
  }
}
</style>
