var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-page',{attrs:{"title":`Disable ${_vm.employee ? _vm.employee.full_name : 'Employee'}`,"error":_vm.error,"previous":[
    { label: 'Employees', to: { name: 'employees' } },
    {
      label: _vm.employee ? _vm.employee.full_name : 'Employee',
      to: { name: 'employee', params: { employeeId: _vm.$route.params.employeeId } },
    },
  ],"icon":"user-minus"}},[_c('app-loading',{attrs:{"loading":_vm.loading}}),(!_vm.loading && !_vm.saved)?[_c('sme-alert',{staticClass:"mb-3",attrs:{"level":"warning"}},[_vm._v(" You are about to remove access to the app for "+_vm._s(_vm.employee ? _vm.employee.full_name : 'Employee')+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return v.handleSubmit(_vm.onSubmit)}}},[_c('app-input',{attrs:{"type":"text","name":"reason","rules":"required","label":"Reason","disabled":_vm.saving || _vm.saved,"validate-immediate":false},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('page-sub-footer',[_c('b-button',{attrs:{"type":"submit","variant":"danger","disabled":v.invalid || _vm.saving || _vm.saved}},[(_vm.saving)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.saving ? 'Disabling...' : 'Disable')+" ")],1)],1)],1)]}}],null,false,2406730918)})]:_vm._e(),(_vm.saved && !_vm.optedOut)?_c('sme-alert',{attrs:{"level":"good"}},[_vm._v(" When the employee has zero outstanding streamed wages you will be required to update their banking details to complete the opt-out process. ")]):_vm._e(),(_vm.saved && _vm.optedOut)?_c('sme-alert',{attrs:{"level":"good"}},[_vm._v(" Employee has been deactivated and no longer has access to the app. ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }