<template>
  <article
    class="flexible-work-shift__claim"
    :class="{
      [`flexible-work-shift__claim--${claim.status.toLowerCase()}`]: true,
      'flexible-work-shift__claim--alternative': alternative,
      'flexible-work-shift__claim--standalone': standalone,
    }"
  >
    <FlexibleWorkRateEmployeeModal
      v-if="employeeBeingRated"
      class="modal"
      :employeeBeingRated="employeeBeingRated"
      @rating="rateEmployee"
      @reset="toggleFlexibleWorkRateEmployeeModal"
    />
    <FlexibleWorkOnboardEmployeeModal
      v-if="employeeBeingOnboarded"
      class="modal"
      :employeeBeingOnboarded="employeeBeingOnboarded"
      @onboarding="onboardEmployee"
      @reset="toggleFlexibleWorkOnboardEmployeeModal"
    />
    <font-awesome-icon v-if="isAssigned" :icon="['fas', 'circle-check']" class="flexible-work-shift__claim-icon" />
    <div>
      <h4 class="flexible-work-shift__title m-0">
        {{ claim.user_settings?.full_name }}
      </h4>
      <p
        v-if="!hasOnboardedAtShiftLocation && claim.status === CLAIM_STATUS.APPROVED"
        class="flexible-work-shift__checklist-warning mb-0 mt-2"
      >
        This user has not yet completed their onboarding checklist at this location.
      </p>
      <p class="flexible-work-shift__text m-0 mt-2">
        Total cost <strong>{{ toCurrency(claimValue) }}</strong> at <strong>{{ toCurrency(claimRate) }}</strong> per
        hour<span v-if="boostValue > 0">
          with an additional boost of <strong>{{ toCurrency(boostValue) }}</strong></span
        >.
      </p>
      <div class="flexible-work-shift__claim-rating-container">
        <b-form-rating
          class="flexible-work-shift__claim-rating p-0"
          :class="{
            [`flexible-work-shift__claim--${claim.status.toLowerCase()}`]: true,
            'flexible-work-shift__claim--alternative': alternative,
            'flexible-work-shift__claim--standalone': standalone,
          }"
          :value="averageUserRating"
          readonly
          show-value
          precision="2"
        />
        <span>({{ totalUserRatings }})</span>
      </div>
      <p class="flexible-work-shift__text mb-2">
        Applied on {{ toFormattedDate(claim.created_at, 'YYYY-MM-DD') }} at
        {{ toFormattedDate(createdAt, 'HH:mm') }}
      </p>
    </div>
    <aside v-if="canAction" class="flexible-work-shift__aside">
      <div class="flexible-work-shift__buttons flexible-work-shift__buttons-container">
        <b-button
          v-if="claim.status === CLAIM_STATUS.WORKED && canUserFulfillShift"
          variant="primary"
          @click="fullfillShiftAndRateEmployee"
        >
          <font-awesome-icon v-if="!fulfilling" :icon="['far', 'circle-check']" />
          <b-spinner v-if="fulfilling" class="mr-2" small />
          <span class="d-none d-md-inline ml-2">
            {{ fulfilling ? 'Signing Off...' : 'Sign Off' }}
          </span>
        </b-button>
        <b-button
          v-if="!hasOnboardedAtShiftLocation && claim.status === CLAIM_STATUS.APPROVED"
          variant="primary"
          @click="() => toggleFlexibleWorkOnboardEmployeeModal(props.claim)"
        >
          <font-awesome-icon :icon="['far', 'circle-xmark']" />
          <span class="d-none d-md-inline ml-2">Onboard</span>
        </b-button>
        <b-button
          v-if="claim.status === CLAIM_STATUS.APPROVED"
          v-b-modal="`confirm-unapprove-modal-${claim.claim_id}`"
          variant="outline-danger"
          @click="unapprovingClaim = claim"
        >
          <font-awesome-icon :icon="['far', 'circle-xmark']" />
          <span class="d-none d-md-inline ml-2">Unapprove</span>
        </b-button>
        <b-button
          v-else-if="claim.status === CLAIM_STATUS.DENIED || claim.status === CLAIM_STATUS.PENDING"
          :disabled="approving"
          :variant="alternative ? 'light' : 'primary'"
          class="ml-auto"
          @click="approveClaim(claim)"
        >
          <font-awesome-icon v-if="!approving" :icon="['far', 'circle-check']" />
          <b-spinner v-if="approving" class="mr-2" small />
          <span class="d-none d-md-inline ml-2">
            {{ approving ? 'Approving...' : alternative ? 'Approve instead' : 'Approve' }}
          </span>
        </b-button>
      </div>
    </aside>

    <b-modal
      :id="`confirm-unapprove-modal-${claim.claim_id}`"
      ok-title="Delete"
      ok-variant="danger"
      centered
      hide-header
      @cancel="resetUnapproveClaim"
      @ok="unapproveClaim"
    >
      Are you sure you want to unapprove this shift for {{ unapprovingClaim?.user_settings?.full_name }}? Doing so will
      move the shift (and claims) back into pending. All applicants will be notified.
      <template #modal-footer="{ ok, cancel }">
        <footer class="flexible-work-shift__buttons">
          <b-button variant="secondary" @click="cancel">Cancel</b-button>
          <b-button variant="danger" @click="ok">
            <b-spinner v-if="unapproving" class="mr-2" small />
            {{ unapproving ? 'Unapproving...' : 'Unapprove' }}
          </b-button>
        </footer>
      </template>
    </b-modal>
  </article>
</template>

<script setup>
import moment from 'moment';
import { computed, ref } from 'vue';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import useFeatureFlags from '@/composables/useFeatureFlags';
import useToast from '@/composables/useToast';
import FlexibleWorkOnboardEmployeeModal from '@/pages/flexible-work/components/FlexibleWorkOnboardEmployeeModal.vue';
import FlexibleWorkRateEmployeeModal from '@/pages/flexible-work/components/FlexibleWorkRateEmployeeModal.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';
import { toFormattedDate } from '@/utils/date';
import { CLAIM_STATUS, isAssignedClaim } from '@/utils/flexible-work/Claim';

const { TOAST_VARIANT, doToast } = useToast();
const { isEnableFlexibleWorkManagerShiftSignOff } = useFeatureFlags();

const props = defineProps({
  shift: {
    type: Object,
    required: true,
  },
  claim: {
    type: Object,
    required: true,
  },
  canAction: {
    type: Boolean,
    required: true,
  },
  alternative: Boolean,
  standalone: Boolean,
});

const emit = defineEmits(['updated']);

const approving = ref(false);
const unapproving = ref(false);
const unapprovingClaim = ref(undefined);
const fulfilling = ref(false);
const employeeBeingRated = ref(null);
const employeeBeingOnboarded = ref(null);

const claimRate = computed(
  () => (!props.shift.properties.rate_from_employee && props.shift.rate) || props.claim?.user_settings?.rate || 0,
);
const claimValue = computed(
  () =>
    ((!props.shift.properties.rate_from_employee && props.shift.wages) ||
      (props.claim?.user_settings?.rate || 0) * props.shift.hours) + (props.shift.properties?.boost || 0),
);
const boostValue = computed(() => props.shift.properties?.boost || 0);
const createdAt = computed(() => moment(props.claim.created_at).utc(true).local());
const isAssigned = computed(() => isAssignedClaim(props.claim));
const hasOnboardedAtShiftLocation = computed(() =>
  (props.claim?.user_settings?.onboarding_checklist_completed_at || []).includes(props.shift?.location_id),
);
const canUserFulfillShift = computed(
  () => State.state.claims?.a || (State.state.claims?.m && isEnableFlexibleWorkManagerShiftSignOff.value),
);
const totalUserRatings = computed(() => props.claim?.user_settings?.ratings?.length || 0);
const averageUserRating = computed(
  () =>
    props.claim?.user_settings?.ratings?.reduce((acc, rating) => acc + rating.rating, 0) / totalUserRatings.value || 0,
);

const approveClaim = async claim => {
  approving.value = true;

  try {
    await ApiClient.postFlexibleWorkClaimApprove(claim);
    doToast('Approved Shift', `Approved shift for ${claim.user_settings?.full_name}`);
    tracker.trackEvent('flexible_work_shift_claim_approved');
    emit('updated');
  } catch {
    doToast('Error Approving Shift', 'Please contact support if this error persists.', TOAST_VARIANT.DANGER);
  } finally {
    approving.value = false;
  }
};

const unapproveClaim = async () => {
  unapproving.value = true;

  try {
    await ApiClient.deleteFlexibleWorkClaimApprove(unapprovingClaim.value);
    doToast('Unapproved Shift', `Unapproved shift for ${unapprovingClaim.value.user_settings?.full_name}`);
    tracker.trackEvent('flexible_work_shift_claim_unapproved');
    emit('updated');
  } catch {
    doToast('Error Unapproving Shift', 'Please contact support if this error persists.', TOAST_VARIANT.DANGER);
  } finally {
    resetUnapproveClaim();
  }
};

const resetUnapproveClaim = () => {
  unapprovingClaim.value = undefined;
  unapproving.value = false;
};

const onboardEmployee = async submit => {
  employeeBeingOnboarded.value = null;
  if (submit) {
    const payload = {
      employee_id: props.claim.employee_id,
      company_id: props.shift.company_id,
      preferences: {
        ...props.claim.user_settings,
        onboarding_checklist_completed_at: [
          ...(props.claim.user_settings?.onboarding_checklist_completed_at || []),
          props.shift.location_id,
        ],
      },
    };
    await ApiClient.putFlexibleWorkUserSettings(props.claim.employee_id, payload);
    emit('updated');
  }
};

const toggleFlexibleWorkOnboardEmployeeModal = employeeData => {
  employeeBeingOnboarded.value = employeeData ? employeeData : null;
};

const fullfillShiftAndRateEmployee = async () => {
  fulfilling.value = true;

  try {
    await ApiClient.postFlexibleWorkClaimFulfill(props.claim);
    toggleFlexibleWorkRateEmployeeModal(props.claim);
    tracker.trackEvent('flexible_work_shift_claim_fulfilled');
  } catch {
    doToast('Error Signing Off Shift', 'Please contact support if this error persists.', TOAST_VARIANT.DANGER);
  }
};

const rateEmployee = async rating => {
  if (rating) {
    const payload = {
      rating,
      shift_id: employeeBeingRated.value.shift_id,
    };
    await ApiClient.postRateEmployee(employeeBeingRated.value.employee_id, payload);
  }
  employeeBeingRated.value = null;
  fulfilling.value = false;
  doToast('Shift Signed Off', `Shift signed off for ${props.claim.user_settings?.full_name}`);
  emit('updated');
};

const toggleFlexibleWorkRateEmployeeModal = employeeData => {
  if (employeeData) {
    employeeBeingRated.value = employeeData;
  } else {
    employeeBeingRated.value = null;
  }
};
</script>

<style lang="scss" scoped>
.flexible-work-shift__claim {
  align-items: center;
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
  border-top: 1px solid var(--palette-color-default-lighten-90);
  display: flex;
  gap: 1rem;
  margin: 0 1rem;
  padding: 1rem 0 1rem 2rem;

  &:last-child {
    border-bottom: 0;
  }

  & + & {
    border-top: 0;
  }
}

.flexible-work-shift__claim--alternative {
  padding: 1rem;
}

.flexible-work-shift__claim--standalone {
  padding-left: 0;
}

.flexible-work-shift__claim--approved,
.flexible-work-shift__claim--fulfilled,
.flexible-work-shift__claim--worked {
  background-color: var(--palette-color-success-lighten-90);
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  .flexible-work-shift__title {
    color: var(--palette-color-success);
  }

  .flexible-work-shift__checklist-warning {
    color: var(--palette-color-warning);
  }
}

.flexible-work-shift__claim-icon {
  color: var(--palette-color-success);
  height: 1.25rem;
  margin: 0 -0.125rem;
  width: 1.25rem;
}

.flexible-work-shift__buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.flexible-work-shift__claim-rating-container {
  display: flex;
  align-items: center;
  align-content: center;
  width: 12rem;
}

.flexible-work-shift__claim-rating {
  border: none;
}

:deep(.b-rating-star) {
  padding: 0 !important;
  justify-content: start !important;
}
</style>
