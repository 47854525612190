<template>
  <div class="sme-card" :class="[large && 'sme-card--large']">
    <header v-if="title" class="sme-card__header">
      <router-link v-if="to" :to="to" class="sme-card__link">
        <component :is="titleTag" class="sme-card__title">
          {{ title }}
          <slot v-if="$slots.badge" name="badge"></slot>
        </component>
        <font-awesome-icon class="sme-card__link-icon" :icon="['fad', 'external-link']" size="lg" />
      </router-link>
      <component v-else :is="titleTag" class="sme-card__title">
        {{ title }}
        <slot v-if="$slots.badge" name="badge"></slot>
      </component>
    </header>

    <slot></slot>

    <template v-if="$slots.footer">
      <footer class="sme-card__footer">
        <slot name="footer"></slot>
      </footer>
    </template>
  </div>
</template>

<script>
const TITLE_TAGS = ['h1', 'h2', 'h3', 'h4', 'p'];

export default {};
</script>

<script setup>
defineProps({
  title: String,
  titleTag: {
    type: String,
    default: 'h2',
    validator: value => TITLE_TAGS.includes(value),
  },
  to: Object,
  large: Boolean,
});
</script>

<style lang="scss" scoped>
@use 'sass:color';

.sme-card {
  align-self: baseline;
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  box-shadow: var(--depth-2);
  color: var(--palette-color-default-lighten-20);
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0;
  padding: 1rem;
  width: 100%;

  & + & {
    margin-top: 1.5rem;
  }
}

.sme-card--large {
  padding: 1.5rem;
}

.sme-card__header {
  margin-bottom: 1.25rem;

  .sme-card__title {
    margin: 0;
  }
}

.sme-card__link {
  align-items: center;
  color: inherit;
  display: inline-flex;
  flex-direction: row;
  justify-items: center;

  &:hover {
    color: var(--palette-color-brand-primary);
  }
}

.sme-card__title {
  color: var(--palette-color-default);
}

.sme-card__link-icon {
  color: var(--palette-color-brand-primary);
  margin-left: 1rem;
}

.sme-card__footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  > * {
    margin: 1rem 1rem 0 0;
  }
}
</style>
