import { render, staticRenderFns } from "./EmployeeAvatar.vue?vue&type=template&id=1636a4a2&scoped=true&"
import script from "./EmployeeAvatar.vue?vue&type=script&setup=true&lang=js&"
export * from "./EmployeeAvatar.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./EmployeeAvatar.vue?vue&type=style&index=0&id=1636a4a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1636a4a2",
  null
  
)

export default component.exports