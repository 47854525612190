<template>
  <div class="search-dropdown">
    <AppInput
      v-bind="$attrs"
      :value="value"
      @input="emit('input', $event)"
      class="textbox"
      :name="props.label || 'dropdown-input'"
      :label="label"
      :rules="props.rules"
    />
    <b-collapse id="options" :class="`options ${options.length && visible && 'options--focus'}`" :visible="visible">
      <div class="options__container">
        <div
          v-for="(option, index) in options"
          :key="`${option}${index}`"
          class="options__option"
          @click="() => handleSelectOption(option)"
          role="option"
        >
          {{ option }}
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { ref, computed, watch } from 'vue';
import AppInput from '@/components/AppInput';

const emit = defineEmits(['click', 'input']);
const visible = ref(true);

const props = defineProps(['value', 'label', 'options', 'rules']);

const options = computed(() => {
  return props.options;
});

watch(props, () => {
  visible.value = !props.options.includes(props.value);
});

const handleSelectOption = selectedOption => {
  emit('input', selectedOption);
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
}

.search-dropdown {
  position: relative;
}

.textbox {
  margin-bottom: 0;
}

.options {
  position: absolute;
  width: 100%;
  z-index: 1000;
  border-radius: 5px;
}

.options__container {
  max-height: 15rem;
  overflow-y: auto;
}

.options__option {
  padding: 0.25rem 0;
  padding-left: 0.875rem;
  background-color: var(--palette-color-default-lighten-95);
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: var(--palette-color-default-lighten-80);
  }
}
</style>
