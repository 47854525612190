<template>
  <div class="kyc-page">
    <div v-if="!isRedirectFromStripe" class="kyc-page-content">
      <div class="wagestream-logo"></div>
      <h1>Verify your identity</h1>
      <h2>Check your details below</h2>
      <p class="subtitle">You must complete identity verification as part of your company's onboarding process.</p>
      <sme-alert v-if="status == 'SUCCESS'" level="info" class="my-3">Verification Complete</sme-alert>
      <sme-alert v-if="status == 'REVIEW'" level="info" class="my-3">
        <p>
          <strong>Verification in review</strong>
        </p>
        <p>This page will update when the status of your verification changes.</p>
      </sme-alert>
      <div class="person-box">
        <div class="person-name">{{ personName }}</div>
        <div class="person-address">
          <h3>Correspondence address</h3>
          <p>
            {{ personAddress }}
          </p>
        </div>
        <div class="person-secondary">
          <div class="person-dob">
            <h3>Date of birth</h3>
            <p>
              {{ personDOB }}
            </p>
          </div>
          <div class="person-country" v-if="personCountry">
            <h3>Country of residence</h3>
            <p>
              {{ personCountry }}
            </p>
          </div>
        </div>
      </div>
      <div class="failure" v-if="status == 'FAILURE'">
        <h3>We were unable to verify your documents</h3>
        <p>The following are common reasons why your identity documents might have been rejected:</p>
        <ul>
          <li>Either your document or selfie was blurry or in low lighting</li>
          <li>Either your document or selfie were obscured</li>
          <li>Either your document or selfie were in black and white</li>
          <li>Your document has expired</li>
          <li>You used a photocopy of your document</li>
        </ul>
        <p>If you think any of the above reasons apply, please try again.</p>
      </div>
      <div class="problem">
        <div>
          <h3>This isn't you?</h3>
          <p>If you've received this link by mistake or anything else looks wrong then let us know.</p>
        </div>
      </div>
      <sme-alert v-if="error" level="danger" class="my-3">
        <p>
          <strong>{{ error }}</strong>
        </p>
        <p>If this error persists please contact support</p>
      </sme-alert>
      <div class="buttons">
        <button v-if="isDashboard" class="btn btn-outline-secondary" @click="handleBack">Back</button>
        <span class="stripe">Powered by <strong>stripe</strong></span>
        <button
          v-if="status != 'SUCCESS' && status != 'REVIEW' && status != 'FAILURE'"
          class="btn-start-verification btn"
          @click="handleVerification"
        >
          Verify your identity
        </button>
        <button v-if="status == 'FAILURE'" class="btn-start-verification btn" @click="handleVerification">
          Retry verification
        </button>
      </div>
    </div>
    <div v-else>
      <div class="complete">
        <div class="wagestream-logo"></div>
        <h2>Verification in progress</h2>
        <p class="subtitle" v-if="status == 'SUCCESS'">Your Stripe Identity Verification has been completed.</p>
        <p class="subtitle" v-if="status == 'SUCCESS'">You may close this window.</p>
        <p class="subtitle" v-if="status == 'NEW'">Your Stripe Identity Verification is in progress.</p>
        <p class="subtitle" v-if="status == 'REVIEW'">Your Stripe Identity Verification is in review.</p>
        <p class="subtitle" v-if="!status">Your Stripe Identity Verification is in progress.</p>
        <button v-if="isDashboard" class="btn btn-outline-secondary d-block mt-n4 mx-auto" @click="handleBack">
          Back to Dashboard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/ApiClient';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'Kyc',
  components: {
    SmeAlert,
  },
  data() {
    return {
      person: undefined,
      state: State.state,
      error: undefined,
      companyName: undefined,
      isRedirectFromStripe: false,
      isDashboard: false,
      status: undefined,
    };
  },
  async mounted() {
    this.isDashboard = this.$route.query.dashboard;
    this.isRedirectFromStripe = this.$route.query.redirectFromStripe;
    if (!this.isRedirectFromStripe) {
      this.person = await ApiClient.getKYCStatus(this.$route.query.id);
      this.companyName = this.person.company_name;
      this.status = this.person.status;
    } else {
      this.checkStatus();
    }
  },
  computed: {
    personName() {
      if (this.person) {
        return this.person.first_name + ' ' + this.person.middle_name + ' ' + this.person.last_name;
      } else {
        return '';
      }
    },
    personAddress() {
      if (this.person) {
        const a = this.person.address;
        return [a.premises, a.address_line_1, a.locality, a.postal_code, a.country].filter(x => x).join(', ');
      } else {
        return '';
      }
    },
    personCountry() {
      if (this.person) {
        return this.person.country_of_residence;
      } else {
        return '';
      }
    },
    personDOB() {
      if (this.person) {
        const dob = new Date(this.person.date_of_birth.year, this.person.date_of_birth.month - 1, 1);
        return dob.toLocaleString('default', { month: 'long' }) + ' ' + this.person.date_of_birth.year;
      } else {
        return '';
      }
    },
  },
  methods: {
    async handleVerification() {
      try {
        const returnUrl = window.location.href + '&redirectFromStripe=true';
        const payload = {
          company_id: this.person.company_id,
          return_url: returnUrl,
        };
        const { redirect_url } = await ApiClient.smeCompanyVerifyRelevantPersonConnection(
          this.$route.query.id,
          payload,
        );
        window.location.assign(redirect_url);
      } catch (error) {
        this.error = error.message || error.toString();
        this.loading = false;
      }
    },
    async handleBack() {
      this.$router.push({ name: 'sme-verify-ownership' });
    },
    checkStatus() {
      this.isRedirectFromStripe = this.$route.query.redirectFromStripe;
      if (this.isRedirectFromStripe) {
        const userId = this.$route.query.id;
        const instance = this;
        const checkingStatus = async function () {
          instance.person = await ApiClient.getKYCStatus(userId);
          instance.status = instance.person.status;
          if (instance.person.status != 'NEW') {
            clearInterval(checking);
          }
          if (instance.person.status == 'FAILURE') {
            instance.$router.push({ name: 'sme-kyc', query: { id: userId } });
          }
        };
        let checking = setInterval(checkingStatus, 30000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.kyc-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--palette-color-base-background);
  width: 100%;
  min-height: 100vh;
}

.kyc-page-content {
  width: 590px;
  padding: 40px 10px;
  margin: 0 auto;
  flex-grow: 2;
}

@media (max-width: 600px) {
  .kyc-page-content {
    width: 95vw;
  }
}

.wagestream-logo {
  width: 80px;
  height: 51px;
  background-image: url('~@/assets/ws-symbol-purple.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 70px auto 50px;
}

@media (max-width: 768px) {
  .wagestream-logo {
    margin: 20px auto 10px;
  }
}

h1 {
  font-size: 16px;
  color: var(--palette-color-default-lighten-20);
  margin: 0 auto;
  text-align: center;
}

.subtitle {
  margin: -20px auto 60px auto;
  max-width: 30rem;
  color: var(--palette-color-default-lighten-20);
  text-align: center;
}

h2 {
  font-size: 24px;
  color: var(--palette-color-default-lighten-20);
  margin: 10px auto 30px;
  font-weight: bold;
  text-align: center;
}

.person-box {
  min-height: 250px;
  padding: 25px 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: var(--palette-color-base-background);
  border: 1px solid var(--palette-color-core-5);
  color: var(--palette-color-default-lighten-20);
  margin-bottom: 40px;
  border-radius: 8px;
}

.person-box h3 {
  font-weight: bold;
}

.person-name {
  font-size: 20px;
  font-weight: bold;
  display: block;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--palette-color-core-5);
}

.person-address {
  padding: 15px 0;
  border-bottom: 1px solid var(--palette-color-core-5);
  font-size: 16px;
}

.person-secondary {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  margin: 40px 0 100px 0;
  flex-direction: row;
  align-items: center;
}

.buttons .btn {
  margin-top: 0;
}

.buttons .stripe {
  opacity: 0.5;
  margin-left: auto;
  color: var(--palette-color-default-lighten-20);
}

@media (max-width: 768px) {
  .buttons .stripe {
    display: none;
  }
}

.btn-start-verification {
  margin-left: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: var(--palette-color-core-5);
  padding: 8px 16px;
  font-size: 16px;
  color: var(--palette-color-base-white);
}

@media (max-width: 600px) {
  .btn-start-verification {
    width: 100%;
  }
}

.problem {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: var(--palette-color-base-white);
  padding: 30px 20px 15px;
  font-size: 16px;
  margin: 0;
  width: 588px;
  display: flex;
  color: var(--palette-color-default-lighten-20);
  justify-content: space-between;
  border-radius: 8px;
}

.problem h3 {
  font-weight: bold;
  color: var(--palette-color-default-lighten-20);
}

.failure {
  color: var(--palette-color-danger);
  background-color: var(--palette-color-danger-lighten-90);
  border: 1px solid var(--palette-color-danger-lighten-90);
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 20px 20px 5px 20px;
  margin-bottom: 40px;
}

.complete .wagestream-logo {
  margin-top: 0;
}

.error-box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
}

.error-box p {
  margin-bottom: 8px;
}

.error-box p:last-child {
  margin-bottom: 0;
}

.btn-report-problem {
  font-size: 16px;
  color: var(--palette-color-danger);
  border: 1px solid var(--palette-color-danger);
  margin: 30px 0 0 20px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 160px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .problem {
    display: block;
    width: 100%;
    margin: 0 auto 50px;
  }
  .problem .btn-report-problem {
    width: 100%;
    margin: 30px 0;
  }
  .buttons {
    margin-bottom: 32px;
  }
}
</style>
