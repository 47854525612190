<template>
  <div :class="['user-rating', { xsmall: !!props.extraSmall }]">
    <div v-if="!!props.small || !!props.extraSmall" class="single-star">
      <StarFilled width="14px" height="14px" :color="props.color || PaletteColors['warning']" />
    </div>
    <div v-else>
      <div v-if="props.explainer && !props.extraSmall" class="explainer">{{ explainerText }}</div>
      <div class="rating-container">
        <div class="unfilled-stars">
          <Star
            v-for="num in [1, 2, 3, 4, 5]"
            :key="num"
            width="14px"
            height="14px"
            :color="PaletteColors['default-lighten-80']"
          />
        </div>
        <div class="filled-stars" :style="{ width: `${filledWidth}%` }">
          <StarFilled
            v-for="num in [1, 2, 3, 4, 5]"
            :key="num"
            :color="props.color || PaletteColors['warning']"
            width="14px"
            height="14px"
          ></StarFilled>
        </div>
      </div>
    </div>
    <div v-if="!props.explainer" class="explainer">{{ displayRating }}</div>
  </div>
</template>

<script setup>
import StarFilled from '@/assets/icons/StarFilled.vue';
import Star from '@/assets/icons/Star.vue';
import { PaletteColors } from '@/Theme';
import { computed } from 'vue';

const props = defineProps({
  rating: { type: [String, Number] },
  small: { type: Boolean, required: false },
  extraSmall: { type: Boolean, required: false },
  color: { type: String, required: false },
  explainer: { type: String, required: false },
});

const filledWidth = computed(() => {
  if (!props.rating) {
    return 0;
  }
  return (props.rating / 5) * 100;
});

const displayRating = computed(() => (props.rating ? parseFloat(props.rating).toFixed(1) : 0));

const explainerText = computed(() => (props.explainer ? props.explainer : displayRating.value));
</script>

<style scoped lang="scss">
.user-rating {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.single-star {
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating-container {
  display: flex;
  position: relative;
  margin-right: 0.25rem;
  width: fit-content;
}

.filled-stars {
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.unfilled-stars {
  position: relative;
}

.rating-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--palette-color-default);
}

.xsmall .rating-text {
  font-size: 12px;
}

.xsmall .star-icon {
  width: 14px;
  height: 14px;
}

.explainer {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
}
</style>
