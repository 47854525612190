<template>
  <!-- eslint-disable vue/no-v-html vue/no-static-inline-styles -->
  <div :class="['page', { open: sidebarOpen }]">
    <aside :class="['sidebar', { open: sidebarOpen }]">
      <div v-if="locations.length > 0" class="location-wrapper">
        <LocationSelector :compact="!sidebarOpen" route-name="talent-pool-map" />
      </div>
      <div class="roles-wrapper">
        <ul v-if="roles?.length > 0">
          <li
            v-for="{ role } in roles"
            :key="role"
            :class="[{ selected: selectedRoles.includes(role) }]"
            @click="toggleRole(role)"
          >
            <span
              class="role-icon"
              :style="
                selectedRoles.includes(role)
                  ? {
                      background: roleColors[role] || roleColors['default'],
                      color: 'var(--palette-color-base-white)',
                    }
                  : { color: roleColors[role] || roleColors['default'] }
              "
              v-html="roleIcons[role] || roleIcons['default']"
            ></span>
            <span class="role-name">{{ role }}</span>
            <!-- <span class="role-count">{{ count }}</span> -->
          </li>
        </ul>
        <ul v-else>
          <li v-for="i in 5" :key="i">
            <span
              class="role-icon selected"
              :style="{ backgroundColor: 'var(--palette-color-default-lighten-80)' }"
            ></span>
            <span class="role-name">Loading...</span>
          </li>
        </ul>
      </div>
    </aside>
    <div class="map-container">
      <button :class="['expand-toggle', { open: sidebarOpen }]" @click="sidebarOpen = !sidebarOpen">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.91107 3.16083C7.23651 2.83539 7.76414 2.83539 8.08958 3.16083L14.3396 9.41083C14.665 9.73626 14.665 10.2639 14.3396 10.5893L8.08958 16.8393C7.76414 17.1648 7.23651 17.1648 6.91107 16.8393C6.58563 16.5139 6.58563 15.9863 6.91107 15.6608L12.5718 10.0001L6.91107 4.33934C6.58563 4.0139 6.58563 3.48626 6.91107 3.16083Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <l-map
        ref="map"
        @update:bounds="boundsUpdated"
        @update:zoom="zoomUpdated"
        class="map"
        :zoom="zoom"
        :center="center"
        :options="{
          attributionControl: false,
        }"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker
          v-for="employee in filteredEmployees"
          :key="employee.opaque_id"
          :lat-lng="[employee.worker_latitude, employee.worker_longitude]"
        >
          <l-icon class-name="worker-marker" :icon-size="[84, 47]" :icon-anchor="[0, 47]">
            <div v-if="zoom > 12" :class="['worker']">
              <span class="name">{{ truncateText(employee.first_name, 8) }}</span>
              <RaiseRating :rating="employee.internal_rating" size="EXTRA-SMALL" />
            </div>
            <div class="point"></div>
          </l-icon>
        </l-marker>
        <l-marker
          v-for="location in filteredLocations"
          :key="location.location_id"
          :lat-lng="[location.latitude, location.longitude]"
        >
          <l-icon class-name="location-marker" :icon-size="[64, 75]" :icon-anchor="[32, 75]">
            <div class="location-pin-inner">
              <img :src="location.properties.logo_url" class="location-logo" />
              <svg
                width="64"
                height="75"
                viewBox="0 0 64 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="pin"
              >
                <path
                  d="M64.0001 31.9997C64.0001 13.5747 48.4281 -1.21332 29.7271 0.0786837C13.7141 1.18468 0.843099 14.3257 0.0410991 30.3567C-0.672901 44.6427 7.9881 56.9997 20.4101 61.8287C22.6031 62.6807 24.4731 64.1987 25.7301 66.1867L30.9151 74.3907C31.1291 74.7257 31.4491 74.9317 31.8221 74.9837C32.3291 75.0607 32.8891 74.8547 33.1821 74.3647L38.3181 66.1867C39.5441 64.2347 41.3451 62.6917 43.4961 61.8637C55.4891 57.2457 64.0001 45.6197 64.0001 31.9997Z"
                />
              </svg>
            </div>
          </l-icon>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script setup>
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import { computed, onMounted, ref, watchEffect } from 'vue';
import ApiClient from '@/ApiClient';
import LocationSelector from '@/pages/talent-pool/components/LocationSelector.vue';
import { roleColors, roleIcons } from '@/pages/talent-pool/utils/roles';
import { truncateText } from '@/pages/talent-pool/utils/text';
import RaiseRating from '@/pages/talent-pool/components/RaiseRating.vue';

const url = process.env.VUE_APP_MAPBOX_MAP_URL + `?access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`;
const roleDelimiter = ' / ';
const employees = ref([]);
const map = ref(null);
const zoom = ref(13);
const selectedRoles = ref([]);
const sidebarOpen = ref(false);

const center = computed(() =>
  locations?.[0] ? [locations[0].latitude, locations[0].longitude] : [51.515419, -0.141099],
);

const { locations, getLocations } = useTalentPoolLocations();

const filteredLocations = computed(
  () =>
    locations.value?.length &&
    locations.value.filter(location => location.properties.logo_url && location.latitude && location.longitude),
);

const roles = computed(() => {
  const roleCounts = {}; // Object to store role counts
  // Iterate over each employee
  employees.value.forEach(employee => {
    // Split worker_role by roleDelimiter and trim each role
    const employeeRoles = employee.worker_role.split(roleDelimiter).map(role => role.trim());

    // Iterate over each role and update the count
    employeeRoles.forEach(role => {
      // If role already exists, increment the count, otherwise set it to 1
      roleCounts[role] = (roleCounts[role] || 0) + 1;
    });
  });

  const roleArray = Object.entries(roleCounts).map(([role, count]) => ({ role, count }));

  roleArray.sort((a, b) => a.role.localeCompare(b.role));

  return roleArray;
});

const filteredEmployees = computed(() =>
  employees.value.filter(employee =>
    employee.worker_role.split(roleDelimiter).some(role => selectedRoles.value.includes(role)),
  ),
);

watchEffect(() => {
  if (roles.value) {
    if (!selectedRoles.value.length && roles.value.length) {
      selectedRoles.value = roles.value.map(role => role.role);
    }
  }
});

const toggleRole = role => {
  if (!selectedRoles.value.includes(role) || selectedRoles.value.length > 1) {
    selectedRoles.value = [role];
  } else {
    selectedRoles.value = roles.value.map(role => role.role);
  }
};

const boundsUpdated = async bounds => {
  const data = (
    await ApiClient.getTalentPoolMapEmployees({
      min_lat: bounds._southWest.lat,
      max_lat: bounds._northEast.lat,
      min_lng: bounds._southWest.lng,
      max_lng: bounds._northEast.lng,
    })
  )?.data;
  employees.value = data;
};

const zoomUpdated = newZoom => {
  zoom.value = newZoom;
};

onMounted(async () => {
  await getLocations();
  map.value.setZoom(13.5);
});
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  display: flex;
  flex-grow: 1;
  background-color: var(--palette-color-base-white);
  padding: 1rem;
  gap: 1rem;
  max-height: 100%;
}

.page aside {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  max-height: 100%;
  min-width: 44px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.page aside::-webkit-scrollbar {
  display: none;
}

.employee-profile {
  width: 30%;
  border-radius: 0.5rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  overflow: hidden;
  background-color: var(--palette-color-base-background);
  display: flex;
  flex-direction: column;
}

.employee-profile header {
  background-color: var(--palette-color-base-white);
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employee-profile header .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.employee-profile header .icon {
  font-size: 20px;
  cursor: pointer;
}

.employee-profile .profile-wrapper {
  padding: 0.75rem;
  flex-grow: 1;
  overflow-y: scroll;
}

.page aside.open {
  width: 20%;
  gap: 1rem;
}

.page aside ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0rem;
  width: 100%;
}

.page aside ul li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  cursor: pointer;
}

.page.open aside ul {
  padding: 0.5rem 1rem;
}

.page aside ul li.selected .role-name {
  font-weight: 500;
}

.page aside ul li.selected .role-count {
  font-weight: 600;
}

aside:not(.open) .role-name {
  display: none;
}

aside:not(.open) .role-count {
  display: none;
}

.page aside ul li.selected .role-icon {
  opacity: 1;
}

.role-icon {
  height: 44px;
  width: 44px;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.role-name {
  margin-right: 0.5rem;
  flex-grow: 1;
}

aside.open .location-wrapper {
  border-radius: 0.5rem;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.roles-wrapper {
  display: flex;
  border-top: 1px solid var(--palette-color-default-lighten-90);
}

aside.open .roles-wrapper {
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.map-container {
  position: relative;
  flex-grow: 1;
}

.map-container .expand-toggle {
  background-color: var(--palette-color-base-white);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid var(--palette-color-default-lighten-90);
  position: absolute;
  top: 50%;
  left: 1rem;
  z-index: 999;
  color: var(--palette-color-default);
  transition: 0.2s;
  box-shadow: 0px 4px 20px 0px rgba(34, 40, 47, 0.15);
  box-shadow: 0px 0px 3px 0px rgba(34, 40, 47, 0.1);
}

.expand-toggle.open {
  transform: rotate(180deg);
}

.map {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 3px solid var(--palette-color-base-white);
}

.location-marker .location-pin-inner {
  position: relative;
  width: fit-content;
}

.location-marker .location-pin-inner .location-logo {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--palette-color-base-white);
}

.location-marker .location-pin-inner .pin {
  fill: var(--palette-color-brand-primary);
}

.worker-marker {
  width: auto !important;
}

.worker-marker .point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--palette-color-default-lighten-20);
}

.worker-marker .worker {
  background-color: var(--palette-color-base-white);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -38px;
  margin-bottom: 3px;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.worker-marker .name {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: center;
  text-transform: capitalize;
}

.worker-marker .roles {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.worker-marker .roles div {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
</style>
```
