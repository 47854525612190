<template>
  <app-page
    title="Bank Pay Analytics"
    icon="calendar-days"
    :previous="[{ label: 'Analytics', to: { name: 'analytics-homepage' } }]"
  >
    <template v-slot:custom-content>
      <b-col>
        <analytics-nav />

        <app-loading v-if="loading" />
        <template v-if="!loading">
          <sme-alert v-if="unfinalisedShiftsNextWeek !== 0" level="danger" class="mb-4">
            There are {{ unfinalisedShiftsNextWeek }} shifts that need to be finalised in order for employees to be paid
            in the next Bank Pay cycle. Go to Allocate HealthRoster to sign them off.
          </sme-alert>

          <b-row>
            <b-col md="12" class="mb-4">
              <sme-card
                :title="`Bank Pay Funnel for the week ${moment(timeSeries.calculated.last_complete * 1000).format(
                  'MMMM Do, YYYY',
                )}`"
              >
                <p>
                  The number of employees who are paid Bank Pay payments may not correlate to the number of Employees
                  who have worked Bank shifts. This may be due to the shifts not being finalised, or employees opting
                  out of receiving Bank Pay payments. Employees may have also streamed their wages before Bank Pay pay
                  day.
                </p>
                <bar-chart
                  :key="employeesFunnel.id"
                  :series-x="EmployeeFunnelLabels"
                  :series-y="employeesFunnel"
                  :chart-dataset-options="{ borderWidth: 2, borderSkipped: false, maxBarThickness: 125 }"
                  :chart-options="{
                    aspectRatio: getInitialAspectRatio(2.5, 1.5),
                    scales: { x: { grid: { display: 0 } } },
                  }"
                />
              </sme-card>
            </b-col>
            <b-col md="6" class="mb-4">
              <sme-card title="Employees receiving Bank Pay payments" class="h-100">
                <p>
                  These are the number of employees who have worked Bank shifts that have been finalised and have been
                  paid using Bank Pay.
                </p>
                <line-chart
                  :key="employeesOverTime.id"
                  :labels="timeSeries"
                  :datasets="employeesOverTime"
                  :chart-options="{
                    scales: { y: { title: { text: employeesOverTime.name } } },
                    plugins: { title: { text: employeesOverTime.description } },
                  }"
                  class="mt-auto"
                  fill-gradient
                />
              </sme-card>
            </b-col>
            <b-col md="6" class="mb-4">
              <sme-card title="Bank shifts worked by enrolled employees" class="h-100">
                <p>
                  These are the number of employees who have worked Bank shifts that have been finalised and have been
                  paid using Bank Pay.
                </p>
                <line-chart
                  :key="withShiftsOverTime.id"
                  :labels="timeSeries"
                  :datasets="withShiftsOverTime"
                  :chart-options="{
                    scales: { y: { title: { text: withShiftsOverTime.name } } },
                    plugins: { title: { text: withShiftsOverTime.description } },
                  }"
                  class="mt-auto"
                  fill-gradient
                />
              </sme-card>
            </b-col>
            <b-col md="6" class="mb-4">
              <sme-card title="Opted out employees with Bank shifts" class="h-100">
                <p>
                  These are the number of employees who have worked Bank shifts that have been finalised, but who have
                  chosen to opt out of the Bank Pay feature. Therefore they have not been paid using Bank Pay.
                </p>
                <line-chart
                  :key="optOutsOverTime.id"
                  :labels="timeSeries"
                  :datasets="optOutsOverTime"
                  :chart-options="{
                    scales: { y: { title: { text: optOutsOverTime.name } } },
                    plugins: { title: { text: optOutsOverTime.description } },
                  }"
                  class="mt-auto"
                  fill-gradient
                />
              </sme-card>
            </b-col>
            <b-col md="6" class="mb-4">
              <sme-card title="Finalised Bank shifts" class="h-100">
                <p>
                  Shifts must be finalised before we can pay Bank shifts. This chart shows the percentage of finalised
                  Bank shifts against all Bank shifts.
                </p>
                <line-chart
                  :key="finalisedOverTime.id"
                  :labels="timeSeries"
                  :datasets="finalisedOverTime"
                  :chart-options="{
                    scales: { y: { title: { text: finalisedOverTime.name } } },
                    plugins: { title: { text: finalisedOverTime.description } },
                  }"
                  class="mt-auto"
                  fill-gradient
                />
              </sme-card>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import map from 'lodash/map';
import moment from 'moment/moment';
import { onMounted, ref } from 'vue';
import ApiClient from '@/ApiClient';
import { PaletteColors } from '@/Theme';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import SmeAlert from '@/components/atoms/SmeAlert';
import SmeCard from '@/components/atoms/SmeCard.vue';
import BarChart from '@/components/charts/BarChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import AnalyticsNav from '@/pages/analyticsHomepage/components/AnalyticsNav.vue';
import { getInitialAspectRatio } from '@/utils/chart';

const loading = ref(true);
const employeesOverTime = ref();
const unfinalisedShiftsNextWeek = ref(0);
const employeesFunnel = ref();
const timeSeries = ref();
const optOutsOverTime = ref();
const withShiftsOverTime = ref();
const finalisedOverTime = ref();

const EmployeeFunnelLabels = {
  name: 'Bank Pay usage',
  values: [
    { name: 'Employees With Shifts', id: 'employees_with_shifts', color: PaletteColors['brand-primary'] },
    {
      name: 'Employees With No Finalised Shifts',
      id: 'employees_with_only_late_shifts',
      isNegative: true,
      color: PaletteColors['danger'],
    },
    {
      name: 'Employees With Finalised Shifts',
      id: 'employees_with_on_time_shifts',
      color: PaletteColors['brand-primary'],
    },
    {
      name: 'Opted Out Shift Employees',
      id: 'opted_out_employees_with_shifts',
      isNegative: true,
      color: PaletteColors['danger'],
    },
    { name: 'Paid Employees', id: 'employees_paid', color: PaletteColors['brand-primary'] },
  ],
};

onMounted(async () => {
  const data = await ApiClient.getWeeklyPayMetrics();

  const timeDataset = data.results.find(res => res.id === 'time');
  timeSeries.value = {
    ...timeDataset,
    values: timeDataset.values.slice(0, -1).map(value => moment(value * 1000).format('DD MMM')),
  };

  employeesOverTime.value = data.results.find(res => res.id === 'employees_paid');

  unfinalisedShiftsNextWeek.value = data.results.find(
    res => res.id === 'employees_with_only_late_shifts',
  ).calculated.last;

  const employeeFunnelDatasets = map(EmployeeFunnelLabels.values, (label, index) => {
    const dataset = data.results.find(item => item.id === label.id);
    if (label.isNegative) {
      const previousDataset = data.results.find(item => item.id === EmployeeFunnelLabels.values[index - 1].id);
      const offset = previousDataset.calculated.last_complete - dataset.calculated.last_complete;
      return [offset, dataset.calculated.last_complete + offset];
    }
    return [0, dataset.calculated.last_complete];
  });

  employeesFunnel.value = {
    name: 'Number of employees',
    description: 'Bank Pay Employee Funnel for the previous week',
    values: employeeFunnelDatasets,
  };

  const employeesWithShiftsDataset = data.results.find(res => res.id === 'employees_with_shifts');
  const optOutsWithShiftsDataset = data.results.find(res => res.id === 'opted_out_employees_with_shifts');
  const finalisedShiftsDataset = data.results.find(res => res.id === 'employees_with_on_time_shifts');
  const enrolledDataset = data.results.find(res => res.id === 'enrolled_employees');

  optOutsOverTime.value = {
    name: 'Percentage (%)',
    description: 'Percentage of opted out employees with Bank shifts over time',
    values: employeesWithShiftsDataset.values.map(
      (datum, index) => (optOutsWithShiftsDataset.values[index] / datum) * 100,
    ),
  };

  withShiftsOverTime.value = {
    name: 'Percentage (%)',
    description: 'Percentage of Bank shifts taken by enrolled employees over time',
    values: enrolledDataset.values.map((datum, index) => (employeesWithShiftsDataset.values[index] / datum) * 100),
  };

  finalisedOverTime.value = {
    name: 'Percentage (%)',
    description: 'Percentage of finalised Bank shifts per week',
    values: employeesWithShiftsDataset.values.map(
      (datum, index) => (finalisedShiftsDataset.values[index] / datum) * 100,
    ),
  };

  loading.value = false;
});
</script>
