<template>
  <app-page title="Add Admin" :previous="[{ label: 'Admins', to: { name: 'admins' } }]" icon="user-plus">
    <admin-add-form />
  </app-page>
</template>

<script>
import AppPage from '@/components/AppPage.vue';
import AdminAddForm from '@/pages/adminAdd/component/AdminAddForm.vue';

export default {
  name: 'AdminAdd',
  components: { AppPage, AdminAddForm },
};
</script>
