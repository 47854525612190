<template>
  <b-dropdown v-if="hasDivisions" variant="primary" no-caret>
    <template #button-content>
      <font-awesome-icon :icon="['fad', 'repeat']" class="mr-2" />
      <template v-if="selectedDivision"> Division: {{ selectedDivision.name }} </template>
      <template v-else> All Divisions </template>
    </template>
    <template v-if="selectedDivision">
      <b-dropdown-item-button @click="setDivision(null)">
        <font-awesome-icon :icon="['fal', 'circle-xmark']" class="mr-2" /> Clear
      </b-dropdown-item-button>
      <b-dropdown-divider />
    </template>
    <b-dropdown-item-button
      v-for="division in divisions"
      :active="division === selectedDivision"
      :key="division.division_id"
      @click="setDivision(division)"
    >
      {{ division.name }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script setup>
import useDivisionSelection from '@/state/composables/useDivisionSelection';

const { division: selectedDivision, divisions, hasDivisions, setDivision } = useDivisionSelection();
</script>

<style lang="scss" scoped>
:deep(.dropdown-menu) {
  height: 50vh;
  min-height: 15rem;
  overflow-y: auto;
}
</style>
