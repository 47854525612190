var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-page',{attrs:{"title":`Unpause ${_vm.employee ? _vm.employee.full_name : 'Employee'}`,"error":_vm.error,"previous":[
    { label: 'Employees', to: { name: 'employees' } },
    {
      label: _vm.employee ? _vm.employee.full_name : 'Employee',
      to: { name: 'employee', params: { employeeId: _vm.$route.params.employeeId } },
    },
  ],"icon":"pause"}},[_c('app-loading',{attrs:{"loading":_vm.loading}}),(!_vm.loading && !_vm.saved)?[_c('sme-alert',{attrs:{"level":"warning"}},[_vm._v(" You are about to unpause access to the app for "+_vm._s(_vm.employee.full_name)+" ")]),_c('page-sub-footer',[_c('b-button',{attrs:{"data-testid":"unpause-button","variant":"primary","disabled":_vm.saving || _vm.saved},on:{"click":_vm.unpause}},[(_vm.saving)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.saving ? 'Unpausing...' : 'Unpause')+" ")],1)],1)]:_vm._e(),(_vm.saved)?_c('sme-alert',{attrs:{"level":"good"}},[_vm._v(_vm._s(_vm.employee.full_name)+" streamed wages have been unpaused")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }