import ApiClient from '@/ApiClient';
import { useStateComposable } from '@/state/composables';

const { computedState, state, reset } = useStateComposable({
  roles: [],
  locations: [],
  error: false,
  errorRoles: false,
  errorLocations: false,
  initial: true,
  initialRoles: false,
  initialLocations: false,
  loading: false,
  loadingRoles: false,
  loadingLocations: false,
});

const getRolesAndLocations = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initial) {
    return;
  }

  reset();

  state.initial = false;
  state.loading = true;

  try {
    await Promise.all([getRoles(), getLocations()]);
  } catch (error) {
    state.error = true;
    console.error(error);
  }

  state.loading = false;
};

const getRoles = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initialRoles) {
    return;
  }

  reset(['roles', 'errorRoles', 'initialRoles', 'loadingRoles']);

  state.initialRoles = false;
  state.loadingRoles = true;

  try {
    state.roles = (await ApiClient.getFlexibleWorkRoles())?.roles || [];
  } catch (error) {
    state.errorRoles = true;
    console.error(error);
  }

  state.loadingRoles = false;
};

const getLocations = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initialLocations) {
    return;
  }

  reset(['locations', 'errorLocations', 'initialLocations', 'loadingLocations']);

  state.initialLocations = false;
  state.loadingLocations = true;

  try {
    state.locations = ((await ApiClient.getFlexibleWorkLocations())?.locations || []).map(location => ({
      ...location,
      invalid: !!location.properties?.postcode,
    }));
  } catch (error) {
    state.errorLocations = true;
    console.error(error);
  }

  state.loadingLocations = false;
};

export default () => {
  return {
    ...computedState,
    getRolesAndLocations,
    getRoles,
    getLocations,
  };
};
