<template>
  <register-integrate-page>
    <app-loading v-if="loading" />
    <sme-alert v-if="error" level="danger" class="mb-4">{{ error }}</sme-alert>
    <div class="oauth-container">
      <img :alt="state.state.provider" :src="`/integrations/${state.state.provider}.png`" class="" />
    </div>
    <p>
      Hook up your <span class="text-capitalize">{{ state.state.provider }}</span> account to automatically synchronise
      employees and shifts.
    </p>
    <div class="button-container" v-if="state.state.provider === 's4labour'">
      <b-button
        sm="6"
        lg="2"
        class="mb-3 right-margin"
        type="submit"
        variant="primary"
        @click="submitHandledIntegration"
      >
        Connect
      </b-button>
      <div class="button">
        <router-link :to="{ name: 'sme-dashboard' }" class="btn btn-outline-secondary btn-change-payday">
          Skip
        </router-link>
      </div>
    </div>
    <div class="button-container" v-else-if="state.state.provider === 'c247'">
      <b-button
        sm="6"
        lg="2"
        class="mb-3 right-margin"
        type="submit"
        variant="primary"
        @click="submitHandledIntegration"
      >
        Connect
      </b-button>
      <div class="button">
        <router-link :to="{ name: 'sme-dashboard' }" class="btn btn-outline-secondary btn-change-payday">
          Skip
        </router-link>
      </div>
    </div>
    <div class="button-container" v-else-if="state.state.provider === 'harri'">
      <b-button
        sm="6"
        lg="2"
        class="mb-3 right-margin"
        type="submit"
        variant="primary"
        @click="submitHandledIntegration"
      >
        Connect
      </b-button>
      <div class="button">
        <router-link :to="{ name: 'sme-dashboard' }" class="btn btn-outline-secondary btn-change-payday">
          Skip
        </router-link>
      </div>
    </div>
    <div class="button-container" v-else>
      <b-button sm="6" lg="2" class="mb-3 right-margin" type="submit" variant="primary" @click="startOauthFlow">
        Connect
      </b-button>
      <div class="button">
        <router-link :to="{ name: 'sme-dashboard' }" class="btn btn-outline-secondary btn-change-payday">
          Skip
        </router-link>
      </div>
    </div>
  </register-integrate-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { GoogleTagManager } from '@/mixins/GoogleTagManager';
import RegisterIntegratePage from '@/pages/registerIntegrate/components/RegisterIntegratePage.vue';
import OnboardingState from '@/state/OnboardingState.js';

export default {
  name: 'RegisterIntegrate',
  components: {
    AppLoading,
    SmeAlert,
    RegisterIntegratePage,
  },
  mixins: [GoogleTagManager],
  data() {
    return {
      loading: false,
      error: undefined,
      state: OnboardingState.load(),
    };
  },
  methods: {
    async redirectToDashboard() {
      this.$router.push({ name: 'apps' });
    },
    startOauthFlow() {
      window.location.replace(`${window.location.origin}/authorize/${this.state.state.provider}?next=/`);
    },
    async submitHandledIntegration() {
      const payload = {
        email: this.state.state.userEmail,
      };
      try {
        await ApiClient.configureIntegration(this.state.state.provider, this.state.state.companyId, payload);
        this.redirectToDashboard();
      } catch {
        this.error = "Integration failed. You can skip this step for now, we'll be in touch shortly.";
      }
    },
  },
  mounted() {
    // if either a provider is not set or is not "one-click"
    // don't force them to register now, add it to their todo list
    if (
      !this.state.state.provider ||
      this.state.state.provider == 'rotaready' ||
      this.state.state.provider == 'hibob'
    ) {
      this.redirectToDashboard();
    }
  },
};
</script>

<style scoped lang="scss">
.button {
  margin-right: 20px;
}
.button-container {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.outer {
  background: var(--palette-color-base-background);
  position: relative;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
}

.outer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.inner {
  width: 588px;
  margin: 0 auto;
}

.logo {
  display: block;
  margin: 45px auto 25px auto;
  height: 51px;
  width: 78px;
}

.title1,
.title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
}

.title1 {
  color: var(--palette-color-default-lighten-20);
}

.title2 {
  color: var(--palette-color-brand-primary);
}

.subtitle {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: var(--palette-color-default-lighten-20);
  margin-top: 24px;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 70px 0;
}

.steps__item {
  color: var(--palette-color-base-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9000px;
  background: var(--palette-color-default-lighten-30);
  min-height: 56px;
  min-width: 56px;
  font-weight: 300;
  font-size: 25px;
  opacity: 0.25;
}

.steps__line {
  border-top: 1px dashed var(--palette-color-default-lighten-30);
  width: 85px;
  opacity: 0.5;
}

.steps__item--active {
  opacity: 1;
}

.steps__line:last-child {
  display: none;
}

.main {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: auto;
  background: var(--palette-color-base-white);
  color: var(--palette-color-default-lighten-30);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-weight: 300;
  font-size: 14px;
  margin: 48px 0;
  padding: 84px;
  width: 588px;
}

.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6,
strong {
  color: var(--palette-color-default);
}

.oauth-container {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
}

.right-margin {
  margin-right: 12px;
}
</style>
