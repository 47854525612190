import { render, staticRenderFns } from "./FlexibleWorkMap.vue?vue&type=template&id=85b2a240&scoped=true&"
import script from "./FlexibleWorkMap.vue?vue&type=script&setup=true&lang=js&"
export * from "./FlexibleWorkMap.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./FlexibleWorkMap.vue?vue&type=style&index=0&id=85b2a240&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b2a240",
  null
  
)

export default component.exports