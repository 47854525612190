<template>
  <app-page
    :title="`Unpause ${employee ? employee.full_name : 'Employee'}`"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="pause"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning"> You are about to unpause access to the app for {{ employee.full_name }} </sme-alert>
      <page-sub-footer>
        <b-button data-testid="unpause-button" @click="unpause" variant="primary" :disabled="saving || saved">
          <b-spinner v-if="saving" class="mr-2" small />
          {{ saving ? 'Unpausing...' : 'Unpause' }}
        </b-button>
      </page-sub-footer>
    </template>
    <sme-alert v-if="saved" level="good">{{ employee.full_name }} streamed wages have been unpaused</sme-alert>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';

export default {
  name: 'EmployeeUnpause',
  components: { AppLoading, AppPage, PageSubFooter, SmeAlert },
  data() {
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
    };
  },
  methods: {
    unpause() {
      this.saving = true;
      const newEmployee = { ...this.employee };

      if (!newEmployee.properties) {
        newEmployee.properties = {};
      }

      newEmployee.properties.paused = false;
      newEmployee.properties.paused_timestamp = null;
      newEmployee.properties.paused_reason = null;
      newEmployee.properties.pause_salary_accrual = false;
      newEmployee.properties.pause_expires_at = null;
      newEmployee.properties.pause_scheduled_at = null;

      ApiClient.updateEmployee(newEmployee).then(
        () => {
          this.saving = false;
          this.saved = true;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
