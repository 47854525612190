<template>
  <div class="ap">
    <div class="ap__banner">
      <slot name="image">
        <img class="ap__image" :src="image" />
      </slot>
    </div>
    <div class="ap__container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import phoneImg from '@/assets/login-phone.png';

export default {
  name: 'AppAuthPage',
  props: {
    image: {
      type: String,
      default: phoneImg,
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'SemplicitaProBold';
  src: url('../assets/fonts/semplicitapro-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'SemplicitaPro';
  src: url('../assets/fonts/semplicitapro-webfont.woff') format('woff');
}

.ap {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: row;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;

  &__banner {
    display: flex;
    height: 100vh;
    overflow: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-color: var(--palette-color-default-lighten-95);
  }

  &__image {
    margin-right: 64px;
    margin-left: 16px;
    width: auto;
    max-height: 100%;
  }

  &__container {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 48px 64px;
    flex-direction: column;
    justify-content: center;
    background: var(--palette-color-base-white);
    max-height: 100vh;
    max-width: 500px;
    min-width: 500px;
    width: 33vw;
    flex-grow: 1;
    z-index: 1;
  }

  & h1 {
    text-align: center;
    margin-bottom: 0.5em;
    opacity: 0.9;
  }

  @media (max-width: 900px) {
    & {
      justify-content: center;
      align-items: center;
    }

    &__image {
      display: none;
    }

    &__banner {
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 0;
    }

    &__proof {
      display: none;
    }

    &__container {
      flex-grow: 0;
      width: 100%;
      max-height: 90vh;
      border-radius: 16px;
      padding: 48px 32px;
      margin: 0 32px;
      min-width: 0;
    }

    & h1 {
      font-size: 1.5em;
    }
  }
}
</style>
