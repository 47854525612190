<template>
  <sme-card title="Pay" :to="{ name: 'sme-pay-day' }" class="payschedules">
    <p>Pay schedules are how we know when your pay periods start and when your pay days fall.</p>
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <sme-alert v-if="!payschedules.length" :level="ALERT_LEVELS.DANGER">
        You must add a payschedule before your employees can start using the app.
      </sme-alert>
      <sme-alert v-else-if="!payscheduleReports.length" :level="ALERT_LEVELS.GOOD">
        You have added a payschedule. Now please import your employees.
      </sme-alert>
      <div v-else>
        <sme-info-box
          id="pay-terminology"
          title="What are missed salaries, invalid bank details and yet to be paid?"
          v-if="!hideStreamAccountRelatedItems"
        >
          <h4>Missed salaries</h4>
          <p>
            A <strong>missed salary</strong> happens when we do not receive an employee's salary. Your employees'
            salaries should be paid to their Wagestream bank account details on pay day, so that we can subtract the
            amount they have streamed.
          </p>
          <h4>Invalid bank details</h4>
          <p>
            If we are given <strong>invalid bank details</strong> we won't be able to pay an employee on pay day. You
            can edit your employees' details on the
            <router-link :to="{ name: 'employees' }" target="_blank">portal</router-link>.
          </p>
          <h4>Yet to be paid</h4>
          <p>
            We will flag any employees added since your last payday. You need to make sure that you update your payroll
            system with their Wagestream bank details.
          </p>
        </sme-info-box>
        <sme-alert
          v-if="
            (hasFailedSalaryEmployees || hasInvalidBankDetailsEmployees || hasMissedSalaryEmployees) &&
            !hideStreamAccountRelatedItems
          "
          :level="ALERT_LEVELS.DANGER"
          class="mb-3"
        >
          We have identified some issues with your payschedules. For more information, please refer to the
          <sme-button-link :to="{ name: 'sme-pay-day' }">Pay</sme-button-link> page.
        </sme-alert>
        <div class="payschedules__list">
          <dashboard-payschedules-tab
            v-for="data in payscheduleReports"
            :key="data.payschedule.pay_schedule_id"
            :data="data"
          />
        </div>
      </div>
    </template>

    <template v-if="!loading" v-slot:footer>
      <b-button :to="{ name: 'sme-pay-day' }" variant="outline-primary">View payschedules</b-button>
      <b-button v-if="!payschedules.length && !isDisablePayscheduleEditing" @click="onClick" variant="primary">
        Add payschedule
      </b-button>
    </template>
  </sme-card>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeButtonLink from '@/components/atoms/SmeButtonLink.vue';
import SmeCard from '@/components/atoms/SmeCard';
import SmeInfoBox from '@/components/atoms/SmeInfoBox';
import useFeatureFlags from '@/composables/useFeatureFlags';
import DashboardPayschedulesTab from '@/pages/dashboard/components/payschedules/DashboardPayschedulesTab';
import useEmployees from '@/state/composables/useEmployees';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import usePayschedules from '@/state/composables/usePayschedules';
import { toFormattedDate } from '@/utils/date';
import { useRouter } from 'vue-router/composables';
import useAnalytics from '@/state/composables/useAnalytics';

const { postAnalyticsEvent } = useAnalytics();
const $router = useRouter();
const { employeesLoading } = useEmployees();
const {
  getEmployeesReport,
  loading: employeesLoadingReport,
  payscheduleReports,
  hasFailedSalaryEmployees,
  hasInvalidBankDetailsEmployees,
  hasMissedSalaryEmployees,
} = useEmployeesReport();
const { getPayschedules, loading: loadingPayschedules, payschedules } = usePayschedules();
const { isDisablePayscheduleEditing, hideStreamAccountRelatedItems } = useFeatureFlags();

const startDate = toFormattedDate(new Date().setDate(new Date().getDate() - 35));
const endDate = toFormattedDate(new Date());

const loading = computed(() => employeesLoading.value || employeesLoadingReport.value || loadingPayschedules.value);

const onClick = () => {
  postAnalyticsEvent({
    intent: 'navigate_dashboard_pay_schedules_add_pay_schedule',
    task: 'add_pay_schedule',
    action: 'click',
  });
  $router.push({ name: 'pay-schedule', params: { payScheduleId: 'create' } });
};

onBeforeMount(async () => {
  await Promise.all([getPayschedules(), getEmployeesReport(startDate, endDate)]);
});
</script>

<style lang="scss" scoped>
.payschedules__list {
  max-height: 25rem;
  overflow-y: auto;
}
</style>
