import { PaletteColors } from '@/Theme';

export const getAllShiftsWorkedTimeSpan = (daysInRole, hoursInRole) => {
  if (!daysInRole) {
    return `${hoursInRole} hour${hoursInRole === 1 ? '' : 's'}`;
  }

  if (daysInRole < 365) {
    const month = (daysInRole / 30.44).toFixed(0);

    if (month === '0') {
      const days = daysInRole.toFixed(0);
      return `${days} day${days === '1' ? '' : 's'}`;
    }

    return `${month} month${month === '1' ? '' : 's'}`;
  } else {
    const years = (daysInRole / 365).toFixed(0);
    return `${years}+ year${years === '1' ? '' : 's'}`;
  }
};

export const getHoursInRole = roles => {
  return roles
    ?.filter(role => role.is_role_match)
    .map(role => role.hours_in_role)
    .reduce((partialSum, a) => partialSum + a, 0)
    .toFixed(0);
};

export const getHoursInFunction = roles => {
  return roles
    ?.filter(role => role.is_function_match)
    .map(role => role.hours_in_role)
    .reduce((partialSum, a) => partialSum + a, 0)
    .toFixed(0);
};

export const getDaysInRole = roles => {
  return roles
    ?.filter(role => role.is_role_match)
    .map(role => role.days_in_role)
    .reduce((partialSum, a) => partialSum + a, 0);
};

export const getDaysInFunction = roles => {
  return roles
    ?.filter(role => role.is_function_match)
    .map(role => role.days_in_role)
    .reduce((partialSum, a) => partialSum + a, 0);
};

export const getCompanyLogosFromRoles = roles => {
  const logos = roles
    ?.map(role => role.brands)
    .flat(4)
    .map(brand => brand.logo_url)
    .filter(logo => !!logo);
  if (!logos) {
    return [];
  }
  return logos.splice(0, 3);
};

export const formatWorkerName = workerName => {
  if (!workerName) {
    return '';
  }
  const nameSplit = workerName?.split(' ');
  return nameSplit[0];
};

export const getInternalRating = claim => {
  if (claim.roles && claim.roles.length) {
    return 0.5 * claim.roles[0].internal_rating_for_role + 0.5 * claim.internal_rating_for_worker;
  }
  return claim.internal_rating_for_worker;
};

const groupByRole = (historyArray, type) => {
  return historyArray?.reduce((acc, history) => {
    const role = 'internal_role' in history ? history.internal_role?.role : history?.role || '';

    if (!acc[role]) {
      acc[role] = { [type]: [] };
    }

    acc[role][type].push(history);
    return acc;
  }, {});
};

export const buildProfileByRole = profile => {
  if (!profile) {
    return {};
  }

  const { raise_history = [], wagestream_history = [], unverified_roles = [] } = profile;

  const raiseHistoryByRole = groupByRole(raise_history, 'raise_history');
  const wagestreamHistoryByRole = groupByRole(wagestream_history, 'wagestream_history');
  const unverifiedHistoryByRole = groupByRole(unverified_roles, 'unverified_roles');

  const combinedProfileByRole = {};

  if (raiseHistoryByRole) {
    Object.keys(raiseHistoryByRole).forEach(role => {
      if (!combinedProfileByRole[role]) {
        combinedProfileByRole[role] = {};
      }
      Object.assign(combinedProfileByRole[role], raiseHistoryByRole[role]);
    });
  }

  if (wagestreamHistoryByRole) {
    Object.keys(wagestreamHistoryByRole).forEach(role => {
      if (!combinedProfileByRole[role]) {
        combinedProfileByRole[role] = {};
      }
      Object.assign(combinedProfileByRole[role], wagestreamHistoryByRole[role]);
    });
  }

  if (unverifiedHistoryByRole) {
    Object.keys(unverifiedHistoryByRole).forEach(role => {
      if (!combinedProfileByRole[role]) {
        combinedProfileByRole[role] = {};
      }
      Object.assign(combinedProfileByRole[role], unverifiedHistoryByRole[role]);
    });
  }

  return combinedProfileByRole;
};

export const getExperienceLevelColor = experienceLevel => {
  if (experienceLevel === 'Trainee') {
    return PaletteColors['core-5-darken-10'];
  }
  if (experienceLevel === 'Junior') {
    return PaletteColors['core-4'];
  }
  if (experienceLevel === 'Skilled') {
    return PaletteColors['core-3'];
  }
  if (experienceLevel === 'Experienced') {
    return PaletteColors['core-1-lighten-30'];
  }
  return PaletteColors['core-1'];
};

export const getExperienceLevel = days => {
  if (!days) {
    return 'Trainee';
  }

  // less than 6 months
  const monthString = (days / 30.44).toFixed(0);

  const month = parseInt(monthString);

  if (month < 6) {
    return 'Trainee';
  }
  if (month <= 12) {
    return 'Junior';
  }

  const years = (days / 365).toFixed(0);

  if (years < 2) {
    return 'Skilled';
  }
  if (years <= 5) {
    return 'Experienced';
  }
  return 'Seasoned';
};
