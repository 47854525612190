import { render, staticRenderFns } from "./TeamSection.vue?vue&type=template&id=58787170&scoped=true&"
import script from "./TeamSection.vue?vue&type=script&setup=true&lang=js&"
export * from "./TeamSection.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TeamSection.vue?vue&type=style&index=0&id=58787170&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58787170",
  null
  
)

export default component.exports