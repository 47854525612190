export const redirectConfigs = {
  default: [
    {
      id: 1,
      name: 'register-landing',
      queryParams: { typeformId: '01J9BN66C6SRWAH209STXNHZEW', ws_group_id: 'default' },
      probability: 1,
    },
  ],
  // This is an example, testing different typeforms embed mode
  abTestGroup: [
    {
      id: 2,
      name: 'register-landing',
      queryParams: { typeformId: '01J9KJ56DP49B909Q1WS9VNATE' },
      probability: 0.25,
    },
    {
      id: 3,
      name: 'register-landing',
      queryParams: { typeformId: '01J9MAN797096HD1BSBSCW18Y6' },
      probability: 0.25,
    },
    {
      id: 4,
      name: 'register-landing',
      queryParams: { typeformId: '01J9KJ6KJGPY9WC6KVDG8Q2HZ0' },
      probability: 0.25,
    },
    {
      id: 5,
      name: 'register-landing',
      queryParams: { typeformId: '01J9MB1BRVR0HTWAPRBNRZTART' },
      probability: 0.25,
    },
  ],
};
