import cloneDeep from 'lodash/cloneDeep';
import State from '@/state/State';
import { TODO_ACTIONS } from '@/utils/Todo';
import useFeatureFlags from '@/composables/useFeatureFlags';
import ApiClient from '@/ApiClient';

export const NAME_REGEX = /^[a-zA-ZÀ-ÿ0-9 .\-/&'‘’]+$/;

const MAX_SALARY = 500000;
const MAX_WAGERATE = 99.99;

const { showLeaversAsDisabled, canEnrollEmployees, canDisableEmployees } = useFeatureFlags();

export const SALARY_VALIDATION_RULES = {
  required: true,
  min_value: 0,
  max_value: MAX_SALARY,
};

export const WAGERATE_VALIDATION_RULES = {
  required: true,
  min_value: 0,
  max_value: MAX_WAGERATE,
};

export const EMPLOYEE_STATE = {
  NEW: 'NEW',
  INSTALLED: 'INSTALLED',
  PENDING: 'PENDING',
  ENROLLING: 'ENROLLING',
  ENROLLED: 'ENROLLED',
  ACTIVE: 'ACTIVE',
  OPTOUT: 'OPTED OUT',
  DISABLED: 'DISABLED',
  OPTOUTPENDING: 'OPT OUT PENDING',
};

export const EMPLOYEE_PAY_TYPE = {
  HOURLY: 'hourly',
  SALARY: 'salary',
};

const NEW_EMPLOYEE_STATE = [EMPLOYEE_STATE.NEW, EMPLOYEE_STATE.INSTALLED];

const PENDING_EMPLOYEE_STATE = [EMPLOYEE_STATE.PENDING];

const ENROLLED_EMPLOYEE_STATE = [EMPLOYEE_STATE.ENROLLING, EMPLOYEE_STATE.ENROLLED, EMPLOYEE_STATE.ACTIVE];

const DISABLED_EMPLOYEE_STATE = [EMPLOYEE_STATE.DISABLED, EMPLOYEE_STATE.OPTOUT, EMPLOYEE_STATE.OPTOUTPENDING];

export const isNewEmployee = employee => NEW_EMPLOYEE_STATE.includes(employee.current_state);

export const isPendingEmployee = employee => PENDING_EMPLOYEE_STATE.includes(employee.current_state);

export const isEnrolledEmployee = employee => ENROLLED_EMPLOYEE_STATE.includes(employee.current_state);

export const isDisabledOrOptedOutEmployee = employee => DISABLED_EMPLOYEE_STATE.includes(employee.current_state);

export const isEligibleEmployee = employee => !DISABLED_EMPLOYEE_STATE.includes(employee.current_state);

export const isSalariedEmployee = employee => employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY;

export const canEnrollEmployee = employee => isPendingEmployee(employee) && canEnrollEmployees.value;

export const canOptOutEmployee = employee =>
  convertBackendStatusToDisplayStatus(employee) !== 'LEAVER' &&
  convertBackendStatusToDisplayStatus(employee) !== 'OPTED OUT' &&
  convertBackendStatusToDisplayStatus(employee) !== 'OPT OUT PENDING';

export const canCompleteOptOutForEmployee = employee =>
  canDisableEmployees.value &&
  convertBackendStatusToDisplayStatus(employee) == 'OPT OUT PENDING' &&
  State.state.todos.some(todo => todo.action === TODO_ACTIONS.DISABLE_EMPLOYEE && todo.fk === employee.employee_id);

export const canOptInEmployee = employee =>
  convertBackendStatusToDisplayStatus(employee) === 'OPTED OUT' ||
  (canEnrollEmployees.value && convertBackendStatusToDisplayStatus(employee) === 'LEAVER');

export const canPauseEmployee = employee =>
  convertBackendStatusToDisplayStatus(employee) !== 'PAUSED' &&
  convertBackendStatusToDisplayStatus(employee) !== 'PAUSE SCHEDULED';

export const canUnpauseEmployee = employee =>
  convertBackendStatusToDisplayStatus(employee) === 'PAUSED' ||
  convertBackendStatusToDisplayStatus(employee) === 'PAUSE SCHEDULED';

export const canChangePasswordForEmployee = employee =>
  convertBackendStatusToDisplayStatus(employee) !== 'LEAVER' &&
  convertBackendStatusToDisplayStatus(employee) !== 'OPTED OUT' &&
  convertBackendStatusToDisplayStatus(employee) !== 'OPT OUT PENDING';

export const getStateCounts = enrolmentSnapshot => {
  const snapshot = {};
  snapshot['ELIGIBLE'] = State.state.company.properties.headcount || enrolmentSnapshot.eligible || 0;
  snapshot[EMPLOYEE_STATE.ENROLLING] = enrolmentSnapshot.enrolling + enrolmentSnapshot.pending || 0;
  snapshot[EMPLOYEE_STATE.ENROLLED] = enrolmentSnapshot.adopted || 0;
  snapshot[EMPLOYEE_STATE.NEW] =
    (State.state.company.properties.headcount
      ? State.state.company.properties.headcount - (snapshot.ENROLLING + snapshot.ENROLLED)
      : enrolmentSnapshot.new) || 0;
  return snapshot;
};

export const convertBackendStatusToDisplayStatus = employee => {
  if (showLeaversAsDisabled.value && employee.current_state === 'DISABLED') {
    if (employee.properties?.optout_initiated_at && !employee.properties?.optout_completed_at) {
      return 'OPT OUT PENDING';
    } else if (employee.properties?.unenrolment_initiated_at && !employee.properties?.unenrolment_completed_at) {
      return 'LEAVER';
    } else {
      return 'DISABLED';
    }
  }

  if (employee.current_state === 'DISABLED' && employee.properties?.optout_initiated_at) {
    if (!employee.properties?.optout_completed_at) {
      return 'OPT OUT PENDING';
    } else if (employee.terminated_at) {
      return 'LEAVER';
    } else {
      return 'OPTED OUT';
    }
  }

  if (employee.current_state === 'DISABLED' && employee.terminated_at) {
    return 'LEAVER';
  }

  if (employee.properties?.paused && employee.current_state !== 'DISABLED') {
    return 'PAUSED';
  }

  if (employee.properties?.pause_scheduled_at && employee.current_state !== 'DISABLED') {
    return 'PAUSE SCHEDULED';
  }

  const convertedStatuses = {
    // the employee has not yet signed up, nothing happening yet, NOT YET ACTIVE
    NEW: 'NOT ENROLLED',
    INSTALLED: 'NOT ENROLLED',
    // the employee uses the app, USING
    ACTIVE: 'ENROLLED',
    ENROLLED: 'ENROLLED',
    // the employee is in the process of activating and using the app, ONBOARDING
    ENROLLING: 'ENROLLING',
    PENDING: 'ENROLLING',
    // for some reason the employee is not using the app, DISABLED
    FURLOUGH: 'DISABLED',
    OPTOUT: 'OPTED OUT',
    OPTOUTPENDING: 'DISABLED',
    PAUSED: 'PAUSED',
    DISABLED: 'DISABLED',
  };

  return convertedStatuses[employee.current_state] || 'NO STATUS';
};

const removeBeneficiaryDetails = employee => {
  const employeeNoBanking = cloneDeep(employee);
  delete employeeNoBanking.properties.beneficiary_account_number;
  delete employeeNoBanking.properties.beneficiary_sort_code;
  return employeeNoBanking;
};

export const updateEmployeeAndBanking = async employee => {
  // beneficiiary details need to be removed from the employee object before patching, since the
  // banking patch does a diff between the payload and the current state of the employee
  // so if we have just updated the bank details, the banking update will always be a no-op
  const employeeNoBanking = removeBeneficiaryDetails(employee);
  await ApiClient.updateEmployee(employeeNoBanking);
  await ApiClient.patchSmeEmployeeBanking(
    employee.employee_id,
    employee.properties.beneficiary_account_number,
    employee.properties.beneficiary_sort_code,
    employee.properties.beneficiary_type,
  );
};
