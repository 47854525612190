import * as moment from 'moment/moment';

import * as uuid from 'uuid';

import ApiClient from '@/ApiClient';

const DEFINITIVE_SME_MARKER = 'company_registration';
const BASE_FUNDING_LEVEL = 25000; //Put a minimum of £500 in the funding ledger
const FUNDING_PER_ENROLLED = 25000; //How much to put in the ledger per enrolled employee

const defaultState = {
  loading: true,
  user: undefined,
  visitor: getVisitor(),
  claims: undefined,
  company: undefined,
  paySchedule: undefined,
  todos: undefined,
  subscriptions: undefined,
  divisions: undefined,
  route: undefined,
  isSelfServeSME: undefined, // This is actual test for SME
  funding: undefined, // cached
  enrollment: undefined, // cached
  accounting: undefined, // cached
  autoEnroll: undefined,
};

function getVisitor() {
  let visitor_id = window.localStorage.getItem('visitor_id');
  if (!visitor_id) {
    visitor_id = uuid.v4();
    window.localStorage.setItem('visitor_id', visitor_id);
  }
  return { visitor_id: visitor_id };
}

const roles = {
  company_admin: '2cbea343-d131-49ba-bb54-b1790c584dbc',
  employee: '625bda6f-992b-4688-8ee1-601d0b9537cf',
  company_manager: '53e45000-653f-4719-86a8-e4f3c534f7c9',
  infostream_manager: 'a0167e62-a085-4200-8be7-96b678d5dbcf',
  super_admin: '4670ed78-3d66-41cf-bd2b-152f9237f028',
};

function formatDivisionGroupings(divisionGroupingsRaw) {
  // flatten division groupings
  if (divisionGroupingsRaw.properties) {
    divisionGroupingsRaw = { ...divisionGroupingsRaw, ...divisionGroupingsRaw.properties };
    delete divisionGroupingsRaw.properties;
  }
  // rename keys to division_ + original key
  const divisionGroupings = {};
  Object.keys(divisionGroupingsRaw).forEach(key => {
    divisionGroupings['division_' + key] = divisionGroupingsRaw[key];
  });
  return divisionGroupings;
}

const State = {
  state: { ...defaultState },

  logout() {
    this.state = { ...defaultState };
    moment.locale('en');
  },

  async getAccounting() {
    if (this.state.accounting === undefined) {
      const accountingReport = await ApiClient.getAccountingReport();
      this.state.accounting = accountingReport.data[0];
    }
    return this.state.accounting;
  },

  async getEnrollment() {
    if (this.state.enrollment === undefined) {
      const snap = await ApiClient.getEnrolmentSnapshot();
      this.state.enrollment = snap[0] || {
        enrolled: 0,
        enrolling: 0,
        eligible: 0,
        adopted: 0,
        pending: 0,
        active: 0,
      };
    }
    return this.state.enrollment;
  },

  async getFunding() {
    if (this.state.funding === undefined) {
      const snapshot = await this.getEnrollment();
      const companyId = this.state.company.company_id;

      const fundingReport = await ApiClient.companyFundingBalance(companyId);

      let fundingCurrent = fundingReport.modulr;
      const fundingMin = Math.max(BASE_FUNDING_LEVEL, snapshot.active * FUNDING_PER_ENROLLED);
      const fundingRecomendation = Math.max(
        BASE_FUNDING_LEVEL,
        (snapshot.enrolled + snapshot.active + snapshot.enrolling) * FUNDING_PER_ENROLLED,
      );

      if (fundingCurrent === undefined) {
        fundingCurrent = 0;
      }

      // Set up metrics:
      const adoptionRate = Math.floor((snapshot.adopted / snapshot.eligible) * 100);
      const fundsInsufficient = fundingCurrent < fundingMin;

      const isBankingEnabled = this.state.company.properties.modulr;

      this.state.funding = {
        enabled: isBankingEnabled,
        balance: fundingCurrent / 100 || 0,
        base: BASE_FUNDING_LEVEL / 100,
        minimum: fundingMin / 100,
        recommended: fundingRecomendation / 100,
        adoption: adoptionRate,
        sufficient: !fundsInsufficient,
      };
    }
    return this.state.funding;
  },

  async getAutoEnroll() {
    if (this.state.autoEnroll === undefined) {
      const { can_auto_enroll } = await ApiClient.smeGetCanAutoEnroll();
      this.state.autoEnroll = can_auto_enroll;
      return this.state.autoEnroll;
    } else {
      return this.state.autoEnroll;
    }
  },

  async setMe(data) {
    const language_code = 'en';
    let divisionGroupings = data.company.properties.division_groupings || {
      name: undefined,
    };
    divisionGroupings = formatDivisionGroupings(divisionGroupings);

    const {
      tableau_url: tableauUrl,
      hide_dashboard_charts: hideDashboardCharts,
      use_extended_permissions: useExtendedPermissions,
      idle_logout_time_in_seconds: idleLogoutTimeInSeconds,
    } = data.company.properties?.portal || {};

    const canAdminAdmins = !useExtendedPermissions || data.admin_roles.find(x => x.role_id === roles.super_admin);

    const safestreamEnabled =
      data.company.properties.app_features && data.company.properties.app_features.savestream_enabled;

    const safestreamPrizeEnabled =
      data.company.properties.app_features &&
      !data.company.properties.app_features.savestream_prize_disabled &&
      data.company.properties.safestream_settings &&
      data.company.properties.safestream_settings.prizes &&
      data.company.properties.safestream_settings.prizes.length > 0;

    const extendedBankingVerificationReport =
      data.company.properties.portal && data.company.properties.portal.show_additional_banking_verification_data;

    this.state = {
      ...this.state, // preserves cached values
      user: data.user,
      claims: data.claims,
      company: data.company,
      paySchedule: data.pay_schedule,
      todos: data.tasks,
      roles: data.roles,
      subscriptions: data.subscriptions,
      divisions: data.divisions,
      credentials: data.credentials,
      canAdminAdmins,
      loading: false,
      language_code,
      divisionGroupings,
      tableauUrl,
      hideDashboardCharts,
      idleLogoutTimeInSeconds,
      safestreamEnabled,
      safestreamPrizeEnabled,
      extendedBankingVerificationReport,
      isSelfServeSME: data.company.properties.Author === DEFINITIVE_SME_MARKER,
    };

    moment.locale(this.state.language_code);
  },
};

export default State;
