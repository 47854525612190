<template>
  <b-spinner v-if="loading" class="ml-3" />
  <b-dropdown v-else :variant="props.displayText ? 'default' : 'primary'" class="export-button ml-3" no-caret>
    <template v-slot:button-content>
      <div v-if="props.displayText">
        Export as
        <font-awesome-icon class="ml-2" :icon="['fas', 'angle-down']" />
      </div>
      <div v-else>
        <font-awesome-icon class="icon" :icon="['fad', 'download']" />
        <font-awesome-icon class="ml-2" :icon="['fas', 'angle-down']" />
      </div>
    </template>
    <slot></slot>
    <b-dropdown-item @click="exportPDF">PDF</b-dropdown-item>
    <b-dropdown-item @click="exportCSV">CSV</b-dropdown-item>
    <b-dropdown-item @click="exportXLSX">XLSX</b-dropdown-item>
  </b-dropdown>
</template>

<script setup>
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ref } from 'vue';
import { utils as xlsxUtils, write as xlsxWrite } from 'xlsx';
import { getPartnershipData } from '@/utils/partnership';

const { name: partnershipName } = getPartnershipData();

const props = defineProps({
  fileName: { type: String, required: true },
  exportFileRows: { type: Function, required: true },
  exportHeaders: { type: Array, required: true },
  exportData: { type: Array, required: false },
  exportDataFunction: { type: Function, required: false },
  displayText: { type: String, required: false, default: '' },
});

const loading = ref(false);

const exportFilename = fileType => {
  if (fileType && fileType === 'excel') {
    return props.fileName.substring(0, 30); // excel filenames cannot be longer than 31 chars
  }

  return props.fileName;
};

const getRows = async () => {
  loading.value = true;
  let data;
  if (props.exportDataFunction) {
    data = await props.exportDataFunction();
  } else {
    data = props.exportData;
  }
  loading.value = false;
  return data.map(props.exportFileRows);
};

const exportCSV = async () => {
  let csvContent = '';

  // add headers
  csvContent += props.exportHeaders.map(header => '"' + header + '"').join(',') + '\n';

  // add fields
  const rows = await getRows();
  csvContent += rows.map(values => values.map(value => '"' + (value || '') + '"').join(',')).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, exportFilename() + '.csv');
};

const exportXLSX = async () => {
  const book = xlsxUtils.book_new();
  book.Props = {
    Title: exportFilename('excel'),
    Author: partnershipName,
    CreatedDate: new Date(),
  };

  const rows = await getRows();
  book.SheetNames.push(exportFilename('excel'));
  let data = [props.exportHeaders];
  data = data.concat(rows);

  const sheet = xlsxUtils.aoa_to_sheet(data);
  book.Sheets[exportFilename('excel')] = sheet;
  const out = xlsxWrite(book, { bookType: 'xlsx', type: 'array' });

  saveAs(new Blob([out], { type: 'text/plain;charset=utf-8' }), exportFilename('excel') + '.xlsx');
};

const exportPDF = async () => {
  const doc = new jsPDF();

  const rows = await getRows();
  autoTable(doc, {
    head: [props.exportHeaders],
    body: rows,
    startY: 10,
  });

  doc.save(`${exportFilename()}.pdf`);
};
</script>

<style scoped lang="scss">
.export-button {
  :deep(ul.dropdown-menu) {
    position: absolute;
    top: 100%;
    left: auto;
    will-change: transform;
    max-width: 100%;
    min-width: 0;
    right: 0;
    margin: 0;

    .dropdown-item {
      padding: 0.75rem 0;
      text-align: center;
    }
  }
}

.loading-spinner {
  height: 3rem;
  width: 3rem;
}
</style>
