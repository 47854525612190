import { getCurrentInstance } from 'vue';

const TOAST_VARIANT = {
  DANGER: 'danger',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export default () => {
  const currentInstance = getCurrentInstance();

  const doToast = (title, text, variant = TOAST_VARIANT.SUCCESS) =>
    currentInstance.proxy.$appToast(text, { title, variant });

  return {
    TOAST_VARIANT,
    doToast,
  };
};
