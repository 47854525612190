<template>
  <header class="page-sub-header">
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      class="page-sub-header__icon"
      color="var(--palette-color-brand-primary)"
    />
    <div class="page-sub-header__container">
      <div class="page-sub-header__titles">
        <component :is="titleTag" class="page-sub-header__title">
          <div @click="onGoBack()">
            <ArrowLeft v-if="props.backRoute" class="back"></ArrowLeft>
          </div>
          {{ title }}
          <slot name="extra-title"></slot>
        </component>
        <p v-if="subtitle" class="page-sub-header__subtitle">{{ subtitle }}</p>
        <p v-else-if="$slots.subtitle" class="page-sub-header__subtitle"><slot name="subtitle"></slot></p>
      </div>

      <aside v-if="$slots.header">
        <nav class="page-sub-header__nav">
          <slot name="header"></slot>
        </nav>
      </aside>
    </div>
  </header>
</template>

<script>
const TITLE_TAGS = ['h1', 'h2', 'h3'];

export default {};
</script>

<script setup>
import ArrowLeft from '@/assets/icons/ArrowLeft.vue';
import { useRouter } from 'vue-router/composables';

const router = useRouter();

const props = defineProps({
  icon: Array,
  title: String,
  subtitle: String,
  titleTag: {
    type: String,
    default: 'h1',
    validator: value => TITLE_TAGS.includes(value),
  },
  backRoute: String,
});

const onGoBack = () => {
  router.push(props.backRoute);
};
</script>

<style lang="scss" scoped>
.page-sub-header {
  margin-bottom: 1.5rem;
}

.page-sub-header__icon {
  font-size: 32px;
  margin: 0.75rem 0;
}

.page-sub-header__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.page-sub-header__titles {
  flex: 1;
  max-width: 100%;
  min-width: 20rem;
}

.page-sub-header__title {
  color: var(--palette-color-default);
  font-size: 24px;
  font-weight: 500;
  margin: 0;

  display: flex;
  align-items: center;

  @at-root {
    h2#{&} {
      font-size: 18px;
    }
  }

  @at-root {
    h3#{&} {
      font-size: 16px;
    }
  }
}

.page-sub-header__subtitle {
  color: var(--palette-color-default-lighten-20);
  font-size: 14px;
  margin: 0.25rem 0 0;
}

.page-sub-header__nav {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.back {
  cursor: pointer;
  margin-right: 1rem;
}
</style>
