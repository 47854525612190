export default {
  install: function (Vue) {
    Vue.prototype.$appToast = function (message, methodOptions) {
      const h = this.$createElement;
      let icon;
      if (!methodOptions.icon) {
        switch (methodOptions.variant) {
          case 'success':
            icon = 'check';
            break;
          case 'danger':
            icon = 'times-circle';
            break;
          case 'warning':
            icon = 'exclamation-triangle';
            break;
          case 'primary':
          case 'secondary':
          case 'info':
          default:
            icon = 'info-circle';
            break;
        }
      }

      this.$root.$bvToast.toast(message, {
        ...methodOptions,
        title: h('div', [
          h('font-awesome-icon', {
            class: { 'mr-3': true },
            props: { icon: ['fal', icon], size: 'lg' },
          }),
          methodOptions.title,
        ]),
        appendToast: true,
        autoHideDelay: 5000,
      });
    };
  },
};
