<template>
  <app-login-box>
    <template v-slot:default>
      <ValidationObserver v-slot="v">
        <form @submit.prevent="v.handleSubmit(onSubmit)" class="container">
          <sme-alert v-if="success" level="good" class="mb-4">
            We have emailed a password reset to {{ sent_email }}
          </sme-alert>
          <b-row>
            <b-col>
              <div class="form-group">
                <label for="email" class="form-label">Enter your email to reset password</label>
                <ValidationProvider name="Email" rules="required|email">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="email"
                    placeholder="Enter email address"
                  />
                </ValidationProvider>
              </div>
              <button type="submit" class="btn btn-primary float-right waves-effect waves-themed" :disabled="v.invalid">
                Reset
              </button>
              <app-loading :loading="loading" />
            </b-col>
          </b-row>
          <sme-alert v-if="error" level="danger" class="mt-4">{{ error }}</sme-alert>
        </form>
      </ValidationObserver>
    </template>
    <template v-slot:footer>
      <router-link :to="{ name: 'login' }">
        <strong>Return to Login</strong>
      </router-link>
      |
      <a href="#" @click.prevent="contactSupport">
        <strong>Contact Support</strong>
      </a>
    </template>
  </app-login-box>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import AppLoginBox from '@/components/AppLoginBox.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { contactSupport } from '@/utils/support';

export default {
  name: 'Login',
  components: {
    ValidationObserver,
    ValidationProvider,
    AppLoading,
    AppLoginBox,
    SmeAlert,
  },
  data: function () {
    return {
      email: '',
      sent_email: '',
      error: undefined,
      loading: false,
      success: false,
    };
  },
  methods: {
    contactSupport,
    onSubmit() {
      this.error = undefined;
      this.loading = true;
      this.success = false;
      ApiClient.forgotPassword(this.email).then(
        () => {
          this.success = true;
          this.loading = false;
          this.sent_email = this.email;
        },
        error => {
          this.loading = false;
          this.error = error.message;
        },
      );
    },
  },
};
</script>
