const defaultState = {
  loading: true,
  availableFilters: undefined,
  filtersEmployees: undefined,
  employees: undefined,
};

const state = () => ({
  ...defaultState,
});

// mutations
const mutations = {
  setFilters(state, data) {
    state.availableFilters = data.available_filters;
    state.filtersEmployees = data.filters_employees;
    state.employees = data.employees;
    state.loading = false;
  },
  logout(state) {
    // eslint-disable-next-line
    state = { ...defaultState };
  },
  setLoading(state) {
    state.loading = true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
