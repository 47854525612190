import { render, staticRenderFns } from "./RaiseAuthPage.vue?vue&type=template&id=8b857510&scoped=true&"
import script from "./RaiseAuthPage.vue?vue&type=script&lang=js&"
export * from "./RaiseAuthPage.vue?vue&type=script&lang=js&"
import style0 from "./RaiseAuthPage.vue?vue&type=style&index=0&id=8b857510&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b857510",
  null
  
)

export default component.exports