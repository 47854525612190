import State from '@/state/State';
import ApiClient from '@/ApiClient';
import useLaunchState from '@/state/composables/useLaunchState';
import useFeatureFlags from '@/composables/useFeatureFlags';

export default function useAnalytics() {
  const { isYetToLaunch, getLaunchState } = useLaunchState();
  const { isSme } = useFeatureFlags();

  const postAnalyticsEvent = async params => {
    if (!State.state.claims || window.location.pathname.includes('register')) {
      ApiClient.postAnalytics('registration', params);
    } else {
      if (isSme.value) {
        // Post analytics event based on launch state for SMEs
        getLaunchState(true).then(() => {
          if (isYetToLaunch.value) {
            ApiClient.postAnalytics('onboarding', params);
          } else {
            ApiClient.postAnalytics('bau', params);
          }
        });
      } else {
        // Do nothing for enterprise businesses for now
        return;
      }
    }
  };

  return { postAnalyticsEvent };
}
