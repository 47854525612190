<template>
  <LineChartGenerator
    v-if="chartData"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import ApiClient from '@/ApiClient.js';
import { PaletteColors } from '@/Theme';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  annotationPlugin,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
);

export default {
  name: 'AdoptionChart',
  components: {
    LineChartGenerator,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 450,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: this.getChartOptions(),
      chartData: null,
    };
  },
  async created() {
    this.chartData = await this.getDatasets();
    const pointsToCompare = [
      this.chartData.datasets[1].data[this.chartData.datasets[0].data.length - 1],
      this.chartData.datasets[0].data[this.chartData.datasets[0].data.length - 1],
    ];
    this.$emit('onload', pointsToCompare);
  },
  methods: {
    getChartOptions() {
      return {
        devicePixelRatio: 3,
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 100,
        animation: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: 80,
                yMax: 80,
                borderColor: PaletteColors['brand-primary-default-5'],
                borderWidth: 2,
              },
            },
          },
          legend: {
            position: 'top',
            labels: {
              boxWidth: 20,
              boxHeight: 1,
            },
          },
          tooltip: {
            callbacks: {
              label: function (ctx) {
                let label = ctx.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (ctx.parsed.y !== null) {
                  label += ctx.parsed.y + '%';
                }
                return label;
              },
              title: function (ctx) {
                return ctx[0].label + ' days';
              },
            },
          },
        },
        scales: {
          x: {
            type: 'linear',
            min: 0,
            max: 30,
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 5,
            },
            title: {
              display: true,
              text: 'Days Since Launch',
            },
          },
          y: {
            min: 0,
            max: 100,
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Adoption (%)',
            },
          },
        },
      };
    },
    async getAllData() {
      const rawData = await ApiClient.getAdoptionData(this.company.company_id);

      if (rawData.error) {
        this.error = rawData.error.message;
      }
      // in case data isn't given already sorted for some reason
      const rawDataSorted = rawData.sort((a, b) => a.DAY_OFFSET - b.DAY_OFFSET);
      return rawDataSorted;
    },
    async getDatasets() {
      const chartSeries = await this.getChartSeries();
      return {
        datasets: [
          {
            label: this.company.name,
            borderColor: PaletteColors['core-4'],
            borderWidth: 5,
            data: chartSeries['TARGET'],
            pointRadius: ctx => {
              // makes it so just the last point on the company line is enlarged
              const pointsLength = ctx.chart.data.datasets[0].data.length - 1;
              const pointsArray = [];
              for (let i = 0; i <= pointsLength; i++) {
                if (i === pointsLength) {
                  pointsArray.push(3);
                } else {
                  pointsArray.push(0);
                }
              }
              return pointsArray;
            },
          },
          {
            label: 'Benchmark',
            borderColor: PaletteColors['default-lighten-20'],
            borderWidth: 4,
            data: chartSeries['UPPER'],
            borderDash: [5, 3],
          },
        ],
      };
    },
    async getChartSeries() {
      const chartSeries = {};
      const rawChartData = await this.getAllData();

      rawChartData.forEach(function (daySnapshot) {
        const x = daySnapshot.day_offset;
        const y = (daySnapshot.adopted_pc * 100).toFixed(1);
        const point = { x, y };
        chartSeries[daySnapshot.name] = chartSeries[daySnapshot.name] || [{ x: 0, y: 0 }];
        chartSeries[daySnapshot.name].push(point);
      });

      return chartSeries;
    },
  },
};
</script>
