<template>
  <div class="job-info" v-if="props.shortlist">
    <div class="header">
      <div class="text-heading">
        <div>{{ props.shortlist.posting.role }}</div>
        <b-button v-if="canCloseJob" variant="light" v-b-modal="'close-job-modal'">Close this job</b-button>
        <b-button v-if="canMarkJobAsDone" variant="outline-success" v-b-modal="'mark-job-done-modal'"
          ><CheckCircle width="16" height="16"></CheckCircle> Mark as done</b-button
        >
      </div>
      <div class="info">
        <Pound :color="PaletteColors['default-lighten-30']" width="20" height="20"></Pound>
        {{ props.shortlist.posting.pay.amount }}
        {{ props.shortlist.posting.pay.frequency }}
      </div>
      <div class="info" v-if="props.shortlist.posting.location.name">
        <MapPin :color="PaletteColors['default-lighten-30']" width="20" height="20"></MapPin>
        {{ props.shortlist.posting.location.name }}
      </div>
      <div class="date" v-if="props.shortlist.posting.time.date">
        <Calendar width="20" height="20" />
        <span>{{ formatDate(props.shortlist.posting.time.date) }}</span>
      </div>
      <div class="time" v-if="props.shortlist.posting.time.time_start && props.shortlist.posting.time.time_end">
        <Clock width="20" height="20"></Clock>
        <span
          >{{ formatTimeRange(props.shortlist.posting.time.time_start, props.shortlist.posting.time.time_end) }}
        </span>
      </div>
      <!-- <div v-if="props.shift?.assigned_to && props.shift?.winning_claim_employee_name" class="assigned-employee">
      <EmployeeAvatar :size="20" :imageUrl="props.shift?.winning_claim_employee_photo_url" />
      <span>{{ props.shift?.winning_claim_employee_name }}</span>
    </div> -->

      <b-button
        v-if="props.shortlist.posting.description"
        @click="showFullDescription = !showFullDescription"
        class="info see-more"
      >
        <MinusCircle v-if="showFullDescription" :color="PaletteColors['brand-primary']" width="20" height="20" />
        <PlusCircle v-else :color="PaletteColors['brand-primary']" width="20" height="20" />
        {{ showFullDescription ? 'Hide description' : 'Show description' }}
      </b-button>
      <div v-if="props.shortlist.posting.description && showFullDescription" class="description">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="props.shortlist.posting.description.html" v-html="props.shortlist.posting.description.html"></div>
        <div class="info" v-else>{{ props.shortlist.posting.description.text?.replace('\n\n', '\n') }}</div>
      </div>
    </div>
    <Dialog
      modalId="close-job-modal"
      size="lg"
      title="Are you sure you want to close this posting?"
      okBtnLabel="Close this posting"
      @on-ok="onCloseJob"
    >
      <template #content>
        <div>
          <p>This posting is already hidden from potential candidates.</p>
          <p>
            Unlock this job to see the full profile of the best matched talent we have found for you. You will be able
            to see their full work history and contact details.
          </p>
          <p>If you havent found a suitable candidate, we will mark it as closed.</p>
        </div>
      </template>
    </Dialog>
    <Dialog
      modalId="mark-job-done-modal"
      size="lg"
      title="Are you sure you want to mark this posting as done?"
      okBtnLabel="Mark as done"
      @on-ok="onCloseJob"
    >
      <template #content>
        <div>
          <p>We are glad to help you find the best available talent for your company.</p>
          <p>Mark this job posting as done to indicate you have finished hiring for this role through Raise.</p>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import MapPin from '@/assets/icons/MapPin.vue';
import Pound from '@/assets/icons/Pound.vue';
import { ref, computed } from 'vue';
import { PaletteColors } from '@/Theme';
import PlusCircle from '@/assets/icons/PlusCircle.vue';
import MinusCircle from '@/assets/icons/MinusCircle.vue';
import CheckCircle from '@/assets/icons/CheckCircle.vue';
import { isShortlistLive, isShortlistUnlocked } from '@/pages/talent-pool/utils/shortlists';
import Dialog from '@/components/Dialog.vue';
import { useMutation } from '@tanstack/vue-query';
import { closeJobPosting } from '@/queries/talent-pool/jobs.js';

const props = defineProps({
  shortlist: Object,
});

const showFullDescription = ref(false);
const closeJob = useMutation(closeJobPosting());

const canCloseJob = computed(() => {
  return !isShortlistUnlocked(props.shortlist) && !isShortlistLive(props.shortlist);
});

const canMarkJobAsDone = computed(() => {
  return !isShortlistLive(props.shortlist) && isShortlistUnlocked(props.shortlist);
});

const onCloseJob = () => {
  closeJob.mutate({
    jobId: props.shortlist.talent_pool_posting_id,
  });
};
</script>

<style scoped lang="scss">
.job-info {
  display: flex;
  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  width: 100%;
  background-color: var(--palette-color-base-white);
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-heading {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.info {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0.25rem 0;
  gap: 0.5rem;
  align-items: center;
  white-space: pre-wrap;
}

.info-html {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.description {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  white-space: pre-line;
  overflow-y: auto;
  max-height: 15rem;
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.info.see-more {
  color: var(--palette-color-brand-primary);
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  width: fit-content;
  &:hover {
    font-weight: 600;
    background-color: transparent;
    color: var(--palette-color-brand-primary);
  }
  &:focus,
  &:active {
    background-color: transparent !important;
    color: var(--palette-color-brand-primary) !important;
    box-shadow: 0 0 0 0 !important;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 50%;

  button {
    width: fit-content;
    align-self: flex-end;
  }
}

.sub-text {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
}
</style>
