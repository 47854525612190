<template>
  <div :class="['sme-alert', `sme-alert--${level}`, flush && 'sme-alert--flush', large && 'sme-alert--large']">
    <div class="sme-alert__content">
      <font-awesome-icon :class="`sme-alert__icon sme-alert__icon--${level}`" :icon="['fas', icon]" />
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="html" v-html="html"></div>
      <!-- eslint-enable vue/no-v-html -->
      <div v-else class="sme-alert__slot-content">
        <slot></slot>
      </div>
    </div>
    <template v-if="$slots.footer">
      <footer class="sme-alert__footer">
        <slot name="footer"></slot>
      </footer>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export const ALERT_LEVELS = {
  DANGER: 'danger',
  INFO: 'info',
  WARNING: 'warning',
  GOOD: 'good',
};

const ALERT_LEVEL_ICONS = {
  [ALERT_LEVELS.DANGER]: 'circle-exclamation',
  [ALERT_LEVELS.INFO]: 'circle-info',
  [ALERT_LEVELS.WARNING]: 'circle-exclamation',
  [ALERT_LEVELS.GOOD]: 'circle-check',
};

export default {};
</script>

<script setup>
const props = defineProps({
  html: String,
  level: {
    type: String,
    required: true,
    validator: level => Object.values(ALERT_LEVELS).includes(level),
  },
  flush: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
});

const icon = computed(() => ALERT_LEVEL_ICONS[props.level]);
</script>

<style lang="scss" scoped>
@use 'sass:map';

$levels: (
  danger: (
    background: var(--palette-color-danger-lighten-90),
    color: var(--palette-color-danger),
  ),
  info: (
    background: var(--palette-color-core-1-lighten-90),
    color: var(--palette-color-brand-primary),
  ),
  warning: (
    background: var(--palette-color-warning-lighten-90),
    color: var(--palette-color-warning),
  ),
  good: (
    background: var(--palette-color-success-lighten-90),
    color: var(--palette-color-success),
  ),
);

.sme-alert {
  padding: 0.75rem;
  border-radius: 5px;
}

.sme-alert--flush {
  border-radius: 0;
}

.sme-alert--large {
  padding: 1rem 0.75rem;

  .sme-alert__content {
    align-items: flex-start;
  }
}

.sme-alert:not(.sme-alert--large) {
  .sme-alert__slot-content {
    :deep(> p, > ul) {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@each $level, $attributes in $levels {
  .sme-alert--#{$level} {
    background-color: map.get($attributes, 'background');
  }

  .sme-alert--#{$level},
  .sme-alert__icon--#{$level} {
    color: map.get($attributes, 'color');
  }
}

.sme-alert__content {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.sme-alert__icon {
  font-size: 20px;
  margin-right: 0.8rem;
}

.sme-alert__slot-content {
  flex: 1;
}
</style>
