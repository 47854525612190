<template>
  <div
    @mouseover="emit('mouseover')"
    @mouseleave="emit('mouseleave')"
    @click="selectClaim"
    :class="['card', { selected: isSelected }]"
  >
    <header>
      <EmployeeCompanyLogos :company-logos="companyLogos" />
      <div class="selection-container" v-if="props.claim.claim_id">
        <span>{{ isSelected ? 'Selected' : 'Select' }}</span>
        <div class="radio-box">
          <svg class="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="i_Check">
              <path
                id="Vector"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.2071 3.79289C14.5976 4.18342 14.5976 4.81658 14.2071 5.20711L7.20711 12.2071C6.81658 12.5976 6.18342 12.5976 5.79289 12.2071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289C2.68342 6.90237 3.31658 6.90237 3.70711 7.29289L6.5 10.0858L12.7929 3.79289C13.1834 3.40237 13.8166 3.40237 14.2071 3.79289Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </header>
    <div class="card-content">
      <span class="name">{{ formatWorkerName(props.claim.worker_name) }} </span>
      <div class="info">
        <div>
          <div v-if="props.claim?.worker_tagline" class="tagline">{{ props.claim?.worker_tagline }}</div>
          <div class="active-search" v-if="props.activelyLooking">Actively looking for work</div>
        </div>
        <div class="ratings">
          <RaiseRating class="stat-cont" :rating="getInternalRating(props.claim)" explainer="Raise score"></RaiseRating>
          <div class="divider" v-if="props.claim.raise_rating_avg"></div>
          <UserRatingDisplay
            class="stat-cont"
            :rating="props.claim.raise_rating_avg"
            v-if="props.claim.raise_rating_avg"
            explainer="Reviews"
          />
        </div>
      </div>
      <div class="stats">
        <div class="explainer">
          <span class="stat">{{ hoursInRoleText }}</span>
          as {{ props.claim.role }}
        </div>
        <div class="explainer">
          <VerifiedWagestream :color="PaletteColors['brand-primary']" :width="16" :height="16"></VerifiedWagestream>
          <span class="stat">{{ hoursInFunctionText }}</span>
          as {{ props.claim.function }}
        </div>
      </div>
      <b-button
        v-b-modal="'candidate_profile_' + props.claim.worker_id"
        @click.prevent="selectClaim"
        class="profile-button"
        :variant="props.showFullProfile ? 'primary' : 'outline-primary'"
        @click="trackProfileView"
      >
        <UserCircle :color="props.showFullProfile ? PaletteColors['base-white'] : PaletteColors['brand-primary']" />
        View {{ props.showFullProfile ? '' : 'free' }} profile</b-button
      >
    </div>
    <CandidateProfileModal
      :claim="props.claim"
      :modal-id="'candidate_profile_' + props.claim.worker_id"
      :full-profile="props.showFullProfile"
    />
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import EmployeeCompanyLogos from '@/pages/talent-pool/components/EmployeeCompanyLogos.vue';
import UserRatingDisplay from '@/pages/talent-pool/components/UserRatingDisplay.vue';
import CandidateProfileModal from '@/pages/talent-pool/components/CandidateProfileModal.vue';
import {
  getCompanyLogosFromRoles,
  getAllShiftsWorkedTimeSpan,
  formatWorkerName,
  getInternalRating,
} from '@/pages/talent-pool/utils/profile';
import UserCircle from '@/assets/icons/UserCircle.vue';
import RaiseRating from '@/pages/talent-pool/components/RaiseRating.vue';
import VerifiedWagestream from '@/assets/icons/VerifiedWagestream.vue';
import { PaletteColors } from '@/Theme';

const props = defineProps({
  claim: {
    type: Object,
    required: true,
  },
  selected: Boolean,
  roles: { type: String },
  shift: { type: Object },
  activelyLooking: Boolean,
  showFullProfile: Boolean,
});

const emit = defineEmits(['claimSelected', 'mouseover', 'mouseleave', 'profileOpened']);

const isSelected = computed(() => props.selected);

const hoursInRoleText = computed(() => {
  const relevantRoles = props.claim.roles.filter(role => role.is_role_match);
  const daysInRole = relevantRoles.reduce((acc, role) => acc + role.days_in_role, 0);
  const hoursInRole = relevantRoles.reduce((acc, role) => acc + role.hours_in_role, 0);
  return getAllShiftsWorkedTimeSpan(daysInRole, hoursInRole);
});

const hoursInFunctionText = computed(() => {
  const relevantRoles = props.claim.roles.filter(role => role.is_function_match);
  const daysInRole = relevantRoles.reduce((acc, role) => acc + role.days_in_role, 0);
  const hoursInRole = relevantRoles.reduce((acc, role) => acc + role.hours_in_role, 0);
  return getAllShiftsWorkedTimeSpan(daysInRole, hoursInRole);
});

const companyLogos = computed(() => {
  const logos = getCompanyLogosFromRoles(props.claim?.roles);
  const paddedLogos = [...logos];
  while (paddedLogos.length < 3) {
    paddedLogos.push('');
  }
  return paddedLogos;
});

const trackProfileView = () => {
  emit('profileOpened');
};

const selectClaim = () => {
  emit('claimSelected');
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 1px 0px rgba(34, 40, 47, 0.05), 0px 0px 3px 0px rgba(34, 40, 47, 0.1);
  background-color: var(--palette-color-base-white);
  overflow: hidden;
  padding: 0.9rem;
  border: 1px solid var(--palette-color-default-lighten-90);
  width: 300px;
}

.card.selected {
  border: 2px solid var(--palette-color-brand-primary);
}

.selection-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  color: var(--palette-color-default-lighten-30);
}

.card.selected .selection-container {
  color: var(--palette-color-brand-primary);
}

.stat-cont {
  width: 50%;
}

.stat-line {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.profile-button {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  &:hover {
    svg {
      fill: var(--palette-color-base-white);
    }
  }
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.card-content {
  .info {
    height: 103px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0.25rem;
  }

  .name {
    font-size: 18px;
    font-weight: 500;
  }

  .location {
    color: var(--palette-color-default-lighten-20);
    margin-top: 0.25rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  .past-roles {
    padding-top: 8px;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .stats {
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;

    .stat {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--palette-color-default);
    }
  }
}

.card-content button {
  margin-bottom: 0;
}

.divider {
  width: 0;
  margin: 5px 10px;
  border: 1px solid var(--palette-color-default-lighten-90);
}

.explainer {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.radio-box {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid var(--palette-color-default-lighten-60);
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-box .icon {
  display: none;
  color: var(--palette-color-base-white);
}

.card.selected .radio-box .icon {
  display: block;
  color: var(--palette-color-base-white);
}

.card.selected .radio-box {
  border: 1px solid var(--palette-color-brand-primary);
  background-color: var(--palette-color-brand-primary);
}

.rating .icon {
  color: var(--palette-color-warning);
}

.active-search {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--palette-color-brand-primary);
}

.tagline {
  color: var(--palette-color-default-lighten-20);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ratings {
  display: flex;
  margin-top: 0.75rem;
}
</style>
