<template>
  <page-tabs :items="analyticsPageItems" />
</template>

<script setup>
import { computed } from 'vue';
import PageTabs from '@/components/PageTabs.vue';
import State from '@/state/State';

const analyticsPageItems = computed(() => [
  {
    icon: 'chart-simple',
    name: 'Summary',
    to: 'analytics-homepage',
    visible: true,
  },
  {
    icon: 'mobile-screen-button',
    name: 'App',
    to: 'analytics-app',
    visible: true,
  },
  {
    icon: 'calendar-alt',
    name: 'Shifts',
    to: 'analytics-shifts',
    visible: false,
  },
  {
    icon: 'calendar-days',
    name: 'Bank Pay',
    to: 'analytics-bank-pay',
    visible: State.state.company?.properties?.weekly_pay_settings?.enabled || false,
  },
  {
    icon: 'down-left-and-up-right-to-center',
    name: 'Compare',
    to: 'analytics-compare',
    visible: State.state.claims?.a || (State.state.claims?.m && State.state.divisions?.length > 1) || false,
  },
]);
</script>
