<template>
  <div class="sign-off">
    <div :class="['signing-container', ['can-start', 'today'].includes(signingStatus) && 'active']">
      <div class="header-with-icon">
        <ShiftCheckbox :checked="!['can-start', 'today', 'filled'].includes(signingStatus)" />
        <div class="left-header">
          <span class="sub-text">Clock in</span>
          <span class="header-text"
            >{{
              ['can-start', 'today'].includes(signingStatus)
                ? `Start ${firstName}'s shift`
                : signingStatus === 'filled'
                ? `Come back later to start ${firstName}'s shift`
                : `${firstName}'s
            shift has started`
            }}
          </span>
        </div>
      </div>
      <div class="right-header" v-if="['can-start', 'today', 'filled'].includes(signingStatus)">
        <b-button
          variant="outline-warning"
          v-b-modal="'noShowModal'"
          :disabled="['filled', 'today'].includes(signingStatus)"
        >
          <WarningTriangleFilled width="16px" height="16px" :color="PaletteColors['warning']" /> {{ firstName }} did not
          show up
        </b-button>
        <b-button variant="primary" v-b-modal="'clockInModal'" :disabled="!canClockIn(props.shift)">
          <CheckCircleFilled width="16px" height="16px" :color="PaletteColors['base-white']"></CheckCircleFilled> Clock
          in
        </b-button>
      </div>
      <div class="right-header" v-else-if="!props.shift.winning_claim_clocked_in_at && signingStatus === 'in-progress'">
        <b-button variant="outline-warning" v-b-modal="'noShowModal'">
          <WarningTriangleFilled width="16px" height="16px" :color="PaletteColors['warning']" /> {{ firstName }} did not
          show up</b-button
        >
      </div>
      <div class="right-header" v-else>
        <b-button variant="outline-success" disabled> Clocked-in</b-button>
      </div>
    </div>
    <div class="reminder-container" v-if="signingStatus === 'can-start'">
      <img class="signing-png" :src="signingPng" />

      <div class="reminder-right signing-flow">
        <div>Phone</div>
        <span class="header-text">Or scan their QR code with your phone</span>
        <span class="sub-text"
          >Reminder, inviting 'Signers' to your team will also let them clock workers in and out using this method.
        </span>
        <b-button variant="outline-default" @click="inviteCollegues">
          <Users width="16px" height="16px"></Users> Invite colleagues
          <ArrowRight width="16px" height="16px"></ArrowRight>
        </b-button>
      </div>
    </div>
    <div
      v-if="signingStatus === 'in-progress'"
      :class="['signing-container', signingStatus === 'in-progress' && 'active', 'in-progress']"
    >
      <div class="vertical-progress-bar-wrapper">
        <div :class="['vertical-progress-bar', { complete: percentage === 100 }]" :style="`height: ${percentage}%`" />
      </div>
      <div class="left-header">
        <span class="sub-text">
          {{ formatTime(props.shift?.claim_properties?.actual_start_at || props.shift.start_at) }}
        </span>
        <span class="sub-text text-bold">{{ formatTimeRemaining(props.shift.end_at) }} remaining </span>
        <span class="sub-text">
          {{ formatTime(props.shift.end_at) }}
        </span>
      </div>
    </div>
    <div :class="['signing-container', signingStatus === 'can-finalise' && 'active']">
      <div class="header-with-icon">
        <ShiftCheckbox :checked="false" />
        <div class="left-header">
          <span class="sub-text">Sign-off {{ isFuture(props.shift.end_at) ? 'early' : '' }}</span>
          <span class="header-text">End {{ firstName }}'s shift</span>
        </div>
      </div>
      <b-button
        v-b-modal="'signOffModal'"
        variant="primary"
        :disabled="!['can-finalise', 'in-progress'].includes(signingStatus)"
      >
        <CheckCircleFilled width="16px" height="16px" :color="PaletteColors['base-white']"> </CheckCircleFilled> Sign
        off {{ isFuture(props.shift.end_at) ? 'early' : '' }}
      </b-button>
    </div>
    <span v-if="signingStatus !== 'can-start'" class="vertical-line" />
    <Modal ref="signOffModal" modalId="signOffModal" title="Sign off shift" size="md" hide-footer>
      <SignOff :redirect-on-success="true"></SignOff>
    </Modal>
    <Modal ref="clockInModal" modalId="clockInModal" title="Clock in" size="md" hide-footer>
      <ClockIn :redirect-on-success="true"></ClockIn>
    </Modal>
    <Dialog
      modalId="noShowModal"
      :title="`Are you sure you want to mark ${firstName} as a no show?`"
      description="This action cannot be undone"
      okBtnLabel="Yes"
      okBtnVariant="danger"
      cancelBtnLabel="No"
      @on-ok="onNoShow"
    />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router/composables';
import { computed, ref, watchEffect } from 'vue';
import { isFuture, isToday, isPast } from 'date-fns';
import { formatTime, formatTimeRemaining, getProgressPercentage } from '@/pages/talent-pool/utils/datetime';
import { canClockIn, canFinalise, isCompleted } from '@/pages/talent-pool/utils/ShiftStatus';
import WarningTriangleFilled from '@/assets/icons/WarningTriangleFilled.vue';
import Users from '@/assets/icons/Users.vue';
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import ArrowRight from '@/assets/icons/ArrowRight.vue';
import { PaletteColors } from '@/Theme';
import signingPng from '@/assets/signing-flow.png';
import SignOff from '@/pages/talent-pool/sign-off/index.vue';
import ShiftCheckbox from '@/pages/talent-pool/shift/components/ShiftCheckbox.vue';
import ClockIn from '@/pages/talent-pool/clock-in/index.vue';
import { useMutation } from '@tanstack/vue-query';
import { noShow } from '@/queries/talent-pool/shifts';
import Modal from '@/components/Modal.vue';
import Dialog from '@/components/Dialog.vue';

const props = defineProps(['shift']);

const route = useRoute();
const router = useRouter();
const locationId = route.params.locationId;

const signOffModal = ref(null);
const clockInModal = ref(null);
const percentage = ref(
  getProgressPercentage(props.shift?.claim_properties?.actual_start_at || props.shift.start_at, props.shift.end_at),
);

const getSigningStatus = () => {
  if (canClockIn(props.shift) && isToday(props.shift.start_at)) {
    return 'can-start';
  }
  if (!props.shift.winning_claim_clocked_in_at && isPast(props.shift.start_at)) {
    return 'in-progress';
  }
  if (props.shift.winning_claim_clocked_in_at && isFuture(props.shift.end_at)) {
    return 'in-progress';
  }
  if (canFinalise(props.shift)) {
    return 'can-finalise';
  }
  if (isCompleted(props.shift)) {
    return 'complete';
  }
  if (isToday(props.shift.start_at)) {
    return 'today';
  }

  return 'filled';
};

const signingStatus = ref(getSigningStatus());

const onNoShowSuccess = () => {
  router
    .push({
      name: 'talent-pool-shift',
      params: { locationId, shiftId: props.shift.shift_id, tab: 'unfilled' },
    })
    .catch(() => {});
};

const postNoShow = useMutation(noShow({ shiftId: props.shift.shift_id, locationId, onSuccess: onNoShowSuccess }));

const onNoShow = () => {
  postNoShow.mutate(props.shift.assigned_to);
};

const inviteCollegues = () => {
  router.push({
    name: 'talent-pool-team',
    params: { locationId },
  });
};

const firstName = computed(() => props.shift?.winning_claim_employee_name.split(' ')[0]);

watchEffect(onInvalidate => {
  const timer = setInterval(() => {
    percentage.value = getProgressPercentage(
      props.shift?.claim_properties?.actual_start_at || props.shift.start_at,
      props.shift.end_at,
    );
  }, 1000);

  onInvalidate(() => {
    clearInterval(timer);
  });
});
</script>

<style scoped lang="scss">
.sign-off {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0;
}

.signing-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  z-index: 1;
}

.active {
  box-shadow: 0px 2px 10px 0px var(--palette-color-default-lighten-80);
  background-color: var(--palette-color-base-white);
  border-radius: 12px;
}

.right-header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.left-header {
  display: flex;
  flex-direction: column;
}

.sub-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--palette-color-default-lighten-30);
}

.header-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.reminder-container {
  display: flex;
  flex: 1 1 0;
  border: 1px solid var(--palette-color-base-white);
  background: rgba(var(--palette-color-base-white), 0.5);
  border-radius: 10px;
}

.reminder-right {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.signing-png {
  width: 50%;
  border-radius: 10px;
  margin: 0.5rem;
}

.signing-flow {
  gap: 0.5rem;
  padding: 0.5rem;
}

.text-bold {
  font-weight: 600;
  margin: 0.5rem 0;
}

.in-progress {
  width: fit-content;
  // calculate left padding to align the progress bar with the line behind
  padding: 1rem 4rem 1rem calc(1rem + 10px);
}

.header-with-icon {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.no-show-text {
  margin: 1rem;
  font-weight: 500;
  font-size: 15px;
}

.vertical-line {
  content: '';
  display: block;
  position: absolute;
  top: 36px;
  left: 29px;
  width: 1px;
  background: var(--palette-color-default-lighten-80);
  z-index: 0;
  // cater for the 36px of gap required above and below
  height: calc(100% - 36px * 2);
}

.vertical-progress-bar-wrapper {
  position: relative;
  background: var(--palette-color-core-1-lighten-60);
  width: 6px;
  flex: 1 0 max-content;
  overflow: hidden;
  border-radius: 999px;

  .vertical-progress-bar {
    position: absoolute;
    top: 0;
    width: 100%;
    border-radius: 999px;

    &:not(.complete) {
      background: var(--palette-color-brand-primary);
    }
    &.complete {
      background: var(--palette-color-success);
    }
  }
}
</style>
