import { render, staticRenderFns } from "./CompanyRoleBadge.vue?vue&type=template&id=c3c1d692&scoped=true&"
import script from "./CompanyRoleBadge.vue?vue&type=script&setup=true&lang=js&"
export * from "./CompanyRoleBadge.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CompanyRoleBadge.vue?vue&type=style&index=0&id=c3c1d692&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3c1d692",
  null
  
)

export default component.exports