<template>
  <section>
    <page-sub-header :title="bankingInfo.stream.label" class="mt-4">
      <template #header>
        <app-input
          v-model="timeSeries"
          :options="TIME_SERIES_VALUES"
          type="radio"
          buttons
          button-variant="outline-primary"
          class="text-uppercase"
          size="sm"
        />
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="timeGroupedStreamersDataset.values.length" class="mb-4">
        <b-row class="align-items-center mb-4">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedStreamersDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { ticks: { precision: 0 }, title: { text: timeGroupedStreamersDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedStreamers"
              :previous-stat="prevGroupedStreamers"
              :formatter="toCompactNumber"
              description="Streamers"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ toCompactNumber(lastGroupedStreamers) }} of your enrolled employees streamed.
              {{ streamersPrevDiffText }}
            </p>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedStreamerPercentageDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { title: { text: timeGroupedStreamerPercentageDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedStreamersPercentage"
              :previous-stat="prevGroupedStreamersPercentage"
              :formatter="toNumber"
              suffix="%"
              description="Streamer Percentage"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ lastGroupedStreamersPercentage }}% of your enrolled employees streamed.
              {{ streamersPercentagePrevDiffText }}
            </p>
          </b-col>
        </b-row>
      </sme-card>

      <b-row class="mb-4">
        <b-col lg="4" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="averageAmountDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :prefix="currencySymbolFromCode(State.state.company.default_currency)"
              :description="`Average ${bankingInfo.stream.label.toLowerCase()} value`"
              :class="[averageAmountDataset.values.length && 'mb-3']"
              background-color="core-5-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="averageAmountDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="averageAmountDataset"
              :chart-options="merge({}, CHART_PRESET_OPTIONS.CURRENCY, CHART_PRESET_OPTIONS.MINIMAL)"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <b-col lg="4" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="transfersDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :description="`Average ${timeSeriesUnit}ly ${bankingInfo.stream.label.toLowerCase()}s`"
              :class="[transfersDataset.values.length && 'mb-3']"
              background-color="core-4-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="transfersDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="transfersDataset"
              :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <b-col lg="4" class="mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="totalAmountDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :prefix="currencySymbolFromCode(State.state.company.default_currency)"
              :description="`Average ${timeSeriesUnit}ly ${bankingInfo.stream.label.toLowerCase()} value total`"
              :class="[totalAmountDataset.values.length && 'mb-3']"
              background-color="core-3-lighten-80"
              color="default"
              small
            />
            <line-chart
              v-if="totalAmountDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="totalAmountDataset"
              :chart-options="merge({}, CHART_PRESET_OPTIONS.CURRENCY, CHART_PRESET_OPTIONS.MINIMAL)"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
      </b-row>

      <sme-card v-if="streamReasonsDatasets?.some(dataset => !!dataset.values.length)">
        <line-chart
          :labels="streamReasonsTimeGroupDataset"
          :datasets="timeGroupedStreamReasonsDataset"
          :chart-options="
            merge(
              {
                aspectRatio: getInitialAspectRatio(3.5, 2),
                scales: { y: { title: { text: 'Percentage (%)' } } },
                plugins: {
                  legend: { display: true, position: 'right' },
                  title: { display: true, text: 'Stream reasons' },
                },
              },
              CHART_PRESET_OPTIONS.PERCENTAGE,
            )
          "
        />
      </sme-card>
    </template>
  </section>
</template>

<script setup>
import merge from 'lodash/merge';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import { PaletteColors } from '@/Theme';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart, { CHART_PRESET_OPTIONS } from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import State from '@/state/State';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES_VALUES, getDatasetColor, getInitialAspectRatio } from '@/utils/chart';
import { toCompactNumber, toNumber, currencySymbolFromCode } from '@/utils/common';

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const timeDataset = ref(null);
const streamersDataset = ref(null);
const streamersPercentageDataset = ref(null);
const totalAmountDataset = ref(null);
const transfersDataset = ref(null);
const averageAmountDataset = ref(null);

const streamReasonsTimeDataset = ref(null);
const streamReasonsDatasets = ref(null);

const { timeGroupDataset, timeSeries, timeGroupTitle, timeSeriesUnit, datasetToTimeGroupedDataset, getPrevDiffText } =
  useTimeGroupDataset(timeDataset);
const {
  timeGroupDataset: streamReasonsTimeGroupDataset,
  datasetToTimeGroupedDataset: streamReasonsDatasetToTimeGroupedDataset,
} = useTimeGroupDataset(streamReasonsTimeDataset, timeSeries);

const timeGroupedStreamersDataset = computed(() => datasetToTimeGroupedDataset(streamersDataset));
const timeGroupedStreamerPercentageDataset = computed(() => datasetToTimeGroupedDataset(streamersPercentageDataset));
const timeGroupedStreamReasonsDataset = computed(() => streamReasonsDatasetToTimeGroupedDataset(streamReasonsDatasets));
const lastGroupedStreamers = computed(() => timeGroupedStreamersDataset.value?.calculated.last);
const prevGroupedStreamers = computed(() => timeGroupedStreamersDataset.value?.calculated.prev);
const lastGroupedStreamersPercentage = computed(() => timeGroupedStreamerPercentageDataset.value?.calculated.last);
const prevGroupedStreamersPercentage = computed(() => timeGroupedStreamerPercentageDataset.value?.calculated.prev);
const streamersPrevDiffText = computed(() => getPrevDiffText(timeGroupedStreamersDataset));
const streamersPercentagePrevDiffText = computed(() =>
  getPrevDiffText(timeGroupedStreamerPercentageDataset, value => `${Number(toNumber(value))}%`),
);
const bankingInfo = computed(() => getBankingInfo());

watch([divisionId, timeSeries], () => getMetrics());

const getStreamMetrics = async () => {
  const { results } = await ApiClient.getStreamMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const streamers = results.find(result => result.id === 'streamers');
    const enrolled = results.find(result => result.id === 'enrolled');
    const totalAmounts = results.find(result => result.id === 'total_amount_streamed');
    const transfers = results.find(result => result.id === 'transfers');
    const averageAmountValues = totalAmounts.values.map((value, index) =>
      value ? value / transfers.values[index] : 0,
    );

    timeDataset.value = time;
    streamersDataset.value = {
      ...streamers,
      name: 'Number of streamers',
    };
    streamersPercentageDataset.value = {
      name: 'Percentage (%)',
      values: enrolled.values.map((value, index) =>
        value ? Number(toNumber((streamers.values[index] / value) * 100)) : 0,
      ),
    };
    totalAmountDataset.value = {
      ...totalAmounts,
      name: 'Total',
      lineColor: PaletteColors['core-3'],
    };
    transfersDataset.value = {
      ...transfers,
      name: 'Streams',
      lineColor: PaletteColors['core-4'],
    };
    averageAmountDataset.value = {
      name: 'Value',
      values: averageAmountValues,
      calculated: { mean: totalAmounts.calculated.mean / transfers.calculated.mean },
      lineColor: PaletteColors['core-5'],
    };
  }
};

const getStreamReasonsMetrics = async () => {
  const { results } = await ApiClient.getStreamReasonsMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const categories = results.filter(result => result.id !== 'time');

    streamReasonsTimeDataset.value = time;
    streamReasonsDatasets.value = categories.map((category, index) => ({
      ...category,
      lineColor: PaletteColors[getDatasetColor(index)],
      values: category.values.map(value => toNumber(value * 100)),
    }));
  }
};

const getMetrics = async () => {
  loading.value = true;

  await Promise.all([getStreamMetrics(), getStreamReasonsMetrics()]);

  loading.value = false;
};

onBeforeMount(() => getMetrics());
</script>
