<template>
  <section>
    <flexible-work-map />
    <page-tabs :items="pageItems" class="mt-4" />
    <flexible-work-schedule v-if="$route.query.view === SHIFTS_VIEW_TYPE.SCHEDULE" />
    <flexible-work-shifts class="mt-4" v-else />
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import PageTabs from '@/components/PageTabs.vue';
import FlexibleWorkMap from '@/pages/flexible-work/components/FlexibleWorkMap.vue';
import FlexibleWorkSchedule from '@/pages/flexible-work/components/FlexibleWorkSchedule.vue';
import FlexibleWorkShifts from '@/pages/flexible-work/components/FlexibleWorkShifts.vue';
import { SHIFTS_VIEW_TYPE, SHIFTS_VIEW_TYPES } from '@/utils/flexible-work';

const $router = useRouter();
const $route = useRoute();

const VIEWS_KEY = 'flexible_work_shifts_default_view';

const pageItems = computed(() => [
  {
    icon: 'calendar-alt',
    name: 'Schedule',
    to: {
      name: 'flexible-work',
      query: { ...$route.query, view: SHIFTS_VIEW_TYPE.SCHEDULE },
    },
    visible: true,
  },
  {
    icon: 'list',
    name: 'List',
    to: {
      name: 'flexible-work',
      query: { ...$route.query, view: SHIFTS_VIEW_TYPE.LIST },
    },
    visible: true,
  },
]);

let storedView = localStorage.getItem(VIEWS_KEY);

if (storedView && !SHIFTS_VIEW_TYPES.includes(storedView)) {
  storedView = null;
}

if (!$route.query.view || !SHIFTS_VIEW_TYPES.includes($route.query.view)) {
  $router.replace({
    name: 'flexible-work',
    query: { ...$route.query, view: storedView || SHIFTS_VIEW_TYPE.SCHEDULE },
  });
} else {
  localStorage.setItem(VIEWS_KEY, $route.query.view);
}
</script>
