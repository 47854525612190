<template>
  <app-page title="My profile" icon="circle-user">
    <page-sub-header title="2 Factor Authentication" title-tag="h2" class="mb-3" />
    <sme-alert v-if="state.user.requires_2fa" level="good" class="my-3"> 2 Factor Authentication Enabled </sme-alert>
    <p v-if="state.user.phone">
      Phone number registered: <code>{{ state.user.phone }}</code
      >. If you wish to change it please contact support.
    </p>
    <p v-else class="mb-0">We have no phone number for you configured yet, please contact support.</p>

    <template v-if="!state.user.requires_2fa && state.user.phone && !activating">
      <b-button variant="primary" @click="activate"> Enable 2 Factor Authentication </b-button>
    </template>

    <template v-if="activating">
      <app-loading :loading="!challenge" />
      <template v-if="challenge">
        <p v-if="state.user.phone">
          We have just sent you a <b>6 digit pin</b> to you phone. Please fill it bellow and submit.
        </p>
        <ValidationObserver v-slot="v">
          <b-form @submit.prevent="v.handleSubmit(onSubmit)">
            <app-input
              v-model="challengePin"
              type="text"
              name="challenge pin"
              placeholder="6 digit pin"
              label="2FA Pin"
              description="Check your phone SMS for a 2FA pin"
              :rules="{ required: true, regex: /^\d{6}$/ }"
              :disabled="challenging"
              :validate-immediate="false"
            />
            <page-sub-footer>
              <template #before>
                <sme-alert v-if="error" level="danger">{{ error }}</sme-alert>
              </template>
              <b-button type="submit" variant="primary" :disabled="v.invalid || challenging">
                <b-spinner v-if="challenging" class="mr-2" small />
                {{ challenging ? 'Submitting...' : 'Submit' }}
              </b-button>
            </page-sub-footer>
          </b-form>
        </ValidationObserver>
      </template>
    </template>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';

export default {
  name: 'Profile',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, PageSubHeader, SmeAlert },
  data() {
    return {
      challenge: undefined,
      challengePin: undefined,
      error: undefined,
      activating: false,
      challenging: false,
      state: State.state,
    };
  },
  methods: {
    activate() {
      this.activating = true;
      ApiClient.createChallenge2fa(this.state.user.user_id).then(
        challenge => {
          this.challenge = challenge;
        },
        error => {
          this.error = error;
        },
      );
    },
    onSubmit() {
      this.challenging = true;
      ApiClient.checkChallenge2fa(this.challenge.mfa_id, this.challengePin).then(
        response => {
          if (response.is_valid) {
            const newUser = {
              user_id: this.state.user.user_id,
              requires_2fa: true,
            };
            ApiClient.patchUser(newUser, this.state.company.company_id).then(
              () => {
                this.$appToast(`You'll need to enter a valid PIN on each login`, {
                  title: 'Enable 2 Factor Authentication',
                  variant: 'success',
                });
                ApiClient.getUser().then(
                  () => {
                    this.activating = false;
                    this.challenging = false;
                  },
                  error => {
                    this.error = error.message;
                    this.challenging = false;
                  },
                );
              },
              error => {
                this.error = error.message;
                this.challenging = false;
              },
            );
          } else {
            this.error = 'Invalid Pin submitted';
            this.challenging = false;
          }
        },
        error => {
          this.challenging = false;
          this.error = error.message;
        },
      );
    },
    async loadData() {
      this.loading = true;
      try {
        const [registration, employee] = await Promise.all([
          ApiClient.getRegistration(this.$route.params.registrationId),
          ApiClient.getRegistrationEmployee(this.$route.params.registrationId),
        ]);
        this.registration = registration.data.registration;
        this.employee = employee.data.registration;
        this.loading = false;
      } catch (error) {
        this.error = error.message;
        this.loading = false;
      }
    },
  },
  mounted() {
    // this.loadData()
  },
};
</script>
