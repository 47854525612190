<template>
  <sub-page
    v-if="hasFailedSalaryEmployees"
    title="Failed Salaries"
    subtitle="We failed to process salaries for some of your employees"
  >
    <header class="d-flex flex-column flex-sm-row align-items-end mb-3">
      <sme-alert :level="ALERT_LEVELS.DANGER" class="mb-3 mb-sm-0">
        We've had the following salaries fail processing and the funds returned to Wagestream. Please review the banking
        details held for all affected employees and re-submit any corrections, as appropriate. To expedite payment,
        please reach out to us via <a href="mailto:client.support@wagestream.com">client.support@wagestream.com</a> with
        the corrected details. We will seek to return any failed payments not sent on within 3 business days.
      </sme-alert>
      <export-button
        :file-name="exportFilename"
        :export-data="failedSalaryEmployees"
        :export-file-rows="exportFileRows"
        :export-headers="EXPORT_HEADERS"
      />
    </header>

    <sme-detailed-table
      :fields="FIELDS"
      :items="failedSalaryEmployees"
      sort-by="full_name"
      tbody-tr-class="sme-detailed-table__row--link"
      sort-asc
      sticky-header
      @row-clicked="item => $router.push({ name: 'employee', params: { employeeId: item.employee_id } })"
    >
      <template #cell(details)="data">
        <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
          <strong class="sme-detailed-table__label sme-detailed-table__label--large">
            {{ data.item.employee_name }}
          </strong>
          <span class="sme-detailed-table__value sme-detailed-table__value--primary">
            {{ data.item.employee_code }}
          </span>
        </div>
      </template>

      <template #cell(rejection_reasons)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Rejection reasons:</span>
          <strong class="sme-detailed-table__value">{{
            toTitleCase(data.item.rejection_reasons.join(' ').replace('-', ' '))
          }}</strong>
        </div>
      </template>

      <template #cell(account_number)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal account:</span>
          <strong class="sme-detailed-table__value">{{ data.item.account_number }}</strong>
        </div>
      </template>

      <template #cell(sort_code)="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">Personal sort code:</span>
          <strong class="sme-detailed-table__value">{{ data.item.sort_code }}</strong>
        </div>
      </template>
    </sme-detailed-table>
  </sub-page>
</template>

<script setup>
import moment from 'moment/moment';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import ExportButton from '@/components/ExportButton.vue';
import SubPage from '@/components/SubPage.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import { toTitleCase } from '@/utils/common';

const $router = useRouter();
const { failedSalaryEmployees, hasFailedSalaryEmployees } = useEmployeesReport();

const FIELDS = [
  {
    key: 'details',
    label: 'Name',
  },
  {
    key: 'rejection_reasons',
    label: 'Rejection reasons',
  },
  {
    key: 'account_number',
    label: 'Personal account number',
  },
  {
    key: 'sort_code',
    label: 'Personal sort code',
  },
];

const EXPORT_HEADERS = [
  'Employee Code',
  'Name',
  'Personal Sort Code',
  'Personal Account No.',
  'Wagestream Sort Code',
  'Wagestream Account No.',
];

const exportFilename = computed(() => {
  const today = moment().format('YYYY-MM-DD');
  let companyName = '';
  if (State.state.company.name) {
    companyName = State.state.company.name.toLowerCase()?.replace(/ /g, '-');
  }

  return `payroll-update-list-${companyName}-${today}`;
});

const exportFileRows = item => {
  return [
    item.employee_code,
    item.full_name,
    item.beneficiary_sort_code,
    item.beneficiary_account_number,
    item.payment_sort_code,
    item.payment_account_number,
  ];
};
</script>
