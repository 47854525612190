<template>
  <section>
    <app-loading :loading="employeesLoading" is-background />

    <template v-if="!employeesLoading">
      <template v-if="employeesWithoutPreferences.length">
        <sme-alert v-if="employeesWithoutPreferences" :level="ALERT_LEVELS.WARNING" class="mt-2">
          The following {{ employeesWithoutPreferences.length }}
          {{ pluralize('employee', employeesWithoutPreferences) }}
          have missing flexible work preferences. This means that they won't be able to access flexible work in the app.
        </sme-alert>
        <employee-issue-list :employees="employeesWithoutPreferences" class="mt-2" />
      </template>
      <sme-alert v-else :level="ALERT_LEVELS.GOOD">
        All of your employees have flexible work preferences set. Well done!
      </sme-alert>
    </template>
  </section>
</template>

<script setup>
import orderBy from 'lodash/orderBy';
import { computed } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import EmployeeIssueList from '@/components/EmployeeIssueList.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import useEmployees from '@/state/composables/useEmployees';
import { pluralize } from '@/utils/common';

const { employees, employeesLoading, getEmployees } = useEmployees();

const employeesWithoutPreferences = computed(() => {
  const employeesWithoutPreferences = employees.value
    .map(employee => {
      const issues = [
        !employee.properties?.flexible_work_preferences?.postcode ? 'Missing postcode' : null,
        !employee.properties?.flexible_work_preferences?.roles?.length ? 'Missing role' : null,
      ].filter(value => value);

      return {
        ...employee,
        ...(issues.length
          ? {
              badge: issues.map(text => ({ text, variant: 'warning' })),
              priority: issues.length,
            }
          : null),
      };
    })
    .filter(employee => !!employee.badge);

  return orderBy(employeesWithoutPreferences, ['priority', 'full_name'], ['desc', 'asc']);
});

getEmployees();
</script>
