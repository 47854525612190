<template>
  <section class="subpage">
    <page-sub-header :title="title" :subtitle="subtitle" title-tag="h2">
      <template #header><slot name="header"></slot></template>
    </page-sub-header>

    <sme-card v-bind="$attrs">
      <slot></slot>
    </sme-card>
  </section>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: String,
});
</script>

<style lang="scss" scoped>
@use 'sass:color';

.subpage {
  margin-top: 0.5rem;

  & + & {
    margin-top: 2rem;
  }
}
</style>
