import { EMPLOYEE_PAY_TYPE } from '@/utils/Employee';

export const employeeHandle = {
  methods: {
    setEmployeeDefaults(employee) {
      employee.properties = employee.properties || {};
      if (!Array.isArray(employee.properties.notes)) {
        employee.properties.notes = [];
      }

      if (!employee.properties.address) {
        employee.properties.address = {};
      }

      employee.salary_properties = employee.salary_properties || {};
      employee.salary_properties.salary_or_hourly =
        employee.salary_properties.salary_or_hourly || EMPLOYEE_PAY_TYPE.HOURLY;
      employee.salary_properties.salary_period = employee.salary_properties.salary_period || 'YEAR';

      const employee_defaults = this.state.company.properties.employee_defaults || {};
      if (
        employee.max_transfer_percent === undefined &&
        employee_defaults.max_transfer_percent &&
        employee_defaults.max_transfer_percent.default
      ) {
        employee.max_transfer_percent = employee_defaults.max_transfer_percent.default;
      }

      if (employee.monthly_transfer_limit === undefined && employee_defaults.monthly_transfer_limit) {
        employee.monthly_transfer_limit = employee_defaults.monthly_transfer_limit;
      }

      return employee;
    },
    ensureNoEmptyStringsInEmployee() {
      if (!this.employee.default_wagerate && this.employee.default_wagerate != 0) {
        this.employee.default_wagerate = undefined;
      }
      if (!this.employee.salary_properties.salary && this.employee.salary_properties.salary != 0) {
        this.employee.salary_properties.salary = undefined;
      }
      if (!this.employee.min_transfer_balance && this.employee.min_transfer_balance != 0) {
        this.employee.min_transfer_balance = undefined;
      }
      if (!this.employee.monthly_transfer_limit && this.employee.monthly_transfer_limit != 0) {
        this.employee.monthly_transfer_limit = undefined;
      }
      if (!this.employee.max_transfers_month && this.employee.max_transfers_month != 0) {
        this.employee.max_transfers_month = undefined;
      }
    },
  },
};
