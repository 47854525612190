import orderBy from 'lodash/orderBy';
import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import { useStateComposable } from '@/state/composables';
import useEmployees from '@/state/composables/useEmployees';
import usePayschedules from '@/state/composables/usePayschedules';

const { employees, getEmployees } = useEmployees();
const { getPayschedules, payschedules } = usePayschedules();

const { computedState, state, reset } = useStateComposable({
  invalidBankDetails: {},
  missedSalaries: {},
  neverPaid: {},
  failedSalaries: {},
  error: false,
  loading: false,
});

const invalidBankDetailsEmployees = computed(() => getSortedReportEmployees(state.invalidBankDetails));
const missedSalaryEmployees = computed(() => getSortedReportEmployees(state.missedSalaries));
const neverPaidEmployees = computed(() => getSortedReportEmployees(state.neverPaid));
const failedSalaryEmployees = computed(() => getSortedReportEmployees(state.failedSalaries));

const hasInvalidBankDetailsEmployees = computed(() => invalidBankDetailsEmployees.value.length > 0);
const hasMissedSalaryEmployees = computed(() => missedSalaryEmployees.value.length > 0);
const hasNeverPaidEmployees = computed(() => neverPaidEmployees.value.length > 0);
const hasFailedSalaryEmployees = computed(() => failedSalaryEmployees.value.length > 0);

const payscheduleReports = computed(() => {
  const payscheduleReports = [];

  payschedules.value.forEach(payschedule => {
    const payscheduleId = payschedule.pay_schedule_id;
    const payscheduleEmployees =
      filterByPayschedule(employees.value, payscheduleId).filter(employee => employee.current_state !== 'DISABLED') ||
      [];

    if (payscheduleEmployees.length > 0) {
      payscheduleReports.push({
        employees: payscheduleEmployees,
        invalidBankDetails: filterByPayschedule(state.invalidBankDetails, payscheduleId),
        missedSalaries: filterByPayschedule(state.missedSalaries, payscheduleId),
        neverPaid: state.neverPaid[payscheduleId] || [],
        failedSalaries: filterByPayschedule(state.failedSalaries, payscheduleId),
        payschedule,
      });
    }
  });

  return orderBy(
    payscheduleReports,
    [
      payschedule => payschedule.missedSalaries.length,
      payschedule => payschedule.invalidBankDetails.length,
      payschedule => payschedule.neverPaid.length,
      payschedule => payschedule.failedSalaries.length,
      payschedule => payschedule.employees.length,
      'payschedule.name',
    ],
    ['desc', 'desc', 'desc', 'desc', 'asc'],
  );
});

const getEmployeesReport = async (
  startDate,
  endDate,
  filter = {
    invalidBankDetails: true,
    missedSalaries: true,
    neverPaid: true,
    failedSalaries: true,
  },
) => {
  reset();

  state.loading = true;

  try {
    getEmployees(true);
    getPayschedules(true);

    const [invalidBankDetails, missedSalaries, neverPaid, failedSalaries] = await Promise.all([
      filter.invalidBankDetails ? getInvalidBankDetails() : {},
      filter.missedSalaries ? getMissedSalaries(startDate, endDate) : {},
      filter.neverPaid ? getNeverPaid() : {},
      filter.failedSalaries ? getFailedSalaries(startDate, endDate) : {},
    ]);

    state.invalidBankDetails = invalidBankDetails;
    state.missedSalaries = missedSalaries;
    state.neverPaid = neverPaid;
    state.failedSalaries = failedSalaries;
  } catch (error) {
    console.error(error);
    state.error = true;
  }

  state.loading = false;
};

const getInvalidBankDetails = async () => (await ApiClient.getInvalidBankDetails())?.data || {};

const getMissedSalaries = async (startDate, endDate) =>
  (await ApiClient.getMissedSalaries({ starts_on: startDate, ends_on: endDate }))?.data || {};

const getNeverPaid = async () => (await ApiClient.smeGetEmployeesWithoutPayments())?.data?.employees || {};

const getFailedSalaries = async (startDate, endDate) =>
  (await ApiClient.getFailedSalaries({ starts_on: startDate, ends_on: endDate }))?.data || {};

const isInvalidBankDetailsEmployee = employeeId =>
  invalidBankDetailsEmployees.value.find(employee => employee.employee_id === employeeId);
const isMissedSalaryEmployee = employeeId =>
  missedSalaryEmployees.value.find(employee => employee.employee_id === employeeId);
const isNeverPaidEmployee = employeeId =>
  neverPaidEmployees.value.find(employee => employee.employee_id === employeeId);
const isFailedSalaryEmployee = employeeId =>
  failedSalaryEmployees.value.find(employee => employee.employee_id === employeeId);

const filterByPayschedule = (employees, payscheduleId) => {
  try {
    return employees.filter(employee => employee.pay_schedule_id === payscheduleId) || [];
  } catch {
    return [];
  }
};

const getSortedReportEmployees = reportEmployees => orderBy(Object.values(reportEmployees).flat(), 'full_name', 'asc');

export default () => {
  return {
    ...computedState,
    payscheduleReports,
    invalidBankDetailsEmployees,
    missedSalaryEmployees,
    neverPaidEmployees,
    failedSalaryEmployees,
    hasInvalidBankDetailsEmployees,
    hasMissedSalaryEmployees,
    hasNeverPaidEmployees,
    hasFailedSalaryEmployees,
    isInvalidBankDetailsEmployee,
    isMissedSalaryEmployee,
    isNeverPaidEmployee,
    isFailedSalaryEmployee,
    getEmployeesReport,
  };
};
