<template>
  <app-page
    :title="`Disable ${employee ? employee.full_name : 'Employee'}`"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="user-minus"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning" class="mb-3">
        You are about to remove access to the app for {{ employee ? employee.full_name : 'Employee' }}
      </sme-alert>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="reason"
            type="text"
            name="reason"
            rules="required"
            label="Reason"
            :disabled="saving || saved"
            :validate-immediate="false"
          />
          <page-sub-footer>
            <b-button type="submit" variant="danger" :disabled="v.invalid || saving || saved">
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Disabling...' : 'Disable' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <sme-alert v-if="saved && !optedOut" level="good">
      When the employee has zero outstanding streamed wages you will be required to update their banking details to
      complete the opt-out process.
    </sme-alert>
    <sme-alert v-if="saved && optedOut" level="good">
      Employee has been deactivated and no longer has access to the app.
    </sme-alert>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { employeeBadges } from '@/mixins/EmployeeBadges.js';
import State from '@/state/State';

export default {
  name: 'EmployeeDeactivate',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  mixins: [employeeBadges],
  data() {
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
      reason: undefined,
      state: State.state,
      optedOut: false,
    };
  },
  methods: {
    onSubmit() {
      this.saving = true;

      if (this.isEmployeeEnrolled(this.employee)) {
        ApiClient.employeeDeactivate(this.employee.employee_id, this.reason).then(
          () => {
            this.saving = false;
            this.saved = true;
          },
          error => {
            this.error = error.message;
            this.loading = false;
          },
        );
      } else {
        ApiClient.employeeOptOut(this.employee.employee_id, { reason: 'Opted out before enrollment' }).then(
          optOut => {
            if (optOut.optout) {
              ApiClient.employeeOptOutComplete(this.employee.employee_id).then(
                () => {
                  this.optedOut = true;
                  this.saving = false;
                  this.saved = true;
                },
                error => {
                  this.error = error.message;
                  this.saving = false;
                },
              );
            } else {
              this.error = 'Unable to deactivate employee at this time';
              this.saving = false;
            }
          },
          error => {
            this.error = error.message;
            this.saving = false;
          },
        );
      }
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
