<template>
  <div>
    <div class="meta-header">
      <div>
        <h1 class="name">{{ props.entity?.name }}</h1>
        <div class="address">{{ addressText }}</div>
      </div>
      <b-button v-b-modal="props.modalId" variant="outline-primary" class="edit-button">
        <Edit height="16px" width="16px" :color="PaletteColors['brand-primary']"></Edit>
        Edit {{ props.entityType }}
      </b-button>
    </div>
    <p class="description">
      {{ props.entity.description }}
    </p>
  </div>
</template>

<script setup>
import Edit from '@/assets/icons/Edit.vue';
import { computed } from 'vue';
import { PaletteColors } from '@/Theme';

const props = defineProps({ modalId: { required: true }, entityType: { required: true }, entity: { required: true } });

const addressText = computed(() => {
  return (
    (props.entity.address?.value?.address_line_1 || '') +
    (props.entity.address?.value?.city ? `, ${props.entity.address.value.city}` : '') +
    (props.entity.address?.value?.postcode ? `, ${props.entity.address.value.postcode}` : '')
  );
});
</script>

<style scoped>
.name {
  font-size: 22px;
  font-weight: 700;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-top: 0.5rem;
  margin-block-end: 0;
}

.address {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default);
  text-transform: capitalize;
}

.meta-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    svg {
      fill: var(--palette-color-base-white) !important;
    }
  }
}
</style>
