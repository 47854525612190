import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=48a95e79&scoped=true&"
import script from "./Dialog.vue?vue&type=script&setup=true&lang=js&"
export * from "./Dialog.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=48a95e79&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a95e79",
  null
  
)

export default component.exports