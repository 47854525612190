export let PaletteColors = {};

let themeSubPath = '';

export const initTheme = async themeId => {
  themeSubPath = themeId ? `themes/${themeId}/` : '';
  document.documentElement.dataset.theme = themeId;

  try {
    await import(`@/styles/${themeSubPath}app.scss`);

    PaletteColors = (await import(`@/styles/${themeSubPath}palette-colors.module.scss`)).default;
  } catch (error) {
    console.error(error);
  }
};

export const themeImage = fileName => require(`@/assets/${themeSubPath}${fileName}`);
