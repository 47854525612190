<template>
  <div class="entity-card" @click="() => onClick()">
    <ImagesHeader small :cover-url="coverUrl" />
    <div class="content">
      <h2 class="name">{{ props.name }}</h2>
      <p class="rating">
        <StarFilled width="16px" height="16px" :color="PaletteColors['warning']"></StarFilled> {{ rating || 0 }}/5
      </p>
    </div>
  </div>
</template>

<script setup>
import ImagesHeader from '@/pages/talent-pool/location-settings/components/ImagesHeader.vue';
import { useRouter } from 'vue-router/composables';
import StarFilled from '@/assets/icons/StarFilled.vue';
import { PaletteColors } from '@/Theme';

const router = useRouter();

const props = defineProps(['name', 'entityId', 'coverUrl', 'rating']);

const onClick = () => {
  router.push({
    name: 'talent-pool-settings',
    params: { locationId: props.entityId },
  });
};
</script>

<style scoped>
.entity-card {
  border: 1px solid var(--palette-color-default-lighten-90);
  padding: 0.25rem;
  border-radius: 12px;
  flex: 1;
  min-width: 16rem;
  max-width: 20rem;
  transition: all 0.1s ease-out;
  height: fit-content;

  &:hover {
    cursor: pointer;
    border-color: var(--palette-color-base-white);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  }
}

.content {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.rating {
  display: flex;
  align-items: center;
  color: var(--palette-color-default-lighten-30);
  gap: 0.5rem;
}
</style>
