<template>
  <app-page
    :title="`Pause ${employee ? employee.full_name : 'Employee'}`"
    :error="error"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
    icon="pause"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading && !saved">
      <sme-alert level="warning" class="mb-3">
        You are about to pause access to the app for {{ employee.full_name }}
      </sme-alert>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="reason"
            type="text"
            name="reason"
            rules="required"
            label="Reason for pausing"
            :disabled="saving || saved"
            :validate-immediate="false"
          />
          <sme-alert level="info" class="mb-3" v-if="isEnableEmployeePauseScheduling">
            Pause Date is optional. If not selected, pause will be effective immediately.
          </sme-alert>
          <app-input
            v-if="isEnableEmployeePauseScheduling"
            v-model="pausedAtDate"
            type="date"
            name="pause-at"
            label="Employee to be paused on"
            :min="pauseAtDateMin"
            :max="expiresAtDate"
            isNullable
          />
          <sme-alert level="info" class="mb-3">
            Unpause Date is optional. If not selected, pause will continue until manually unpaused.
          </sme-alert>
          <app-input
            v-model="expiresAtDate"
            type="date"
            name="unpause-at"
            label="Employee to be unpaused on"
            :min="pausedAtDate ? pausedAtDate : expiresAtDateMin"
            isNullable
          />
          <app-input
            v-if="employeeSalaried && !isEnableEmployeePauseScheduling"
            v-model="pauseSalaryAccrual"
            type="select"
            name="Stop accrual"
            rules="required"
            label="Stop accruing salary (useful for people on unpaid leave, sick leave)"
            :options="salaryAccrualOptions"
          />
          <page-sub-footer>
            <b-button
              data-testid="pause-button"
              type="submit"
              variant="primary"
              :disabled="v.invalid || saving || saved || !checkDatesAreValid()"
            >
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Pausing...' : 'Pause' }}
            </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
    <sme-alert v-if="saved" level="good"> {{ employee.full_name }}'s streamed wages have been paused </sme-alert>
  </app-page>
</template>

<script>
import { extend, ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import State from '@/state/State';
import { EMPLOYEE_PAY_TYPE } from '@/utils/Employee';
import useFeatureFlags from '@/composables/useFeatureFlags';
import moment from 'moment';

extend('date_validate', {
  validate(time) {
    return time !== null;
  },
  params: ['time'],
});

const { isEnableEmployeePauseScheduling } = useFeatureFlags();

export default {
  name: 'EmployeePause',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert },
  setup() {
    return {
      isEnableEmployeePauseScheduling,
    };
  },
  data() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return {
      loading: true,
      saving: false,
      saved: false,
      error: undefined,
      employee: undefined,
      reason: undefined,
      pauseSalaryAccrual: false,
      expiresAtDate: null,
      pausedAtDate: null,
      expiresAtDateMin: tomorrow,
      pauseAtDateMin: tomorrow,
      salaryAccrualOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      language_code: State.state.language_code.slice(0, 2),
    };
  },
  methods: {
    onSubmit() {
      this.saving = true;
      const newEmployee = { ...this.employee };

      if (!newEmployee.properties) {
        newEmployee.properties = {};
      }

      // If there is no scheduled pause date, pause immediately
      newEmployee.properties.paused = !this.pausedAtDate;
      newEmployee.properties.paused_timestamp = !this.pausedAtDate ? JSON.stringify(new Date()) : null;
      newEmployee.properties.paused_reason = this.reason;
      newEmployee.properties.pause_expires_at = !this.expiresAtDate ? null : this.expiresAtDate;
      newEmployee.properties.pause_scheduled_at = !this.pausedAtDate ? null : this.pausedAtDate;
      // isEnableEmployeePauseScheduling on for US only, if there is no pausedAtDate, pause salary accrual
      newEmployee.properties.pause_salary_accrual =
        isEnableEmployeePauseScheduling.value && !this.pausedAtDate ? true : this.pauseSalaryAccrual;

      ApiClient.updateEmployee(newEmployee).then(
        () => {
          this.saving = false;
          this.saved = true;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
    checkDatesAreValid() {
      if (!this.pausedAtDate || !this.expiresAtDate) {
        return true;
      }
      return (
        this.pausedAtDate && this.expiresAtDate && moment(this.pausedAtDate).isBefore(moment(this.expiresAtDate), 'day')
      );
    },
    loadData() {
      this.loading = true;
      ApiClient.getEmployee(this.$route.params.employeeId).then(
        employee => {
          this.employee = employee;
          this.loading = false;
        },
        error => {
          this.error = error.message;
          this.loading = false;
        },
      );
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    employeeSalaried() {
      return this.employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY;
    },
  },
};
</script>

<style>
#pause-expiration-datepicker-label {
  font-weight: 500 !important;
}
</style>
