<template>
  <LineChartGenerator :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
export const CHART_PRESET_OPTIONS = {
  CURRENCY: {
    scales: {
      y: {
        ticks: {
          callback: label => (!isNaN(label) ? toCurrency(label * 100) : label),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context =>
            `${context.dataset.label ? `${context.dataset.label}: ` : ''}${
              !isNaN(context.parsed.y) ? toCurrency(context.parsed.y * 100) : ''
            }`,
        },
      },
    },
  },
  MINIMAL: {
    aspectRatio: getInitialAspectRatio(2.5, 4),
    scales: {
      x: { grid: { display: false, drawBorder: false, tickLength: 0 }, ticks: { display: false } },
      y: { grid: { display: false, drawBorder: false, tickLength: 0 }, ticks: { display: false } },
    },
  },
  PERCENTAGE: {
    plugins: {
      tooltip: {
        callbacks: {
          label: context =>
            `${context.dataset.label ? `${context.dataset.label}: ` : ''}${
              !isNaN(context.parsed.y) ? `${context.parsed.y}%` : ''
            }`,
        },
      },
    },
  },
};
</script>

<script setup>
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import merge from 'lodash/merge';
import min from 'lodash/min';
import { computed } from 'vue';
import { Line as LineChartGenerator } from 'vue-chartjs';
import { PaletteColors } from '@/Theme';
import { toCurrency } from '@/utils';
import {
  DEFAULT_TOOLTIP_STYLES,
  generateGradientBackground,
  getDefaultGridStyles,
  getInitialAspectRatio,
} from '@/utils/chart';
import { isValidHex } from '@/utils/color';

ChartJS.register(CategoryScale, Legend, LineElement, LinearScale, PointElement, Title, Tooltip);

const POINT_OPTIONS = {
  pointBackgroundColor: PaletteColors['base-white'],
  pointBorderWidth: 3,
  pointRadius: 4,
  pointStyle: 'circle',
};

const props = defineProps({
  datasets: {
    type: [Array, Object],
    required: true,
  },
  labels: {
    type: Object,
    required: true,
  },
  chartOptions: Object,
  displayPoints: {
    type: Boolean,
    default: true,
  },
  fillGradient: Boolean,
});

const chartData = computed(() => ({
  labels: props.labels.values,
  datasets: (Array.isArray(props.datasets) ? props.datasets : [props.datasets]).map(dataset => ({
    label: dataset.name,
    data: dataset.values,
    borderDash: dataset.borderDash || undefined,
    borderColor: isValidHex(dataset.lineColor) ? dataset.lineColor : PaletteColors['brand-primary'],
    ...(props.fillGradient
      ? {
          backgroundColor: ctx =>
            generateGradientBackground(
              ctx,
              isValidHex(dataset.lineColor) ? dataset.lineColor : PaletteColors['brand-primary'],
            ),
          fill: true,
        }
      : { backgroundColor: dataset.lineColor || PaletteColors['brand-primary'] }),
  })),
}));

const chartOptions = computed(() => {
  const defaultGridStyles = getDefaultGridStyles();
  const suggestedYMin =
    (Array.isArray(props.datasets)
      ? min(props.datasets.map(dataset => min(dataset.values)))
      : min(props.datasets.values)) * 0.9;
  const yMin = suggestedYMin === 0 ? 0 : undefined;

  return merge(
    {
      aspectRatio: 2,
      maintainAspectRatio: true,
      responsive: true,
      elements: {
        line: {
          lineTension: 0.3,
        },
        point: {
          ...(props.displayPoints ? POINT_OPTIONS : { pointRadius: 0 }),
        },
      },
      interaction: {
        axis: 'x',
        intersect: false,
        mode: 'nearest',
      },
      scales: {
        x: merge({}, defaultGridStyles, {
          title: {
            display: !!props.chartOptions?.scales?.x?.title?.text,
          },
        }),
        y: merge({}, defaultGridStyles, {
          min: yMin,
          suggestedMin: suggestedYMin,
          title: {
            display: !!props.chartOptions?.scales?.y?.title?.text,
          },
        }),
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            color: PaletteColors['default-lighten-30'],
          },
        },
        title: {
          color: PaletteColors['default-lighten-30'],
          display: !!props.chartOptions?.plugins?.title?.text,
        },
        tooltip: merge({}, DEFAULT_TOOLTIP_STYLES),
      },
    },
    props.chartOptions,
  );
});
</script>
