<template>
  <div v-if="loading" class="loading-container">
    <app-loading />
  </div>
  <div v-else-if="success" class="wrapper">
    <CheckCircleFilled
      :color="PaletteColors['success']"
      :width="40"
      :height="40"
      class="check-circle"
    ></CheckCircleFilled>
    <div class="prompt-text">You've successfully unlocked your candidates!</div>
    <div class="info-text">Check out their full profiles now and start contacting them</div>
  </div>
  <div v-else-if="deets" class="wrapper">
    <div class="info-text">
      As an existing Wagestream Company, we will add this unlock fee to your Wagestream Account.
    </div>
    <div class="info-text">Invoices can be accessed via your Flexible Pay Dashboard.</div>
    <div class="prompt-text" v-if="deets.type == 'card'">
      £{{ toRawCurrency(props.shortlist.properties.total_cost_excl_vat) }} + VAT will be charged to your card ending in
      <b>{{ deets.last4 }}</b>
    </div>
    <div class="prompt-text" v-if="deets.type == 'invoice'">
      £{{ toRawCurrency(props.shortlist.properties.total_cost_excl_vat) }} + VAT will be added to your next invoice.
    </div>
    <div class="money-back">Don't hire via Raise within 6 weeks? Your money back, guaranteed</div>
    <div class="text-center">
      <b-button id="paynow-button" variant="primary" @click="onChargeExistingCard">
        <Lock class="lock" :color="PaletteColors['default-lighten-90']" width="20" height="20"></Lock>
        Unlock
      </b-button>
    </div>
  </div>
  <div v-else class="wrapper">
    <div ref="stripeCardDom" class="stripe-element"></div>
    <div class="text-center">
      <b-button id="pay-button" variant="primary">
        <Lock class="lock" :color="PaletteColors['default-lighten-90']" width="20" height="20"></Lock>
        Unlock
      </b-button>
    </div>
    <b-alert class="small-alert" v-if="error" variant="danger" show>{{ error }}</b-alert>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import Lock from '@/assets/icons/Lock.vue';
import { PaletteColors } from '@/Theme';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import { toRawCurrency } from '@/utils';
import tracker from '@/Tracker';
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';

const props = defineProps({
  shortlist: Object,
});

const emit = defineEmits(['charged']);

const error = ref(null);
const loading = ref(true);
const stripeCardDom = ref(null);
const stripeCard = ref(null);
const cardDetails = ref(null);
const deets = ref(null);
const success = ref(false);

const onChargeExistingCard = async () => {
  await tracker.trackEvent('shortlist_private_pay_click', {
    posting_id: props.shortlist.talent_pool_posting_id,
    charge_existing: true,
  });
  const intent = await ApiClient.unlockShortlist(props.shortlist.talent_pool_posting_id);
  if (intent.data.charged === true) {
    await tracker.trackEvent('shortlist_private_pay_success', {
      posting_id: props.shortlist.talent_pool_posting_id,
      charge_existing: true,
    });
    success.value = true;
    emit('charged', null);
  } else {
    // This shouldnt happen?
    await tracker.trackEvent('shortlist_private_pay_anomaly', {
      posting_id: props.shortlist.talent_pool_posting_id,
      charge_existing: true,
    });
  }
};

onMounted(async () => {
  loading.value = true;
  const check = await ApiClient.getShortlistPaymentDetails(props.shortlist.talent_pool_posting_id);
  deets.value = check.data;
  loading.value = false;

  if (deets.value) {
    return;
  }

  const stripe = await loadStripe(process.env.VUE_APP_RAISE_STRIPE_PUB_KEY);

  stripeCard.value = stripe.elements().create('card', { hidePostalCode: true, disableLink: true });

  stripeCard.value.mount(stripeCardDom.value);

  stripeCard.value.addEventListener('change', event => {
    if (event.complete) {
      cardDetails.value = stripeCard.value;
    } else {
      // if the card number is not valid, do not emit a value
      cardDetails.value = null;
    }
  });

  const intent = await ApiClient.unlockShortlist(props.shortlist.talent_pool_posting_id);

  if (intent.data.charged === true) {
    // This should be impossible but we might want to handle where
    // we have a card on file but they want to use another one.
    await tracker.trackEvent('shortlist_private_pay_anomaly', {
      posting_id: props.shortlist.talent_pool_posting_id,
      charge_existing: false,
    });
    emit('charged', null);
    return;
  }

  document.getElementById('pay-button').addEventListener('click', async () => {
    await tracker.trackEvent('shortlist_private_pay_click', {
      posting_id: props.shortlist.talent_pool_posting_id,
      charge_existing: false,
    });

    const response = await stripe.createToken(cardDetails.value);
    if (response?.error) {
      await tracker.trackEvent('shortlist_private_pay_error', {
        posting_id: props.shortlist.talent_pool_posting_id,
        message: response.error.message,
        charge_existing: false,
      });
      error.value = response.error.message;
      loading.value = false;
      return;
    }

    const token = response.token;
    const result = await stripe.confirmCardPayment(intent.data.client_secret, {
      payment_method: {
        card: {
          token: token.id,
        },
      },
    });

    if (result.error) {
      await tracker.trackEvent('shortlist_private_pay_error', {
        posting_id: props.shortlist.talent_pool_posting_id,
        message: result.error.message,
        charge_existing: false,
      });
      error.value = result.error.message;
      stripeCard.value.loading.value = false;
      return;
    }

    // We dont care so much about result.paymentIntent.status as
    // even a succeeded intent has to pass through webhooks
    // back to us so we can unlock.
    let count = 0;
    const max = 15;
    const checkingStatus = async function () {
      if (count++ > max) {
        // something is wrong but may still have worked
        await tracker.trackEvent('shortlist_private_pay_timeout', {
          posting_id: props.shortlist.talent_pool_posting_id,
          charge_existing: false,
        });
        clearInterval(checking);
        window.location.reload();
      }
      try {
        const creds = await ApiClient.getUnlockedShortlist(props.shortlist.talent_pool_posting_id);
        if (creds.success !== true) {
          clearInterval(checking);
        }
        if (creds.data) {
          await tracker.trackEvent('shortlist_private_pay_success', {
            posting_id: props.shortlist.talent_pool_posting_id,
            charge_existing: false,
          });
          clearInterval(checking);
          emit('charged', null);
        }
      } catch (e) {
        clearInterval(checking);
      }
    };
    let checking = setInterval(checkingStatus, 1000);
  });
});
</script>

<style scoped lang="scss">
.wrapper {
  padding: 1rem 3rem;
  text-align: center;
}
.stripe-element {
  padding-top: 10px;
  padding-bottom: 50px;
}
.prompt-text {
  padding-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
}

.info-text {
  padding-bottom: 30px;
  font-size: 14px;
}

.small-alert {
  margin-top: 20px;
}

.check-circle {
  margin-bottom: 1rem;
}

.money-back {
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
}
</style>
