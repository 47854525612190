import { queryOptions, useQueryClient } from '@tanstack/vue-query';
import ApiClient from '@/ApiClient';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { isAfter } from 'date-fns';

const { isEnableRaiseJobs } = useFeatureFlags();

export const orderJobPostings = data => {
  // We do this to remove jobs that dont have a shortlist
  const filteredJobPostings = data.filter(job => isAfter(job.created_at, '2024-08-01T00:00:00Z'));

  return filteredJobPostings.sort((a, b) => {
    if (a.closed_at === null && b.closed_at !== null) {
      return -1;
    }
    if (a.closed_at !== null && b.closed_at === null) {
      return 1;
    }
    return a.external_role_name.localeCompare(b.external_role_name);
  });
};

export const getAllJobPostingsOptions = companyId => {
  return queryOptions({
    queryKey: ['get_jobs'],
    queryFn: async () => {
      const result = await ApiClient.getJobPostings(companyId);
      return result.data;
    },
    enabled: isEnableRaiseJobs.value,
    select: orderJobPostings,
  });
};

export const getJobPosting = (jobId, disable) => {
  return queryOptions({
    enabled: !disable,
    queryKey: ['get_job', jobId],
    queryFn: async () => {
      const result = await ApiClient.getJobPosting(jobId);
      return result.data;
    },
  });
};

export const getApplicants = (jobId, payload, onSelect) => {
  return queryOptions({
    queryKey: ['get_job_applicants', jobId, payload],
    queryFn: async () => {
      const result = await ApiClient.getJobApplicants(jobId, payload);
      return result.data;
    },
    select: onSelect,
  });
};

export const postRejectCandidate = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async applicantId => {
      const result = await ApiClient.denyJobApplicant(applicantId);
      return result.data;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('get_job_applicants', data.unfilled_shift_group_id);
      queryClient.invalidateQueries('get_job', data.unfilled_shift_group_id);
    },
  };
};

export const postJobPosting = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.createJobPosting(payload.payload);
      return result.data;
    },
    onSuccess: data => {
      queryClient.invalidateQueries('get_jobs');
      queryClient.invalidateQueries('get_job', data.unfilled_shift_group_id);
    },
  };
};

export const patchJobPosting = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.updateJobPosting(payload.jobId, payload.payload);
      return result.data;
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries('get_jobs');
      await queryClient.invalidateQueries('get_job', data.unfilled_shift_group_id);
    },
  };
};

export const closeJobPosting = () => {
  const queryClient = useQueryClient();

  return {
    mutationFn: async payload => {
      const result = await ApiClient.closeJob(payload.jobId, payload.payload);
      return result.data;
    },
    onSuccess: async (data, payload) => {
      await queryClient.invalidateQueries('get_jobs');
      await queryClient.invalidateQueries('get_job', payload.jobId);
    },
  };
};
