import { queryOptions } from '@tanstack/vue-query';
import ApiClient from '@/ApiClient';

export const getArticle = (slug, disable) => {
  return queryOptions({
    enabled: !disable,
    queryKey: ['get_article', slug],
    queryFn: async () => {
      const result = await ApiClient.renderArticle(slug);
      return result.data;
    },
  });
};
