<template>
  <ValidationObserver v-slot="v" tag="div">
    <b-form @submit.prevent="v.handleSubmit(onSave)">
      <section class="employee-add-form__fieldsets">
        <fieldset>
          <legend class="h2 font-weight-normal m-0 mb-4">Personal Information</legend>

          <app-input
            v-if="isFieldVisible('full_name')"
            v-model="localEmployee.full_name"
            name="Full Name"
            rules="required"
            label="Full Name"
            description="Please enter the same value used in your payroll system"
            :disabled="!isFieldEditable('full_name')"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('employee_code')"
            v-model="localEmployee.employee_code"
            name="Employee Code"
            label="Employee Code"
            rules="required"
            :disabled="!isFieldEditable('employee_code')"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('work_email')"
            v-model="localEmployee.work_email"
            name="Email"
            label="Email"
            rules="required|email"
            :disabled="!isFieldEditable('work_email')"
            :validate-immediate="false"
          />
          <app-input
            v-model="localEmployee.work_phone"
            type="text"
            name="phone"
            label="Phone Number (Optional)"
            placeholder="XXXXXXXXXXX"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.line1"
            type="text"
            name="Address Line 1"
            label="Address Line 1"
            placeholder="13 Main Street"
            rules="required"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.line2"
            type="text"
            name="Address Line 2"
            label="Address Line 2 (Optional)"
            placeholder="Apt 13"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.city"
            type="text"
            name="City"
            label="City"
            placeholder="New York"
            rules="required"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.state_province"
            type="text"
            name="State"
            label="State"
            placeholder="NY"
            :rules="{ required: true, oneOf: states }"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.zip"
            type="text"
            name="Zip"
            label="Zip"
            placeholder="XXXXX"
            rules="required|numeric|length:5"
            :validate-immediate="false"
          />
          <app-input
            v-if="isFieldVisible('address')"
            v-model="localEmployee.properties.address.country"
            type="text"
            name="Country"
            label="Country"
            placeholder="US"
            rules="required|regex:^US$"
            :validate-immediate="false"
          />
        </fieldset>

        <fieldset v-if="!hideEarnings && !hideSalary">
          <legend class="h2 font-weight-normal m-0 mb-4">Salary</legend>
          <app-input
            v-if="isFieldVisible('salary_properties')"
            v-model="localEmployee.salary_properties.salary_or_hourly"
            type="radio"
            name="Payment Type"
            label="How are they paid?"
            rules="required"
            :options="salaryOptions"
            :disabled="!isFieldEditable('salary_properties')"
            :validate-immediate="false"
            button-variant="outline-primary"
            buttons
          />

          <template v-if="localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY">
            <app-input
              v-if="isFieldVisible('salary_properties')"
              v-model="localEmployee.salary_properties.salary"
              type="number"
              step="0.01"
              name="Salary"
              label="Salary"
              :rules="SALARY_VALIDATION_RULES"
              :description="
                isSalaryWarningVisible
                  ? 'Are you sure this is the correct salary setting? Check that you have entered the amount and whether it is per year or per month.'
                  : ''
              "
              :disabled="!isFieldEditable('salary_properties')"
              :prepend="currencySymbolFromCode(state.company.default_currency)"
              :validate-immediate="false"
              control-class="employee-add-form__salary-control"
            >
              <b-input-group-text class="border-0 bg-white">per</b-input-group-text>
              <app-input
                v-model="localEmployee.salary_properties.salary_period"
                type="select"
                :disabled="!isFieldEditable('salary_properties')"
                :options="salaryPeriods"
                :validate-immediate="false"
                class="employee-add-form__salary-period"
              />
            </app-input>
            <app-input
              v-if="isFieldVisible('salary_properties')"
              v-model="localEmployee.salary_properties.salary_accrual_schedule"
              type="select"
              name="Accrual Schedule"
              label="Accrual Schedule"
              description="For salaried workers, this setting controls how frequently their earned income updates"
              rules="required"
              undefined-label="Select an Accrual Schedule"
              :disabled="!isFieldEditable('salary_properties')"
              :options="salaryAccrualSchedules"
              :validate-immediate="false"
            />
            <app-input
              v-if="isFieldVisible('started_on')"
              v-model="localEmployee.started_on"
              type="date"
              name="Start Date"
              label="Start Date"
              rules="required"
              :disabled="!isFieldEditable('started_on')"
              :validate-immediate="false"
            />
          </template>
        </fieldset>
      </section>

      <page-sub-footer>
        <template #before>
          <sme-alert v-if="saveError" level="danger">{{ saveError }}</sme-alert>
        </template>
        <b-button type="submit" variant="primary" :disabled="v.invalid || saving">
          <b-spinner v-if="saving" class="mr-2" small />
          {{ saving ? 'Saving...' : 'Save' }}
        </b-button>
      </page-sub-footer>
    </b-form>
  </ValidationObserver>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { ValidationObserver } from 'vee-validate';
import AppInput from '@/components/AppInput.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { employeeFieldPermissions } from '@/mixins/EmployeeFieldPermissions.js';
import { EMPLOYEE_PAY_TYPE, SALARY_VALIDATION_RULES } from '@/utils/Employee';
import { currencySymbolFromCode } from '@/utils/common';
import { states } from '@/utils/stateCodes';

export default {
  name: 'EmployeeAddForm',
  model: {
    prop: 'employee',
    event: 'input',
  },
  props: {
    employee: Object,
    balance: Object,
    saveError: String,
    saving: Boolean,
    state: Object,
    hideEarnings: Boolean,
    hideSalary: Boolean,
    fieldPermissions: Array,
  },
  mixins: [employeeFieldPermissions],
  components: { ValidationObserver, AppInput, PageSubFooter, SmeAlert },
  data() {
    return {
      EMPLOYEE_PAY_TYPE,
      SALARY_VALIDATION_RULES,
      localEmployee: cloneDeep(this.employee),
      salaryOptions: [
        { value: EMPLOYEE_PAY_TYPE.HOURLY, text: 'Shift' },
        { value: EMPLOYEE_PAY_TYPE.SALARY, text: 'Salary' },
      ],
      hourlyPeriods: [{ value: 'hour', text: 'hour' }],
      selectedHourlyPeriod: 'hour',
      salaryPeriods: [
        { value: 'MONTH', text: 'month' },
        { value: 'YEAR', text: 'year' },
      ],
      salaryAccrualSchedules: [
        { value: 'WEEKDAY0917', text: 'Weekdays, 9am—5pm GMT' },
        {
          value: 'EVERYDAY0917',
          text: 'Every day (including weekends!), 9am—5pm GMT',
        },
      ],
      paySchedules: null,
      states,
    };
  },
  mounted() {
    this.transformToLocal();
  },
  methods: {
    currencySymbolFromCode,
    transformToLocal() {
      function toLocal(field) {
        if (field === undefined) {
          return undefined;
        }
        return field === 0 ? 0 : field / 100;
      }
      this.localEmployee = cloneDeep(this.employee);
      this.localEmployee.default_wagerate = toLocal(this.localEmployee.default_wagerate);
    },
    onSave() {
      const employee = cloneDeep(this.localEmployee);
      this.castEmployeeIntegers(employee);
      this.$emit('input', employee);
      this.$emit('save');
    },
    castEmployeeIntegers(employee) {
      if (employee.default_wagerate) {
        employee.default_wagerate = parseInt((employee.default_wagerate * 100).toFixed(0));
        employee.salary_properties.default_wagerate = employee.default_wagerate;
      }
      if (employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY) {
        employee.salary_properties.salary = Math.round(employee.salary_properties.salary);
      }
    },
  },
  computed: {
    isSelfFunded() {
      return this.state?.isSelfServeSME;
    },
    isSalaryWarningVisible() {
      const { salary, salary_period } = this.localEmployee.salary_properties;

      if (!salary) {
        return false;
      }

      return salary_period === 'MONTH' ? salary < 750 : salary < 2000;
    },
  },
  watch: {
    employee: {
      deep: true,
      handler() {
        this.transformToLocal();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-add-form__fieldsets {
  display: grid;
  gap: 3rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

:deep(.employee-add-form__salary-control) {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.employee-add-form__salary-period {
  width: 6rem;
}
</style>
