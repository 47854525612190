<template>
  <section class="progress-dial-chart">
    <div class="progress-dial-chart__chart-container">
      <Doughnut
        :chart-data="{ datasets: [{ backgroundColor: PaletteColors['base-background'], data: [100] }] }"
        :chart-options="merge({}, CHART_OPTIONS, { animation: { animateRotate: false } })"
        class="progress-dial-chart__chart progress-dial-chart__chart--background"
      />
      <Doughnut :chart-data="chartData" :chart-options="CHART_OPTIONS" class="progress-dial-chart__chart" />
    </div>
    <div v-if="$slots.default" class="progress-dial-chart__content">
      <slot></slot>
    </div>
  </section>
</template>

<script setup>
import { ArcElement, CategoryScale, Chart as ChartJS } from 'chart.js';
import merge from 'lodash/merge';
import { computed } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { PaletteColors } from '@/Theme';

ChartJS.register(ArcElement, CategoryScale);

const CHART_OPTIONS = {
  circumference: 180,
  cutout: '80%',
  elements: {
    arc: {
      borderRadius: 4,
      borderWidth: 0,
    },
  },
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  rotation: -90,
};

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

const chartData = computed(() => ({
  datasets: [
    {
      backgroundColor: props.data.map(data => data.backgroundColor || PaletteColors['brand-primary']),
      data: props.data.map(data => data.value),
    },
  ],
}));
</script>

<style lang="scss" scoped>
.progress-dial-chart {
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: fit-content;
}

.progress-dial-chart__chart-container {
  position: relative;
}

.progress-dial-chart__chart {
  margin: -25% auto;
  pointer-events: none;
  position: relative;
  z-index: 1;
}

.progress-dial-chart__chart--background {
  bottom: 25%;
  left: 0;
  position: absolute;
  right: 0;
  top: 25%;
}

.progress-dial-chart__content {
  bottom: 0;
  left: 0;
  padding-bottom: 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}
</style>
