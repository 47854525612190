<!-- eslint-disable vue/no-deprecated-filter -->
<template>
  <ValidationObserver v-slot="v" tag="div">
    <b-form @submit.prevent="v.handleSubmit(onSave)" class="employee-form">
      <section class="employee-form__fieldsets">
        <fieldset class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">Personal Information</legend>

          <app-input
            v-model="localEmployee.full_name"
            name="Full Name"
            :disabled="!isFieldEditable('full_name')"
            :plaintext="!isFieldVisible('full_name')"
            :rules="{ required: true, regex: NAME_REGEX }"
            highlight-changed
            row
          >
            <template #label>
              Full Name
              <small v-if="isFieldVisible('full_name')">Only letters, numbers and .-/&' allowed</small>
            </template>
          </app-input>
          <app-input
            v-model="localEmployee.employee_code"
            name="Employee Code"
            label="Employee Code"
            rules="required"
            :disabled="!isFieldEditable('employee_code')"
            :plaintext="!isFieldVisible('employee_code')"
            highlight-changed
            row
          />
          <app-input
            v-model="localEmployee.work_email"
            type="email"
            name="Email"
            rules="required|email"
            :disabled="!isFieldEditable('work_email')"
            :plaintext="!isFieldVisible('work_email')"
            highlight-changed
            row
          >
            <template #label>
              Email
              <small v-if="isFieldVisible('work_email')">Must be a valid email address</small>
            </template>
          </app-input>
          <app-input
            v-model="localEmployee.work_phone"
            type="tel"
            name="Phone"
            placeholder="XXXXXXXXXXX"
            rules="phone"
            :disabled="!isFieldEditable('work_phone')"
            :plaintext="!isFieldVisible('work_phone')"
            highlight-changed
            row
          >
            <template #label>
              Phone
              <small v-if="isFieldVisible('work_phone')">Must be a valid phone number</small>
            </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.line1"
            name="Address Line 1"
            placeholder="13 Main Street"
            rules="required"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label> Address Line 1 </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.line2"
            name="Address Line 2"
            placeholder="Apt 13"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label>
              Address Line 2
              <small>Optional</small>
            </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.city"
            name="City"
            placeholder="New York"
            rules="required"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label> City </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.state_province"
            name="State"
            placeholder="NY"
            :rules="{ required: true, oneOf: states }"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label>
              State
              <small>Enter 2 Letter state abbreviation</small>
            </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.zip"
            name="Zip"
            placeholder="XXXXX"
            rules="required|numeric|length:5"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label>
              Zip
              <small>5 digit zip code</small>
            </template>
          </app-input>
          <app-input
            v-if="isFieldVisible('address', true)"
            v-model="localEmployee.properties.address.country"
            name="Country"
            placeholder="US"
            rules="required|regex:^US$"
            :plaintext="!editable"
            highlight-changed
            row
          >
            <template #label> Country </template>
          </app-input>

          <app-input
            v-if="localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY"
            v-model="localEmployee.started_on"
            type="date"
            name="Start Date"
            label="Start Date"
            rules="required"
            :disabled="!isFieldEditable('started_on')"
            :plaintext="!isFieldVisible('started_on')"
            highlight-changed
            row
          />
        </fieldset>

        <fieldset v-if="!hideEarnings && !hideSalary" class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">Salary</legend>

          <app-input
            v-model="localEmployee.salary_properties.salary_or_hourly"
            type="radio"
            name="Payment Type"
            label="Payment Type"
            rules="required"
            :disabled="!isFieldEditable('salary_properties')"
            :options="salaryOptions"
            :plaintext="!isFieldVisible('salary_properties')"
            :plaintext-formatter="toTitleCase"
            button-variant="outline-primary"
            buttons
            highlight-changed
            row
          />
          <app-input
            v-if="salaryToHourlyChange"
            v-model="salaryToHourlyDate"
            type="date"
            name="Date Changed"
            label="Date Changed"
            :max="salaryToHourlyDateMax"
            highlight-changed
            row
          />
          <app-input
            v-if="localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY"
            v-model="localEmployee.salary_properties.salary"
            type="number"
            step="0.01"
            name="Salary"
            label="Salary"
            :prepend="currencySymbolFromCode(state.company.default_currency)"
            :rules="SALARY_VALIDATION_RULES"
            :description="
              isFieldVisible('salary_properties') && isSalaryWarningVisible
                ? 'Are you sure this is the correct salary setting? Check that you have entered the amount and whether it is per year or per month.'
                : ''
            "
            :disabled="!isFieldEditable('salary_properties')"
            :plaintext="!isFieldVisible('salary_properties')"
            :plaintext-formatter="
              value =>
                `${toCurrency(
                  value * 100,
                  state.company.default_currency,
                )} per ${localEmployee.salary_properties.salary_period.toLowerCase()}`
            "
            control-class="employee-form__salary-control"
            highlight-changed
            row
          >
            <b-input-group-text class="border-0 bg-white">per</b-input-group-text>
            <app-input
              v-model="localEmployee.salary_properties.salary_period"
              type="select"
              :disabled="!isFieldEditable('salary_properties')"
              :options="salaryPeriods"
              :plaintext="!isFieldVisible('salary_properties')"
              class="employee-form__salary-period"
              highlight-changed
            />
          </app-input>
          <app-input
            v-if="
              localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY && canSetDefaultWagerate
            "
            v-model="localEmployee.default_wagerate"
            type="number"
            step="0.01"
            name="Hourly Rate"
            label="Hourly Rate"
            :prepend="currencySymbolFromCode(state.company.default_currency)"
            :rules="Object.assign({}, WAGERATE_VALIDATION_RULES, { required: false })"
            :disabled="!isFieldEditable('salary_properties')"
            :plaintext="!isFieldVisible('salary_properties')"
            :plaintext-formatter="value => `${toCurrency((value || 0) * 100, state.company.default_currency)} per hour`"
            control-class="employee-form__salary-control"
            highlight-changed
            row
          >
          </app-input>
          <app-input
            v-if="
              localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.HOURLY && canSetDefaultWagerate
            "
            v-model="localEmployee.default_wagerate"
            type="number"
            step="0.01"
            name="Hourly Rate"
            label="Hourly Rate"
            :prepend="currencySymbolFromCode(state.company.default_currency)"
            :rules="WAGERATE_VALIDATION_RULES"
            :disabled="!isFieldEditable('salary_properties')"
            :plaintext="!isFieldVisible('salary_properties')"
            :plaintext-formatter="value => `${toCurrency(value * 100, state.company.default_currency)} per hour`"
            control-class="employee-form__salary-control"
            highlight-changed
            row
          >
          </app-input>
        </fieldset>

        <fieldset class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">{{ getBankingInfo.bankDetails }}</legend>
          <app-input
            v-model="localEmployee.properties.beneficiary_sort_code"
            name="Employee Sort Code"
            :disabled="!isFieldEditable('bank_details')"
            :placeholder="
              localEmployee.properties.beneficiary_sort_code ||
              employee.properties.sme_properties?.personal_sort_code ||
              getBankingInfo.sortCode.placeholder
            "
            :plaintext="!isFieldVisible('beneficiary_sort_code')"
            :plaintext-formatter="value => formatSortCode(value, state.company.default_currency)"
            :rules="{ required: isEmployeeEnrolled(employee), regex: getBankingInfo.sortCode.regex }"
            highlight-changed
            row
          >
            <template #label>
              Employee {{ getBankingInfo.sortCode.label }}
              <small v-if="isFieldVisible('beneficiary_sort_code')">{{ getBankingInfo.sortCode.description }}</small>
            </template>
          </app-input>
          <app-input
            v-model="localEmployee.properties.beneficiary_account_number"
            name="Employee Account Number"
            :disabled="!isFieldEditable('bank_details')"
            :placeholder="
              localEmployee.properties.beneficiary_account_number ||
              localEmployee.properties.sme_properties?.personal_account_number ||
              getBankingInfo.accountNumber.placeholder
            "
            :plaintext="!isFieldVisible('beneficiary_account_number')"
            :rules="{ required: isEmployeeEnrolled(employee), regex: getBankingInfo.accountNumber.regex }"
            highlight-changed
            row
          >
            <template #label>
              Employee Account Number
              <small v-if="isFieldVisible('beneficiary_account_number')">{{
                getBankingInfo.accountNumber.description
              }}</small>
            </template>
          </app-input>
          <app-input
            v-if="canSetAccountType"
            v-model="localEmployee.properties.beneficiary_type"
            :name="getBankingInfo.accountType.label"
            type="select"
            :disabled="!isFieldEditable('bank_details')"
            :placeholder="localEmployee.properties.beneficiary_type || getBankingInfo.accountType?.placeholder"
            :plaintext="!editable"
            :options="getBankingInfo.accountType?.options"
            highlight-changed
            row
          >
            <template #label>
              {{ getBankingInfo.accountType.label }}
              <small>{{ getBankingInfo.accountType.description }}</small>
            </template>
          </app-input>
          <app-input
            name="Wagestream Sort Code"
            :label="'Wagestream ' + getBankingInfo.sortCode.label"
            :plaintext-formatter="value => formatSortCode(value, state.company.default_currency)"
            :value="employee.properties.payment_sort_code"
            plaintext
            row
          />
          <app-input
            name="Wagestream Account Number"
            label="Wagestream Account Number"
            :value="employee.properties.payment_account_number"
            plaintext
            row
          />
        </fieldset>

        <fieldset v-if="isFundingSelf || isExpandedStreamingFieldsVisible()" class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">Streaming</legend>
          <template v-if="isExpandedStreamingFieldsVisible()">
            <app-input
              v-if="isFieldVisible('max_transfer_percent', true)"
              v-model="localEmployee.max_transfer_percent"
              type="text"
              name="Eligible Streamed Wages Percent"
              append="%"
              :disabled="!isFieldEditable('max_transfer_percent')"
              :plaintext="!isFieldVisible('max_transfer_percent')"
              :plaintext-formatter="value => `${!isNaN(value) ? value : 0}%`"
              :rules="maxTransferPercentValidation"
              highlight-changed
              row
            >
              <template #label>
                Eligible Streamed Wages Percent
                <small v-if="isFieldVisible('max_transfer_percent')">
                  The percentage of gross earned wages the employee is permitted to stream. Note that the maximum is
                  {{ state.company?.properties?.employee_defaults?.max_transfer_percent?.max || 100 }}%, on account of
                  taxes and other gross salary deductions
                </small>
              </template>
            </app-input>
            <app-input
              v-if="!isFundingSelf && isFieldVisible('min_transfer_balance', true)"
              v-model="localEmployee.min_transfer_balance"
              type="number"
              name="Minimum Balance"
              label="Minimum Balance"
              :prepend="currencySymbolFromCode(state.company.default_currency)"
              step="1"
              rules="numeric|min_value:0|max_value:2000"
              :disabled="!isFieldEditable('min_transfer_balance')"
              :plaintext="!isFieldVisible('min_transfer_balance')"
              :plaintext-formatter="value => toCurrency((value || 0) * 100, state.company.default_currency)"
              highlight-changed
              row
            />
            <app-input
              v-if="isFieldVisible('max_transfers_month', true)"
              v-model="localEmployee.max_transfers_month"
              type="number"
              name="Max Streams"
              step="1"
              rules="numeric|min_value:0|max_value:9999"
              :disabled="!isFieldEditable('max_transfers_month')"
              :plaintext="!isFieldVisible('max_transfers_month')"
              highlight-changed
              row
            >
              <template #label>
                Max Streams (per pay period)
                <small v-if="isFieldVisible('max_transfers_month')">
                  The maximum number of transfers an employee can make in a pay period
                </small>
              </template>
            </app-input>
          </template>
          <app-input
            v-if="isFundingSelf || isExpandedStreamingFieldsVisible()"
            v-model="localEmployee.monthly_transfer_limit"
            type="number"
            name="Monthly Streams Limit"
            label="Streams Limit (per pay period)"
            :prepend="currencySymbolFromCode(state.company.default_currency)"
            step="1"
            :disabled="!isFieldEditable('monthly_transfer_limit')"
            :plaintext="!isFieldVisible('monthly_transfer_limit')"
            :plaintext-formatter="
              value => (value == null ? value : toCurrency(value * 100, state.company.default_currency))
            "
            :rules="monthlyTransferLimitValidation"
            highlight-changed
            row
          >
            <template #label>
              Streams Limit (per pay period)
              <small v-if="isFieldVisible('monthly_transfer_limit')">
                The maximum amount an employee can transfer in a pay period
              </small>
            </template>
          </app-input>
        </fieldset>

        <fieldset class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">Administration</legend>
          <app-input
            v-model="localEmployee.pay_schedule_id"
            type="select"
            name="Pay Schedule"
            label="Pay Schedule"
            :disabled="!isFieldEditable('salary_properties')"
            :options="payScheduleOptions"
            :plaintext="!isFieldVisible('salary_properties')"
            :plaintext-formatter="() => employeePayScheduleName || 'Unknown'"
            :rules="{ required: true, oneOf: payScheduleOptions?.map(p => p.value) }"
            highlight-changed
            row
          />
          <app-input
            name="Next Payday"
            label="Next Payday"
            :value="balance.next_paycheck_date ? toFormattedDate(balance.next_paycheck_date) : 'Unknown'"
            plaintext
            row
          />
          <app-input
            v-if="localEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY"
            v-model="localEmployee.salary_properties.salary_accrual_schedule"
            type="select"
            name="Accrual Schedule"
            rules="required"
            undefined-label="Select an Accrual Schedule"
            :disabled="!isFieldEditable('salary_properties')"
            :options="salaryAccrualSchedules"
            :plaintext="!isFieldVisible('salary_properties')"
            highlight-changed
            row
          >
            <template #label>
              Accrual Schedule
              <small v-if="isFieldVisible('salary_properties')">
                For salaried workers, this setting controls how frequently their earned income updates
              </small>
            </template>
          </app-input>
          <app-input
            v-if="employee.division_name"
            name="Division Name"
            label="Division Name"
            :value="employee.division_name"
            plaintext
            row
          />
          <app-input
            v-if="employee.properties.terminated_on"
            name="Termination Date"
            label="Termination Date"
            :value="toFormattedDate(employee.properties.terminated_on)"
            plaintext
            row
          />
          <app-input
            v-if="employee.terminated_at"
            name="Termination Date Received"
            label="Termination Date Received"
            :value="toFormattedDate(employee.terminated_at)"
            plaintext
            row
          />
          <app-input
            v-if="employee.properties.paused || employee.properties.pause_scheduled_at"
            name="Paused Reason"
            label="Paused Reason"
            :value="employee.properties.paused_reason ?? '-'"
            plaintext
            row
          />
          <app-input
            v-if="employee.properties.pause_scheduled_at"
            name="Scheduled Pause Date"
            label="Scheduled Pause Date"
            :value="employee.properties.pause_scheduled_at"
            plaintext
            row
          />
          <app-input
            v-if="employee.properties.pause_expires_at"
            name="Pause Expiry Date"
            label="Pause Expiry Date"
            :value="employee.properties.pause_expires_at"
            plaintext
            row
          />
        </fieldset>

        <fieldset v-if="isEnableFlexibleWork" class="employee-form__fieldset">
          <legend class="h2 font-weight-normal m-0 mb-4">Flexible Work</legend>
          <tag-input
            v-model="localEmployee.properties.flexible_work_preferences.roles"
            name="Roles"
            label="Roles"
            placeholder="Add a role..."
            :disabled="!isFieldEditable('properties.flexible_work_preferences.roles')"
            :options="roleOptions"
            :plaintext="!isFieldVisible('properties.flexible_work_preferences.roles')"
            :plaintext-formatter="() => localEmployee.properties.flexible_work_preferences.roles?.join(', ') || '-'"
            row
          />
          <app-input
            v-model="localEmployee.properties.flexible_work_preferences.postcode"
            name="Postcode"
            label="Postcode"
            :disabled="!isFieldEditable('properties.flexible_work_preferences.postcode')"
            :plaintext="!isFieldVisible('properties.flexible_work_preferences.postcode')"
            highlight-changed
            row
          />
          <app-input
            v-model="localEmployee.properties.flexible_work_preferences.radius"
            type="number"
            step="1"
            name="Radius"
            label="Radius (meters)"
            :disabled="!isFieldEditable('properties.flexible_work_preferences.radius')"
            :plaintext="!isFieldVisible('properties.flexible_work_preferences.radius')"
            highlight-changed
            row
          />
        </fieldset>
      </section>

      <page-sub-footer>
        <template #before>
          <sme-alert v-if="!saving && editable && warnAboutEditing" level="warning">
            Any information that you change here may get overwritten by your integration when we next sync with them.
          </sme-alert>
        </template>
        <b-button type="submit" variant="primary" :disabled="v.invalid || saving">
          <b-spinner v-if="saving" class="mr-2" small />
          {{ saving ? 'Saving...' : editable ? 'Save' : 'Edit' }}
        </b-button>
        <b-button v-if="editable" variant="outline-primary" :disabled="saving" @click.prevent="handleCancel">
          Cancel
        </b-button>
      </page-sub-footer>
    </b-form>
  </ValidationObserver>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { ValidationObserver } from 'vee-validate';
import { computed } from 'vue';
import { getBankingInfo } from '@/Banking';
import AppInput from '@/components/AppInput.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import TagInput from '@/components/TagInput.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { employeeBadges } from '@/mixins/EmployeeBadges.js';
import { employeeFieldPermissions } from '@/mixins/EmployeeFieldPermissions.js';
import useRolesAndLocations from '@/state/composables/useRolesAndLocations';
import { toCurrency } from '@/utils';
import { EMPLOYEE_PAY_TYPE, NAME_REGEX, SALARY_VALIDATION_RULES, WAGERATE_VALIDATION_RULES } from '@/utils/Employee';
import { formatSortCode, toTitleCase, currencySymbolFromCode } from '@/utils/common';
import { toFormattedDate } from '@/utils/date';
import { states } from '@/utils/stateCodes';

export default {
  name: 'EmployeeForm',
  model: {
    prop: 'employee',
    event: 'input',
  },
  props: {
    employee: Object,
    balance: Object,
    saving: Boolean,
    state: Object,
    integrationsState: Object,
    fieldPermissions: Array,
    paySchedules: Array,
    employeePayScheduleName: String,
    hideEarnings: Boolean,
    hideSalary: Boolean,
  },
  mixins: [employeeFieldPermissions, employeeBadges],
  components: { ValidationObserver, AppInput, PageSubFooter, TagInput, SmeAlert },
  data() {
    return {
      EMPLOYEE_PAY_TYPE,
      NAME_REGEX,
      SALARY_VALIDATION_RULES,
      WAGERATE_VALIDATION_RULES,
      localEmployee: cloneDeep(this.employee),
      controlEmployee: cloneDeep(this.employee),
      salaryOptions: [
        { value: EMPLOYEE_PAY_TYPE.HOURLY, text: 'Shift' },
        { value: EMPLOYEE_PAY_TYPE.SALARY, text: 'Salary' },
      ],
      selectedHourlyPeriod: 'hour',
      salaryPeriods: [
        { value: 'MONTH', text: 'month' },
        { value: 'YEAR', text: 'year' },
      ],
      salaryAccrualSchedules: [
        { value: 'WEEKDAY0917', text: 'Weekdays, 9am—5pm GMT' },
        {
          value: 'EVERYDAY0917',
          text: 'Every day (including weekends!), 9am—5pm GMT',
        },
      ],
      payScheduleOptions: undefined,
      editable: false,
      fieldsHaveBeenEdited: true,
      salaryToHourlyChange: false,
      salaryToHourlyDate: undefined,
      salaryToHourlyDateMax: new Date(),
      payscheduleChange: false,
      states,
    };
  },
  setup() {
    const { isEnableFlexibleWork, canSetAccountType, canSetDefaultWagerate } = useFeatureFlags();
    const { roles, getRoles } = useRolesAndLocations();

    if (isEnableFlexibleWork.value) {
      getRoles();
    }

    const roleOptions = computed(() =>
      roles.value.map(role => ({
        text: role.name,
        value: role.name,
      })),
    );

    return { isEnableFlexibleWork, roleOptions, canSetAccountType, canSetDefaultWagerate };
  },
  beforeMount() {
    this.transformToLocal(this.employee);
    this.payScheduleOptions = this.paySchedules.map(x => {
      return {
        text: x.name,
        value: x.pay_schedule_id,
      };
    });
  },
  methods: {
    formatSortCode,
    toCurrency,
    toFormattedDate,
    toTitleCase,
    currencySymbolFromCode,
    handleCancel() {
      this.editable = false;
      this.fieldsHaveBeenEdited = false;
      this.transformToLocal(this.employee);
    },
    transformToLocal(updatedEmployee) {
      function toLocal(field) {
        if (field == null) {
          return undefined;
        }
        return field === 0 ? 0 : field / 100;
      }
      if (!updatedEmployee.properties?.beneficiary_account_number) {
        updatedEmployee.properties.beneficiary_account_number =
          updatedEmployee.properties?.sme_properties?.personal_account_number;
      }
      if (!updatedEmployee.properties?.beneficiary_sort_code) {
        updatedEmployee.properties.beneficiary_sort_code =
          updatedEmployee.properties?.sme_properties?.personal_sort_code;
      }
      if (this.isEnableFlexibleWork && !updatedEmployee.properties?.flexible_work_preferences) {
        updatedEmployee = merge({}, updatedEmployee, { properties: { flexible_work_preferences: {} } });
      }
      this.localEmployee = cloneDeep(updatedEmployee);
      this.localEmployee.default_wagerate = toLocal(this.localEmployee.default_wagerate);
      this.localEmployee.min_transfer_balance = toLocal(this.localEmployee.min_transfer_balance);
      this.localEmployee.monthly_transfer_limit = toLocal(this.localEmployee.monthly_transfer_limit);
      this.controlEmployee = cloneDeep(updatedEmployee || this.localEmployee);
    },
    async onSave() {
      if (!this.editable) {
        this.editable = true;
        return;
      }

      if (!this.fieldsHaveBeenEdited) {
        this.$appToast(`${this.localEmployee.full_name}'s details haven't been changed`, {
          title: `Cannot update employee as you haven't made any changes`,
          variant: 'warning',
        });
        return;
      }

      const employee = cloneDeep(this.localEmployee);
      this.castEmployeeIntegers(employee);

      if (this.payscheduleChange) {
        const code = this.paySchedules.find(ps => ps.pay_schedule_id === employee.pay_schedule_id).properties
          .pay_schedule_code;
        employee.properties.pay_schedule_code = code;
      }

      // If the default wage rate is empty, set it to 0
      if (employee.default_wagerate === '') {
        employee.default_wagerate = 0;
      }

      const controlEmployee = this.controlEmployee;
      function revertDataOnFail() {
        return controlEmployee;
      }

      this.$emit('input', employee);
      this.$emit('save', {
        failureCallback: revertDataOnFail,
        salaryToHourlyDate: this.salaryToHourlyDate,
        salaryToHourlyChange: this.salaryToHourlyChange,
      });
      this.editable = false;
    },
    castEmployeeIntegers(employee) {
      if (employee.max_transfer_percent) {
        employee.max_transfer_percent = parseInt(employee.max_transfer_percent);
      }
      if (employee.min_transfer_balance) {
        employee.min_transfer_balance = parseInt(employee.min_transfer_balance) * 100;
      }
      if (employee.monthly_transfer_limit) {
        employee.monthly_transfer_limit = parseInt(employee.monthly_transfer_limit) * 100;
      }
      if (employee.max_transfers_month) {
        employee.max_transfers_month = parseInt(employee.max_transfers_month);
      }
      if (employee.default_wagerate) {
        employee.default_wagerate = parseInt((employee.default_wagerate * 100).toFixed(0));
        employee.salary_properties.default_wagerate = employee.default_wagerate;
      }
      if (employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY) {
        employee.salary_properties.salary = Math.round(employee.salary_properties.salary);
      }
    },
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
    isFundingSelf() {
      return this.state.company?.properties?.funding_self || false;
    },
    isSalaryWarningVisible() {
      const { salary, salary_period } = this.localEmployee.salary_properties;

      if (!salary) {
        return false;
      }

      return salary && salary_period === 'MONTH' ? salary < 750 : salary < 2000;
    },
    maxTransferPercentValidation() {
      return {
        required: true,
        numeric: true,
        min_value: this.state.company?.employee_defaults?.max_transfer_percent?.min || 0,
        max_value: this.state.company?.employee_defaults?.max_transfer_percent?.max || 100,
      };
    },
    monthlyTransferLimitValidation() {
      const rules = {
        numeric: true,
        min_value: 0,
        required: false,
      };
      const maxValue = this.balance?.employee_transfer_limits?.absolute_maximum_transfer_month;

      if (maxValue) {
        rules.max_value = maxValue / 100;
      }

      return rules;
    },
    warnAboutEditing() {
      return (
        this.integrationsState.integrations &&
        !this.integrationsState.integrations?.map(i => i.partner).includes('BROWSER_UPLOAD')
      );
    },
  },
  watch: {
    employee: {
      deep: true,
      handler() {
        this.transformToLocal(this.employee);
      },
    },
    localEmployee: {
      deep: true,
      handler() {
        this.fieldsHaveBeenEdited = !isEqual(this.localEmployee, this.controlEmployee);
        if (
          this.localEmployee.salary_properties.salary_or_hourly !==
            this.controlEmployee.salary_properties.salary_or_hourly &&
          this.controlEmployee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY
        ) {
          this.salaryToHourlyChange = true;
        } else {
          this.salaryToHourlyChange = false;
        }

        if (this.localEmployee.pay_schedule_id !== this.controlEmployee.pay_schedule_id) {
          this.payscheduleChange = true;
        } else {
          this.payscheduleChange = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-form__fieldsets {
  display: grid;
  gap: 3rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

:deep(.employee-form__salary-control) {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.employee-form__salary-period {
  width: 6rem;
}
</style>
