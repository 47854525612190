var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"flexible-work-schedule-summary",on:{"click":function($event){return _setup.emit('view-list')}}},[_c('div',{staticClass:"flexible-work-schedule-summary__content"},[_c('h4',{staticClass:"flexible-work-schedule-summary__title"},[_vm._v(_vm._s(_vm.summary.count)+" "+_vm._s(_setup.pluralize('shift', _vm.summary.count)))]),_c('ul',{staticClass:"flexible-work-schedule-summary__details"},[_c('li',{staticClass:"flexible-work-schedule-summary__detail",class:{
          'flexible-work-schedule-summary__detail--applications flexible-work-schedule-summary__detail--clickable':
            _vm.summary.applications > 0,
        },on:{"click":function($event){_setup.emit(
            'view-list',
            _vm.summary.applications ? (_vm.summary.pending ? _setup.CLAIM_STATUS.PENDING : _setup.assignedStatusOfInterest) : null,
          )}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'hand']}}),_vm._v(" "+_vm._s(_vm.summary.applications)+" "+_vm._s(_setup.pluralize('application', _vm.summary.applications))+" ")],1),_c('li',{staticClass:"flexible-work-schedule-summary__detail flexible-work-schedule-summary__detail--large",class:{
          'flexible-work-schedule-summary__detail--assigned flexible-work-schedule-summary__detail--clickable':
            _vm.summary.assigned > 0,
        },on:{"click":function($event){return _setup.emit('view-list', _setup.assignedStatusOfInterest)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'circle-check']}}),_vm._v(" "+_vm._s(_vm.summary.assigned)+" assigned ")],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }