<template>
  <b-modal id="whatsapp-modal" size="lg" hide-header hide-footer no-close-on-backdrop no-close-on-escape centered>
    <h4>Invite your employees via WhatsApp</h4>
    <p>
      Turn on WhatsApp invites, and we’ll send a one-time WhatsApp (or an SMS if they do not have WhatsApp) message to
      your employees inviting them to enrol, helping to ensure maximum visibility within your business.
    </p>
    <p>
      To enable this, you’ll need to accept an amendment to our Agreement, which can be viewed
      <a :href="contentsUrl && `/#${contentsUrl.split('#')[1]}`" target="_blank">here</a>, and ensure you have your
      employees’ consent. Your employees can opt-out of WhatsApp and/or SMS communications at any time.
    </p>
    <p>
      <strong>
        If you choose not to opt in, we will still send invites by email. This does not require an amendment to your
        agreement
      </strong>
    </p>
    <b-button variant="primary" class="employee-cta" @click="() => handleSubmission(true)">Opt in</b-button>
    <b-button variant="secondary" class="ml-3 employee-cta" @click="() => handleSubmission(false)"
      >Do not opt in</b-button
    >
  </b-modal>
</template>

<script>
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';

export default {
  name: 'DashboardWhatsAppModal',
  data() {
    return {
      contentsUrl: undefined,
      agreementId: undefined,
    };
  },
  async mounted() {
    this.$bvModal.show('whatsapp-modal');
    const result = await ApiClient.smeGetWhatsAppOptIn();
    this.contentsUrl = result.data?.data?.contents_url;
    this.agreementId = result.data?.data?.agreement_id;
  },
  methods: {
    async handleSubmission(optIn) {
      await tracker.trackEvent('sme_activation_launch', { optIn });
      this.$bvModal.hide('whatsapp-modal');
      this.$emit('accept', {
        optIn,
        agreementId: this.agreementId,
      });
    },
  },
};
</script>
