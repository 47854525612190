<template>
  <article
    class="app-stat"
    :class="[small && 'app-stat--small']"
    :style="{
      backgroundColor: backgroundColor ? PaletteColors[backgroundColor] : null,
      color: color ? PaletteColors[color] : null,
    }"
  >
    <div class="app-stat__value-container">
      <b-spinner v-if="stat === undefined" />
      <p v-else class="app-stat__value">{{ prefix }}{{ formattedStat }}{{ suffix }}</p>
    </div>
    <span v-if="previousStat" class="app-stat__value app-stat__value--small" :class="previousStatClass">
      {{ stat >= previousStat ? '+' : '-' }}{{ prefix }}{{ formattedPreviousStatDiff }}{{ suffix }}
    </span>
    <slot></slot>
    <p class="app-stat__description">{{ description }}</p>
    <font-awesome-icon v-if="icon" :icon="icon" class="app-stat__icon" size="7x" />
  </article>
</template>

<script setup>
import { computed } from 'vue';
import { PaletteColors } from '@/Theme';
import { isPaletteColor } from '@/utils/propValidators';

const props = defineProps({
  stat: [Number, String],
  previousStat: [Number, String],
  formatter: {
    type: Function,
    default: value => value,
  },
  description: String,
  prefix: String,
  suffix: String,
  icon: Array,
  backgroundColor: { type: String, validator: isPaletteColor },
  color: { type: String, validator: isPaletteColor },
  small: Boolean,
});

const formattedStat = computed(() => props.formatter(props.stat));
const previousStatDiff = computed(() => Math.abs(props.stat - props.previousStat));
const formattedPreviousStatDiff = computed(() => props.formatter(previousStatDiff.value));
const previousStatClass = computed(() => {
  if (props.stat > props.previousStat) {
    return 'text-success';
  } else if (props.stat < props.previousStat) {
    return 'text-danger';
  }

  return '';
});
</script>

<style lang="scss" scoped>
.app-stat {
  background-color: var(--palette-color-base-white);
  border-radius: 4px;
  box-shadow: var(--depth-2);
  color: var(--palette-color-brand-primary);
  height: 100%;
  overflow: hidden;
  padding: 1.25rem 1.5rem;
  position: relative;

  @media (min-width: 992px) {
    padding: 1.5rem 2rem;
  }

  &[style] {
    color: var(--palette-color-base-white);
  }
}

.app-stat--small {
  padding: 1.25rem;

  .app-stat__value-container {
    height: 2.3rem;
  }

  .app-stat__value {
    font-size: 28px;

    @media (min-width: 992px) {
      font-size: 32px;
    }
  }
}

.app-stat__value-container {
  align-items: flex-start;
  display: flex;
  height: 2.8rem;
}

.app-stat__value {
  display: block;
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 40px;
  }
}

.app-stat__value--small {
  font-size: 18px;
  margin-bottom: 0.5rem;

  @media (min-width: 992px) {
    font-size: 22px;
  }
}

.app-stat__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
}

.app-stat__icon {
  bottom: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  transform: translate(10%, 10%);
}
</style>
