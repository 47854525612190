<template>
  <div class="day-column">
    <span :class="['date', { today }]">{{ toFormattedDate(props.date, 'ddd, D MMM') }}</span>
    <template v-for="state in Object.entries(groupedShifts)">
      <div v-if="!!state[1].shifts.length" class="state-section" :key="state.key">
        <div :class="['state-header', state[0]]">
          <div class="square"></div>
          <span class="label">{{ state[1].label }}</span>
          <span class="count">{{ state[1].shifts.length }}</span>
        </div>
        <CalendarShift v-for="shift in state[1].shifts" :key="shift.id" :shift="shift" />
      </div>
    </template>
    <div class="buttons">
      <button @click="emit('click:add-shift')">
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" /> Add shift
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { toFormattedDate } from '@/utils/date';
import CalendarShift from '@/pages/talent-pool/schedule/components/CalendarShift.vue';
import {
  isUnclaimed,
  isClaimed,
  isFulfilled,
  isCompleted,
  isUnfilled,
  PortalShiftState,
} from '@/pages/talent-pool/utils/ShiftStatus';
import { toTitleCase } from '@/utils/common';

const props = defineProps(['date', 'shifts']);
const emit = defineEmits(['click:add-shift']);

const groupedShifts = computed(() => {
  return {
    [PortalShiftState.UNCLAIMED]: {
      label: toTitleCase(PortalShiftState.UNCLAIMED),
      shifts: props.shifts.filter(isUnclaimed),
    },
    [PortalShiftState.CLAIMED]: {
      label: toTitleCase(PortalShiftState.CLAIMED),
      shifts: props.shifts.filter(isClaimed),
    },
    [PortalShiftState.FULFILLED]: {
      label: toTitleCase(PortalShiftState.FULFILLED),
      shifts: props.shifts.filter(isFulfilled),
    },
    [PortalShiftState.COMPLETED]: {
      label: toTitleCase(PortalShiftState.COMPLETED),
      shifts: props.shifts.filter(isCompleted),
    },
    [PortalShiftState.UNFILLED]: {
      label: toTitleCase(PortalShiftState.UNFILLED),
      shifts: props.shifts.filter(isUnfilled),
    },
  };
});

const today = computed(() => {
  const today = new Date();
  return today.toDateString() === new Date(props.date).toDateString();
});
</script>

<style lang="scss" scoped>
.day-column {
  border-right: 1px solid var(--palette-color-default-lighten-90);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 6px;
  gap: 1rem;
}

.date {
  font-size: 13px;
  font-weight: 500;
  padding: 6px 0;
  text-align: center;
}

.date.today {
  font-weight: 600;
  color: var(--palette-color-brand-primary);
}

.state-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.state-header {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--palette-color-default-lighten-20);
}

.state-header .square {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 6px;
  background-color: var(--palette-color-brand-primary);
}

.state-header.unclaimed .square {
  background-color: var(--palette-color-shift-states-unclaimed);
}

.state-header.claimed .square {
  background-color: var(--palette-color-shift-states-claimed);
}

.state-header.fulfilled .square {
  background-color: var(--palette-color-shift-states-fulfilled);
}

.state-header.completed .square {
  background-color: var(--palette-color-shift-states-completed);
}

.state-header.unfilled .square {
  background-color: var(--palette-color-shift-states-unfilled);
}

.state-header .label {
  flex-grow: 1;
}

.state-header .count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  border-radius: 50%;
  background-color: var(--palette-color-base-background);
  line-height: 16px;
  font-weight: 500;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

button {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--palette-color-default-lighten-80);
  border-radius: 4px;
  background-color: var(--palette-color-base-white);
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s;
}

.day-column:hover button {
  opacity: 1;
}
</style>
```
