<template>
  <section>
    <template v-if="shifts.length">
      <flexible-work-shift
        v-for="shift in pagedShifts"
        :key="shift.shift_id"
        :shift="shift"
        :claims="claims[shift.shift_id]"
        @updated="emit('updated')"
      />
    </template>
    <p v-else class="my-2 text-center">No {{ statusName.toLowerCase() }} shifts to display.</p>
    <b-pagination
      v-model="currentPage"
      :total-rows="shifts.length"
      :per-page="perPage"
      class="justify-content-end mb-0"
    />
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import FlexibleWorkShift from '@/pages/flexible-work/components/FlexibleWorkShift.vue';

const props = defineProps({
  shifts: {
    type: Array,
    required: true,
  },
  claims: {
    type: Object,
    required: true,
  },
  statusName: {
    type: String,
    required: true,
  },
  perPage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['updated']);

const currentPage = ref(1);

const offset = computed(() => (currentPage.value - 1) * (props.perPage + 1));
const pagedShifts = computed(() => props.shifts.slice(offset.value, offset.value + props.perPage));
</script>
