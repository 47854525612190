export const PER_PAGE_OPTIONS = [25, 50, 100];

export const ABSENCE_FIELD_DEFINITIONS = [
  {
    key: 'employee_code',
    label: 'Employee Code',
  },
  {
    key: 'full_name',
    label: 'Employee Name',
  },
  {
    key: 'started_at',
    label: 'Start Date',
  },
  {
    key: 'ended_at',
    label: 'End Date',
  },
  {
    key: 'duration',
    label: 'Days',
  },
  {
    key: 'is_paid',
    label: 'Paid',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'reference',
    label: 'Reference',
  },
];

export const ABSENCE_FIELD_KEYS = {
  EMPLOYEE_CODE: 'employee_code',
  FULL_NAME: 'full_name',
  STARTED_AT: 'started_at',
  ENDED_AT: 'ended_at',
  DURATION: 'duration',
  IS_PAID: 'is_paid',
  TYPE: 'type',
  STATUS: 'status',
  REFERENCE: 'reference',
};

export const ABSENCE_FILTER_KEYS = {
  STARTS_ON: 'starts_on',
  ENDS_ON: 'ends_on',
};
