import ApiClient from '@/ApiClient';
import { queryOptions } from '@tanstack/vue-query';

export const getLocationRatings = locationId => {
  return queryOptions({
    queryKey: ['get_location_ratings', locationId],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolLocationRating(locationId);
      return result.data;
    },
  });
};

export const getEntity = entityId => {
  return queryOptions({
    queryKey: ['get_entity', entityId],
    queryFn: async () => {
      const result = await ApiClient.getTalentPoolEntityDetails(entityId);
      return result.data;
    },
  });
};
