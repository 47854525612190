<template>
  <div class="absences-list">
    <page-list-header>
      <template #left>
        <filter-dropdown v-model="filters" :filters="filterItems">
          <inline-search v-if="!fixedSearchText" v-model="searchText" @search="getAbsences" />
        </filter-dropdown>
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
        <export-button
          file-name="absences"
          :export-data="absences"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </page-list-header>

    <component :is="componentType" class="mb-3">
      <app-loading :loading="loading" />

      <b-table
        v-if="!loading"
        ref="table"
        primary-key="absence_id"
        :items="filteredAbsences"
        :fields="fieldDefinitions"
        class="mb-0"
        table-class="table-minimal"
        :tfoot-class="!absences?.length ? 'd-none' : ''"
        show-empty
        foot-clone
        no-footer-sorting
        no-local-sorting
        responsive
      >
        <template v-slot:cell(employee_code)="data">{{ data.item.employee_code }}</template>
        <template v-slot:cell(full_name)="data">
          <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">{{
            data.item.full_name
          }}</router-link>
        </template>
        <template v-slot:cell(is_paid)="data">
          <font-awesome-icon
            v-if="data.item.is_paid"
            :icon="['fal', 'circle-check']"
            class="mr-2 absences-table__is-paid--true"
          />
          <font-awesome-icon v-else :icon="['fal', 'circle-xmark']" class="mr-2 absences-table__is-paid--false" />
        </template>
        <template v-slot:foot(employee_code)>&nbsp;</template>
        <template v-slot:foot(full_name)>&nbsp;</template>
      </b-table>
    </component>

    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="justify-content-end" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import {
  ABSENCE_FIELD_DEFINITIONS,
  ABSENCE_FIELD_KEYS,
  ABSENCE_FILTER_KEYS,
  PER_PAGE_OPTIONS,
} from '@/pages/absences/constants';
import { toFormattedDate, toFormattedDateAllowNulls, daysBetweenDates } from '@/utils/date';

const props = defineProps({
  nested: {
    type: Boolean,
    default: false,
  },
  fixedSearchText: {
    type: String,
  },
  employeeId: {
    type: String,
  },
  filterKeys: {
    type: Array,
    default: () => Object.values(ABSENCE_FILTER_KEYS),
  },
  fieldKeys: {
    type: Array,
    default: () => Object.values(ABSENCE_FIELD_KEYS),
  },
});

const totalRows = ref(0);
const absences = ref([]);
const searchText = ref(props.fixedSearchText || '');
const currentPage = ref(1);
const perPage = ref(25);
const loading = ref();
const filters = ref({
  starts_on: '',
  ends_on: '',
});

const filteredAbsences = computed(() => {
  if (props.employeeId) {
    return absences.value.filter(absence => absence.employee_id === props.employeeId);
  } else {
    return absences.value;
  }
});

const fieldDefinitions = computed(() => {
  return ABSENCE_FIELD_DEFINITIONS.filter(field =>
    (props.fieldKeys || Object.values(ABSENCE_FIELD_KEYS)).includes(field.key),
  );
});

const componentType = computed(() => (props.nested ? 'div' : SmeCard));

const exportHeaders = computed(() => fieldDefinitions.value.map(field => field.label));

const filterItems = computed(() =>
  [
    {
      key: ABSENCE_FILTER_KEYS.STARTS_ON,
      label: 'From',
      type: CONTROL_TYPES.DATE,
    },
    {
      key: ABSENCE_FILTER_KEYS.ENDS_ON,
      label: 'To',
      type: CONTROL_TYPES.DATE,
    },
  ].filter(filter => props.filterKeys.includes(filter.key)),
);

watch([() => filters.value, () => perPage.value, () => currentPage.value], () => getAbsences(), { deep: true });

const exportFileRows = item => {
  return fieldDefinitions.value.map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );
};

const getAbsences = async () => {
  loading.value = true;
  const payload = {
    offset: (currentPage.value - 1) * perPage.value,
    limit: perPage.value,
    start_date: filters.value.starts_on,
    end_date: filters.value.ends_on,
    employee_name: searchText.value,
  };
  const rawAbsences = (await ApiClient.getAbsencesForCompany(payload))?.data || [];
  totalRows.value = rawAbsences.total;
  absences.value = rawAbsences.items.map(absence => {
    const startedAt = new Date(absence.started_at);
    const endedAt = absence.ended_at ? new Date(absence.ended_at) : null;
    return {
      ...absence,
      started_at: toFormattedDate(startedAt),
      ended_at: toFormattedDateAllowNulls(endedAt),
      duration: endedAt ? daysBetweenDates(startedAt, endedAt) : daysBetweenDates(startedAt, new Date()),
      type: absence.properties.type || 'Unknown',
      status: absence.properties.status || 'Unknown',
      reference: absence.properties.reference || 'Unknown',
    };
  });
  loading.value = false;
};

onMounted(async () => {
  await getAbsences();
});
</script>

<style lang="scss" scoped>
.absences-table__is-paid {
  &--true {
    color: var(--palette-color-success);
    font-size: large;
  }

  &--false {
    color: var(--palette-color-danger);
    font-size: large;
  }
}
</style>
