<template>
  <div class="d-flex justify-content-end">
    <b-button class="border-0 py-1 px-2" variant="outline-primary" @click="startTour">
      <font-awesome-icon :icon="['fa', 'info']" class="mr-2" />
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script setup>
import Shepherd from 'shepherd.js';
import { useRoute } from 'vue-router/composables';
import { getTourState, setTourState } from '@/utils/Tour';
import 'shepherd.js/dist/css/shepherd.css';

const route = useRoute();

const props = defineProps({
  autoStart: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: 'Quick tour',
  },
  id: {
    type: String,
    required: true,
  },
  tour: {
    type: Shepherd.Tour,
    required: true,
  },
});

const startTour = () => {
  if (!props.tour.isActive()) {
    props.tour.start();
  }
};

if ((props.autoStart && !getTourState(props.id)) || route.query.tour === props.id) {
  startTour();
  props.tour.once('cancel', () => setTourState(props.id));
}
</script>

<style lang="scss">
.sme-tour {
  max-width: 27.5rem;

  .shepherd-header {
    align-items: flex-start;
    background: none !important;
  }

  &.shepherd-has-title {
    .shepherd-header {
      padding: 0.75rem 1rem 0;
    }
  }

  &.shepherd-has-cancel-icon {
    .shepherd-header {
      padding-right: 1rem;
    }
  }

  .shepherd-title {
    font-size: 24px;
    padding-top: 1rem;
  }

  .shepherd-text {
    font-size: 14px;
    line-height: 1.4;
    padding: 1rem;

    &:last-child {
      padding-bottom: 1.5rem;
    }
  }

  .shepherd-footer {
    margin-top: 0;
    padding: 0.5rem 1rem 1rem;
  }

  .shepherd-button {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &.btn-primary {
      &:hover {
        background: var(--palette-color-core-1-darken-20) !important;
        color: var(--palette-color-base-white) !important;
      }
    }

    &.btn-outline-primary {
      &:hover {
        background: var(--palette-color-brand-primary) !important;
        color: var(--palette-color-base-white) !important;
      }
    }
  }
}
</style>
