const HEX_REGEX = /^#([0-9a-f]{3}){1,2}$/i;

export const isValidHex = hex => typeof hex === 'string' && HEX_REGEX.test(hex);

export const hexToRGB = (hex, alpha) => {
  if (!isValidHex(hex)) {
    throw `${hex} is not a valid hex.`;
  }

  const stringValues =
    hex.length === 4
      ? [hex.slice(1, 2), hex.slice(2, 3), hex.slice(3, 4)].map(n => `${n}${n}`)
      : [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)];
  const intValues = stringValues.map(n => parseInt(n, 16));

  return typeof alpha === 'number' ? `rgba(${intValues.join(', ')}, ${alpha})` : `rgb(${intValues.join(', ')})`;
};

export const toRGBParts = rgb => rgb.match(/[0-9.]+/gi);
