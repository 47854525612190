import { render, staticRenderFns } from "./FlexibleWorkShiftClaim.vue?vue&type=template&id=415f489e&scoped=true&"
import script from "./FlexibleWorkShiftClaim.vue?vue&type=script&setup=true&lang=js&"
export * from "./FlexibleWorkShiftClaim.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./FlexibleWorkShiftClaim.vue?vue&type=style&index=0&id=415f489e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415f489e",
  null
  
)

export default component.exports