import { render, staticRenderFns } from "./SmeAlert.vue?vue&type=template&id=23d767cd&scoped=true&"
import script from "./SmeAlert.vue?vue&type=script&setup=true&lang=js&"
export * from "./SmeAlert.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SmeAlert.vue?vue&type=style&index=0&id=23d767cd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d767cd",
  null
  
)

export default component.exports