<template>
  <header :class="!!props.divider && 'divider'">
    <LocationSelector :route-name="props.routeName" />
    <slot></slot>

    <div class="buttons">
      <b-button v-b-modal="'help-modal'" class="aligned-btn" variant="outline-primary">
        <font-awesome-icon :icon="['far', 'circle-question']" class="mr-2" />Get started
      </b-button>
      <b-button
        v-if="isEnableRaiseJobs && props.routeName === 'talent-pool-jobs'"
        v-b-modal="'add-job-modal'"
        variant="primary"
      >
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Post a job
      </b-button>
      <b-button v-else v-b-modal="'add-shift-modal'" variant="primary">
        <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Add a shift
      </b-button>
    </div>
  </header>
</template>

<script setup>
import LocationSelector from '@/pages/talent-pool/components/LocationSelector.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';

const { isEnableRaiseJobs } = useFeatureFlags();
const props = defineProps(['routeName', 'locationId', 'divider']);
</script>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--palette-color-base-white);
  padding: 0.5rem 1.5rem;
}

header.divider {
  border-bottom: 1px solid var(--palette-color-default-lighten-90);
}

.location-selector-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.buttons {
  display: flex;
  gap: 1rem;
  flex: 1;
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .location-selector-container,
  .buttons {
    display: none;
  }
}

@media (max-width: 1200px) {
  .location-selector-container,
  .buttons {
    flex: unset;
  }
}
</style>
