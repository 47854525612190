<template>
  <JobsPromo v-if="jobVisible && job?.worker_count > 0" @dismiss="dismiss" :job="job" />
  <ShiftsPromo v-else-if="shiftVisible && shift?.worker_count > 0" @dismiss="dismiss" :shift="shift" />
</template>

<script setup>
import JobsPromo from '@/pages/dashboard/components/raisePromo/JobsPromo';
import ShiftsPromo from '@/pages/dashboard/components/raisePromo/ShiftsPromo';
import ApiClient from '@/ApiClient';
import { onMounted, ref } from 'vue';

const job = ref({});
const jobVisible = ref(false);
const shift = ref({});
const shiftVisible = ref(false);

const dismiss = () => {
  sessionStorage.setItem('raise_promo', 'hidden');
  jobVisible.value = false;
  shiftVisible.value = false;
};

onMounted(async () => {
  const jobResponse = await ApiClient.getTalentPoolJobAd();
  job.value = jobResponse.data || {};
  const numJobs = Object.keys(job.value).length;

  const shiftResponse = await ApiClient.getTalentPoolShiftAd();
  shift.value = shiftResponse.data || {};
  const numShifts = Object.keys(shift.value).length;

  const dismissed = sessionStorage.getItem('raise_promo');
  jobVisible.value = dismissed !== 'hidden' && numJobs > 0;
  shiftVisible.value = dismissed !== 'hidden' && numShifts > 0;
});
</script>
