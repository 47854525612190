import moment from 'moment';

export const LAUNCH_STATUS = {
  YET_TO_LAUNCH: 'yetToLaunch',
  ACTIVATING: 'activating',
  BUSINESS_AS_USUAL: 'businessAsUsual',
};

export const getLaunchStatus = launchDate => {
  const now = moment();
  const launchDatePlus30Days = launchDate && moment(launchDate).add(30, 'days');

  if (!launchDate) {
    return LAUNCH_STATUS.YET_TO_LAUNCH;
  } else if (now.isBefore(launchDatePlus30Days) || now.isSame(launchDatePlus30Days)) {
    return LAUNCH_STATUS.ACTIVATING;
  }

  return LAUNCH_STATUS.BUSINESS_AS_USUAL;
};
