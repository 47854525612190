<template>
  <Modal title="Get started" modalId="help-modal" size="xl" :hide-footer="!props.onboarding">
    <GetStartedJobs v-if="route.fullPath.includes('jobs')" :onboarding="props.onboarding" />
    <GetStarted v-else :onboarding="props.onboarding" />
    <template v-if="props.onboarding" #modal-footer>
      <b-button variant="outline-primary" @click="onCancel($bvModal)">Cancel</b-button>
      <b-button v-if="props.onboarding" variant="primary" @click="redirectToRaise">Try it now!</b-button>
    </template>
  </Modal>
</template>

<script setup>
import GetStarted from '@/pages/talent-pool/components/GetStarted';
import axios from 'axios';
import ApiClient, { setRefreshToken, setToken } from '@/ApiClient';
import { useRouter, useRoute } from 'vue-router/composables';
import Modal from '@/components/Modal.vue';
import GetStartedJobs from '@/pages/talent-pool/components/GetStarted/GetStartedJobs.vue';

const $router = useRouter();
const route = useRoute();

const props = defineProps(['onboarding']);

const onCancel = async modal => {
  await ApiClient.postAnalytics('click_modal_cancel', {});
  modal.hide('help-modal');
};

const redirectToRaise = async () => {
  await ApiClient.postAnalytics('click_modal_redirect_to_raise', {});
  const response = await ApiClient.postAdminOnboarding();
  if (response.auth) {
    const token = response.auth.access_token;
    setToken(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const refreshToken = response.auth.refresh_token;
    setRefreshToken(refreshToken);
    await ApiClient.getUser();
  }
  $router.push({ name: 'talent-pool-root' });
};
</script>
