import { queryOptions } from '@tanstack/vue-query';
import ApiClient from '@/ApiClient';

export const getShortlist = (jobId, onSelect) => {
  return queryOptions({
    queryKey: ['get_job_shortlist', jobId],
    queryFn: async () => {
      const result = await ApiClient.getShortlistedCandidates(jobId);
      return result.data;
    },
    select: onSelect,
  });
};

export const getPublicShortlist = (jobId, onSelect) => {
  return queryOptions({
    queryKey: ['get_job_public_shortlist', jobId],
    queryFn: async () => {
      const result = await ApiClient.getPublicShortlistedCandidates(jobId);
      return result.data;
    },
    select: onSelect,
  });
};
