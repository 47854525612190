<template>
  <section class="flexible-work-shift-audience">
    <flexible-work-shift-audience-map :location="location" class="mb-3" />
    <sme-alert v-if="isAddMode" :level="ALERT_LEVELS.INFO">
      You'll see audience insights for your shift here once it's published
    </sme-alert>
    <template v-else>
      <progress-dial-chart
        :data="[
          { value: employeeCount, backgroundColor: claimLikelinessColor },
          { value: 100 - employeeCount, backgroundColor: 'transparent' },
        ]"
        class="mb-3"
      >
        <div>
          <p class="flexible-work-shift-audience__header-title flexible-work-shift-audience__header-title--small m-0">
            {{ claimLikelinessText }}
          </p>
          <p class="flexible-work-shift-audience__header-text mt-1 mb-0">to fill</p>
        </div>
      </progress-dial-chart>
      <sme-alert :level="CLAIM_LIKELINESS_ALERT_COLOR[claimLikeliness]">
        {{ employeeCount > 0 && claimLikeliness === (CLAIM_LIKELINESS.VERY_LOW || CLAIM_LIKELINESS.LOW) ? 'Only' : '' }}
        {{ employeeCount }} {{ employeeCount === 1 ? 'person' : 'people' }} available in selected area
      </sme-alert>
      <flexible-work-shift-boost v-if="isEnableFlexibleWorkBoost" class="mt-3" />
    </template>
  </section>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import { PaletteColors } from '@/Theme';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import ProgressDialChart from '@/components/charts/ProgressDialChart.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import FlexibleWorkShiftAudienceMap from '@/pages/flexible-work/shift/components/FlexibleWorkShiftAudienceMap.vue';
import FlexibleWorkShiftBoost from '@/pages/flexible-work/shift/components/FlexibleWorkShiftBoost.vue';

const { isEnableFlexibleWorkBoost } = useFeatureFlags();

const CLAIM_LIKELINESS = {
  VERY_LOW: 'VERY_LOW',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  VERY_HIGH: 'VERY_HIGH',
};

const CLAIM_LIKELINESS_TEXT = {
  [CLAIM_LIKELINESS.VERY_LOW]: 'Very unlikely',
  [CLAIM_LIKELINESS.LOW]: 'Unlikely',
  [CLAIM_LIKELINESS.MEDIUM]: 'Somewhat likely',
  [CLAIM_LIKELINESS.HIGH]: 'Likely',
  [CLAIM_LIKELINESS.VERY_HIGH]: 'Very likely',
};

const CLAIM_LIKELINESS_ALERT_COLOR = {
  [CLAIM_LIKELINESS.VERY_LOW]: ALERT_LEVELS.DANGER,
  [CLAIM_LIKELINESS.LOW]: ALERT_LEVELS.DANGER,
  [CLAIM_LIKELINESS.MEDIUM]: ALERT_LEVELS.WARNING,
  [CLAIM_LIKELINESS.HIGH]: ALERT_LEVELS.GOOD,
  [CLAIM_LIKELINESS.VERY_HIGH]: ALERT_LEVELS.GOOD,
};

const CLAIM_LIKELINESS_THRESHOLD = {
  [CLAIM_LIKELINESS.VERY_LOW]: 2,
  [CLAIM_LIKELINESS.LOW]: 5,
  [CLAIM_LIKELINESS.MEDIUM]: 20,
  [CLAIM_LIKELINESS.HIGH]: 50,
  [CLAIM_LIKELINESS.VERY_HIGH]: 75,
};

const props = defineProps({
  isAddMode: {
    type: Boolean,
    required: true,
  },
  location: Object,
  shiftId: String,
});

const employeeCount = ref(0);

const claimLikeliness = computed(() => {
  if (employeeCount.value < CLAIM_LIKELINESS_THRESHOLD.VERY_LOW) {
    return CLAIM_LIKELINESS.VERY_LOW;
  } else if (employeeCount.value < CLAIM_LIKELINESS_THRESHOLD.LOW) {
    return CLAIM_LIKELINESS.LOW;
  } else if (employeeCount.value < CLAIM_LIKELINESS_THRESHOLD.MEDIUM) {
    return CLAIM_LIKELINESS.MEDIUM;
  } else if (employeeCount.value < CLAIM_LIKELINESS_THRESHOLD.HIGH) {
    return CLAIM_LIKELINESS.HIGH;
  }

  return CLAIM_LIKELINESS.VERY_HIGH;
});

const claimLikelinessColor = computed(() => {
  if (claimLikeliness.value === CLAIM_LIKELINESS.VERY_LOW || CLAIM_LIKELINESS.LOW) {
    return PaletteColors['danger'];
  } else if (claimLikeliness.value === CLAIM_LIKELINESS.MEDIUM) {
    return PaletteColors['warning'];
  }

  return PaletteColors['success'];
});

const claimLikelinessText = computed(() => CLAIM_LIKELINESS_TEXT[claimLikeliness.value]);

onBeforeMount(async () => {
  if (!props.isAddMode) {
    const proximityMetrics = await ApiClient.getFlexibleWorkShiftProximityMetrics(props.shiftId);

    employeeCount.value = proximityMetrics?.count || 0;
  }
});
</script>

<style lang="scss">
.flexible-work-shift-audience__header-title {
  color: var(--palette-color-default);
  font-weight: 400;
  font-size: 36px;
  line-height: 1.2;
}

.flexible-work-shift-audience__header-title--small {
  font-size: 18px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}
</style>
