<template>
  <section>
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <sme-alert v-if="isIntegrated() && !isOnboarding" :level="ALERT_LEVELS.INFO" class="mb-4">
        Roles and locations are synced from your rota provider. If you want to add, change or remove a role or location,
        this cannot be done in the portal and must be done within your rota provider. Supplementary notes can however,
        be added against roles and locations below. Rota provider changes will be reflected in the portal after your
        next rota sync (typically every 2 hours).
      </sme-alert>
      <b-row>
        <b-col lg="6" class="mb-4 mb-lg-0">
          <flexible-work-secondary-entity-list
            :fields="ROLE_FIELDS"
            :items="roles"
            :loading="loadingRoles"
            :create-function="createRole"
            :delete-function="deleteRole"
            :update-function="updateRole"
            name="Role"
            @updated="getRoles"
          />
        </b-col>
        <b-col lg="6">
          <flexible-work-secondary-entity-list
            :fields="LOCATION_FIELDS"
            :items="locationFields"
            :loading="loadingLocations"
            :messages="{ invalidItem: 'Location does not have a valid postcode' }"
            :create-to="{ name: 'divisions-add', params: { backTo: { name: $route.name } } }"
            :delete-function="deleteLocation"
            :update-to="
              location => ({
                name: 'division',
                params: { divisionId: location.division_id, backTo: { name: $route.name } },
                query: { tab: 'details' },
              })
            "
            name="Location"
            @updated="getLocations"
          />
        </b-col>
      </b-row>
    </template>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import FlexibleWorkSecondaryEntityList from '@/pages/flexible-work/components/FlexibleWorkSecondaryEntityList.vue';
import useRolesAndLocations from '@/state/composables/useRolesAndLocations';
import { isIntegrated } from '@/utils/flexible-work';

const { roles, locations, loading, loadingRoles, loadingLocations, getRoles, getLocations, getRolesAndLocations } =
  useRolesAndLocations();

const ROLE_FIELDS = [
  {
    key: 'name',
    editable: true,
    manual: true,
  },
  {
    key: 'properties',
  },
];

const LOCATION_FIELDS = [
  {
    key: 'name',
    editable: false,
    manual: true,
  },
  {
    key: 'postcode',
    editable: true,
    manual: true,
  },
  {
    key: 'properties',
  },
];

defineProps({ isOnboarding: Boolean });

const locationFields = computed(() => {
  return locations.value.map(location => {
    return {
      division_id: location.division_id,
      name: location.name,
      postcode: location.properties.postcode,
      properties: {
        ratings: location.ratings,
        notes: location.properties.notes,
      },
    };
  });
});

const createRole = async role => await ApiClient.postFlexibleWorkRole(role);
const deleteRole = async role => await ApiClient.deleteFlexibleWorkRole(role);
const updateRole = async role => await ApiClient.putFlexibleWorkRole(role);
const deleteLocation = async location => await ApiClient.deleteDivision(location.division_id);

getRolesAndLocations();
</script>
