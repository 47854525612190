<template>
  <sme-card title="Shifts" :to="{ name: 'shifts' }">
    <p>Uploading or syncing shifts is how we calculate the amount that your hourly employees are able to stream.</p>
    <div>
      <sme-info-box id="shifts" title="How do shifts work?">
        <h4>Syncing from an integration</h4>
        <p>
          If you have an <router-link :to="{ name: 'sme-integrations' }" target="_blank">integration</router-link> with
          a rota provider, we will "sync" your shifts from them. This means you don't need to upload shifts directly to
          the portal.
        </p>
        <h4>Uploading to the portal</h4>
        <p>
          You can <router-link :to="{ name: 'sme-shift-add' }" target="_blank">upload</router-link> shifts manually to
          tell us how much your employees have earned. We recommend doing this regularly so that your employees'
          balances stay up-to-date.
        </p>
        <h4>Salaried employees</h4>
        <p>
          You don't need to do anything for salaried employees, as we will calculate their balance based on their salary
          alone.
        </p>
      </sme-info-box>
      <sme-box>
        <shift-status-alerts />
      </sme-box>
    </div>

    <template #footer>
      <b-button :to="{ name: 'shifts' }" variant="outline-primary">View shifts</b-button>
      <b-button v-if="canAddShifts && State.state.claims.a" :to="{ name: 'sme-shifts-upload' }" variant="primary"
        >Add shifts</b-button
      >
    </template>
  </sme-card>
</template>

<script setup>
import ShiftStatusAlerts from '@/components/ShiftStatusAlerts.vue';
import SmeBox from '@/components/atoms/SmeBox';
import SmeCard from '@/components/atoms/SmeCard';
import SmeInfoBox from '@/components/atoms/SmeInfoBox';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';

const { canAddShifts } = useFeatureFlags();
</script>
