import { redirectConfigs } from '@/utils/redirectConfigs';
import { getQueryParams } from '@/utils';

const getIndexGivenProbabilities = probabilities => {
  // Create a cumulative probabilities distribution, if the random number is less than the cumulative probability, return the index
  // For example:
  // probabilities: [0.1, 0.3, 0.4, 0.2]
  // Cumulative probabilities: [0.1, 0.4, 0.8, 1.0]
  // random value: 0.42
  // returns the third element because it covers the probability range between 0.4 to 0.8
  const randomValue = Math.random();
  let cumulativeProbability = 0;
  for (let i = 0; i < probabilities.length; i++) {
    cumulativeProbability += probabilities[i];
    if (randomValue < cumulativeProbability) {
      return i;
    }
  }
  // Returns the index of the highest probability as fallback
  const maxProbability = Math.max(...probabilities);
  return probabilities.indexOf(maxProbability);
};

export default function useAbTestRedirect() {
  const getAbTestRedirectConfig = groupId => {
    const adsConfig = redirectConfigs[groupId] || redirectConfigs['default'];
    const targetPageIndex = getIndexGivenProbabilities(adsConfig.map(i => i.probability));
    const redirectConfig = adsConfig[targetPageIndex];
    // Pass on all query params such as ws_content, overrides with any overlapping query params in the redirectConfig
    redirectConfig.queryParams = { ...getQueryParams(), ...redirectConfig.queryParams };
    return redirectConfig;
  };

  return { getAbTestRedirectConfig };
}
