<template>
  <Modal title="Add interviews or trial shifts" :modalId="modalId" :multi-column="true" size="xl" ref="modal">
    <template #modal-left>
      <l-map
        v-if="location"
        @ready="mapReady"
        class="map"
        id="add-shift-map"
        :zoom="12"
        :center="[location.latitude, location.longitude]"
        :options="{
          zoomControl: false,
          dragging: false,
          scrollWheelZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
          touchZoom: false,
          attributionControl: false,
        }"
        ref="map"
      >
        <l-tile-layer :url="url"></l-tile-layer>
      </l-map>
    </template>
    <div class="form-container">
      <div class="full-width">
        <div class="group-title">
          <div class="input-title">Screening method</div>
        </div>
        <b-radio-group v-model="screeningMethod" :options="screeningMethods" />
      </div>
      <div>
        <div class="input-title">Role</div>
        <div class="icon-form">
          <b-form-input readonly :value="role?.role?.role" />
          <Padlock class="icon" />
        </div>
      </div>
      <div>
        <div class="input-title">Site</div>
        <div class="icon-form">
          <b-form-input readonly :value="location?.name"></b-form-input>
          <Padlock class="icon" />
        </div>
      </div>
      <div class="full-width">
        <span class="subscript"
          ><Link :color="PaletteColors['default-lighten-20']" />Shift details coming from jobs cannot be changed.</span
        >
      </div>
      <div class="full-width row">
        <div class="input-title">Date</div>
        <div class="input-title">Time</div>
        <div>
          <b-button class="p-0" variant="none" @click="createShift">
            <plus-circle />
          </b-button>
        </div>
      </div>
      <div class="full-width row shift-inputs" v-for="shift in shifts" :key="shift.shift_id">
        <b-form-datepicker v-model="shift.date" />
        <div>
          <div class="time-container">
            <div>
              <b-form-input
                type="time"
                class="input-section"
                placeholder="HH:MM"
                v-model="shift.hour_start"
              ></b-form-input>
            </div>
            <ArrowRight height="18" width="18" :color="PaletteColors['default-lighten-50']" />
            <div>
              <b-form-input
                type="time"
                class="input-section"
                placeholder="HH:MM"
                v-model="shift.hour_end"
              ></b-form-input>
            </div>
          </div>
          <div v-if="getStartTimeHasPassed(shift.shift_id)" class="form-subtext danger">
            <Clock :color="PaletteColors['danger']" height="18" width="18" />
            Start time cannot be in the past
          </div>
          <div v-else-if="getHours(shift.shift_id)" class="form-subtext">
            <Clock height="18" width="18" />
            Duration {{ formatHoursAndMinutes(getHours(shift.shift_id)) }}
            {{ getMultiDayShift(shift.shift_id) ? ' (multi-day)' : '' }}
          </div>
        </div>
        <div>
          <b-button class="p-0" variant="none" @click="deleteShift(shift.shift_id)">
            <minus-circle :color="PaletteColors['danger']" />
          </b-button>
        </div>
      </div>
      <div v-if="shifts.length === 0" class="full-width">
        <span>No shifts added.</span>
      </div>
      <div class="full-width">
        <div class="group-title">
          <div class="input-title">Notes</div>
          <div class="helper-text">Optional</div>
        </div>
        <b-form-textarea class="input-section" v-model="notes"></b-form-textarea>
      </div>
    </div>

    <template #modal-footer>
      <div class="footer">
        <div>
          <div v-if="shifts.length > 0" class="total">
            <span class="label">Total value</span>
            <span class="value">£{{ toRawCurrency(totalValue, 'GBP') }}</span>
          </div>
        </div>
        <div class="buttons">
          <b-button variant="outline-primary" @click="$bvModal.hide(modalId)">Cancel</b-button>
          <b-button @click="postShifts" variant="primary" :disabled="addDisabled">Add shifts</b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import useTalentPoolLocations from '@/state/composables/useTalentPoolLocations';
import { computed, onMounted, ref } from 'vue';
import uuidv4 from 'uuid/v4';
import { set, isAfter, addDays, endOfDay, isPast } from 'date-fns';
import { toRawCurrency } from '@/utils';
import Modal from '@/components/Modal.vue';
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import PlusCircle from '@/assets/icons/PlusCircle.vue';
import MinusCircle from '@/assets/icons/MinusCircle.vue';
import Link from '@/assets/icons/Link.vue';
import Padlock from '@/assets/icons/Padlock.vue';
import { PaletteColors } from '@/Theme';
import Clock from '@/assets/icons/Clock.vue';
import ArrowRight from '@/assets/icons/ArrowRight.vue';
import { formatHoursAndMinutes } from '@/pages/talent-pool/utils/datetime';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { getShiftsForJob, postBulkShifts } from '@/queries/talent-pool/shifts';

const screeningMethods = ['Trial shift', 'Interview'];

const url = process.env.VUE_APP_MAPBOX_MAP_URL + `?access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`;

const modalId = 'add-interview-or-trial-shift-modal';

const queryClient = useQueryClient();

const onSuccess = () => {
  queryClient.fetchQuery(getShiftsForJob(props.jobId));
  shifts.value = [];
  modal.value.modal.hide();
};

const { getLocations, locations } = useTalentPoolLocations();
const postBulkShiftsMutation = useMutation(postBulkShifts({ onSuccess }));

const props = defineProps({
  jobId: {
    type: String,
    required: true,
  },
  roleId: {
    type: String,
    required: true,
  },
  locationId: {
    type: String,
    required: true,
  },
});

const modal = ref(null);
const screeningMethod = ref(screeningMethods[0]);
const role = ref(null);
const location = ref(null);
const map = ref(null);
const shifts = ref([]);
const notes = ref('');

const shiftsAreInvalid = computed(() => {
  return shifts.value.some(shift => {
    return shift.hour_start === '' || shift.hour_end === '' || getStartTimeHasPassed(shift.shift_id);
  });
});

const addDisabled = computed(() => {
  return shifts.value.length === 0 || shiftsAreInvalid.value;
});

const totalValue = computed(() => {
  return screeningMethod.value === 'Interview'
    ? 0
    : shifts.value.reduce((total, shift) => {
        return total + (getHours(shift.shift_id) || 0) * role.value.client_rate;
      }, 0);
});

const getShiftById = shiftId => {
  return shifts.value.find(shift => shift.shift_id === shiftId);
};

const getShiftStartDateTime = shift => {
  const [startHour, startMinute] = shift.hour_start.split(':').map(Number);
  return set(shift.date, {
    hours: startHour,
    minutes: startMinute || 0,
    seconds: 0,
    milliseconds: 0,
  });
};

const getShiftEndDateTime = shift => {
  const [endHour, endMinute] = shift.hour_end.split(':').map(Number);
  let endDateTime = set(shift.date, {
    hours: endHour,
    minutes: endMinute || 0,
    seconds: 0,
    milliseconds: 0,
  });

  // If end time is before start time, add 1 day
  if (endDateTime < getShiftStartDateTime(shift)) {
    endDateTime = addDays(endDateTime, 1);
  }

  return endDateTime;
};

const getStartTimeHasPassed = shiftId => {
  const shift = getShiftById(shiftId);
  return shift.hour_start === '' ? false : isPast(getShiftStartDateTime(shift));
};

const getHours = shiftId => {
  const shift = getShiftById(shiftId);
  return shift.hour_start === '' || shift.hour_end === ''
    ? false
    : (getShiftEndDateTime(shift) - getShiftStartDateTime(shift)) / (1000 * 60 * 60);
};

const getMultiDayShift = shiftId => {
  const shift = getShiftById(shiftId);
  return shift.hour_start === '' || shift.hour_end === ''
    ? false
    : isAfter(getShiftEndDateTime(shift), endOfDay(getShiftStartDateTime(shift)));
};

const mapReady = () => {
  // Hack to fix map not rendering properly in Modal
  setTimeout(function () {
    window.dispatchEvent(new Event('resize'));
  }, 250);
};

const createShift = () => {
  shifts.value.push({
    shift_id: uuidv4(),
    date: new Date(),
    hour_start: new Date().getHours() + 1 + ':00',
    hour_end: '',
  });
};

const deleteShift = shiftId => {
  const index = shifts.value.findIndex(shift => shift.shift_id === shiftId);
  shifts.value.splice(index, 1);
};

const postShifts = async () => {
  const payload = [];
  shifts.value.forEach(shift => {
    payload.push({
      shift_id: shift.shift_id,
      company_id: State.state.company.company_id,
      internal_role_id: props.roleId,
      external_role_name: null,
      location_id: props.locationId,
      currency: 'GBP',
      start_at: getShiftStartDateTime(shift),
      end_at: getShiftEndDateTime(shift),
      hours: getHours(shift.shift_id),
      assigned_to: null,
      source: 'raise_manual_job_shift_upload',
      group_id: props.jobId,
      shift_code: shift.shift_id,
      properties: {
        notes: notes.value,
        type: screeningMethod.value.toLowerCase().replace(' ', '_'),
      },
    });
  });
  await postBulkShiftsMutation.mutateAsync({ payload });
};

onMounted(async () => {
  await getLocations();
  location.value = locations.value.find(location => location.location_id === props.locationId);

  const { data } = await ApiClient.getTalentPoolRoles();
  role.value = data.find(role => role.internal_job_role_id === props.roleId);
});
</script>

<style scoped lang="scss">
.input-title {
  font-size: 14px;
  font-weight: 500;
  margin: 6px;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
}

.full-width {
  grid-column: 1 / -1;
}

.form-subtext {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.danger {
  color: var(--palette-color-danger);
}

.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helper-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
}

.time-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.time-container div {
  display: flex;
  flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.footer .label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.footer .value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.total {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.map {
  display: block;
  height: 100%;
  width: 100%;
  min-height: 460px;
}

::v-deep .custom-radio {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0.25rem;

  &:first-child {
    width: 50%;
    margin-left: 0.5rem;
  }
}

::v-deep .custom-control-label::before {
  transform: scale(1.5);
  padding-left: 0.1rem;
}

::v-deep .custom-control-label::after {
  transform: scale(1.5);
}

.subscript {
  color: var(--palette-color-default-lighten-20);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
  margin: 0;
}

.shift-inputs > div:nth-child(1) {
  flex: 9;
}

.shift-inputs > div:nth-child(2) {
  flex: 9;
}

.shift-inputs > div:nth-child(3) {
  flex: 1;
}

.icon-form {
  display: -webkit-box;
}

.icon {
  position: relative;
  right: 30px;
  top: 12px;
}
</style>
