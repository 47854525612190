<template>
  <main class="get-started">
    <div class="cover-wrapper">
      <div class="title">
        <span>Welcome to</span>
        <img :src="themeImage('raise-logo-without-icon.svg')" />
      </div>
      <p>Hire verified hospitality talent from leading UK hospitality businesses</p>
      <img src="./images/raise-job-get-started-cover.png" />
    </div>
    <div class="content-wrapper">
      <Step :step="1" title="Post a job" description="Fill in a quick job form" imageName="raise-add-job.png"
        ><b-button v-if="!props.onboarding" variant="primary" v-b-modal="'add-job-modal'"
          ><font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />Post a job</b-button
        ></Step
      >
      <Step
        :step="2"
        title="Finding the best workers for you"
        description="Allow up to 5 days for us to create your shortlist with the best available candidates in our pool. "
        imageName="raise-finding-candidates.png"
      ></Step>
      <img class="timeline-image" src="./images/matching-process.png" />
      <Step
        :step="3"
        title="Your shortlist is ready!"
        description="Pay to unlock their contact information and full work history."
        imageName="raise-unlock-full-profile.png"
      >
      </Step>
      <div v-if="!props.onboarding" class="access">
        <img src="./images/raise-show-help.png" />
        <p>You can access this screen anytime by clicking the ? button next to your profile</p>
      </div>
    </div>
  </main>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import { getEntityType } from '@/pages/talent-pool/utils/getEntityType';
import { onMounted, ref } from 'vue';
import { themeImage } from '@/Theme';
import Step from '@/pages/talent-pool/components/GetStarted/components/Step.vue';

const props = defineProps({
  onboarding: {
    type: Boolean,
    default: false,
  },
});

const organisation = ref([]);

const getOrganisation = async () => {
  const { data } = await ApiClient.getTalentPoolOrganisation();
  organisation.value =
    data.children.filter(org => getEntityType(org.source) !== 'brand' || org.children?.length > 0) || [];
};

onMounted(() => {
  getOrganisation();
});
</script>

<style scoped lang="scss">
.get-started {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--palette-color-base-white);
  padding: 1.5rem 3.25rem;
}

.cover-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.cover-wrapper .title {
  display: flex;
  align-items: flex-end;
  gap: 0.375rem;
}
.cover-wrapper .title img {
  margin-bottom: 0.625rem;
  width: 88px;
}
.cover-wrapper .title span {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--palette-color-default);
}
.cover-wrapper p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--palette-color-default);
  text-align: center;
}
.cover-wrapper img {
  width: 80%;
  max-width: 1024px;
}

.actions-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 5rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
.timeline-image {
  width: 100%;
  max-width: 1024px;
}

.access {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 3rem;
}
.access p {
  font-size: 13px;
  font-weight: 400;
  color: var(--palette-color-default-lighten-20);
}
.access img {
  width: 50%;
  max-width: 600px;
  min-width: 250px;
  border-radius: 0.75rem;
  border: 1px solid var(--palette-color-success-lighten-90);
}

.help-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 4rem;
}
.help-wrapper p {
  font-size: 20px;
  font-weight: 500;
  color: var(--palette-color-default);
  margin: 0;
}
.help-wrapper button {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: 1px solid var(--palette-color-default);
  border: 1px solid var(--palette-color-default-lighten-90);
  background: var(--palette-color-base-white);
  border-radius: 99px;
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
  width: fit-content;
  gap: 0.5rem;
  transition: all ease-out 180ms;
}
.help-wrapper button:hover {
  border-color: var(--palette-color-base-white);
  box-shadow: 0px 0px 2px 0px rgba(34, 40, 47, 0.05), 0px 2px 10px 0px rgba(34, 40, 47, 0.1);
  transition: all ease-out 180ms;
}
.help-wrapper button:active {
  border-color: var(--palette-color-default-lighten-90);
  box-shadow: unset;
  transition: all 0ms;
  opacity: 0.5;
}

@media (max-width: 1000px) {
  .cover-wrapper img {
    width: 100%;
  }
}
</style>
