<template>
  <article>
    <app-loading :loading="loading" is-background />

    <template v-if="!loading">
      <template v-if="anyShifts">
        <sme-alert v-if="lastSuccessfulSync === NA" level="danger">
          We have not received shift data in the last {{ N_DAYS }} days.
        </sme-alert>
        <sme-alert v-else level="good">
          <p v-if="showShiftUploadDetail">
            We last received <i>new</i> shift data ({{ shiftsUploaded }} uploaded, {{ shiftsUpdated }} updated) on
            {{ toFormattedDateTime(lastSuccessfulSync) }}.
          </p>
          <p v-else>We last received <i>new</i> shifts on {{ toFormattedDateTime(lastSuccessfulSync) }}.</p>
        </sme-alert>
        <sme-alert
          v-if="employeesWithoutShiftsCount && !isUseUserCountInsteadOfEmployeesCount"
          level="warning"
          class="mt-2"
        >
          The following
          <sme-button-link @click="handleDisplayNoShifts" inherit>
            {{ employeesWithoutShiftsCount > 1 ? employeesWithoutShiftsCount : '' }}
            {{ pluralize('employee', employeesWithoutShiftsCount) }}
          </sme-button-link>
          {{ employeesWithoutShiftsCount > 1 ? 'have' : 'has' }} not worked a shift in the last {{ N_DAYS }} days. This
          means that their balance will not have updated in this time period.
        </sme-alert>
        <employee-issue-list
          v-if="employeesWithoutShifts.length > 0 && showMissingShifts"
          :employees="employeesWithoutShifts"
          class="mt-2"
        />
        <sme-alert v-if="employeesWithoutShiftsCount === 0 && employeesWithShiftsCount >= 1" level="good" class="mt-2">
          We have received shifts for all of your enrolled employees in the last {{ N_DAYS }} days.
        </sme-alert>
      </template>
      <sme-alert v-else level="danger">We have not found any shifts for your employees.</sme-alert>
    </template>
  </article>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import EmployeeIssueList from '@/components/EmployeeIssueList.vue';
import SmeAlert from '@/components/atoms/SmeAlert';
import SmeButtonLink from '@/components/atoms/SmeButtonLink';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { pluralize } from '@/utils/common';
import { toFormattedDateTime } from '@/utils/date';

const { isUseUserCountInsteadOfEmployeesCount } = useFeatureFlags();

const N_DAYS = 8;
const NA = 'N/A';

const showMissingShifts = ref(false);
const loading = ref(true);
const anyShifts = ref(false);

const employeesWithoutShiftsCount = ref(0);
const employeesWithShiftsCount = ref(0);
const employeesWithoutShifts = ref([]);
const lastSuccessfulSync = ref(NA);
const shiftsUploaded = ref(undefined);
const shiftsUpdated = ref(undefined);

defineProps({
  showShiftUploadDetail: String,
});

const handleDisplayNoShifts = () => (showMissingShifts.value = !showMissingShifts.value);

const getEmployeeShiftData = async () => {
  const employeeData = (await ApiClient.smeGetEmployeesWithoutShifts(N_DAYS))?.data;
  employeesWithoutShiftsCount.value = employeeData.employees_without_shift;
  employeesWithoutShifts.value = employeeData.employees;
  employeesWithShiftsCount.value = employeeData.employees_with_shift;
};

const getLastSyncData = async () => {
  const shiftsData = await ApiClient.smeGetShiftsInLastNDays(N_DAYS);
  const lastSuccessfulSyncData = shiftsData?.data?.last_successful_sync;

  const createdOn = (Date.now() - Date.parse(State.state.company.created_at)) / (24 * 3600 * 1000);
  const allShiftsData = await ApiClient.smeGetShiftsInLastNDays(parseInt(Math.ceil(createdOn)));
  anyShifts.value = allShiftsData?.data?.syncs_in_time_period;

  if (lastSuccessfulSyncData && lastSuccessfulSyncData !== NA) {
    const completedAt = lastSuccessfulSyncData.created_at;
    const dateParts = completedAt.split('T');
    const roundedPart2 = dateParts[1].split('.')[0];
    lastSuccessfulSync.value = `${dateParts[0]} ${roundedPart2}`;
  }

  shiftsUploaded.value = shiftsData?.data?.shifts_created_count;
  shiftsUpdated.value = shiftsData?.data?.shifts_updated_count || 0;
};

onBeforeMount(async () => {
  await Promise.all([getEmployeeShiftData(), getLastSyncData()]);

  loading.value = false;
});
</script>
