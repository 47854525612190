<template>
  <app-page title="Absences" subtitle="View and (optionally) manage absences" icon="calendar-alt">
    <template v-slot:infobox>
      <sme-info-box title="About absences" id="about-absences" save-state>
        <p>
          Absence data helps us to calculate earned wages - for salaried employees, we calculate their daily accrual
          based on their salary and absences.
        </p>
        <p>
          <strong>The employee experience is greatly improved the sooner we know about absences.</strong>
        </p>
      </sme-info-box>
      <sme-info-box title="How can I add absences?" id="adding-absences" save-state>
        <p>
          The easiest way to manage absences is to use an
          <router-link :to="{ name: 'sme-integrations' }">integration</router-link>
          with popular absence software provider.
        </p>
        <p v-if="canAddAbsences">
          Alternatively, you can manually provide us absence data -- imported
          <router-link :to="{ name: 'sftp-bulk-upload', query: { entity: 'absences' } }"> in bulk </router-link> -- at
          your preferred schedule via the portal.
        </p>
      </sme-info-box>
      <sme-info-box title="How can I edit absences?" id="editing-absences" save-state>
        <p v-if="canAddAbsences">
          If you have uploaded absence data through our portal, please edit or remove the absence on your absence file
          and re-upload.
        </p>
        <p v-if="!canAddAbsences">
          If you are integrated with an {{ bankingInfo.absence.absenceProvider }}, you will need to update the absences
          on their platform. Any updates that you make will be reflected on this page upon the next sync.
        </p>
      </sme-info-box>
    </template>
    <template v-slot:header v-if="State.state.claims.a">
      <b-button
        v-if="canAddAbsences"
        :to="{ name: 'sftp-bulk-upload', query: { entity: 'absences' } }"
        variant="outline-primary"
      >
        <font-awesome-icon :icon="['fal', 'upload']" class="mr-2" /> Bulk Import
      </b-button>
    </template>

    <template v-slot:custom-content>
      <b-col>
        <b-card>
          <absences-list class="mt-3" />
        </b-card>
      </b-col>
    </template>
  </app-page>
</template>

<script setup>
import { computed } from 'vue';
import { getBankingInfo } from '@/Banking';
import AppPage from '@/components/AppPage.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import AbsencesList from '@/pages/absences/components/AbsencesList.vue';
import State from '@/state/State';

const { canAddAbsences } = useFeatureFlags();
const bankingInfo = computed(() => getBankingInfo());
</script>
