import { render, staticRenderFns } from "./AddDivisionModal.vue?vue&type=template&id=274298c8&scoped=true&"
import script from "./AddDivisionModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./AddDivisionModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AddDivisionModal.vue?vue&type=style&index=0&id=274298c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274298c8",
  null
  
)

export default component.exports