import { offset } from '@floating-ui/dom';
import Shepherd from 'shepherd.js';

const BASE_CONFIG = {
  defaultStepOptions: {
    classes: 'sme-tour',
    canClickTarget: false,
    cancelIcon: {
      enabled: true,
    },
    floatingUIOptions: {
      middleware: [offset(10)],
    },
    scrollTo: {
      behavior: 'smooth',
      block: 'center',
    },
    showOn: function () {
      if (this.attachTo?.element) {
        const attachToElement = this.attachTo.element;
        let element;

        if (typeof attachToElement === 'string') {
          element = document.querySelector(attachToElement);
        } else if (typeof attachToElement === Function) {
          element = attachToElement();
        } else {
          element = attachToElement;
        }

        if (!element) {
          return false;
        }
      }

      return true;
    },
  },
  useModalOverlay: true,
};

export const createTour = (config = {}) => new Shepherd.Tour({ ...BASE_CONFIG, ...config });

export const createTourButton = (text, action, classes = 'btn btn-primary') => ({ text, action, classes });

export const createTourText = lines => lines.map(line => `<p>${line}</p>`).join('');

const getTourStates = () => JSON.parse(localStorage?.tourStates || '{}');

export const getTourState = id => !!getTourStates()[id];

export const setTourState = id => {
  if (localStorage) {
    localStorage.tourStates = JSON.stringify({ ...getTourStates(), [id]: true });
  }
};
