var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['page', { open: _setup.sidebarOpen }]},[_c('aside',{class:['sidebar', { open: _setup.sidebarOpen }]},[(_setup.locations.length > 0)?_c('div',{staticClass:"location-wrapper"},[_c(_setup.LocationSelector,{attrs:{"compact":!_setup.sidebarOpen,"route-name":"talent-pool-map"}})],1):_vm._e(),_c('div',{staticClass:"roles-wrapper"},[(_setup.roles?.length > 0)?_c('ul',_vm._l((_setup.roles),function({ role }){return _c('li',{key:role,class:[{ selected: _setup.selectedRoles.includes(role) }],on:{"click":function($event){return _setup.toggleRole(role)}}},[_c('span',{staticClass:"role-icon",style:(_setup.selectedRoles.includes(role)
                ? {
                    background: _setup.roleColors[role] || _setup.roleColors['default'],
                    color: 'var(--palette-color-base-white)',
                  }
                : { color: _setup.roleColors[role] || _setup.roleColors['default'] }),domProps:{"innerHTML":_vm._s(_setup.roleIcons[role] || _setup.roleIcons['default'])}}),_c('span',{staticClass:"role-name"},[_vm._v(_vm._s(role))])])}),0):_c('ul',_vm._l((5),function(i){return _c('li',{key:i},[_c('span',{staticClass:"role-icon selected",style:({ backgroundColor: 'var(--palette-color-default-lighten-80)' })}),_c('span',{staticClass:"role-name"},[_vm._v("Loading...")])])}),0)])]),_c('div',{staticClass:"map-container"},[_c('button',{class:['expand-toggle', { open: _setup.sidebarOpen }],on:{"click":function($event){_setup.sidebarOpen = !_setup.sidebarOpen}}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M6.91107 3.16083C7.23651 2.83539 7.76414 2.83539 8.08958 3.16083L14.3396 9.41083C14.665 9.73626 14.665 10.2639 14.3396 10.5893L8.08958 16.8393C7.76414 17.1648 7.23651 17.1648 6.91107 16.8393C6.58563 16.5139 6.58563 15.9863 6.91107 15.6608L12.5718 10.0001L6.91107 4.33934C6.58563 4.0139 6.58563 3.48626 6.91107 3.16083Z","fill":"currentColor"}})])]),_c(_setup.LMap,{ref:"map",staticClass:"map",attrs:{"zoom":_setup.zoom,"center":_setup.center,"options":{
        attributionControl: false,
      }},on:{"update:bounds":_setup.boundsUpdated,"update:zoom":_setup.zoomUpdated}},[_c(_setup.LTileLayer,{attrs:{"url":_setup.url}}),_vm._l((_setup.filteredEmployees),function(employee){return _c(_setup.LMarker,{key:employee.opaque_id,attrs:{"lat-lng":[employee.worker_latitude, employee.worker_longitude]}},[_c(_setup.LIcon,{attrs:{"class-name":"worker-marker","icon-size":[84, 47],"icon-anchor":[0, 47]}},[(_setup.zoom > 12)?_c('div',{class:['worker']},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_setup.truncateText(employee.first_name, 8)))]),_c(_setup.RaiseRating,{attrs:{"rating":employee.internal_rating,"size":"EXTRA-SMALL"}})],1):_vm._e(),_c('div',{staticClass:"point"})])],1)}),_vm._l((_setup.filteredLocations),function(location){return _c(_setup.LMarker,{key:location.location_id,attrs:{"lat-lng":[location.latitude, location.longitude]}},[_c(_setup.LIcon,{attrs:{"class-name":"location-marker","icon-size":[64, 75],"icon-anchor":[32, 75]}},[_c('div',{staticClass:"location-pin-inner"},[_c('img',{staticClass:"location-logo",attrs:{"src":location.properties.logo_url}}),_c('svg',{staticClass:"pin",attrs:{"width":"64","height":"75","viewBox":"0 0 64 75","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M64.0001 31.9997C64.0001 13.5747 48.4281 -1.21332 29.7271 0.0786837C13.7141 1.18468 0.843099 14.3257 0.0410991 30.3567C-0.672901 44.6427 7.9881 56.9997 20.4101 61.8287C22.6031 62.6807 24.4731 64.1987 25.7301 66.1867L30.9151 74.3907C31.1291 74.7257 31.4491 74.9317 31.8221 74.9837C32.3291 75.0607 32.8891 74.8547 33.1821 74.3647L38.3181 66.1867C39.5441 64.2347 41.3451 62.6917 43.4961 61.8637C55.4891 57.2457 64.0001 45.6197 64.0001 31.9997Z"}})])])])],1)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }