<template>
  <sme-box title="Pending Employees">
    <dashboard-stats :stats="stats" />
    <sme-alert v-if="showPendingEmployeesAlert" level="danger">
      You currently have employees who are waiting to be enrolled. See the Tasks section to enroll your employees.
    </sme-alert>
    <sme-alert v-else level="info">
      Your integration is set up to automatically enroll your employees. You do not need to take any action.
      <SmeButtonLink @click="goToFilteredEmployees"> View enrolling employees. </SmeButtonLink>
    </sme-alert>
  </sme-box>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import SmeAlert from '@/components/atoms/SmeAlert';
import SmeBox from '@/components/atoms/SmeBox';
import SmeButtonLink from '@/components/atoms/SmeButtonLink';
import useFeatureFlags from '@/composables/useFeatureFlags';
import DashboardStats from '@/pages/dashboard/components/DashboardStats';

const $router = useRouter();
const { canEnrollEmployees } = useFeatureFlags();

const props = defineProps({
  pendingEmployeesCount: {
    type: Number,
    required: true,
  },
});

const averagePendingTime = ref(0);

const showPendingEmployeesAlert = computed(() => {
  return props.pendingEmployeesCount > 0 && canEnrollEmployees.value;
});
const stats = computed(() => {
  return [
    {
      value: props.pendingEmployeesCount,
      explanation: 'pending employees',
    },
    {
      value: averagePendingTime.value,
      explanation: 'days in pending on average',
    },
  ];
});

const goToFilteredEmployees = () => {
  $router.push({
    name: 'employees',
    query: {
      employee_status: 'ENROLLING',
    },
  });
};

onBeforeMount(async () => {
  const [averagePendingTimeData] = await Promise.all([ApiClient.smeGetAveragePendingTime()]);
  averagePendingTime.value = Math.round(averagePendingTimeData.data / 24);
});
</script>
